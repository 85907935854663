import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { ExternalProvidersAADPage } from '../authsettings/subcomponents/ExternalProvidersAADPage';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import SecuritySettingsSAMLForm from './subcomponents/SecuritySettingsSAMLForm';

const ConfigureSSOPage: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const { type } = useParams<{ type: 'aad' | 'saml2' }>();

    const organizationName = useOrganizationName();
    const provider = type === 'saml2' ? 'saml' : type;

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: !process.buildConfigs.isOnPrem ? RouteNames.SecuritySettingsConfigure : RouteNames.AuthSettings,
            name: translate({ id: `CLIENT_AUTH_SETTINGS_CONFIGURE_${provider.toUpperCase()}_TITLE` }),
        },
    ];

    return (
        <UiPageContainer
            header={AdminBreadCrumbs(breadCrumbLinks)}
            position='center'
            data-cy={`security-settings-configure-${provider}-panel`}
        >
            {provider === 'aad'
                ? <ExternalProvidersAADPage />
                : <SecuritySettingsSAMLForm />}
        </UiPageContainer>
    );
};

export default ConfigureSSOPage;
