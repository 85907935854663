import { fixName } from '@experiences/util';

import type { IOnPremOrganizationDto } from '../common/interfaces/organization';
import type { IAddOrganizationData } from '../component/organization/subcomponents/AddOrganizationComponent';

export function mapOnPremOrganizationDto(data: IAddOrganizationData, language: string): IOnPremOrganizationDto {
    return {
        organizationName: data.organizationName,
        logicalName: data.organizationName,
        adminUserName: fixName(data.adminUserName),
        adminFirstName: fixName(data.adminFirstName),
        adminLastName: fixName(data.adminLastName),
        adminEmail: data.adminEmail,
        adminPassword: data.password,
        language,
    };
}
