import type { LanguageCulture } from '@experiences/locales';

import type {
    IBasicUserDto,
    IBulkInviteUserDto,
    IBulkInviteUserResponse,
    IUpdateUserDto,
    IUserCIS,
} from '../../common/interfaces/cis/user';
import type { IResult } from '../../common/interfaces/result';
import { updateUserGroups } from '../../store/action/UserProfileAction';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

const userUrl = `${process.buildConfigs.identityApiBaseRoute}/User`;

export function getUser(userGlobalId: string) {
    return get<IUserCIS>(`${userUrl}/${userGlobalId}`);
}

export async function createUser(basicUserDto: IBasicUserDto) {
    return await post<IResult>(userUrl, { body: basicUserDto });
}

export async function putUser(userGlobalId: string, userData: IUpdateUserDto, isCurrentUser: boolean) {
    await put<any>(`${userUrl}/${userGlobalId}`, { body: userData });
    if (isCurrentUser && userData.groupIDsToAdd) {
        await updateUserGroups('update', userData.groupIDsToAdd);
    }
}

export async function deleteUsers(partitionGlobalId: string, userIds: string[], deleteCurrentUser: boolean = false, isHostMode: boolean = false) {
    return await axiosDelete(userUrl, {
        body: {
            partitionGlobalId,
            userIds,
            deleteCurrentUser,
            isHostMode,
        },
    });
}

export async function inviteUsers(
    inviteData: IBulkInviteUserDto[],
    provider?: string,
    language?: LanguageCulture,
    orgName?: string,
) {
    return await post<IBulkInviteUserResponse>(`${userUrl}/InviteUsers`, {
        body: {
            inviteData,
            provider,
            language,
            orgName,
        },
    });
}
