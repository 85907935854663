import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Checkbox,
    Divider,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        selectButton: { paddingLeft: '12px' },
        inputBase: { marginRight: '8px' },
        selectIcon: {
            color: theme.palette.semantic.colorIconDefault,
            verticalAlign: 'bottom',
        },
        menuItemPadding: { padding: '4px 16px' },
        menuItemRoot: { height: '36px' },
    }),
);

interface IUserLicenseSelectFilterProps {
    filterMap: Map<any, any>;
    header: string;
    filterChange: (selected: string[]) => void;
}

export const UserLicenseSelectFilterComponent: React.FC<IUserLicenseSelectFilterProps> = ({
    filterMap,
    header,
    filterChange,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ selected, setSelected ] = useState<string[]>([]);

    useEffect(() => {
        setSelected([]);
    }, []);

    const options = useMemo(() => filterMap!, [ filterMap ]);

    const optionsArray = useMemo(() => [ ...options.keys() ], [ options ]);

    const change = useCallback(
        event => {
            let arr = (event.target.value as string[]).flat();
            const flattened = JSON.stringify(arr) !== JSON.stringify(event.target.value);
            arr = Array.from(new Set(arr));
            if (flattened && selected.length === optionsArray.length) {
                arr = [];
            }
            setSelected(arr);
            filterChange(arr);
        },
        [ filterChange, optionsArray.length, selected.length ],
    );

    return (
        <Select
            multiple
            displayEmpty
            renderValue={() => header + (selected.length ? ` (${selected.length})` : '')}
            value={selected}
            disableUnderline
            IconComponent={ExpandMoreIcon}
            classes={{
                select: classes.selectButton,
                icon: classes.selectIcon,
            }}
            className={classes.inputBase}
            onChange={change}
            data-cy="grid-ubl-filter"
            variant="standard"
        >
            <MenuItem
                value={optionsArray}
                className={classes.menuItemPadding}
                data-cy="grid-ubl-select-all">
                <Checkbox
                    checked={selected.length === optionsArray.length}
                    indeterminate={!!selected.length && selected.length < optionsArray.length}
                />
                <ListItemText primary={translate({ id: 'CLIENT_SELECT_ALL_NONE' })} />
            </MenuItem>
            <Divider />
            {optionsArray.map((option, i) => (
                <MenuItem
                    key={i}
                    value={option}
                    className={clsx(classes.menuItemRoot, classes.menuItemPadding)}
                    data-cy="auditlog-grid-category-select-one"
                >
                    <Checkbox checked={selected.indexOf(option) > -1} />
                    <ListItemText primary={options.get(option)} />
                </MenuItem>
            ))}
        </Select>
    );
};
