import type {
    ICustomPlanForm,
    IDiscountedProductPriceOptions,
    IPackage,
    IPackagesConfig,
} from '@experiences/ecommerce';
import {
    billingUrl,
    BuyProPresets,
    getPackagesConfigByCurrency,
    getProductsPricesInAllCurrencies,
    useEcommerce,
    useEcommerceEnabledCountries,
} from '@experiences/ecommerce';
import {
    useAuthContext,
    useRouteResolver,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import useSWR from 'swr';

const useEcommerceIndividualProductsViewModel = (currentAccountName: string) => {
    const productsPricesInAllCurrenciesUrl = `${billingUrl}/productsPricesInAllCurrencies`;
    const { token } = useAuthContext();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const { accountCountryCode } = useEcommerceEnabledCountries();
    const { data: productsPricesInAllCurrencies } = useSWR<IDiscountedProductPriceOptions[], Error>(
        [ currentAccountName, productsPricesInAllCurrenciesUrl ], () => getProductsPricesInAllCurrencies(currentAccountName, token));
    const [ loading, setLoading ] = useState<boolean>(true);
    const {
        currentSkuPackageDetails,
        isTaxLoading,
    } = useEcommerce();

    const {
        defaultProductsToRender, addonProductsToRender,
    } = useMemo<{
        defaultProductsToRender: IDiscountedProductPriceOptions[];
        addonProductsToRender: IDiscountedProductPriceOptions[];
    }>(() => {
        const defaultProductCodes: string[] = [ 'RPADEVPRONU', 'RU' ];
        const addonProductCodes: string[] = [ 'CTZDEVNU', 'UNATT', 'ATTUNU' ];
        const defaultProducts: IDiscountedProductPriceOptions[] = [];
        const addonProducts: IDiscountedProductPriceOptions[] = [];
        if (productsPricesInAllCurrencies) {
            const productsGroupedByCode: { [key: string]: IDiscountedProductPriceOptions } = {};
            productsPricesInAllCurrencies.forEach(product => {
                if (product.planType === currentSkuPackageDetails.planType) {
                    productsGroupedByCode[product.code] = product;
                }
            });
            defaultProductCodes.forEach((productCode) => {
                const product = productsGroupedByCode[productCode];
                if (product) {
                    defaultProducts.push(product);
                }
            });
            addonProductCodes.forEach(productCode => {
                const product = productsGroupedByCode[productCode];
                if (product) {
                    addonProducts.push(product);
                }
            });
        }
        return {
            defaultProductsToRender: defaultProducts,
            addonProductsToRender: addonProducts,
        };
    }, [ productsPricesInAllCurrencies, currentSkuPackageDetails ]);

    const [ customPlanPackage, setCustomPlanPackage ] = useState<IPackage>();
    const [ selectedCurrency, setSelectedCurrency ] = useState<string>('USD');

    const packagesConfigUrl = `${billingUrl}/packagesConfig`;
    const { data: packagesData } = useSWR<IPackagesConfig, Error>(
        [ currentAccountName, selectedCurrency, accountCountryCode, packagesConfigUrl ],
        () => getPackagesConfigByCurrency(currentAccountName, token, selectedCurrency),
    );

    useEffect(() => {
        if (!customPlanPackage) {
            setCustomPlanPackage(packagesData?.packages.find(p => p.isCustomizable));
        }
    }, [ packagesData?.packages, customPlanPackage ]);

    const productQuantitiesForCustomPlan = useMemo(() => {
        const productQuantities: { [code: string]: number } = {};

        if (customPlanPackage) {
            customPlanPackage?.productQuantities?.forEach((pq) => productQuantities[pq.code] = pq.quantity);
            setLoading(false);
        }

        return productQuantities;
    }, [ customPlanPackage ]);
    const useFormMethods = useForm<ICustomPlanForm>({
        mode: 'onChange',
        defaultValues: { productQuantities: productQuantitiesForCustomPlan },
    });
    const {
        getValues, formState: { isValid: isFormValid }, reset,
    } = useFormMethods;

    useEffect(() => {
        reset({ productQuantities: productQuantitiesForCustomPlan });
    }, [ productQuantitiesForCustomPlan, reset ]);

    const backToPresets = useCallback(() => {
        history.push({
            pathname: getRoute(BuyProPresets),
            state: { selectedCurrency },
        });
    }, [ history, getRoute, selectedCurrency ]);

    const updatePlanSummary = useCallback(() => {
        const { productQuantities } = getValues();
        const updatedCustomPlanPackage = customPlanPackage;

        updatedCustomPlanPackage?.productQuantities.forEach(p => {
            p.quantity = Number(productQuantities[p.code] ?? 0);
        });

        setCustomPlanPackage(updatedCustomPlanPackage);

    }, [ customPlanPackage, setCustomPlanPackage, getValues ]);

    const checkPlusInput = useCallback((productCode) => {
        if (customPlanPackage) {
            const productQuantity = customPlanPackage?.productQuantities.find(pq => pq.code === productCode);
            if (productQuantity?.minQuantity) {
                return productQuantity.minQuantity > 0;
            }
        }
        return false;
    }, [ customPlanPackage ]);

    const isMonthlyPlanType = useMemo(() => currentSkuPackageDetails.planType === 'ANNUAL_SUB_MONTHLY_PAY', [ currentSkuPackageDetails ]);

    return {
        defaultProductsToRender,
        addonProductsToRender,
        productsPricesInAllCurrencies,
        customPlanPackage,
        useFormMethods,
        productQuantitiesForCustomPlan,
        loading,
        isTaxLoading,
        currentSkuPackageDetails,
        isMonthlyPlanType,
        selectedCurrency,
        setSelectedCurrency,
        checkPlusInput,
        updatePlanSummary,
        backToPresets,
        isFormValid,
    };
};

export default useEcommerceIndividualProductsViewModel;
