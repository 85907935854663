import type { IAuthenticationSettingPayload } from '../identity/AuthenticationSettingService';

export function authenticationSettingMapper(payload: IAuthenticationSettingPayload) {
    const bodyPayload: any = {
        ...payload,
        authenticationScheme: 'oidc',
        isActive: true,
        isExclusive: true,
    };

    if (!bodyPayload.allowedIdp) {
        const tenantId = payload.tenantId!.trim();
        bodyPayload.logoutUrl = 'https://login.microsoftonline.com/common/oauth2/v2.0/logout';
        bodyPayload.authority = `https://login.microsoftonline.com/${tenantId}/v2.0/`;
        bodyPayload.settings = JSON.stringify({ PostLogoutRedirectUriParameter: 'post_logout_redirect_uri' });
        bodyPayload.configuration = JSON.stringify({
            ClientId: payload.clientId,
            ClientSecret: payload.clientSecret,
            TenantId: tenantId,
            AADProvisioningAttributeMapper: {
                Department: payload.department,
                City: payload.city,
                ExtensionUserAttributeMappings: payload.extensionUserAttributeMappings,
            },
        });

        delete bodyPayload['tenantId'];
    }

    return bodyPayload;
}

export function authenticationSettingMapperSaml2(payload: IAuthenticationSettingPayload) {
    const bodyPayload: any = {
        ...payload,
        authenticationScheme: 'Saml2',
        isActive: true,
        isExclusive: true,
    };

    return bodyPayload;
}
