export enum LogExportInsStorageType {
    AzureEventHub = 'AzureEventHub',
    AwsSqs = 'AWSSQS',
    Splunk = 'SplunkHec',
    None = 'None',
}

export const logExportInsStorageTypeSelectItems = [
    {
        id: LogExportInsStorageType.AzureEventHub,
        displayId: 'CLIENT_AZURE_EVENT_HUBS',
        hidden: false,
        inPreview: false,
    },
    {
        id: LogExportInsStorageType.AwsSqs,
        displayId: 'CLIENT_AWS_SQS',
        hidden: false,
        inPreview: true,
    },
];

export const logExportInsStorageTypeSelectItemsOnPrem = [
    {
        id: LogExportInsStorageType.Splunk,
        displayId: 'CLIENT_SPLUNK',
        hidden: false,
        inPreview: false,
    },
];

export const AzEventHubsConnStrPattern = /Endpoint=sb:\/\/.*\.servicebus.windows.net\/;SharedAccessKeyName=.*;SharedAccessKey=.*/;
