import {
    AccountLicense,
    TelemetryTitle,
    TranslatedRegionName,
} from '@experiences/constants';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import {
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import type { IScheduledOrganizationMigrationStatus } from '../../../common/interfaces/gws';
import { getScheduledTenantMigrationStatus } from '../../../services/global-worklow-service/TenantMigration';
import { getAvailableServices } from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';
import type { IServiceMetadata } from '../../tenants/interfaces/service';
import { getDefaultRegionForTenant } from '../../tenants/subcomponents/helpers/TenantRegionHelper';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        moveTenantBox: {
            marginTop: '24px',
            marginBottom: '20px',
            margin: '0 2px',
        },
        moveTenantBoxDisabled: { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
        moveTenantTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        moveTenantTitleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
        },
        moveTenantDetails: {
            fontSize: '14px',
            fontWeight: 600,
            marginLeft: '12px',
        },
        requestMove: {
            display: 'flex',
            flexDirection: 'row',
            width: 'inherit',
            alignItems: 'center',
            outline: 'solid 1px',
            marginTop: '16px',
            borderRadius: '2px',
            outlineColor: theme.palette.semantic.colorBorderDeEmp,
            padding: '5px',
            position: 'relative',
            height: '44px',
        },
        moveButtonWrapper: {
            position: 'absolute',
            right: '4px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        tooltip: { align: 'center' },
        chipSpacer: { marginLeft: '8px' },
    }),
}),
);

const TenantMigration: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const location = useLocation();
    const { formatMessage: translate } = useIntl();
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const { logEvent } = useTelemetryHelper();
    const match = useRouteMatch<{ tenantId: string }>();

    const { tenantId } = useMemo<{ tenantId: string }>(
        () => ({ tenantId: match.params.tenantId }),
        [ match ],
    );

    const currentAccountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ '/api/tenant/availableservices', partitionGlobalId, currentAccountName ],
        getAvailableServices,
    );
    const currentRegion = useMemo(() => getDefaultRegionForTenant(availableServices), [ availableServices ]) ?? 'None';

    const [ tenantMigrationStatus, setTenantMigrationStatus ] = useState< string | undefined >();

    const {
        data: scheduledTenantMigrationStatus, isValidating: isScheduledMigrationStatusLoading,
    } = useSWR<IScheduledOrganizationMigrationStatus, Error>(
        [ tenantId, currentAccountName, 'getScheduledTenantMigrationStatus' ],
        getScheduledTenantMigrationStatus,
    );
    const navigateToMigration = useCallback(async () => {
        if (isFreeOrCommunityRevamp) {
            logEvent('OffersRevampSelfServeMigration.Click', {
                AdminRevampEnabled: isAdminRevampEnabled,
                URL: location,
            });
            history.push(getRoute(RouteNames.BuyPro));
            return;
        }
        logEvent(TelemetryTitle.TenantSelfServeMigration.RequestMove, { ServerRegion: currentRegion });
        if (tenantMigrationStatus === 'Scheduled') {
            history.push(getRoute(RouteNames.EditTenantMigration.replace(':tenantId', tenantId)), scheduledTenantMigrationStatus);
        } else {
            history.push(getRoute(RouteNames.TenantMigration.replace(':tenantId', tenantId)));
        }
        // eslint-disable-next-line max-len
    }, [ currentRegion, getRoute, history, isAdminRevampEnabled, isFreeOrCommunityRevamp, location, logEvent, logEvent, tenantMigrationStatus, scheduledTenantMigrationStatus ]);

    useEffect(() => {
        setTenantMigrationStatus(scheduledTenantMigrationStatus?.migrationAvailability);
    }, [ scheduledTenantMigrationStatus ]);

    const renderRequestMoveButtonText = useCallback(() => {
        if (tenantMigrationStatus === 'Enabled') {
            return translate({ id: 'CLIENT_REQUEST_MOVE' });
        }
        if (tenantMigrationStatus === 'Scheduled') {
            return translate({ id: 'CLIENT_MIGRATION_SCHEDULED' });
        }
        if (tenantMigrationStatus === 'Running') {
            return translate({ id: 'CLIENT_MIGRATION_RUNNING' });
        }
        if (tenantMigrationStatus === 'PendingFinalization') {
            return (<>
                {translate({ id: 'CLIENT_MIGRATION_COMPLETED' })}

            </>);

        }
        return translate({ id: 'CLIENT_REQUEST_MOVE' });
    }, [ tenantMigrationStatus, translate ]);

    const isMigrationButtonDisabled = useCallback(() => tenantMigrationStatus === 'Running' || tenantMigrationStatus === 'PendingFinalization', [ tenantMigrationStatus ]);
    return (
        <div
            className={classes.moveTenantBox}
            data-cy="tenant-migration-section">
            <div className={classes.moveTenantTitleSection}>
                <Typography
                    className={classes.moveTenantTitle}
                    data-cy="tenant-migration-settings"
                >
                    {translate({ id: 'CLIENT_SETTINGS_MIGRATION' })}
                </Typography>
                {isFreeOrCommunityRevamp &&
                    <UiUpgradeChip
                        className={classes.chipSpacer}
                        licenseType={AccountLicense.PRO}
                        title='CLIENT_CHIP_MIGRATION_HEADER'
                        description='CLIENT_CHIP_MIGRATION_DESCRIPTION'
                        iconDescription='CLIENT_CHIP_MIGRATION_ICON_DESCRIPTION'
                        icon={<LanguageOutlinedIcon />}
                        telemetryTitle={TelemetryTitle.TenantSettings} />}
            </div>
            <div className={clsx(classes.requestMove, isFreeOrCommunityRevamp && classes.moveTenantBoxDisabled)}>
                <Typography
                    className={classes.moveTenantDetails}
                    data-cy="tenant-migration-title">
                    {translate({ id: TranslatedRegionName[currentRegion] })}
                </Typography>
                {!isFreeOrCommunityRevamp && (
                    <span className={classes.moveButtonWrapper}>
                        <UiProgressButton
                            disabled={isMigrationButtonDisabled()}
                            size="small"
                            loading={isScheduledMigrationStatusLoading}
                            onClick={navigateToMigration}
                            data-cy="tenant-migration-button"
                            variant="text"
                        >
                            {renderRequestMoveButtonText()}
                        </UiProgressButton>
                        {tenantMigrationStatus === 'PendingFinalization' && (
                            <Tooltip
                                className={classes.tooltip}
                                data-cy="tenant-migration-tooltip"
                                title={translate({ id: 'CLIENT_MIGRATION_COMPLETED_TOOLTIP' })}>
                                <InfoOutlinedIcon fontSize='small' />
                            </Tooltip>)}
                    </span>
                )}
            </div>
        </div>
    );
};

export default TenantMigration;
