import { GlobalStyles } from '@experiences/theme';
import {
    Chip,
    CircularProgress,
    Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as NoActionIcon } from '../../../images/noPendingActions.svg';
import type {
    ITask,
    TaskProps,
} from './interfaces/task';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        taskListContainer: {
            width: '100%',
            paddingTop: '10px',
            overflow: 'auto',
            maxHeight: '350px',
        },
        taskRow: {
            padding: '14px 10px 14px 10px',
            display: 'flex',
            fontSize: '14px',
            cursor: 'pointer',
            borderBottom: 'solid 1px',
            borderBottomColor: theme.palette.semantic.colorBorderDeEmp,
            '&:last-child': { borderColor: 'transparent' },
            '&:hover, &:focus': { backgroundColor: theme.palette.semantic.colorHover },
        },
        taskData: {
            width: '20%',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            '&.taskTitle': {
                width: '60%',
                maxWidth: '900px',
            },
        },
        loadingContainer: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        noData: {
            paddingTop: '16px',
            textAlign: 'left',
            height: '36px',
            color: theme.palette.semantic.colorForeground,
            display: 'flex',
            alignItems: 'center',
        },
        noDataIcon: { marginLeft: '5px' },
        title: {
            paddingRight: '10px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
            width: 'auto',
            display: 'inline-block',
        },
        chip: {
            height: '16px',
            lineHeight: '16px',
            border: 'solid 1px',
            fontSize: '10px',
            fontWeight: 600,
            cursor: 'pointer',
            '&.Low': {
                backgroundColor: theme.palette.semantic.colorChipSuccessBackground,
                color: theme.palette.semantic.colorSuccessText,
                borderColor: theme.palette.semantic.colorSuccessText,
            },

            '&.High': {
                backgroundColor: theme.palette.semantic.colorChipWarningBackground,
                borderColor: theme.palette.semantic.colorWarningText,
                color: theme.palette.semantic.colorWarningText,
            },
            '&.Critical': {
                backgroundColor: theme.palette.semantic.colorChipErrorBackground,
                color: theme.palette.semantic.colorErrorText,
                borderColor: theme.palette.semantic.colorErrorText,
            },

            '&.Medium': {
                backgroundColor: theme.palette.semantic.colorChipInfoBackground,
                color: theme.palette.semantic.colorInfoForeground,
                borderColor: theme.palette.semantic.colorInfoForeground,
            },
        },
    }),
}));

const Loader = () => {
    const classes = useStyles();
    return (
        <div className={clsx(classes.loadingContainer)}>
            <CircularProgress
                size={36}
                thickness={4} />
        </div>
    );
};
const NoData: React.FC<{ actionType: number }> = ({ actionType }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    return (
        <Typography
            component="div"
            className={clsx(classes.noData)}
            data-cy="home-page-action-no-data">
            {actionType === 0 && translate({ id: 'CLIENT_NO_PENDING_ACTIONS' })}
            {actionType === 1 && translate({ id: 'CLIENT_NO_UNASSIGNED_ACTIONS' })}
            <NoActionIcon className={clsx(classes.noDataIcon, classes.svgIcon)} />
        </Typography>
    );
};

const ShowTask: React.FC<TaskProps> = ({
    taskListArray, gotoTask,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    return (
        <div className={clsx(classes.taskListContainer)}>
            {taskListArray?.map(task => (
                <div
                    className={clsx(classes.taskRow)}
                    key={task.Id}
                    data-cy="home-page-action-row"
                    onClick={() => gotoTask(task.Id)}
                >
                    <div className={clsx(classes.taskData, 'taskTitle')}>
                        <Typography>
                            <Tooltip title={task.Title}>
                                <span className={clsx(classes.title)}>
                                    {task.Title}
                                </span>
                            </Tooltip>
                        </Typography>
                    </div>
                    <div className={clsx(classes.taskData)}>
                        <Typography>
                            {task.Type}
                        </Typography>
                    </div>
                    <div className={clsx(classes.taskData)}>
                        <Chip
                            size="small"
                            variant="outlined"
                            className={clsx(classes.chip, task.Priority)}
                            label={translate({ id: 'PRIORITY_' + task.Priority.toUpperCase() })}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
const TaskInfo: React.FC<{
    taskList?: ITask[];
    loading: boolean;
    accountLogicalName: string;
    tenantName: string | null;
    actionType: number;
}> = ({
    taskList, loading, accountLogicalName, tenantName, actionType,
}) => {
    const gotoTask = useCallback(
        (id: number) => {
            window.location.assign(
                `${window.location.origin}/${accountLogicalName}/${tenantName}/actions_/tasks/${id}`,
            );
        },
        [ accountLogicalName, tenantName ],
    );
    return (
        <div>
            {loading && <Loader />}
            {!loading && (!Array.isArray(taskList) || taskList?.length === 0) && <NoData actionType={actionType} />}
            {!loading && taskList && taskList.length > 0 && <ShowTask
                taskListArray={taskList}
                gotoTask={gotoTask} />}
        </div>
    );
};
export default TaskInfo;
