import {
    AccountLicense,
    ContactUsLinks,
} from '@experiences/constants';
import { GlobalStyles } from '@experiences/theme';
import {
    useLocalizedLinks,
    useRouteResolver,
} from '@experiences/util';
import {
    Button,
    Popover,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCard } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        ...GlobalStyles(theme),
        icon: {
            margin: '6px 8px 0 0',
            color: theme.palette.orange[600],
        },
        cardDescription: {
            alignItems: 'center',
            alignContent: 'center',
            display: 'flex',
        },
        cardHeader: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '12px',
        },
        cardButton: {
            marginLeft: 'auto',
            marginRight: '0px',
        },
    }),
);

export const UiUpgradePopOver: React.FC<{
    licenseType: AccountLicense.ENTERPRISE | AccountLicense.PRO | -1 ;
    title: string;
    description: string;
    iconDescription: string;
    icon: JSX.Element;
    anchorEl: HTMLElement | null;
    telemetryTitle: string;
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
}> = ({
    licenseType, title, description, iconDescription, icon, anchorEl, setAnchorEl, telemetryTitle,
}) => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const getLocalizedLink = useLocalizedLinks();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const { logEvent } = useTelemetryHelper();
    const { formatMessage: translate } = useIntl();

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    logEvent(telemetryTitle, { UpgradePageLicense: AccountLicense[licenseType] });

    const showEnterpriseChip = useMemo(() => licenseType <= AccountLicense.ENTERPRISE, [ licenseType ]);

    const upgradeLicense = useCallback(() => {

        logEvent(`${telemetryTitle}${showEnterpriseChip ? 'Contact' : 'Upgrade'}`, {
            ChipLicenseType: licenseType,
            AdminRevampEnabled: isAdminRevampEnabled,
        });

        showEnterpriseChip
            ? window.open(getLocalizedLink(ContactUsLinks), '_blank', 'noopener,noreferrer')
            : history.push(getRoute(RouteNames.BuyPro));
    }, [ getLocalizedLink, getRoute, history, isAdminRevampEnabled, licenseType, logEvent, showEnterpriseChip, telemetryTitle ]);

    return (
        <Popover
            id="upgrade-chip-card"
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <PortalCard
                cardId='upgrade-chip-card'
                header={title}
                clickable={false}
                description={description}
                data-cy="upgrade-portal-card">
                <div className={classes.cardHeader}>
                    {licenseType > AccountLicense.UNDEFINED &&
                    <Typography className={classes.cardDescription}>
                        <span className={classes.icon}>
                            {icon}
                        </span>
                        {iconDescription}
                    </Typography> }
                    <Button
                        className={classes.cardButton}
                        onClick={upgradeLicense}
                        data-cy="upgrade-license-popup-button">
                        {translate({ id: showEnterpriseChip ? 'CLIENT_UPGRADE_TO_ENTERPRISE_REVAMP' : 'CLIENT_UPGRADE_TO_PRO' })}
                    </Button>
                </div>
            </PortalCard>
        </Popover>);
};
