import { useLocalizedLinks } from '@experiences/util';
import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { AllocationWarning } from '../../../common/constants/AllocationWarning.default';
import { checkIfLicenseIsOverallocated } from '../../../services/licensing/LicenseManagementService';
import {
    accountGlobalId,
    isAdminSelector,
    isHostModeSelector,
    isUnlicensedSelector,
} from '../../../store/selectors';
import type { ILicenseOverallocation } from '../../tenants/interfaces/service';
import UiAlertBanner from '../UiAlertBanner';

const useStyles = makeStyles(() =>
    createStyles({ learnMoreLink: { marginLeft: '1ch' } }),
);

const UiOverAllocationBanner: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountGUID = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const isHostMode = useSelector(isHostModeSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const getLocalizedLink = useLocalizedLinks();

    const {
        data, isValidating,
    } = useSWR<ILicenseOverallocation, Error>(
        isAdmin && !isUnlicensedMode ? [ accountGUID, `/api/account/${accountGUID}/accountLicenseOverAllocation` ] : null,
        checkIfLicenseIsOverallocated,
    );

    const isOverAllocated = useMemo(() => !isValidating && data && data.licenseIsOverAllocated, [ data, isValidating ]);

    return (
        <>
            {isAdmin && isOverAllocated && (
                <div data-cy="ui-overallocation-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        {translate({
                            id: isHostMode
                                ? 'CLIENT_LICENSE_OVERALLOCATION_WARNING_BANNER_FOR_HOST'
                                : 'CLIENT_LICENSE_OVERALLOCATION_WARNING_BANNER',
                        })}
                        <Link
                            className={classes.learnMoreLink}
                            href={getLocalizedLink(AllocationWarning)}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </UiAlertBanner>
                </div>
            )}
        </>
    );
};
export default () => <UiOverAllocationBanner />;
