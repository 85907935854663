import {
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { SplunkConnDetails } from './ConnectionDetailsType';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

export const SplunkInputs: React.FC = () => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control, errors,
    } = useFormContext<SplunkConnDetails>();

    return (
        <>
            <div className={clsx(classes.input)}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'SPLUNK_ENDPOINT' })}
                </Typography>
                <Controller
                    as={TextField}
                    rules={{
                        required: true,
                        pattern: /http.*/,
                    }}
                    control={control}
                    type="text"
                    name="endpoint"
                    data-cy="logs-connection-endpoint"
                    variant="outlined"
                    error={!!errors.endpoint}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                />
                <FormHelperText className={clsx(classes.breakAll)}>
                    {translate({ id: 'SPLUNK_PORT_REQUIREMENT' })}
                </FormHelperText>
            </div>

            <div className={clsx(classes.input)}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'SPLUNK_AUTH_TOKEN' })}
                </Typography>
                <Controller
                    as={TextField}
                    rules={{ required: true }}
                    control={control}
                    type="password"
                    name="authToken"
                    data-cy="logs-connection-authToken"
                    variant="outlined"
                    error={!!errors.authToken}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                />
            </div>
        </>
    );
};
