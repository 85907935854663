import {
    Divider,
    Typography,
    useTheme,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import React from 'react';

export const DividerBox = ({ text }: { text: string }) => {
    const theme = useTheme();

    return (
        <>
            <Typography sx={{
                fontSize: Tokens.FontFamily.FontMSize,
                fontFamily: Tokens.FontFamily.FontNormal,
                fontWeight: Tokens.FontFamily.FontWeightSemibold,
                lineHeight: Tokens.FontFamily.FontMLineHeight,
                color: theme.palette.semantic.colorForeground,
            }}>
                { text }
            </Typography>
            <Divider sx={{
                margin: '8px 0',
                borderColor: theme.palette.semantic.colorBorderDeEmp,
            }} />
        </>
    );
};
