import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useRouteResolver,
} from '@experiences/util';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalPeoplePicker } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import {
    isEqual,
    isString,
    omit,
} from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import { Link } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import { v4 as uuidv4 } from 'uuid';

import useCheckAuthTypes from '../../../auth/hooks/CheckAuthType';
import useUserInfo from '../../../auth/hooks/UserInfo';
import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAADEnabled } from '../../../common/hooks/useCheckAADEnabled';
import useIsServicesPageEnabled from '../../../common/hooks/useIsServicesPageEnabled';
import { useLicenseExpired } from '../../../common/hooks/useLicenseExpired';
import type {
    IDirectoryEntry,
    ResolveDirectoryEntityType,
} from '../../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../../common/interfaces/cis/directory';
import type { IGroup } from '../../../common/interfaces/cis/group';
import type { ISAMLRule } from '../../../common/interfaces/cis/saml';
import type { SourceFilters } from '../../../services/identity/DirectoryService';
import type {
    ICreateGroupPayload,
    IEditGroupPayload,
    IGroupPayload,
} from '../../../services/identity/GroupService';
import {
    createGroup,
    editGroup,
    getGroupById,
} from '../../../services/identity/GroupService';
import {
    getRules,
    ruleUrl,
} from '../../../services/identity/RuleService';
import {
    accountGlobalId,
    EnableUserLicensingSelector,
    isAdminSelector,
    profile,
    userGlobalId,
} from '../../../store/selectors';
import { decodeSanitizedHtml } from '../../../util/DecodeSanitizedHtml';
import UiAlertBanner from '../../common/UiAlertBanner';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { UserGroup } from '../../common/UserGroups';
import { groupUrl } from '../GroupsPageComponent';
import { ConfirmCreateEditGroupSuccessComponent } from './SuccessMessageComponents';

const useStyles = makeStyles(theme =>
    createStyles({
        body: {
            margin: '0px 24px',
            flex: '1 1 100%',
        },
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        licenseContent: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: 'calc(100vh - 54px)',
        },
        closeButton: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: '12px',
            padding: '12px',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        },
        provisioningRuleBody: {
            fontSize: '10px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        provisioningRuleLinkContainer: { display: 'inline-flex' },
        provisioningRuleLink: {
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundLink,
        },
        spacingTop: { marginTop: '20px' },
    }),
);

interface ICreateEditGroupData {
    name: string;
    groupMembers: Array<IDirectoryEntry | string>;
}

const CreateEditGroupComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const setErrorMessage = useCentralErrorSetter();

    const { getErrorMessage } = useGetErrorInfo();

    const history = useHistory();
    const match = useRouteMatch<{ type: 'add'; id: string }>();
    const getRoute = useRouteResolver();
    const isServicesPageEnabled = useIsServicesPageEnabled();
    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const { checkTokenTypeIsAuth0: isAuth0 } = useCheckAuthTypes();
    const { token } = useUserInfo();

    const currentUserId = useSelector(userGlobalId);
    const currentProfile = useSelector(profile);

    const {
        open, close,
    } = useModalState(RouteNames.Groups);

    const {
        type, id,
    } = useMemo<{ type: 'add' | 'edit'; id: string }>(
        () => ({
            type: match.params.type === 'add' ? 'add' : 'edit',
            id: match.params.id,
        }),
        [ match ],
    );

    const AADConnectionEnabledOnOrganization = useCheckAADEnabled();
    const isExpiredLicense = useLicenseExpired();

    const dataUrl = useMemo(() => {
        const baseUrl = EnableUserLicensing && isAdmin ? `${groupUrl}/licenses` : `${groupUrl}`;
        return `${baseUrl}?partitionGlobalId=${partitionGlobalId}`;
    }, [ EnableUserLicensing, isAdmin, partitionGlobalId ]);

    const {
        data: group,
        isValidating,
        error,
    } = useSWR<IGroup, Error>(
        type === 'edit' ? [ partitionGlobalId, id ] : null,
        getGroupById,
    );

    const {
        data: rules,
        isValidating: rulesLoading,
    } = useSWR<ISAMLRule[], Error>(
        type === 'edit' ? [ ruleUrl, partitionGlobalId ] : null,
        getRules,
    );

    const [ loading, setLoading ] = useState(false);
    const [ showConfirmCreate, setShowConfirmCreate ] = useState(false);
    const [ createdGroup, setCreatedGroup ] = useState<IGroup | undefined>();

    const {
        control,
        handleSubmit,
        reset,
        errors,
        setError,
        clearErrors,
        getValues,
        watch,
        setValue,
        formState: {
            isDirty, isValid,
        },
    } = useForm<ICreateEditGroupData>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            groupMembers: [],
        },
    });

    const singleAdmin = useMemo(() =>
        group?.name === 'Administrators' && watch('groupMembers').length < 1
    , [ watch, group?.name ]);

    useEffect(() => {
        if (type === 'edit' && group) {
            reset({
                name: group.name ? decodeSanitizedHtml(group.name) : '',
                groupMembers:
                group.members?.map(member => ({
                    identifier: member.identifier,
                    identityName: member.name,
                    displayName: member.displayName,
                    email: member.email,
                    objectType: member.objectType as ResolveDirectoryEntityType,
                    source: member.source,
                    type: member.objectType?.toLowerCase().includes('group')
                        ? DirectoryEntityType.group
                        : DirectoryEntityType.user,
                    chipType: 'resolved',
                })) ?? [],
            });
        }
    }, [ reset, type, group ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const rulesForGroup = useMemo(() => rules?.filter(rule => {
        let groupsInRule: string[] = [];
        try {
            groupsInRule = JSON.parse(rule.definition as string)?.GroupsToAssign;
            // eslint-disable-next-line no-empty
        } catch {}
        return groupsInRule.includes(id) && rule.enabled;
    }), [ id, rules ]);

    const onSubmit = useCallback(
        async (data: ICreateEditGroupData) => {
            setLoading(true);

            const payload: IGroupPayload = {
                partitionGlobalId,
                name: data.name.trim(),
            };

            try {
                if (type === 'add') {
                    const createGroupPayload: ICreateGroupPayload = {
                        ...payload,
                        id: uuidv4(),
                        directoryUserMemberIDs: data.groupMembers.map(member =>
                            typeof member !== 'string' ? member.identifier : member,
                        ),
                    };

                    const isCurrentUser = isString(data.groupMembers?.[0])
                        ? (data.groupMembers as string[]).indexOf(currentUserId) > -1
                        : !!(data.groupMembers as IDirectoryEntry[]).find(g => g.identifier === currentUserId);

                    const group = await createGroup(createGroupPayload, isCurrentUser);
                    setCreatedGroup(group);
                } else {
                    const addedMembers = data.groupMembers.filter(
                        user =>
                            !group?.members?.find(x =>
                                typeof user !== 'string' ? x.identifier === user.identifier : user === x.email,
                            ),
                    );
                    const removedMembers =
                        group?.members?.filter(
                            user =>
                                !data.groupMembers
                                    .map(x => x as IDirectoryEntry)
                                    .find(dataUser => dataUser.identifier === user.identifier),
                        ) ?? [];

                    if (
                        !AADConnectionEnabledOnOrganization &&
                        group?.id === UserGroup.Administrators &&
                        addedMembers.length === 0 &&
                        removedMembers.length === group?.members.length
                    ) {
                        setError('groupMembers', { type: 'noAdmins' });
                        setLoading(false);
                        return;
                    }
                    const isAdded = !!addedMembers.find(m =>
                        typeof m !== 'string' ? m.identifier === currentUserId : m === currentProfile.emailId,
                    );
                    const isCurrentUser = isAdded || !!removedMembers.find(m => m.identifier === currentUserId);
                    const editGroupPayload: IEditGroupPayload = {
                        ...payload,
                        directoryUserIDsToAdd: addedMembers.map(member =>
                            typeof member !== 'string' ? member.identifier : member,
                        ),
                        directoryUserIDsToRemove: removedMembers.map(entry => entry.identifier),
                    };
                    await editGroup(group!.id, editGroupPayload, {
                        type: isAdded ? 'create' : 'delete',
                        isCurrentUser,
                    });

                }
            } catch (error) {
                const response = (error as any)?.response;
                if (response?.status === 409) {
                    setError('name', { type: 'conflict' });
                    setLoading(false);
                } else if (response?.status === 400 && response?.data?.length) {
                    setErrorMessage(
                        <div>
                            <Typography>
                                {translate({ id: 'CLIENT_GROUP_RESOLVE_ERROR' })}
                            </Typography>
                            <Typography>
                                {response.data.join(', ')}
                            </Typography>
                        </div>,
                    );
                    setError('groupMembers', { type: 'unresolved' });
                    setLoading(false);
                } else {
                    setErrorMessage(await getErrorMessage(error));
                }
                return;
            }

            setLoading(false);
            mutate([ dataUrl, partitionGlobalId ]);
            if (type === 'add' && (EnableUserLicensing || isServicesPageEnabled)) {
                setShowConfirmCreate(true);
            } else {
                createNotification(
                    translate(
                        { id: type === 'edit' ? 'CLIENT_GROUP_UPDATED' : 'CLIENT_GROUP_CREATED' },
                        { 0: data.name && decodeSanitizedHtml(data.name) },
                    ),
                );
                close(true);
            }
        },
        [
            partitionGlobalId,
            type,
            isServicesPageEnabled,
            EnableUserLicensing,
            currentUserId,
            group,
            AADConnectionEnabledOnOrganization,
            setError,
            currentProfile.emailId,
            setErrorMessage,
            translate,
            getErrorMessage,
            dataUrl,
            createNotification,
            close,
        ],
    );

    const peoplePickerChangedCallback = useCallback((event: any) => {
        const prevWithoutChip =
                    getValues('groupMembers')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
        const curWithoutChip = event.detail.data.map((chip: IDirectoryEntry) => omit(chip, [ 'optionId', 'chipType' ]));
        const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
        setValue('groupMembers', event.detail.data, {
            shouldDirty: valuesChanged,
            shouldValidate: valuesChanged,
        });
    }, [ getValues, setValue ]);

    const peoplePickerErrorCallback = useCallback((event: any) => {
        if (event.detail) {
            setError('groupMembers', { type: 'invalid' });
        } else {
            clearErrors('groupMembers');
        }
    }, [ clearErrors, setError ]);

    return (
        <UiDrawer
            title={
                EnableUserLicensing && showConfirmCreate
                    ? translate({ id: 'CLIENT_GROUP_SUCCESSFULLY_CREATED' })
                    : type === 'add'
                        ? translate({ id: 'CLIENT_GROUPS_ADD_GROUP' })
                        : translate({ id: 'CLIENT_GROUPS_EDIT_GROUP' })
            }
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(showConfirmCreate),
            }}
            loading={isValidating || rulesLoading}
        >
            {error ? (
                <div className={classes.body}>
                    <Typography>
                        {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
                    </Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            window.location.reload();
                        }}
                        style={{ marginTop: '12px' }}
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                </div>
            ) : showConfirmCreate ? (
                EnableUserLicensing ? (
                    <div className={classes.licenseContent}>
                        <div className={classes.body}>
                            <Typography style={{
                                fontSize: '16px',
                                marginTop: '16px',
                            }}>
                                {translate({ id: 'CLIENT_WHATS_NEXT' })}
                            </Typography>
                            <Typography style={{
                                fontWeight: 600,
                                marginTop: '8px',
                            }}>
                                {translate({ id: 'CLIENT_CREATE_LICENSE_ALLOCATION_RULE' })}
                            </Typography>
                            <Typography>
                                {translate({ id: 'CLIENT_LICENSE_ALLOCATION_SUCCESS_ONE' })}
                            </Typography>
                            <br />
                            <Typography>
                                {translate({ id: 'CLIENT_LICENSE_ALLOCATION_SUCCESS_TWO' })}
                            </Typography>
                            {createdGroup && (
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    style={{ marginTop: '12px' }}
                                    onClick={() =>
                                        history.push({
                                            pathname: `${getRoute(RouteNames.Groups)}/allocations/add`,
                                            state: {
                                                group: createdGroup,
                                                previousLocation: getRoute(RouteNames.Groups),
                                            },
                                        })}
                                    data-cy="add-edit-create-allocation-button"
                                    disabled={isExpiredLicense}
                                >
                                    {translate({ id: 'CLIENT_ALLOCATE_RULE' })}
                                </Button>
                            )}
                        </div>
                        <div className={classes.closeButton}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => close(true)}>
                                {translate({ id: 'CLIENT_CLOSE' })}
                            </Button>
                        </div>
                    </div>
                ) : (
                    <ConfirmCreateEditGroupSuccessComponent close={close} />
                )
            ) : (
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            {!showConfirmCreate && (
                                <div className={classes.actions}>
                                    <Button
                                        className={classes.cancelButton}
                                        onClick={() => close()}
                                        color="primary"
                                        data-cy='add-edit-cancel-button'>
                                        {translate({ id: 'CLIENT_CANCEL' })}
                                    </Button>
                                    <UiProgressButton
                                        loading={loading}
                                        disabled={!isDirty || !isValid || singleAdmin}
                                        onClick={handleSubmit(onSubmit)}
                                        variant="contained"
                                        data-cy="add-edit-submit-button"
                                    >
                                        {type === 'add' ? translate({ id: 'CLIENT_ADD' }) : translate({ id: 'CLIENT_SAVE' })}
                                    </UiProgressButton>
                                </div>
                            )}
                        </>
                    }
                    isDrawer
                >
                    <div data-cy="AAD-warning-text">
                        {AADConnectionEnabledOnOrganization && isAuth0 && (
                            <UiAlertBanner
                                type="info"
                                closeable={false}>
                                {translate({ id: 'CLIENT_CANNOT_SEARCH_AZURE_AD_DIRECTORY' })}
                            </UiAlertBanner>
                        )}
                    </div>

                    <div className={classes.input}>
                        <Typography
                            className={clsx(classes.inputLabel, classes.inputMargin)}
                            id="groupNameLabel">
                            {translate({ id: 'CLIENT_GROUP_NAME' })}
                        </Typography>
                        <Controller
                            as={TextField}
                            control={control}
                            rules={{
                                required: true,
                                maxLength: 256,
                            }}
                            error={!!errors.name}
                            helperText={
                                (errors.name?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                (errors.name?.type === 'maxLength' && translate({ id: 'CLIENT_GROUPS_MAX_GROUP_NAME_ERROR' })) ||
                                (errors.name?.type === 'conflict' && translate({ id: 'CLIENT_GROUP_ALREADY_EXISTS' }))
                            }
                            disabled={group?.type === 0}
                            name="name"
                            variant="outlined"
                            fullWidth
                            InputProps={{ className: 'Tall' }}
                            data-cy="add-edit-group-name"
                            inputProps={{ 'aria-labelledby': 'groupNameLabel' }}
                        />
                    </div>
                    <div
                        className={clsx(classes.input)}
                        data-cy="groups-people-picker">
                        <Controller
                            render={props => (
                                <PortalPeoplePicker
                                    token={process.buildConfigs.passPeoplePickerToken ? (token || undefined) : undefined}
                                    sourceFilters={[
                                        'localUsers',
                                        'directoryUsers',
                                        'directoryGroups',
                                        'robotAccounts',
                                        ...(process.buildConfigs.enableScopelessApp ? [ 'applications' ] : []),
                                    ] as SourceFilters[]}
                                    value={props.value}
                                    maxHeight="200px"
                                    onPeoplePickerChanged={peoplePickerChangedCallback}
                                    onPeoplePickerError={peoplePickerErrorCallback}
                                    onPeoplePickerLoading={(event: any) => setLoading(event.detail)}
                                />
                            )}
                            control={control}
                            name="groupMembers"
                        />
                        {singleAdmin &&
                        <Typography className={classes.spacingTop}>
                            {translate({ id: 'CLIENT_ONLY_ONE_ADMIN' })}
                        </Typography> }
                    </div>
                    {!!rulesForGroup?.length &&
                        <div className={classes.input}>
                            <Typography className={clsx(classes.inputLabel)}>
                                {translate({ id: 'CLIENT_SAML_JIT_PROVISIONING_RULES' }, { count: rulesForGroup.length })}
                            </Typography>
                            <Typography>
                                {translate({ id: 'CLIENT_SAML_JIT_PROVISIONING_RULES_DESCRIPTION' })}
                            </Typography>
                            <Typography className={classes.provisioningRuleLinkContainer}>
                                {rulesForGroup
                                    .map((rule, index) =>
                                        <React.Fragment key={`${rule.name}-${rule.id}`}>
                                            <Link
                                                className={classes.provisioningRuleLink}
                                                to={getRoute(`${RouteNames.SecuritySettingsSAMLProvisioningRules}/edit/${rule.id}`)}>
                                                {rule.name}
                                            </Link>
                                            {index !== rulesForGroup.length - 1 &&
                                            <Typography>
                                                ,&nbsp;
                                            </Typography>}
                                        </React.Fragment>)}
                            </Typography>
                        </div>}
                </UiForm>
            )}
        </UiDrawer>
    );
};

export default CreateEditGroupComponent;
