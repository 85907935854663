import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import getUsersInPartition from '../../services/identity/UserPartitionService';
import { accountGlobalId } from '../../store/selectors';
import { UserPartition } from '../constants/Constant';

const useIsEmailTaken = (originalEmail?: string) => {
    const partitionGlobalId = useSelector(accountGlobalId);

    return useCallback(
        async (email: string) => {
            const response = await getUsersInPartition(
                {
                    top: UserPartition.MAX_RETURNED_USERS,
                    skip: 0,
                    searchTerm: email,
                },
                partitionGlobalId,
            );
            return (
                email.toLowerCase() !== originalEmail?.toLocaleLowerCase() &&
        response.results.some(result => result.email?.toLowerCase() === email.toLowerCase())
            );
        },
        [ originalEmail, partitionGlobalId ],
    );
};

export default useIsEmailTaken;
