import {
    getPublishersWithGroupedTopics,
    getPublishersWithSeverity,
    getPublisherWithGroupedTopics,
    isPublisherMandatoryIndeterminate,
    isPublisherModeIndeterminate,
    isPublisherModeMandatory,
    isPublisherModeSubscribed,
    isPublisherModeVisible,
    isTopicGroupMandatoryIndeterminate,
    isTopicGroupModeIndeterminate,
    isTopicGroupModeMandatory,
    isTopicGroupModeSubscribed,
    isTopicGroupModeVisibleOption,
} from '../helpers/topicsGrouper';
import type {
    INotificationMode,
    INotificationPublisherDto,
    INotificationSettingsAction,
    INotificationSettingsActionRestoreDefaultData,
    INotificationSettingsActionUpdateModeData,
    INotificationSettingsActionUpdateTabIndexData,
    INotificationSettingsActionUpdateTopicData,
    INotificationSettingsReducer,
    INotificationSeverityModeData,
    INotificationTopic,
} from '../interfaces/notificationSettings';
import { NotificationMode } from '../interfaces/notificationSettings';
import { ActionTypes as ACTION_TYPE } from './actionTypes';

export const initialNotificationSettingsReducerState = {
    tabIndex: 0,
    publishers: [],
    publishersWithGroupedTopics: [],
    publishersWithSeverity: [],
} as INotificationSettingsReducer;

export const notificationSettingsReducer =
(state: INotificationSettingsReducer, action: INotificationSettingsAction): INotificationSettingsReducer => {
    switch (action.type) {
        case ACTION_TYPE.NS_INITIALIZE_NOTIFICATION_SETTINGS: {
            const publisherData = action.data as INotificationPublisherDto[];
            const publisherDataGroupd = getPublishersWithGroupedTopics(publisherData);
            return {
                ...state,
                publishers: publisherData,
                publishersWithGroupedTopics: publisherDataGroupd,
                tabIndex: state.tabIndex >= publisherData.length ? 0 : state.tabIndex,
                publishersWithSeverity: getPublishersWithSeverity(publisherDataGroupd),
            };
        }
        case ACTION_TYPE.NS_SET_TAB_INDEX: {
            const { tabIndex } = action.data as INotificationSettingsActionUpdateTabIndexData;
            return {
                ...state,
                tabIndex,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION: {
            return toggleTopicSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed, mode,
            } = action.data as INotificationSettingsActionUpdateTopicData;

            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];
            const topic = topicGroup.topics[topicIndex!];
            topic.isSubscribed = isSubscribed!;
            topic.isUpdating = false;

            const topicMode = topic.modes?.find((m: INotificationMode) => m.name === mode);
            if (topicMode) {
                topicMode.isSubscribed = isSubscribed!;
                topicMode.isUpdating = false;
            }

            const topicGroupMode = topicGroup.modes?.find((m: INotificationMode) => m.name === mode);
            if (topicGroupMode) {
                topicGroupMode.isSubscribed = isTopicGroupModeSubscribed(mode!, topicGroup);
                topicGroupMode.isIndeterminate = isTopicGroupModeIndeterminate(mode!, topicGroup);
            }

            const publisherMode = publisher.modes?.find((m: INotificationMode) => m.name === mode);
            if (publisherMode) {
                publisherMode.isSubscribed = isPublisherModeSubscribed(mode!, publisher);
                publisherMode.isIndeterminate = isPublisherModeIndeterminate(mode!, publisher);
            }
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed, mode,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];

            topicGroup.topics.filter(x => x.isVisible).forEach((topic: INotificationTopic) => {
                topic.isSubscribed = isSubscribed!;
                const topicMode = topic.modes?.find(m => m.name === mode);
                if (topicMode) {
                    topicMode.isSubscribed = isSubscribed!;
                }
            });

            topicGroup.isUpdating = false;
            const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
            if (topicGroupMode) {
                topicGroupMode.isUpdating = false;
                topicGroupMode.isSubscribed = isSubscribed!;
                topicGroupMode.isIndeterminate = false;
            }

            const publisherMode = publisher.modes?.find((m: INotificationMode) => m.name === mode);
            if (publisherMode) {
                publisherMode.isSubscribed = isPublisherModeSubscribed(mode!, publisher);
                publisherMode.isIndeterminate = isPublisherModeIndeterminate(mode!, publisher);
            }
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION: {
            return togglePublisherSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, mode, isSubscribed,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            publisher.isUpdating = true;
            const publisherMode = publisher.modes.find(m => m.name === mode);
            publisherMode!.isUpdating = false;
            publisherMode!.isSubscribed = isSubscribed;
            publisherMode!.isIndeterminate = false;

            publisher.topicGroups.filter(x => x.isVisible).forEach(topicGroup => {
                const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
                if (topicGroupMode) {
                    topicGroupMode.isSubscribed = isSubscribed!;
                    topicGroupMode.isIndeterminate = false;
                }

                topicGroup.topics.filter(x => x.isVisible).forEach((topic: INotificationTopic) => {
                    topic.isSubscribed = isSubscribed!;
                    const topicMode = topic.modes?.find(m => m.name === mode);
                    if (topicMode) {
                        topicMode.isSubscribed = isSubscribed!;
                    }
                });
            });
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_RESET: {
            const {
                publisherIndex, isSubscribed,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            publisher.isMandatory = false;
            publisher.isMandatoryInDeterminate = false;
            publisher.isVisible = true;
            publisher.isUpdating = true;
            publisher.modes.forEach(publisherMode => {
                publisherMode!.isUpdating = false;
                publisherMode!.isSubscribed = isSubscribed;
                publisherMode!.isIndeterminate = false;
            });

            publisher.topicGroups.forEach(topicGroup => {
                topicGroup.isMandatoryChecked = false;
                topicGroup.isMandatoryIndeterminate = false;
                topicGroup.isVisible = true;
                topicGroup.isUpdating = false;

                topicGroup.modes.forEach(topicGroupMode => {
                    topicGroupMode.isIndeterminate = false;
                    topicGroupMode.isSubscribed = isSubscribed;
                    topicGroupMode.isUpdating = false;
                });

                topicGroup.topics.forEach((topic: INotificationTopic) => {
                    topic.isSubscribed = isSubscribed!;
                    topic.isMandatory = false;
                    topic.isVisible = true;
                    topic.modes?.forEach(topicmode => {
                        topicmode.isSubscribed = isSubscribed!;
                        topicmode.isIndeterminate = false;
                    });

                });
            });
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_UPDATE_FAILED: {
            return togglePublisherSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE: {
            return toggleChannelModeUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE_UPDATED: {
            const {
                publisherIndex, modeIndex, newModeStatus,
            } = action.data as INotificationSettingsActionUpdateModeData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

            updatedPublishersWithGroupedTopics[publisherIndex].modes[modeIndex].isActive = newModeStatus!;
            updatedPublishersWithGroupedTopics[publisherIndex].modes[modeIndex].isUpdating = false;

            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_CHANNEL_MODE_UPDATE_FAILED: {
            return toggleChannelModeUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS: {
            return toggleRestoreSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATED: {
            const {
                publisherIndex, resetData,
            } = action.data as INotificationSettingsActionRestoreDefaultData;
            const updatedRawPublishers = state.publishers;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

            updatedRawPublishers[publisherIndex] = resetData![0];
            const newGroupedData = getPublishersWithGroupedTopics(updatedRawPublishers);
            updatedPublishersWithGroupedTopics[publisherIndex] = newGroupedData[publisherIndex];
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishers: updatedRawPublishers,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED: {
            return toggleRestoreSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY: {
            return toggleTopicGroupSeveritySubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED: {
            const {
                publisherid,
                severity,
                isSubscribed,
                mode,
                groupIndex,
            } = action.data as INotificationSeverityModeData;

            const publisher = state.publishers.find(p => p.id === publisherid);
            publisher?.topics.forEach((topic) => {
                if (topic.category === severity && !topic.isMandatory) {
                    topic.isSubscribed = isSubscribed;
                    const topicMode = topic.modes?.find(m => m.name === mode);
                    if (topicMode) {
                        topicMode.isSubscribed = isSubscribed!;
                    }
                }
            });
            if (publisher !== undefined) {
                const topicGroups = getPublisherWithGroupedTopics(publisher);
                const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
                const index = updatedPublishersWithGroupedTopics.findIndex(x => x.publisherId === publisherid);
                topicGroups.publisherIndex = index;
                updatedPublishersWithGroupedTopics[index] = topicGroups;
                const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
                return {
                    ...state,
                    publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                    publishersWithSeverity,
                };
            }
            return { ...state };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_SUBSCRIPTION_SEVERITY_UPDATED_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY: {
            return toggleTopicSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed,
            } = action.data as INotificationSettingsActionUpdateTopicData;

            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];
            const topic = topicGroup.topics[topicIndex!];
            topic.isMandatory = isSubscribed!;
            topic.isUpdating = false;
            topicGroup.modes = Object.values(NotificationMode)
                .map(modeName => ({
                    name: modeName,
                    isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                    isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
                }));
            topicGroup.isMandatoryChecked = isTopicGroupModeMandatory(topicGroup);
            topicGroup.isMandatoryIndeterminate = isTopicGroupMandatoryIndeterminate(topicGroup);
            publisher.isMandatory = isPublisherModeMandatory(publisher);
            publisher.isMandatoryInDeterminate = isPublisherMandatoryIndeterminate(publisher);
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_MANDATORY_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed, mode,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];

            topicGroup.topics.filter(x => x.isVisible).forEach((topic: INotificationTopic) => {
                topic.isMandatory = isSubscribed!;
            });

            topicGroup.isUpdating = false;
            const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);

            topicGroup.isUpdating = false;
            topicGroup.isMandatoryChecked = isSubscribed!;
            topicGroup.isMandatoryIndeterminate = false;
            const publisherMode = publisher.modes?.find((m: INotificationMode) => m.name === mode);
            topicGroup.modes = Object.values(NotificationMode)
                .map(modeName => ({
                    name: modeName,
                    isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                    isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
                }));
            publisher.isMandatory = isPublisherModeMandatory(publisher);
            publisher.isMandatoryInDeterminate = isPublisherMandatoryIndeterminate(publisher);
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_MANDATORY_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION: {
            return togglePublisherSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, isSubscribed,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            publisher.isUpdating = true;
            publisher!.isMandatory = isSubscribed!;
            publisher!.isMandatoryInDeterminate = false;

            publisher.topicGroups.filter(x => x.isVisible)
                .forEach(topicGroup => {
                    topicGroup.isMandatoryChecked = isSubscribed!;
                    topicGroup.isMandatoryIndeterminate = false;

                    topicGroup.topics.filter(x => x.isVisible).forEach((topic: INotificationTopic) => {
                        topic.isMandatory = isSubscribed!;
                    });
                    topicGroup.modes = Object.values(NotificationMode)
                        .map(modeName => ({
                            name: modeName,
                            isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                            isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
                        }));
                });
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_PUBLISHER_MANDATORY_SUBSCRIPTION_UPDATE_FAILED: {
            return togglePublisherSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE: {
            return toggleTopicSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATED: {
            const {
                publisherIndex, groupIndex, topicIndex, isSubscribed,
            } = action.data as INotificationSettingsActionUpdateTopicData;

            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];
            const topic = topicGroup.topics[topicIndex!];
            topic.isVisible = isSubscribed!;
            topic.isMandatory = false;
            topic.isSubscribed = false;
            topic.modes?.forEach(mode => {
                mode.isSubscribed = false;
                mode.isIndeterminate = false;
            });
            topic.isUpdating = false;
            topicGroup.isVisible = isTopicGroupModeVisibleOption(topicGroup);
            topicGroup.modes = Object.values(NotificationMode)
                .map(modeName => ({
                    name: modeName,
                    isSubscribed: isTopicGroupModeSubscribed(modeName, topicGroup),
                    isIndeterminate: isTopicGroupModeIndeterminate(modeName, topicGroup),
                }));
            topicGroup.isMandatoryChecked = isTopicGroupModeMandatory(topicGroup);
            topicGroup.isMandatoryIndeterminate = isTopicGroupMandatoryIndeterminate(topicGroup);
            publisher.isVisible = isPublisherModeVisible(publisher);
            publisher.isMandatory = isPublisherModeMandatory(publisher);
            publisher.isMandatoryInDeterminate = isPublisherMandatoryIndeterminate(publisher);
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_CHANGE_TOPIC_SUBSCRIPTION_VISIBLE_UPDATE_FAILED: {
            return toggleTopicSubscriptionUpdate(action, state, false);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION: {
            return toggleTopicGroupSubscriptionUpdate(action, state, true);
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATED: {
            const {
                publisherIndex, groupIndex, isSubscribed, mode,
            } = action.data as INotificationSettingsActionUpdateTopicData;
            const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
            const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
            const topicGroup = publisher.topicGroups[groupIndex!];

            topicGroup.topics.forEach((topic: INotificationTopic) => {
                topic.isVisible = isSubscribed!;
                topic.isMandatory = false;
                topic.isSubscribed = false;
                topic.modes?.forEach(topicMode => {
                    topicMode.isSubscribed = false;
                    topicMode.isIndeterminate = false;
                });
            });
            topicGroup.isMandatoryChecked = false;
            topicGroup.isMandatoryIndeterminate = false;
            topicGroup.modes.forEach(topicGroupMode => {
                topicGroupMode.isSubscribed = false;
                topicGroupMode.isIndeterminate = false;
            });
            topicGroup.isUpdating = false;
            topicGroup.isVisible = isSubscribed!;
            publisher.modes.forEach(publisherGroupMode => {
                publisherGroupMode.isSubscribed = false;
                publisherGroupMode.isIndeterminate = false;
            });
            publisher.isVisible = isPublisherModeVisible(publisher);
            publisher.isMandatory = isPublisherModeMandatory(publisher);
            publisher.isMandatoryInDeterminate = isPublisherMandatoryIndeterminate(publisher);
            const publishersWithSeverity = getPublishersWithSeverity(updatedPublishersWithGroupedTopics);
            return {
                ...state,
                publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
                publishersWithSeverity,
            };
        }
        case ACTION_TYPE.NS_TOGGLE_TOPIC_GROUP_VISIBLE_SUBSCRIPTION_UPDATE_FAILED: {
            return toggleTopicGroupSubscriptionUpdate(action, state, false);
        }
        default: {
            return state;
        }
    }
};

function toggleTopicSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, topicIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;

    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
    const topic = updatedPublishersWithGroupedTopics[publisherIndex].topicGroups[groupIndex!].topics[topicIndex!];
    topic.isUpdating = isUpdating;
    if (mode !== undefined) {
        const topicMode = topic.modes?.find((m: INotificationMode) => m.name === mode);

        if (topicMode) {
            topicMode.isUpdating = isUpdating;
        }
    }
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleTopicGroupSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
    const topicGroup = updatedPublishersWithGroupedTopics[publisherIndex].topicGroups[groupIndex!];
    if (mode !== undefined) {
        topicGroup.isUpdating = isUpdating;
        const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
        if (topicGroupMode) {
            topicGroupMode.isUpdating = isUpdating;
        }
    }
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}
function toggleTopicGroupSeveritySubscriptionUpdate(action: INotificationSettingsAction,
    state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, groupIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithSeverity ];
    const topicGroup = updatedPublishersWithGroupedTopics[publisherIndex].topicGroups[groupIndex!];
    topicGroup.isUpdating = isUpdating;
    const topicGroupMode = topicGroup.modes?.find(m => m.name === mode);
    if (topicGroupMode) {
        topicGroupMode.isUpdating = isUpdating;
    }
    return {
        ...state,
        publishersWithSeverity: updatedPublishersWithGroupedTopics,
    };
}
function togglePublisherSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, mode,
    } = action.data as INotificationSettingsActionUpdateTopicData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];
    const publisher = updatedPublishersWithGroupedTopics[publisherIndex];
    publisher.isUpdating = isUpdating;
    if (mode !== undefined) {
        publisher.modes.find(m => m.name === mode)!.isUpdating = isUpdating;
    }
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleChannelModeUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const {
        publisherIndex, modeIndex,
    } = action.data as INotificationSettingsActionUpdateModeData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

    updatedPublishersWithGroupedTopics[publisherIndex].modes[modeIndex].isUpdating = isUpdating;

    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

function toggleRestoreSubscriptionUpdate(action: INotificationSettingsAction, state: INotificationSettingsReducer, isUpdating: boolean) {
    const { publisherIndex } = action.data as INotificationSettingsActionRestoreDefaultData;
    const updatedPublishersWithGroupedTopics = [ ...state.publishersWithGroupedTopics ];

    updatedPublishersWithGroupedTopics[publisherIndex].modes.forEach((mode) => {
        mode.isUpdating = isUpdating;
    });
    updatedPublishersWithGroupedTopics[publisherIndex].isUpdating = isUpdating;
    return {
        ...state,
        publishersWithGroupedTopics: updatedPublishersWithGroupedTopics,
    };
}

