import { RegionFriendlyNameMap } from '@experiences/constants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import type { IService } from '../../../../common/interfaces/tenant/tenant';
import type { IServiceMetadata } from '../../interfaces/service';
import { TenantStatusConstants } from '../../TenantConstants';
import type { IServiceRegionMap } from '../../TenantsPageComponent';
import { getListOfDependencies } from './ServiceDependencyGraph';

const useStyles = makeStyles(theme =>
    createStyles({
        icon: {
            width: '20px',
            height: '20px',
            marginLeft: '6px',
        },
        licenseIcon: { color: theme.palette.semantic.colorIconDefault },
        altRegionIcon: { color: theme.palette.semantic.colorIconDefault },
        noFallbackIcon: { color: theme.palette.semantic.colorIconDefault },
    }),
);

export type ServiceErrorType =
  | 'license'
  | 'altRegion'
  | 'shouldDisable'
  | 'shouldDisableRevamp'
  | 'unsupported'
  | 'noFallback'
  | 'missingDependency'
  | 'dependencyDisabled';

interface IErrorProps {
    message: string;
    params?: { [key: number]: any };
    className: string;
    Icon: any;
}

const TenantServiceErrorMessage: React.FC<{
    errors: Record<ServiceErrorType, string[]>;
    service: IServiceMetadata;
    availableServices: IServiceMetadata[];
    currentServices: string[];
    servicesAltRegion?: IServiceRegionMap;
    services: IService[];
}> = ({
    errors, service, availableServices, currentServices, servicesAltRegion, services,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const ErrorTranslationParams: Record<string, IErrorProps> = useMemo(
        () => ({
            license: {
                message: 'CLIENT_TENANT_NO_LICENSES_FOR_SERVICE',
                Icon: InfoOutlinedIcon,
                className: classes.licenseIcon,
            },
            altRegion: {
                message: 'CLIENT_HOSTED_IN',
                params: { 0: servicesAltRegion && RegionFriendlyNameMap[servicesAltRegion[service.id]] },
                Icon: LanguageOutlined,
                className: classes.altRegionIcon,
            },
            noFallback: {
                message: 'CLIENT_TENANT_UNSUPPORTED_REGION',
                params: { 0: service.name },
                Icon: InfoOutlinedIcon,
                className: classes.noFallbackIcon,
            },
            missingDependency: {
                message: 'CLIENT_TENANT_MISSING_DEPENDENCY',
                params: {
                    0: service.name,
                    1: getListOfDependencies(service.id)
                        .filter(s => !currentServices?.includes(s))
                        .map(s => availableServices.find(k => k.id === s)?.name ?? '')
                        .filter(s => !!s)
                        .join(', '),
                },
                Icon: InfoOutlinedIcon,
                className: classes.altRegionIcon,
            },
            dependencyDisabled: {
                message: 'CLIENT_TENANT_DISABLED_DEPENDENCY',
                params: {
                    0: service.name,
                    1: getListOfDependencies(service.id)
                        .filter(s => services?.find(t => t.serviceType === s)?.status.toUpperCase() === TenantStatusConstants.DISABLED)
                        .map(s => availableServices.find(k => k.id === s)?.name ?? '')
                        .filter(s => !!s)
                        .join(', '),
                },
                Icon: InfoOutlinedIcon,
                className: classes.altRegionIcon,
            },
        }),
        [
            classes.licenseIcon,
            classes.altRegionIcon,
            classes.noFallbackIcon,
            servicesAltRegion,
            service.id,
            service.name,
            currentServices,
            availableServices,
            services,
        ],
    );

    const ErrorProps = useMemo(() => {
        const error = Object.keys(errors).find(
            k => k === 'missingDependency' && isAdminRevampEnabled
                ? false
                : (errors[k as ServiceErrorType].indexOf(service.id) > -1 && ErrorTranslationParams[k]),
        );

        return error && ErrorTranslationParams[error];
    }, [ ErrorTranslationParams, errors, isAdminRevampEnabled, service.id ]);

    if (!ErrorProps) {
        return <></>;
    }

    return (
        <Tooltip title={translate({ id: ErrorProps.message }, ErrorProps.params)}>
            <ErrorProps.Icon
                className={clsx(classes.icon, ErrorProps.className)}
                data-cy={`${service.id}-service-warning`} />
        </Tooltip>
    );
};

export default TenantServiceErrorMessage;
