import type { IDirectoryConnectionDto } from '../common/interfaces/directoryConnection';
import type { IADDirectoryConnectionData } from '../component/authsettings/subcomponents/SecuritySettingsADForm';
import type { IDirectoryConnectionPayload } from '../services/identity/DirectoryConnectionService';

export const defaultADDirectoryConnectionData: IADDirectoryConnectionData = {
    domain: '',
    username: '',
    password: '',
    ldaps: false,
    useKerberosAuth: true,
};

export const mapADDataToDirectoryConnectionDto = (data: IADDirectoryConnectionData): IDirectoryConnectionDto => ({
    Domain: data.domain ?? defaultADDirectoryConnectionData.domain,
    Username: data.username?.trim() ?? defaultADDirectoryConnectionData.username,
    Password: data.password ?? defaultADDirectoryConnectionData.password,
    Ldaps: data.ldaps ?? defaultADDirectoryConnectionData.ldaps,
    UseKerberosAuth: data.useKerberosAuth ?? defaultADDirectoryConnectionData.useKerberosAuth,
});

export const mapDirectoryConnectionToADConfigData = (payload: IDirectoryConnectionPayload): IADDirectoryConnectionData => {
    const dto: IDirectoryConnectionDto = JSON.parse(payload.configuration);
    return {
        domain: dto.Domain,
        username: dto.Username,
        ldaps: dto.Ldaps,
        password: '', // Do not include password since it is taken care of by a placeholder.
        useKerberosAuth: dto.UseKerberosAuth,
    };
};
