import { useEcommerceTelemetry } from '@experiences/ecommerce';
import { useRouteResolver } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import type { ILicenseAndKey } from '../../common/interfaces/licenses';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import UiAlertBanner from '../common/UiAlertBanner';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        link: { marginLeft: '8px' },
    }),
);

export const EcommerceAlertBarComponent = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const logEcommerceEvent = useEcommerceTelemetry();

    const {
        data: licenseAndKey, isValidating: loading,
    } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const timeLeft = useMemo(() => {
        if (loading || !licenseAndKey) {
            return '';
        }

        const hour = 60 * 60 * 1000;
        const differenceInHours = Math.round(
            (new Date(licenseAndKey?.accountLicense.endDate * 1000).getTime() - new Date().getTime()) / hour,
        );

        if (differenceInHours > 24) {
            const differenceInDays = Math.round(differenceInHours / 24);
            return translate({ id: 'CLIENT_DAYS' }, { 0: differenceInDays });
        } else if (differenceInHours > 0) {
            return translate({ id: 'CLIENT_HOURS' }, { 0: differenceInHours });
        }
        return '';

    }, [ licenseAndKey, loading, translate ]);

    return (
        <>
            <UiAlertBanner
                type="info"
                closeable={false}
                enterpriseTrialAlertBar>
                <div className={classes.root}>
                    {translate({ id: 'CLIENT_YOUR_TRIAL_ENDS_IN' }, { 0: timeLeft })}
                    <RouterLink
                        className={clsx(classes.link)}
                        data-cy="trial-banner-buy-enterprise-button"
                        onClick={() => {
                            logEcommerceEvent('Licenses.UpgradeToEnterprise', { InvocationMethod: 'Trial Banner' });
                        }}
                        to={getRoute(RouteNames.BuyPro)}
                    >
                        {translate({ id: 'CLIENT_BUY_NOW' })}
                    </RouterLink>
                </div>
            </UiAlertBanner>
        </>
    );
};
