import {
    AccountLicense,
    TelemetryTitle,
    TranslatedRegionName,
} from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import {
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import moment from 'moment';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import useSWR from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import type {
    IOrganizationMigrationStatus,
    IScheduledOrganizationMigrationStatus,
} from '../../../common/interfaces/gws';
import {
    getOrgMigrationStatuses,
    getScheduledOrgMigrationStatus,
} from '../../../services/global-worklow-service/OrgMigration';
import { getOrganizationData } from '../../../services/organization/OrganizationService';
import { updateOrgRegion } from '../../../store/action/UserProfileAction';
import {
    accountCreatedOn,
    accountLogicalName,
    organizationRegion,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        moveOrganizationBox: {
            marginTop: '24px',
            marginBottom: '20px',
            margin: '0 2px',
        },
        moveOrganizationBoxDisabled: { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
        moveOrganizationTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        moveOrganizationTitleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '16px',
        },
        moveOrganizationDetails: {
            fontSize: '14px',
            fontWeight: 600,
            marginLeft: '12px',
        },
        requestMove: {
            display: 'flex',
            flexDirection: 'row',
            width: 'inherit',
            alignItems: 'center',
            outline: 'solid 1px',
            marginTop: '16px',
            borderRadius: '2px',
            outlineColor: theme.palette.semantic.colorBorderDeEmp,
            padding: '5px',
            position: 'relative',
            height: '44px',
        },
        moveButtonWrapper: {
            position: 'absolute',
            right: '4px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        tooltip: { align: 'center' },
        chipSpacer: { marginLeft: '8px' },
    }),
}),
);

const OrganizationMigration: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const orgCreatedOn = moment(useSelector(accountCreatedOn));
    const location = useLocation();
    const EnableScheduledMigration = getFeatureFlagValue(Features.EnabledScheduledOrgMigration.name);
    const { formatMessage: translate } = useIntl();
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const { logEvent } = useTelemetryHelper();
    const { token } = useUserInfo();
    const dispatch = useDispatch();

    const currentAccountName = useSelector(accountLogicalName);
    const currentRegion = useSelector(organizationRegion);

    const [ isMigrationRunning, setIsMigrationRunning ] = useState< boolean | undefined >();
    const [ orgMigrationStatus, setOrgMigrationStatus ] = useState< string | undefined >();

    useEffect(() => {
        const updateOrg = async () => {
            const orgInfoPromise = await getOrganizationData(token);
            if (currentRegion !== orgInfoPromise.region) {
                dispatch(updateOrgRegion(orgInfoPromise.region));
            }
        };
        updateOrg();
    }, [ currentRegion, dispatch, token ]);

    const { data: orgMigrationStatuses } = useSWR<IOrganizationMigrationStatus[], Error>(
        !EnableScheduledMigration ? [ currentAccountName, 'getOrgMigrationStatuses' ] : null,
        getOrgMigrationStatuses,
    );

    const {
        data: scheduledOrgMigrationStatus, isValidating: isScheduledMigrationStatusLoading,
    } = useSWR<IScheduledOrganizationMigrationStatus, Error>(
        EnableScheduledMigration ? [ currentAccountName, 'getScheduledOrgMigrationStatus' ] : null,
        getScheduledOrgMigrationStatus,
    );
    // Migrations can't be started in the first hour after the org has been created
    const isUnderOrgCreationMigrationBackoff = useMemo(() => moment.duration(moment().diff(orgCreatedOn)).asHours() < 1, [ orgCreatedOn ]);
    const navigateToMigration = useCallback(async () => {
        if (isFreeOrCommunityRevamp) {
            logEvent('OffersRevampSelfServeMigration.Click', {
                AdminRevampEnabled: isAdminRevampEnabled,
                URL: location,
            });
            history.push(getRoute(RouteNames.BuyPro));
            return;
        }
        logEvent('OrgSelfServeMigration.RequestMove', { ServerRegion: currentRegion });
        if (orgMigrationStatus === 'Scheduled') {
            history.push(getRoute(RouteNames.EditOrganizationMigration), scheduledOrgMigrationStatus);
        } else {
            history.push(getRoute(RouteNames.OrganizationMigration));
        }
    }, [
        currentRegion,
        getRoute,
        history,
        isAdminRevampEnabled,
        isFreeOrCommunityRevamp,
        location,
        logEvent,
        orgMigrationStatus,
        scheduledOrgMigrationStatus,
    ]);

    useEffect(() => {
        setOrgMigrationStatus(scheduledOrgMigrationStatus?.migrationAvailability);
    }, [ scheduledOrgMigrationStatus ]);

    useEffect(() => {
        const IsOrganizationLocked = orgMigrationStatuses?.some(migrationStatus => !migrationStatus.isFinished);
        setIsMigrationRunning(IsOrganizationLocked);
    }, [ orgMigrationStatuses ]);

    const renderRequestMoveButtonText = useCallback(() => {
        if (isUnderOrgCreationMigrationBackoff) {
            return translate({ id: 'CLIENT_JOBSTATUS_INPROGRESS' });
        }
        if (!EnableScheduledMigration) {
            return (isMigrationRunning
                ? translate({ id: 'CLIENT_JOBSTATUS_INPROGRESS' })
                : translate({ id: 'CLIENT_REQUEST_MOVE' }));
        }
        if (orgMigrationStatus === 'Enabled') {
            return translate({ id: 'CLIENT_REQUEST_MOVE' });
        }
        if (orgMigrationStatus === 'Scheduled') {
            return translate({ id: 'CLIENT_MIGRATION_SCHEDULED' });
        }
        if (orgMigrationStatus === 'Running') {
            return translate({ id: 'CLIENT_MIGRATION_RUNNING' });
        }
        if (orgMigrationStatus === 'PendingFinalization') {
            return (<>
                {translate({ id: 'CLIENT_MIGRATION_COMPLETED' })}

            </>);

        }
        return translate({ id: 'CLIENT_REQUEST_MOVE' });
    }, [ EnableScheduledMigration, isMigrationRunning, orgMigrationStatus, translate ]);

    const isMigrationButtonDisabled = useCallback(() => {
        if (isUnderOrgCreationMigrationBackoff) {
            return true;
        }
        if (!EnableScheduledMigration) {
            return isMigrationRunning;
        }
        return orgMigrationStatus === 'Running' || orgMigrationStatus === 'PendingFinalization';
    }, [ EnableScheduledMigration, isMigrationRunning, orgMigrationStatus ]);
    return (
        <div
            className={classes.moveOrganizationBox}
            data-cy="organization-migration-section">
            <div className={classes.moveOrganizationTitleSection}>
                <Typography
                    className={classes.moveOrganizationTitle}
                    data-cy="organization-migration-settings"
                >
                    {translate({ id: 'CLIENT_SETTINGS_MIGRATION' })}
                </Typography>
                {isFreeOrCommunityRevamp &&
                            <UiUpgradeChip
                                className={classes.chipSpacer}
                                licenseType={AccountLicense.PRO}
                                title={translate({ id: 'CLIENT_CHIP_MIGRATION_HEADER' })}
                                description={translate({ id: 'CLIENT_CHIP_MIGRATION_DESCRIPTION' })}
                                iconDescription={translate({ id: 'CLIENT_CHIP_MIGRATION_ICON_DESCRIPTION' })}
                                icon={<LanguageOutlinedIcon />}
                                telemetryTitle={TelemetryTitle.OrganizationSettings.Advanced} />}
            </div>
            <div className={clsx(classes.requestMove, isFreeOrCommunityRevamp && classes.moveOrganizationBoxDisabled)}>
                <Typography
                    className={classes.moveOrganizationDetails}
                    data-cy="organization-migration-title">
                    {translate({ id: TranslatedRegionName[currentRegion] ?? 'CLIENT_NONE' })}
                </Typography>
                {!isFreeOrCommunityRevamp && (
                    <span className={classes.moveButtonWrapper}>
                        <UiProgressButton
                            disabled={isMigrationButtonDisabled()}
                            size="small"
                            loading={isScheduledMigrationStatusLoading}
                            onClick={navigateToMigration}
                            data-cy="organization-migration-button"
                            variant="text"
                        >
                            {renderRequestMoveButtonText()}
                        </UiProgressButton>
                        {(orgMigrationStatus === 'PendingFinalization' || isUnderOrgCreationMigrationBackoff) && (
                            <Tooltip
                                data-cy="organization-migration-tooltip"
                                className={classes.tooltip}
                                title={isUnderOrgCreationMigrationBackoff ?
                                    translate({ id: 'CLIENT_MIGRATION_TOO_SOON' })
                                    : translate({ id: 'CLIENT_MIGRATION_COMPLETED_TOOLTIP' })}>
                                <InfoOutlinedIcon fontSize='small' />
                            </Tooltip>)}
                    </span>
                )}
            </div>
        </div>
    );
};

export default OrganizationMigration;
