import {
    Features,
    getFeatureFlagConfig,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiHomePage } from '@experiences/home-page';
import type { Persona } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import {
    ENABLED_PERSONAS,
    isHomePageRevampEnabled,
} from '@experiences/util';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from '@mui/styles';
import React, {
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import useUserInfo from '../../auth/hooks/UserInfo';
import { hyperLink } from '../../common/constants/Constant';
import { useCheckSSOEnabled } from '../../common/hooks/useCheckSSOEnabled';
import useIsOnPrem from '../../common/hooks/useIsOnPrem';
import useProfileStateName from '../../common/hooks/useProfileStateName';
import {
    accountGlobalId,
    accountLogicalName,
    groupIdsForUser,
    isUnlicensedSelector,
    profile,
} from '../../store/selectors';
import Carousel from '../carousel/CarouselComponent';
import UiAlertBanner from '../common/UiAlertBanner';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import { UiWidgetContainer } from '../common/UiWidgetContainer';
import { FREBannerHelper } from './helper/FREBannerHelper';
import { HomeWidgets } from './helper/HomeWidgets';
import { HomePageLicensesComponent } from './HomePageLicensesComponent';
import HomePageSidebarLinksComponent from './HomePageSidebarLinksComponent';

const useStyles = makeStyles((theme) => GlobalStyles(theme));

const HomePageComponent = () => {
    const classes = useStyles();

    const [ firstName, lastName ] = useProfileStateName();
    const { formatMessage: translate } = useIntl();
    const { token } = useUserInfo();

    const organizationName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const currentGroupIds = useSelector(groupIdsForUser);
    const userProfile = useSelector(profile);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const isOnPrem = useIsOnPrem();
    const { checkTokenTypeIsAuth0: isAuth0 } = useCheckAuthTypes();

    const EnableHomePageCarousel = useFeatureFlagValue(
        Features.EnableHomePageCarousel.name,
    );

    const EnableHomePageProTrial = useFeatureFlagValue(
        Features.EnableHomePageProTrial.name,
    );

    const DisableProtrialCarousel = useFeatureFlagValue(
        Features.DisableProtrialCarousel.name,
    );

    const isProTrial = userProfile.accountUserDto.accountType === 'PRO-TRIAL';
    const enableHomePageForProtrial = EnableHomePageProTrial && isProTrial;
    const disableProtrialCarousel = DisableProtrialCarousel && isProTrial;

    const EnableContentfulSideBar = useFeatureFlagValue(
        Features.EnableContentfulSideBar.name,
    );

    const EnableHomePageRevamp = useFeatureFlagValue(
        Features.EnableHomePageRevamp.name,
    );

    const EnabledPersonas = TryParseEnabledPersona(getFeatureFlagConfig(
        Features.HomePageEnabledPersonas.name,
    ));

    const userName = useMemo(() => firstName + ' ' + lastName, [ firstName, lastName ]);

    const greeting = useMemo((): string => {
        const currentHour = new Date().getHours();

        if (currentHour >= 3 && currentHour < 12) {
            return translate(
                { id: 'CLIENT_GOOD_MORNING_USER' },
                { 0: userName || translate({ id: 'CLIENT_USER' }) },
            );
        } else if (currentHour >= 12 && currentHour < 18) {
            return translate(
                { id: 'CLIENT_GOOD_AFTERNOON_USER' },
                { 0: userName || translate({ id: 'CLIENT_USER' }) },
            );
        }
        return translate(
            { id: 'CLIENT_GOOD_EVENING_USER' },
            { 0: userName || translate({ id: 'CLIENT_USER' }) },
        );
    }, [ translate, userName ]);

    const isSSOConnectionEnabled = useCheckSSOEnabled();
    const isHPRevampEnabled = isHomePageRevampEnabled({
        featureFlag: EnableHomePageRevamp,
        dominantPersona: userProfile.persona,
        enabledPersonas: EnabledPersonas && EnabledPersonas.length > 0 ? EnabledPersonas : ENABLED_PERSONAS,
    });

    const [ hideSidebar, setHideSidebar ] = useState(false);
    return (
        <UiPanel
            header={{
                title: greeting,
                fontSize: '18px',
                fontWeight: 700,
                isGreeting: true,
            }}
            sideBar={
                !hideSidebar && (EnableContentfulSideBar ? <HomePageSidebarLinksComponent /> :
                    <UiWidgetContainer
                        widgets={HomeWidgets}
                        userGroups={currentGroupIds}
                        position="right"
                    />
                )
            }
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            trialPerSkuLicenseInGracePeriodBanners={
                <UiTrialPerSkuLicenseInGracePeriodBanners />
            }
            licenseOverAllocationBanner={<UiOverAllocationBanner />}
            banner={
                <>
                    {EnableHomePageCarousel && isHPRevampEnabled && !disableProtrialCarousel && <Carousel />}
                    {isSSOConnectionEnabled && isAuth0 && (
                        <UiAlertBanner
                            type="info"
                            closeable={false}>
                            {translate(
                                { id: 'CLIENT_AZURE_AUTH0_LOGIN' },
                                {
                                    0: (
                                        <a
                                            className={classes.a}
                                            href={`${window.location.origin}/${organizationName}`}
                                        >
                                            {`${window.location.origin}/${organizationName}`}
                                        </a>
                                    ),
                                },
                            )}
                            <a
                                className={classes.a}
                                target="_blank"
                                rel="noreferrer"
                                href={hyperLink.USER_MANAGEMENT}
                                style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    marginLeft: '8px',
                                }}
                            >
                                {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                <LaunchIcon
                                    style={{
                                        fontSize: '14px',
                                        marginLeft: '4px',
                                    }}
                                />
                            </a>
                        </UiAlertBanner>
                    )}
                </>
            }
            freBanner={
                !isHPRevampEnabled ? <FREBannerHelper
                    partitionGlobalId={partitionGlobalId}
                    userGroupIds={currentGroupIds}
                    setHideSidebar={setHideSidebar}
                /> : null
            }
        >
            {isOnPrem && isUnlicensedMode && <HomePageLicensesComponent />}
            {isHPRevampEnabled || enableHomePageForProtrial ?
                <UiHomePage
                    profile={userProfile}
                    token={token} />
                :
                <UiWidgetContainer
                    widgets={HomeWidgets}
                    userGroups={currentGroupIds}
                    position="center"
                />}
        </UiPanel>
    );
};

function TryParseEnabledPersona(featureFlagValue: string): Persona[] {
    try {
        return JSON.parse(featureFlagValue);
    } catch (e) {
        return ENABLED_PERSONAS;
    }
}

export default HomePageComponent;
