import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    getCachedLanguage,
    Languages,
    setLanguage,
} from '@uipath/portal-shell-util';
import { useMemo } from 'react';

import type { LanguageCulture } from './language';
import { languageCodes } from './language';

export const processLocaleKeys = (
    enableLocKeysLanguage: boolean,
    setStringsCallback: (strings: Record<string, string>) => void,
    en: {},
) => {
    if (!enableLocKeysLanguage) {
        return;
    }

    const keys = Object.keys(en).reduce(
        (accum, current) => ({
            ...accum,
            [current]: current,
        }),
        {},
    );
    setStringsCallback(keys);
};

export function isValidLanguageCode(languageCode: string | null | undefined) {
    return languageCodes.find(language => language.toLowerCase() === languageCode?.toLowerCase());
}

export function getUserLanguageFromLocalStorage() {
    const EnableLocKeysLanguage = getFeatureFlagValue(Features.EnableLocKeysLanguage.name);
    const EnableLanguageZhTw = getFeatureFlagValue(Features.EnableLanguageZhTw.name);

    const defaultLanguage = getBrowserLanguage();
    const languageFromLocalStorage = isValidLanguageCode(getCachedLanguage());

    return isValidLanguageCode(
        !languageFromLocalStorage
        || (!EnableLocKeysLanguage && languageFromLocalStorage?.toLowerCase() === 'keys')
        || (!EnableLanguageZhTw && languageFromLocalStorage?.toLowerCase() === 'zh-tw')
            ? defaultLanguage
            : languageFromLocalStorage,
    ) ?? 'en';
}

export const getBrowserLanguage = () =>
    isValidLanguageCode(navigator.language) ?? isValidLanguageCode(navigator.language.substring(0, 2)) ?? 'en';

export function setUserLanguageInLocalStorage(language: string) {
    const validLanguage = isValidLanguageCode(language) ?? 'en';
    setLanguage(validLanguage);
    return validLanguage;
}

export function useSupportedLanguagesMap() {
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);
    const EnableLanguageZhTw = useFeatureFlagValue(Features.EnableLanguageZhTw.name);

    return useMemo(() => {
        const languagesMap: { [key: string]: string } = { ...Languages };
        if (!EnableLocKeysLanguage) {
            delete languagesMap['keys'];
        }
        if (!EnableLanguageZhTw) {
            delete languagesMap['zh-tw'];
        }
        return languagesMap;
    }, [ EnableLanguageZhTw, EnableLocKeysLanguage ]);
}

export function getLanguageForDocLinks(lang: LanguageCulture) {
    switch (lang) {
        case 'en':
        case 'keys':
            return '';
        case 'zh-CN':
        case 'zh-TW':
            return `lang-zh_CN/`;
        case 'es-MX':
            return `lang-es_MX/`;
        case 'pt-BR':
            return `lang-pt_BR/`;
        default:
            return `lang-${lang}/`;
    }
}

export function useSupportedLanguages() {
    const EnableLocKeysLanguage = useFeatureFlagValue(Features.EnableLocKeysLanguage.name);
    const EnableLanguageZhTw = useFeatureFlagValue(Features.EnableLanguageZhTw.name);

    return useMemo(() => {
        let languageList = Object.entries(Languages);
        if (!EnableLocKeysLanguage) {
            languageList = languageList.filter(lang => lang[0] !== 'keys');
        }
        if (!EnableLanguageZhTw) {
            languageList = languageList.filter(lang => lang[0] !== 'zh-tw');
        }
        return languageList.map(([ id, text ]) => ({
            id,
            text,
        }));
    }, [ EnableLanguageZhTw, EnableLocKeysLanguage ]);
}
