import { GlobalStyles } from '@experiences/theme';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import type { ButtonProps } from '@mui/material';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { styled } from '@mui/system';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import getApps, { appsUrl } from '../../../services/orchestrator/AppsService';
import { accountLogicalName } from '../../../store/selectors';
import HomePageHeader from '../../home/helper/HomePageHeader';
import AppsCard from './AppsCard';
import type { IApps } from './interfaces/apps';

const CreateAppButton = styled(Button)<ButtonProps>(({ theme }) => ({
    marginLeft: '16px',
    width: '125px',
    height: '32px',
    borderRadius: '4px',
}));

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        appsWidget: { padding: '20px 0' },
        appsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            maxHeight: '119px',
            overflow: 'hidden',
            margin: '0 -8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            '& $appsCardWrapper': {
                marginTop: '4px',
                boxSizing: 'border-box',
                height: '112px',
                minWidth: '311px',
                marginBottom: '12px',
                marginRight: '24px',
            },
        },
        noData: {
            fontSize: '14px',
            height: '36px',
            paddingLeft: '8px',
            color: theme.palette.semantic.colorForeground,
        },
        appsCardWrapper: {},
        '@media all and (max-width: 960px)': { cardWrapper: { width: '30%' } },
        '@media all and (min-width: 1024px)': { cardWrapper: { width: '30%' } },
        '@media all and (min-width: 1400px)': { cardWrapper: { width: '20%' } },
        '@media all and (min-width: 1600px)': { cardWrapper: { width: '15%' } },
        '@media all and (min-width: 1920px)': { cardWrapper: { width: '10%' } },
        headerBar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '12px 0',
        },
        spinner: { padding: '0 8px' },
    }),
}));

const AppsComponent: React.FC = () => {
    const logicalName = useSelector(accountLogicalName);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const onAppsViewAll = useCallback(() => {
        window.location.assign(
            `${window.location.origin}/${logicalName}/apps_`,
        );
    }, [ logicalName ]);

    const onCreateAppClick = useCallback(() => {
        window.location.assign(
            `${window.location.origin}/${logicalName}/apps_?openCreateAppPanel=true`,
        );
    }, [ logicalName ]);

    const {
        data: appsList,
        isValidating: isValidatingUnassigned,
        error: Error,
    } = useSWR<IApps[], Error>(appsUrl, () => getApps(logicalName));

    return (
        <div
            data-cy="home-page-apps-widget"
            className={clsx(classes.appsWidget)}
        >
            <div
                className={classes.headerBar}
                data-cy="home-page-apps-header-bar"
            >
                <HomePageHeader
                    icon={
                        <PortalCustomIcon
                            name="apps"
                            className={classes.svgIcon}
                        />
                    }
                    label={translate({ id: 'CLIENT_APP' })}
                    fontSize="20px"
                />
                <div>
                    <Button
                        onClick={onAppsViewAll}
                        data-cy="home-page-apps-view-all-button"
                        color="primary"
                    >
                        <HomePageHeader
                            label={translate({ id: 'CLIENT_SEE_ALL_APPS' })}
                            variant="button"
                        />
                    </Button>
                    <CreateAppButton
                        onClick={onCreateAppClick}
                        data-cy="home-page-apps-create-button"
                        color="primary"
                        variant="contained"
                        startIcon={<AddCircleOutline />}
                    >
                        {translate({ id: 'CLIENT_APPS_CREATE' })}
                    </CreateAppButton>
                </div>
            </div>
            <div className={clsx(classes.appsContainer)}>
                {!Error &&
                    appsList?.map((appInfo, idx) => (
                        <div
                            key={appInfo.model.id}
                            className={clsx(classes.appsCardWrapper)}
                        >
                            <AppsCard
                                title={appInfo.model.title}
                                owner={appInfo.owner}
                                id={idx}
                                latestPublishedDate={
                                    appInfo.latestPublishedDate
                                }
                                description={appInfo.model.description}
                                logicalName={logicalName}
                                systemName={appInfo.model.systemName}
                            />
                        </div>
                    ))}
                {!appsList && isValidatingUnassigned && (
                    <div className={classes.spinner}>
                        <CircularProgress
                            size={36}
                            thickness={4} />
                    </div>
                )}
                {!isValidatingUnassigned && appsList?.length === 0 && (
                    <div className={classes.noData}>
                        {translate({ id: 'CLIENT_NO_APPS' })}
                    </div>
                )}
            </div>
        </div>
    );
};
export default AppsComponent;
