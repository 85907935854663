import { escapedXmlCharacterMap } from '../common/constants/HtmlConstant';

export function decodeSanitizedHtml(sanitizedHtml: string) {
    const doc = new DOMParser().parseFromString(sanitizedHtml, 'text/html');
    return doc.documentElement.textContent ?? '';
}

export function decodeXmlCharacters(str: string) {
    return str.replace(/(&quot;|&apos;|&lt;|&gt;|&amp;)/g, (_, item: string) => escapedXmlCharacterMap.get(item) ?? item);
}
