import { AccountLicense } from '@experiences/constants';
import { GlobalStyles } from '@experiences/theme';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import upgradeProIcon from '../../../images/upgradeChipPro.svg';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiUpgradePopOver } from './UiUpgradePopOver';

const useStyles = makeStyles(theme =>
    createStyles({
        ...GlobalStyles(theme),
        chip: {
            height: '16px',
            color: theme.palette.semantic.colorForeground,
            backgroundColor: theme.palette.semantic.colorInfoBackground,
            fontWeight: 600,
        },
    }),
);

export const UiUpgradeChip: React.FC<{
    licenseType: AccountLicense.ENTERPRISE | AccountLicense.PRO ;
    className?: string;
    title: string;
    description: string;
    iconDescription: string;
    icon: JSX.Element;
    telemetryTitle: string;
    customLabel?: string;
}> = ({
    licenseType, className, title, description, iconDescription, icon, telemetryTitle, customLabel,
}) => {

    const classes = useStyles();

    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();

    const [ anchorEl, setAnchorEl ] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        logEvent(telemetryTitle);
        setAnchorEl(event.currentTarget);
    };

    const showEnterpriseChip = useMemo(() => licenseType === AccountLicense.ENTERPRISE, [ licenseType ]);

    return (
        <>
            <Chip
                variant="filled"
                size="small"
                onClick={handleClick}
                icon={<img
                    src={upgradeProIcon}
                    alt='upgrade to pro' />}
                label={customLabel ?? translate({ id: showEnterpriseChip ? 'CLIENT_ENTERPRISE_ONLY' : 'CLIENT_PRO_ONLY' })}
                className={clsx(classes.chip, className)}
                data-cy="upgrade-license-chip" />
            <UiUpgradePopOver
                licenseType={licenseType}
                title={title}
                description={description}
                iconDescription={iconDescription}
                icon={icon}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                telemetryTitle={telemetryTitle}
            />
        </>);
};
