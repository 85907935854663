import { useShowDialog } from '@experiences/util';
import { Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

const useShowRestartMessageDialog = () => {
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();

    return () =>
        createDialog({
            dataCy: 'restart-message-dialog',
            body: (
                <Typography data-cy="">
                    {translate({
                        id: process.buildConfigs.showK8SRestartMessage
                            ? 'CLIENT_EXTERNAL_PROVIDER_DIALOG_RESTART_MESSAGE_K8S'
                            : 'CLIENT_EXTERNAL_PROVIDER_DIALOG_RESTART_MESSAGE',
                    })}
                </Typography>
            ),
            icon: 'warning',
            showCancel: false,
            primaryButtonText: translate({ id: 'CLIENT_OK' }),
        });
};

export default useShowRestartMessageDialog;
