import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

export const exportLogsUrl = `/api/orchestrator/logsexport`;

export interface IExportLogsData {
    connectionDetail?: any;
    tenantKey?: string;
    connectionString?: string;
    containerName: string;
    storageType?: 'AzureBlob' | 'AwsS3' | 'GcpStorage';
    RegionSystemName?: string;
}

export async function getLogsExportDetails(url: string, tenantKey: string) {
    return await get<IExportLogsData>(url, { urlParams: { tenantKey } });
}

export function postLogsExportDetails(body: IExportLogsData) {
    return post<IExportLogsData>(exportLogsUrl, { body });
}

export function deleteLogsExportDetails(tenantKey: string) {
    return axiosDelete(`${exportLogsUrl}?tenantKey=${tenantKey}`);
}
