import {
    AccountLicense,
    TelemetryTitle,
} from '@experiences/constants';
import { useCentralErrorSetter } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import {
    useLocalizedLinks,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import { DirectorySSOLink } from '../../../common/constants/DirectorySSO';
import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import type IExternalIdentity from '../../../common/interfaces/externalIdentity';
import configureSSOIcon2 from '../../../images/configureSSOIcon2.svg';
import type { IBulkAuthenticationSettingResponse } from '../../../services/identity/AuthenticationSettingService';
import {
    authenticationSettingUrl,
    BulkAuthenticationSettingKey,
    getAllAuthenticationSettings,
} from '../../../services/identity/AuthenticationSettingService';
import type { IDirectoryConnectionPayload } from '../../../services/identity/DirectoryConnectionService';
import {
    DirectoryConnectionType,
    directoryConnectionUrl,
    getDirectoryConnection,
} from '../../../services/identity/DirectoryConnectionService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../../store/selectors';
import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';
import DeleteSSOConfigurationDialogBody from './DeleteSSOConfigurationDialogBody';
import DirectoryIntegration from './DirectoryIntegration';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        centerLoader: { margin: 'auto' },
        ssoConfigureButton: { marginRight: '8px' },
        radioPrimary: {
            fontWeight: 600,
            fontSize: '14px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px',
        },
        configureSSO: { marginTop: '24px' },
        spacer: { marginTop: '8px' },
        buttonWidth: { width: '160px' },
        link: {
            cursor: 'pointer',
            color: '#0067df',
            '&:hover': { textDecoration: 'underline' },
        },
        configuredSSO: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        ssoText: {
            fontSize: '12px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            lineHeight: '16px',
            marginBottom: '4px',
            marginTop: '8px',
        },
        chipSpacer: { marginLeft: '8px' },
    }),
}));

export const DirectoryIntegrationComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const getRoute = useRouteResolver();
    const history = useHistory();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();
    const getLocalizedLink = useLocalizedLinks();

    const partitionGlobalId = useSelector(accountGlobalId);
    const isHostMode = useSelector(isHostModeSelector);
    const { isOffersRevampAndCommunity } = useCheckLicense();
    const EnableAttributeMapping = useFeatureFlagValue(Features.EnableAttributeMapping.name);

    const {
        data: authenticationSetting,
        mutate,
    } = useSWR<IBulkAuthenticationSettingResponse, Error>(
        !isHostMode ? [ `${authenticationSettingUrl}/getAll`, partitionGlobalId ] : null,
        getAllAuthenticationSettings,
    );

    const {
        data: directoryConnectionData, mutate: mutateDirectoryConnection,
    } = useSWR<IDirectoryConnectionPayload[]>(
        isHostMode ? [ directoryConnectionUrl, partitionGlobalId ] : null,
        getDirectoryConnection,
    );

    const adDirectoryConnection = useMemo(() => directoryConnectionData?.find(connection =>
        connection.type === DirectoryConnectionType.AD
        || connection.type === DirectoryConnectionType.LDAPAD),
    [ directoryConnectionData ]);

    const {
        isAAD, isSaml, isAD,
    } = useMemo(
        () => ({
            isAAD: !!authenticationSetting?.[BulkAuthenticationSettingKey.AAD],
            isSaml: !!authenticationSetting?.[BulkAuthenticationSettingKey.SAML],
            isAD: !!(adDirectoryConnection
                ?? authenticationSetting?.[BulkAuthenticationSettingKey.AD]
                ?? authenticationSetting?.[BulkAuthenticationSettingKey.LDAPAD]),
        }),
        [ adDirectoryConnection, authenticationSetting ],
    );

    const isSSO = isAAD || isSaml || isAD;

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        const samlSettings = authenticationSetting?.[BulkAuthenticationSettingKey.SAML]?.externalIdentityProviderDto?.settings;
        if (!samlSettings) {
            return undefined;
        }

        try {
            return JSON.parse(samlSettings) as ISaml2ProviderSettings;
        } catch (error) {
            const errorMessage = (error as Error).message;
            setErrorMessage(errorMessage);
        }
    }, [ authenticationSetting, setErrorMessage ]);

    const ssoOtherOptions = useCallback((str: string) =>
        <Link
            display="inline"
            className={classes.link}
            onClick={() => history.push(getRoute(RouteNames.ConfigureSSO))}>
            {str}
        </Link>
    , [ classes.link, getRoute, history ]);

    const refreshConfig = useCallback(() => {
        mutate();
        mutateDirectoryConnection();
    }, [ mutate, mutateDirectoryConnection ]);

    const handleDelete = useCallback(async () => {
        const deleteDialogTitle = isSaml ? 'CLIENT_DELETE_SAML_SSO_CONFIGURATION'
            : isAAD ? 'CLIENT_DELETE_AZURE_AD_SSO_CONFIGURATION'
                : 'CLIENT_DELETE_AD_SSO_CONFIGURATION';
        await createDialog({
            title: translate({ id: deleteDialogTitle }),
            customDialogContent: DeleteSSOConfigurationDialogBody,
            customDialogContentProps: {
                connectionId: adDirectoryConnection?.id,
                refreshCallback: () => {
                    refreshConfig();
                },
            },
            icon: 'error',
        });
    }, [ adDirectoryConnection?.id, createDialog, isAAD, isSaml, refreshConfig, translate ]);

    const directoryIntegrationDescription = useMemo(() => {
        if (isAAD) {
            return (
                <>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-application-id">
                        <strong>
                            {`${translate({ id: 'CLIENT_AZURE_APPLICATION_ID' })}: `}
                        </strong>
                        {authenticationSetting?.[BulkAuthenticationSettingKey.AAD]?.externalIdentityProviderDto?.clientId}
                    </Typography>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-tenant-id">
                        <strong>
                            {`${translate({ id: 'CLIENT_AZURE_TENANT_ID' })}: `}
                        </strong>
                        {
                            (authenticationSetting?.[BulkAuthenticationSettingKey.AAD]
                                ?.externalIdentityProviderDto as IExternalIdentity)?.authority?.match(
                                /.com\/(.*)\/v/,
                            )?.[1]
                        }
                    </Typography>
                </>
            );
        } else if (isSaml) {
            return (
                <>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-sign-on-url">
                        <strong>
                            {`${translate({ id: 'CLIENT_SIGN_ON_URL' })}: `}
                        </strong>
                        {externalIdentityProviderDtoSettings?.SingleSignOnServiceUrl}
                    </Typography>
                    <Typography
                        className={classes.ssoText}
                        data-cy="sso-identity-provider-id">
                        <strong>
                            {`${translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}: `}
                        </strong>
                        {externalIdentityProviderDtoSettings?.IdentityProviderEntityId}
                    </Typography>
                </>
            );
        }
    }, [ authenticationSetting, classes, externalIdentityProviderDtoSettings, isAAD, isSaml, translate ]);

    const directoryIntegrationType = useMemo(() => {
        if (isSaml) {
            return 'SAML2';
        }

        if (isAAD) {
            return 'AAD';
        }

        if (isAD) {
            return 'AD';
        }

        return '';
    }, [ isAAD, isAD, isSaml ]);

    return <div className={classes.configureSSO}>
        <div className={classes.configuredSSO}>
            <Typography
                className={classes.headerText}
                data-cy="directory-integration-header">
                {translate({
                    id: !isHostMode
                        ? 'CLIENT_SSO_CONFIGURATION_HEADER'
                        : 'CLIENT_DIRECTORY_CONFIGURATION_HEADER',
                })}
            </Typography>
            {isOffersRevampAndCommunity &&
                <Tooltip title={translate({ id: 'CLIENT_UPGRADE_TO_ENTERPRISE_ACCESS' })}>
                    <UiUpgradeChip
                        className={classes.chipSpacer}
                        licenseType={AccountLicense.ENTERPRISE}
                        title={translate({ id: 'CLIENT_CHIP_SSO_HEADER' })}
                        description={translate({ id: 'CLIENT_CHIP_SSO_DESCRIPTION' })}
                        iconDescription={translate({ id: 'CLIENT_CHIP_SSO_ICON_DESCRIPTION' })}
                        icon={
                            <img
                                src={configureSSOIcon2}
                                alt='' />
                        }
                        telemetryTitle={TelemetryTitle.SecuritySettings.AuthSettingsEnterprise} />
                </Tooltip>}
        </div>
        {isSSO && ((isHostMode && isAD) || (!isHostMode && !isAD)) ? (
            <>
                <DirectoryIntegration
                    title={translate({ id: `CLIENT_${directoryIntegrationType}_INTEGRATION` })}
                    enableChip
                    description={directoryIntegrationDescription}
                    actions={
                        <>
                            {isSaml && !isHostMode && <Button
                                variant="outlined"
                                className={classes.ssoConfigureButton}
                                data-cy="saml-provisioning-rules-button"
                                onClick={() => history.push(getRoute(RouteNames.SecuritySettingsSAMLProvisioningRules))}
                            >
                                {translate({ id: 'CLIENT_SAML_VIEW_PROVISIONING_RULES' })}
                            </Button>}
                            {!isAD && <Button
                                variant="outlined"
                                className={classes.ssoConfigureButton}
                                data-cy="sso-edit-button"
                                onClick={() =>
                                    history.push(getRoute(`${RouteNames.SecuritySettings}/configure/${directoryIntegrationType.toLowerCase()}`))}
                            >
                                {translate({ id: 'CLIENT_EDIT' })}
                            </Button>}
                            {isAD && <Button
                                variant="outlined"
                                className={classes.ssoConfigureButton}
                                data-cy="sso-edit-button"
                                onClick={() =>
                                    history.push({ pathname: getRoute(RouteNames.AuthSettingsConfigureADDirectory) })}
                            >
                                {translate({ id: 'CLIENT_EDIT' })}
                            </Button>}
                            {(isAAD || isSaml) && EnableAttributeMapping && <Button
                                variant="outlined"
                                className={classes.ssoConfigureButton}
                                onClick={() => history.push(getRoute(`${RouteNames.SecuritySettings}/attributes/${directoryIntegrationType.toLowerCase()}`))}
                                data-cy="configure-attribute-mapping-button"
                            >
                                {translate({ id: 'CLIENT_CONFIGURE_ATTRIBUTE_MAPPING' })}
                            </Button>}
                            <UiDeleteButton
                                variant="outlined"
                                data-cy="sso-delete-button"
                                onClick={handleDelete}
                            >
                                {translate({ id: 'CLIENT_DELETE' })}
                            </UiDeleteButton>
                        </>
                    }
                />
                {!isHostMode && <Typography className={classes.spacer}>
                    <FormattedMessage
                        id="CLIENT_SSO_OTHER_OPTIONS"
                        values={{ a: (str: string) => ssoOtherOptions(str) }}
                    />
                </Typography>}
            </>
        ) : <>
            <div className={classes.spacer}>
                <Typography style={{ maxWidth: '640px' }}>
                    <FormattedMessage
                        id={isHostMode ? 'CLIENT_AD_SSO_CONFIGURATION_DESCRIPTION' : 'CLIENT_SSO_CONFIGURATION_DESCRIPTION'}
                        values={{
                            a: (msg: any) =>
                                (
                                    <a
                                        className={classes.a}
                                        href={getLocalizedLink(DirectorySSOLink)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {msg}
                                    </a>
                                ) as any,
                        }}
                    />
                </Typography>
            </div>
            <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                    !isHostMode
                        ? history.push(getRoute(RouteNames.ConfigureSSO))
                        : history.push({ pathname: getRoute(RouteNames.AuthSettingsConfigureADDirectory) });
                }}
                data-cy="configure-sso-button"
                className={classes.spacer}
                style={{ display: 'block' }}
            >
                {translate({
                    id: isOffersRevampAndCommunity
                        ? 'CLIENT_LEARN_MORE'
                        : isHostMode
                            ? 'CLIENT_CONFIGURE'
                            : 'CLIENT_CONFIGURE_SSO',
                })}
            </Button>
        </>}
    </div>;
};
