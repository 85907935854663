import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useLocalizedLinks,
    useShowDialog,
} from '@experiences/util';
import {
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { LicensingManagementOptionsLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import {
    AutomationDocsLinks,
    LegacyLicenseLinks,
} from '../../../common/constants/LegacyLicenseURLs.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import {
    getUserLicenseManagementStatus,
    updateUserLicenseManagementStatus,
} from '../../../services/licensing/UserLicenseService';
import { getUserOrganizationInfo } from '../../../services/organization/UserOrganizationInfoService.default';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import { accountType } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        userLicenseManagementTitle: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        userLicenseManagementHelper: {
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        userLicensingButton: {
            marginTop: '16px',
            marginBottom: '24px',
            width: 'fit-content',
        },
        userLicensingButtonRevamp: {
            marginTop: '16px',
            marginBottom: '16px',
            width: '120px',
        },
        userLicensingInnerButtonRevamp: { width: '120px' },
        userLicenseManagementActions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        userLicensingLink: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
    }),
}));

interface IUserLicenseManagementConfig {
    enabled: boolean;
}

const UserLicensingSettingsComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();

    const { getErrorMessage } = useGetErrorInfo();
    const getLocalizedLink = useLocalizedLinks();
    const getLocalizedDocumentationLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const userAccountType = useSelector(accountType);
    const [ licensingLoading, setLicensingLoading ] = useState(false);

    const isUserCommunityAccountType = useMemo(() => AccountLicense[userAccountType] === AccountLicense.COMMUNITY, [ userAccountType ]);

    const { data: userLicenseManagementConfig } = useSWR<IUserLicenseManagementConfig, Error>(
        '/api/organization/user-license/enable',
        getUserLicenseManagementStatus,
    );

    const userLicensingStatusLoading = useMemo(() => licensingLoading || !userLicenseManagementConfig, [ licensingLoading, userLicenseManagementConfig ]);

    const enableDialogBody = useMemo(
        () => (
            <div>
                <Typography>
                    <FormattedMessage
                        id="CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_P1"
                        values={{
                            b: (msg: any) => (<b>
                                {msg}
                            </b>) as any,
                        }}
                    />
                </Typography>
                <br />
                <Typography>
                    {translate({ id: 'CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_P2' })}
                </Typography>
                <br />
                <Typography>
                    <FormattedMessage
                        id="CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_P3"
                        values={{
                            a: (msg: any) =>
                                (
                                    <a
                                        className={classes.a}
                                        href={getLocalizedLink(AutomationDocsLinks)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        data-cy="automation-docs-link"
                                    >
                                        {msg}
                                    </a>
                                ) as any,
                        }}
                    />
                </Typography>
                <br />
                <Typography>
                    {translate({ id: 'CLIENT_WARN_USER_LICENSE_MANAGEMENT_ASK_CONFIRM' })}
                </Typography>
            </div>
        ),
        [ translate, classes, getLocalizedLink ],
    );

    const disableDialogBody = useMemo(
        () => (
            <div>
                <Typography>
                    {translate({ id: 'CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P1' })}
                </Typography>
                <br />
                <Typography>
                    {process.buildConfigs.userLicenseManagementCloud ? (
                        <FormattedMessage
                            id="CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P2"
                            values={{
                                b: (msg: any) => (<b>
                                    {msg}
                                </b>) as any,
                            }}
                        />
                    ) : (
                        translate({ id: 'CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P2_ON_PREM' })
                    )}
                </Typography>
                <br />
                <Typography>
                    <FormattedMessage
                        id="CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P3"
                        values={{
                            a: (msg: any) =>
                                (
                                    <a
                                        className={classes.a}
                                        href={getLocalizedLink(LegacyLicenseLinks)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {msg}
                                    </a>
                                ) as any,
                        }}
                    />
                </Typography>
                <br />
                <Typography>
                    {translate({ id: 'CLIENT_WARN_USER_LICENSE_MANAGEMENT_ASK_CONFIRM' })}
                </Typography>
            </div>
        ),
        [ translate, getLocalizedLink, classes ],
    );

    const alreadyEnabledAndDoNotAllowDisabling = useMemo (() => isUserCommunityAccountType && userLicenseManagementConfig?.enabled,
        [ isUserCommunityAccountType, userLicenseManagementConfig ]);

    const setUserLicenseManagementStatus = useCallback(
        async (status: 'ENABLE' | 'DISABLE') => await createDialog({
            title: `${translate({ id: `CLIENT_${status}_USER_LICENSE_MANAGEMENT` })}`,
            body: status === 'ENABLE' ? enableDialogBody : disableDialogBody,
            icon: status === 'ENABLE' ? 'warning' : 'error',
            primaryButtonText: `${translate({ id: `CLIENT_${status}` })}`,
            showCancel: true,
        }),
        [ createDialog, translate, enableDialogBody, disableDialogBody ],
    );

    const updateUserLicensingManagement = useCallback(
        async (checked: boolean) => {
            const proceed = checked
                ? await setUserLicenseManagementStatus('ENABLE')
                : await setUserLicenseManagementStatus('DISABLE');
            if (proceed) {
                try {
                    setLicensingLoading(true);

                    await updateUserLicenseManagementStatus(checked);
                    const userOrganizationInfo = await getUserOrganizationInfo();
                    if (userOrganizationInfo) {
                        await setUserProfile(userOrganizationInfo);
                    }
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                } finally {
                    mutate('/api/organization/user-license/enable');
                    setLicensingLoading(false);
                }
            }
        },
        [ setUserLicenseManagementStatus, setErrorMessage, getErrorMessage ],
    );

    return alreadyEnabledAndDoNotAllowDisabling ?
        (
            <>
                <Typography className={classes.userLicenseManagementTitle}>
                    {translate({ id: 'CLIENT_USER_LICENSE_MANAGEMENT' })}
                </Typography>
                <Typography className={classes.userLicenseManagementHelper}>
                    { translate({ id: 'CLIENT_USER_LICENSING_MANAGEMENT_ALREADY_ENABLED' })}
                </Typography>
            </>
        )
        : (
            <>
                <Typography className={classes.userLicenseManagementTitle}>
                    {translate({ id: 'CLIENT_USER_LICENSE_MANAGEMENT' })}
                </Typography>
                <Typography className={classes.userLicenseManagementHelper}>
                    {userLicenseManagementConfig?.enabled
                        ? translate({
                            id: isAdminRevampEnabled
                                ? 'CLIENT_DISABLE_USER_LICENSE_DESCRIPTION_REVAMP'
                                : 'CLIENT_DISABLE_USER_LICENSE_DESCRIPTION',
                        })
                        : translate({ id: 'CLIENT_ENABLE_USER_LICENSE_DESCRIPTION' })}
                </Typography>
                <div className={classes.userLicenseManagementActions}>
                    <UiProgressButton
                        loading={userLicensingStatusLoading}
                        variant="outlined"
                        className={clsx({
                            [classes.userLicensingButton]: !isAdminRevampEnabled,
                            [classes.userLicensingButtonRevamp]: isAdminRevampEnabled,
                        })}
                        innerButtonClass={clsx({ [classes.userLicensingInnerButtonRevamp]: isAdminRevampEnabled })}
                        data-cy="enable-disable-user-licensing-button"
                        onClick={() => updateUserLicensingManagement(!userLicenseManagementConfig?.enabled)}
                    >
                        {userLicenseManagementConfig?.enabled
                            ? translate({ id: 'CLIENT_DISABLE' })
                            : translate({ id: 'CLIENT_ENABLE' })}
                    </UiProgressButton>
                    {isAdminRevampEnabled && <Link
                        href={getLocalizedDocumentationLink(LicensingManagementOptionsLink)}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        className={classes.userLicensingLink}
                    >
                        {translate({ id: 'CLIENT_USER_MANAGEMENT_HELPER' })}
                    </Link>}
                </div>
            </>
        );
};

export default UserLicensingSettingsComponent;
