import { resolveHostUsingEnvironmentInfo } from '@experiences/util';

const identityBaseRoute = process.buildConfigs.identityBaseRoute as string;

const CIS_ORIGIN_LOCAL = window.env?.CIS_HOST_PREFIX ? `https://${(window.env?.CIS_HOST_PREFIX as string)?.replace('/identity_', '')}` : 'http://localhost:8080';

export const CISHostWithAPIPrefix =
  window.env?.CIS_HOST_PREFIX || `${resolveHostUsingEnvironmentInfo(window.location.host)}${identityBaseRoute}`;

export const CISAuthority = `https://${CISHostWithAPIPrefix}`;

export const CISHostConnect = `${CISAuthority}/connect`;

export const CISOpenIdConfiguration = `${CISAuthority}/.well-known/openid-configuration`;
