import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import InfoIcon from '@mui/icons-material/Info';
import {
    Divider,
    Fade,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    LegacyProductsToUserBundleLicenseMap,
    ProductTypes,
} from '../../../common/constants/Constant';
import {
    getFriendlyName,
    getIconName,
} from '../../../common/constants/ServicesMapping';
import { concurrentLicensesOpted } from '../../../store/selectors';
import type {
    ITenantServiceLicense,
    ITenantServiceProduct,
} from '../../tenants/interfaces/service';
import ConsumptionInfoComponent from '../../tenants/subcomponents/ConsumptionInfoComponent';
import { minMaxAllowedProductQty } from '../../tenants/subcomponents/helpers/AllocationSectionsHelper';
import {
    concurrencyMapping,
    productSubLabel,
    translationCode,
} from '../../tenants/subcomponents/helpers/ManageLicensesHelper';
import UiLicenseToggleComponent from './UiLicenseToggleComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputGroup: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        labelSubGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        inputMargin: { marginBottom: '12px' },
        overAllocatedWarning: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        serviceLicenseHeader: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '34px',
        },
        serviceHeader: { marginLeft: '12px' },
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            paddingLeft: '3px',
            margin: '1px',
        },
        disabledField: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            color: theme.palette.semantic.colorForegroundDisable,
            borderColor: theme.palette.semantic.colorBorderDisabled,
        },
    }),
);

const UiLicenseAllocationPerServiceComponent: React.FC<{
    type?: 'add' | 'edit';
    name: string;
    serviceLicense: ITenantServiceLicense;
}> = ({
    type = 'edit', name, serviceLicense,
}) => {
    const enableRuFallbackConfiguration = getFeatureFlagValue(Features.EnableRuFallbackConfiguration.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const theme = useTheme();

    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);

    const products = useMemo(() => serviceLicense.products, [ serviceLicense ]);

    const {
        register, watch, formState,
    } = useFormContext();
    const { errors } = formState;

    const disableCallback = useCallback((product: ITenantServiceProduct) => ({ disableInput: concurrentLicenseEnabled && watch(`${name}.${concurrencyMapping[product.code]}`, 0) > 0 }), [ concurrentLicenseEnabled, name, type, watch ]);

    const minMaxCallback = useCallback((product: ITenantServiceProduct) => minMaxAllowedProductQty(product), []);

    const getTooltip = useCallback((disableInput: boolean, newLicensingUIEnabled: boolean) => {
        if (!disableInput) {
            return '';
        }

        return newLicensingUIEnabled
            ? translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_MULTIUSER' })
            : translate({ id: 'CLIENT_CAN_NOT_USE_ATTENDED_AND_CONCURRENT' });
    }, [ translate ]);

    const getHelperText = useCallback((product: ITenantServiceProduct) => {
        const {
            minQuantity, maxQuantity,
        } = minMaxCallback(product);

        if (errors[name]?.[product.code]?.type === 'required') {
            return translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' });
        } else if (errors[name]?.[product.code]?.type === 'min'
            || (errors[name]?.[product.code]?.type === 'max' && maxQuantity > 0)) {
            return translate({ id: 'CLIENT_WARN_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (errors[name]?.[product.code]?.type === 'max' && maxQuantity === 0) {
            return translate({ id: 'CLIENT_WARN_NO_LICENSE' }, {
                0: minQuantity,
                1: maxQuantity,
            });
        } else if (product.available < 0) {
            return (
                <div className={classes.overAllocatedWarning}>
                    {translate(
                        { id: 'CLIENT_TENANTS_LICENSE_OVERALLOCATED' },
                        { 0: Math.abs(product.available) },
                    )}
                    <Tooltip
                        data-cy="overallocation-tooltip"
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={translate(
                            { id: 'CLIENT_LICENSE_OVERALLOCATION_TOOLTIP' },
                        )}
                        arrow
                    >
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                </div>
            );
        }
        return null;
    }, [
        classes.infoIcon,
        classes.overAllocatedWarning,
        errors,
        minMaxCallback,
        name,
        translate,
    ]);

    const mappedProductCallback = useCallback((product: ITenantServiceProduct) => {
        const mappedProduct = LegacyProductsToUserBundleLicenseMap[product.code];
        const newLicensingUIEnabled = !!mappedProduct;
        const productCodeForTranslation = newLicensingUIEnabled
            ? mappedProduct.slice(0, -2)
            : product.code;
        const productLabel = newLicensingUIEnabled
            ? mappedProduct.slice(-2)
            : productSubLabel(translationCode(productCodeForTranslation));

        return {
            newLicensingUIEnabled,
            productCodeForTranslation,
            productLabel,
        };
    }, []);
    return <div>
        <div className={classes.serviceLicenseHeader}>
            <PortalCustomIcon
                name={getIconName(serviceLicense.serviceType)}
                size="18px" />
            <Typography
                className={clsx(classes.inputLabel, classes.serviceHeader)}
                variant="h3"
                data-cy="license-service-type">
                {getFriendlyName(serviceLicense.serviceType)}
            </Typography>
        </div>
        <Divider />
        {products.map((product, j) => {
            const { disableInput } = disableCallback(product);

            const {
                minQuantity, maxQuantity,
            } = minMaxCallback(product);

            const {
                newLicensingUIEnabled, productCodeForTranslation, productLabel,
            } = mappedProductCallback(product);

            return (
                <div
                    key={j}
                    className={clsx(classes.input, classes.inputColumn, classes.inputMargin)}>
                    <div className={classes.inputGroup}>
                        <div className={classes.labelSubGroup}>
                            <Typography
                                className={classes.inputLabel}
                                id={`${productCodeForTranslation}Label`}>
                                {translate({ id: `CLIENT_${translationCode(productCodeForTranslation)}` })}
                            </Typography>
                            {productLabel && (
                                <Typography className={clsx(classes.inputLabel, classes.subText)}>
                                    {translate({ id: `CLIENT_${productLabel}` })}
                                </Typography>
                            )}
                        </div>
                        <Tooltip
                            arrow
                            title={getTooltip(disableInput, newLicensingUIEnabled)}
                        >
                            <TextField
                                inputProps={{
                                    className: clsx({ [classes.disabledField]: disableInput }),
                                    min: 0,
                                }}
                                name={`${name}.${product.code}`}
                                inputRef={register({
                                    validate: {
                                        required: (value) => !Number.isNaN(parseInt(value)),
                                        min: (value) => parseInt(value) === product.quantity || value >= minQuantity,
                                        max: (value) => parseInt(value) === product.quantity || value <= maxQuantity,
                                    },
                                },
                                ) as any}
                                InputProps={{ readOnly: disableInput }}
                                error={!!errors[name]?.[`${product.code}`] || product.available < 0}
                                type="number"
                                helperText={getHelperText(product)}
                                variant="outlined"
                                size="small"
                                style={{ maxWidth: '100px' }}
                                aria-labelledby={`${productCodeForTranslation}Label`}
                            />
                        </Tooltip>
                    </div>
                    {product.type === ProductTypes.ConsumptionInterval ? (
                        <>
                            <ConsumptionInfoComponent
                                product={product}
                                isNewTenant={type === 'add'} />
                            {type === 'edit' && product.code === 'RU' && enableRuFallbackConfiguration && (
                                <UiLicenseToggleComponent
                                    name="fallbackConfiguration.enabled"
                                    switchName="fallbackConfiguration.enabled"
                                    toggleLabelValue="CLIENT_LICENSE_FALLBACK_TOGGLE"
                                    tooltipValue="CLIENT_LICENSE_FALLBACK_TOOLTIP" />
                            )}
                        </>
                    ) : (
                        <Typography
                            style={{
                                color: product.available > 0
                                    ? theme.palette.semantic.colorSuccessText
                                    : theme.palette.semantic.colorForeground,
                            }}>
                            {translate({ id: 'CLIENT_LICENSES_AVAILABLE' }, { count: product.available })}
                        </Typography>
                    )}
                </div>
            );
        })}
    </div>;
};

export default UiLicenseAllocationPerServiceComponent;
