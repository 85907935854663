import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

const EnableTaskminingIsNoLongerALicensedService = getFeatureFlagValue(Features.EnableTaskminingIsNoLongerALicensedService.name);
const EnableHideIntegrationService = getFeatureFlagValue(Features.EnableHideIntegrationService.name);

// This is the list of services that are system managed and should never be deleted.
// This behavior is under discussion, as it may be possible in the future that Orchestrator can be removed.
// This list is maintained here on OMS:
// https://github.com/UiPath/Cloud-RPA/blob/dev/net5/OrganizationManagementService/src/Web/appsettings.json
export const defaultServicesProvisioned =
 [
     'connections',
     'connectorbuilder',
     'elements',
     'provisioning',
     'messagebus',
     'resourcecatalog',
     'aimetering',
     'ocr',
     'automationsolutions',
 ];

// This dependency graph maintains the list of parent dependencies for each service
const ServiceDependencyGraph: Record<string, string[]> = {
    aimetering: [],
    asyncbus: [],
    orchestrator: [],
    actions: [ 'orchestrator' ],
    aifabric: [ 'orchestrator' ],
    processes: [ 'orchestrator' ],
    insights: [ 'orchestrator' ],
    insightslogex: [ 'orchestrator' ],
    automationhub: [],
    dataservice: [],
    taskmining: EnableTaskminingIsNoLongerALicensedService ? [] : [ 'aifabric' ],
    processmining: [],
    du: [],
    ocr: [],
    automationstore: [ 'automationhub' ],
    testmanager: [ 'orchestrator' ],
    hypervisor: [ 'orchestrator' ],
    connections: [ 'orchestrator' ],
    elements: [ 'connections' ],
    provisioning: [ 'connections' ],
    connectorbuilder: [ 'connections' ],
    serverlesscontrolplane: [ 'orchestrator' ],
    automationsolutions: [ 'orchestrator' ],
};

// This list is used to maintain the dependencies that are hidden from the FE, but still need to provisioned together
// with a service.
const HiddenServiceProvisioning: Record<string, string[]> = {
    orchestrator: [
        'hypervisor',
        'serverlesscontrolplane',
        'insightslogex',
        ...(EnableHideIntegrationService
            ? [ 'connections' ]
            : []),
        'automationsolutions',
    ],
    connections: [ 'elements', 'provisioning', 'connectorbuilder' ],
    du: [ 'aimetering', 'asyncbus' ],
    aifabric: [ 'aimetering', 'asyncbus' ],
    taskmining: [ 'aimetering' ],
};

// Get a list of parent services that this service depends on
export const getListOfDependencies = (serviceId: string) => {
    // Recursively build the list of dependencies
    const buildDependencyGraph = (serviceId: string, results: string[]) => {
        const dependencies = ServiceDependencyGraph[serviceId] ?? [];
        results.push(...dependencies);
        if (results.includes(serviceId)) {
            return results;
        }
        dependencies.forEach(dep => buildDependencyGraph(dep, results));
        return results;
    };

    // Make sure we only have a unique array
    return [ ...new Set(buildDependencyGraph(serviceId, [])) ];
};

// Get list of all child services that depend on this service
export const getListOfParents = (serviceId: string) => {
    const buildReverseDependencyGraph = (serviceId: string, results: string[]) => {
        const dependencies = Object.entries(ServiceDependencyGraph)
            .map(([ service, deps ]) => deps.length && deps.indexOf(serviceId) > -1 ? service : '')
            .filter(service => !!service);
        results.push(...dependencies);
        if (results.includes(serviceId)) {
            return results;
        }
        dependencies.forEach(dep => buildReverseDependencyGraph(dep, results));
        return results;
    };

    return [ ...new Set(buildReverseDependencyGraph(serviceId, [])) ];
};

// Get a list of parent services that this service depends on
export const getListOfHiddenDependencies = (serviceId: string) => {
    // Recursively build the list of dependencies
    const buildServiceGraph = (serviceId: string, results: string[]) => {
        const dependencies = HiddenServiceProvisioning[serviceId] ?? [];
        results.push(...dependencies);
        if (results.includes(serviceId)) {
            return results;
        }
        dependencies.forEach(dep => buildServiceGraph(dep, results));
        return results;
    };

    // Make sure we only have a unique array
    return [ ...new Set(buildServiceGraph(serviceId, [])) ];
};

export const getHiddenDependencyParent = (serviceId: string) => {
    const buildReverseServiceGraph = (serviceId: string) => {
        const parent = Object.entries(HiddenServiceProvisioning)
            .map(([ service, deps ]) => deps.length && deps.indexOf(serviceId) > -1 ? service : '')
            .filter(service => !!service);
        return parent.length ? parent[0] : undefined;
    };

    return buildReverseServiceGraph(serviceId);
};
