import { UiSelect } from '@experiences/ui-common';
import {
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { certificateLocationUsageOptions } from '../../../common/constants/ExternalIdentityProviderConstant';
import type { IEditCertificateLocationsData } from '../../../common/interfaces/externalIdentity';

const useStyles = makeStyles(theme =>
    createStyles({
        section: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        header: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
    }),
);

export const defaultEditCertificateLocationsData: IEditCertificateLocationsData = {
    signingCertificateLocation: { CertificateText: '' },
    serviceCertificateLocation: {
        ServiceCertificateLocationEnabledOnOnpremK8s: false,
        Usage: undefined,
    },
};

const EditCertificateLocationsFormComponent: FC = () => {
    const {
        control, errors, setValue,
    } = useFormContext<IEditCertificateLocationsData>();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <>
            <div className={classes.section}>
                <Typography className={classes.header}>
                    {translate({ id: 'CLIENT_SIGNING_CERTIFICATE' })}
                </Typography>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_CERTIFICATE_TEXT' })}
                </Typography>
                <Controller
                    as={TextField}
                    control={control}
                    rules={{ required: true }}
                    error={!!errors.signingCertificateLocation?.CertificateText}
                    helperText={
                        errors.signingCertificateLocation?.CertificateText?.type === 'required' &&
            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    name="signingCertificateLocation.CertificateText"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{
                        className: 'Tall',
                        multiline: true,
                    }}
                    data-cy="edit-certificate-locations-signing-certificate-text"
                />
            </div>
            <div className={classes.section}>
                <Typography className={classes.header}>
                    {translate({ id: 'CLIENT_SERVICE_CERTIFICATE' })}
                </Typography>
                <Controller
                    name="serviceCertificateLocation.ServiceCertificateLocationEnabledOnOnpremK8s"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={props.value}
                                    onChange={e =>
                                        handleSwitch(
                                            'serviceCertificateLocation.ServiceCertificateLocationEnabledOnOnpremK8s',
                                            e.target.checked,
                                            props.onChange,
                                        )}
                                    color="primary"
                                    data-cy="edit-certificate-locations-service-enabled"
                                />
                            }
                            label={<div className={classes.switchLabel}>
                                {translate({ id: 'CLIENT_ENABLED' })}
                            </div>}
                        />
                    )}
                />
                <UiSelect
                    control={control}
                    name="serviceCertificateLocation.Usage"
                    inputLabel={translate({ id: 'CLIENT_CERTIFICATE_LOCATION_USAGE' })}
                    error={!!errors.serviceCertificateLocation?.Usage}
                    options={certificateLocationUsageOptions}
                    fullWidth
                    dataCy="edit-certificate-locations-service-usage"
                    required
                />
            </div>
        </>
    );
};

export default EditCertificateLocationsFormComponent;
