import type { ILAUsage } from '@experiences/interfaces';
import type { CancelTokenSource } from 'axios';

import type {
    IEntitlementUsageDetailsResponse,
    IGetUsageDetails,
} from '../../component/tenants/interfaces/service';
import store from '../../store/index';
import {
    post,
    put,
} from '../utility/Requests.default';

const licenseUsageUrl = '/api/usage';

export function getLicenseUsageTimeline(
    clientIds: string[],
    timePeriod: number,
    usageAggregationType: string,
    source?: CancelTokenSource,
) {
    const accountGlobalId = store.getState().profile.accountUserDto.accountGlobalId.toLowerCase();
    return post<ILAUsage[]>(`${licenseUsageUrl}/getTimelineForAccount`, {
        body: {
            accountGlobalId,
            clientIds,
            timePeriod,
            usageAggregationType,
        },
        cancelTokenSource: source,
    });
}

export async function getUsageDetails(
    entitlements: string[],
) {
    const usageDetails: IGetUsageDetails = { entitlements };

    return put<IEntitlementUsageDetailsResponse[]>(
        `${licenseUsageUrl}/pools/details`,
        { body: usageDetails },
    );
}
