import type { MessageTemplateName } from '../../common/constants/MessageTemplateConstant';
import type {
    ICreateMessageTemplateDto,
    IMessageTemplate,
} from '../../common/interfaces/cis/message-template';
import {
    get,
    post,
    put,
} from '../utility/Requests.default';

const messageTemplateUrl = `${process.buildConfigs.identityApiBaseRoute}/MessageTemplate`;

export function getMessageTemplate(name: MessageTemplateName) {
    try {
        return get<IMessageTemplate>(messageTemplateUrl, { urlParams: { name } });
    } catch {
        return undefined;
    }
}

export function createMessageTemplate(createMessageTemplateDto: ICreateMessageTemplateDto) {
    return post<IMessageTemplate>(messageTemplateUrl, { body: createMessageTemplateDto });
}

export function updateMessageTemplate(messageTemplate: IMessageTemplate) {
    const { id } = messageTemplate;
    return put<IMessageTemplate>(`${messageTemplateUrl}/${id}`, { body: messageTemplate });
}
