import './index.css';

import { initializeTelemetryPortal } from '@experiences/telemetry';
import React from 'react';
import ReactDOM from 'react-dom';

import AppWrapper from './container/AppWrapper.default';
// @ts-ignore
import * as serviceWorker from './serviceWorker';

document.title = 'UiPath';

initializeTelemetryPortal();

ReactDOM.render(<AppWrapper />, document.getElementById('apollo-shell'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
