import { useShowDialog } from '@experiences/util';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import TenantTagsWarningDialogBody from './TenantTagsWarningDialogBody';

export const useTagsCancel = (isDirty: boolean, showDialog: boolean) => {
    const createDialog = useShowDialog();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();

    const cancel = useCallback(async () => {
        let proceed = true;
        if (isDirty && showDialog) {
            proceed = await createDialog({
                title: translate({ id: 'CLIENT_UNSAVED_CHANGES' }),
                customDialogContent: TenantTagsWarningDialogBody,
                icon: 'warning',
            });
        }

        if (!proceed) {
            return;
        }
        history.goBack();

    }, [ createDialog, history, isDirty, showDialog, translate ]);
    return cancel;
};
