import { portalTelemetry } from '@experiences/telemetry';
import {
    UiLoader,
    useServiceOrderContext,
} from '@experiences/ui-common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
    Box,
    Typography,
    useTheme,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { Service } from '@uipath/portal-shell';
import {
    motion,
    Reorder,
} from 'framer-motion';
import {
    cloneDeep,
    debounce,
    pick,
} from 'lodash';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { DividerBox } from './DividerBoxComponent';
import { NavigationSettingsItem } from './NavigationSettingsItemComponent';
import { ServiceIcon } from './ServiceIconComponent';
import {
    DEBOUNCE_SAVE_KEYBOARD,
    getItemSvg,
    MORE_DIVIDER,
    orderByDivider,
} from './utils';

export const NavigationSettingsComponent: FC = () => {
    const [ services, setServices ] = useState<Service[]>([]);
    const [ home, setHome ] = useState<Service>();

    const { serviceOrder } = useServiceOrderContext();

    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        if (!serviceOrder?.length) {
            return;
        }

        const clonedServiceOrder = cloneDeep(serviceOrder);

        if (!clonedServiceOrder.find((service) => service.id === MORE_DIVIDER.id)) {
            const firstMore = clonedServiceOrder.findIndex((service) => service.isMore);
            const insertIndex = firstMore >= 0 ? firstMore : clonedServiceOrder.length;

            clonedServiceOrder.splice(insertIndex, 0, MORE_DIVIDER as any);
        }

        setServices(clonedServiceOrder.filter((service) => service.id !== 'home').map((service) => ({
            ...service,
            svg: getItemSvg(theme, service.id),
        })));

        if (!home) {
            setHome(clonedServiceOrder.find((service) => service.id === 'home'));
        }
    }, [ home, serviceOrder, theme ]);

    const saveValues = useCallback(async (saveServices: Service[]) => {
        const homeDetails = pick(home, [ 'id', 'isMore' ]);
        const savedServices = [
            homeDetails,
            ...saveServices.filter((service) => service.id !== MORE_DIVIDER.id).map((service) => pick(service, [ 'id', 'isMore' ])),
        ];

        await window.PortalShell.AccountAndTenants?.updateServiceOrder(savedServices as Array<{ id: string; isMore: boolean }>);

        portalTelemetry.trackTrace({
            message: `Custom left navigation order saved`,
            severityLevel: SeverityLevel.Information,
        }, {
            core: savedServices.filter((service) => !service.isMore).map((service) => service.id),
            more: savedServices.filter((service) => service.isMore).map((service) => service.id),
            // FIXME: This should be sent on all requests https://uipath.atlassian.net/browse/PLT-39768
            persona: localStorage.getItem('PORTAL_SHELL_PERSONA') ?? 'UNKNOWN',
        });
    }, [ home ]);

    const debouncedSaveValues = useMemo(() => debounce(saveValues, DEBOUNCE_SAVE_KEYBOARD), [ saveValues ]);

    const keyNavigation = useCallback(async (e: React.KeyboardEvent, item) => {
        const itemIndex = services.findIndex((service) => service.id === item.id);

        const move = (up: boolean) => {
            if (up && itemIndex === 0) {
                return;
            }

            if (!up && itemIndex === services.length - 1) {
                return;
            }

            const newServices = cloneDeep(services);
            newServices.splice(itemIndex + (up ? -1 : 1), 0, newServices.splice(itemIndex, 1)[0]);

            const finalOrder = orderByDivider(newServices);

            setServices(finalOrder);
            debouncedSaveValues(finalOrder);
        };

        // moves the item up in the list
        if (e.key === 'ArrowUp') {
            move(true);
        }

        // moves the item down in the list
        if (e.key === 'ArrowDown') {
            move(false);
        }
    }, [ services, debouncedSaveValues ]);

    const onReorder = useCallback(async (newOrder) => {
        setServices(orderByDivider(newOrder));
    }, []);

    const strong = (chunk: string) => <strong>
        {chunk}
    </strong>;

    if (!serviceOrder?.length) {
        return <UiLoader />;
    }

    return (
        <Box sx={{ marginLeft: '25px' }}>
            <Box>
                <Typography sx={{
                    fontSize: Tokens.FontFamily.FontLSize,
                    fontFamily: Tokens.FontFamily.FontNormal,
                    fontWeight: Tokens.FontFamily.FontWeightSemibold,
                    lineHeight: Tokens.FontFamily.FontLLineHeight,
                    color: theme.palette.semantic.colorForeground,
                }}>
                    {translate({ id: 'CLIENT_NAVIGATION_SETTINGS' })}
                </Typography>
                <Typography sx={{
                    fontSize: Tokens.FontFamily.FontMSize,
                    fontFamily: Tokens.FontFamily.FontNormal,
                    fontWeight: Tokens.FontFamily.FontWeightDefault,
                    lineHeight: Tokens.FontFamily.FontMLineHeight,
                    color: theme.palette.semantic.colorForeground,
                    whiteSpace: 'pre-wrap',
                }}>
                    <FormattedMessage
                        id="CLIENT_NAVIGATION_SETTINGS_DESCRIPTION"
                        values={{ strong }}
                    />
                </Typography>
            </Box>
            <motion.div
                layoutScroll
                style={{
                    width: 'fit-content',
                    maxHeight: 'calc(100vh - 250px)',
                    minHeight: '200px',
                    overflowY: 'auto',
                    marginTop: '24px',
                }}
            >
                <DividerBox text={translate({ id: 'CLIENT_NAVIGATION_SETTINGS_VISIBLE_ITEMS' })} />
                { home && (
                    <Box sx={{
                        margin: 0,
                        width: '248px',
                        height: '48px',
                        position: 'relative',
                        borderRadius: 5,
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: theme.palette.semantic.colorBackground,
                        padding: '0 16px',
                    }}>
                        <ServiceIcon svg={getItemSvg(theme, 'home', true)} />

                        <Box sx={{ height: '24px' }}>
                            <Typography sx={{ color: theme.palette.semantic.colorForegroundDisable }}>
                                { home.name }
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Reorder.Group
                    axis="y"
                    onReorder={onReorder}
                    values={services}
                    style={{
                        borderRadius: 10,
                        padding: 0,
                        width: '248px',
                        marginTop: 0,
                        position: 'relative',
                    }}>
                    {services.map((item) => (
                        <NavigationSettingsItem
                            keyNavigation={keyNavigation}
                            dragEnd={() => saveValues(services)}
                            key={item.id}
                            item={item} />
                    ))}
                </Reorder.Group>
            </motion.div>
        </Box>
    );
};
