import type { UserManagerSettings } from 'oidc-client-ts';
import { UserManager } from 'oidc-client-ts';
import {
    useCallback,
    useMemo,
} from 'react';

export const Auth0UserManagerSettings: UserManagerSettings = {
    authority: `https://${window.env?.DOMAIN}/v2/`,
    client_id: window.env?.API_ACCESS_CLIENT_ID || 'ADLqfy4M6cRUaEL6XOxiKpMqyPvjTuUi',
    redirect_uri: `${window.location.origin}/portal_/requestusertokencallback`,
    response_type: 'code',
    scope: 'openid profile email offline_access',
    extraQueryParams: { audience: 'https://orchestrator.cloud.uipath.com' },
};

export function useRequestUserToken() {

    const manager = useMemo(() => new UserManager(Auth0UserManagerSettings), []);

    const getRefreshToken = useCallback(async () => {
        try {
            const user = await manager.signinSilent();
            return user;
        } catch (error) {
            // if there's an error, the refresh_token has been revoked.
            // therefore, we remove the old tokens and sign in again
            await manager.removeUser();
            return manager.signinSilent();
        }
    }, [ manager ]);

    return { getRefreshToken };
}
