import {
    UiProgressButton,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useModalState,
    useRouteResolver,
} from '@experiences/util';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    FormGroup,
    MenuItem,
    Select,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import produce from 'immer';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import useSWR, { mutate } from 'swr';

import { ResourceToHiddenScopes } from '../../../common/constants/ResourceToHiddenScopes';
import * as RouteNames from '../../../common/constants/RouteNames';
import type {
    IGridResource,
    IResource,
} from '../../../common/interfaces/cis/client';
import {
    externalApiResourceUrl,
    getExternalApiResources,
} from '../../../services/identity/ExternalApiResourceService';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IExternalAppDataContext } from './CreateEditExternalApplicationsPageComponent';
import type { IResourceForm } from './ScopesController';
import ScopeController from './ScopesController';

const useStyles = makeStyles(theme =>
    createStyles({
        body: {
            margin: '0px 24px',
            flex: '1 1 100%',
        },
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        searchBar: { width: '100%' },
        searchIcon: { marginRight: '4px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const ScopesTab = {
    USER: 'user',
    APPLICATION: 'application',
};

const TabPanel: React.FC<{
    value: string;
    resource?: IGridResource;
    selectedResource?: IResource;
    scopeSearch: string;
}> = ({
    value, resource, selectedResource, scopeSearch,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    switch (value) {
        case ScopesTab.USER:
            return (
                <div className={classes.input}>
                    {!selectedResource ? (
                        <Typography>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE_DESCRIPTION' })}
                        </Typography>
                    ) : (
                        <FormGroup>
                            <ScopeController
                                name="userScopes"
                                resource={resource}
                                selectedResource={selectedResource}
                                scopeSearch={scopeSearch}
                            />
                        </FormGroup>
                    )}
                </div>
            );
        case ScopesTab.APPLICATION:
            return (
                <div className={classes.input}>
                    {!selectedResource ? (
                        <Typography>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_APPLICATION_SCOPE_DESCRIPTION' })}
                        </Typography>
                    ) : (
                        <FormGroup>
                            <ScopeController
                                name="applicationScopes"
                                resource={resource}
                                selectedResource={selectedResource}
                                scopeSearch={scopeSearch}
                            />
                        </FormGroup>
                    )}
                </div>
            );
    }

    return null;
};

const ExternalApplicationsScopesDrawerComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const match = useRouteMatch<{ type: 'add' | 'edit'; id: string; name: string }>();

    const {
        type, id, name,
    } = useMemo(() => ({
        type: match.params.type,
        id: match.params.id,
        name: match.params.name,
    }), [ match ]);

    const {
        data: externalResources,
        error: resourcesError,
    } = useSWR<IResource[], Error>(externalApiResourceUrl, getExternalApiResources);

    const {
        data: contextData, setData,
    } = useUiDataContext<IExternalAppDataContext>();

    const {
        resources, confidential,
    } = useMemo(() => ({
        resources: contextData?.resources,
        confidential: contextData?.confidential,
    }), [ contextData?.confidential, contextData?.resources ]);

    const editResource: IGridResource | undefined = useMemo(() => {
        if (!name) {
            return undefined;
        }

        const matchResource = resources?.find(resourceIterator => resourceIterator.name === name);
        if (!matchResource) {
            return undefined;
        }
        return {
            name: matchResource.name,
            userScopes: matchResource.userScopes,
            applicationScopes: matchResource.applicationScopes,
        };
    }, [ name, resources ]);
    const filteredResources = useMemo(() => {
        const filteredExternalResources: IResource[] | undefined = !name
            ? externalResources?.filter(resourceIterator => !resources?.find(scope => scope.name === resourceIterator.name))
            : externalResources;

        return filteredExternalResources?.map(rsc => {
            if (ResourceToHiddenScopes.has(rsc.name)) {
                const hiddenScopes = ResourceToHiddenScopes.get(rsc.name);
                const selectedUserScopes = resources?.find(resource => resource.name === name)?.userScopes ?? [];
                rsc.scopes = rsc.scopes.filter(scope => selectedUserScopes.includes(scope.name) || !hiddenScopes?.includes(scope.name));
            }
            return rsc;
        });
    }, [ externalResources, name, resources ]);

    const history = useHistory();
    const getRoute = useRouteResolver();
    const { open } = useModalState(`${RouteNames.ExternalApplications}/${type}`);

    const close = useCallback(() => {
        history.push(getRoute(`${RouteNames.ExternalApplications}/${type}/${type === 'edit' ? id : ''}`));
    }, [ getRoute, history, id, type ]);

    const [ loading, setLoading ] = useState(false);
    const [ selectedResource, setSelectedResource ] = useState<IResource>();
    const [ scopeSearch, setScopeSearch ] = useState<string>('');
    const [ tabValue, setTabValue ] = useState<string>(ScopesTab.USER);

    const methods = useForm<IResourceForm>({
        mode: 'onSubmit',
        shouldUnregister: false,
        defaultValues: {
            name: undefined,
            userScopes: [],
            applicationScopes: [],
        },
    });

    const {
        control, handleSubmit, reset, formState, watch,
    } = useMemo(() => methods, [ methods ]);

    const {
        isDirty, errors,
    } = formState;

    const {
        applicationScopes, userScopes,
    } = useMemo(() => watch([ 'applicationScopes', 'userScopes' ]), [ watch ]);

    useEffect(() => {
        if (name && editResource) {
            setSelectedResource(filteredResources?.find(external => external.name === editResource.name));
            reset({
                name: editResource,
                userScopes: editResource.userScopes,
                applicationScopes: editResource.applicationScopes,
            });
        }
    }, [ editResource, filteredResources, name, reset ]);

    const handleChangeSelect = useCallback(
        (event, onChange) => {
            const pickedResource = filteredResources?.find(resource => resource.name === (event.target.value as string));
            setSelectedResource(pickedResource);
            onChange(pickedResource);
            if (editResource) {
                reset({
                    name: undefined,
                    userScopes: editResource.userScopes,
                    applicationScopes: editResource.applicationScopes,
                });
            } else {
                reset({
                    name: undefined,
                    userScopes: [],
                    applicationScopes: [],
                });
            }
        },
        [ editResource, filteredResources, reset ],
    );

    const onSubmit = useCallback(
        async (data: IResourceForm) => {
            setLoading(true);

            const resource: IGridResource = {
                name: data.name?.name ?? '',
                userScopes: data.userScopes ?? [],
                applicationScopes: data.applicationScopes ?? [],
            };

            setLoading(false);
            setData(produce(contextData, draftState => {
                if (!draftState.resources) {
                    draftState.resources = [];
                }

                const changedResource = draftState.resources.find(resourceIterator => resourceIterator.name === resource.name);
                if (!changedResource) {
                    draftState.resources.push(resource);
                    return;
                }

                changedResource.userScopes = resource.userScopes;
                changedResource.applicationScopes = resource.applicationScopes;
            }));
            close();
        },
        [ close, contextData, setData ],
    );

    return (
        <UiDrawer
            title={
                name
                    ? translate({ id: 'CLIENT_EXTERNAL_APP_EDIT_RESOURCE' })
                    : translate({ id: 'CLIENT_EXTERNAL_APP_ADD_RESOURCE' })
            }
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            loading={!externalResources}
            themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
        >
            {resourcesError ? (
                <div className={classes.body}>
                    <Typography>
                        {translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' })}
                    </Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            mutate(externalApiResourceUrl);
                        }}
                        style={{ marginTop: '12px' }}
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                </div>
            ) : (
                <FormProvider {...methods}>
                    <UiForm
                        onSubmit={handleSubmit(onSubmit)}
                        actions={
                            <div className={classes.actions}>
                                <Button
                                    data-cy="scopes-add-edit-cancel-button"
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary">
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={
                                        !isDirty
                                            || Object.keys(errors).length > 0
                                            || (userScopes?.length ?? 0) + (applicationScopes?.length ?? 0) <= 0
                                    }
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="scopes-add-edit-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        }
                        isDrawer
                        addScrollPadding
                    >
                        <div className={classes.input}>
                            <Typography
                                id="scopes-resource-name-label"
                                className={clsx(classes.inputLabel, classes.inputMargin)}>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPES_RESOURCE' })}
                            </Typography>
                            <Controller
                                render={({
                                    onChange, value,
                                }) => (
                                    <Select
                                        labelId="scopes-resource-name-label"
                                        value={value?.name || selectedResource?.name || ''}
                                        onChange={event => handleChangeSelect(event, onChange)}
                                        variant="outlined"
                                        fullWidth
                                        disabled={!!name}
                                        data-cy="scope-resource-select"
                                    >
                                        {filteredResources?.map(resource => (
                                            <MenuItem
                                                key={`resource-select-${resource.name}`}
                                                value={resource.name}>
                                                {resource.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 256,
                                }}
                                error={!!errors.name}
                                name="name"
                                data-cy="scopes-resource-name"
                            />
                        </div>
                        <Tabs
                            value={tabValue}
                            className={clsx('default', classes.input)}
                            onChange={(_, newValue: string) => setTabValue(newValue)}
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab
                                value={ScopesTab.USER}
                                label={translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE' })}
                                data-cy="scopes-user-tab"
                                className="default"
                            />
                            {confidential && (
                                <Tab
                                    value={ScopesTab.APPLICATION}
                                    label={translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_SCOPE' })}
                                    data-cy="scopes-application-tab"
                                    className="default"
                                />
                            )}
                        </Tabs>
                        <div className={classes.input}>
                            <TextField
                                className={classes.searchBar}
                                variant="outlined"
                                placeholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                                InputProps={{ startAdornment: <SearchIcon className={classes.searchIcon} /> }}
                                value={scopeSearch}
                                onChange={e => setScopeSearch(e.target.value)}
                                data-cy="scope-searchBar"
                            />
                        </div>
                        <TabPanel
                            value={tabValue}
                            resource={editResource}
                            selectedResource={selectedResource}
                            scopeSearch={scopeSearch}
                        />
                    </UiForm>
                </FormProvider>
            )}
        </UiDrawer>
    );
};

export default ExternalApplicationsScopesDrawerComponent;
