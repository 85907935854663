import type { ICampaigns } from '@experiences/interfaces';

export const abTestingUrl = '/api/abTesting';

export const Campaigns: ICampaigns = {

    EcommercePlanSelectionDisplayedPresets: {
        name: 'ecommerce_plan_selection_displayed_presets',
        layer: { name: 'ecommerce-offered-presets-layer' },
        parameters: { ecommerceOfferedPresetsParameter: { name: 'ecommerce-offered-presets' } },
        variations: {
            OneSingleOfferedPreset: { name: 'VARIATION_1' },
            ThreeOfferedPresets: { name: 'VARIATION_2' },
        },
        defaultVariation: { name: 'CONTROL' },
    },

};

export const getCampaignByKey = (campaignKey: string) => Object.values(Campaigns).find(value => value.name === campaignKey);
