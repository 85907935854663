import { GlobalStyles } from '@experiences/theme';
import {
    Chip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        authSettingsPage: { marginTop: '14px' },
        button: {
            width: 'fit-content',
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
        content: { padding: '0 12px' },
        header: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            marginRight: '8px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        switchLabel: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        description: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '4px',
        },
        switchDescriptionPadding: { marginLeft: '46px' },
        signInContainer: { marginBottom: '24px' },
        headerContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '8px',
        },
        subtext: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
        },
        configuredSSO: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
        },
        spacer: { marginBottom: '8px' },
    }),
}));

const DirectoryIntegration: FC<{
    title?: string;
    titleSubtext?: string;
    description?: React.ReactNode;
    actions?: React.ReactNode;
    enableChip?: boolean;
}> = (props: any) => {
    const {
        title,
        titleSubtext,
        description,
        actions,
        enableChip,
        ...rest
    } = props;
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div
            aria-label='directory-integration'
            {...rest}
        >
            <div className={clsx(classes.configuredSSO, classes.spacer)}>
                <Typography
                    role='heading'
                    aria-level={2}
                    className={classes.header}
                    data-cy='directory-integration-title'>
                    {title}
                </Typography>
                {enableChip && <Chip
                    style={{ marginRight: '8px' }}
                    variant="outlined"
                    size="small"
                    label={translate({ id: 'CLIENT_ENABLED' })}
                    className="success-mini"
                    data-cy='directory-integration-enabled-chip' />}
                <Typography
                    className={classes.subtext}
                    data-cy='directory-integration-subtext'>
                    {titleSubtext}
                </Typography>
            </div>
            <div
                data-cy="directory-integration-description"
                className={classes.spacer}>
                {description}
            </div>
            <div data-cy='directory-integration-actions'>
                {actions}
            </div>
        </div>
    );
};

export default DirectoryIntegration;
