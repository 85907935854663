import type { LogExportInsStorageType } from '../../component/tenants/subcomponents/constant/InsightsLogExportConstant';
import type { LogExportConnectionDetails } from '../../component/tenants/subcomponents/InsightsLogExportComponent/ConnectionDetailsType';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

const getHeaders = (selectedTenantId: string, selectedAccontId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
    'X-UiPath-Internal-AccountId': selectedAccontId,
});

export const exportLogsUrl = `/api/insights/logsexport`;

export interface IExportLogsData {
    tenantKey: string;
    storageType: LogExportInsStorageType;
    connectionDetails: LogExportConnectionDetails;
}

export async function getLogsExportDetails({
    tenantId, accountId, tenantName, accountLogicalName,
}: { tenantId: string; accountId: string; tenantName: string; accountLogicalName: string }) {

    return await get<IExportLogsData>(exportLogsUrl, {
        urlParams: {
            accountLogicalName,
            tenantName,
        },
        headers: getHeaders(tenantId, accountId),
    });
}

export function postLogsExportDetails({
    body, tenantId, accountId, tenantName, accountLogicalName,
}: { body: IExportLogsData; tenantId: string; accountId: string; tenantName: string; accountLogicalName: string }) {

    return post<IExportLogsData>(exportLogsUrl, {
        body,
        urlParams: {
            accountLogicalName,
            tenantName,
        },
        headers: getHeaders(tenantId, accountId),
    });
}

export function deleteLogsExportDetails({
    tenantId, accountId, tenantName, accountLogicalName,
}: { tenantId: string; accountId: string; tenantName: string; accountLogicalName: string }) {
    return axiosDelete(exportLogsUrl, {
        urlParams: {
            accountLogicalName,
            tenantName,
        },
        headers: getHeaders(tenantId, accountId),
    });
}
