import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import type { IconButtonProps } from '@mui/material/IconButton';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { styled } from '@mui/system';
import { random } from 'lodash';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { contentfulSlide } from '../../common/interfaces/slide';
import {
    carouselURL,
    getContentfulResource,
} from '../../services/CarouselCMS';
import { persona } from '../../store/selectors';
import { rotateArray } from './CarouselUtil';
import { CarouselWrapper } from './CarouselWrapper';
import type { CarouselSlideParameters } from './types/CarouselSlideParameters';

export const CustomButtonFilled = styled(IconButton)(({ theme }) => ({
    color: theme.palette.semantic.colorPrimary,
    height: '12px',
    width: '12px',
}));

export const CustomButtonUnfilled = styled(IconButton)<IconButtonProps>(
    ({ theme }) => ({
        color: theme.palette.semantic.colorForegroundLight,
        height: '12px',
        width: '12px',
        '&:hover': { color: theme.palette.semantic.colorPrimary },
    }),
);

export const CustomIconFilled = styled(CircleIcon)(({ theme }) => ({
    color: theme.palette.semantic.colorForegroundDark,
    height: '12px',
    width: '12px',
    strokeWidth: '1px',
}));

export const CustomIconUnfilled = styled(CircleOutlinedIcon)`
    width: 12px;
    height: 12px;
`;

export const useStyles = makeStyles((theme) =>
    createStyles({
        carouselInner: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            rowGap: '20px',
        },
        carouselSlidesBackground: {
            backgroundColor: theme.palette.semantic.colorToggleOffHover,
            width: '100%',
            height: '232px',
            padding: '0px',
            overflow: 'hidden',
            borderRadius: '8px',
        },
        carouselSlideDisplay: {
            width: '100%',
            height: '232px',
            maxHeight: '232px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            transition: 'transform .5s',
            gap: '1%',
        },
        carouselButtonDisplay: {
            display: 'flex',
            justifyContent: 'center',
            columnGap: '20px',
            width: '100%',
            height: '10px',
            alignItems: 'flex-start',
            marginBottom: '16px',
        },
        carouselSlideBackground: {
            width: '100%',
            minWidth: '100%',
            height: '232px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        },
    }),
);

const Carousel = () => {
    const carouselMinWidth = useMediaQuery('(min-width:768px)');
    const language = useLocalization();
    const EnableHomePageCarousel = useFeatureFlagValue(Features.EnableHomePageCarousel.name);
    const [ isOpen, setOpen ] = useState(localStorage.getItem('cloudRPA_carousel_enabled') !== 'false');

    const dominantPersona = useSelector(persona);

    const [ slides, setSlides ] = useState<CarouselSlideParameters[]>([]);
    const { data } = useSWR(
        EnableHomePageCarousel ? [ language, dominantPersona, carouselURL ] : null,
        getContentfulResource,
    );

    const initialStartIdx = () => Number(localStorage.getItem('cloudRPA_carousel_start_index') ?? -1);
    const [ startIdx, setStartIdx ] = useState(initialStartIdx);

    const EnableHomePageCarouselRotation = useFeatureFlagValue(
        Features.EnableHomePageCarouselRotation.name,
    );

    const carouselSlidesDataArray = useMemo(() => {
        if (data) {
            return data.homePageCarousel.slidesCollection.items
                .filter((obj) => obj != null)
                .map((obj: contentfulSlide) => ({
                    ...obj,
                    lineImage: obj.lineImage ? obj.lineImage.url : undefined,
                    backgroundImage: obj.backgroundImage
                        ? obj.backgroundImage.url
                        : undefined,
                }));
        }
    }, [ data ]);

    useEffect(() => {
        if (carouselSlidesDataArray !== undefined) {
            let rotatedArray = carouselSlidesDataArray;

            if (EnableHomePageCarouselRotation) {
                if (startIdx === -1) {
                    setStartIdx(random(0, carouselSlidesDataArray.length - 1));
                }

                const newStartIdx = (startIdx + 1) % carouselSlidesDataArray.length;

                // Rotate array based on startIdx that is either fetched or generated randomly
                rotatedArray = rotateArray(carouselSlidesDataArray, newStartIdx);

                localStorage.setItem('cloudRPA_carousel_start_index', `${newStartIdx}`);
            }

            setSlides(rotatedArray);
        }

    }, [ startIdx, carouselSlidesDataArray, EnableHomePageCarouselRotation ]);

    return (
        <>
            {isOpen && slides.length !== 0 && carouselMinWidth && (
                <CarouselWrapper
                    slides={slides}
                    setOpen={setOpen} />
            )}
        </>
    );
};

export default Carousel;
