import { get } from '../utility/Requests.default';

export const processesUrl = `/api/orchestrator/processes`;

export function getProcesses(tenantName: any, accountLogicalName: string) {
    return get(processesUrl, {
        urlParams: {
            accountLogicalName,
            tenantName,
        },
    });
}

export function getProcessesCount(
    duration: any,
    tenantName: any,
    accountLogicalName: string,
    currentOrchestratorId: number,
) {
    return get(`${processesUrl}/getProcessCount`, {
        urlParams: {
            accountLogicalName,
            tenantName,
            currentOrchestratorId,
            duration,
        },
    });
}

export function getProcessesRecentRuns(tenantName: any, accountLogicalName: string, currentOrchestratorId: number) {
    return get(`${processesUrl}/recentRuns`, {
        urlParams: {
            accountLogicalName,
            tenantName,
            currentOrchestratorId,
        },
    });
}
