import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import downloadResources from '../../../common/constants/DownloadResources';
import { ReactComponent as Arrow } from '../../../images/Arrow.svg';
import { ReactComponent as AutomationIcon } from '../../../images/AutomationIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../images/DownloadIcon.svg';
import { ReactComponent as FreBanner } from '../../../images/freBanner.svg';
import { ReactComponent as SignInIcon } from '../../../images/SignInIcon.svg';
import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { studioDownloadMarketoUpdate } from '../../../services/licensing/MarketoService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    profile,
    userGlobalId,
} from '../../../store/selectors';
import {
    logStudioDownload,
    StudioDownload,
} from '../../../telemetry';
import UiFREBanner from '../../common/UiFREBanner';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        fre2diagram: {
            display: 'flex',
            flexDirection: 'row',
        },
        detailsTextSmall: {
            fontSize: '16px',
            fontWeight: 400,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        detailsTextLarge: {
            fontSize: '32px',
            fontWeight: 400,
            color: theme.palette.semantic.colorForeground,
        },
        detailsTextSubscript: {
            fontSize: '14px',
            fontWeight: 400,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        svgIconOtherColors: {
            '& .iconBlue': { stroke: theme.palette.semantic.colorPrimary },
            '& .iconOrange': { stroke: theme.palette.semantic.colorForegroundHigh },
            '& .iconOrangeFill': { fill: theme.palette.semantic.colorForegroundHigh },
        },
        downloadIcon: {
            marginBottom: '24px',
            marginRight: '48px',
        },
        automationIcon: {
            marginBottom: '18px',
            marginLeft: '48px',
        },
        signInIcon: {
            marginBottom: '24px',
            marginLeft: '48px',
            marginRight: '48px',
        },
    }),
}));

export const FREBannerHelper: React.FC<{
    partitionGlobalId: string;
    userGroupIds: string[];
    setHideSidebar: (value: boolean) => void;
}> = ({
    partitionGlobalId, userGroupIds, setHideSidebar,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const accountTypeSelector = useSelector(accountType);
    const profileState = useSelector(profile);
    const accountGUID = useSelector(accountGlobalId);
    const logicalName = useSelector(accountLogicalName);
    const currentAccountLanguage = useLocalization();
    const userId = useSelector(userGlobalId);

    const [ displayFREBanner, setDisplayFREBanner ] = useState(false);
    const [ FREBannerState, setFREBannerState ] = useState(parseInt(sessionStorage.getItem('FREBannerState') ?? '0'));

    // On first load, we want to check the Settings table to check whether to enable FREBanner
    useEffect(() => {
        (async () => {
            const result = await getSetting(settingUrl, [ 'displayFREBanner' ], partitionGlobalId, userId);
            const getSettingWithKey = !!result?.length && result.find(setting => setting.key === 'displayFREBanner');

            if (getSettingWithKey) {
                setDisplayFREBanner(getSettingWithKey.value === 'true');
                setHideSidebar(getSettingWithKey.value === 'true');
            } else {
                setDisplayFREBanner(false);
                setHideSidebar(false);
            }
        })();
    }, [ accountTypeSelector, partitionGlobalId, setDisplayFREBanner, setHideSidebar, userGroupIds, userId ]);

    const closeFREBanner = useCallback(async () => {
        await saveSetting(settingUrl, {
            settings: [
                {
                    key: 'displayFREBanner',
                    value: 'false',
                },
            ],
            partitionGlobalId,
            userId,
        });
        setDisplayFREBanner(false);
        setHideSidebar(false);
    }, [ partitionGlobalId, setHideSidebar, userId ]);

    const updateMarketo = useCallback(
        async (editionType: string) => {
            await studioDownloadMarketoUpdate(editionType, profileState, accountGUID, logicalName, currentAccountLanguage);
        },
        [ profileState, accountGUID, logicalName, currentAccountLanguage ],
    );

    const studioEditionAndLink: {
        edition: 'Enterprise Cloud' | 'Enterprise Trial' | 'Community Edition';
        link: string;
    } = useMemo(() => {
        switch (accountTypeSelector) {
            case 'ENTERPRISE':
                return {
                    edition: 'Enterprise Cloud',
                    link: downloadResources.ENTERPRISE_CLOUD_STUDIO_DOWNLOAD_LINK,
                };
            case 'TRIAL':
                return {
                    edition: 'Enterprise Trial',
                    link: downloadResources.ENTERPRISE_TRIAL_STUDIO_DOWNLOAD_LINK,
                };

            case 'COMMUNITY':
            default:
                return {
                    edition: 'Community Edition',
                    link: downloadResources.COMMUNITY_STUDIO_DOWNLOAD_LINK,
                };
        }
    }, [ accountTypeSelector ]);

    const clickDownloadStudioButton = useCallback(async () => {
        enqueueSnackbar(translate({ id: 'CLIENT_DOWNLOAD_IN_PROGRESS' }), { variant: notificationType.INPROGRESS } as any);
        await logStudioDownload(studioEditionAndLink.edition, StudioDownload.FREBanner, true);
        updateMarketo(studioEditionAndLink.edition);
    }, [ enqueueSnackbar, translate, updateMarketo, studioEditionAndLink ]);

    const getFREBannerFromState = useMemo(() => {
        switch (FREBannerState) {
            case 0: {
                return (
                    <UiFREBanner
                        summary={translate({ id: 'CLIENT_FRE_BANNER_SUMMARY_1' })}
                        details={
                            <>
                                <Typography
                                    variant="body1"
                                    className={classes.detailsTextSmall}
                                    style={{ marginBottom: '8px' }}>
                                    {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_1_1' })}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    className={classes.detailsTextLarge}
                                    style={{ marginBottom: '24px' }}>
                                    {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_1_2' })}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className={classes.detailsTextSmall}>
                                    {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_1_3' })}
                                </Typography>
                            </>
                        }
                        image={<FreBanner />}
                        action={
                            <Button
                                href={downloadResources.getDownloadURL(studioEditionAndLink.link)}
                                onClick={() => {
                                    clickDownloadStudioButton();
                                    sessionStorage.setItem('FREBannerState', '1');
                                    setFREBannerState(1);
                                }}
                                variant="contained"
                                color="primary"
                                target="_blank"
                                data-cy="ui-fre-banner-download-button"
                            >
                                {translate({ id: 'CLIENT_FRE_DOWNLOAD_UIPATH_STUDIO' })}
                            </Button>
                        }
                        close={closeFREBanner}
                    />
                );
            }
            case 1: {
                return (
                    <UiFREBanner
                        summary={translate({ id: 'CLIENT_FRE_BANNER_SUMMARY_2' })}
                        details={
                            <>
                                <Typography
                                    variant="body1"
                                    className={classes.detailsTextSmall}
                                    style={{ marginBottom: '8px' }}>
                                    {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_2_1' })}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    className={classes.detailsTextLarge}
                                    style={{ marginBottom: '24px' }}>
                                    {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_2_2' })}
                                </Typography>
                                <div
                                    className={classes.fre2diagram}
                                    data-cy="ui-fre-banner-diagram">
                                    <div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <DownloadIcon className={clsx(classes.svgIconOtherColors, classes.downloadIcon)} />
                                            <Arrow className={classes.svgIcon} />
                                        </div>
                                        <Typography
                                            variant="body1"
                                            className={classes.detailsTextSubscript}
                                            style={{ maxWidth: '150px' }}>
                                            {translate(
                                                { id: 'CLIENT_FRE_BANNER_DETAILS_2_3' },
                                                {
                                                    0: (
                                                        <a
                                                            className={classes.a}
                                                            href={downloadResources.getDownloadURL(studioEditionAndLink.link)}
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                            onClick={clickDownloadStudioButton}
                                                        >
                                                            {translate({ id: 'CLIENT_FRE_BANNER_CLICK_HERE' })}
                                                        </a>
                                                    ),
                                                },
                                            )}
                                        </Typography>
                                    </div>
                                    <div>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <SignInIcon className={clsx(classes.svgIconOtherColors, classes.signInIcon)} />
                                            <Arrow className={classes.svgIcon} />
                                        </div>
                                        <Typography
                                            variant="body1"
                                            className={classes.detailsTextSubscript}
                                            style={{
                                                maxWidth: '150px',
                                                marginLeft: '48px',
                                            }}
                                        >
                                            {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_2_4' })}
                                        </Typography>
                                    </div>
                                    <div>
                                        <AutomationIcon className={clsx(classes.svgIconOtherColors, classes.automationIcon)} />
                                        <Typography
                                            variant="body1"
                                            className={classes.detailsTextSubscript}
                                            style={{
                                                maxWidth: '150px',
                                                marginLeft: '48px',
                                            }}
                                        >
                                            {translate({ id: 'CLIENT_FRE_BANNER_DETAILS_2_5' })}
                                        </Typography>
                                    </div>
                                </div>
                            </>
                        }
                        image={<FreBanner style={{ marginTop: '26px' }} />}
                        close={closeFREBanner}
                    />
                );
            }
        }

        return null;
    }, [ FREBannerState, classes, clickDownloadStudioButton, closeFREBanner, translate, studioEditionAndLink ]);

    return displayFREBanner ? getFREBannerFromState : null;
};
