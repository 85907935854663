import type {
    DeleteType,
    IUiDialogHookCustomContent,
} from '@experiences/interfaces';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonInner: { width: '100px' },
    }),
);

const TenantTagsDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, deleteType, taggedObjectsCount,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const warningContent = useMemo(() => {
        switch (deleteType as DeleteType) {
            case 'Label': {
                return <Typography>
                    {translate(
                        { id: 'CLIENT_TENANT_TAGS_DELETE_LABEL_WARNING' },
                        { number: taggedObjectsCount },
                    )}
                </Typography>;
            }
            case 'Property': {
                return <Typography>
                    {translate(
                        { id: 'CLIENT_TENANT_TAGS_DELETE_PROPERTY_WARNING' },
                        { number: taggedObjectsCount },
                    )}
                </Typography>;

            }
            case 'Value': {
                return <Typography>
                    {translate(
                        { id: 'CLIENT_TENANT_TAGS_DELETE_VALUE_WARNING' },
                    )}
                </Typography>;
            }
            default: {
                return <></>;
            }
        }
    }, [ taggedObjectsCount, translate, deleteType ]);

    return (
        <>
            {warningContent}
            <div className={classes.buttonContainer}>
                <Button
                    className={clsx(classes.button, classes.buttonInner)}
                    color="primary"
                    data-cy="tenant-tags-dialog-cancel-button"
                    onClick={() => closeDialog(false)}
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiDeleteButton
                    className={classes.button}
                    data-cy="tenant-tags-dialog-delete-button"
                    innerButtonClass={classes.buttonInner}
                    loading={false}
                    onClick={() => closeDialog(true)}
                    variant="contained"
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiDeleteButton>
            </div>
        </>
    );
};

export default TenantTagsDeleteDialogBody;
