import type { Region } from '@experiences/constants';
import type {
    AccountType,
    IPortalUser,
    Persona,
} from '@experiences/interfaces';
import type { LanguageCulture } from '@experiences/locales';

import { UserRole } from '../../common/constants/Constant';
// TODO: add postfix selector to all selector variable names
export type UserRoleType = 'DEFAULT_USER' | 'TENANT_ADMIN' | 'ACCOUNT_ADMIN' | 'ACCOUNT_OWNER';

export const organizationLanguage = (state: any) => state.profile.accountUserDto.accountLanguage as LanguageCulture;
export const organizationRegion = (state: any) => state.profile.accountUserDto.region as Region;
export const companyName = (state: any) => state.profile.accountUserDto.companyName as string;
export const accountLogicalName = (state: any) => state.profile?.accountUserDto?.accountLogicalName as string;
export const accountCreatedOn = (state: any) => state.profile?.accountUserDto?.createdOn as string;
export const accountGlobalId = (state: any) => state.profile?.accountUserDto?.accountGlobalId.toLowerCase() as string;
export const concurrentLicensesOpted = (state: any) => state.profile.accountUserDto.concurrentLicenseOpted as boolean;
export const firstNameSelector = (state: any) => state.profile.accountUserDto.firstName as string;
export const lastNameSelector = (state: any) => state.profile.accountUserDto.lastName as string;
export const groupIdsForUser = (state: any) => state.profile.groups as string[];
export const countrySelector = (state: any) => state.profile?.accountUserDto?.country as string;
export const emailSelector = (state: any) => (state.profile as IPortalUser).emailId;
export const accountRoleType = (state: any) => state.profile?.accountRoleType as UserRoleType;
export const persona = (state: any) => state.profile.persona as Persona;
export const profile = (state: any) => state.profile as IPortalUser;
export const isAdminSelector = (state: any) => state.profile?.accountRoleType !== UserRole.DEFAULT_USER;
export const accountType = (state: any) => state.profile?.accountUserDto?.accountType as AccountType;
export const accountPictureUrlSelector = (state: any) => state.profile?.picture as string;

export const isAdmin = (state: any) => state.profile?.accountRoleType !== UserRole.DEFAULT_USER;
export const userGlobalId = (state: any) => state.profile.userGlobalId as string;

export const EnableUserLicensingSelector = (state: any) =>
    (!process.buildConfigs.disableUserLicensing && state.profile.enableUserLicensing) as boolean;
export const isUnlicensedSelector = (state: any) =>
    (process.buildConfigs.enableUnlicensedMode &&
    state.profile.accountUserDto?.licenseCode?.toLowerCase() === 'unlicensed') as boolean;

export const isHostModeSelector = (state: any) =>
    (process.buildConfigs.enableHostMode && state.profile?.isHostMode) as boolean;

export const orchestratorUrlSelector = (state: any) => state.profile.orchestratorUrl as string | undefined;

export const adminRevampSelector = (state: any) => state.adminRevamp.adminRevampToggle as boolean;

export * from './AnnouncementsSelectors';
