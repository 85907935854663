import { useCentralErrorSetter } from '@experiences/error';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    mapADConfigDataToExternalIdentityPayload,
    mapExternalIdentityPayloadToADConfigData,
} from '../../../util/ExternalIdentityProviderUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import type { IEditIdentityProviderData } from './EditIdentityProviderFormComponent';
import EditIdentityProviderFormComponent, { defaultEditIdentityProviderData } from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
}));

const ConfigureADComponent: FC = () => {
    const { formatMessage: translate } = useIntl();

    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();
    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        open, close,
    } = useModalState(isSecuritySettingsRevampEnabled
        ? RouteNames.SecuritySettings : RouteNames.AuthSettings);

    const classes = useStyles();
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const setErrorDialog = useCentralErrorSetter();
    const [ errorMessage, setErrorMessage ] = useState<string>(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
    const { enqueueSnackbar } = useSnackbar();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const methods = useForm<IEditIdentityProviderData>({
        mode: 'onSubmit',
        defaultValues: {
            isActive: true,
            isExclusive: false,
            displayName: '',
        },
    });
    const {
        handleSubmit, formState, reset,
    } = useMemo(() => methods, [ methods ]);
    const {
        isDirty, isSubmitting,
    } = formState;
    const fetchedExternalIdentity = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: IEditIdentityProviderData) => {
            const externalIdentityPayload = mapADConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            externalIdentityPayload.isActive = true;

            try {
                await fetchedExternalIdentity
                    ? updateExternalIdentityProvider({
                        ...externalIdentityPayload,
                        partitionGlobalId,
                    })
                    : createExternalIdentityProvider({
                        ...externalIdentityPayload,
                        partitionGlobalId,
                    });
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }));
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                if (isSecuritySettingsRevampEnabled) {
                    setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
                    setShowDrawerError(true);
                }
            }
        },
        [
            fetchedExternalIdentity,
            partitionGlobalId,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            isSecuritySettingsRevampEnabled,
            setErrorDialog,
        ],
    );

    useEffect(() => {
        const mappedExternalIdentity = fetchedExternalIdentity
            ? mapExternalIdentityPayloadToADConfigData(fetchedExternalIdentity)
            : defaultEditIdentityProviderData;
        reset({ ...mappedExternalIdentity });
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: isSecuritySettingsRevampEnabled ? RouteNames.SecuritySettings : RouteNames.AuthSettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_AD_SSO_CONFIGURATION' }),
        },
    ], [ isSecuritySettingsRevampEnabled, organizationName, translate ]);

    const form = useMemo(() =>
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={clsx(classes.cancelButton)}
                        onClick={() => close()}
                        color="primary">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        type="submit"
                        loading={isSubmitting}
                        disabled={!isDirty && !isSecuritySettingsRevampEnabled}
                        variant="contained"
                        data-cy="configure-ad-submit-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            isDrawer={!isSecuritySettingsRevampEnabled}
            centerChild={isSecuritySettingsRevampEnabled}
            addScrollPadding={!isSecuritySettingsRevampEnabled}
        >
            <FormProvider {...methods}>
                <EditIdentityProviderFormComponent />
            </FormProvider>
        </UiForm>
    , [
        isSecuritySettingsRevampEnabled,
        classes,
        close,
        handleSubmit,
        isDirty,
        isSubmitting,
        methods,
        onSubmit,
        translate,
    ]);

    return (
        isSecuritySettingsRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                maxWidth="900px"
                position='center'>
                {form}
            </UiPageContainer>
            :
            <UiDrawer
                title={translate({ id: 'CLIENT_CONFIGURE_AD_INTEGRATION' })}
                drawerProps={{
                    anchor: 'right',
                    open,
                    onClose: () => close(),
                }}
                error={{
                    showError: showDrawerError,
                    message: errorMessage,
                }}
                themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
            >
                {form}
            </UiDrawer>
    );
};

export default ConfigureADComponent;
