import type { IOrchestratorUser } from '../../component/users/interfaces/user';
import { get } from '../utility/Requests.default';

export function getCurrentOrchestratorUser(tenantName: string, accountLogicalName: string) {
    return get<IOrchestratorUser>('/api/orchestrator/users', {
        urlParams: {
            accountLogicalName,
            tenantName,
        },
    });
}
