import { merge } from 'lodash';

import { ExternalAuthenticationScheme } from '../common/constants/ExternalIdentityProviderConstant';
import type { ISaml2ProviderSettings } from '../common/interfaces/externalIdentity';
import type IExternalIdentity from '../common/interfaces/externalIdentity';
import type { IAADConfigData } from '../component/authsettings/subcomponents/ConfigureAADComponent';
import type { IGoogleConfigData } from '../component/authsettings/subcomponents/ConfigureGoogleComponent';
import type { ISamlConfigData } from '../component/authsettings/subcomponents/ConfigureSamlComponent';
import type { IEditIdentityProviderData } from '../component/authsettings/subcomponents/EditIdentityProviderFormComponent';
import {
    removeUndefinedNullAndEmptyStringValues,
    replaceNullValuesWithUndefined,
} from './ObjectUtil';

export const mapADConfigDataToExternalIdentityPayload = (
    configData: IEditIdentityProviderData,
    externalIdentity: IExternalIdentity = {
        authenticationScheme: process.buildConfigs.showKerberosAuth
            ? ExternalAuthenticationScheme.Negotiate
            : ExternalAuthenticationScheme.Windows,
    },
): IExternalIdentity => removeUndefinedNullAndEmptyStringValues({
    ...externalIdentity,
    isActive: configData.isActive,
    isExclusive: configData.isExclusive,
    displayName: configData.displayName,
});

export const mapExternalIdentityPayloadToADConfigData = (
    externalIdentity: IExternalIdentity,
): IEditIdentityProviderData => ({
    isActive: externalIdentity.isActive,
    isExclusive: externalIdentity.isExclusive,
    displayName: externalIdentity.displayName,
});

export const mapGoogleConfigDataToExternalIdentityPayload = (
    configData: IGoogleConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.Google },
): IExternalIdentity => removeUndefinedNullAndEmptyStringValues({
    ...externalIdentity,
    ...configData,
    clientSecret: configData.clientSecret || '********',
});

export const mapExternalIdentityPayloadToGoogleConfigData = (
    externalIdentity: IExternalIdentity,
): Partial<IGoogleConfigData> => ({
    isActive: externalIdentity.isActive,
    isExclusive: externalIdentity.isExclusive,
    displayName: externalIdentity.displayName,
    clientId: externalIdentity.clientId,
    clientSecret: '', // Do not include client secret since the secret is being used as a placeholder instead.
});

export const mapAADConfigDataToExternalIdentityPayload = (
    configData: IAADConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.AzureAD },
): IExternalIdentity => removeUndefinedNullAndEmptyStringValues({
    ...externalIdentity,
    ...configData,
    clientSecret: configData.clientSecret || '********',
});

const defaultExternalIdentity: IAADConfigData = {
    isActive: false,
    isExclusive: false,
    displayName: '',
    clientId: '',
    clientSecret: '',
    authority: '',
    logoutUrl: '',
};

export const mapExternalIdentityPayloadToAADConfigData = (externalIdentity: IExternalIdentity): IAADConfigData => merge(defaultExternalIdentity, {
    isActive: externalIdentity.isActive,
    isExclusive: externalIdentity.isExclusive,
    displayName: externalIdentity.displayName,
    clientId: externalIdentity.clientId,
    clientSecret: '', // Do not include client secret since the secret is being used as a placeholder instead.
    authority: externalIdentity.authority,
    logoutUrl: externalIdentity.logoutUrl,
});

export const mapSamlConfigDataToExternalIdentityPayload = (
    configData: ISamlConfigData,
    externalIdentity: IExternalIdentity = { authenticationScheme: ExternalAuthenticationScheme.Saml2 },
): IExternalIdentity => {
    const { settings } = externalIdentity;
    const parsedSettings = settings ? (JSON.parse(settings) as ISaml2ProviderSettings) : undefined;
    const combinedSettings: ISaml2ProviderSettings = removeUndefinedNullAndEmptyStringValues({
        ...parsedSettings,
        ServiceProviderEntityId: configData.serviceProviderEntityId,
        IdentityProviderEntityId: configData.identityProviderEntityId,
        SingleSignOnServiceUrl: configData.singleSignOnServiceUrl,
        AllowUnsolicitedAuthnResponse: configData.allowUnsolicitedAuthnResponse,
        ReturnUrl: configData.returnUrl,
        ExternalAuthUserMappingStrategy: configData.externalAuthUserMappingStrategy,
        Saml2BindingType: configData.saml2BindingType,
        ExternalAuthUserIdentifierClaim: configData.externalAuthUserIdentifierClaim,
        SigningCertificateLocation: removeUndefinedNullAndEmptyStringValues({
            ...parsedSettings?.SigningCertificateLocation,
            ...configData.signingCertificateLocation,
        }),
        ServiceCertificateLocation: removeUndefinedNullAndEmptyStringValues({
            ...parsedSettings?.ServiceCertificateLocation,
            ...configData.serviceCertificateLocation,
        }),
    });
    return removeUndefinedNullAndEmptyStringValues({
        ...externalIdentity,
        isActive: configData.isActive,
        isExclusive: configData.isExclusive,
        displayName: configData.displayName,
        settings: JSON.stringify(combinedSettings),
    });
};

export const mapExternalIdentityPayloadToSamlConfigData = (
    externalIdentity: IExternalIdentity,
): Partial<ISamlConfigData> => {
    const { settings } = externalIdentity;
    const parsedSettings = settings ? (JSON.parse(settings) as ISaml2ProviderSettings) : undefined;
    return replaceNullValuesWithUndefined({
        isActive: externalIdentity.isActive,
        isExclusive: externalIdentity.isExclusive,
        displayName: externalIdentity.displayName,
        serviceProviderEntityId: parsedSettings?.ServiceProviderEntityId,
        identityProviderEntityId: parsedSettings?.IdentityProviderEntityId,
        singleSignOnServiceUrl: parsedSettings?.SingleSignOnServiceUrl,
        allowUnsolicitedAuthnResponse: parsedSettings?.AllowUnsolicitedAuthnResponse,
        returnUrl: parsedSettings?.ReturnUrl,
        externalAuthUserMappingStrategy: parsedSettings?.ExternalAuthUserMappingStrategy,
        saml2BindingType: parsedSettings?.Saml2BindingType,
        externalAuthUserIdentifierClaim: parsedSettings?.ExternalAuthUserIdentifierClaim,
        signingCertificateLocation: replaceNullValuesWithUndefined(parsedSettings?.SigningCertificateLocation),
        serviceCertificateLocation: replaceNullValuesWithUndefined(parsedSettings?.ServiceCertificateLocation),
    });
};

export const mapExternalIdentityPayloadToErrorCode = (externalIdentity: IExternalIdentity): string | undefined => {
    const { settings } = externalIdentity;
    if (!settings) {
        return undefined;
    }
    return (JSON.parse(settings) as ISaml2ProviderSettings).ErrorCode || undefined;
};
