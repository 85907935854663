import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ShouldChangePassword } from '../../../common/constants/AuthSettingConstant';
import type { ISecurityAuthenticationSettingsData } from '../../../common/interfaces/authSetting';

const useStyles = makeStyles(theme =>
    createStyles({
        formWrapper: {
            flex: 1,
            height: '100%',
        },
        accordionContentDivided: {
            padding: '16px 16px 0px 16px',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            width: '100%',
            gap: '16px',
        },
        section: { marginBottom: '14px' },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        inputLabel: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        column: {
            flex: 1,
            minWidth: '200px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        inputAdornment: { width: 'auto' },
        outlinedInput: {
            display: 'flex',
            marginTop: '8px',
        },
        outlinedInputWrapper: { marginBottom: '20px' },
        indentedSection: { marginLeft: '8px' },
        singleColumn: { overflowX: 'hidden' },
    }),
);

const PasswordPolicyForm: React.FC = () => {
    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        control, setValue, errors,
    } = useFormContext<ISecurityAuthenticationSettingsData>();

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const passwordComplexity = useMemo(() => (
        <>
            <FormControl component="fieldset">
                <Typography
                    role="heading"
                    component="legend"
                    className={classes.subHeading}>
                    {translate({ id: 'CLIENT_PASSWORD_COMPLEXITY' })}
                </Typography>
                <div className={classes.indentedSection}>
                    <Controller
                        name="password.passwordComplexity.hasSpecialCharacter"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={props.value}
                                        onChange={e =>
                                            handleSwitch(
                                                'password.passwordComplexity.hasSpecialCharacter',
                                                e.target.checked,
                                                props.onChange,
                                            )}
                                        color="primary"
                                        data-cy="security-settings-has-special-character-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_SPECIAL_CHARACTERS' })}
                                    </div>
                                }
                            />
                        )}
                    />
                    <Controller
                        name="password.passwordComplexity.hasLowercase"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={props.value}
                                        onChange={e =>
                                            handleSwitch(
                                                'password.passwordComplexity.hasLowercase',
                                                e.target.checked,
                                                props.onChange,
                                            )}
                                        color="primary"
                                        data-cy="security-settings-has-lowercase-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_HAS_LOWERCASE_CHARACTERS' })}
                                    </div>
                                }
                            />
                        )}
                    />
                    <Controller
                        name="password.passwordComplexity.hasUppercase"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={props.value}
                                        onChange={e =>
                                            handleSwitch(
                                                'password.passwordComplexity.hasUppercase',
                                                e.target.checked,
                                                props.onChange,
                                            )}
                                        color="primary"
                                        data-cy="security-settings-has-uppercase-switch"
                                    />
                                }
                                label={
                                    <div className={classes.switchLabel}>
                                        {translate({ id: 'CLIENT_HAS_UPPERCASE_CHARACTERS' })}
                                    </div>
                                }
                            />
                        )}
                    />

                    <Controller
                        name="password.passwordComplexity.hasDigit"
                        control={control}
                        render={props => (
                            <FormControlLabel
                                className={classes.switchFormControl}
                                control={
                                    <Checkbox
                                        checked={props.value}
                                        onChange={e =>
                                            handleSwitch(
                                                'password.passwordComplexity.hasDigit',
                                                e.target.checked,
                                                props.onChange,
                                            )}
                                        color="primary"
                                        data-cy="security-settings-has-digit-switch"
                                    />
                                }
                                label={<div className={classes.switchLabel}>
                                    {translate({ id: 'CLIENT_HAS_DIGITS' })}
                                </div>}
                            />
                        )}
                    />
                </div>
            </FormControl>
            <div className={classes.outlinedInputWrapper}>
                <Typography
                    className={classes.inputLabel}
                    id="minimumPasswordLengthLabel">
                    {translate({ id: 'CLIENT_MINIMUM_PASSWORD_LENGTH' })}
                </Typography>
                <Controller
                    name="password.passwordComplexity.Length"
                    control={control}
                    rules={{
                        required: true,
                        min: 1,
                        max: 256,
                    }}
                    error={!!errors.password?.passwordComplexity?.Length}
                    as={TextField}
                    className={classes.outlinedInput}
                    data-cy="security-settings-min-password-length-input"
                    type="number"
                    inputProps={{
                        'aria-labelledby': 'minimumPasswordLengthLabel',
                        min: 1,
                        max: 256,
                    }}
                    helperText={
                        !!errors.password?.passwordComplexity?.Length && translate({ id: 'CLIENT_PASSWORD_POLICY_MIN_LENGTH_ERROR' })
                    }
                />
            </div>
            <div className={classes.outlinedInputWrapper}>
                <Typography
                    className={classes.inputLabel}
                    id="passwordExpirationLabel">
                    {translate({ id: 'CLIENT_DAYS_BEFORE_PASSWORD_EXPIRATION' })}
                </Typography>
                <Controller
                    name="password.defaultExpirationDays"
                    control={control}
                    rules={{
                        required: true,
                        min: 0,
                        max: 1000,
                    }}
                    error={!!errors.password?.defaultExpirationDays}
                    as={TextField}
                    className={classes.outlinedInput}
                    data-cy="security-settings-default-expiration-days-input"
                    type="number"
                    inputProps={{
                        'aria-labelledby': 'passwordExpirationLabel',
                        min: 0,
                        max: 1000,
                    }}
                    helperText={
                        !!errors.password?.defaultExpirationDays && translate({ id: 'CLIENT_PASSWORD_POLICY_EXPIRATION_ERROR' })
                    }
                />
            </div>
            <div className={classes.outlinedInputWrapper}>
                <Typography
                    className={classes.inputLabel}
                    id="previousPasswordLimitLabel">
                    {translate({ id: 'CLIENT_PREVIOUS_PASSWORD_USE_LIMIT' })}
                </Typography>
                <Controller
                    name="password.previousUseLimit"
                    control={control}
                    rules={{
                        required: true,
                        min: 0,
                        max: 25,
                    }}
                    error={!!errors.password?.previousUseLimit}
                    as={TextField}
                    className={classes.outlinedInput}
                    data-cy="security-settings-previous-use-limit-input"
                    type="number"
                    inputProps={{
                        'aria-labelledby': 'previousPasswordLimitLabel',
                        min: 0,
                        max: 25,
                    }}
                    helperText={
                        !!errors.password?.previousUseLimit && translate({ id: 'CLIENT_PASSWORD_POLICY_PREVIOUS_USE_LIMIT_ERROR' })
                    }
                />
            </div>
            <div>
                <Typography className={classes.inputLabel}>
                    {translate({ id: 'CLIENT_CHANGE_PASSWORD_ON_LOGIN' })}
                </Typography>
                <Controller
                    name="password.shouldChangePasswordAfterFirstLogin"
                    control={control}
                    as={
                        <RadioGroup className={classes.indentedSection}>
                            <FormControlLabel
                                value={ShouldChangePassword.NotRequired}
                                control={<Radio
                                    data-cy="security-settings-should-not-change-password-radio"
                                    color="primary" />}
                                label={translate({ id: 'CLIENT_NOT_REQUIRED' })}
                            />
                            <FormControlLabel
                                value={ShouldChangePassword.Required}
                                control={<Radio
                                    data-cy="security-settings-should-change-password-radio"
                                    color="primary" />}
                                label={translate({ id: 'CLIENT_REQUIRED' })}
                            />
                        </RadioGroup>
                    }
                />
            </div>
        </>
    ), [ classes, control, errors, handleSwitch, translate ]);

    const accountLockout = useMemo(() => (
        <>
            <FormControl
                component='fieldset'>
                <Typography
                    className={classes.subHeading}
                    component='legend'
                    id="clientAccountLockoutLabel">
                    {translate({ id: 'CLIENT_ACCOUNT_LOCKOUT' })}
                </Typography>
                <Controller
                    name="userLockOut.isEnabled"
                    control={control}
                    render={props => (
                        <FormControlLabel
                            className={classes.switchFormControl}
                            control={
                                <Switch
                                    className={classes.indentedSection}
                                    checked={props.value}
                                    onChange={e => handleSwitch('userLockOut.isEnabled', e.target.checked, props.onChange)}
                                    color="primary"
                                    data-cy="security-settings-user-lock-out-enabled-switch"
                                    inputProps={{ 'aria-labelledby': 'clientAccountLockoutLabel' }}
                                />
                            }
                            label={
                                <div className={classes.switchLabel}>
                                    {translate({ id: props.value === true ? 'CLIENT_ENABLED' : 'CLIENT_DISABLED' })}
                                </div>
                            }
                        />
                    )}
                />
            </FormControl>
            <div className={classes.outlinedInputWrapper}>
                <Typography
                    className={classes.inputLabel}
                    id="accountLockoutLabel">
                    {translate({ id: 'CLIENT_ACCOUNT_LOCKOUT_DURATION' })}
                </Typography>
                <Controller
                    name="userLockOut.defaultAccountLockoutSeconds"
                    control={control}
                    rules={{
                        required: true,
                        min: 0,
                        max: 2592000,
                    }}
                    error={!!errors.userLockOut?.defaultAccountLockoutSeconds}
                    as={TextField}
                    className={classes.outlinedInput}
                    data-cy="security-settings-account-lockout-duration-input"
                    type="number"
                    inputProps={{
                        'aria-labelledby': 'accountLockoutLabel',
                        min: 0,
                        max: 2592000,
                    }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment
                                position="end"
                                className={classes.inputAdornment}>
                                {translate({ id: 'CLIENT_SECONDS' })}
                            </InputAdornment>,
                    }}
                    helperText={
                        !!errors.userLockOut?.defaultAccountLockoutSeconds && translate({ id: 'CLIENT_ACCOUNT_LOCKOUT_DURATION_ERROR' })
                    }
                />
            </div>
            <div className={classes.outlinedInputWrapper}>
                <Typography
                    className={classes.inputLabel}
                    id="consecutiveLoginLabel">
                    {translate({ id: 'CLIENT_CONSECUTIVE_LOGIN_ATTEMPTS' })}
                </Typography>
                <Controller
                    name="userLockOut.maxFailedAccessAttemptsBeforeLockout"
                    control={control}
                    rules={{
                        required: true,
                        min: 2,
                        max: 10,
                    }}
                    error={!!errors.userLockOut?.maxFailedAccessAttemptsBeforeLockout}
                    as={TextField}
                    className={classes.outlinedInput}
                    data-cy="security-settings-max-logout-attempts-input"
                    type="number"
                    inputProps={{
                        'aria-labelledby': 'consecutiveLoginLabel',
                        min: 2,
                        max: 10,
                    }}
                    helperText={
                        !!errors.userLockOut?.maxFailedAccessAttemptsBeforeLockout
                            && translate({ id: 'CLIENT_CONSECUTIVE_LOGIN_ATTEMPTS_ERROR' })
                    }
                />
            </div>
        </>
    ), [ classes, control, errors, handleSwitch, translate ]);

    return (
        !EnableSecuritySettingsRevamp ?
            <div className={classes.accordionContentDivided}>
                <div className={classes.column}>
                    <div className={classes.section}>
                        {passwordComplexity}
                    </div>
                </div>
                <div className={classes.column}>
                    <div className={classes.section}>
                        {accountLockout}
                    </div>
                </div>
            </div>
            :
            <div className={classes.singleColumn}>
                {passwordComplexity}
                {accountLockout}
            </div>
    );
};

export default PasswordPolicyForm;
