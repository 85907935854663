import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { TenantRegionDocsLinks } from '../../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useTenantFormHelpers } from '../../../../common/hooks/tenantFormHelpers';
import useCheckLicense from '../../../../common/hooks/useCheckLicense';
import { checkServiceRegion } from '../../../../common/hooks/useCheckRegionService';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import type { IService } from '../../../../common/interfaces/tenant/tenant';
import UiCardGrid from '../../../common/UiCardGrid/UiCardGrid';
import type { IServiceMetadata } from '../../interfaces/service';
import { serviceOrder } from '../../TenantConstants';
import type { IServiceRegionMap } from '../../TenantsPageComponent';
import { ServiceLicenseStatus } from '../CreateEditTenantComponent';
import { getHiddenServices } from '../helpers/HiddenServicesHelper';
import {
    defaultServicesProvisioned,
    getListOfDependencies,
    getListOfParents,
} from '../helpers/ServiceDependencyGraph';
import { getDefaultRegionForTenant } from '../helpers/TenantRegionHelper';
import type { ServiceErrorType } from '../helpers/TenantServiceErrorMessage';
import TenantServiceErrorMessage from '../helpers/TenantServiceErrorMessage';
import { useServiceDependency } from '../helpers/useServiceDependency';
import validateTenantService from '../helpers/ValidateTenantService';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        header: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        disclaimer: {
            fontSize: '12px',
            lineHeight: '16px',
            fontWeight: 400,
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '4px',
        },
        labelRoot: { alignItems: 'flex-start' },
        labelRootRevamp: {
            alignItems: 'center',
            border: '1px solid',
            borderRadius: '4px',
            margin: '0px',
        },
        labelHover: { '&:hover': { backgroundColor: `${theme.palette.semantic.colorBackgroundSecondary} !important` } },
        label: {
            display: 'inline-flex',
            padding: '9px',
            paddingTop: '10px',
        },
        labelRevamp: {
            display: 'flex',
            padding: '9px',
            alignItems: 'center',
            alignContent: 'center',
            width: '330px',
            height: '56px',
        },
        labelName: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        labelDescription: {
            display: 'block',
            flexDirection: 'column',
        },
        icon: { margin: '2px 8px 0 2px' },
        tenantServiceForm: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: '8px',
            justifyContent: 'right',
        },
        serviceCard: {
            display: 'flex',
            flexDirection: 'column',
        },
        drawerMessage: { margin: '24px 12px 20px 0px' },
        needMoreServices: {
            boxSizing: 'border-box',
            border: 'solid 1px',
            padding: '16px',
            borderRadius: '4px',
        },
        upgradeInfo: {
            fontWeight: 400,
            fontSize: '14px',
            marginTop: '8px',
        },
        upgradeHeader: {
            fontWeight: 600,
            fontSize: '14px',
        },
        selectAllCheckbox: { alignSelf: 'flex-end' },
    }),
}));

const CreateEditTenantFormComponent: React.FC<{
    type: 'add' | 'edit';
    services: IService[];
    isRegionEnabled: boolean;
    validationErrorHandler: [
        Record<ServiceErrorType, string[]>,
        React.Dispatch<React.SetStateAction<Record<ServiceErrorType, string[]>>>,
    ];
    availableServices: IServiceMetadata[] | undefined;
    servicesAltRegion?: IServiceRegionMap;
    defaultRegion?: string;
}> = ({
    type, services, isRegionEnabled, validationErrorHandler, servicesAltRegion, availableServices, defaultRegion,
}) => {

    const classes = useStyles();
    const history = useHistory();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const { formatMessage: translate } = useIntl();
    const { servicesToHide } = useServiceDependency();
    const { isFreeOrCommunityRevamp } = useCheckLicense();

    const [ serviceDependencyMessages, setServiceDependencyMessages ] = useState<string[]>([ ]);
    const [ showRegionMessage, setShowRegionMessage ] = useState<boolean>(false);
    const [ checkSelectAll, setCheckSelectAll ] = useState<boolean>(true);

    const {
        control, setValue, getValues, watch,
    } = useFormContext<ICreateEditTenantPayload>();

    const [ validationErrors, setValidationErrors ] = validationErrorHandler;

    const defaultRegionCommunityFree = useMemo(() => getDefaultRegionForTenant(availableServices), [ availableServices ]);
    const {
        isInsightsTrial, getServiceName,
    } = useTenantFormHelpers();

    const region = useMemo(() => watch('region') ? watch('region')
        : defaultRegionCommunityFree
    , [ defaultRegionCommunityFree, watch ]);

    const hiddenServices = useMemo(() => getHiddenServices(services, availableServices), [ availableServices, services ]);

    const checkedServices = useMemo(
        () => {
            if (isAdminRevampEnabled && type === 'add' &&
                getValues('services')?.filter(serviceIterator => !hiddenServices.includes(serviceIterator)).length > 0) {
                return getValues('services');
            }
            return type === 'add'
                ? (availableServices ?? [])
                    .filter(
                        service =>
                            service.provisioningMode === 'Implicit' &&
                service.serviceLicenseStatus === ServiceLicenseStatus.Available,
                    )
                    .map(service => service.id)
                : services.map(service => service.serviceType);
        },
        [ isAdminRevampEnabled, type, getValues, availableServices, services, hiddenServices ],
    );
    const editAvailableServices = useMemo(() => availableServices?.filter(i => !checkedServices.includes(i.id))
        , [ availableServices, checkedServices ]);

    const displayedServices = useMemo(() => {
        const data = type === 'add' ? availableServices : editAvailableServices;
        return data?.filter(service => servicesToHide.indexOf(service.id) < 0 && hiddenServices.indexOf(service.id) < 0);
    }, [ availableServices, editAvailableServices, hiddenServices, servicesToHide, type ]);

    const handleCheck = useCallback(
        (checkedId: string) => {
            const { services: ids } = getValues();
            if (ids.indexOf(checkedId) > -1) {
                setServiceDependencyMessages(prev => prev.filter(t => t !== checkedId));
            }
            return ids.indexOf(checkedId) > -1 ? ids.filter(id => id !== checkedId) : [ ...(ids ?? []), checkedId ];
        },
        [ getValues ],
    );

    const validate = useCallback(async () => {
        if (!availableServices) {
            return;
        }

        const currentServices = getValues('services');

        const validatedServices = validateTenantService(
            availableServices.filter(s => s.id !== 'bupproxyservice'),
            services,
            currentServices,
            isRegionEnabled ? {
                region,
                servicesAltRegion,
            } : {},
        );

        setValidationErrors(validatedServices);

        if (isAdminRevampEnabled) {
            if (currentServices && validatedServices.shouldDisableRevamp.length) {
                setValue(
                    'services',
                    currentServices.filter(s => validatedServices.shouldDisableRevamp.indexOf(s) === -1),
                );
            }
        } else {
            if (currentServices && validatedServices.shouldDisable.length) {
                setValue(
                    'services',
                    currentServices.filter(s => validatedServices.shouldDisableRevamp.indexOf(s) === -1),
                );
            }
        }
    }, [
        availableServices,
        getValues,
        services,
        isRegionEnabled,
        region,
        servicesAltRegion,
        setValidationErrors,
        isAdminRevampEnabled,
        setValue,
    ]);

    useEffect(() => {
        if (checkedServices) {
            setValue('services', checkedServices);
        }
    }, [ checkedServices, setValue ]);

    // validate services based on region changes
    useEffect(() => {
        validate();
    }, [ region, validate ]);

    useEffect(() => {
        setShowRegionMessage(watch('services').some((iterator) => {
            const displayServicesFilter = displayedServices?.find((service) => service.id === iterator);
            return displayServicesFilter ? !checkServiceRegion(displayServicesFilter, region) : false;
        }));
    }, [ watch, displayedServices, region ]);

    const getServiceIcon = useCallback(
        (service: IServiceMetadata) => {
            if (isInsightsTrial(service)) {
                return 'Insights Trial';
            }
            switch (service.name) {
                case 'Document Understanding': return 'du';
                case 'Integration Service': return 'integrations';
                case 'AI Center': return 'aifabric';
                default: return service.name.toLowerCase().replace(' ', '');
            }
        },
        [ isInsightsTrial ],
    );

    const disabledCheckBox = useCallback((id) => validationErrors &&
            (isAdminRevampEnabled ? validationErrors?.shouldDisableRevamp?.indexOf(id) : validationErrors?.shouldDisable?.indexOf(id)) > -1, [ isAdminRevampEnabled, validationErrors ]);

    const tenantServices = useCallback((props) => displayedServices
        ?.sort(
            (serviceA: IServiceMetadata, serviceB: IServiceMetadata) =>
                serviceOrder.indexOf(serviceA.id) - serviceOrder.indexOf(serviceB.id),
        )
        ?.map((service, i) => (
            <FormControlLabel
                key={i}
                control={
                    <Checkbox
                        checked={props.value.indexOf(service.id) > -1}
                        onChange={() => {
                            props.onChange(handleCheck(service.id));
                            validate();
                        }}
                    />
                }
                label={
                    <div className={classes.labelDescription}>
                        <div className={classes.labelName}>
                            <Typography className={clsx(classes.header)}>
                                {getServiceName(service)}
                            </Typography>
                            {validationErrors && (
                                <TenantServiceErrorMessage
                                    errors={validationErrors}
                                    service={service}
                                    availableServices={displayedServices}
                                    currentServices={watch('services')}
                                    servicesAltRegion={servicesAltRegion}
                                    services={services}
                                />
                            )}
                        </div>
                        {isInsightsTrial(service) && props.value.indexOf(service.id) > -1 && (
                            <Typography className={classes.disclaimer}>
                                {translate(
                                    { id: 'CLIENT_INSIGHTS_TRIAL_DISCLAIMER' },
                                    {
                                        evaluationAgreement: (
                                            <a
                                                className={classes.a}
                                                href="https://www.uipath.com/hubfs/legalspot/UiPath_Evaluation_Agreement.pdf"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {translate({ id: 'CLIENT_INSIGHTS_TRIAL_EVALUATION_AGREEMENT' })}
                                            </a>
                                        ),
                                    },
                                )}
                            </Typography>
                        )}
                    </div>
                }
                disabled={disabledCheckBox(service.id)}
                classes={{
                    root: classes.labelRoot,
                    label: classes.label,
                }}
                data-cy={`create-edit-tenant-option-${service.id}`}
            />),
        ), [
        classes.a,
        classes.disclaimer,
        classes.header,
        classes.label,
        classes.labelDescription,
        classes.labelName,
        classes.labelRoot,
        disabledCheckBox,
        displayedServices,
        getServiceName,
        handleCheck,
        isInsightsTrial,
        services,
        servicesAltRegion,
        translate,
        validate,
        validationErrors,
        watch,
    ]);

    const serviceDependencyMessage = useCallback((service: IServiceMetadata) => {

        const parentDependency =
          type === 'edit'
              ? getListOfDependencies(service.id)
                  .filter(s => !services.some(serviceIterator => serviceIterator.serviceType === s) && !!s)
                  .map(s => availableServices?.find(k => k.id === s)?.name ?? '')
                  .join(', ')
              : getListOfDependencies(service.id)
                  .map(s => availableServices?.find(k => k.id === s)?.name ?? '')
                  .filter(s => !!s)
                  .join(', ') ;

        return parentDependency ?
            translate({ id: 'CLIENT_SERVICE_DEPENDENCY' }, {
                serviceName: service.name,
                parentName: parentDependency,
            })
            : '';
    }, [ availableServices, services, translate, type ]);

    const handleDependencyCheck = useCallback((service: IServiceMetadata) => {
        const parentServices = getListOfDependencies(service.id);
        const childServices = getListOfParents(service.id);
        const currentServices = getValues('services');

        if (currentServices.some(serviceIterator => serviceIterator === service.id)) {
            if (parentServices && !parentServices.every(iterator => currentServices.includes(iterator))) {
                setServiceDependencyMessages(t => !t?.includes(service.id) ? [ ...t, service.id ] : t);
                setValue('services', currentServices.concat(parentServices));
                validate();
            }
        } else {
            if (childServices) {
                setServiceDependencyMessages(t => t.filter(iterator => !childServices.includes(iterator)));
                setValue('services', currentServices.filter(serviceIterator => !childServices.includes(serviceIterator)));
                validate();
            }
        }
    }, [ getValues, setValue, validate ]);

    const addAllServices = useCallback(() => {
        setCheckSelectAll((prevState) => !prevState);
        if (checkSelectAll) {
            const servicesToAdd = availableServices
                ?.filter((serviceIterator) => serviceIterator.serviceLicenseStatus === ServiceLicenseStatus.Available)
                .map((serviceIterator) => serviceIterator.id) ?? [];
            setValue('services', servicesToAdd, { shouldDirty: true });
            validate();
        } else {
            setValue('services', defaultServicesProvisioned, { shouldDirty: true });
        }
    }, [ availableServices, checkSelectAll, setValue, validate ]);

    const tenantServicesAdminRevamp = useCallback((props) =>
        <>
            <div
                className={classes.tenantServiceForm}
                style={{
                    flexDirection: type === 'add' ? 'row' : 'column',
                    justifyContent: type === 'edit' || !showRegionMessage ? 'right' : 'space-between',
                }}
            >
                {type === 'add' ? showRegionMessage && <Typography data-cy="tenant-services-tenant-region-text">
                    {translate({ id: 'CLIENT_TENANT_CREATE_SERVICES_REGION' },
                        { region: translate({ id: `CLIENT_${region.toUpperCase()}` }) })}
                </Typography> :
                    displayedServices?.some(
                        serviceIterator => serviceIterator.defaultRegion !== 'None' &&
                        checkServiceRegion(serviceIterator, defaultRegion)) && (
                        <div className={classes.drawerMessage}>
                            <Typography >
                                {translate(
                                    { id: 'CLIENT_DEFAULT_TENANT_REGION_DIFFERENT_SERVICE_ADD' },
                                    { 0: defaultRegion }) + ' '}
                                <Link
                                    data-cy="learn-region-hosting-button"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={getLocalizedLink(TenantRegionDocsLinks)}
                                >
                                    {translate({ id: 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_TOOLTIP_LEARN_MORE' })}
                                </Link>
                            </Typography>
                        </div>
                    )}
                <FormControlLabel
                    className={classes.selectAllCheckbox}
                    control={<Checkbox
                        value={checkSelectAll}
                        onClick={addAllServices}
                    />}
                    label={translate({ id: 'CLIENT_SELECT_ALL' })}
                    data-cy="tenant-services-add-all-checkbox" />
            </div>
            <UiCardGrid
                maxCardWidth={type === 'edit' ? '1000px' : '330px'}>
                {displayedServices
                    ?.sort(
                        (serviceA: IServiceMetadata, serviceB: IServiceMetadata) =>
                            serviceOrder.indexOf(serviceA.id) - serviceOrder.indexOf(serviceB.id),
                    )
                    ?.map((service, i) => (
                        <div
                            key={`service-checkbox-${i}`}
                            className={classes.serviceCard}>
                            <FormControlLabel
                                key={i}
                                labelPlacement='start'
                                control={
                                    <Checkbox
                                        checked={props.value.indexOf(service.id) > -1}
                                        onChange={() => {
                                            props.onChange(handleCheck(service.id));
                                            handleDependencyCheck(service);
                                            validate();
                                        }}
                                    />
                                }
                                label={
                                    <div>
                                        <div className={classes.labelName}>
                                            <div className={classes.icon}>
                                                <PortalCustomIcon
                                                    name={getServiceIcon(service)} />
                                            </div>
                                            <Typography
                                                className={classes.header}
                                                style={{ fontWeight: 600 }}>
                                                {getServiceName(service)}
                                            </Typography>
                                            {service.defaultRegion !== 'None'
                                            && !checkServiceRegion(service, region) &&
                                            <Chip
                                                label={translate({ id: `CLIENT_${service.defaultRegion.toUpperCase()}` })}
                                                className="info-mini"
                                                style={{ marginLeft: '8px' }}
                                                data-cy={`service-region-chip-${service.id}`}
                                                size="small" />}
                                            {validationErrors && (
                                                <TenantServiceErrorMessage
                                                    errors={validationErrors}
                                                    service={service}
                                                    availableServices={availableServices ?? []}
                                                    currentServices={watch('services')}
                                                    servicesAltRegion={servicesAltRegion}
                                                    services={services}
                                                />
                                            )}
                                        </div>
                                        {isInsightsTrial(service) && props.value.indexOf(service.id) > -1 && (
                                            <Typography className={classes.disclaimer}>
                                                {translate(
                                                    { id: 'CLIENT_INSIGHTS_TRIAL_DISCLAIMER' },
                                                    {
                                                        evaluationAgreement: (
                                                            <a
                                                                className={classes.a}
                                                                // eslint-disable-next-line max-len
                                                                href="https://www.uipath.com/hubfs/legalspot/UiPath_Evaluation_Agreement.pdf"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {translate({ id: 'CLIENT_INSIGHTS_TRIAL_EVALUATION_AGREEMENT' })}
                                                            </a>
                                                        ),
                                                    },
                                                )}
                                            </Typography>
                                        )}
                                    </div>
                                }
                                disabled={
                                    disabledCheckBox(service.id)
                                }
                                classes={{
                                    root: clsx(classes.labelRootRevamp, !disabledCheckBox(service.id) && classes.labelHover),
                                    label: classes.labelRevamp,
                                }}
                                data-cy={`create-edit-tenant-option-${service.id}`}
                            />
                            {serviceDependencyMessages?.includes(service.id) && <Typography
                                style={{
                                    fontSize: '12px',
                                    margin: '4px 0px 0px 4px',
                                }}
                                data-cy={`service-dependency-${service.id}`}>
                                {serviceDependencyMessage(service)}
                            </Typography>}
                        </div>
                    ))}
                {
                    (isFreeOrCommunityRevamp && type === 'edit') ?
                        <div className={classes.needMoreServices}>
                            <Typography className={classes.upgradeHeader}>
                                {translate({ id: 'CLIENT_NEED_MORE_SERVICES' })}
                            </Typography>
                            <Typography className={classes.upgradeInfo}>
                                {translate({ id: 'CLIENT_UPGRADE_TO_TRY' })}
                            </Typography>
                            <Button
                                style={{ marginTop: '20px' }}
                                onClick={() => history.push(getRoute(RouteNames.BuyPro))}
                                variant='outlined'>
                                {translate({ id: 'CLIENT_UPGRADE' })}
                            </Button>
                        </div>
                        : <></>
                }
            </UiCardGrid>
        </>,
    [
        classes.tenantServiceForm,
        classes.drawerMessage,
        classes.needMoreServices,
        classes.upgradeHeader,
        classes.upgradeInfo,
        classes.serviceCard,
        classes.labelName,
        classes.icon,
        classes.header,
        classes.disclaimer,
        classes.a,
        classes.labelRootRevamp,
        classes.labelHover,
        classes.labelRevamp,
        type,
        showRegionMessage,
        translate,
        region,
        displayedServices,
        defaultRegion,
        getLocalizedLink,
        isFreeOrCommunityRevamp,
        checkSelectAll,
        getServiceIcon,
        getServiceName,
        validationErrors,
        availableServices,
        watch,
        servicesAltRegion,
        services,
        isInsightsTrial,
        disabledCheckBox,
        serviceDependencyMessages,
        serviceDependencyMessage,
        handleCheck,
        handleDependencyCheck,
        validate,
        history,
        getRoute,
        addAllServices,
    ]);

    return (
        <FormGroup>
            <Controller
                name="services"
                control={control}
                render={props => (
                    <>
                        {displayedServices && <>
                            {displayedServices.length > 0 ? <>
                                {!isAdminRevampEnabled && type === 'edit' && <Typography data-cy="create-edit-tenant-service-message">
                                    {translate({ id: 'CLIENT_PROVISION_SERVICES_MESSAGE' })}
                                </Typography>}
                                {isAdminRevampEnabled ? tenantServicesAdminRevamp(props) : tenantServices(props)}
                            </> : <Typography data-cy="create-edit-tenant-empty-service">
                                {translate({ id: 'CLIENT_PROVISION_SERVICES_EMPTY' })}
                            </Typography>}
                        </>}
                        {
                            !displayedServices &&
                            <CircularProgress data-cy="tenant-services-loader" />
                        }
                    </>
                )}
            />
        </FormGroup>
    );
};

export default CreateEditTenantFormComponent;
