import { isArray } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    externalIdentityProviderUrl,
    getExternalIdentityProvider,
} from '../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../store/selectors';
import type { ExternalAuthenticationScheme } from '../constants/ExternalIdentityProviderConstant';
import type IExternalIdentity from '../interfaces/externalIdentity';

const useExternalIdentity = (
    externalAuthenticationScheme: ExternalAuthenticationScheme | ExternalAuthenticationScheme[],
) => {
    const partitionGlobalId = useSelector(accountGlobalId);
    const { data: externalIdentities } = useSWR<IExternalIdentity[], Error>(
        [ externalIdentityProviderUrl, partitionGlobalId ],
        getExternalIdentityProvider,
        { shouldRetryOnError: false },
    );
    return useMemo<IExternalIdentity | undefined>(
        () =>
            externalIdentities?.find(externalIdentity =>
                isArray(externalAuthenticationScheme)
                    ? externalAuthenticationScheme.indexOf(externalIdentity.authenticationScheme) >= 0
                    : externalIdentity.authenticationScheme === externalAuthenticationScheme,
            ),
        [ externalAuthenticationScheme, externalIdentities ],
    );
};

export default useExternalIdentity;
