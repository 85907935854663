import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import type { IService } from '../../../common/interfaces/tenant/tenant';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
        },
    }),
}));

export interface ICardAction {
    label: string | ((service: IService) => string);
    click: (_: any) => void;
    disable?: boolean;
    invisible?: boolean | ((service: IService) => boolean);
    dataCy: string;
    ariaLabel: string | ((service: IService) => string);
}

interface IMoreRowActionsProps {
    service: IService;
    actions: ICardAction[];
    disabled: boolean | undefined;
}

export const UiMoreActionsComponent = ({
    service, actions, disabled,
}: IMoreRowActionsProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const clickMenuItem = useCallback((click: (_: any) => void, service: IService) => {
        setAnchorEl(null);
        click(service);
    }, []);

    return (
        <>
            <Tooltip title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    className={clsx(classes.showMoreButton)}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    data-cy="ui-grid-show-more-actions-button"
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                data-cy="ui-grid-show-more-actions-menu"
            >
                {actions.map((action, p) =>
                    (
                        (action.invisible !== undefined
                            && (typeof action.invisible === 'function' ?
                                action.invisible(service)
                                : action.invisible
                            )
                        ) ? null :
                            <MenuItem
                                key={p}
                                disabled={action?.disable || disabled}
                                data-cy={action.dataCy}
                                className={clsx(classes.menuItem)}
                                aria-labelledby={typeof action.ariaLabel === 'function' ? action.ariaLabel(service) : action.ariaLabel}
                                onClick={() => (action.click ? clickMenuItem(action.click, service) : undefined)}
                            >
                                <ListItemText
                                    primary={typeof action.label === 'function' ? action.label(service) : action.label}
                                />
                            </MenuItem>
                    ),
                )}
            </Menu>
        </>
    );
};
