import { GlobalStyles } from '@experiences/theme';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import type { Row } from 'react-table';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useLicenseExpired } from '../../../common/hooks/useLicenseExpired';
import type { IOrganization } from '../../../common/interfaces/organization';
import AllocateLicenseIcon from '../../../images/icons/AllocateLicenseIcon';
import { removeHostedLicense } from '../../../services/licensing/management/HostService';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../../services/organization/OnPremOrganizationService';
import { isUnlicensedSelector } from '../../../store/selectors';
import {
    ButtonType,
    GridActionType,
} from '../../common/UiGrid/grid';
import { UiPaginatedGrid } from '../../common/UiPaginatedGrid';
import OnPremOrganizationDeleteDialogBody from './OnPremOrganizationDeleteDialogBody';

export interface IOnPremOrganizationResponse {
    totalCount: number;
    results: IOrganization[];
}

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({ organizationNameCell: { display: 'flex' } }),
}));

const OrganizationGridComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const [ refresh, setRefresh ] = useState(false);
    const createDialog = useShowDialog();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const getRoute = useRouteResolver();
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const isLicenseExpired = useLicenseExpired();

    const openDeleteDialog = useCallback(
        async (row: Row<IOrganization>) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_ORGANIZATION' }),
                customDialogContent: OnPremOrganizationDeleteDialogBody,
                customDialogContentProps: {
                    orgName: row.original.name,
                    orgId: row.original.id,
                },
                icon: 'error',
                showCancel: false,
            });
            if (proceed) {
                setRefresh(true);
                enqueueSnackbar(translate({ id: 'CLIENT_ORGANIZATION_DELETED' }), { variant: notificationType.INPROGRESS as any });
            }
        },
        [ createDialog, enqueueSnackbar, translate ],
    );

    const openRemoveLicenseDialog = useCallback(
        async (row: Row<IOrganization>) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' }),
                body: translate({ id: 'CLIENT_REMOVE_LICENSE_DIALOG' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
            });
            if (proceed) {
                await removeHostedLicense(row.original.id);
            }
        },
        [ translate, createDialog ],
    );

    const openLicenseAllocationDrawer = useCallback(
        async (row: Row<IOrganization>) => {
            history.push({
                pathname: `${getRoute(RouteNames.OrganizationsLicense)}`,
                state: {
                    organization: row.original,
                    previousLocation: location.pathname,
                },
            });
        },
        [ getRoute, history ],
    );

    useEffect(() => {
        history.listen(history => {
            if (history.state && (history.state as any)['refresh']) {
                setRefresh(true);
            }
        });
    }, [ history ]);

    return (
        <UiPaginatedGrid<IOrganization, IOnPremOrganizationResponse>
            url={onPremOrganizationManagementUri}
            fetcher={getAllOnPremOrganizations}
            data-cy="organization-ui-grid"
            search
            searchPlaceholder={translate({ id: 'CLIENT_SEARCH_ORGANIZATION_TEXT' })}
            refreshData={{
                current: refresh,
                set: setRefresh,
            }}
            filters
            columns={[
                {
                    accessor: 'name',
                    sortName: 'Name',
                    Header: translate({ id: 'CLIENT_ORGANIZATION' }),
                    width: 25,
                    Cell: ({ row }) => (
                        <div className={clsx(classes.organizationNameCell)}>
                            <Tooltip
                                arrow
                                title={row.original.name}>
                                <span>
                                    {row.original.name}
                                </span>
                            </Tooltip>
                        </div>
                    ),
                },
            ]}
            rowActions={[
                {
                    type: ButtonType.Icon,
                    label: translate({ id: 'CLIENT_ALLOCATE_ORGANIZATION_LICENSE' }),
                    tooltip: translate({ id: 'CLIENT_ALLOCATE_ORGANIZATION_LICENSE' }),
                    actionType: GridActionType.Row,
                    icon: <AllocateLicenseIcon alt="allocateLicenseIcon" />,
                    disable: () => isUnlicensedMode || isLicenseExpired,
                    click: openLicenseAllocationDrawer,
                    dataCy: 'allocate-org-license-button',
                },
                {
                    type: ButtonType.Button,
                    label: translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' }),
                    tooltip: translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' }),
                    actionType: GridActionType.Row,
                    click: openRemoveLicenseDialog,
                    dataCy: 'remove-license-allocation-button',
                },
                {
                    type: ButtonType.Button,
                    label: translate({ id: 'CLIENT_DELETE_ORGANIZATION' }),
                    tooltip: translate({ id: 'CLIENT_DELETE_ORGANIZATION' }),
                    actionType: GridActionType.Row,
                    click: openDeleteDialog,
                    dataCy: 'delete-organization-button',
                },
            ]}
        />
    );
};

export default OrganizationGridComponent;
