import { useUpdateLocalizationRoute } from '@experiences/locales';
import { useMemo } from 'react';
import { renderRoutes } from 'react-router-config';

import { useTabName } from '../component/common/useTabName';
import useMainRoutes from './routeConfigs/useMainRoutes.default';

export default function Routes() {
    const mainRoutes = useMainRoutes();

    useTabName();
    useUpdateLocalizationRoute();

    return useMemo(() => renderRoutes(mainRoutes), [ mainRoutes ]);
}
