import { NavCustomizationSetting } from '@experiences/constants';
import { getSetting } from '@uipath/portal-shell-util';
import { merge } from 'lodash';

import type { IKeyValuePair } from '../../common/interfaces/keyValuePair';
import type { INavCustomizationData } from '../../component/organizationsettings/subcomponents/navigationCustomization/NavCustomizationComponent';
import { defaultNavCustomizationData } from '../../component/organizationsettings/subcomponents/navigationCustomization/NavCustomizationComponent';
import type { ISetting } from '../../services/identity/SettingService';

export const mapSettingArrayToNavCustomizationData = (
    fetchedSettings: ISetting[],
    defaults: INavCustomizationData = defaultNavCustomizationData,
): INavCustomizationData => {
    const appsHidden = getSetting(fetchedSettings, NavCustomizationSetting.AppsHidden);
    const marketplaceHidden = getSetting(fetchedSettings, NavCustomizationSetting.MarketplaceHidden);
    const resourceCenterHidden = getSetting(fetchedSettings, NavCustomizationSetting.ResourceCenterHidden);
    const studioHidden = getSetting(fetchedSettings, NavCustomizationSetting.StudioWebHidden);

    return merge({}, defaults, {
        appsHidden: appsHidden === undefined ? undefined : appsHidden === 'true',
        marketplaceHidden: marketplaceHidden === undefined ? undefined : marketplaceHidden === 'true',
        resourceCenterHidden: resourceCenterHidden === undefined ? undefined : resourceCenterHidden === 'true',
        studioHidden: studioHidden === undefined ? undefined : studioHidden === 'true',
    });
};

export function mapNavCustomizationDataToKeyValuePairs(data: INavCustomizationData): IKeyValuePair[] {
    return [
        {
            key: NavCustomizationSetting.AppsHidden,
            value: data.appsHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.MarketplaceHidden,
            value: data.marketplaceHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.ResourceCenterHidden,
            value: data.resourceCenterHidden?.toString() ?? 'false',
        },
        {
            key: NavCustomizationSetting.StudioWebHidden,
            value: data.studioHidden?.toString() ?? 'false',
        },
    ];
}
