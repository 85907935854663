import type {
    ILabelModelObject,
    IPagination,
} from '@experiences/interfaces';
import { PermissionType } from '@experiences/interfaces';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import { elementToNode } from '@uipath/portal-shell-react';
import type {
    GridHeaderButtons,
    GridRowButtons,
    IColumn,
    IGridOptions,
    SelectionManager,
} from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    getLabels,
    getPermissions,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import { hasPermission } from '../../addedit/properties/TenantTagsUtil';
import { useTagsDeleteDialog } from '../../common/useTagsDeleteDialog';
import { useTagsDisabled } from '../../common/useTagsDisabled';
import TenantTagsLabelsMoreActions from './TenantTagsLabelsMoreActions';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const useTenantTagsLabelsViewModel = () => {
    const history = useHistory();
    const match = useRouteMatch<{ tenantId: string }>();
    const { formatMessage: translate } = useIntl();
    const apGridRef = useRef<any>(null);
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();
    const { checkDisabled } = useTagsDisabled();
    const { logEvent } = useTelemetryHelper();

    // Redux state
    const organizationName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);

    // Component state
    const tenantId = match.params.tenantId;
    const [ index, setPageIndex ] = useState(DEFAULT_PAGE_INDEX);
    const [ size, setPageSize ] = useState(DEFAULT_PAGE_SIZE);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ selectionMgr, setSelectionMgr ] = useState<SelectionManager<ILabelModelObject>>();

    const { data: tenant } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ? [ organizationManagementTenantUri, tenantId ] : null,
        getTenantById,
    );

    const { data: permissions } = useSWR(
        ((organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId)) ?
            [ `${tagsUrl}/permissions`, organizationName, tenant?.name, accountId ] : null,
        (_url: string, organizationName: string, tenantName: string | undefined, selectedAccountId: string) =>
            getPermissions(organizationName, tenantName, selectedAccountId),
    );

    const {
        data: labelData, isValidating: loading, mutate: labelMutate,
    } = useSWR<{ totalCount: number; results: any[] }>(
        ((organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId)) ?
            [
                `${tagsUrl}/label`, {
                    top: size,
                    skip: index * size,
                    searchTerm,
                }, organizationName, tenant?.name, accountId,
            ] : null,
        (_url: string, pagination: IPagination, organizationName: string, tenantName: string | undefined, selectedAccountId: string) =>
            getLabels(pagination, organizationName, tenantName, selectedAccountId),
    );

    const openDeleteDialog = useTagsDeleteDialog('Label', organizationName, tenantId, () => {
        labelMutate();
        createNotification(translate({ id: 'CLIENT_TENANT_TAGS_DELETE_SUCCESS' }), notificationType.SUCCESS);
    });

    const handleAddLabel = useCallback(() => {
        history.push(getRoute(process.buildConfigs.showForMSI
            ? `${RouteNames.TenantTagsLabelsMsi}/add`
            : `${RouteNames.TenantTagsLabels}/add`.replace(':tenantId', tenantId)));
    }, [ getRoute, history, tenantId ]);

    const gridHeaderButtons: Array<GridHeaderButtons<ILabelModelObject>> = useMemo(() => {
        const actionList: Array<GridHeaderButtons<ILabelModelObject>> = [];
        const bulkDeleteAction: GridHeaderButtons<ILabelModelObject> = {
            id: 'deleteProperties',
            type: 'action',
            buttonType: 'mat-flat-button',
            icon: 'delete',
            color: 'warn',
            text: translate({ id: 'CLIENT_DELETE' }),
            label: translate({ id: 'CLIENT_DELETE' }),
            onClick: (rows) => openDeleteDialog(rows ?? []),
            disabled: checkDisabled(permissions, [ PermissionType.View, PermissionType.Delete ]),
        };

        const addLabel: GridHeaderButtons<ILabelModelObject> = {
            id: 'addProperty',
            type: 'main',
            buttonType: 'mat-flat-button',
            color: 'primary',
            text: translate({ id: 'CLIENT_ADD_LABEL' }),
            label: translate({ id: 'CLIENT_ADD_LABEL' }),
            onClick: handleAddLabel,
            disabled: checkDisabled(permissions, [ PermissionType.View, PermissionType.Create ]),
        };

        isAdmin && actionList.push(...[ addLabel, bulkDeleteAction ]);

        return actionList;
    }, [ checkDisabled, handleAddLabel, isAdmin, openDeleteDialog, permissions, translate ]);

    const changePageIndex = useCallback((pageIndex) => {
        setPageIndex(pageIndex);
        mutate([
            [
                `${tagsUrl}/label`, {
                    top: size,
                    skip: pageIndex * size,
                    searchTerm,
                }, organizationName, tenant?.name, accountId,
            ],
            (_url: string, pagination: IPagination, organizationName: string, tenantName: string, selectedAccountId: string) =>
                getLabels(pagination, organizationName, tenantName, selectedAccountId),
        ]);
    }, [ accountId, organizationName, searchTerm, size, tenant?.name ]);

    const changePageSize = useCallback((pageSize) => {
        setPageSize(pageSize);
        mutate([
            [
                `${tagsUrl}/label`, {
                    top: pageSize,
                    skip: index * pageSize,
                    searchTerm,
                }, organizationName, tenant?.name, accountId,
            ],
            (_url: string, pagination: IPagination, organizationName: string, tenantName: string, selectedAccountId: string) =>
                getLabels(pagination, organizationName, tenantName, selectedAccountId),
        ]);
    }, [ accountId, index, organizationName, searchTerm, tenant?.name ]);

    const columnDef: Array<IColumn<ILabelModelObject>> = useMemo(() => [
        {
            property: 'name',
            title: translate({ id: 'CLIENT_LABEL' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'description',
            title: translate({ id: 'CLIENT_DESCRIPTION' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'referencesCount',
            title: translate({ id: 'CLIENT_TAGGED_OBJECTS' }),
            sortable: false,
            visible: true,
        },
    ], [ translate ]);

    const extraActionRowButtons: Array<GridRowButtons<ILabelModelObject>> = useMemo(() => {
        if (!isAdmin) {
            return [];
        }
        return [
            ...(
                isAdmin ? [
                    {
                        id: 'showMoreActions',
                        label: translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' }),
                        icon: 'add',
                        dataCy: 'ap-data-grid-more-actions-button',
                        customTemplate: (row: ILabelModelObject) => elementToNode(
                            <ApolloThemeProvider>
                                <IntlProvider>
                                    <TenantTagsLabelsMoreActions
                                        logEvent={logEvent}
                                        getRoute={getRoute}
                                        history={history}
                                        row={row}
                                        organizationName={organizationName}
                                        tenantId={tenantId}
                                        tenantName={tenant?.name}
                                        permissions={permissions}
                                        onDelete={openDeleteDialog} />
                                </IntlProvider>
                            </ApolloThemeProvider>
                        ),
                    },
                ] : []
            ),
        ];
    }, [ isAdmin, translate, logEvent, getRoute, history, organizationName, tenantId, tenant?.name, permissions, openDeleteDialog ]);

    const configuration: IGridOptions<any> = useMemo(() => ({
        loading,
        columns: columnDef,
        headerOptions: {
            search: true,
            searchTerm: (term: string) => setSearchTerm(term),
            gridHeaderButtons,
        },
        refreshable: true,
        data: labelData?.results ?? [],
        refresh: labelMutate,
        footerOptions: {
            length: labelData?.totalCount,
            pageSizes: [ 5, 10, 25, 50 ],
            pageIndex: index,
            pageSize: size,
            pageChange: ({
                pageIndex, pageSize,
            }: {
                pageIndex: number; pageSize: number;
            }) => {
                index !== pageIndex && changePageIndex(pageIndex);
                size !== pageSize && changePageSize(pageSize);
            },
        },
        toggleColumns: true,
        gridRowButtons: extraActionRowButtons,
        onGridApi: ({ selectionManager }: {
            selectionManager: SelectionManager<ILabelModelObject>;
        }) => {
            setSelectionMgr(selectionManager);
        },
        selectable: hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ]),

    }), [
        changePageIndex,
        changePageSize,
        columnDef,
        extraActionRowButtons,
        gridHeaderButtons,
        index,
        labelData?.results,
        labelData?.totalCount,
        labelMutate,
        loading,
        permissions,
        size,
    ]);

    useEffect(() => {
        if (apGridRef.current && configuration) {
            apGridRef.current.configuration = configuration;
        }
    }, [ configuration ]);

    useEffect(() => {
        if (labelData && selectionMgr) {
            (selectionMgr as any).clear();
        }
    }, [ labelData, selectionMgr ]);

    return {
        configuration,
        organizationName,
        tenantName: tenant?.name,
        tenantId,
        isAdmin,
        history,
        openDeleteDialog,
        apGridRef,
        handleAddLabel,
        gridHeaderButtons,
    };
};

export default useTenantTagsLabelsViewModel;
