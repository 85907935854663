import {
    AccountLicense,
    CanaryTenantLimitedServicesLinks,
} from '@experiences/constants';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { useLocalizedLinks } from '@experiences/util';
import {
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, {
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { IService } from '../../../../common/interfaces/tenant/tenant';
import { getAvailableServices } from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../../../store/selectors';
import UiAlertBanner from '../../../common/UiAlertBanner';
import type { IServiceMetadata } from '../../interfaces/service';
import { serviceOrder } from '../../TenantConstants';
import type { IServiceRegionMap } from '../../TenantsPageComponent';
import CreateEditTenantFormComponent from '../forms/CreateEditTenantFormComponent';
import { getDefaultRegionForTenant } from '../helpers/TenantRegionHelper';
import type { ServiceErrorType } from '../helpers/TenantServiceErrorMessage';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        licenseInfo: {
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        serviceIconColor: { color: Tokens.Colors.ColorBlue600 },
        errorText: {
            fontSize: '11px',
            color: theme.palette.semantic.colorErrorText,
        },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            marginTop: '4px',
        },
    }),
}));

const TenantCreateServicesComponent: React.FC = () => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinkConstant = useLocalizedLinks();

    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ],
    );

    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });

    const methods = useFormContext<ICreateEditTenantPayload>();

    const {
        watch, formState,
    } = methods;

    const { errors } = formState;

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ '/api/tenant/availableservices', partitionGlobalId, accountName, watch('customProperties.isCanaryTenant') ],
        getAvailableServices,
    );

    const defaultRegion = useMemo(() => getDefaultRegionForTenant(availableServices), [ availableServices ]);

    const {
        services, servicesAltRegion,
    } = useMemo(() => {
        const services = [] as IService[];
        const servicesAltRegion = services
            .reduce((map, service) => {
                map[service.serviceType] = service.region;
                return map;
            }, {} as IServiceRegionMap);
        return {
            services,
            servicesAltRegion,
        };
    }, []);

    return (
        <div className={classes.mainContainer}>
            {watch('customProperties.isCanaryTenant') && availableServices &&
            <span style={{
                width: '100%',
                alignContent: 'center',
            }}>
                <UiAlertBanner
                    dataCy='canary-services-banner'
                    type="info"
                    closeable={false}
                    enterpriseTrialAlertBar>
                    <div className={classes.banner}>
                        {translate({ id: 'CLIENT_CANARY_TENANT_SERVICES' })}
                        <Link
                            style={{ marginLeft: '8px' }}
                            data-cy="learn-region-hosting-button"
                            href={getLocalizedLinkConstant(CanaryTenantLimitedServicesLinks)}
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>
                    </div>
                </UiAlertBanner>
            </span>}
            <div style={{ marginTop: '16px' }}>
                <CreateEditTenantFormComponent
                    type="add"
                    availableServices={
                        availableServices?.sort(
                            (serviceA, serviceB) => serviceOrder.indexOf(serviceA.id) - serviceOrder.indexOf(serviceB.id),
                        )
                    }
                    services={services}
                    servicesAltRegion={servicesAltRegion}
                    isRegionEnabled={isRegionEnabled}
                    validationErrorHandler={validationErrorHandlers}
                    defaultRegion={defaultRegion}
                />
                {!!errors.services && (
                    <Typography className={classes.errorText}>
                        {translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default TenantCreateServicesComponent ;
