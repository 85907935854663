import { UiProgressButton } from '@experiences/ui-common';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    AuthSettingPasswordKey,
    AuthSettingUserLockOutKey,
    ShouldChangePassword,
} from '../../../common/constants/AuthSettingConstant';
import { notificationType } from '../../../common/constants/Constant';
import type { ISecurityAuthenticationSettingsData } from '../../../common/interfaces/authSetting';
import type { ISetting } from '../../../services/identity/SettingService';
import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import {
    mapSecurityAuthSettingDataToKeyValuePairs,
    mapSettingArrayToSecurityAuthSettingsData,
} from '../../../util/setting/AuthSettingUtil';
import UiForm from '../../common/UiForm';
import PasswordPolicyForm from './PasswordPolicyForm';

const useStyles = makeStyles(theme =>
    createStyles({
        formWrapper: {
            flex: 1,
            height: '100%',
        },
        accordionContentDivided: {
            padding: '16px 16px 0px 16px',
            flexDirection: 'row',
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            width: '100%',
            gap: '16px',
        },
        section: { marginBottom: '14px' },
        subHeading: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        inputLabel: {
            fontWeight: 'bold',
            fontSize: '14px',
            lineHeight: '20px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        column: {
            flex: 1,
            minWidth: '200px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
        inputAdornment: { width: 'auto' },
        outlinedInput: {
            display: 'flex',
            marginTop: '8px',
        },
        outlinedInputWrapper: { marginBottom: '20px' },
        indentedSection: { marginLeft: '8px' },
    }),
);

export const defaultSecurityAuthenticationSettingsData: ISecurityAuthenticationSettingsData = {
    password: {
        defaultExpirationDays: 0,
        previousUseLimit: 0,
        shouldChangePasswordAfterFirstLogin: ShouldChangePassword.NotRequired,
        passwordComplexity: {
            hasSpecialCharacter: false,
            hasLowercase: false,
            hasUppercase: false,
            hasDigit: false,
            Length: 6,
        },
    },
    userLockOut: {
        isEnabled: false,
        defaultAccountLockoutSeconds: 0,
        maxFailedAccessAttemptsBeforeLockout: 2,
    },
};

const SecuritySettingsComponent: FC = () => {

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const partitionGlobalId = useSelector(accountGlobalId);

    const methods = useForm<ISecurityAuthenticationSettingsData>({
        mode: 'onSubmit',
        defaultValues: defaultSecurityAuthenticationSettingsData,
    });

    const {
        formState, handleSubmit, reset,
    } = methods;
    const {
        isDirty, isSubmitting,
    } = formState;

    const keys = useMemo(
        () => [
            AuthSettingPasswordKey.DefaultExpirationDays,
            AuthSettingPasswordKey.PasswordComplexity,
            AuthSettingPasswordKey.PreviousUseLimit,
            AuthSettingPasswordKey.ShouldChangePasswordAfterFirstLogin,
            AuthSettingUserLockOutKey.DefaultAccountLockoutSeconds,
            AuthSettingUserLockOutKey.MaxFailedAccessAttemptsBeforeLockout,
            AuthSettingUserLockOutKey.IsEnabled,
        ],
        [],
    );
    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: ISecurityAuthenticationSettingsData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapSecurityAuthSettingDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ createNotification, mutate, partitionGlobalId, translate ],
    );

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToSecurityAuthSettingsData(fetchedSettings));
        }
    }, [ reset, fetchedSettings ]);

    return (
        <div className={classes.formWrapper}>
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        {isDirty && (
                            <Button
                                className={classes.cancelButton}
                                onClick={() => {
                                    handleCancel();
                                }}
                                color="primary"
                                data-cy="security-settings-cancel-button"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                        )}
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="security-settings-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
            >
                <FormProvider {...methods}>
                    <PasswordPolicyForm />
                </FormProvider>
            </UiForm>
        </div>
    );
};

// ts-unused-exports:disable-next-line
export default SecuritySettingsComponent;
