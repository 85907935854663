import { DIRECT_BUY_DATA } from '@experiences/ecommerce';
import { UiDialog } from '@experiences/ui-common';
import { UiStorage } from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import useAuthentication from '../../auth/hooks/Authentication';

const useStyles = makeStyles(() =>
    createStyles({ orgsBodyText: { wordBreak: 'break-word' } }),
);

const CannotUpgradeOrganizationDialog: React.FC<{
    isAdmin: boolean;
}> = ({ isAdmin }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ shouldRenderDialog, setShouldRenderDialog ] = useState(true);

    const { logout } = useAuthentication();

    const close = useCallback(() => {
        UiStorage.removeItem(DIRECT_BUY_DATA);
        setShouldRenderDialog(false);
    }, []);

    return shouldRenderDialog ? (
        <UiDialog
            close={close}
            title={translate({ id: 'CLIENT_YOU_CANNOT_UPGRADE_THIS_ORGANIZATION' })}
            width="412px"
            actions={[
                <Button
                    key={1}
                    variant="contained"
                    onClick={() => logout()}
                    data-cy="switch-organization"
                >
                    {translate({ id: 'CLIENT_SWITCH_ORGANIZATION' })}
                </Button>,
            ]}
        >
            <Typography
                className={classes.orgsBodyText}
                data-cy="organization-switcher-text">
                {translate(
                    { id: isAdmin ? 'CLIENT_YOU_CANNOT_UPGRADE_ALREADY_PAID' : 'CLIENT_YOU_CANNOT_UPGRADE_NO_ADMIN' },
                )}
            </Typography>
        </UiDialog>
    ) : null;
};

export default CannotUpgradeOrganizationDialog;
