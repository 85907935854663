import { UiProgressButton } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import InfoIcon from '@mui/icons-material/Info';
import {
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router';
import useSWR, { mutate } from 'swr';

import {
    licenseTypes,
    notificationType,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import {
    productNameTranslationCode,
    productSubLabelTranslationCode,
} from '../../../common/LicenseNameTranslation';
import {
    allocateOrganizationLicense,
    getOrganizationLicenseWithAvailableProducts,
    licenseManagementHostUrl,
    removeHostedLicense,
} from '../../../services/licensing/management/HostService';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../../services/organization/OnPremOrganizationService';
import type { IEditPasswordData } from '../../common/EditPasswordFormComponent';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import type {
    IOrganizationLicenseWithAvailableProducts,
    IProductAvailability,
    IProductQuantity,
} from '../../licensing/interfaces/license';
import type { IConfigureOrganizationLicenses } from '../../licensing/subcomponents/AddEditOrganizationLicenseComponent';
import {
    buildProductQuantitiesMap,
    groupLicensesByType,
} from '../../licensing/subcomponents/LicensingHelpers';
import ProductQuantityHelperText from '../../licensing/subcomponents/ProductQuantityHelperText';
import { UiAccordion } from '../../licensing/UiAccordion';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import type { IOnPremOrganizationResponse } from '../subcomponents/OrganizationGridComponent';

interface IAddOrganizationData extends IEditPasswordData {
    organizationName: string;
    adminUserName: string;
    adminFirstName: string;
    adminLastName: string;
    adminEmail: string;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        input: { marginTop: 20 },
        inputLine: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: {
            marginLeft: '24px',
            marginBottom: '12px',
        },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        inputEndAdornment: { color: theme.palette.semantic.colorForegroundDisable },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        labelGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        subText: { color: theme.palette.semantic.colorForegroundDisable },
        standaloneInfo: {
            marginLeft: '24px',
            marginBottom: '12px',
            display: 'flex',
            backgroundColor: theme.palette.semantic.colorInfoBackground,
            borderColor: theme.palette.semantic.colorInfoForeground,
            borderWidth: '1px',
            borderStyle: 'solid',
        },
        infoIcon: {
            color: theme.palette.semantic.colorInfoForeground,
            margin: '12px 9.5px 0px 9.5px',
        },
        textBox: {
            margin: '10px 10px 10px 0px',
            color: theme.palette.semantic.colorForeground,
        },
        removeLicenseAllocationButton: { margin: '0px 0px 12px 0' },
    }),
);

const HostOrganizationLicense: FC = () => {

    const createDialog = useShowDialog();
    const classes = useStyles();
    const match = useRouteMatch<{ organizationId: string }>();

    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(false);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const { organizationId } = useMemo<{ organizationId: string }>(
        () => ({ organizationId: match.params.organizationId }),
        [ match ],
    );

    const { data: organizations } = useSWR<IOnPremOrganizationResponse>([ `${onPremOrganizationManagementUri}/getAllOrganizations` ], getAllOnPremOrganizations);

    const organizationName = useMemo(() => organizations?.results.find(orgIterator => orgIterator.id === organizationId)?.name ?? ''
        , [ organizationId, organizations?.results ]);

    const { data: organizationLicenseAndAvailableProducts } = useSWR<
    IOrganizationLicenseWithAvailableProducts,
    Error
    >([ `${licenseManagementHostUrl}/getOrganizationLicenseWithAvailableProducts`, organizationId ?? null ], () =>
        getOrganizationLicenseWithAvailableProducts(organizationId),
    );

    const isStandaloneLicense = useMemo(() => organizationLicenseAndAvailableProducts?.license?.type === licenseTypes.STANDALONE, [ organizationLicenseAndAvailableProducts?.license?.type ]);

    const {
        licensesByGroups, availableProducts,
    } = useMemo(() => {
        if (!organizationLicenseAndAvailableProducts) {
            return {};
        }
        const availableProducts = buildProductQuantitiesMap(
            organizationLicenseAndAvailableProducts!,
            isStandaloneLicense,
        );

        const licensesByGroups = groupLicensesByType(organizationLicenseAndAvailableProducts, isStandaloneLicense);

        return {
            licensesByGroups,
            availableProducts,
        };
    }, [ organizationLicenseAndAvailableProducts, isStandaloneLicense ]);

    const {
        control, handleSubmit, reset, errors, formState,
    } = useForm<IConfigureOrganizationLicenses>({
        mode: 'onSubmit',
        defaultValues: { products: availableProducts },
    });

    const { isDirty } = formState;

    useEffect(() => {
        if (availableProducts) {
            reset({ products: availableProducts });
        }
    }, [ availableProducts, reset ]);

    const maxAvailable = useCallback((currentQuantity: number, available: number) => available + currentQuantity > 0 ? available + currentQuantity : 0, []);

    const maxAvailableOrSpecialProduct = useCallback(
        (productType: string, currentQuantity: number, available: number) => available + currentQuantity > 0
            ? productType === 'UNLIMITED'
                ? 1
                : available + currentQuantity
            : 0,
        [],
    );

    const openRemoveLicenseDialog = useCallback(
        async () => {
            if (organizationId == null) {
                return;
            }
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' }),
                body: translate({ id: 'CLIENT_REMOVE_LICENSE_DIALOG' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
            });
            if (proceed) {
                setLoading(true);
                await removeHostedLicense(organizationId);
                await mutate(
                    [
                        `${licenseManagementHostUrl}/getOrganizationLicenseWithAvailableProducts`,
                        organizationId ?? null,
                    ],
                );
                createNotification(translate({ id: 'CLIENT_LICENSE_SUCCESSFULLY_REMOVED' }));
                setLoading(false);
            }
        },
        [ organizationId, createDialog, translate, createNotification ],
    );

    const onSave = useCallback(
        async (data: IConfigureOrganizationLicenses) => {
            setLoading(true);
            try {
                const productQuantities =
                    organizationLicenseAndAvailableProducts!.productsWithAvailability.map(
                        (pa: IProductAvailability): IProductQuantity => ({
                            code: pa.code,
                            quantity: Number(data.products?.[pa.code] ? data.products[pa.code] : 0),
                        }),
                    ) ?? [];
                await allocateOrganizationLicense(organizationId, { productQuantities });
                await mutate([ `${licenseManagementHostUrl}/getOrganizationLicenseWithAvailableProducts`, organizationId ?? null ]);
                createNotification(translate({ id: 'CLIENT_LICENSE_SUCCESSFULLY_CHANGED' }));
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        },
        [ createNotification, organizationId, organizationLicenseAndAvailableProducts, translate ],
    );

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationHome.replace(':organizationId', organizationId),
            name: organizationName,
        },
        {
            link: RouteNames.HostOrganizationLicense.replace(':organizationId', organizationId),
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ];

    return (
        <UiPageContainer
            position='left'
            header={AdminBreadCrumbs(breadCrumbLinks)}
            maxWidth='1100px'>
            <UiForm
                onSubmit={handleSubmit(onSave)}
                actions={
                    <div className={classes.actions}>
                        <UiProgressButton
                            loading={loading}
                            onClick={handleSubmit(onSave)}
                            variant="contained"
                            data-cy="configure-organization-license-save"
                            disabled={(isStandaloneLicense && !loading) || !isDirty}
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <>
                    {isStandaloneLicense && (
                        <div className={clsx(classes.standaloneInfo)}>
                            <InfoIcon className={clsx(classes.infoIcon)} />
                            <Typography className={clsx(classes.textBox)}>
                                {translate({ id: 'CLIENT_SELF_LICENSED_ORGANIZATION_MESSAGE' })}
                            </Typography>
                        </div>
                    )}
                    {organizationLicenseAndAvailableProducts?.license != null && (
                        <UiProgressButton
                            loading={false}
                            variant="outlined"
                            className={classes.removeLicenseAllocationButton}
                            data-cy="remove-license-allocation-from-drawer-button"
                            onClick={() => openRemoveLicenseDialog()}
                        >
                            {translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' })}
                        </UiProgressButton>
                    )}
                    <div>
                        {(licensesByGroups ?? [])
                            .filter(group => group.products.length > 0)
                            .map((group, idx) => (
                                <UiAccordion
                                    key={idx}
                                    titleTranslationCode={'CLIENT_ORG_LICENSE_PANEL_' + group.id}>
                                    <div className={clsx(classes.groupColumn)}>
                                        {(group.products ?? [])
                                            .filter(
                                                product =>
                                                    !process.buildConfigs.unavailableProductCodes?.includes(
                                                        product.code,
                                                    ) ?? true,
                                            )
                                            .map(product => (
                                                <div
                                                    key={product.code}
                                                    className={clsx(
                                                        classes.input,
                                                        classes.inputLine,
                                                        classes.inputMargin,
                                                    )}
                                                >
                                                    <div className={clsx(classes.labelGroup)}>
                                                        <Typography
                                                            className={clsx(classes.inputLabel)}
                                                            id={`${product.code}Label`}
                                                        >
                                                            {translate({
                                                                id: `CLIENT_${productNameTranslationCode(
                                                                    product.code,
                                                                )}`,
                                                            })}
                                                        </Typography>
                                                        <Typography
                                                            className={clsx(classes.inputLabel, classes.subText)}
                                                        >
                                                            {translate({
                                                                id: `CLIENT_${productSubLabelTranslationCode(
                                                                    product.code,
                                                                    product.type,
                                                                )}`,
                                                            })}
                                                        </Typography>
                                                    </div>
                                                    <Controller
                                                        as={
                                                            <TextField
                                                                variant="outlined"
                                                                error={false}
                                                                data-cy={`product-quantity-${product.code}`}
                                                                disabled={isStandaloneLicense}
                                                                InputProps={{
                                                                    type: 'number',
                                                                    endAdornment: !isStandaloneLicense && (
                                                                        <InputAdornment
                                                                            position="end"
                                                                            className={clsx(classes.inputEndAdornment)}
                                                                        >
                                                                            /
                                                                            {' '}
                                                                            {maxAvailable(
                                                                                product.quantity,
                                                                                product.available,
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                inputProps={{ 'aria-labelledby': `${product.code}Label` }}
                                                            />
                                                        }
                                                        control={control}
                                                        rules={{
                                                            required: true,
                                                            min: product.consumedQuantity,
                                                            max: maxAvailableOrSpecialProduct(
                                                                product.type,
                                                                product.quantity,
                                                                product.available,
                                                            ),
                                                        }}
                                                        name={`products[${product.code}]`}
                                                        defaultValue="0"
                                                        error={
                                                            (errors.products && !!errors.products[`${product.code}`]) ||
                                                            (product.available as number) < 0
                                                        }
                                                        helperText={
                                                            <ProductQuantityHelperText
                                                                errors={errors}
                                                                product={product}
                                                            />
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ maxWidth: '50%' }}
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                </UiAccordion>
                            ))}
                    </div>
                </>
            </UiForm>
        </UiPageContainer>
    );
};

export default HostOrganizationLicense;
