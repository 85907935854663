import {
    useCallback,
    useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useParams,
} from 'react-router-dom';

import useAuthentication from '../../../auth/hooks/Authentication';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';

const useOrganizationMismatchViewModel = () => {
    const history = useHistory();

    const [ shouldRenderDialog, setShouldRenderDialog ] = useState(true);

    const accountNameInStore = useSelector(accountLogicalName);
    const accountGuidInStore = useSelector(accountGlobalId);
    const { accountName } = useParams<{ accountName: string }>();

    const { logout } = useAuthentication();

    const close = useCallback(() => {
        history.goBack();
        setShouldRenderDialog(false);
    }, [ history, setShouldRenderDialog ]);

    return {
        shouldRenderDialog,
        accountNameInStore,
        accountGuidInStore,
        accountName,
        logout,
        close,
    };
};

export default useOrganizationMismatchViewModel;
