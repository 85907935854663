import type { IPagination } from '@experiences/interfaces';

import type { IOnPremOrganizationDto } from '../../common/interfaces/organization';
import type { IOnPremOrganizationResponse } from '../../component/organization/subcomponents/OrganizationGridComponent';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

export const onPremOrganizationManagementUri = '/onprem/api/organization';

export function addOnPremOrganization(onPremOrganizationDto: IOnPremOrganizationDto) {
    return post(onPremOrganizationManagementUri, { body: onPremOrganizationDto });
}

export function deleteOnPremOrganization(organizationGuid: string) {
    return axiosDelete(`${onPremOrganizationManagementUri}/${organizationGuid}`);
}

export function getAllOnPremOrganizations(pagination?: IPagination) {
    return get<IOnPremOrganizationResponse>(`${onPremOrganizationManagementUri}/getAllOrganizations`, { pagination });
}
