import type { ITenant } from '@experiences/interfaces';
import useSWR from 'swr';

import { TenantStatusConstants } from '../../component/tenants/TenantConstants';
import {
    getTenantById,
    tenantByIdUrl,
} from '../../services/organization/TenantService';

export const useCheckOrchestratorEnabled = (tenantId: string) => {
    const { data: tenant } = useSWR<ITenant>(
        tenantId ? [ tenantByIdUrl, tenantId ] : null,
        getTenantById,
    );

    return tenant?.tenantServiceInstances.some((service) => service.serviceType === 'orchestrator'
            && service.status?.toUpperCase() === TenantStatusConstants.ENABLED);
};
