import { useRouteResolver } from '@experiences/util';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../common/constants/RouteNames';
import { isHostModeSelector } from '../../store/selectors';
import { useIsAdminRevampEnabled } from './useIsAdminRevampEnabled';

export const useAdminNavigationRoute = () => {
    const isHost = useSelector(isHostModeSelector);
    const getRoute = useRouteResolver();
    const adminRevampEnabled = useIsAdminRevampEnabled();

    const hostRoute = getRoute(isHost
        ? RouteNames.Organizations
        : RouteNames.Services);

    const routeIfAdminRevamp = adminRevampEnabled
        ? getRoute(RouteNames.OrganizationAdminHome)
        : hostRoute;

    return routeIfAdminRevamp;
};
