import { Entitlements } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../../../common/constants/RouteNames';
import useCheckLicense from '../../../../common/hooks/useCheckLicense';
import { useOrganizationName } from '../../../../common/hooks/useOrganizationName';
import type { IKmsConfig } from '../../../../services/identity/KeyManagementService';
import {
    getKmsConfig,
    kmsUrl,
} from '../../../../services/identity/KeyManagementService';
import { isEntitled } from '../../../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../../../store/selectors';
import { EncryptionType } from './EncryptionComponent';
import EncryptionConsentDialogBody from './EncryptionConsentDialogBody';
import EncryptionWarningDialogBody from './EncryptionWarningDialogBody';

const entitlementsKey = '/api/license/accountant/Entitlement/';

const useEncryptionViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const createDialog = useShowDialog();
    const { isUserEnterprise } = useCheckLicense();
    const organizationName = useOrganizationName();
    const EnableCustomerManagedKeyEntitlement = useFeatureFlagValue(Features.EnableCustomerManagedKeyEntitlement.name);

    const partitionGlobalId = useSelector(accountGlobalId);

    const { data: kmsConfig } = useSWR<IKmsConfig, Error>(
        [ kmsUrl, partitionGlobalId ],
        getKmsConfig,
    );

    const [ encryptionType, setEncryptionType ] = useState<EncryptionType>(EncryptionType.UIPATH);

    useEffect(() => {
        if (kmsConfig) {
            setEncryptionType(kmsConfig.keyType === 1 ? EncryptionType.CUSTOMER : EncryptionType.UIPATH);
        }
    }, [ kmsConfig ]);

    const { data: hasEntitlement } =
        useSWR<boolean, Error>([ entitlementsKey, partitionGlobalId, Entitlements.CustomerManagedKey, EnableCustomerManagedKeyEntitlement ],
            (key, organizationId, entitlement, flag) => flag ? isEntitled(entitlement) : false);

    const refreshRadioGroup = useCallback(() => {
        mutate([ kmsUrl, partitionGlobalId ]);
    }, [ partitionGlobalId ]);

    const addConnection = useCallback(() => {
        history.push({ pathname: getRoute(`${RouteNames.Encryption}/configure/add`) });
    }, [ getRoute, history ]);

    const editConnection = useCallback(() => {
        history.push({ pathname: getRoute(`${RouteNames.Encryption}/configure/edit`) });
    }, [ getRoute, history ]);

    const handleChange = useCallback(async (e, value) => {
        if (value === EncryptionType.CUSTOMER) {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_CONSENT_HEADER' }),
                customDialogContent: EncryptionConsentDialogBody,
                customDialogContentProps: { organizationName },
                icon: 'warning',
            });
            if (proceed) {
                addConnection();
            }
        } else {
            await createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_WARNING_TITLE' }),
                customDialogContent: EncryptionWarningDialogBody,
                customDialogContentProps: {
                    refreshCallback: () => {
                        refreshRadioGroup();
                    },
                },
                icon: 'info',
            });
        }
    }, [ createDialog, translate, organizationName, refreshRadioGroup, addConnection ]);

    return {
        addConnection,
        editConnection,
        handleChange,
        encryptionType,
        kmsConfig,
        isUserEnterprise,
        hasEntitlement,
        loading: (EnableCustomerManagedKeyEntitlement && hasEntitlement == null || !kmsConfig),
    };
};

export default useEncryptionViewModel;
