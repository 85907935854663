import type { Region } from '@experiences/constants';
import { AccountLicense } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutline';
import {
    Button,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import {
    isString,
    xor,
} from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import useSWR, { mutate } from 'swr';

import { TenantsAndServicesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { triggerPortalShellRefresh } from '../../../common/hooks/triggerPortalShellRefresh';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import { leaseUrl } from '../../../services/licensing/accountant/LeaseService';
import {
    getAvailableServices,
    getTenantById,
    organizationManagementTenantUri,
    tenantAvailableServicesUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../../store/selectors';
import validateName from '../../../util/validators/NameValidator';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import TenantGeneralForm from '../general/TenantGeneralForm';
import type { IServiceMetadata } from '../interfaces/service';
import { serviceOrder } from '../TenantConstants';
import { serviceInstanceUrl } from '../TenantsContextProvider';
import type { IServiceRegionMap } from '../TenantsPageComponent';
import CreateEditTenantFormComponent from './forms/CreateEditTenantFormComponent';
import { NeedMoreServicesComponent } from './forms/NeedMoreServicesComponent';
import { addHiddenDependenciesHelper } from './helpers/AddHiddenDependenciesHelper';
import { getHiddenDependencyParent } from './helpers/ServiceDependencyGraph';
import type { ServiceErrorType } from './helpers/TenantServiceErrorMessage';

export enum ServiceLicenseStatus {
    Available = '_0',
    Used = '_1',
    NotAvailable = '_2',
}

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        colorPicker: { marginLeft: '8px' },
        formContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        errorText: {
            fontSize: '11px',
            color: theme.palette.semantic.colorErrorText,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        defaultAllocationInfo: { marginTop: '32px' },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        licenseInfo: {
            fontSize: '12px',
            lineHeight: '16px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        selectLabel: { overflow: 'visible' },
        serviceLine: {
            fontSize: '14px',
            fontWeight: 600,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        serviceIconColor: { color: Tokens.Colors.ColorBlue600 },
        formContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            maxWidth: '480px',
        },
    }),
}));

export interface IEditTenantPayload extends Omit<ICreateEditTenantPayload, 'services' | 'region'> {
    services: { [key: string]: boolean };
}

const CreateEditTenantComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'pt-BR', 'tr', 'zh-CN', 'zh-TW' ] });
    const tenantOperations = useTenantOperations();

    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);
    const EnableShowNeedMoreServicesComponent = useFeatureFlagValue(Features.EnableShowNeedMoreServicesComponent.name);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const match = useRouteMatch<{ type: 'add' | 'edit'; tenantId: string }>();
    const createDialog = useShowDialog();

    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const currentAccountType = useSelector(accountType);

    const {
        open, close,
    } = useModalState(RouteNames.Services);

    const [ errorMessage, setErrorMessage ] = useState<string | undefined>(undefined);
    const [ loading, setLoading ] = useState(false);
    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });

    const {
        type, id,
    } = useMemo<{ type: 'add' | 'edit'; id: string }>(
        () => ({
            type: match.params.type === 'add' ? 'add' : 'edit',
            id: match.params.tenantId,
        }),
        [ match.params ],
    );

    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ],
    );

    const {
        data: tenant,
        error: tenantError,
    } = useSWR<ITenant>(
        (type === 'edit' && id)
            ? [ `${organizationManagementTenantUri}/tenantById`, id ]
            : null,
        getTenantById,
    );

    const initialValuesRef = useMemo<ICreateEditTenantPayload>(() => ({
        name: tenant?.name ?? '',
        color: tenant?.color ?? '',
        region: (tenant?.region as Region) ?? '',
        services: tenant?.tenantServiceInstances.map(service => service.serviceType) ?? [],
    }), [ tenant ]);

    const {
        services, servicesAltRegion,
    } = useMemo(() => {
        const services = tenant?.tenantServiceInstances ?? [];
        const servicesAltRegion = services
            .filter(service => service.region !== tenant?.region)
            .reduce((map, service) => {
                map[service.serviceType] = service.region;
                return map;
            }, {} as IServiceRegionMap);
        return {
            services,
            servicesAltRegion,
        };
    }, [ tenant?.region, tenant?.tenantServiceInstances ]);

    const methods = useForm<ICreateEditTenantPayload>({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: {
            name: tenant?.name ?? '',
            color: tenant?.color ?? '',
            region: (tenant?.region as Region) ?? '',
            services: [],
        },
    });

    const {
        formState, errors, handleSubmit, setError, reset,
    } = useMemo(() => methods, [ methods ]);
    const { isDirty } = formState;

    const {
        data: availableServices,
        error: servicesError,
    } = useSWR<IServiceMetadata[], Error>(
        [ tenantAvailableServicesUri, partitionGlobalId, accountName, tenant?.isCanaryTenant ],
        getAvailableServices,
    );

    useEffect(() => {
        if (tenant && availableServices) {
            reset(initialValuesRef);
        }
    }, [ availableServices, initialValuesRef, reset, tenant ]);

    useEffect(() => {
        if (tenantError || servicesError || (availableServices && availableServices.length <= 0)) {
            setErrorMessage(translate({ id: 'CLIENT_UNKNOWN_ERROR_FROMBACKEND' }));
        }
    }, [ availableServices, servicesError, setErrorMessage, tenantError, translate ]);

    const onSubmit = useCallback(
        async (data: ICreateEditTenantPayload) => {
            setLoading(true);

            data.name = data.name.trim();

            if (!Object.values(data.services).some(service => !!service)) {
                setError('services', { type: 'required' });
                setLoading(false);
                return;
            }
            if (!validateName(data.name)) {
                setError('name', { type: 'invalid' });
                setLoading(false);
                return;
            }

            try {
                if (type === 'add') {
                    data.services = addHiddenDependenciesHelper(data.services, availableServices);
                    await tenantOperations.tenantCreate(data);
                } else {
                    const proceed = data.name !== tenant?.name
                        ? await createDialog({
                            title: translate({ id: 'CLIENT_CHANGE_SERVICE_NAME' }),
                            body: (
                                <div>
                                    <Typography>
                                        <FormattedMessage
                                            id={
                                                process.buildConfigs.disableUserInvite || DisableFeatureFedRamp
                                                    ? 'CLIENT_WARNING_MESSAGE_FOR_EDIT_SERVICE_NAME_NO_INVITE'
                                                    : 'CLIENT_WARNING_MESSAGE_FOR_EDIT_SERVICE_NAME'
                                            }
                                            values={{
                                                strong: (chunk: string) => <strong>
                                                    {chunk}
                                                </strong>,
                                            }}
                                        />
                                    </Typography>
                                    <Typography style={{ marginTop: '8px' }}>
                                        {translate({ id: 'CLIENT_PROCEED_MESSAGE' })}
                                    </Typography>
                                </div>
                            ),
                            icon: 'warning',
                            showCancel: true,
                            unclosable: true,
                            primaryButtonText: translate({ id: 'CLIENT_PROCEED' }),
                            dataCy: 'create-edit-tenant-confirmation-dialog',
                        })
                        : true;
                    if (proceed) {
                        // send only changed services
                        let changedServices = xor(
                            services.map(service => service.serviceType),
                            data.services,
                        );
                        changedServices = addHiddenDependenciesHelper(changedServices, availableServices);
                        const changedServicesObj = Object.assign(
                            {},
                            ...changedServices.map(s => {
                                const implicitParent = getHiddenDependencyParent(s);
                                return {
                                    [s]:
                                        data.services.indexOf(s) > -1
                                        || (!!implicitParent && data.services.indexOf(implicitParent) > -1),
                                };
                            }),
                        );
                        await tenantOperations.tenantEdit(data, tenant, changedServicesObj);
                        triggerPortalShellRefresh();
                    } else {
                        setLoading(false);
                        return;
                    }
                }
                setLoading(false);

                mutate(
                    !EnableGetTenantsRedesign
                        ? [ serviceInstanceUrl, partitionGlobalId, accountName, true ]
                        : [ serviceInstanceUrl, partitionGlobalId, accountName, 'All', undefined, undefined, true ],
                );
                mutate(leaseUrl);

                close();
            } catch (error) {
                const errorObject = await getErrorObject(error);

                if (errorObject.response?.status === 504) {
                    setErrorMessage(translate({ id: 'CLIENT_CONNECTION_TIMEOUT_MESSAGE' }));
                } else {
                    const data = errorObject.response?.data;
                    const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
                    setErrorMessage(errorResponse);
                }
                setLoading(false);
            }
        },
        [
            setError,
            type,
            EnableGetTenantsRedesign,
            partitionGlobalId,
            accountName,
            close,
            availableServices,
            tenantOperations,
            tenant,
            createDialog,
            translate,
            DisableFeatureFedRamp,
            services,
            getErrorObject,
            getErrorMessage,
        ],
    );

    return (
        <UiDrawer
            title={type === 'add' ? translate({ id: 'CLIENT_ADD_TENANT' }) : translate({ id: 'CLIENT_TENANT_SETTINGS' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width="medium"
            loading={type === 'add' ? !availableServices : !tenant || !availableServices}
        >
            {!!errorMessage && (
                <div
                    className={classes.error}
                    data-cy="add-edit-tenant-error">
                    <Typography>
                        {errorMessage}
                    </Typography>
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setErrorMessage('');
                        }}
                        style={{ marginTop: '12px' }}
                        data-cy="add-edit-tenant-retry"
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                </div>
            )}
            {!errorMessage && (
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => close()}
                                color="primary"
                                data-cy="add-edit-tenant-cancel"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                type="submit"
                                loading={loading}
                                disabled={!isDirty || !!errorMessage}
                                variant="contained"
                                data-cy="add-edit-tenant-submit-button"
                            >
                                {translate({ id: 'CLIENT_SAVE' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                >
                    <div className={classes.formContainer}>
                        <div className={classes.formContent}>
                            <FormProvider {...methods}>
                                <TenantGeneralForm
                                    type={type}
                                    tenant={tenant}
                                    disableRegion={type === 'edit'} />
                            </FormProvider>
                            <>
                                <div style={{ marginTop: '16px' }}>
                                    <Typography className={clsx(classes.inputLabel, classes.serviceLine)}>
                                        {translate({ id: 'CLIENT_PROVISION_SERVICES' })}
                                        <IconButton
                                            target="_blank"
                                            href={getLocalizedLink(TenantsAndServicesLink)!}
                                            aria-label={translate({ id: 'CLIENT_PROVISION_SERVICES_LINK_ARIA' })}
                                        >
                                            <HelpOutlinedIcon className={classes.serviceIconColor} />
                                        </IconButton>
                                    </Typography>
                                    <FormProvider {...methods}>
                                        <CreateEditTenantFormComponent
                                            type={type}
                                            availableServices={
                                                availableServices?.sort(
                                                    (serviceA, serviceB) =>
                                                        serviceOrder.indexOf(serviceA.id) - serviceOrder.indexOf(serviceB.id),
                                                )
                                            }
                                            services={services}
                                            servicesAltRegion={servicesAltRegion}
                                            isRegionEnabled={isRegionEnabled}
                                            validationErrorHandler={validationErrorHandlers}
                                        />
                                    </FormProvider>
                                    {!!errors.services && (
                                        <Typography className={clsx(classes.errorText)}>
                                            {translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                        </Typography>
                                    )}
                                </div>
                                <div style={{ marginTop: '16px' }}>
                                    <Typography className={clsx(classes.inputLabel)}>
                                        {translate({ id: 'CLIENT_TENANT_LICENSE_ALLOCATION_HEADER' })}
                                    </Typography>
                                    <Typography className={classes.licenseInfo}>
                                        {translate({ id: 'CLIENT_TENANT_LICENSE_HELPER_TEXT' })}
                                    </Typography>
                                </div>
                                {EnableShowNeedMoreServicesComponent && type === 'add'
                                && AccountLicense[currentAccountType] === AccountLicense.COMMUNITY && (
                                    <NeedMoreServicesComponent />
                                )}
                            </>
                        </div>
                    </div>
                </UiForm>
            )}
        </UiDrawer>
    );
};

export default CreateEditTenantComponent;
