import { useShowDialog } from '@experiences/util';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { removeHostedLicense } from '../../../services/licensing/management/HostService';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../../services/organization/OnPremOrganizationService';
import type { IEditPasswordData } from '../../common/EditPasswordFormComponent';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import OnPremOrganizationDeleteDialogBody from '../subcomponents/OnPremOrganizationDeleteDialogBody';
import type { IOnPremOrganizationResponse } from '../subcomponents/OrganizationGridComponent';

interface IAddOrganizationData extends IEditPasswordData {
    organizationName: string;
    adminUserName: string;
    adminFirstName: string;
    adminLastName: string;
    adminEmail: string;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        deleteOrgTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        deleteOrgActions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        deleteOrgButtonRevamp: {
            marginTop: '16px',
            marginBottom: '16px',
            color: `${theme.palette.semantic.colorErrorText} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
                borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            },
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            width: '120px',
        },
        spacer: { marginTop: '24px' },
        removeLicenseButton: {
            marginTop: '16px',
            marginBottom: '16px',
            color: `${theme.palette.semantic.colorWarningText} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
                borderColor: `${theme.palette.semantic.colorWarningText} !important`,
            },
            borderColor: `${theme.palette.semantic.colorWarningText} !important`,
            width: '120px',
        },
        infoSection: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '32px',
        },
    }),
);

const HostOrganizationSettings: FC = () => {

    const classes = useStyles();
    const createDialog = useShowDialog();

    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const match = useRouteMatch<{ organizationId: string }>();
    const { organizationId } = useMemo<{ organizationId: string }>(
        () => ({ organizationId: match.params.organizationId }),
        [ match ],
    );

    const { data: organizations } = useSWR<IOnPremOrganizationResponse>([ `${onPremOrganizationManagementUri}/getAllOrganizations` ], getAllOnPremOrganizations);

    const organization = useMemo(() => organizations?.results.find(orgIterator => orgIterator.id === organizationId)
        , [ organizationId, organizations?.results ]);

    const openDeleteDialog = useCallback(
        async () => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_ORGANIZATION' }),
                customDialogContent: OnPremOrganizationDeleteDialogBody,
                customDialogContentProps: {
                    orgName: organization?.name ?? '',
                    orgId: organizationId,
                },
                icon: 'error',
                showCancel: false,
            });
            if (proceed) {
                enqueueSnackbar(translate({ id: 'CLIENT_ORGANIZATION_DELETED' }), { variant: notificationType.INPROGRESS as any });
            }
        },
        [ createDialog, translate, organization, organizationId, enqueueSnackbar ],
    );

    const openRemoveLicenseDialog = useCallback(
        async () => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' }),
                body: translate({ id: 'CLIENT_REMOVE_LICENSE_DIALOG' }),
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
            });
            if (proceed) {
                await removeHostedLicense(organizationId);
                enqueueSnackbar(translate({ id: 'CLIENT_LICENSE_SUCCESSFULLY_REMOVED' }), { variant: notificationType.SUCCESS as any });
            }
        },
        [ createDialog, translate, organizationId, enqueueSnackbar ],
    );

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationHome.replace(':organizationId', organizationId),
            name: organization?.name ?? '',
        },
        {
            link: RouteNames.HostOrganizationSettings.replace(':organizationId', organizationId),
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ];

    return (
        <UiPageContainer
            position='left'
            header={AdminBreadCrumbs(breadCrumbLinks)}
            maxWidth='1100px'>
            <div className={clsx(classes.infoSection, classes.spacer)}>
                <Typography
                    className={classes.deleteOrgTitle}
                    data-cy="organization-name-title">
                    {translate({ id: 'CLIENT_ORGANIZATION_NAME' })}
                </Typography>
                <TextField
                    style={{ marginTop: '4px' }}
                    disabled
                    value={organization?.name} />
                <Typography
                    className={clsx(classes.deleteOrgTitle, classes.spacer)}
                    data-cy="admin-email-organization-title">
                    {translate({ id: 'CLIENT_ADMIN_EMAIL' })}
                </Typography>
                <TextField
                    style={{ marginTop: '4px' }}
                    disabled
                    value={organization?.companyEmail} />
            </div>
            <Typography
                className={classes.deleteOrgTitle}
                data-cy="delete-organization-title">
                {translate({ id: 'CLIENT_REMOVE_LICENSE_ALLOCATION' })}
            </Typography>
            <div className={classes.deleteOrgActions}>
                <Button
                    className={classes.removeLicenseButton}
                    onClick={openRemoveLicenseDialog}
                    data-cy="remove-organization-license-button"
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_REMOVE' })}
                </Button>
            </div>
            <Typography
                className={clsx(classes.deleteOrgTitle, classes.spacer)}
                data-cy="delete-organization-title">
                {translate({ id: 'CLIENT_DELETE_ORGANIZATION' })}
            </Typography>
            <div className={classes.deleteOrgActions}>
                <Button
                    className={classes.deleteOrgButtonRevamp}
                    onClick={openDeleteDialog}
                    data-cy="delete-organization-button"
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </Button>
            </div>
        </UiPageContainer>
    );
};

export default HostOrganizationSettings;
