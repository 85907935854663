import { useLocalization } from '@experiences/locales';
import { UiDialog } from '@experiences/ui-common';
import { deserializeList } from '@experiences/util';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { isString } from 'lodash';
import type { ReactNode } from 'react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import { timestampToDateTime } from '../../util/DateUtil';
import { startCase } from '../../util/StringUtils';
import { UiGrid } from '../common/UiGrid';
import type { IAuditLog } from './interfaces/auditLog';

const useStyles = makeStyles(theme =>
    createStyles({
        modalDimensions: {
            maxHeight: '720px',
            overflowY: 'auto',
        },
        auditHeaderRow: {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 'bold',
        },
        auditValueRow: { marginLeft: '10px' },
        line: {
            margin: '20px 0 30px 0',
            border: `solid 1px ${theme.palette.semantic.colorBorderGrid}`,
        },
    }),
);

const AuditDialogGridComponent: React.FC<{ data: any; headers: { key: string; value: string } }> = ({
    data,
    headers,
}) => {
    const { formatMessage: translate } = useIntl();

    const deserializedData = useMemo(() => deserializeList(data), [ data ]);

    return deserializedData ? (
        <UiGrid
            tableHeight="180px"
            data={deserializedData}
            columns={[
                {
                    Header: translate({ id: headers.key }),
                    accessor: 'key',
                    width: 50,
                    disableSortBy: true,
                },
                {
                    Header: translate({ id: headers.value }),
                    accessor: 'value',
                    width: 50,
                    disableSortBy: true,
                },
            ]}
        />
    ) : null;
};

const AuditDialogComponent: React.FC<{
    location?: { state?: { data: IAuditLog } };
}> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const language = useLocalization();

    const data: IAuditLog | undefined = useMemo(() => {
        const passedData = location?.state?.data;
        if (passedData?.auditLogDetails && typeof passedData.auditLogDetails === 'string') {
            try {
                passedData.auditLogDetails = JSON.parse(passedData.auditLogDetails.replace(/\r\n/g, ''));
            } catch (error) {
                passedData.auditLogDetails = undefined;
            }
        }
        return passedData;
    }, [ location ]);

    const close = useCallback(() => {
        history.goBack();
    }, [ history ]);

    // Upper part
    const auditInformationRow = useCallback(
        (key: string, rowHeader: string, rowValue: ReactNode) => (
            <div
                key={key}
                data-cy="audit-information-row">
                <span className={classes.auditHeaderRow}>
                    {rowHeader}
:
                </span>
                <span className={classes.auditValueRow}>
                    {rowValue}
                </span>
            </div>
        ),
        [ classes.auditHeaderRow, classes.auditValueRow ],
    );

    const auditSplitInfoLine = useCallback(
        (key: string, rowHeader: string, text: string) => {
            if (text) {
                const lines = text.split(/\r\n|\r|\n|\\r\\n/g);
                return (
                    <div key={key}>
                        <span className={classes.auditHeaderRow}>
                            {rowHeader}
:
                        </span>
                        {lines.map((line, index) => (
                            <div
                                key={index}
                                className={classes.auditValueRow}>
                                {line.trim()}
                            </div>
                        ))}
                    </div>
                );
            }
            return null;
        },
        [ classes.auditHeaderRow, classes.auditValueRow ],
    );

    const {
        users,
        userName,
        adminName,
        userNames,
        tenantName,
        serviceName,
        licenseAllocationsAsText,
        policyData,
        oldPolicyData,
        newPolicyData,
        ...rest
    } = useMemo(() => data?.auditLogDetails, [ data ]);

    const getAuditRowHeader = useCallback(
        (key: string) => translate({
            id: `CLIENT_AUDITLOG_${key.toUpperCase()}`,
            defaultMessage: startCase(key),
        }),
        [ translate ],
    );

    const getAuditRowContent = useCallback(
        (key: string) => translate({
            id: `CLIENT_AUDITLOG_${key.toUpperCase()}`,
            defaultMessage: key,
        }),
        [ translate ],
    );

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_AUDIT_DATA' })}
            width="520px"
            close={() => close()}
            dialogProps={{
                open: true,
                classes: { root: classes.modalDimensions },
            }}
        >
            {data && (
                <>
                    {auditInformationRow('CATEGORY', translate({ id: 'CLIENT_CATEGORY' }), data.category)}
                    {auditInformationRow(
                        'USER',
                        translate({ id: 'CLIENT_USER' }),
                        data.userName || translate({ id: 'CLIENT_SYSTEM' }),
                    )}
                    {auditInformationRow('ACTION', translate({ id: 'CLIENT_ACTION' }), data.action)}
                    {auditInformationRow(
                        'TIME',
                        translate({ id: 'CLIENT_TIME' }),
                        <Typography display="inline">
                            {timestampToDateTime(data.createdOn, language)}
                        </Typography>,
                    )}
                    {auditInformationRow('OPERATION', translate({ id: 'CLIENT_OPERATION' }), data.message)}
                    <div className={classes.line} />
                    {auditInformationRow(
                        'USER_DATA',
                        translate({ id: 'CLIENT_USER' }),
                        userName || data.userName || translate({ id: 'CLIENT_SYSTEM' }),
                    )}
                    {adminName && auditInformationRow('ADMIN', translate({ id: 'CLIENT_ADMIN' }), adminName)}
                    {userNames && auditInformationRow('USERS', translate({ id: 'CLIENT_USERS' }), userNames)}
                    {tenantName && auditInformationRow('TENANT_NAME', translate({ id: 'CLIENT_TENANT' }), tenantName)}
                    {serviceName &&
            auditInformationRow('SERVICE_NAME', translate({ id: 'CLIENT_SERVICE_INSTANCE' }), serviceName)}
                    {licenseAllocationsAsText &&
            auditSplitInfoLine('LICENSE_ALLOCATIONS', translate({ id: 'CLIENT_USAGE' }), licenseAllocationsAsText)}
                    {rest &&
            Object.entries(rest).map(
                ([ key, value ]) =>
                    value &&
                isString(value) &&
                auditInformationRow(key, getAuditRowHeader(key), getAuditRowContent(value)),
            )}
                    {policyData &&
            auditInformationRow('POLICY_DATA', getAuditRowHeader('policyData'), JSON.stringify(policyData.data))}
                    {oldPolicyData &&
            auditInformationRow(
                'OLD_POLICY_DATA',
                getAuditRowHeader('oldPolicyData'),
                JSON.stringify(oldPolicyData.data),
            )}
                    {newPolicyData &&
            auditInformationRow(
                'NEW_POLICY_DATA',
                getAuditRowHeader('newPolicyData'),
                JSON.stringify(newPolicyData.data),
            )}
                    {users && <AuditDialogGridComponent
                        data={users}
                        headers={{
                            key: 'CLIENT_NAME',
                            value: 'CLIENT_EMAIL',
                        }} />}
                </>
            )}
        </UiDialog>
    );
};

export default AuditDialogComponent;
