import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { isHostModeSelector } from '../../store/selectors';
import { useIsSettingsRouteMatched } from '../../util/RouteUtil';
import useIsProfileRoute from './useIsProfileRoute';

const useProductName = () => {

    const isProfileRoute = useIsProfileRoute();
    const isHostMode = useSelector(isHostModeSelector);
    const isSettingsRoute = useIsSettingsRouteMatched();
    const isTenantCreateRoute = useRouteMatch(RouteNames.TenantCreate);

    const showTrademark = useMemo(
        () => !isProfileRoute && !isSettingsRoute && !isTenantCreateRoute && !process.buildConfigs.forceLogoTrademark,
        [ isProfileRoute, isSettingsRoute, isTenantCreateRoute ],
    );

    const productName = useMemo<string>(
        () => {
            if (isProfileRoute) {
                return '';
            }
            return (isHostMode && `${process.buildConfigs.productName} | Host`) ||
                ((isSettingsRoute || isTenantCreateRoute) && 'Administration') ||
                process.buildConfigs.productName;
        },
        [ isHostMode, isProfileRoute, isSettingsRoute, isTenantCreateRoute ],
    );

    return {
        productName,
        showTrademark,
    };
};

export default useProductName;
