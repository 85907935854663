import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import * as React from 'react';
import { useIntl } from 'react-intl';

import { NotificationSettingsMode } from './interfaces/notificationSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        selectButton: {
            paddingLeft: Tokens.Padding.PadXxl,
            fontSize: `${Tokens.FontFamily.FontLSize} !important`,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold} !important`,
        },
        inputBase: {
            marginRight: '8px',
            marginBottom: '18px',
            fontWeight: Tokens.FontFamily.FontWeightBold,
        },
        selectIcon: {
            color: theme.palette.semantic.colorIconDefault,
            verticalAlign: 'bottom',
        },
        menuItem: { fontSize: Tokens.FontFamily.FontMSize },
    }),
);
const NotificationSettingSelectionMenu: React.FC<{
    handleActionMenu: any;
}> = ({ handleActionMenu }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const EnableNotificationSettingsForAdmin = useFeatureFlagValue(
        Features.EnableNotificationSettingsForAdmin.name,
    );
    const modeResxMap = new Map<NotificationSettingsMode, string>([
        [ NotificationSettingsMode.MyNotification, 'CLIENT_NOTIFICATION_SETTING_MODE_MYNOTIFICATION' ],
        [ NotificationSettingsMode.ManageNotification, 'CLIENT_NOTIFICATION_SETTING_MODE_MANAGENOTIFICATION' ],
    ]);
    React.useEffect(() => {
        const isNSManageMode = sessionStorage.getItem('isNSManageMode');
        const arr = isNSManageMode === 'true' && EnableNotificationSettingsForAdmin ?
            translate({ id: modeResxMap.get(NotificationSettingsMode.ManageNotification) }) :
            translate({ id: modeResxMap.get(NotificationSettingsMode.MyNotification) });

        setSelected([ arr ]);
    }, [ EnableNotificationSettingsForAdmin, modeResxMap, translate ]);
    const handleClose = (manageMode: boolean) => (event: any) => {
        handleActionMenu(manageMode);
        sessionStorage.setItem('isNSManageMode', manageMode.toString());

    };
    const [ selected, setSelected ] = React.useState<string[]>([]);

    const change = React.useCallback(
        event => {
            const menuValue = event.target.value as string;
            setSelected([ menuValue ]);
        },
        []);
    return (
        <div>
            <Select
                value={selected}
                displayEmpty
                disableUnderline
                IconComponent={ExpandMoreIcon}
                classes={{
                    select: classes.selectButton,
                    icon: classes.selectIcon,
                }}
                className={classes.inputBase}
                onChange={change}
                data-cy="admin-select-menu"
                variant="standard"
            >
                {Object.values(NotificationSettingsMode)
                    .map((mode, cellIndex) => (
                        <MenuItem
                            key={mode}
                            onClick={handleClose(mode === NotificationSettingsMode.MyNotification ? false : true)}
                            value={mode}
                            className={classes.menuItem}
                            disableRipple>
                            {translate({ id: modeResxMap.get(mode) })}
                        </MenuItem>
                    ))}
            </Select>

        </div>
    );
};
export default NotificationSettingSelectionMenu;
