import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { PortalCard } from '@uipath/portal-shell-react';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import { useUserReadableTime } from '../../../util/DateUtil';
import type { IService } from '../interfaces/service';
import { TenantStatusConstants } from '../TenantConstants';
import { useTenantOperationTrackerContext } from '../TenantOperationTrackerContextProvider';

interface IServiceInstanceUrlProps {
    data: IService | ITenant;
    accountLogicalName: string;
}

export const ServiceInstanceUrlComponent: React.FC<IServiceInstanceUrlProps> = ({
    data,
    accountLogicalName,
}) => {
    const { userReadableTime } = useUserReadableTime();
    const { formatMessage: translate } = useIntl();
    const cardref = React.createRef<HTMLPortalCardElement>();
    const FIXED_CARD_WIDTH = '311px';
    const serviceName = data.name;
    const status = useMemo(() => (data as ITenant).status?.toUpperCase() ?? (data as IService).tenantStatus, [ data ]);
    const isInstanceAccessible = useMemo(() => status === TenantStatusConstants.ENABLED, [ status ]);
    const { checkOperationList } = useTenantOperationTrackerContext();

    const serviceLastPublishedText = `${translate({ id: 'CLIENT_LAST_UPDATED' })}: ${
        status === TenantStatusConstants.ENABLED
            ? userReadableTime(data.updatedOn)
            : translate({ id: 'CLIENT_UPDATING' })
    }`;

    const onTenantCardClicked = () => {
        portalTelemetry.trackTrace({
            message: '[IN-HP-03] - Homepage tenants card clicked',
            severityLevel: SeverityLevel.Information,
        });
        window.location.assign(`${window.location.origin}/${accountLogicalName}/${data.name}/orchestrator_`);
    };

    useEffect(() => {
        if (cardref.current) {
            cardref.current.addEventListener('cardClicked', onTenantCardClicked);
        }
    });

    return (
        <PortalCard
            data-cy="service-instance-url-card"
            ref={cardref}
            key={data.id}
            width={FIXED_CARD_WIDTH}
            cardId={`${data.id}`}
            header={serviceName}
            subtitle={serviceLastPublishedText}
            clickable={isInstanceAccessible && !checkOperationList(data.id.toString())}
            titleOverflow="1"
        />
    );
};
