import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import type { AuthConfiguration } from '@uipath/auth-react';

import { CISAuthority } from './AuthDomainResolver';

export const CISClientId = '6e80a74c-0878-4517-9b94-5283859df42f';

export const getAuthConfiguration: () => AuthConfiguration = () => {
    const EnableInsightsScope = getFeatureFlagValue(Features.EnableInsightsScope.name);
    const EnableStudioWebScope = getFeatureFlagValue(Features.EnableStudioWebScope.name);
    const EnableSRSScope = getFeatureFlagValue(Features.EnableSRSScope.name);
    const EnableSRSRealTimeEventsScope = getFeatureFlagValue(Features.EnableSRSRealTimeEventsCollection.name);
    const EnablePortalTagManagement = getFeatureFlagValue(Features.EnablePortalTagManagement.name);
    const EnableConnectionServiceUserScope = getFeatureFlagValue(Features.EnableConnectionServiceUserScope.name);
    const accountName = window.location.pathname.split('/')[1];
    const isValidAccountName = accountName && !accountName.includes('portal_');

    const scopes = [
        'openid',
        'profile',
        'email',
        'IdentityServerApi',
        'OrchestratorApiUserAccess',
        'JamJamApi',
        'ReferenceToken',
        'offline_access',
        ... EnableSRSScope ? [ 'SRS.Recommendations' ] : [],
        ... EnableSRSRealTimeEventsScope ? [ 'SRS.Events' ] : [],
        ... EnableInsightsScope ? [ 'Insights' ] : [],
        ... EnableStudioWebScope ? [ 'StudioWebBackend', 'StudioWebTypeCacheService' ] : [],
        ... EnablePortalTagManagement ? [ 'RCS.FolderAuthorization', 'RCS.TagsManagement' ] : [],
        ... EnableConnectionServiceUserScope ? [ 'ConnectionServiceUser' ] : [],
    ];

    const redirectUri = `${window.location.origin}/${isValidAccountName ? accountName + '/' : ''}portal_/loginsuccess`;

    return {
        authority: CISAuthority,
        client_id: CISClientId,
        redirect_uri: redirectUri,
        silent_redirect_uri: `${window.location.origin}/portal_/loginsuccess`,
        post_logout_redirect_uri: `${window.location.origin}/portal_/cloudrpa`,
        scope: scopes.join(' '),
        // To test PKCE, set accessTokenExpiringNotificationTime: 300
    };
};
