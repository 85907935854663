import { ContactUsLinks } from '@experiences/constants';
import type { ICurrentTrial } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import {
    getAllProductsConfigurations,
    useLocalizedLinks,
} from '@experiences/util';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { TelemetryEvents } from '../../../common/constants/TelemetryEvents';
import { subscriptionCodeToServiceName } from '../../../services/licensing/TrialPerSku';
import {
    accountType,
    EnableUserLicensingSelector,
    isHostModeSelector,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import {
    computeProductProperties,
    extractProducts,
} from '../../../util/LicenseUtil';
import { LicensingProductAllocations } from '../LicensingProductAllocations';
import { CurrentTrialActionButtons } from './CurrentTrialActionButtons.default';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '16px',
        },
        currentTrialService: { marginBottom: '40px' },
        trialServiceInfoBar: {
            display: 'flex',
            justifyContent: 'flexStart',
            alignItems: 'center',
        },
        serviceTitle: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        serviceExpiryDate: {
            display: 'flex',
            justifyContent: 'flexStart',
        },
        divider: {
            marginTop: '10px',
            marginBottom: '3px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
        },
        titleAndDate: { marginLeft: '12px' },
        contactSalesButton: {
            color: theme.palette.semantic.colorForegroundLink,
            cursor: 'pointer',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            '&:hover': { textDecoration: 'underline' },
            marginLeft: 'auto',
        },
    }),
}));

export const CurrentTrialsPanel: React.FC<{ currentTrials: ICurrentTrial[] }> = ({ currentTrials }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountTypeSelector = useSelector(accountType);
    const isHostMode = useSelector(isHostModeSelector);
    const getLocalizedLink = useLocalizedLinks();
    const userLicensingIsEnabled = useSelector(EnableUserLicensingSelector);
    const { logEvent } = useTelemetryHelper();

    return currentTrials && currentTrials.length > 0 ? (
        <>
            <Typography className={clsx(classes.title)}>
                {translate({ id: 'CLIENT_CURRENT_TRIALS' })}
            </Typography>
            {currentTrials.map((currentTrial, i) => (
                <div
                    key={i}
                    className={clsx(classes.currentTrialService)}
                    data-cy={`current-trial-${currentTrial.subscriptionCode.toLowerCase()}`}
                >
                    <div className={clsx(classes.trialServiceInfoBar)}>
                        <PortalCustomIcon
                            name={subscriptionCodeToServiceName[currentTrial.subscriptionCode]}
                            size="32px" />
                        <div className={clsx(classes.titleAndDate)}>
                            <Typography className={clsx(classes.serviceTitle)}>
                                {translate({ id: `CLIENT_CURRENT_TRIAL_SERVICE_HEADER_${currentTrial.subscriptionCode}` })}
                            </Typography>
                            <div className={clsx(classes.serviceExpiryDate)}>
                                <Typography>
                                    {translate({ id: `CLIENT_TRIAL_EXPIRY` })}
                                </Typography>
                                <div style={{ marginLeft: '8px' }}>
                                    <Typography data-cy="trial-expiry-date">
                                        <FormattedDate
                                            value={new Date(currentTrial.expiryDate * 1000)}
                                            year="numeric"
                                            month="short"
                                            day="numeric"
                                            timeZone="UTC"
                                        />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: 'auto' }}>
                            <a
                                className={clsx(classes.contactSalesButton)}
                                target="_blank"
                                rel="noreferrer"
                                href={getLocalizedLink(ContactUsLinks)}
                                data-cy={`learn-more-link-${currentTrial.subscriptionCode.toLowerCase()}`}
                                onClick={() => logEvent(TelemetryEvents.TrialService_ContactSales,
                                    { SubscriptionCode: currentTrial.subscriptionCode })}
                            >
                                {translate({ id: 'CLIENT_CONTACT_SALES' })}
                            </a>
                            <CurrentTrialActionButtons
                                currentTrial={currentTrial}
                                id={i}
                            />
                        </div>
                    </div>
                    <Divider className={clsx(classes.divider)} />
                    <LicensingProductAllocations
                        products={extractProducts(
                            getAllProductsConfigurations(userLicensingIsEnabled),
                            currentTrial.products,
                            false,
                            false,
                        ).map(p => computeProductProperties(p, isHostMode, undefined, accountTypeSelector))}
                        entitlementUsages={[]}
                        loading={false}
                    />
                </div>
            ))}
        </>
    ) : null;
};
