import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Fade,
    FormControlLabel,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
    useTheme,
} from '@mui/styles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        licenseToggle: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        licenseToggleText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        licenseToggleContainer: {
            display: 'flex',
            alignItems: 'left',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginLeft: '0.75em',
        },
    }),
);

const UiLicenseToggleComponent: React.FC<{
    name: string;
    toggleLabelValue: string;
    tooltipValue: string;
    switchName: string;
}> = ({
    name,
    toggleLabelValue,
    tooltipValue,
    switchName,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();

    const {
        control,
        setValue,
    } = useFormContext();

    const handleSwitch = useCallback(
        (switchName: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(switchName, checked);
            }
            callback(checked);
        },
        [ setValue ],
    );

    return (
        <div className={clsx(classes.licenseToggleContainer)}>
            <Controller
                name={name}
                control={control}
                render={props => (
                    <FormControlLabel
                        control={
                            <div>
                                <Switch
                                    checked={props.value}
                                    onChange={e => {
                                        handleSwitch(
                                            switchName, e.target.checked, props.onChange);
                                    }}
                                    data-cy="license-toggle"
                                />
                            </div>
                        }
                        label={
                            <div className={clsx(classes.licenseToggleText)}>
                                <Typography>
                                    {translate({ id: toggleLabelValue })}
                                </Typography>
                                <Tooltip
                                    data-cy="license-tooltip"
                                    TransitionComponent={Fade}
                                    TransitionProps={{ timeout: 600 }}
                                    title={translate(
                                        { id: tooltipValue },
                                    )}
                                    arrow
                                >
                                    <InfoOutlinedIcon
                                        style={{
                                            color: theme.palette.semantic.colorForegroundLight,
                                            paddingLeft: '3px',
                                            fontSize: 'x-large',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        }
                        labelPlacement='end'
                    />
                )}
            />
        </div>
    );
};

export default UiLicenseToggleComponent;
