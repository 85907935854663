import { accountGlobalId } from '@experiences/ecommerce';
import produce from 'immer';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import type { OmitResetState } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { AuthSettingKey } from '../../../common/constants/AuthSettingConstant';
import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import type { ISetting } from '../../../services/identity/SettingService';
import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { isHostModeSelector } from '../../../store/selectors';
import {
    mapAADConfigDataToExternalIdentityPayload,
    mapADConfigDataToExternalIdentityPayload,
    mapExternalIdentityPayloadToAADConfigData,
    mapExternalIdentityPayloadToADConfigData,
    mapExternalIdentityPayloadToErrorCode,
    mapExternalIdentityPayloadToGoogleConfigData,
    mapExternalIdentityPayloadToSamlConfigData,
    mapGoogleConfigDataToExternalIdentityPayload,
    mapSamlConfigDataToExternalIdentityPayload,
} from '../../../util/ExternalIdentityProviderUtil';
import {
    mapAuthSettingsRestrictionsDataToKeyValuePairs,
    mapSettingArrayToAuthSettingsRestrictionsData,
} from '../../../util/setting/AuthSettingUtil';

export interface IAuthSettingsData {
    enableBasicAuthentication?: boolean;
    enableBasicAuthenticationForHostTenant?: boolean;
    google?: boolean;
    aad?: boolean;
    saml2?: boolean;
    ad?: boolean;
}

export const initialData: IAuthSettingsData = {
    enableBasicAuthentication: true,
    enableBasicAuthenticationForHostTenant: true,
    google: false,
    aad: false,
    saml2: false,
    ad: false,
};

const useExternalIdentityProvidersForm = (reset: (values?: any, omitResetState?: OmitResetState) => void) => {
    const { formatMessage: translate } = useIntl();

    const createNotification = useUiSnackBar();

    const isHostMode = useSelector(isHostModeSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const [ loading, setLoading ] = useState(false);

    const keys = useMemo(
        () => [
            ...(isHostMode ? [ AuthSettingKey.EnableBasicAuthenticationForHostTenant ] : []),
            AuthSettingKey.RestrictBasicAuthentication,
        ],
        [ isHostMode ],
    );

    const {
        data: fetchedSettings, isValidating, mutate,
    } = useSWR<ISetting[]>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const fetchedExternalIdentityGoogle = useExternalIdentity(ExternalAuthenticationScheme.Google);
    const fetchedExternalIdentityAzureAD = useExternalIdentity(ExternalAuthenticationScheme.AzureAD);
    const fetchedExternalIdentitySaml = useExternalIdentity(ExternalAuthenticationScheme.Saml2);
    const fetchedExternalIdentityAD = useExternalIdentity([
        ExternalAuthenticationScheme.Windows,
        ExternalAuthenticationScheme.Negotiate,
    ]);

    useEffect(() => {
        if (fetchedSettings
                || fetchedExternalIdentityGoogle
                || fetchedExternalIdentityAzureAD
                || fetchedExternalIdentitySaml
                || fetchedExternalIdentityAD) {
            const settings = fetchedSettings ? mapSettingArrayToAuthSettingsRestrictionsData(fetchedSettings) : undefined;
            reset(produce(initialData, draftState => {
                if (settings) {
                    draftState.enableBasicAuthentication = !settings.restrictBasicAuthentication;
                    draftState.enableBasicAuthenticationForHostTenant = settings.enableBasicAuthenticationForHostTenant ?? true;
                }
                if (fetchedExternalIdentitySaml?.isActive) {
                    draftState.saml2 = fetchedExternalIdentitySaml.isActive;
                }
                if (fetchedExternalIdentityAD?.isActive) {
                    draftState.ad = fetchedExternalIdentityAD.isActive;
                }
                if (fetchedExternalIdentityGoogle?.isActive) {
                    draftState.google = fetchedExternalIdentityGoogle.isActive;
                }
                if (fetchedExternalIdentityAzureAD?.isActive) {
                    draftState.aad = fetchedExternalIdentityAzureAD.isActive;
                }
            }));
        }
    }, [
        fetchedExternalIdentityAD,
        fetchedExternalIdentityAzureAD,
        fetchedExternalIdentityGoogle,
        fetchedExternalIdentitySaml,
        fetchedSettings,
        reset,
    ]);

    const externalProviderAPI = useCallback(async (payload, fetchedExternalIdentity) => {
        const newExternalIdentityConfig = fetchedExternalIdentity
            ? await updateExternalIdentityProvider(payload)
            : await createExternalIdentityProvider(payload);

        mapExternalIdentityPayloadToErrorCode(newExternalIdentityConfig);
    }, []);

    const onSubmit = useCallback(
        async (data: IAuthSettingsData) => {
            try {
                setLoading(true);
                const authSettingsRestrictionsData = {
                    restrictBasicAuthentication: !data.enableBasicAuthentication,
                    enableBasicAuthenticationForHostTenant: data.enableBasicAuthenticationForHostTenant,
                };
                const settings = fetchedSettings ? mapSettingArrayToAuthSettingsRestrictionsData(fetchedSettings) : undefined;
                if (authSettingsRestrictionsData.restrictBasicAuthentication !== settings?.restrictBasicAuthentication
                    || authSettingsRestrictionsData.enableBasicAuthenticationForHostTenant
                        !== settings?.enableBasicAuthenticationForHostTenant) {
                    await saveSetting(settingUrl, {
                        settings: mapAuthSettingsRestrictionsDataToKeyValuePairs(authSettingsRestrictionsData),
                        partitionGlobalId,
                    });
                }

                if (fetchedExternalIdentityGoogle && data.google !== fetchedExternalIdentityGoogle.isActive) {
                    const googleConfigData = mapExternalIdentityPayloadToGoogleConfigData(fetchedExternalIdentityGoogle);
                    googleConfigData.isActive = data.google;
                    const payload = mapGoogleConfigDataToExternalIdentityPayload(googleConfigData, fetchedExternalIdentityGoogle);
                    await externalProviderAPI(payload, fetchedExternalIdentityGoogle);
                }
                if (fetchedExternalIdentityAzureAD && data.aad !== fetchedExternalIdentityAzureAD.isActive) {
                    const azureAdConfigData = mapExternalIdentityPayloadToAADConfigData(fetchedExternalIdentityAzureAD);
                    azureAdConfigData.isActive = data.aad;
                    const payload = mapAADConfigDataToExternalIdentityPayload(azureAdConfigData, fetchedExternalIdentityAzureAD);
                    await externalProviderAPI(payload, fetchedExternalIdentityAzureAD);
                }
                if (fetchedExternalIdentityAD && data.ad !== fetchedExternalIdentityAD.isActive) {
                    const adConfigData = mapExternalIdentityPayloadToADConfigData(fetchedExternalIdentityAD);
                    adConfigData.isActive = data.ad;
                    const payload = mapADConfigDataToExternalIdentityPayload(adConfigData, fetchedExternalIdentityAD);
                    await externalProviderAPI(payload, fetchedExternalIdentityAD);
                }
                if (fetchedExternalIdentitySaml && data.saml2 !== fetchedExternalIdentitySaml.isActive) {
                    const samlConfigData = mapExternalIdentityPayloadToSamlConfigData(fetchedExternalIdentitySaml);
                    samlConfigData.isActive = data.saml2;
                    const payload = mapSamlConfigDataToExternalIdentityPayload(samlConfigData, fetchedExternalIdentitySaml);
                    await externalProviderAPI(payload, fetchedExternalIdentitySaml);
                }
                reset(data);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
                await mutate();
                setLoading(false);
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            } finally {
                setLoading(false);
            }
        }, [
            createNotification,
            externalProviderAPI,
            fetchedExternalIdentityAD,
            fetchedExternalIdentityAzureAD,
            fetchedExternalIdentityGoogle,
            fetchedExternalIdentitySaml,
            fetchedSettings,
            mutate,
            partitionGlobalId,
            reset,
            translate,
        ],
    );

    return {
        onSubmit,
        loading: loading || isValidating,
    };
};

export default useExternalIdentityProvidersForm;
