import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { getActivationErrorMapping } from '../../../common/constants/LicenseActivationErrorMapping';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        activationErrorMessage: { maxWidth: '417px' },
        closeButton: { marginLeft: '8px' },
        buttonContainer: {
            display: 'flex',
            marginTop: '32px',
            justifyContent: 'flex-end',
        },
        buttonText: { color: theme.palette.semantic.colorBackgroundRaised },
    }),
}));

const LicenseActivationErrorDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, operationStatus,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <>
            <Typography
                className={classes.activationErrorMessage}
                data-cy="activation-error-message">
                {translate({ id: getActivationErrorMapping(operationStatus) })}
            </Typography>
            <div className={classes.buttonContainer}>
                <Button
                    key="contactSupportButton"
                    variant="contained"
                    data-cy="contact-support-button"
                    onClick={() => closeDialog()}
                >
                    <a
                        className={clsx(classes.buttonText, classes.a)}
                        target="_blank"
                        color="white"
                        rel="noreferrer"
                        href="https://www.uipath.com/company/contact-us/licensing-queries-activations"
                    >
                        {translate({ id: 'CLIENT_CONTACT_SUPPORT' })}
                    </a>
                </Button>
                <Button
                    className={classes.closeButton}
                    key="closeButton"
                    variant="outlined"
                    data-cy="close-button"
                    onClick={() => closeDialog()}
                >
                    {translate({ id: 'CLIENT_CLOSE' })}
                </Button>
            </div>
        </>
    );
};

export default LicenseActivationErrorDialogBody;
