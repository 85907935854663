import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../../common/hooks/useOrganizationName';
import { accountGlobalId } from '../../../../store/selectors';
import UiForm from '../../../common/UiForm';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../../organizationsettings/AdminBreadCrumbs';
import useAddEditTenantTagsLabelsViewModel from './AddEditTenantTagsLabelsViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        input: { marginBottom: '26px' },
    }),
);

const AddEditTenantTagsLabelsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const organizationName = useOrganizationName();
    const organizationId = useSelector(accountGlobalId);

    const {
        type,
        tenantId,
        tenant,
        cancel,
        loading,
        methods: {
            watch,
            register,
            formState: {
                errors, isSubmitting, isDirty,
            },
            handleSubmit,
        },
    } = useAddEditTenantTagsLabelsViewModel();

    const { name } = useMemo(() => watch(), [ watch ]);

    const breadCrumbLinks = useMemo(() =>
        process.buildConfigs.showForMSI
            ? [
                {
                    link: RouteNames.OrganizationAdminHome,
                    name: organizationName,
                },
                {
                    link: RouteNames.TenantTagsLabelsMsi,
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    link: RouteNames.TenantTagsLabelsMsi,
                    name: translate({ id: 'CLIENT_LABELS' }),
                },
                {
                    link: RouteNames.TenantTagsLabelsAddEditMsi,
                    name: type === 'add'
                        ? translate({ id: 'CLIENT_ADD_LABEL' })
                        : translate({ id: 'CLIENT_EDIT_LABEL' }, { label: name }),
                },
            ]
            : [
                {
                    link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                    name: tenant?.name ?? '',
                },
                {
                    link: RouteNames.TenantTagsLabels.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    link: RouteNames.TenantTagsLabels.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_LABELS' }),
                },
                {
                    link: RouteNames.TenantTagsLabelsAddEdit.replace(':tenantId', tenantId),
                    name: type === 'add'
                        ? translate({ id: 'CLIENT_ADD_LABEL' })
                        : translate({ id: 'CLIENT_EDIT_LABEL' }, { label: name }),
                },
            ],
    [ name, organizationName, tenant?.name, tenantId, translate, type ]);

    return (
        <UiPageContainer
            header={AdminBreadCrumbs(breadCrumbLinks)}
            maxWidth='900px'
            loading={loading}
        >
            <UiForm
                onSubmit={handleSubmit}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => cancel()}
                            color="primary"
                            data-cy="add-edit-tenant-tag-label-cancel"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            variant="contained"
                            data-cy="tenant-tag-label-add-edit-button"
                            disabled={!isDirty || Object.keys(errors).length > 0}
                        >
                            {translate({ id: type === 'add' ? 'CLIENT_ADD' : 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                centerChild
                dataCy="tenant-settings-component"
            >
                <TextField
                    inputRef={register({
                        required: translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_NAME' }) },
                        ),
                        validate: v => !!v.trim(),
                    })}
                    className={classes.input}
                    helperText={errors?.name?.message}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_NAME' })}
                    required
                    error={!!errors.name}
                    name="name"
                    variant="outlined"
                    label={translate({ id: 'CLIENT_NAME' })}
                    fullWidth
                    data-cy="tenant-tags-label-name"
                    inputProps={{ 'aria-label': translate({ id: 'CLIENT_NAME' }) }}
                />
                <TextField
                    inputRef={register()}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_DESCRIPTION_OPTIONAL' })}
                    multiline
                    rows={3}
                    error={!!errors.description}
                    name="description"
                    variant="outlined"
                    label={translate({ id: 'CLIENT_DESCRIPTION' })}
                    fullWidth
                    data-cy="tenant-tags-label-description"
                    inputProps={{ 'aria-label': translate({ id: 'CLIENT_DESCRIPTION' }) }}
                />
            </UiForm>

        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <AddEditTenantTagsLabelsComponent />
        {renderRoutes(route.routes)}
    </>
);
