import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import React, {
    useCallback,
    useState,
} from 'react';

import { CarouselButton } from './CarouselButton';
import { CarouselCloseButton } from './CarouselCloseButton';
import { useStyles } from './CarouselComponent';
import CarouselSlideBottomLeftImageStyle from './CarouselSlideBottomLeftImageStyle';
import CarouselSlideBottomRightImageStyle from './CarouselSlideBottomRightImageStyle';
import CarouselSlideFullLeftImageStyle from './CarouselSlideFullLeftImageStyle';
import CarouselSlideFullRightImageStyle from './CarouselSlideFullRightImageStyle';
import type { CarouselSlideParameters } from './types/CarouselSlideParameters';

interface CarouselInnerProps {
    slides: CarouselSlideParameters[];
    setOpen: Function;
}

export const CarouselWrapper = ({
    slides, setOpen,
}: CarouselInnerProps) => {
    const [ slideState, setSlide ] = useState({ currentSlide: 0 });
    const classes = useStyles();
    const EnableCarouselFullHeightTemplates = useFeatureFlagValue(Features.EnableCarouselFullHeightTemplates.name);

    const getSlideStyle = useCallback((slide: CarouselSlideParameters, idx: number) => {
        switch (slide.backgroundImagePosition) {
            case 'Bottom Left':
                return <CarouselSlideBottomLeftImageStyle
                    {...slide}
                    setOpen={setOpen}
                    idx={idx}
                    currSlide={slideState.currentSlide} />;
            case 'Full Left':
                // If flag not enabled, use default
                if (EnableCarouselFullHeightTemplates) {
                    return <CarouselSlideFullLeftImageStyle
                        {...slide}
                        setOpen={setOpen}
                        idx={idx}
                        currSlide={slideState.currentSlide} />;
                }
                // eslint-disable-next-line no-fallthrough
            case 'Full Right':
                // If flag not enabled, use default
                if (EnableCarouselFullHeightTemplates) {
                    return <CarouselSlideFullRightImageStyle
                        {...slide}
                        setOpen={setOpen}
                        idx={idx}
                        currSlide={slideState.currentSlide} />;
                }
                // eslint-disable-next-line no-fallthrough
            default:
                return <CarouselSlideBottomRightImageStyle
                    {...slide}
                    setOpen={setOpen}
                    idx={idx}
                    currSlide={slideState.currentSlide} />;
        }
    }, [ EnableCarouselFullHeightTemplates, setOpen, slideState.currentSlide ]);

    return (
        <div
            data-cy="carousel-inner"
            className={classes.carouselInner}>
            <div className={classes.carouselSlidesBackground}>
                <div
                    className={classes.carouselSlideDisplay}
                    style={{
                        transform: 'translateX(-' +
                            String(slideState.currentSlide * 101) +
                            '%)',
                    }}
                >
                    {slides.map((slide, idx) => (
                        <div
                            key={idx}
                            className={classes.carouselSlideBackground}
                        >
                            <CarouselCloseButton
                                fullHeight={slide.backgroundImagePosition === 'Full Left' || slide.backgroundImagePosition === 'Full Right'}
                                {...slide}
                                idx={idx}
                                currSlide={slideState.currentSlide}
                                setOpen={setOpen} />
                            {getSlideStyle(slide, idx)}
                        </div>
                    ))}
                </div>
            </div>
            <div className={classes.carouselButtonDisplay}>
                {slides.map((_slide, idx) => (
                    <CarouselButton
                        slideId={_slide.id}
                        idx={idx}
                        currentSlide={slideState.currentSlide}
                        setSlide={setSlide}
                        key={idx.toString()} />
                ))}
            </div>
        </div>
    );
};
