import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useMemo } from 'react';

// This is the fixed list of services that should be hidden from the FE
const hiddenServices = new Set([
    'bupproxyservice',
    'hypervisor',
    'elements',
    'provisioning',
    'connectorbuilder',
    'resourcecatalog',
    'messagebus',
    'insightslogex',
    'aimetering',
    'asyncbus',
    'ocr',
    'aistorage',
    'automationsolutions',
    'llmgateway',
    'sapproxy',
]);

export const useServiceDependency = () => {
    const ShowServerllessControlPlane = useFeatureFlagValue(Features.ShowServerllessControlPlane.name);
    const EnableHideIntegrationService = useFeatureFlagValue(Features.EnableHideIntegrationService.name);

    // Use this to add any services that can be toggled to be hidden on the FE
    const servicesToHide = useMemo(() => {
        if (!ShowServerllessControlPlane) {
            hiddenServices.add('serverlesscontrolplane');
        }

        if (process.buildConfigs.hideServiceDU) {
            hiddenServices.add('du');
        }

        if (EnableHideIntegrationService) {
            hiddenServices.add('connections');
        }

        return [ ...hiddenServices ];
    }, [ EnableHideIntegrationService, ShowServerllessControlPlane ]);

    return { servicesToHide };
};
