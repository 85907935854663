import type {
    IAddEditIPRestrictionStatus,
    IIPRestrictionStatus,
} from '../../common/interfaces/iprestriction';
import {
    axiosDelete,
    get,
    patch,
    post,
} from '../utility/Requests.default';
import { ipNetworkUrl } from './IPNetworkService';

export async function getIpConfigurationStatus(key: string, organizationId: string) {
    return await get<IIPRestrictionStatus>(`${ipNetworkUrl}/organization/${organizationId}/ipRangeStatus`);
}

export async function addIpConfigurationStatus(organizationId: string, payload: IAddEditIPRestrictionStatus) {
    return await post<IIPRestrictionStatus>(`${ipNetworkUrl}/organization/${organizationId}/ipRangeStatus`, { body: { ... payload } });
}

export async function updateIpConfigurationStatus(organizationId: string, payload: IAddEditIPRestrictionStatus) {
    return await patch<IAddEditIPRestrictionStatus>(`${ipNetworkUrl}/organization/${organizationId}/ipRangeStatus`, { body: { ... payload } });
}

export async function deleteIpConfigurationStatus(organizationId: string) {
    return await axiosDelete(`${ipNetworkUrl}/organization/${organizationId}/ipRangeStatus`);
}
