import {
    Menu,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type {
    ReactNode,
    RefObject,
} from 'react';
import React, { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import useUiNestedMenuItemViewModel from './UiNestedMenuItemViewModel';

export interface ISubMenuItemProps {
    children?: ReactNode;
    className?: any;
    disabled?: boolean;
    ref: RefObject<HTMLDivElement>;
    label: string;
    parentMenuOpen?: boolean;
    righticon?: any;
    leftAnchorMenus?: boolean;
    tabIndex?: number;
    menu?: ReactNode[];
    keepOpen?: boolean;
}

const useStyles = makeStyles((theme) => createStyles(
    { parentMenuItem: { '&:focus-visible': { backgroundColor: theme.palette.semantic.colorHover } } }
));

const UiNestedMenuItem = forwardRef<HTMLLIElement, ISubMenuItemProps>(function UiNestedMenuItem(props, ref) {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const {
        children,
        containerRef,
        menuContainerRef,
        menuItemRef,
        isSubMenuOpen,
        tabIndex,
        righticon,
        MenuItemProps,
        leftAnchorMenus,
        label,
        open,
        onClose,
        handleBlur,
        handleFocus,
        handleKeyDown,
        handleMouseEnter,
        handleMouseLeave,
    } = useUiNestedMenuItemViewModel(props, ref);

    return (
        <>
            {isSubMenuOpen && <div
                role="region"
                aria-live="polite"
                style={{
                    position: 'absolute',
                    height: 0,
                    width: 0,
                    overflow: 'hidden',
                }}
                data-cy="aria-announce-region">
                {translate({ id: 'CLIENT_NESTED_MENU_ITEM_ANNOUNCE' }, { count: React.Children.count(children) })}
            </div>}
            <div
                {...props}
                className={classes.parentMenuItem}
                ref={containerRef as React.RefObject<HTMLDivElement>}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onKeyDown={handleKeyDown}
                tabIndex={tabIndex}
                data-cy='ui-nested-menu-item'
            >
                <MenuItem
                    {...MenuItemProps}
                    ref={menuItemRef}
                >
                    {label}
                    <div style={{ flexGrow: 1 }} />
                    {righticon}
                </MenuItem>
                <Menu
                    hideBackdrop
                    style={{ pointerEvents: 'none' }}
                    anchorEl={menuItemRef.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: leftAnchorMenus ? 'left' : 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: leftAnchorMenus ? 'right' : 'left',
                    }}
                    open={!!open}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={false}
                    disableAutoFocus
                    disableEnforceFocus
                    onClose={onClose}
                >
                    <div
                        ref={menuContainerRef}
                        style={{ pointerEvents: 'auto' }}>
                        {children}
                    </div>
                </Menu>
            </div>
        </>

    );
});

export default UiNestedMenuItem;
