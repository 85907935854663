// all OMS regions
export enum Region {
    None = 'None',
    WestEurope = 'WestEurope',
    NorthEurope = 'NorthEurope',
    FranceCentral = 'FranceCentral',
    Europe = 'Europe',
    WestUs = 'WestUs',
    EastUs = 'EastUs',
    UnitedStates = 'UnitedStates',
    CanadaCentral = 'CanadaCentral',
    Canada = 'Canada',
    EastAsia = 'EastAsia',
    JapanEast = 'JapanEast',
    Japan = 'Japan',
    AustraliaEast = 'AustraliaEast',
    Australia = 'Australia',
    Singapore = 'Singapore',
    GxPUnitedStates = 'GxPUnitedStates',
    GovVirginia = 'GovVirginia',
}

export const RegionFriendlyNameMap: { [key in Region]: string } = {
    [Region.None]: 'None',
    [Region.WestEurope]: 'West Europe',
    [Region.NorthEurope]: 'North Europe',
    [Region.FranceCentral]: 'France Central',
    [Region.Europe]: 'Europe',
    [Region.WestUs]: 'West US',
    [Region.EastUs]: 'East US',
    [Region.UnitedStates]: 'United States',
    [Region.CanadaCentral]: 'Canada Central',
    [Region.Canada]: 'Canada',
    [Region.EastAsia]: 'East Asia',
    [Region.JapanEast]: 'Japan East',
    [Region.Japan]: 'Japan',
    [Region.AustraliaEast]: 'Australia East',
    [Region.Australia]: 'Australia',
    [Region.Singapore]: 'Singapore',
    [Region.GxPUnitedStates]: 'United States (Delayed Update)',
    [Region.GovVirginia]: 'Virginia',
};

export const TranslatedRegionName: { [key in Region]: string } = {
    [Region.None]: 'CLIENT_NONE',
    [Region.WestEurope]: 'CLIENT_WEST_EUROPE',
    [Region.NorthEurope]: 'CLIENT_NORTH_EUROPE',
    [Region.FranceCentral]: 'CLIENT_FRANCE_CENTRAL',
    [Region.Europe]: 'CLIENT_EUROPE',
    [Region.WestUs]: 'CLIENT_WEST_US',
    [Region.EastUs]: 'CLIENT_EAST_US',
    [Region.UnitedStates]: 'CLIENT_UNITED_STATES',
    [Region.CanadaCentral]: 'CLIENT_CANADA_CENTRAL',
    [Region.Canada]: 'CLIENT_CANADA',
    [Region.EastAsia]: 'CLIENT_EAST_ASIA',
    [Region.JapanEast]: 'CLIENT_JAPAN_EAST',
    [Region.Japan]: 'CLIENT_JAPAN',
    [Region.AustraliaEast]: 'CLIENT_AUSTRALIA_EAST',
    [Region.Australia]: 'CLIENT_AUSTRALIA',
    [Region.Singapore]: 'CLIENT_SINGAPORE',
    [Region.GxPUnitedStates]: 'CLIENT_UNITED_STATES_GXP',
    [Region.GovVirginia]: 'CLIENT_GOVVIRGINIA',
};

export function getRegionFromCountry(country: string) {
    switch (country) {
        case 'Japan':
            return Region.Japan;
        case 'United States':
        case 'Mexico':
            return Region.UnitedStates;
        case 'Canada':
            return Region.Canada;
        case 'New Zealand':
        case 'Australia':
            return Region.Australia;
        case 'GxPUnitedStates':
            return Region.GxPUnitedStates;
        case 'Europe':
            return Region.Europe;
        default:
            return Region.None;
    }
}
