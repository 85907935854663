import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {
    CircularProgress,
    Divider,
    IconButton,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import urljoin from 'url-join';

import { Saml2BindingType } from '../../../common/constants/ExternalIdentityProviderConstant';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import {
    BulkAuthenticationSettingKey,
    getTemplateData,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import validateCertificate from '../helpers/ValidateSAMLCert';

const useStyles = makeStyles(theme =>
    createStyles({
        subtitle: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            marginBottom: '8px',
        },
        copyText: { margin: '-12px 0px' },
        sectionTitle: {
            marginTop: '20px',
            marginBottom: '0px',
        },
        section: {
            display: 'flex',
            alignItems: 'flex-start',
        },
        field: {
            marginBottom: '16px',
            maxWidth: '420px',
        },
        fetchButton: {
            marginLeft: '20px',
            marginTop: '36px',
        },
        dividerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
        },
        divider: {
            flexGrow: 1,
            background: '#ddd',
        },
        centerText: {
            margin: '0px 8px',
            width: 'max-content',
            color: theme.palette.semantic.colorForegroundDisable,
        },
        formSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
        selectInput: {
            marginBottom: '12px',
            maxWidth: '420px',
        },
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
    }),
);

const SecuritySettingsSAMLGeneralForm: React.FC = () => {
    const classes = useStyles();

    const { formatMessage: translate } = useIntl();
    const {
        control, reset, watch, setError, formState, setValue,
    } = useFormContext<ISamlFormData>();
    const { errors } = formState;

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ metadataLoading, setMetadataLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ authEntityId, setAuthEntityId ] = useState<string>('');

    const EnableSamlHttpPost = useFeatureFlagValue(Features.EnableSamlHttpPost.name);

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const authenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML];

    const currentEntityId: string | undefined = watch('ServiceProviderEntityId');

    const createdOrgEntityId = useMemo(() => urljoin(
        window.location.origin,
        partitionGlobalId,
        process.buildConfigs.identityBaseRoute,
    ), [ partitionGlobalId ]);

    useEffect(() => {
        const authSettings = authenticationSetting?.externalIdentityProviderDto?.settings;
        const ServiceProviderEntityId: string | undefined = authSettings ? JSON.parse(authSettings).ServiceProviderEntityId : undefined;
        if (!ServiceProviderEntityId) {
            setValue('ServiceProviderEntityId', createdOrgEntityId);
        }
        setAuthEntityId(ServiceProviderEntityId?.includes(partitionGlobalId)
            ? ServiceProviderEntityId : createdOrgEntityId);
    }
    , [ authenticationSetting, createdOrgEntityId, partitionGlobalId, setValue ]);

    const entityIds = useMemo(() => ({
        [authEntityId]: 'CLIENT_SAML_ORG_ENTITY_ID',
        [urljoin(
            window.location.origin,
            process.buildConfigs.identityBaseRoute
        )]: 'CLIENT_SAML_GLOBAL_ENTITY_ID',
    }), [ authEntityId ]);

    const metadataUrls = useMemo(
        () => ({
            metadataUrl: urljoin(
                window.location.origin,
                process.buildConfigs.showMetadataLink ? partitionGlobalId : '',
                process.buildConfigs.identityBaseRoute,
                'Saml2',
            ),
            entityId: currentEntityId ?? '',
            assertionConsumerUrl: urljoin(
                window.location.origin,
                process.buildConfigs.showMetadataLink ? partitionGlobalId : '',
                process.buildConfigs.identityBaseRoute,
                '/Saml2/Acs',
                !process.buildConfigs.showMetadataLink ? `?PartitionId=${partitionGlobalId}` : '',
            ),
        }),
        [ currentEntityId, partitionGlobalId ],
    );

    const fetchData = useCallback(async () => {
        setMetadataLoading(true);
        setErrorMessage('');
        try {
            const fetchedMetadata = await getTemplateData({
                type: 'Saml2',
                displayName: 'Saml2 Provider',
                partitionGlobalId,
                settings: JSON.stringify({
                    ServiceProviderEntityId: metadataUrls.entityId,
                    IdentityProviderMetadataUrl: watch('IdentityProviderMetadataUrl'),
                }),
            });
            const settings: ISaml2ProviderSettings = JSON.parse(fetchedMetadata?.externalIdentityProviderDto?.settings ?? '');
            if (settings) {
                const formSettings = {
                    ...settings,
                    ServiceProviderEntityId: currentEntityId,
                } as any;
                if (formSettings.ProvisioningSetting) {
                    formSettings.ProvisioningSetting.AllowedDomains =
                        settings.ProvisioningSetting?.AllowedDomains?.join(', ') ||
                        watch('ProvisioningSetting.AllowedDomains') ||
                        '';
                    formSettings.ProvisioningSetting.AccountLinkConfirmation =
                        settings.ProvisioningSetting?.AccountLinkConfirmation ??
                        watch('ProvisioningSetting.AccountLinkConfirmation') ??
                        false;
                    const attributeMapper = watch('ProvisioningSetting.AttributeMapper') as any;
                    formSettings.ProvisioningSetting.AttributeMapper =
                        (settings.ProvisioningSetting?.AttributeMapper &&
                        Object.values(settings.ProvisioningSetting?.AttributeMapper).some(attribute => !!attribute) &&
                        settings.ProvisioningSetting?.AttributeMapper) ||
                        (attributeMapper && Object.values(attributeMapper).some(attribute => !!attribute) && attributeMapper);
                }
                if (
                    (!EnableSamlHttpPost && formSettings.Saml2BindingType === Saml2BindingType.HttpPost) ||
                    (formSettings.Saml2BindingType === Saml2BindingType.Artifact)
                ) {
                    formSettings.Saml2BindingType = Saml2BindingType.HttpRedirect;
                }
                formSettings.ServiceCertificateUsage =
                    settings.SigningCertificateLocation?.Usage || watch('ServiceCertificateUsage');
                reset(formSettings, {
                    isDirty: true,
                    dirtyFields: true,
                });
                if (!(await validateCertificate(formSettings.SigningCertificateLocation.CertificateText))) {
                    setError('SigningCertificateLocation.CertificateText', { type: 'invalid' });
                }
            }
        } catch (error) {
            setErrorMessage(translate({ id: 'CLIENT_FAILED_TO_FETCH_DATA' }));
        }
        setMetadataLoading(false);
    }, [ partitionGlobalId, metadataUrls.entityId, watch, currentEntityId, EnableSamlHttpPost, reset, setError, translate ]);

    if (!bulkAuthenticationSetting) {
        return <CircularProgress
            size={24}
            className={classes.buttonProgress}
            aria-label="ui-progress-button-loading"
            data-cy="ui-progress-button-loading" /> ;
    }

    return (
        <>
            <Typography className={classes.subtitle}>
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_SSO_DATA_TO_BE_CONFIGURED' })}
            </Typography>
            <UiSelect
                control={control}
                className={classes.selectInput}
                name="ServiceProviderEntityId"
                inputLabel={
                    <div className={classes.fieldLabel}>
                        {translate({ id: 'CLIENT_ENTITY_ID_SELECT' })}
                        <Tooltip
                            title={translate({ id: 'CLIENT_ENTITY_ID_HELPER' })}>
                            <PortalCustomIcon
                                name='info'
                                size='15px' />
                        </Tooltip>
                    </div>
                }
                error={!!errors.ServiceProviderEntityId}
                options={entityIds}
                required
                fullWidth
                dataCy="configure-saml2-entity-id"
            />
            {process.buildConfigs.showMetadataLink && <Typography className={classes.copyText}>
                <strong>
                    {`${translate({ id: 'CLIENT_METADATA_URL' })}: `}
                </strong>
                {metadataUrls.metadataUrl}
                <CopyToClipboard text={metadataUrls.metadataUrl}>
                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                        <IconButton>
                            <FileCopyOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            </Typography>}
            <Typography className={classes.copyText}>
                <strong>
                    {`${translate({ id: 'CLIENT_ASSERTION_CONSUMER_SERVICE_URL' })}: `}
                </strong>
                {metadataUrls.assertionConsumerUrl}
                <CopyToClipboard text={metadataUrls.assertionConsumerUrl}>
                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                        <IconButton>
                            <FileCopyOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            </Typography>
            <Typography className={classes.copyText}>
                <strong>
                    {`${translate({ id: 'CLIENT_ENTITY_ID' })}: `}
                </strong>
                {metadataUrls.entityId}
                <CopyToClipboard text={metadataUrls.entityId}>
                    <Tooltip title={translate({ id: 'CLIENT_COPY' })}>
                        <IconButton>
                            <FileCopyOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </CopyToClipboard>
            </Typography>
            <Typography className={clsx(classes.subtitle, classes.sectionTitle)}>
                {translate({ id: 'CLIENT_DATA_FROM_IDP' })}
            </Typography>
            <div className={classes.section}>
                <Controller
                    as={TextField}
                    control={control}
                    label={translate({ id: 'CLIENT_METADATA_URL' })}
                    InputLabelProps={{ id: 'metadataUrlLabel' }}
                    inputProps={{ 'aria-labelledby': 'metadataUrlLabel' }}
                    placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                    variant="outlined"
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                    className={classes.field}
                    error={!!errorMessage}
                    helperText={errorMessage || undefined}
                    data-cy="metadata-url-field"
                    name="IdentityProviderMetadataUrl"
                    disabled={metadataLoading}
                />
                <UiProgressButton
                    variant="outlined"
                    onClick={fetchData}
                    className={classes.fetchButton}
                    data-cy="metadata-url-fetch-now-button"
                    loading={metadataLoading}
                >
                    {translate({ id: 'CLIENT_FETCH_DATA' })}
                </UiProgressButton>
            </div>
            <div className={clsx(classes.dividerContainer, classes.field)}>
                <Divider className={classes.divider} />
                <Typography className={classes.centerText}>
                    {translate({ id: 'CLIENT_OR_ENTER_DATA_MANUALLY' })}
                </Typography>
                <Divider className={classes.divider} />
            </div>

            <div className={classes.formSection}>
                <Controller
                    as={TextField}
                    control={control}
                    rules={{
                        required: true,
                        validate: p => !!p.trim(),
                    }}
                    required
                    label={translate({ id: 'CLIENT_SIGN_ON_URL' })}
                    InputLabelProps={{ id: 'signOnUrlLabel' }}
                    inputProps={{ 'aria-labelledby': 'signOnUrlLabel' }}
                    placeholder={translate({ id: 'CLIENT_ENTER_URL_HERE' })}
                    variant="outlined"
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                    className={classes.field}
                    error={!!errors.SingleSignOnServiceUrl}
                    helperText={
                        errors.SingleSignOnServiceUrl?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    name="SingleSignOnServiceUrl"
                    data-cy="single-sign-on-service-url"
                    disabled={metadataLoading}
                />
                <Controller
                    as={TextField}
                    control={control}
                    rules={{
                        required: true,
                        validate: p => !!p.trim(),
                    }}
                    required
                    label={translate({ id: 'CLIENT_IDENTITY_PROVIDER_ENTITY_ID' })}
                    InputLabelProps={{ id: 'identityProviderEntityLabel' }}
                    inputProps={{ 'aria-labelledby': 'identityProviderEntityLabel' }}
                    placeholder={translate({ id: 'CLIENT_ENTER_ID_HERE' })}
                    variant="outlined"
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                    className={classes.field}
                    error={!!errors.IdentityProviderEntityId}
                    helperText={
                        errors.IdentityProviderEntityId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    }
                    name="IdentityProviderEntityId"
                    data-cy="identity-provider-entity-id-field"
                    disabled={metadataLoading}
                />
                <Controller
                    name="SigningCertificateLocation.CertificateText"
                    control={control}
                    rules={{
                        required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                        validate: { invalid: async p => (!!p.trim() && await validateCertificate(p.trim())) },
                    }}
                    required
                    render={({
                        value, onChange,
                    }) =>
                        <TextField
                            required
                            error={!!errors.SigningCertificateLocation?.CertificateText}
                            fullWidth
                            className={classes.field}
                            InputProps={{ className: 'Tall' }}
                            inputProps={{ 'aria-labelledby': 'signingCertificateThumbprintLabel' }}
                            helperText={
                                (
                                    (errors.SigningCertificateLocation?.CertificateText?.type === 'required'
                                        && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                                    || (errors.SigningCertificateLocation?.CertificateText?.type === 'invalid'
                                        && translate({ id: 'CLIENT_SIGNING_CERTIFICATE_INVALID' }))
                                )
                            }
                            label={
                                <div className={classes.fieldLabel}>
                                    {translate({ id: 'CLIENT_SIGNING_CERTIFICATE_THUMBPRINT' })}
                                    <Tooltip
                                        title={translate({ id: 'CLIENT_PASTE_THE_CERTIFICATE' })}>
                                        <PortalCustomIcon
                                            name='info'
                                            size='15px' />
                                    </Tooltip>
                                </div>
                            }
                            placeholder={translate({ id: 'CLIENT_PASTE_THE_CERTIFICATE' })}
                            multiline
                            rows={8}
                            data-cy="signing-certificate-field"
                            disabled={metadataLoading}
                            onChange={onChange}
                            value={value}
                            variant="outlined"
                        />}
                />
            </div>
        </>
    );
};

export default SecuritySettingsSAMLGeneralForm;
