import type { Region } from '@experiences/constants';
import { AccountLicense } from '@experiences/constants';
import { useCentralErrorSetter } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { portalTelemetry } from '@experiences/telemetry';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import AddIcon from '@mui/icons-material/Add';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ToggleOffOutlinedIcon from '@mui/icons-material/ToggleOffOutlined';
import ToggleOnOutlinedIcon from '@mui/icons-material/ToggleOnOutlined';
import {
    Chip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import type { Row } from 'react-table';

import { ReactComponent as UpgradeBlueIcon } from '../../../src/images/upgradeBlue.svg';
import useCheckAuthTypes from '../../auth/hooks/CheckAuthType';
import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { getFriendlyName } from '../../common/constants/ServicesMapping';
import useApiAccessInstanceModal from '../../common/hooks/useApiAccessInstanceModal';
import { useEnabledTenantOperations } from '../../common/hooks/useEnabledTenantOperations';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useLicensingLimitation } from '../../common/hooks/useLicensingLimitation';
import { useTenantOperations } from '../../common/hooks/useTenantOperations';
import { ServiceErrorCode } from '../../common/interfaces/error';
import type {
    ITenant,
    ITenantService,
} from '../../common/interfaces/tenant/tenant';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import type { IServiceError } from '../../services/organization/TenantService';
import {
    accountLogicalName,
    accountType,
    isAdminSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { UiGrid } from '../common/UiGrid';
import type {
    IAction,
    IActionHeader,
} from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import {
    navigateToLicenseConfigure,
    navigateToLicenseView,
} from './subcomponents/helpers/ManageLicensesHelper';
import {
    isUserManagementEnabled,
    navigateToExternalServiceManageUsers,
} from './subcomponents/helpers/ManageUserHelper';
import {
    getListOfDependencies,
    getListOfHiddenDependencies,
    getListOfParents,
} from './subcomponents/helpers/ServiceDependencyGraph';
import { useServiceDependency } from './subcomponents/helpers/useServiceDependency';
import ServiceDeleteDialogBody from './subcomponents/ServiceDeleteDialogBody';
import ServiceRoutingComponent from './subcomponents/ServiceRoutingComponent';
import TenantDeleteDialogBody from './subcomponents/TenantDeleteDialogBody';
import { TenantStatusComponent } from './subcomponents/TenantStatusComponent';
import TenantStatusDialogBody from './subcomponents/TenantStatusDialogBody';
import { TenantStatusConstants } from './TenantConstants';
import { useTenantOperationTrackerContext } from './TenantOperationTrackerContextProvider';
import { useTenantsContext } from './TenantsContextProvider';

export interface IServiceRegionMap {
    [key: string]: Region;
}

const useStyles = makeStyles(theme =>
    createStyles({
        tableActionButton: {
            color: theme.palette.semantic.colorPrimary,
            fontSize: '14px',
        },
        needMoreTenantButton: { marginLeft: '5px' },
        addTenantButton: { marginLeft: '5px' },
        iconAdjustments: {
            width: '50px',
            height: '50px',
        },
        chip: {
            fontSize: '12px',
            fontWeight: 600,
            backgroundColor: `${theme.palette.semantic.colorInfoBackground} !important`,
        },
        migrationLabel: { color: `${theme.palette.semantic.colorBackgroundGrayEmp}` },
    }),
);

const TenantsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();
    const EnableNeedMoreTenantsButton = useFeatureFlagValue(Features.EnableNeedMoreTenantsButton.name);
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableAdminInsightsExport = useFeatureFlagValue(Features.EnableAdminInsightsExport.name);
    const EnableOrchestratorDelete = useFeatureFlagValue(Features.EnableOrchestratorDelete.name);
    const { checkTokenTypeIsAuth0 } = useCheckAuthTypes();

    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const createDialog = useShowDialog();
    const { enqueueSnackbar } = useSnackbar();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();

    const tenantOperations = useTenantOperations();

    const {
        ApiAccessInstanceModalComponent, setCurrentTenant,
    } = useApiAccessInstanceModal();

    const logicalName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const currentAccountType = useSelector(accountType);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const getRoute = useRouteResolver();

    const {
        data, error, isValidating, changeServiceStatus,
    } = useTenantsContext();

    const { isLicenseExpired } = useLicensingLimitation();
    const { servicesToHide } = useServiceDependency();

    // Set-up state hooks
    const {
        canAddTenant, canEnableTenant,
    } = useEnabledTenantOperations(data, isAdmin);
    const setErrorMessage = useCentralErrorSetter();

    const isTenantCreationDisabled = useMemo(() => !canAddTenant || isValidating, [ canAddTenant, isValidating ]);
    const addTenantTooltip = useMemo(
        () => (!canAddTenant || isValidating ? translate({ id: 'CLIENT_MAX_SERVICE_INSTANCE_COUNT_REACHED' }) : ''),
        [ canAddTenant, translate, isValidating ],
    );

    const showBanner = useMemo(() => process.buildConfigs.enableLicenseBanner && isUnlicensedMode, [ isUnlicensedMode ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.INPROGRESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    useEffect(() => {
        refreshAfterComplete();
    }, [ refreshAfterComplete ]);

    const isServiceLinkStatusValid = useCallback(
        (status: string) => status?.toUpperCase() === TenantStatusConstants.ENABLED,
        [],
    );

    const getOrchestratorServiceRow = useCallback(
        (row?: Row<ITenantService>) => row?.subRows?.find(service => service.original.serviceType === 'orchestrator'),
        [],
    );

    const getOrchestratorServiceRowOrFirst = useCallback(
        (row?: Row<ITenantService>) => getOrchestratorServiceRow(row) ?? row?.subRows?.[0],
        [ getOrchestratorServiceRow ],
    );

    const hideActionIconForMainRow = useCallback((row?: Row<ITenantService>) => !row?.original, []);

    const hideDeleteForOrchestrator = useCallback((row?: Row<ITenantService>) =>
        row?.original?.serviceType === 'orchestrator' && !EnableOrchestratorDelete, [ EnableOrchestratorDelete ]);

    const disableActionIconForMainRow = useCallback(
        (row?: Row<ITenantService>) => {
            const service = getOrchestratorServiceRowOrFirst(row);
            return service?.original?.tenant?.status.toUpperCase() !== TenantStatusConstants.ENABLED;
        },
        [ getOrchestratorServiceRowOrFirst ],
    );

    const disableActionIconForMainRowWhenNotDisabled = useCallback(
        (row?: Row<ITenantService>) => {
            const service = getOrchestratorServiceRowOrFirst(row);
            return service?.original?.tenant?.status.toUpperCase() !== TenantStatusConstants.DISABLED;
        },
        [ getOrchestratorServiceRowOrFirst ],
    );

    const hideActionIconForSubRows = useCallback(
        (row?: Row<ITenantService>) => !!row?.original || !getOrchestratorServiceRowOrFirst(row),
        [ getOrchestratorServiceRowOrFirst ],
    );

    const invisibleManageUsers = useCallback(
        (row?: Row<ITenantService>) => !isUserManagementEnabled(row?.original?.serviceType),
        [],
    );

    const hideExportLogs = useCallback((row?: Row<ITenantService>) => ![ 'orchestrator' ].concat(EnableAdminInsightsExport
        ? [ 'insights' ]
        : [],
    ).includes(row?.original?.serviceType ?? ''), [ EnableAdminInsightsExport ]);

    const hideActionIconForNonOrchestratorRow = useCallback((row?: Row<ITenantService>) => row?.original?.serviceType !== 'orchestrator', []);

    const tenantForServiceIsEnabled = useCallback((row?: Row<ITenantService>) => row?.original.tenant.status.toUpperCase() === TenantStatusConstants.ENABLED, []);

    const clickedViewLicense = useCallback(
        (row: Row<ITenantService>) => {
            const service = getOrchestratorServiceRowOrFirst(row);
            if (service) {
                navigateToLicenseView(
                    `${getRoute(RouteNames.PortalPrefix)}/service/${service.original.tenant!.id}/usage`,
                    service.original.tenant!.name,
                );
            }
        },
        [ getOrchestratorServiceRowOrFirst, getRoute ],
    );

    const clickedConfigureLicense = useCallback(
        (row: Row<ITenantService>) => {
            const firstService = getOrchestratorServiceRowOrFirst(row);
            if (firstService) {
                navigateToLicenseConfigure(
                    `${getRoute(RouteNames.Services)}/configure/${firstService.original.tenant!.id.toLowerCase()}`,
                    getRoute(RouteNames.Services),
                );
            }
        },
        [ getOrchestratorServiceRowOrFirst, getRoute ],
    );

    const clickedManageUsers = useCallback(
        (row: Row<ITenantService>) =>
            navigateToExternalServiceManageUsers(row?.original?.serviceType, row?.original?.tenant.name, logicalName),
        [ logicalName ],
    );

    const disableServiceIcons = useCallback((row?: Row<ITenantService>) => (
        row?.original?.tenant.status.toUpperCase() !== TenantStatusConstants.ENABLED ||
      row?.original?.status?.toUpperCase() !== TenantStatusConstants.ENABLED
    ), []);

    const clickedEditServices = useCallback(
        (row: Row<ITenantService>) => {
            const firstService = getOrchestratorServiceRowOrFirst(row);
            if (firstService) {
                history.push(`${getRoute(RouteNames.Services)}/edit/${firstService.original.tenant.id}`);
            }
        },
        [ getOrchestratorServiceRowOrFirst, getRoute, history ],
    );

    const clickedEnableTenant = useCallback(
        async (row: Row<ITenantService>) => {
            const orchestratorService = getOrchestratorServiceRowOrFirst(row);
            await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' }),
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: orchestratorService!.original.tenant,
                    status: TenantStatusConstants.ENABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                },
            });
        },
        [ createDialog, getOrchestratorServiceRowOrFirst, tenantOperations.tenantStatusModify, translate ],
    );

    const clickedDisableTenant = useCallback(
        async (row: Row<ITenantService>) => {
            const orchestratorService = getOrchestratorServiceRowOrFirst(row);
            await createDialog({
                title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER' }),
                customDialogContent: TenantStatusDialogBody,
                customDialogContentProps: {
                    tenant: orchestratorService!.original.tenant,
                    status: TenantStatusConstants.DISABLE,
                    tenantStatusModify: tenantOperations.tenantStatusModify,
                },
            });
        },
        [ getOrchestratorServiceRowOrFirst, createDialog, translate, tenantOperations.tenantStatusModify ],
    );

    const showServiceLicenseError = useCallback(
        async (results: IServiceError[] | undefined, tenant: ITenant) => {
            if (!isArray(results)) {
                return [];
            }

            const licenseFailures = results?.filter(service => service.ErrorCode === ServiceErrorCode.InsufficientLicense);
            if (licenseFailures?.length) {
                const licenseFailureServiceString = licenseFailures
                    .map(service => getFriendlyName(service.ServiceType))
                    .join(', ');

                const proceed = await createDialog({
                    title: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_HEADER' }),
                    body: translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE_DIALOG_BODY' }, { services: licenseFailureServiceString }),
                    icon: 'info',
                    primaryButtonText: translate({ id: 'CLIENT_ALLOCATE_LICENSES' }),
                    showCancel: true,
                });

                if (proceed) {
                    navigateToLicenseConfigure(
                        `${getRoute(RouteNames.Services)}/configure/${tenant.id.toLowerCase()}`,
                        getRoute(RouteNames.Services),
                    );
                }
            }

            return licenseFailures;
        },
        [ createDialog, getRoute, translate ],
    );

    const showServiceGenericError = useCallback(
        async (results: IServiceError[] | undefined, status: string) => {
            if (!isArray(results)) {
                return [];
            }

            const genericFailures = results?.filter(service => service.ErrorCode !== ServiceErrorCode.InsufficientLicense);
            if (genericFailures?.length) {
                const genericFailureServiceString = genericFailures
                    .map(service => getFriendlyName(service.ServiceType))
                    .join(', ');

                await createDialog({
                    title: translate({ id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_HEADER' }),
                    body: translate(
                        { id: 'CLIENT_GENERIC_TENANT_FAILURE_DIALOG_BODY' },
                        {
                            status,
                            services: genericFailureServiceString,
                        },
                    ),
                    icon: 'info',
                    showCancel: true,
                });
            }

            return genericFailures;
        },
        [ createDialog, translate ],
    );

    const clickedEnableService = useCallback(
        async (row: Row<ITenantService>) => {
            try {
                const parentDependencyList = getListOfDependencies(row.original.serviceType);
                const implicitServicesList = getListOfHiddenDependencies(row.original.serviceType);
                const disabledParents = parentDependencyList.filter(dep => {
                    const depService = row.original.tenant.tenantServiceInstances.find(service => service.serviceType === dep);
                    return depService?.status?.toUpperCase() !== TenantStatusConstants.ENABLED;
                });
                const implicitServices = implicitServicesList.filter(dep => {
                    const depService = row.original.tenant.tenantServiceInstances.find(service => service.serviceType === dep);
                    return depService?.status?.toUpperCase() !== TenantStatusConstants.ENABLED;
                });
                const serviceStatusMap = { [row.original.serviceType]: TenantStatusConstants.ENABLED };
                implicitServices.forEach(implicit => (serviceStatusMap[implicit] = TenantStatusConstants.ENABLED));
                if (disabledParents.length > 0) {
                    const parentString = disabledParents.map(parent => getFriendlyName(parent)).join(', ');
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_ENABLE_SERVICE' }, { service: getFriendlyName(row.original.serviceType) }),
                        body: (
                            <>
                                <Typography>
                                    {translate({ id: 'CLIENT_ENABLE_SERVICE_BODY1' }, { parents: parentString })}
                                </Typography>
                                <Typography style={{ marginTop: '12px' }}>
                                    {translate({ id: 'CLIENT_ENABLE_SERVICE_BODY2' }, { parents: parentString })}
                                </Typography>
                            </>
                        ),
                        icon: 'info',
                        primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
                        showCancel: true,
                    });

                    if (!proceed) {
                        return;
                    }

                    disabledParents.forEach(parent => (serviceStatusMap[parent] = TenantStatusConstants.ENABLED));
                }
                await changeServiceStatus(row.original.tenant, serviceStatusMap);

                createNotification(
                    translate(
                        { id: 'CLIENT_ENABLE_SERVICE_NOTIFICATION' },
                        { service: getFriendlyName(row.original.serviceType) },
                    ),
                    notificationType.SUCCESS,
                );
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                const licenseFailures = await showServiceLicenseError(results, row.original.tenant);
                if (licenseFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            } finally {
                refreshAfterComplete();
            }
        },
        [
            changeServiceStatus,
            createDialog,
            createNotification,
            refreshAfterComplete,
            setErrorMessage,
            showServiceGenericError,
            showServiceLicenseError,
            translate,
        ],
    );

    const clickedDeleteService = useCallback(
        async (row: Row<ITenantService>) => {
            try {
                await createDialog({
                    title: translate({ id: 'CLIENT_DELETE_SERVICE' }, { 0: getFriendlyName(row.original.serviceType) }),
                    customDialogContent: ServiceDeleteDialogBody,
                    customDialogContentProps: {
                        tenant: row?.original.tenant,
                        service: row?.original.serviceType,
                        tenantEdit: tenantOperations.tenantEdit,
                    },
                    icon: 'error',
                });
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            } finally {
                refreshAfterComplete();
            }
        },
        [ createDialog, refreshAfterComplete, setErrorMessage, showServiceGenericError, tenantOperations.tenantEdit, translate ],
    );

    const clickedDisableService = useCallback(
        async (row: Row<ITenantService>) => {
            try {
                const childDependencyList = getListOfParents(row.original.serviceType);
                const enabledChildren = childDependencyList.filter(dep => {
                    const depService = row.original.tenant.tenantServiceInstances.find(service => service.serviceType === dep);
                    return depService?.status?.toUpperCase() === TenantStatusConstants.ENABLED;
                });
                const serviceStatusMap = { [row.original.serviceType]: TenantStatusConstants.DISABLED };
                if (enabledChildren.length > 0) {
                    const childenString = enabledChildren
                        .filter(child => !servicesToHide.includes(child))
                        .map(child => getFriendlyName(child))
                        .join(', ');
                    const proceed = childenString
                        ? await createDialog({
                            title: translate(
                                { id: 'CLIENT_DISABLE_SERVICE' },
                                { service: getFriendlyName(row.original.serviceType) },
                            ),
                            body: (
                                <Typography>
                                    {translate(
                                        { id: 'CLIENT_DISABLE_SERVICE_BODY' },
                                        {
                                            service: getFriendlyName(row.original.serviceType),
                                            services: childenString,
                                        },
                                    )}
                                </Typography>
                            ),
                            icon: 'info',
                            primaryButtonText: translate({ id: 'CLIENT_DISABLE' }),
                            showCancel: true,
                        })
                        : true;

                    if (!proceed) {
                        return;
                    }

                    enabledChildren.forEach(child => (serviceStatusMap[child] = TenantStatusConstants.DISABLED));
                }
                await changeServiceStatus(row.original.tenant, serviceStatusMap);

                createNotification(
                    translate(
                        { id: 'CLIENT_DISABLE_SERVICE_NOTIFICATION' },
                        { service: getFriendlyName(row.original.serviceType) },
                    ),
                    notificationType.SUCCESS,
                );
            } catch (error) {
                const results: IServiceError[] = error as any;
                const genericFailures = await showServiceGenericError(results, TenantStatusConstants.ENABLE);
                if (genericFailures?.length) {
                    return;
                }

                setErrorMessage(error);
            } finally {
                refreshAfterComplete();
            }
        },
        [
            changeServiceStatus,
            createDialog,
            createNotification,
            refreshAfterComplete,
            servicesToHide,
            setErrorMessage,
            showServiceGenericError,
            translate,
        ],
    );

    const clickedDelete = useCallback(
        async (row: Row<ITenantService>) => {
            if (row?.subRows.length) {
                // look for orchestrator service first, else use first service found
                const service = getOrchestratorServiceRowOrFirst(row);

                await createDialog({
                    title: translate({ id: 'CLIENT_DELETE_TENANT' }),
                    customDialogContent: TenantDeleteDialogBody,
                    customDialogContentProps: {
                        tenant: service?.original.tenant,
                        tenantDelete: tenantOperations.tenantDelete,
                    },
                    icon: 'error',
                });
            }
        },
        [ createDialog, getOrchestratorServiceRowOrFirst, tenantOperations.tenantDelete, translate ],
    );

    const clickedConfigureRobotLogs = useCallback(
        (row?: Row<ITenantService>) => {
            if (EnableAdminInsightsExport && row?.original?.serviceType === 'insights') {
                logEvent('AdminInsightsExport.Click');
            }

            const {
                tenant, serviceType,
            } = row?.original ?? {};

            history.push({
                pathname: `${getRoute(RouteNames.Services)}/logsexport/${row?.original?.tenant.id.toLowerCase()}`,
                search: `?tenantName=${tenant?.name}&serviceType=${serviceType}`,
            });
        },
        [ getRoute, history, EnableAdminInsightsExport, logEvent ],
    );

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const actionList: IActionHeader[] = [];

        const needMoreTenant: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_NEED_MORE_TENANTS' }),
            icon: <UpgradeBlueIcon />,
            click: () => {
                portalTelemetry.trackTrace({
                    message: `Clicked on need more tenant button to open request enterprise trial form with account name ${logicalName}`,
                    severityLevel: SeverityLevel.Information,
                }, { revampEnabled: isAdminRevampEnabled });
                history.push(getRoute(RouteNames.TenantLicensingUpgradeToEnterprise));
            },
            invisible: !(
                isAdmin &&
        EnableNeedMoreTenantsButton &&
        AccountLicense[currentAccountType] === AccountLicense.COMMUNITY &&
        isTenantCreationDisabled
            ),
            variant: 'text',
            className: clsx(classes.tableActionButton, classes.needMoreTenantButton),
            dataCy: 'need-more-tenant-button',
        };

        const addTenant: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_TENANT' }),
            icon: <AddIcon />,
            click: () => history.push(`${getRoute(RouteNames.Services)}/add`),
            disable: isTenantCreationDisabled,
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.addTenantButton,
            toolTip: addTenantTooltip,
            dataCy: 'ui-grid-primary-action-button',
        };
        actionList.push(needMoreTenant, addTenant);
        return actionList;
    }, [
        translate,
        isAdmin,
        EnableNeedMoreTenantsButton,
        currentAccountType,
        isTenantCreationDisabled,
        classes.tableActionButton,
        classes.needMoreTenantButton,
        classes.addTenantButton,
        addTenantTooltip,
        logicalName,
        isAdminRevampEnabled,
        history,
        getRoute,
    ]);

    const actions: Array<IAction<ITenantService>> = useMemo(() => {
        const actionList: Array<IAction<ITenantService>> = [];

        const viewLicenseAllocation: IAction<ITenantService> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_VIEW_LICENSE_ALLOCATION' }),
            tooltip: translate({ id: 'CLIENT_VIEW_LICENSE_ALLOCATION' }),
            actionType: GridActionType.MainRow,
            icon: <AssessmentOutlinedIcon />,
            click: clickedViewLicense,
            disable: disableActionIconForMainRow,
            invisible: hideActionIconForSubRows,
            dataCy: 'ui-grid-view-license',
        };

        const editLicenseAllocation: IAction<ITenantService> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
            tooltip: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
            actionType: GridActionType.MainRow,
            icon: <AllocateLicenseIcon />,
            click: clickedConfigureLicense,
            disable: row => disableActionIconForMainRow(row) || isLicenseExpired,
            invisible: hideActionIconForSubRows,
            dataCy: 'ui-grid-explicit-license',
        };

        const enableTenant: IAction<ITenantService> = {
            type: ButtonType.ButtonWithIcon,
            tooltip: row => {
                const service = getOrchestratorServiceRowOrFirst(row);
                return !canEnableTenant && service?.original?.tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
                    ? translate({ id: 'CLIENT_MAX_ENABLED_SERVICE_INSTANCE_COUNT_REACHED' })
                    : translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' });
            },
            label: translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' }),
            actionType: GridActionType.MainRow,
            icon: <ToggleOnOutlinedIcon />,
            click: clickedEnableTenant,
            disable: row => disableActionIconForMainRowWhenNotDisabled(row) || !canEnableTenant,
            invisible: row => {
                const service = getOrchestratorServiceRowOrFirst(row);
                return (
                    hideActionIconForSubRows(row) ||
          service?.original?.tenant.status.toUpperCase() === TenantStatusConstants.ENABLED
                );
            },
            dataCy: 'ui-grid-enable-tenant',
        };

        const disableTenant: IAction<ITenantService> = {
            type: ButtonType.ButtonWithIcon,
            tooltip: row => {
                const service = getOrchestratorServiceRowOrFirst(row);
                return !canEnableTenant && service?.original?.tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
                    ? translate({ id: 'CLIENT_MAX_ENABLED_SERVICE_INSTANCE_COUNT_REACHED' })
                    : translate({ id: 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER' });
            },
            label: translate({ id: 'CLIENT_TENANT_CONFIRMATION_DISABLE_HEADER' }),
            actionType: GridActionType.MainRow,
            icon: <ToggleOffOutlinedIcon />,
            click: clickedDisableTenant,
            disable: row => disableActionIconForMainRow(row),
            invisible: row => {
                const service = getOrchestratorServiceRowOrFirst(row);
                return (
                    hideActionIconForSubRows(row) ||
          service?.original?.tenant.status.toUpperCase() !== TenantStatusConstants.ENABLED
                );
            },
            dataCy: 'ui-grid-disable-tenant',
        };

        const enableService: IAction<ITenantService> = {
            type: ButtonType.Icon,
            tooltip: row => tenantForServiceIsEnabled(row)
                ? translate({ id: 'CLIENT_ENABLE' })
                : translate({ id: 'CLIENT_DISABLED_TENANT_SERVICE_TOOLTIP' }),
            label: translate({ id: 'CLIENT_ENABLE' }),
            actionType: GridActionType.MainRow,
            icon: <ToggleOffOutlinedIcon />,
            click: clickedEnableService,
            disable: row => row?.original?.tenant.status?.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: row =>
                hideActionIconForMainRow(row) || row?.original?.status?.toUpperCase() === TenantStatusConstants.ENABLED,
            dataCy: 'ui-grid-enable-service-button',
        };

        const deleteService: IAction<ITenantService> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_DELETE' }),
            tooltip: translate({ id: 'CLIENT_DELETE' }),
            actionType: GridActionType.MainRow,
            icon: <DeleteOutlineIcon />,
            click: clickedDeleteService,
            disable: row => row?.original?.tenant.status?.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: row => hideDeleteForOrchestrator(row),
            dataCy: 'ui-grid-delete-service-button',
        };

        const disableService: IAction<ITenantService> = {
            type: ButtonType.Icon,
            tooltip: translate({ id: 'CLIENT_DISABLE' }),
            label: translate({ id: 'CLIENT_DISABLE' }),
            actionType: GridActionType.MainRow,
            icon: <ToggleOnOutlinedIcon />,
            click: clickedDisableService,
            disable: row => row?.original?.tenant.status?.toUpperCase() !== TenantStatusConstants.ENABLED,
            invisible: row =>
                hideActionIconForMainRow(row) || row?.original?.status?.toUpperCase() !== TenantStatusConstants.ENABLED,
            dataCy: 'ui-grid-disable-service-button',
        };

        const manageUsers: IAction<ITenantService> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_MANAGE_USERS' }),
            tooltip: translate({ id: 'CLIENT_MANAGE_USERS' }),
            actionType: GridActionType.SubRow,
            click: clickedManageUsers,
            icon: <PeopleAltOutlinedIcon />,
            disable: disableServiceIcons,
            invisible: invisibleManageUsers,
            dataCy: row => `manage-users-button-${row?.original?.serviceType}`,
        };

        const editTenant: IAction<ITenantService> = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_TENANT_SETTINGS' }),
            tooltip: translate({ id: 'CLIENT_TENANT_SETTINGS' }),
            actionType: GridActionType.MainRow,
            icon: <EditIcon />,
            click: clickedEditServices,
            disable: disableActionIconForMainRow,
            invisible: hideActionIconForSubRows,
            dataCy: 'ui-grid-edit-services-button',
        };

        const deleteTenant: IAction<ITenantService> = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_DELETE' }),
            actionType: GridActionType.MainRow,
            icon: <DeleteForeverOutlinedIcon />,
            click: clickedDelete,
            disable: row => {
                const service = getOrchestratorServiceRowOrFirst(row);
                return service?.original?.tenant?.status?.toUpperCase() === TenantStatusConstants.DELETION_INPROGRESS;
            },
            invisible: row => !!row?.original,
            dataCy: 'ui-grid-delete-button',
        };

        if (isAdmin) {
            if (isUnlicensedMode) {
                actionList.push(enableService, disableService, editTenant, enableTenant, disableTenant);
            } else {
                actionList.push(
                    viewLicenseAllocation,
                    editLicenseAllocation,
                    enableService,
                    disableService,
                    manageUsers,
                    editTenant,
                    enableTenant,
                    disableTenant,
                );
            }
            actionList.push(deleteTenant, deleteService);
        }

        if (checkTokenTypeIsAuth0 && !DisableFeatureFedRamp) {
            actionList.push({
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_API_ACCESS' }),
                tooltip: translate({ id: 'CLIENT_API_ACCESS' }),
                actionType: GridActionType.SubRow,
                icon: <CloudOutlinedIcon />,
                click: (row: Row<ITenantService>) => {
                    setCurrentTenant(row.original.tenant!.name);
                },
                disable: disableServiceIcons,
                invisible: hideActionIconForNonOrchestratorRow,
            });
        }

        if (
            process.buildConfigs.enableLogExport &&
      isAdmin &&
      !isUnlicensedMode &&
      AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY &&
      !DisableFeatureFedRamp
        ) {
            actionList.push({
                type: ButtonType.Icon,
                label: (row?: Row<ITenantService>) => translate({
                    id: row?.original?.serviceType === 'orchestrator'
                        ? 'CLIENT_LOG_EXPORT_CONFIGURATION'
                        : 'CLIENT_INSIGHTS_EXPORT_DATA',
                }),
                tooltip: (row?: Row<ITenantService>) => translate({
                    id: row?.original?.serviceType === 'orchestrator'
                        ? 'CLIENT_LOG_EXPORT_CONFIGURATION'
                        : 'CLIENT_INSIGHTS_EXPORT_DATA',
                }),
                actionType: GridActionType.SubRow,
                click: clickedConfigureRobotLogs,
                icon: <ImportExportIcon />,
                disable: disableServiceIcons,
                invisible: hideExportLogs,
                dataCy: 'ui-grid-export-log',
            });
        }

        return actionList;
    }, [
        translate,
        clickedViewLicense,
        disableActionIconForMainRow,
        hideActionIconForSubRows,
        clickedConfigureLicense,
        clickedEnableTenant,
        clickedDeleteService,
        clickedDisableTenant,
        clickedEnableService,
        clickedDisableService,
        clickedManageUsers,
        disableActionIconForMainRowWhenNotDisabled,
        disableServiceIcons,
        invisibleManageUsers,
        clickedEditServices,
        clickedDelete,
        isAdmin,
        DisableFeatureFedRamp,
        isUnlicensedMode,
        currentAccountType,
        isLicenseExpired,
        getOrchestratorServiceRowOrFirst,
        canEnableTenant,
        tenantForServiceIsEnabled,
        hideActionIconForMainRow,
        hideActionIconForNonOrchestratorRow,
        hideExportLogs,
        setCurrentTenant,
        clickedConfigureRobotLogs,
        checkTokenTypeIsAuth0,
    ]);

    return (
        <>
            <ApiAccessInstanceModalComponent />
            <UiPanel
                licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={<UiLicenseExpiredBanner />}
                trialPerSkuLicenseInGracePeriodBanners={<UiTrialPerSkuLicenseInGracePeriodBanners />}
                licenseOverAllocationBanner={<UiOverAllocationBanner />}
                header={{ title: translate({ id: 'CLIENT_PAGE_TENANTS' }) }}
                data-cy="services-panel"
            >
                {showBanner && (
                    <PortalAlertBar
                        status="info"
                        cancelable={false}
                        data-cy="tenant-license-info-banner">
                        {translate({ id: 'CLIENT_TENANTS_LICENSE_BANNER' })}
                    </PortalAlertBar>
                )}
                <UiGrid<ITenantService>
                    data-cy="services-ui-grid"
                    loading={isValidating}
                    search
                    searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                    groupBy={[ 'tenant.name' ]}
                    filters
                    pagination
                    refresh={refreshAfterComplete}
                    extraActionButtons={extraActionHeaderButtons}
                    columns={[
                        {
                            accessor: 'tenant.name',
                            Header: translate({ id: 'CLIENT_TENANT' }),
                            sortType: 'alphanumeric',
                            width: 20,
                        },
                        {
                            accessor: 'serviceType',
                            Header: translate({ id: 'CLIENT_SERVICE_INSTANCES' }),
                            Cell: ({ row }) =>
                                row.original?.tenant ? (
                                    <ServiceRoutingComponent
                                        tenantName={row.original.tenant.name}
                                        tenantId={row.original.tenant.id}
                                        serviceType={row.original.serviceType}
                                        isValid={isServiceLinkStatusValid(row.original.status)}
                                    />
                                ) : row?.subRows?.length && !row.isExpanded ? (
                                    <>
                                        {row?.subRows.map((service, serviceKey) => (
                                            <React.Fragment key={service.original.serviceType + '_' + serviceKey}>
                                                <ServiceRoutingComponent
                                                    tenantName={service.original.tenant.name}
                                                    tenantId={service.original.tenant.id}
                                                    serviceType={service.original.serviceType}
                                                    isValid={isServiceLinkStatusValid(service.original.status)}
                                                />
                                                {serviceKey !== row?.subRows.length - 1 && <Typography>
                                                    ,&nbsp;
                                                </Typography>}
                                            </React.Fragment>
                                        ))}
                                    </>
                                ) : null,
                            sortType: 'alphanumeric',
                            width: 60,
                        },
                        {
                            accessor: 'status',
                            Cell: ({ row }) => {
                                const orchestratorServiceOrFirstStatus = useMemo(
                                    () => getOrchestratorServiceRowOrFirst(row)?.original?.tenant?.status, [ row ]);
                                return !row?.original?.status ? (
                                    !row.isExpanded
                                    || orchestratorServiceOrFirstStatus?.toUpperCase() !== TenantStatusConstants.ENABLED
                                        ? (
                                            orchestratorServiceOrFirstStatus?.toUpperCase() === TenantStatusConstants.MAINTENANCE
                                                ? <TenantStatusComponent
                                                    data={orchestratorServiceOrFirstStatus ?? ''}
                                                    label={
                                                        <Typography
                                                            noWrap
                                                            className={classes.migrationLabel}>
                                                            {translate({ id: 'CLIENT_MIGRATION_RUNNING' })}
                                                        </Typography>
                                                    }
                                                    disableStatusIcon />
                                                : <TenantStatusComponent data={orchestratorServiceOrFirstStatus ?? ''} />
                                        )
                                        : null
                                ) : (
                                    row.original.tenant.status.toUpperCase() !== TenantStatusConstants.DISABLED
                                        && row.original.status.toUpperCase() !== TenantStatusConstants.ENABLED
                                        && (
                                            <TenantStatusComponent data={row.original.status} />
                                        )
                                );
                            },
                            disableSortBy: true,
                            width: 20,
                        },
                        {
                            accessor: 'canaryTenant',
                            Cell: ({ row }) => {
                                const orchestratorServiceOrFirst = getOrchestratorServiceRowOrFirst(row);
                                return orchestratorServiceOrFirst?.original.tenant.isCanaryTenant ? <Chip
                                    className={classes.chip}
                                    size="small"
                                    clickable={false}
                                    label={translate({ id: 'CLIENT_CANARY_TENANT' })} /> : <></>;
                            },
                            disableSortBy: true,
                            width: 20,
                        },
                    ]}
                    data={data}
                    error={error}
                    rowActions={actions}
                    autoResetExpanded={false}
                    getRowProps={row => {
                        const hasVisibleServices
                            = row?.subRows?.length > 0
                                && row.subRows[0].original?.tenant?.tenantServiceInstances?.filter(
                                    s => servicesToHide.indexOf(s.serviceType) < 0,
                                )?.length > 0;

                        return { hideExpand: !hasVisibleServices };
                    }}
                />
            </UiPanel>
        </>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <TenantsPageComponent />
        {renderRoutes(route.routes)}
    </>
);
