import moment from 'moment';

import { Duration } from '../interfaces/notifications';

export const getTimeTick = (filterValue: string) => {
    let showTillDate = 0;
    switch (filterValue) {
        case Duration.LastHour.toString():
            showTillDate = moment(Date.now())
                .subtract(Duration.LastHour, 'hours')
                .minute(0)
                .unix();
            break;
        case Duration.Last24Hour.toString():
            showTillDate = moment(Date.now())
                .subtract(Duration.Last24Hour, 'hours')
                .minute(0)
                .unix();
            break;
        case Duration.LastWeek.toString():
            showTillDate = moment(Date.now())
                .subtract(Duration.LastWeek, 'days')
                .minute(0)
                .unix();
            break;
        case Duration.LastMonth.toString():
            showTillDate = moment(Date.now())
                .subtract(Duration.LastMonth, 'days')
                .minute(0)
                .unix();
            break;
    }
    return showTillDate;
};
