import { Fade } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';

import { notificationType } from '../constants/Constant';

export const useUiSnackBar = () => {

    const { enqueueSnackbar } = useSnackbar();

    const createNotification = useCallback(
        (message: string, type = notificationType.INPROGRESS, anchor = 'center') => {
            enqueueSnackbar(message, {
                variant: type as any,
                anchorOrigin: {
                    horizontal: anchor,
                    vertical: 'top',
                },
                TransitionComponent: Fade,
            });
        },
        [ enqueueSnackbar ],
    );

    return createNotification;
};
