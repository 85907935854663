import { UiPathAuthProvider } from '@uipath/auth-react';
import type { FC } from 'react';
import React from 'react';
import { useHistory } from 'react-router';

import { getAuthConfiguration } from './configuration/AuthConfiguration.default';
import useAuthCallback from './hooks/Callback';

export const PortalAuthenticationProvider: FC = ({ children }) => {
    const history = useHistory();
    const onSigninCallback = useAuthCallback();

    return (
        <UiPathAuthProvider
            configuration={getAuthConfiguration()}
            onSigninCallback={onSigninCallback}
            onError={(error) => history.push('/portal_/autherror', { error })}
        >
            {children}
        </UiPathAuthProvider>
    );
};
