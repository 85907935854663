import xss from 'xss';

export interface IParamToValidValues {
    [key: string]: string[];
}

export default function convertParamMapToTranslationKeys(
    params: URLSearchParams,
    paramsForErrorCode?: IParamToValidValues[],
) {
    const validParams = paramsForErrorCode?.flatMap(p =>
        Object.entries(p).map(([ entry, validValues ]) => {
            const paramFromSearch = xss(params.get(entry) || '');
            return paramFromSearch && validValues.indexOf(paramFromSearch) > -1 ? `CLIENT_${paramFromSearch}` : ' ';
        }),
    );

    return validParams ? { ...validParams } : undefined;
}
