import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import CircleIcon from '@mui/icons-material/Circle';
import {
    Checkbox,
    FormControlLabel,
    IconButton,
    Link,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import {
    ApDataGrid,
    elementToNode,
    PortalTooltip,
} from '@uipath/portal-shell-react';
import type {
    DataManager,
    GridHeaderButtons,
    GridRowButtons,
    IColumn,
    IGridOptions,
    ISortModel,
} from '@uipath/portal-shell-types/components/angular-elements';
import moment from 'moment';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    getUserNotificationSubscription,
    UnsubscribeTenantNotificationSubscription,
    updateUserNotificationTopicSubscription,
    userNotificationSubscriptionUri,
} from '../../services/notification-preferences';
import type { ODataQueryOptions } from '../../services/notifications';
import {
    deleteNotification,
    getUserNotificationEntries,
    updateNotificationForUnread,
} from '../../services/notifications';
import { accountGlobalId } from '../../store/selectors';
import { useUserReadableTime } from '../../util/DateUtil';
import type {
    INotificationPublisherDto,
    IUpdateUserSubscriptionsRequestData,
} from '../notificationSettings/interfaces/notificationSettings';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import { getTimeTick } from './helper/NotificationPageHelper';
import type {
    IFilterDataType,
    IFilterQueryParamType,
    INotificationEntry,
    INotificationOdata,
    INotificationReadRequest,
} from './interfaces/notifications';
import {
    Duration,
    INotificationActionType,
    NotificationSeverity,
} from './interfaces/notifications';
import NotificationCellComponent from './NotificationCellComponent';
import { NotificationMoreRowActionsComponent } from './NotificationMoreRowActionsComponent';

const useStyles = makeStyles((theme) =>
    createStyles({
        tabsContainer: { marginBottom: Tokens.Padding.PadL },
        heading: {
            fontSize: Tokens.FontFamily.FontLSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            paddingBottom: Tokens.Padding.PadXxxl,
        },
        showOnlyUnread: {
            marginLeft: Tokens.Padding.PadM,
            fontSize: `${Tokens.FontFamily.FontMSize} !important`,
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold} !important`,
            color: `${theme.palette.semantic.colorForeground} !important`,
        },
        circleiconClass: {
            width: '.5em !important',
            height: '.5em !important',
            fontSize: '1rem !important',
            margin: '6px',
        },
        link: {
            alignSelf: 'flex-start',
            marginTop: '24px',
            fontSize: Tokens.FontFamily.FontMSize,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            lineHeight: '20px',
            cursor: 'pointer',
        },
    }),
);
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 0;

const NotificationPageComponent: React.FC = () => {

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const allFilterOtion = useMemo(() => ({
        label: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
        value: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
    }), [ translate ]);
    const enableNotificationTimeStampFilter = useFeatureFlagValue(
        Features.EnableNotificationTimeStampFilter.name,
    );
    const enableNotificationSeverityFilter = useFeatureFlagValue(
        Features.EnableNotificationSeverityFilter.name,
    );

    const partitionGlobalId = useSelector(accountGlobalId);
    const { userReadableTime } = useUserReadableTime();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const tenantName = localStorage.getItem('PORTAL_CURRENT_TENANT');
    const apGridRef = useRef<any>(null);
    const [ index, setPageIndex ] = useState(DEFAULT_PAGE_INDEX);
    const [ size, setPageSize ] = useState(DEFAULT_PAGE_SIZE);
    const [ dataMgr, setDataMgr ] = useState<DataManager<INotificationEntry>>();
    const [ sortOpts, setSortOpts ] = useState<Array<ISortModel<INotificationEntry>>>([
        {
            field: 'publishedOn',
            direction: 'desc',
            title: 'publishedOn',
        },
    ]);
    const [ loadingGrid, setloadingGrid ] = useState(false);
    const [ publisherTypes, setPublisherTypes ] = useState<IFilterDataType[]>([ ]);
    const [ selectedTopicFilter, setSelectedTopicFilter ] = useState<IFilterDataType>();
    const [ selectedPublisherFilter, setSelectedPublisherFilter ] = useState<IFilterDataType | undefined>(undefined);
    const [ selectedSeverityFilter, setSelectedSeverityFilter ] = useState<IFilterDataType | undefined>(undefined);
    const [ topicTypes, setTopicTypes ] = useState<IFilterDataType[]>([ ]);
    const [ selectedPublishedOnFilter, setSelectedPublishedOnFilter ] = useState<IFilterDataType>();
    const publishedOn = useMemo(() => ([
        {
            label: translate({ id: 'CLIENT_LAST_HOUR' }),
            value: Duration.LastHour.toString(),
        }, {
            label: translate({ id: 'CLIENT_TOTAL_RUN_TODAY' }),
            value: Duration.Last24Hour.toString(),
        }, {
            label: translate({ id: 'CLIENT_LAST_WEEK' }),
            value: Duration.LastWeek.toString(),
        }, {
            label: translate({ id: 'CLIENT_LAST_30_DAYS' }),
            value: Duration.LastMonth.toString(),
        },
    ]), [ translate ]);
    const severityTypes: IFilterDataType[] = [
        {
            label: translate({ id: 'SEVERITY_INFO' }),
            value: NotificationSeverity.Info,
        },
        {
            label: translate({ id: 'SEVERITY_SUCCESS' }),
            value: NotificationSeverity.Success,
        },
        {
            label: translate({ id: 'SEVERITY_WARN' }),
            value: NotificationSeverity.Warn,
        },
        {
            label: translate({ id: 'SEVERITY_ERROR' }),
            value: NotificationSeverity.Error,
        },
        {
            label: translate({ id: 'SEVERITY_FATAL' }),
            value: NotificationSeverity.Fatal,
        },
    ];
    const [ onlyUnread, setOnlyUnread ] = useState(false);
    const [ searchTermText, setSearchTerm ] = useState('');
    const defaultOptions: ODataQueryOptions = useMemo(() => ({
        top: DEFAULT_PAGE_SIZE,
        skip: 0,
        orderBy: [ 'publishedOn desc' ],
        count: true,
    }), []);
    const [ queryOptions, setQueryOptions ] = useState(defaultOptions);
    const [ filterQueryParamType, setFilterQueryParamType ] = useState<IFilterQueryParamType[]>([]);
    const createNotification = useUiSnackBar();

    const handleProfileError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`, notificationType.ERROR, 'right');
        },
        [ createNotification, translate ],
    );

    const {
        data: publishersData,
        isValidating: publisherLoading,
        error,
        mutate: mutate,
    } = useSWR(
        tenantId ? [ userNotificationSubscriptionUri, tenantId ] : null,
        getUserNotificationSubscription,
    );

    const {
        data, isValidating: notificationsLoading, mutate: notificationMutate,
    } = useSWR<INotificationOdata, Error>(
        [ partitionGlobalId, tenantId, queryOptions ],
        getUserNotificationEntries,
    );

    useEffect(() => {
        const services: IFilterDataType[] = [];
        const topics: IFilterDataType[] = [];
        if (publishersData !== undefined) {

            const publishers = publishersData.publishers as INotificationPublisherDto[];
            publishers.forEach((service) => {
                const pubtopics: IFilterDataType[] = [];
                const groupedPublisher: IFilterDataType = {
                    value: service.id,
                    label: service.displayName,
                };

                service.topics.forEach((topic) => {
                    const topicData: IFilterDataType = {
                        value: topic.id,
                        label: topic.displayName,
                    };
                    topics.push(topicData);
                    pubtopics.push(topicData);
                });
                groupedPublisher.childs = pubtopics;
                services.push(groupedPublisher);
            });
        }
        setPublisherTypes(services);
    }, [ enableNotificationTimeStampFilter, publishersData, translate ]);

    const getFilterQuery = useCallback((filterStores: IFilterQueryParamType[]) => {
        let filtersString = '';
        if (filterStores.length > 0) {
            filtersString = '(';
            filterStores.forEach((filterQuery) => {
                if (filterQuery.operator === 'contains') {
                    filtersString += 'contains(' + filterQuery.operand + ',\'' + filterQuery.compValue + '\') and ';
                } else {
                    filtersString += '(' + filterQuery.operand + ' ' + filterQuery.operator + ' ' + filterQuery.compValue + ') and ';
                }
            });
            filtersString = filtersString.substring(0, filtersString.length - 5);
            filtersString += ')';
        }
        return filtersString;
    }, []);

    const updateGridData = useCallback((pageIndex: number, pageSize: number, term: string) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        setSearchTerm(term);
        const filterStores = [ ...filterQueryParamType ];
        const itemIndex = filterStores.findIndex(x => x.operand === 'messageParam');
        if (itemIndex !== -1) {
            filterStores.splice(itemIndex, 1);
        }
        if (term !== '') {
            const filterData: IFilterQueryParamType = {
                compValue: term,
                operand: 'messageParam',
                operator: 'contains',
            };
            filterStores.push(filterData);
        }

        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            top: pageSize,
            skip: pageIndex * pageSize,
            orderBy: queryOptions.orderBy,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);
    }, [ filterQueryParamType, getFilterQuery, queryOptions ]);

    const handleHeaderCheckbox = useCallback((isChecked) => {
        setOnlyUnread(isChecked);
        const filterStores = [ ...filterQueryParamType ];
        if (isChecked) {
            const filterData: IFilterQueryParamType = {
                compValue: 'false',
                operand: 'isRead',
                operator: 'eq',
            };
            filterStores.push(filterData);
        } else {
            const isReadindex = filterStores.findIndex(x => x.operand === 'isRead');
            filterStores.splice(isReadindex, 1);
        }

        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);

        const queryCurrOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(queryCurrOptions);
    }, [ filterQueryParamType, getFilterQuery, queryOptions ]);

    const showResetToDefault = useMemo(() => filterQueryParamType.length > 0
        , [ filterQueryParamType.length ]);
    const ResetToDefault = useCallback(() => {
        setOnlyUnread(false);
        const filterStores: IFilterQueryParamType[] = [];

        setSearchTerm('');
        setSelectedPublisherFilter(allFilterOtion);
        setSelectedSeverityFilter(allFilterOtion);
        setSelectedPublishedOnFilter(allFilterOtion);
        setTopicTypes([]);
        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);

        setQueryOptions(defaultOptions);
    }, [ allFilterOtion, defaultOptions, getFilterQuery ]);

    const changeNotificationRead = useCallback(
        async (entry) => {
            if (!entry.row.isRead) {

                const originalRow = data?.value.find(x => x.id === entry.row.id);
                if (originalRow !== undefined) {
                    originalRow.isRead = true;
                }

                setloadingGrid(true);
                const request: INotificationReadRequest = {
                    notifications: [],
                    forceAllRead: false,
                };

                request.notifications = [
                    {
                        notificationId: entry.row.id,
                        read: true,
                    },
                ];
                await updateNotificationForUnread(request, tenantId, partitionGlobalId);
                setloadingGrid(false);
                dataMgr?.update(data?.value ?? null);
            }
            if (entry.row.redirectionUrl) {
                if (entry.event.ctrlKey) {
                    window.open(entry.row.redirectionUrl);
                } else {
                    window.open(entry.row.redirectionUrl, '_self');
                }
            }
        }, [ tenantId, data?.value, dataMgr, partitionGlobalId ]);

    const setPublisherFilterData = useCallback((publisherFilter: IFilterDataType | undefined) => {

        if (publisherFilter !== undefined) {
            setSelectedPublisherFilter({
                label: publisherFilter?.label,
                value: publisherFilter?.value,
            });
            setTopicTypes(publisherFilter.childs ?? []);
        } else {
            setSelectedPublisherFilter(allFilterOtion);
            setTopicTypes([]);
        }

        setSelectedTopicFilter(allFilterOtion);
        const filtersStore = [ ...filterQueryParamType ];
        const publisherIdIndex = filtersStore.findIndex(x => x.operand === 'publisherId');
        if (publisherIdIndex !== -1) {
            filtersStore.splice(publisherIdIndex, 1);
        }
        const topicIdIndex = filtersStore.findIndex(x => x.operand === 'topicId');
        if (topicIdIndex !== -1) {
            filtersStore.splice(topicIdIndex, 1);
        }
        if (publisherFilter !== undefined) {
            const filter = publisherFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: filter.value as string,
                    operand: 'publisherId', // prop,
                    operator: 'eq',
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }

        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOtion, filterQueryParamType, getFilterQuery, queryOptions ]);

    const setSeverityFilterData = useCallback((severityFilter: IFilterDataType | undefined) => {
        if (severityFilter !== undefined) {
            setSelectedSeverityFilter({
                label: severityFilter?.label,
                value: severityFilter?.value,
            });
        } else {
            setSelectedSeverityFilter(allFilterOtion);
        }

        const filtersStore = [ ...filterQueryParamType ];
        const severityIndex = filtersStore.findIndex(x => x.operand === 'category');
        if (severityIndex !== -1) {
            filtersStore.splice(severityIndex, 1);
        }

        if (severityFilter !== undefined) {
            const filter = severityFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: `'${filter.value}'`,
                    operand: 'category', // prop,
                    operator: 'eq',
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }
        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOtion, filterQueryParamType, getFilterQuery, queryOptions ]);

    const setTopicFilterData = useCallback((topicFilter: IFilterDataType | undefined) => {

        if (topicFilter !== undefined) {
            setSelectedTopicFilter({
                label: topicFilter?.label,
                value: topicFilter?.value,
            });
        } else {
            setSelectedTopicFilter(allFilterOtion);
        }
        const filtersStore = [ ...filterQueryParamType ];
        const topicIdIndex = filtersStore.findIndex(x => x.operand === 'topicId');
        if (topicIdIndex !== -1) {
            filtersStore.splice(topicIdIndex, 1);
        }

        if (topicFilter !== undefined) {
            const filter = topicFilter;
            if (filter.value !== '') {
                const filterData: IFilterQueryParamType = {
                    compValue: filter.value as string,
                    operand: 'topicId', // prop,
                    operator: 'eq',
                };
                filtersStore.push(filterData);
            }
        }
        setFilterQueryParamType(filtersStore);
        const filtersString = getFilterQuery(filtersStore);

        if (queryOptions.filter === filtersString) {
            return;
        }

        const changePageIndexOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(changePageIndexOptions);

    }, [ allFilterOtion, filterQueryParamType, getFilterQuery, queryOptions ]);

    const setPublishedFilterData = useCallback((publishedFilter: IFilterDataType | undefined) => {

        const filterStores = [ ...filterQueryParamType ];
        const itemIndex = filterStores.findIndex(x => x.operand === 'publishedOn');
        if (itemIndex !== -1) {
            filterStores.splice(itemIndex, 1);
        }
        if (publishedFilter !== undefined) {
            setSelectedPublishedOnFilter({
                label: publishedFilter?.label,
                value: publishedFilter?.value,
            });
            const filter = publishedFilter;
            if (filter.value !== '') {
                const showTillDate = getTimeTick(filter.value);
                const filterData: IFilterQueryParamType = {
                    compValue: showTillDate.toString(),
                    operand: 'publishedOn', // prop,
                    operator: 'ge',
                };
                filterStores.push(filterData);
            }
        } else {
            setSelectedPublishedOnFilter(allFilterOtion);
        }

        setFilterQueryParamType(filterStores);
        const filtersString = getFilterQuery(filterStores);

        const queryCurrOptions: ODataQueryOptions = {
            ...queryOptions,
            filter: filtersString,
        };
        setQueryOptions(queryCurrOptions);

    }, [ allFilterOtion, filterQueryParamType, getFilterQuery, queryOptions ]);

    const sortGridData = useCallback((sort: ISortModel<INotificationEntry>) => {
        if (!sort.field) {
            return ;
        }
        const oldOpt = sortOpts.find(opt => opt.field === sort.field);
        const sortingOldString = oldOpt ? `${oldOpt.field} ${oldOpt.direction}` : '';
        const noDirection = sort.direction === '' ? 'desc' : sort.direction;
        const sortingString = `${sort.field} ${noDirection}`;

        if (sortingOldString === sortingString) {
            return ;
        }
        const sortOptions: ODataQueryOptions = {
            ...queryOptions,
            orderBy: [ sortingString ],
        };

        setSortOpts([ sort ]);
        setQueryOptions(sortOptions);
        return true;
    }, [ queryOptions, sortOpts ]);

    const unreadMessageDecoration = useCallback((text: string, isRead: boolean, isMain: boolean, isSeverity: boolean) =>
        elementToNode(<ApolloThemeProvider>
            <IntlProvider>
                <NotificationCellComponent
                    isMain={isMain}
                    isRead={isRead}
                    text={text}
                    severity={isSeverity} />
            </IntlProvider>
        </ApolloThemeProvider>)
    , [ ]);

    const rowAction = useCallback(async (row: INotificationEntry, actionType: INotificationActionType, event: any) => {
        event.stopPropagation();
        event.preventDefault();
        switch (actionType) {
            case INotificationActionType.UnsubscribeTopic:
            {
                setloadingGrid(true);
                const topicState: IUpdateUserSubscriptionsRequestData = {
                    topicId: row.topicId,
                    isSubscribed: false,
                };
                try {
                    await updateUserNotificationTopicSubscription([ topicState ], tenantId);
                    setloadingGrid(false);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_MUTED' }, { 0: row.topicName }),
                        notificationType.SUCCESS, 'right');
                } catch (e) {
                    handleProfileError(e as Error);
                    setloadingGrid(false);
                }
                break;
            }
            case INotificationActionType.UnsubscribeTenant:
            {
                setloadingGrid(true);
                try {
                    await UnsubscribeTenantNotificationSubscription(tenantId);
                    setloadingGrid(false);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_MUTED' }, { 0: tenantName }),
                        notificationType.SUCCESS, 'right');
                } catch (e) {
                    handleProfileError(e as Error);
                    setloadingGrid(false);
                }
                break;
            }
            case INotificationActionType.Delete:
            {
                try {
                    setloadingGrid(true);
                    await deleteNotification(row.id, tenantId, partitionGlobalId);
                    createNotification(translate({ id: 'CLIENT_NOTIFICATION_CLEAR' }), notificationType.SUCCESS, 'right');
                    if (data !== undefined) {
                        data.value = data.value.filter(x => x.id !== row.id);
                        dataMgr?.update(data.value ?? null);
                    }
                    setloadingGrid(false);
                } catch (e) {
                    handleProfileError(e as Error);
                }
                break;
            }
            case INotificationActionType.ToggleRead:
                {
                    setloadingGrid(true);
                    const originalRow = data?.value.find(x => x.id === row.id);
                    if (originalRow !== undefined) {
                        originalRow.isRead = !row.isRead;
                    }

                    const request: INotificationReadRequest = {
                        notifications: [],
                        forceAllRead: false,
                    };

                    request.notifications = [
                        {
                            notificationId: row.id,
                            read: !row.isRead,
                        },
                    ];
                    await updateNotificationForUnread(request, tenantId, partitionGlobalId);
                    setloadingGrid(false);
                    dataMgr?.update(data?.value ?? null);
                }
                break;
        }

    }, [ createNotification, data, dataMgr, handleProfileError, partitionGlobalId, tenantId, tenantName, translate ]);

    const columnDef: Array<IColumn<INotificationEntry>> = useMemo(() => [
        {
            property: 'priority',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_SEVERITY' }),
            sortable: true,
            resizable: false,
            sort: sortOpts[0].field === 'priority' ? sortOpts[0].direction : '',
            width: 9,
            customCell: (entry) => {
                const isWarning = entry.category === NotificationSeverity.Warn;
                const isError = entry.category === NotificationSeverity.Error || entry.category === NotificationSeverity.Fatal;
                const isPriority = isWarning ? '!' :
                    isError ? '!!' : '';
                return unreadMessageDecoration(isPriority, false, isWarning, true);
            },
        },
        {
            property: 'publisherId',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_PUBLISHER_NAME' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedPublisherFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedPublisherFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                items: publisherTypes.map((valueType) => ({
                    value: valueType.value,
                    label: valueType.label,
                })),
                filterChange: (current) => {

                    const parentPublisher = publisherTypes.find(x => x.value === current?.value);
                    setPublisherFilterData(parentPublisher);
                },
            },
        },
        {
            property: 'publisherName',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_PUBLISHER_NAME' }),
            sortable: false,
            resizable: false,
            width: 10,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(entry.publisherName, entry.isRead, false, false);
            },
        },
        {
            property: 'topicId',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_NAME' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedTopicFilter?.value ?? '',
                    label: selectedTopicFilter?.label ?? '',
                },
                visible: topicTypes.length > 0,
                items:
                    topicTypes.map((valueType) => ({
                        value: valueType.value,
                        label: valueType.label,
                    })),
                filterChange: (current) => {
                    const parentTopic = topicTypes.find(x => x.value === current?.value);
                    setTopicFilterData(parentTopic);
                },
            },
        },
        {
            property: 'topicName',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TOPIC_NAME' }),
            sortable: false,
            resizable: false,
            width: 20,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(entry.topicName, entry.isRead, false, false);
            },
        },
        {
            property: 'priority',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_SEVERITY' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedSeverityFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedSeverityFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                visible: enableNotificationSeverityFilter,
                items: severityTypes,
                filterChange: (current) => {

                    const parentSeverity = severityTypes.find(x => x.value === current?.value);
                    setSeverityFilterData(parentSeverity);
                },
            },
        },
        {
            property: 'message',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_MESSAGE' }),
            sortable: false,
            resizable: false,
            searchable: true,
            width: 51,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(entry.message, entry.isRead, true, false);
            },
        },
        {
            property: 'publishedOn',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TIMESTAMP' }),
            sortable: true,
            sort: sortOpts[0].field === 'publishedOn' ? sortOpts[0].direction : '',
            width: 10,
            resizable: false,
            customCell: function customCell(entry) {
                return unreadMessageDecoration(userReadableTime(moment.unix(parseInt(entry.publishedOn, 10))), entry.isRead, false, false);
            },
        },
        {
            property: 'publishedOn',
            title: translate({ id: 'CLIENT_NOTIFICATION_ENTRY_TIMESTAMP' }),
            visible: false,
            resizable: false,
            dropdownFilterOpts: {
                value: {
                    value: selectedPublishedOnFilter?.value ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                    label: selectedPublishedOnFilter?.label ?? translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                },
                visible: publishedOn.length > 0 && enableNotificationTimeStampFilter,
                items:
                    publishedOn.map((valueType) => ({
                        value: valueType.value,
                        label: valueType.label,
                    })),
                filterChange: (current) => {
                    const parentTopic = publishedOn.find(x => x.value === current?.value);
                    setPublishedFilterData(parentTopic);
                },
            },
        },
    ], [
        translate,
        sortOpts,
        selectedPublisherFilter?.value,
        selectedPublisherFilter?.label,
        selectedSeverityFilter?.value,
        selectedSeverityFilter?.label,
        publisherTypes,
        severityTypes,
        selectedTopicFilter?.value,
        selectedTopicFilter?.label,
        topicTypes,
        selectedPublishedOnFilter?.value,
        selectedPublishedOnFilter?.label,
        publishedOn,
        enableNotificationTimeStampFilter,
        enableNotificationSeverityFilter,
        unreadMessageDecoration,
        setPublisherFilterData,
        setSeverityFilterData,
        setTopicFilterData,
        userReadableTime,
        setPublishedFilterData,
    ]);

    const extraActionHeaderButtons: Array<GridHeaderButtons<INotificationEntry>> = useMemo(() => [
        {
            id: 'addProperty',
            type: 'main',
            buttonType: 'mat-flat-button',
            color: 'primary',
            customTemplate: () => elementToNode(
                <FormControlLabel
                    control={<Checkbox
                        className={classes.showOnlyUnread}
                        checked={onlyUnread}
                        onChange={(e) => handleHeaderCheckbox(e.target.checked)} />}
                    label={<Typography className={classes.showOnlyUnread}>
                        {translate({ id: 'CLIENT_NOTIFICATION_PAGE_UNREAD_ONLY' })}
                    </Typography>} />
            ),
        },
        {
            id: 'resetDefaultProperty',
            type: 'inline',
            buttonType: 'mat-flat-button',
            color: 'primary',
            customTemplate: () => elementToNode(
                <Link
                    align="left"
                    underline='none'
                    visibility={showResetToDefault ? 'visible' : 'hidden'}
                    className={classes.link}
                    target="_blank"
                    onClick={() => ResetToDefault()}
                    rel="noopener noreferrer">
                    {translate({ id: 'CLIENT_NOTIFICATIONS_FILTER_RESET_DEFAULT' })}
                </Link>
            ),
        },
    ], [ ResetToDefault, classes.link, classes.showOnlyUnread, handleHeaderCheckbox, onlyUnread, showResetToDefault, translate ]);

    const extraActionRowButtons: Array<GridRowButtons<INotificationEntry>> = useMemo(() => [
        {
            id: 'circle',
            label: translate({ id: 'CLIENT_EDIT' }),
            size: '20px',
            customTemplate: (row: INotificationEntry) => (
                elementToNode(<PortalTooltip text={row.isRead ? translate({ id: 'CLIENT_NOTIFICATION_UNREAD' }) :
                    translate({ id: 'CLIENT_NOTIFICATION_READ' })}>
                    <IconButton
                        size='small'
                        color={`${row.isRead ? 'inherit' : 'primary'}`}
                        onClick={(event) => rowAction(row, INotificationActionType.ToggleRead, event)}>
                        <CircleIcon className={classes.circleiconClass} />
                    </IconButton>
                </PortalTooltip>
                )
            ),
        },
        {
            id: 'Menu',
            label: translate({ id: 'CLIENT_ACTION' }),
            size: '8px',
            customTemplate: (row: INotificationEntry) => (
                elementToNode(<ApolloThemeProvider>
                    <IntlProvider>
                        <NotificationMoreRowActionsComponent {...{
                            notification: row,
                            action: rowAction,
                        }} />
                    </IntlProvider>
                </ApolloThemeProvider>)
            ),
        },
    ], [ classes.circleiconClass, rowAction, translate ]);

    const configuration: IGridOptions<any> = useMemo(() => ({
        loading: notificationsLoading || loadingGrid,
        columns: columnDef,
        headerOptions: {
            search: true,
            searchValue: searchTermText,
            searchTerm: (term: string) => updateGridData(index, size, term),
            gridHeaderButtons: extraActionHeaderButtons,

        },
        disabled: false,
        selectable: false,
        refreshable: true,
        refresh: notificationMutate,
        gridRowButtons: extraActionRowButtons,
        data: data?.value ?? [],
        sortChange: function sortChange(current) {
            sortGridData(current);
            return true;
        },
        footerOptions: {
            length: data?.['@odata.count'] ?? 0,
            pageSizes: [ 5, 10, 25, 50 ],
            pageIndex: index,
            pageSize: size,
            pageChange: ({
                pageIndex, pageSize,
            }: {
                pageIndex: number; pageSize: number;
            }) => {
                if (index !== pageIndex || size !== pageSize) {
                    updateGridData(pageIndex, pageSize, searchTermText);
                }
            },
        },
        rowClick: function rowClick(entry) {
            changeNotificationRead(entry);

        },
        toggleColumns: false,
        onGridApi: ({ dataManager }: {
            dataManager: DataManager<INotificationEntry>;
        }) => {
            setDataMgr(dataManager);
        },
    }), [
        notificationsLoading,
        loadingGrid,
        columnDef,
        extraActionHeaderButtons,
        notificationMutate,
        extraActionRowButtons,
        data,
        index,
        size,
        updateGridData,
        sortGridData,
        searchTermText,
        changeNotificationRead,
    ]);

    useEffect(() => {
        if (apGridRef.current && configuration) {
            apGridRef.current.configuration = configuration;
        }
    }, [ configuration ]);

    return (
        <div style={{
            padding: '24px',
            width: '100%',
        }}>
            <Typography
                className={classes.heading}
                role='heading'
                aria-level={1}>
                {translate({ id: 'CLIENT_NOTIFICATIONS' })}
            </Typography>

            <ApDataGrid
                data-cy="ap-data-grid-notification"
                ref={apGridRef}
            />
        </div>
    );
};

export default NotificationPageComponent;
