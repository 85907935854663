import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    accountLogicalName,
    isAdminSelector,
} from '../../../store/selectors';
import UiAlertBanner from '../../common/UiAlertBanner';
import useEcommercePriceChangesBannerViewModel from './EcommercePriceChangesBannerViewModel';

export const EcommercePriceChangesBanner: React.FC = () => {
    const isAdmin = useSelector(isAdminSelector);
    const currentAccountName = useSelector(accountLogicalName);

    const { shouldDisplayPriceChangesBanner } = useEcommercePriceChangesBannerViewModel(isAdmin, currentAccountName);

    return (
        <>
            {shouldDisplayPriceChangesBanner && (
                <UiAlertBanner
                    type="info"
                    closeable={false}>
                    <Typography>
                        <FormattedMessage
                            id="CLIENT_ECOMMERCE_PRICE_CHANGES_BANNER_MESSAGE"
                            values={{
                                a: (chunk: string) => <a href="mailto:cloud.billing@uipath.com">
                                    {chunk}
                                </a>,
                                supportEmailAddress: 'cloud.billing@uipath.com',
                            }}
                        />
                    </Typography>
                </UiAlertBanner>
            )}
        </>
    );
};
