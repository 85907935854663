import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
    Switch,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import useShowUiCustomizationDialog from '../../../../common/hooks/useShowUiCustomizationDialog';
import type { INavCustomizationData } from './NavCustomizationComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        formControlLabel: { marginRight: '0px' },
        form: {
            width: '518px',
            marginBottom: '16px',
        },
        icon: {
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '8px',
            color: theme.palette.semantic.colorForeground,
        },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tableBodyCell: {
            width: '35%',
            height: '40px',
            padding: '10px 0px 10px 20px',
        },
        tableBodyCellAction: {
            width: '30%',
            padding: '10px 20px',
            display: 'flex',
            justifyContent: 'center',
            paddingRight: '16px',
        },
        uiElementText: { color: theme.palette.semantic.colorForeground },
        infoIcon: {
            color: theme.palette.semantic.colorIconDefault,
            paddingLeft: '8px',
        },
    }),
);

const EditNavIconFormComponent: FC<{
    id: string;
    nameTextId: string;
    titleTextId: string;
    descriptionTextId: string;
    icon: any;
    uiLocation: string;
    tooltipTextId: string;
}> = ({
    id,
    nameTextId,
    titleTextId,
    descriptionTextId,
    icon,
    uiLocation,
    tooltipTextId,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { control } = useFormContext<INavCustomizationData>();
    const name = useMemo(() => `${id}Hidden` as keyof INavCustomizationData, [ id ]);
    const serviceName = useMemo(() => translate({ id: nameTextId }), [ nameTextId, translate ]);
    const showUiCustomizationDialog = useShowUiCustomizationDialog();
    const handleSwitch = useCallback(
        async (checked: boolean, callback: (_: any) => void) => {
            const proceed = !checked || (await showUiCustomizationDialog(nameTextId, titleTextId, descriptionTextId));
            if (proceed) {
                callback(checked);
            }
        },
        [ showUiCustomizationDialog, descriptionTextId, nameTextId, titleTextId ],
    );

    return (
        <TableRow>
            <TableCell
                align="left"
                className={clsx(classes.tableBodyCell)}>
                {icon}
                <Typography
                    id={`serviceName-${id}`}
                    className={classes.uiElementText}>
                    {serviceName}
                </Typography>
                <Tooltip
                    title={translate({ id: tooltipTextId })}
                    arrow
                    data-cy={`edit-nav-icon-${id}-tooltip`}>
                    <InfoIcon
                        className={
                            classes.infoIcon
                        }
                    />
                </Tooltip>
            </TableCell>
            <TableCell
                align="left"
                className={clsx(classes.tableBodyCell)}>
                <Typography>
                    {uiLocation}
                </Typography>
            </TableCell>
            <TableCell
                align="center"
                className={clsx(classes.tableBodyCell, classes.tableBodyCellAction)}>
                <Controller
                    name={name}
                    control={control}
                    render={props => (
                        <Switch
                            inputProps={{ 'aria-label': translate({ id: 'CLIENT_HIDE_SHOW' }) }}
                            checked={!props.value}
                            onChange={e => handleSwitch(!e.target.checked, props.onChange)}
                            color="primary"
                            aria-labelledby={`serviceName-${id}`}
                            data-cy={`nav-customization-${name}-switch`} />
                    )}
                />
            </TableCell>
        </TableRow>
    );
};

export default EditNavIconFormComponent;
