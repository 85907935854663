import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    matchPath,
    useLocation,
} from 'react-router';

import type { ISettingRoute } from '../common/interfaces/route';
import { SettingsRoutes } from '../component/container/NavRoutes.default';
import { HostSettingsRoutes } from '../component/container/NavRoutesHost.default';
import {
    isAdminSelector,
    isHostModeSelector,
} from '../store/selectors';

export function useSettingsRoutes() {
    const isHostMode = useSelector(isHostModeSelector);
    const isAdmin = useSelector(isAdminSelector);

    return (isHostMode && HostSettingsRoutes) || (isAdmin && SettingsRoutes) || [];
}

export function useIsSettingsRouteMatched() {
    const { pathname } = useLocation();
    const settingsRoutes = useSettingsRoutes();

    return useMemo(
        () =>
            settingsRoutes.some(route => {
                if (matchPath(pathname, route.route)) {
                    return true;
                }
                return route.subRoutes?.some(subRoute => matchPath(pathname, subRoute));
            }),
        [ pathname, settingsRoutes ],
    );
}

export function isSubRoute(currentRoute: string, page: ISettingRoute) {
    if (page.subRoutes?.some(route => matchPath(currentRoute, route))) {
        return page.route;
    }

    return null;
}
