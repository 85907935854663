import {
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { renderRoutes } from 'react-router-config';

import { PortalTagManagementOrganizingResourcesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import TenantTagsTabs from './TenantTagsTabs';
import useTenantTagsViewModel from './TenantTagsViewModel';

const useStyles = makeStyles(() =>
    createStyles({ description: { marginBottom: '8px' } }),
);

const TenantTagsComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'zh-TW', 'ru' ] });
    const organizationName = useOrganizationName();

    const {
        tenantId, tenant,
    } = useTenantTagsViewModel();

    const breadCrumbLinks = useMemo(() => process.buildConfigs.showForMSI
        ? [
            {
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                link: RouteNames.TenantTagsMsi,
                name: translate({ id: 'CLIENT_TAGS' }),
            },
        ]
        : [
            {
                link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                name: tenant?.name ?? '',
            },
            {
                link: RouteNames.TenantTags.replace(':tenantId', tenantId),
                name: translate({ id: 'CLIENT_TAGS' }),
            },
        ],
    [ organizationName, tenant?.name, tenantId, translate ]);

    return (
        <UiPageContainer
            header={AdminBreadCrumbs(breadCrumbLinks)}
            disableGutter={[ 'top' ]}
        >
            <Typography className={classes.description}>
                {translate(
                    { id: 'CLIENT_TAGS_LABEL_DESCRIPTION' },
                    {
                        LEARNMORE: <Link
                            href={getLocalizedLink(PortalTagManagementOrganizingResourcesLink)}
                            rel="noreferrer"
                            target="_blank"
                            underline='none'
                        >
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Link>,
                    },
                )}
            </Typography>
            <TenantTagsTabs />
        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <TenantTagsComponent />
        {renderRoutes(route.routes)}
    </>
);
