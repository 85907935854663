import { formatDate } from '@experiences/ecommerce';
import { useLocalization } from '@experiences/locales';
import {
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
    accountLogicalName,
    isAdminSelector,
} from '../../../store/selectors';
import UiAlertBanner from '../../common/UiAlertBanner';
import useEcommerceLicensePendingChangeBannerViewModel from './EcommerceLicensePendingChangeBannerViewModel';

const useStyles = makeStyles(() =>
    createStyles({
        viewChangeLink: {
            marginLeft: '12px',
            cursor: 'pointer',
            alignSelf: 'center',
        },
        bannerContent: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        bannerMessage: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
);

export const EcommerceLicensePendingChangeBanner: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();
    const isAdmin = useSelector(isAdminSelector);
    const currentAccountName = useSelector(accountLogicalName);

    const {
        nextPayment,
        handleManageLicensePendingChangeClick,
        handleManageLicensePendingChangeKeyboardEvent,
        shouldDisplayLicensePendingChangeBanner,
    } = useEcommerceLicensePendingChangeBannerViewModel(isAdmin, currentAccountName);

    return (
        <>
            {shouldDisplayLicensePendingChangeBanner && (
                <div data-cy="ui-license-pending-change-banner">
                    <UiAlertBanner
                        type="warning"
                        closeable={false}>
                        <div className={classes.bannerContent}>
                            <div className={classes.bannerMessage}>
                                <Typography>
                                    {translate(
                                        { id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_WARNING_BANNER_LINE_1' },
                                        { nextPaymentDate: formatDate(nextPayment, language) },
                                    )}
                                </Typography>
                                <strong>
                                    {translate(
                                        { id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_WARNING_BANNER_LINE_2' },
                                        { nextPaymentDate: formatDate(nextPayment, language) },
                                    )}
                                </strong>
                            </div>

                            <Link
                                className={classes.viewChangeLink}
                                role='link'
                                tabIndex={0}
                                onClick={handleManageLicensePendingChangeClick}
                                onKeyDown={handleManageLicensePendingChangeKeyboardEvent}
                                data-cy="view-license-pending-change"
                            >
                                {translate({ id: 'CLIENT_ECOMMERCE_VIEW_LICENSE_PENDING_CHANGE_BUTTON' })}
                            </Link>
                        </div>
                    </UiAlertBanner>
                </div>
            )}
        </>
    );
};
