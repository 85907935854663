import { SqsConnectionsString } from '@experiences/constants';
import { useLocalizedLinks } from '@experiences/util';
import {
    FormHelperText,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { AwsSqsConnDetails } from './ConnectionDetailsType';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

export const AwsSqsInputs: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();
    const {
        control, errors,
    } = useFormContext<AwsSqsConnDetails>();

    return (
        <>
            <div className={clsx(classes.input)}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_ACCESS_KEY_ID' })}
                </Typography>
                <Controller
                    as={TextField}
                    rules={{ required: true }}
                    control={control}
                    type="text"
                    name="awsAccessKeyId"
                    data-cy="logs-connection-awsAccessKeyId"
                    variant="outlined"
                    error={!!errors.awsAccessKeyId}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                />
            </div>

            <div className={clsx(classes.input)}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_SECRET_ACCESS_KEY' })}
                </Typography>
                <Controller
                    as={TextField}
                    rules={{ required: true }}
                    control={control}
                    type="password"
                    name="awsSecretAccessKey"
                    data-cy="logs-connection-awsSecretAccessKey"
                    variant="outlined"
                    error={!!errors.awsSecretAccessKey}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                />
                <FormHelperText className={clsx(errors.awsSecretAccessKey?.type === 'pattern' ? classes.breakAll : '')}>
                    {errors.awsSecretAccessKey?.type === 'required'
                        ? translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        : translate(
                            { id: 'CLIENT_INSIGHTS_AWS_CONNECTION_INFO' },
                            {
                                awsDocumentationLink: (
                                    <Link
                                        href={getLocalizedLink(SqsConnectionsString)}
                                        rel="noreferrer"
                                        target="_blank"
                                        underline='none'
                                    >
                                        {translate({ id: 'CLIENT_INSIGHTS_AWS_DOCUMENTATION' })}
                                    </Link>
                                ),
                            },
                        )}
                </FormHelperText>
            </div>

            <div className={clsx(classes.input)}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_SQS_QUEUE_URL' })}
                </Typography>
                <Controller
                    as={TextField}
                    rules={{ required: true }}
                    control={control}
                    type="text"
                    name="queueUrl"
                    data-cy="logs-connection-queueUrl"
                    variant="outlined"
                    error={!!errors.queueUrl}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                />
            </div>

            <div className={clsx(classes.input)}>
                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                    {translate({ id: 'CLIENT_AWS_SQS_REGION' })}
                </Typography>
                <Controller
                    as={TextField}
                    rules={{ required: true }}
                    control={control}
                    type="text"
                    name="region"
                    data-cy="logs-connection-region"
                    variant="outlined"
                    error={!!errors.region}
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                />
            </div>
        </>
    );
};
