import { UiLoader } from '@experiences/ui-common';
import {
    useFilteredParams,
    useRouteResolver,
} from '@experiences/util';
import React, { useEffect } from 'react';
import {
    useHistory,
    useLocation,
} from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { getOrgLogicalNameAndTenantName } from '../../services/organization/TenantService';
import { loadService } from '../../util/ServiceUtil';

export default function RedirectToService() {
    const location = useLocation();
    const history = useHistory();
    const getRoute = useRouteResolver();

    const [ tenantId, serviceKey ] = useFilteredParams([ 'tenantId', 'serviceKey' ]);

    useEffect(() => {
        (async (): Promise<void> => {
            // cleanup stale state
            localStorage.removeItem('serviceRedirectQueryParams');

            try {
                const {
                    organizationLogicalName, tenantName,
                } = await getOrgLogicalNameAndTenantName(tenantId);
                if (organizationLogicalName && tenantName && serviceKey) {
                    loadService(organizationLogicalName, tenantName, serviceKey);
                } else {
                    history.push(getRoute(RouteNames.CloudRPA));
                }
            } catch (error) {
                history.push(getRoute(RouteNames.CloudRPA));
            }
        })();
    }, [ location.search, history, serviceKey, tenantId, getRoute ]);

    return <UiLoader type="full" />;
}
