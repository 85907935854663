import { GlobalStyles } from '@experiences/theme';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
        },
        menuItemWithGroup: { paddingLeft: theme.spacing(2) },
        menuGroup: {
            fontWeight: theme.typography.fontWeightBold,
            opacity: 1,
            cursor: 'default',
            '&:hover': { backgroundColor: 'transparent !important' },
        },
    }),
}));

export interface IDropdownMenuItem {
    /** The label of the menu item */
    label: string;
    /** Data object associated with the menu item */
    tag?: any;
    /** If true, the menu item will be hidden */
    hidden?: boolean;
    /** Custom element selection attribute for test automation */
    dataCy?: string;
    /** Callback function to be executed when the menu item is clicked */
    onClick?: ((menuItem: IDropdownMenuItem) => void);
    /** If true, the menu item will be disabled */
    disabled?: boolean;
    /** If true, the menu item represents the name of a group of menu items */
    isGroupName?: boolean;
}

interface IUiDropdownMenuComponentProps {
    /** The menu items to be displayed in the dropdown menu */
    menuItems: IDropdownMenuItem[];
    /** If true, the dropdown menu will be disabled */
    disabled?: boolean;
    /** The type of the dropdown menu, based on it the menu icon will be changed */
    type: UiDropdownMenuType;
    /** Element selection attribute for test automation */
    dataCy?: string;
}

export enum UiDropdownMenuType {
    /** Three vertical lines menu */
    Hamburger,
    /** Three vertical dots menu */
    Kebab,
}

/**
 * Generic component for dropdown menus
 */
export const UiDropdownMenuComponent = ({
    type, menuItems, disabled = false, dataCy,
}: IUiDropdownMenuComponentProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const hasGroups = useMemo(() => menuItems.some(mi => mi.isGroupName), [ menuItems ]);

    const onClickMenuItem = useCallback((menuItem: IDropdownMenuItem) => {
        setAnchorEl(null);
        if (menuItem.onClick) {
            menuItem.onClick(menuItem);
        }
    }, []);

    return (
        <>
            <Tooltip title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    className={clsx(classes.showMoreButton)}
                    onClick={event => setAnchorEl(event.currentTarget)}
                    data-cy={dataCy ?? `ui-show-more-actions-menu`}
                >
                    { (type === UiDropdownMenuType.Kebab) &&
                        <MoreVertIcon className={classes.icon} /> }
                    { (type === UiDropdownMenuType.Hamburger) &&
                        <MenuIcon className={classes.icon} /> }
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}>
                {menuItems.map((menuItem, index) =>
                    (
                        (menuItem.hidden !== true) &&
                            <div key={`menuItemDiv${index}`}>
                                <MenuItem
                                    key={`menuItem${index}`}
                                    disabled={menuItem.isGroupName ? false : (menuItem?.disabled ?? disabled)}
                                    onClick={(_el) => onClickMenuItem(menuItem)}
                                    data-cy={menuItem.dataCy ?? `dropdown-menu-item-${index}`}
                                    className={menuItem.isGroupName ? clsx(classes.menuGroup) : clsx(classes.menuItem)}>
                                    <ListItemText
                                        primary={menuItem.label}
                                        classes={menuItem.isGroupName
                                            ? { primary: classes.menuGroup }
                                            : (hasGroups ? { primary: classes.menuItemWithGroup } : undefined)} />
                                </MenuItem>
                            </div>
                    ))}
            </Menu>
        </>
    );
};
