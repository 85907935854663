import {
    Box,
    Typography,
    useTheme,
} from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { Service } from '@uipath/portal-shell';
import {
    Reorder,
    useMotionValue,
} from 'framer-motion';
import React from 'react';
import { useIntl } from 'react-intl';

import { DividerBox } from './DividerBoxComponent';
import { useCustomDrag } from './hooks/use-custom-drag';
import { ServiceIcon } from './ServiceIconComponent';
import { MORE_DIVIDER } from './utils';

export const NavigationSettingsItem = ({
    item,
    dragEnd,
    keyNavigation,
}: {
    item: Service;
    dragEnd: () => void;
    keyNavigation: (event: React.KeyboardEvent, item: Service) => void;
}) => {
    const y = useMotionValue(0);
    const {
        boxShadow, backgroundColor, cursor,
    } = useCustomDrag(y);
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    const isMoreDivider = item.id === MORE_DIVIDER.id;

    return (
        <Reorder.Item
            value={item}
            id={item.id}
            onDragEnd={dragEnd}
            tabIndex={0}
            onKeyUp={e => keyNavigation(e, item)}
            whileFocus={{ backgroundColor: !isMoreDivider ? theme.palette.semantic.colorHover : 'none' }}
            whileHover={{ backgroundColor: !isMoreDivider ? theme.palette.semantic.colorHover : 'none' }}
            style={{
                backgroundColor: !isMoreDivider ? backgroundColor : 'none',
                boxShadow: !isMoreDivider ? boxShadow : 'none',
                cursor,
                y,
                margin: 0,
                width: '100%',
                height: '48px',
                position: 'relative',
                borderRadius: 5,
                display: 'flex',
                flexDirection: !isMoreDivider ? 'row' : 'column',
                alignItems: !isMoreDivider ? 'center' : 'unset',
                justifyContent: !isMoreDivider ? 'unset' : 'center',
                padding: !isMoreDivider ? '2px 16px' : '2px',
                outlineOffset: '-2px',
            }}>
            { item.id === MORE_DIVIDER.id ? (
                <DividerBox text={translate({ id: 'CLIENT_NAVIGATION_SETTINGS_HIDDEN_ITEMS' })} />
            ) : (
                <>
                    <ServiceIcon svg={item.svg} />

                    <Box sx={{ height: '24px' }}>
                        <Typography sx={{
                            fontSize: Tokens.FontFamily.FontMSize,
                            fontFamily: Tokens.FontFamily.FontNormal,
                            fontWeight: Tokens.FontFamily.FontWeightDefault,
                            lineHeight: Tokens.FontFamily.FontMLineHeight,
                            color: theme.palette.semantic.colorForeground,
                        }}>
                            {item.name}
                        </Typography>
                    </Box>
                </>
            )}
        </Reorder.Item>
    );
};
