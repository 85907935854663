import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import { UiDropdownButton } from '../../common/UiDropdownButton/UiDropdownButton';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        title: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '16px',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    }),
}));

export const FreeTrialsHeader: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    return <div className={clsx(classes.header)}>
        <Typography className={clsx(classes.title)}>
            {translate({ id: 'CLIENT_FREE_TRIALS' })}
        </Typography>
        <UiDropdownButton
            label={translate({ id: 'CLIENT_ACTIVATE_TRIAL_LICENSE' })}
            menuItems={[
                {
                    onClick: () => {
                        history.push({
                            pathname: `${getRoute(RouteNames.LicensingActivateLicenseOnline)}`,
                            state: {
                                previousLocation: location.pathname,
                                isUpdateLicense: false,
                                isEnterpriseActivation: false,
                                isTrialPerSkuActivation: true,
                            },
                        });
                    },
                    label: translate({ id: 'CLIENT_ONLINE_ACTIVATION' }),
                },
                {
                    onClick: () => {
                        history.push({
                            pathname: `${getRoute(RouteNames.ActivateOffline)}`,
                            state: {
                                previousLocation: location.pathname,
                                isUpdateLicense: false,
                                isTrialPerSkuActivation: true,
                            },
                        });
                    },
                    label: translate({ id: 'CLIENT_OFFLINE_ACTIVATION' }),
                },
            ]}
        />
    </div>;
};
