import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            margin: 'auto',
        },
    }),
);

export const TestConnection: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const location = useLocation();

    const EnableAADTestConnectionBrowserPost = useFeatureFlagValue(Features.EnableAADTestConnectionBrowserPost.name);
    const [ testConnectionCode, setTestConnectionCode ] = useState<string>();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('code');

        if (code) {
            setTestConnectionCode(code);
            if (EnableAADTestConnectionBrowserPost) {
                window.opener.postMessage({ AAD_code: code }, window.origin);
            } else {
                localStorage.setItem('AAD_code', code);
            }

            window.close();
        }
    }, [ EnableAADTestConnectionBrowserPost, location.search ]);

    return (
        <div className={classes.root}>
            {testConnectionCode ? <CircularProgress />
                : <Typography>
                    {translate({ id: 'CLIENT_TEST_CONNECTION_FAILED' })}
                </Typography>}
        </div>
    );
};
