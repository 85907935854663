import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { TenantStatusConstants } from '../TenantConstants';

const TenantStatusLabel = {
    ENABLE: 'ENABLE',
    DISABLE: 'DISABLE',
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
    INPROGRESS: 'INPROGRESS',
    ERROR: 'FAILED',
    ENABLE_STATUS_INPROGRESS: 'ENABLE_STATUS_INPROGRESS',
    DISABLE_STATUS_INPROGRESS: 'DISABLE_STATUS_INPROGRESS',
    UPDATE_INPROGRESS: 'UPDATE_INPROGRESS',
    DELETION_INPROGRESS: 'DELETION_INPROGRESS',
    UNAVAILABLE: 'UNAVAILABLE',
    UPDATING: 'UPDATING',
    UNKNOWN: 'UNKNOWN',
    MAINTENANCE: 'MAINTENANCE',
};

const useStyles = makeStyles(theme =>
    createStyles({
        tenantStatusColumn: {
            display: 'inline-flex',
            alignItems: 'center',
            cursor: 'auto',
            lineHeight: '20px',
        },
        activeCircle: {
            width: '8px',
            height: '8px',
            backgroundColor: theme.palette.semantic.colorSuccessIcon,
            borderRadius: '5px',
            marginRight: '6px',
        },
        inActiveCircle: {
            width: '8px',
            height: '8px',
            backgroundColor: theme.palette.semantic.colorForegroundDisable,
            borderRadius: '5px',
            marginRight: '6px',
        },
        inProgressCircle: {
            width: '8px',
            height: '8px',
            backgroundColor: theme.palette.semantic.colorWarningIcon,
            borderRadius: '5px',
            marginRight: '6px',
        },
    }),
);

type StatusCircle = 'activeCircle' | 'inActiveCircle' | 'inProgressCircle';

interface IStatusMessage {
    icon: StatusCircle;
    message: string;
}

interface ITenantStatusProps {
    data: string;
    label?: ReactNode | string;
    disableTooltip?: boolean;
    disableStatusIcon?: boolean;
}

export const TenantStatusComponent: React.FC<ITenantStatusProps> = ({
    data, label, disableTooltip, disableStatusIcon,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const tenantInProgressMessage = useMemo(
        () =>
            new Map([
                [ TenantStatusConstants.INPROGRESS, translate({ id: 'CLIENT_TENANT_IN_PROGRESS_TEXT' }) ],
                [ TenantStatusConstants.ENABLE_STATUS_INPROGRESS, translate({ id: 'CLIENT_TENANT_ENABLE_IN_PROGRESS_TEXT' }) ],
                [ TenantStatusConstants.DISABLE_STATUS_INPROGRESS, translate({ id: 'CLIENT_TENANT_DISABLE_IN_PROGRESS_TEXT' }) ],
                [ TenantStatusConstants.DELETION_INPROGRESS, translate({ id: 'CLIENT_TENANT_DELETE_IN_PROGRESS_TEXT' }) ],
                [ TenantStatusConstants.UPDATING, translate({ id: 'CLIENT_PROVISIONING' }) ],
            ]),
        [ translate ],
    );

    const tenantStatusMessageMap = useMemo(() => {
        const inProgressMessage = translate(
            { id: 'CLIENT_SERVICE_INSTANCE_PROVISIONING_MESSAGE' },
            { 0: tenantInProgressMessage.get(data) },
        );

        return new Map<string, IStatusMessage>([
            [
                TenantStatusConstants.ENABLED, {
                    icon: 'activeCircle',
                    message: '',
                },
            ],
            [
                TenantStatusConstants.DISABLED, {
                    icon: 'inActiveCircle',
                    message: '',
                },
            ],
            [
                TenantStatusConstants.ERROR, {
                    icon: 'inActiveCircle',
                    message: '',
                },
            ],
            [
                TenantStatusConstants.UNAVAILABLE,
                {
                    icon: 'inActiveCircle',
                    message: translate({ id: 'CLIENT_TENANT_UNAVAILABLE_MESSAGE' }),
                },
            ],
            [
                TenantStatusConstants.INPROGRESS, {
                    icon: 'inProgressCircle',
                    message: inProgressMessage,
                },
            ],
            [
                TenantStatusConstants.ENABLE_STATUS_INPROGRESS, {
                    icon: 'inProgressCircle',
                    message: inProgressMessage,
                },
            ],
            [
                TenantStatusConstants.DISABLE_STATUS_INPROGRESS, {
                    icon: 'inProgressCircle',
                    message: inProgressMessage,
                },
            ],
            [
                TenantStatusConstants.DELETION_INPROGRESS, {
                    icon: 'inProgressCircle',
                    message: inProgressMessage,
                },
            ],
            [
                TenantStatusConstants.UNKNOWN, {
                    icon: 'inProgressCircle',
                    message: '',
                },
            ],
            [
                TenantStatusConstants.UPDATING, {
                    icon: 'inProgressCircle',
                    message: '',
                },
            ],
            [
                TenantStatusConstants.MAINTENANCE, {
                    icon: 'inProgressCircle',
                    message: '',
                },
            ],
        ]);
    }, [ translate, data, tenantInProgressMessage ]);

    const statusMessage = tenantStatusMessageMap.get(data?.toUpperCase());
    if (!statusMessage) {
        return null;
    }
    return (
        <Tooltip
            arrow
            title={disableTooltip ? '' : statusMessage.message}>
            <div className={clsx(classes.tenantStatusColumn)}>
                {!disableStatusIcon ? <div
                    className={clsx(classes[statusMessage.icon])}
                    data-cy="tenant-status-icon" /> : undefined}
                <div data-cy="tenant-status-message">
                    {label ??
            translate({ id: 'CLIENT_' + ((TenantStatusLabel as any)[data.toUpperCase()] ?? TenantStatusLabel.UNAVAILABLE) })}
                </div>
            </div>
        </Tooltip>
    );
};
