import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import TenantTagsLabelsComponent from './labels/TenantTagsLabelsComponent';
import TenantTagsPropertiesComponent from './properties/TenantTagsPropertiesComponent';
import useTenantTagsTabsViewModel, { TenantTagsTab } from './TenantTagsTabsViewModel';

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case TenantTagsTab.LABELS:
            return <TenantTagsLabelsComponent />;
        case TenantTagsTab.PROPERTIES:
            return <TenantTagsPropertiesComponent />;
    }
    return null;
};

const TenantTagsTabs: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const history = useHistory();
    const getRoute = useRouteResolver();

    const {
        tenantId, tabValueState: [ tabValue, setTabValue ],
    } = useTenantTagsTabsViewModel();

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={(_, newValue: string) => setTabValue(newValue)}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={
                    { width: '100%' }
                }>
                <Tab
                    value={TenantTagsTab.LABELS}
                    label={translate({ id: 'CLIENT_LABELS' })}
                    onClick={() => history.push(getRoute(process.buildConfigs.showForMSI
                        ? RouteNames.TenantTagsLabelsMsi
                        : RouteNames.TenantTagsLabels.replace(':tenantId', tenantId)))}
                    data-cy="labels-tab"
                    className="default"
                />
                <Tab
                    value={TenantTagsTab.PROPERTIES}
                    label={translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' })}
                    onClick={() => history.push(getRoute(process.buildConfigs.showForMSI
                        ? RouteNames.TenantTagsPropertiesMsi
                        : RouteNames.TenantTagsProperties.replace(':tenantId', tenantId)))}
                    data-cy="properties-tab"
                    className="default"
                />
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default TenantTagsTabs;
