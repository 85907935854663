import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { useModalState } from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IDirectoryConnectionPayload } from '../../../services/identity/DirectoryConnectionService';
import {
    createLDAPDirectoryConnection,
    DirectoryConnectionType,
    directoryConnectionUrl,
    getDirectoryConnection,
    testConnectionConfiguration,
    updateDirectoryConnection,
} from '../../../services/identity/DirectoryConnectionService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultADDirectoryConnectionData,
    mapADDataToDirectoryConnectionDto,
    mapDirectoryConnectionToADConfigData,
} from '../../../util/DirectoryConnectionUtil';
import type { IADDirectoryConnectionData } from './SecuritySettingsADForm';

const useSecuritySettingsADFormViewModel = () => {
    const { formatMessage: translate } = useIntl();

    const partitionGlobalId = useSelector(accountGlobalId);
    const organizationName = useOrganizationName();

    const { close } = useModalState(RouteNames.SecuritySettings);
    const createNotification = useUiSnackBar();

    const [ showPasswordPlaceholder, setShowPasswordPlaceholder ] = useState(false);

    const { getErrorMessage } = useGetErrorInfo();
    const setErrorDialog = useCentralErrorSetter();

    const methods = useForm<IADDirectoryConnectionData>({
        mode: 'onSubmit',
        defaultValues: {
            domain: '',
            username: '',
            password: '',
            ldaps: false,
            useKerberosAuth: true,
        },
    });
    const {
        reset, setValue,
    } = methods;

    const {
        data: fetchedConfigurationData, mutate,
    } = useSWR<IDirectoryConnectionPayload[], Error>(
        [ directoryConnectionUrl, partitionGlobalId ],
        getDirectoryConnection,
        { shouldRetryOnError: false },
    );
    const fetchedDirectoryConnection = useMemo(
        () =>
            fetchedConfigurationData?.find(
                connection =>
                    connection.type === DirectoryConnectionType.AD ||
                    connection.type === DirectoryConnectionType.LDAPAD,
            ),
        [ fetchedConfigurationData ],
    );

    const {
        isAD, isLDAPAD,
    } = useMemo(() => ({
        isAD: fetchedDirectoryConnection?.type === DirectoryConnectionType.AD,
        isLDAPAD: fetchedDirectoryConnection?.type === DirectoryConnectionType.LDAPAD,
    }), [ fetchedDirectoryConnection ]);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                if (name === 'useKerberosAuth') {
                    setValue('ldaps', false, { shouldDirty: true });
                }
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const onSubmit = useCallback(
        async (data: IADDirectoryConnectionData) => {
            const dto = mapADDataToDirectoryConnectionDto(data);

            try {
                await testConnectionConfiguration(dto);
            } catch (error) {
                const message = await getErrorMessage(error);
                setErrorDialog(message);
                return;
            }

            try {
                if (fetchedDirectoryConnection) {
                    await updateDirectoryConnection(
                        partitionGlobalId,
                        fetchedDirectoryConnection.id,
                        fetchedDirectoryConnection.type,
                        dto,
                    );
                } else {
                    await createLDAPDirectoryConnection(partitionGlobalId, dto);
                }
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }));
                await mutate();
                close(true);
            } catch (error) {
                setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_AD_GENERIC_ERROR' }));
            }
        },
        [
            getErrorMessage,
            setErrorDialog,
            fetchedDirectoryConnection,
            createNotification,
            translate,
            mutate,
            close,
            partitionGlobalId,
        ],
    );

    useEffect(() => {
        const mappedDirectoryConnection = fetchedDirectoryConnection
            ? mapDirectoryConnectionToADConfigData(fetchedDirectoryConnection)
            : defaultADDirectoryConnectionData;
        reset({
            ...mappedDirectoryConnection,
            useKerberosAuth: (!process.buildConfigs.showKerberosAuth
                || mappedDirectoryConnection.useKerberosAuth)
                ?? false,
        });
        if (fetchedDirectoryConnection) {
            setShowPasswordPlaceholder(true);
        }
    }, [ fetchedDirectoryConnection, reset ]);

    return {
        isAD,
        isLDAPAD,
        organizationName,
        methods,
        onSubmit,
        handleSwitch,
        showPasswordPlaceholder,
    };
};

export default useSecuritySettingsADFormViewModel;
