import { useShowDialog } from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import forceLogout from '../../../auth/utils/ForceLogout';
import { notificationType } from '../../../common/constants/Constant';
import { checkIfOnlyUserInGroup } from '../../../services/identity/GroupService';
import { deleteUsers } from '../../../services/identity/UserService';
import {
    accountGlobalId,
    companyName,
    isAdminSelector,
    userGlobalId,
} from '../../../store/selectors';
import { UserGroup } from '../../common/UserGroups';
import { groupUrl } from '../../users/GroupsPageComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        formContainer: { maxWidth: '482px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
        },
        radio: {
            marginTop: '14px',
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        rowGroup: {
            marginLeft: '8px',
            display: 'flex',
            flexDirection: 'column',
        },
        section: { paddingBottom: '20px' },
        subHeading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
            fontWeight: 600,
        },
        infoText: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '14px',
        },
        deleteOrgButton: {
            marginTop: '12px',
            marginBottom: '16px',
        },
        deleteOrgButtonEnabled: {
            color: `${theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon)}`,
            backgroundColor: `${theme.palette.semantic.colorErrorIcon} !important`,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
    }),
);

const ProfileOrganizationComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const { enqueueSnackbar } = useSnackbar();

    const userId = useSelector(userGlobalId);
    const orgName = useSelector(companyName);
    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        data: lastAdminInOrg, isValidating,
    } = useSWR<Boolean, Error>(
        isAdmin ? [ `${groupUrl}/:id/checkIfOnlyUserInGroup`, partitionGlobalId, userId, UserGroup.Administrators ] : null,
        checkIfOnlyUserInGroup,
    );

    const leaveOrgAsync = useCallback(async () => {
        try {
            await deleteUsers(partitionGlobalId, [ userId ], true);
        } catch (error) {
            enqueueSnackbar(translate({ id: 'CLIENT_USER_LEAVE_FAILED' }), { variant: notificationType.ERROR as any });
        }
    }, [ enqueueSnackbar, partitionGlobalId, translate, userId ]);

    const openLeaveOrgDialog = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_LEAVE_ORG_NAME' }, { name: orgName }),
            body: `${translate({ id: 'CLIENT_SHOULD_LEAVE_ORG' }, { name: orgName })} ${translate({ id: 'CLIENT_SINGLE_USER_DELETE_SUFFIX' })}`,
            icon: 'error',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
        });
        if (proceed) {
            try {
                await leaveOrgAsync();
                forceLogout();
            } catch (error) {
                enqueueSnackbar(translate({ id: 'CLIENT_USER_LEAVE_FAILED' }), { variant: notificationType.ERROR as any });
            }
        }
    }, [ createDialog, translate, orgName, leaveOrgAsync, enqueueSnackbar ]);

    return (
        <div className={classes.formContainer}>
            <div className={classes.section}>
                <Typography
                    className={classes.heading}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_ORGANIZATION' })}
                </Typography>
            </div>
            <div className={classes.section}>
                <Typography className={classes.subHeading}>
                    {translate({ id: 'CLIENT_NAME' })}
                </Typography>
                <Typography data-cy="organization-info-name">
                    {orgName}
                </Typography>
                <Button
                    className={clsx({
                        [classes.deleteOrgButton]: true,
                        [classes.deleteOrgButtonEnabled]: !lastAdminInOrg,
                    })}
                    onClick={openLeaveOrgDialog}
                    data-cy="leave-organization-button"
                    disabled={!!lastAdminInOrg || isValidating}
                >
                    {translate({ id: 'CLIENT_LEAVE_ORG' })}
                </Button>
                {lastAdminInOrg && (
                    <Typography className={classes.infoText}>
                        {translate({ id: 'CLIENT_LEAVE_ORG_MIN_ONE_ADMIN' })}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default ProfileOrganizationComponent;
