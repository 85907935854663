import type { IService } from '../../../../common/interfaces/tenant/tenant';
import type { IServiceMetadata } from '../../interfaces/service';
import { ServiceLicenseStatus } from '../CreateEditTenantComponent';
import {
    getListOfDependencies,
    getListOfParents,
} from './ServiceDependencyGraph';

export function getHiddenServices(services: IService[], availableServices?: IServiceMetadata[]) {
    const listOfHiddenServices =
    availableServices
        ?.filter(
            service =>
                service.serviceLicenseStatus === ServiceLicenseStatus.NotAvailable &&
          !services?.find(s => s.serviceType === service.id),
        )
        .map(service => service.id) ?? [];
    availableServices?.forEach(service => {
        const listOfDependencies = getListOfDependencies(service.id);
        listOfHiddenServices.push(
            ...listOfDependencies.filter(
                parent => !availableServices.find(s => s.id === parent) && !services?.find(s => s.serviceType === parent),
            ),
        );
    });
    listOfHiddenServices.forEach(service => {
        const listOfParents = getListOfParents(service);
        listOfHiddenServices.push(...listOfParents);
    });
    return [ ...new Set(listOfHiddenServices) ];
}
