import type { IPlanDetails } from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    useCallback,
    useMemo,
} from 'react';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import type { ILicenseAndKey } from '../../../common/interfaces/licenses';
import {
    billingUrl,
    fetchPlanDetails,
} from '../../../services/licensing/BillingService';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../../services/licensing/management/AccountService';
import { useShouldShowManageButton } from '../../licensing/useShouldShowManageButton';

const useEcommerceLicensePendingChangeBannerViewModel = (isAdmin: boolean, accountName: string) => {
    const getRoute = useRouteResolver();
    const history = useHistory();
    const EnableEcommerceDecreaseLicenseFlow = useFeatureFlagValue(Features.EnableEcommerceDecreaseLicenseFlow.name);

    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );
    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);
    const shouldShowManageButton = useShouldShowManageButton(accountName, license?.accountType);

    const { data: planDetails } = useSWR<IPlanDetails, Error>(
        shouldShowManageButton ? [ accountName, `${billingUrl}/planDetails/licensePendingChangeBanner` ] : null,
        fetchPlanDetails,
    );
    const [ nextPayment, subscriptionScheduleId, hasLicenceUpdateSubscriptionSchedule ] = useMemo(
        () => [
            planDetails?.nextPayment,
            planDetails?.subscriptionScheduleId,
            planDetails?.hasLicenceUpdateSubscriptionSchedule,
        ],
        [ planDetails ],
    );

    const handleManageLicensePendingChangeClick = useCallback(() => {
        history.push(getRoute(RouteNames.ViewCancelLicensePendingChange), { subscriptionScheduleId });
    }, [ history, getRoute, subscriptionScheduleId ]);

    const handleManageLicensePendingChangeKeyboardEvent =
        useCallback((e: { key: string }) => {
            if (e.key === 'Enter') {
                handleManageLicensePendingChangeClick();
            }
        }, [ handleManageLicensePendingChangeClick ]);

    const shouldDisplayLicensePendingChangeBanner = useMemo(
        () => {
            // TODO:releases/S123
            // remove subscriptionScheduleId and rely only on hasLicenceUpdateSubscriptionSchedule
            const hasLicensePendingChanges = hasLicenceUpdateSubscriptionSchedule !== undefined
                ? hasLicenceUpdateSubscriptionSchedule
                : subscriptionScheduleId;
            return EnableEcommerceDecreaseLicenseFlow && isAdmin && hasLicensePendingChanges;
        },
        [ EnableEcommerceDecreaseLicenseFlow, isAdmin, subscriptionScheduleId, hasLicenceUpdateSubscriptionSchedule ]
    );

    return {
        nextPayment,
        handleManageLicensePendingChangeClick,
        handleManageLicensePendingChangeKeyboardEvent,
        shouldDisplayLicensePendingChangeBanner,
    };
};

export default useEcommerceLicensePendingChangeBannerViewModel;
