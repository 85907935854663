import { CISClientId } from '../configuration/AuthConfiguration.default';
import { CISHostWithAPIPrefix } from '../configuration/AuthDomainResolver';
import { clearAuthStorage } from './ClearStorage';

export default function forceLogout(returnUrl?: string) {
    const returnUrlLocal = returnUrl ?? `${window.location.origin}/portal_/cloudrpa`;

    const redirectParams = new URLSearchParams({
        clientid: CISClientId,
        postLogoutRedirectUri: returnUrlLocal,
    });

    clearAuthStorage();

    window.location.assign(`https://${CISHostWithAPIPrefix}/ui/account/ForceLogout?${redirectParams.toString()}`);
}
