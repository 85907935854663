import { useGetErrorInfo } from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { mutate } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { useUpdateUserProfilePersona } from '../../../common/hooks/useUpdateUserProfilePersona';
import { licenseManagementAccountUrl } from '../../../services/licensing/management/AccountService';
import { activateLicenseOnline } from '../../../services/licensing/management/ActivationService';
import { trialServicesSwrKey } from '../../../services/licensing/TrialPerSku';
import {
    accountGlobalId,
    isHostModeSelector,
    profile,
} from '../../../store/selectors';
import validateLicenseCode from '../../../util/validators/LicenseCodeValidator';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { useTenantOperationTrackerContext } from '../../tenants/TenantOperationTrackerContextProvider';
import LicenseActivationErrorDialogBody from './LicenseActivationErrorDialogBody';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '28px',
            marginBottom: '8px',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

interface IActivationData {
    licenseCode: string;
}

const ActivateLicenseOnlineComponent: React.FC<
{ location?: { state?: { previousLocation: string; isUpdateLicense: boolean;
    isEnterpriseActivation: boolean; isTrialPerSkuActivation?: boolean; customLicenseCode?: string; }; };
}> = ({ location }) => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();

    const accountId = useSelector(accountGlobalId);
    const accountProfile = useSelector(profile);
    const isHostMode = useSelector(isHostModeSelector);

    const { formatMessage: translate } = useIntl();
    const { getErrorObject } = useGetErrorInfo();
    const createNotification = useUiSnackBar();
    const { refreshAfterComplete } = useTenantOperationTrackerContext();
    const updateUserProfilePersona = useUpdateUserProfilePersona();

    const [ loading, setLoading ] = useState(false);

    const [ isUpdateLicense, isEnterpriseActivation, previousLocation, isTrialPerSkuActivation, customLicenseCode ] = useMemo(
        () => [
            location?.state?.isUpdateLicense,
            location?.state?.isEnterpriseActivation,
            location?.state?.previousLocation,
            location?.state?.isTrialPerSkuActivation,
            location?.state?.customLicenseCode,
        ],
        [ location ],
    );

    const {
        open, close,
    } = useModalState(getRoute(previousLocation ?? RouteNames.Licensing));

    const licenseCode = useMemo(
        () => customLicenseCode ? customLicenseCode : accountProfile.accountUserDto.licenseCode,
        [ accountProfile.accountUserDto.licenseCode, customLicenseCode ],
    );

    const methods = useForm<IActivationData>({
        mode: 'onSubmit',
        defaultValues: { licenseCode: isUpdateLicense ? licenseCode : '' },
        shouldUnregister: false,
    });

    const {
        control, formState, handleSubmit, setError, watch,
    } = useMemo(() => methods, [ methods ]);

    const { errors } = formState;

    const formLicenseCode = useMemo(() => watch('licenseCode'), [ watch ]);

    const onSubmit = useCallback(
        async (data: IActivationData) => {
            setLoading(true);
            if (!validateLicenseCode(data.licenseCode)) {
                setError('licenseCode', { type: 'invalid' });
                setLoading(false);
                return;
            }
            try {
                const response = await activateLicenseOnline(data.licenseCode, isHostMode, !!isTrialPerSkuActivation);
                if (response.operationStatus === 0) {
                    createNotification(
                        translate({
                            id: isUpdateLicense
                                ? 'CLIENT_LICENSE_UPDATED_SUCCESSFULLY'
                                : 'CLIENT_ACTIVATION_PLATFORM_LICENSED',
                        }),
                        notificationType.SUCCESS
                    );

                    updateUserProfilePersona();

                    await mutate(licenseManagementAccountUrl);
                    await mutate([ `${licenseManagementAccountUrl}/available`, '' ]);
                    await mutate(trialServicesSwrKey);
                }

                setLoading(false);
                refreshAfterComplete('', true);
                close();
            } catch (error) {
                const errorObject = await getErrorObject(error);
                if (errorObject.response?.status === 422) {
                    await createDialog({
                        title: translate({ id: 'CLIENT_ACTIVATE_FAILED' }),
                        icon: 'error',
                        unclosable: false,
                        showCancel: false,
                        customDialogContent: LicenseActivationErrorDialogBody,
                        customDialogContentProps: { operationStatus: errorObject.response.data.operationStatus },
                    });
                }
                setLoading(false);
            }
        },
        [
            setError,
            isHostMode,
            isTrialPerSkuActivation,
            refreshAfterComplete,
            close,
            createNotification,
            translate,
            isUpdateLicense,
            updateUserProfilePersona,
            getErrorObject,
            createDialog,
        ],
    );

    return (
        <UiDrawer
            title={translate({
                id: isEnterpriseActivation
                    ? 'CLIENT_ACTIVATE_ENTERPRISE_LICENSE'
                    : isUpdateLicense
                        ? 'CLIENT_UPDATE_LICENSE_ONLINE'
                        : 'CLIENT_ACTIVATE_LICENSE_ONLINE',
            })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <>
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary"
                                    data-cy='cancel-button'>
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={loading || formLicenseCode === ''}
                                    type="submit"
                                    variant="contained"
                                    data-cy="activate-submit-button"
                                >
                                    {translate({ id: isUpdateLicense ? 'CLIENT_UPDATE' : 'CLIENT_ACTIVATE_ACTION' })}
                                </UiProgressButton>
                            </div>
                        </>
                    }
                    isDrawer
                >
                    <Typography className={classes.subTitle}>
                        {translate({ id: 'CLIENT_ACCOUNT_ID' })}
                    </Typography>
                    <TextField
                        id="accountId"
                        disabled
                        value={accountId}
                        variant="outlined"
                        InputProps={{ className: 'Tall' }}
                        data-cy="activate-online-account-id"
                    />
                    <Typography
                        id="licenseCode"
                        className={classes.subTitle}>
                        {translate({ id: 'CLIENT_LICENSE_CODE' })}
                    </Typography>
                    <Controller
                        as={<TextField
                            inputProps={{ 'aria-labelledby': 'licenseCode' }}
                        />}
                        control={control}
                        name="licenseCode"
                        variant="outlined"
                        error={!!errors.licenseCode}
                        helperText={
                            errors.licenseCode?.type === 'invalid' &&
                            translate({ id: 'CLIENT_ACTIVATION_INVALID_LICENSE_CODE' })
                        }
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="activate-online-license-code"
                        disabled={isUpdateLicense}
                    />
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default ActivateLicenseOnlineComponent;
