import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    getAvailableTenantLeasesCount,
    leaseUrl,
} from '../../services/licensing/accountant/LeaseService';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import { isUnlicensedSelector } from '../../store/selectors';
import type { ILicenseAndKey } from '../interfaces/licenses';

export const useLicensingLimitation = () => {
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const { data } = useSWR<ILicenseAndKey, Error>(
        !isUnlicensedMode && !process.buildConfigs.hideForMSI ? licenseManagementAccountUrl : null,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const isLicenseExpired = useMemo(() => !!data && data.accountLicense.licenseStatus.toUpperCase() === 'EXPIRED', [ data ]);

    const { data: availableTenantsCount } = useSWR<number, Error>(
        !(isUnlicensedMode || isLicenseExpired) && !process.buildConfigs.hideForMSI ? leaseUrl : null,
        getAvailableTenantLeasesCount,
    );

    return {
        availableTenantsCount,
        isLicenseExpired,
    };
};
