export interface INotificationEntry {
    id: string;
    message: string;
    isRead: boolean;
    publicationId: string;
    publisherName: string;
    publisherId: string;
    topicId: string;
    topicName: string;
    userId: string;
    userEmail: string;
    priority: string;
    messageParam: string;
    messageVersion: string;
    redirectionUrl: string;
    publishedOn: string;
    tenantId: string;
    category: string;
}

export interface INotificationOdata {
    '@odata.context': string;
    '@odata.count': number;
    value: INotificationEntry[];
}

interface INotificationUpdateRead {
    notificationId: string;
    read: boolean;
}

export interface INotificationReadRequest {
    notifications: INotificationUpdateRead[];
    forceAllRead: boolean;
}

interface INotificationRowAction {
    notifications: INotificationUpdateRead[];
    forceAllRead: boolean;
}

export enum INotificationActionType {
    UnsubscribeTopic = 'UnsubscribeTopic',
    UnsubscribeTenant = 'UnsubscribeTenant',
    Delete = 'Delete',
    ToggleRead = 'ToggleRead',
}
export interface IFilterDataType {
    label: string;
    value: string;
    childs?: IFilterDataType[];
}

export interface IFilterQueryParamType {
    operand: string;
    operator: string;
    compValue: string;
}
export enum Duration {
    LastHour = 1,
    Last24Hour = 24,
    LastWeek = 7,
    LastMonth = 30,
}

export enum NotificationSeverity {
    Info = 'Info',
    Success = 'Success',
    Warn = 'Warn',
    Error = 'Error',
    Fatal = 'Fatal',
}
