import type { Theme } from '@mui/material';
import Tokens from '@uipath/apollo-core';
import type { Service } from '@uipath/portal-shell';

export const MORE_DIVIDER = { id: 'MORE_DIVIDER' };
export const DEBOUNCE_SAVE_KEYBOARD = 1000;

export const orderByDivider = (newOrder: Service[]): Service[] => {
    const dividerIndex = newOrder.findIndex((service) => service.id === MORE_DIVIDER.id);
    const finalOrder = [
        ...newOrder.slice(0, dividerIndex).map((service) => ({
            ...service,
            isMore: false,
        })),
        MORE_DIVIDER as Service,
        ...newOrder.slice(dividerIndex + 1).map((service) => ({
            ...service,
            isMore: true,
        })),
    ];

    return finalOrder;
};

export const getItemSvg = (theme: Theme, itemId: string, disabled = false) => {
    const serviceSvgs = window.PortalShell?.ServiceSvgs;
    const itemSvg = serviceSvgs.find((serviceSvg: any) => {
        if (itemId === 'connections') {
            return serviceSvg.id === 'integrations';
        }

        return serviceSvg.id === itemId;
    })?.svg;

    if (!itemSvg) {
        return;
    }

    const mainColor = disabled ? theme.palette.semantic.colorForegroundDisable : theme.palette.semantic.colorForegroundDeEmp;
    const backgroundColor = disabled ? theme.palette.semantic.colorBackgroundDisabled : theme.palette.semantic.colorBackground;

    const styleBlock = `
        <style>
            width: ${Tokens.Icon.IconS};
            height: ${Tokens.Icon.IconS};
            g { stroke: ${mainColor}; }
            path { color:  ${mainColor}; }
            polyline { stroke:  ${mainColor}; }
            rect { stroke:  ${mainColor}; }
            .svgFill { fill:  ${mainColor}; }
            .svgStroke { stroke:  ${mainColor}; }
            .fillBg { fill:  ${backgroundColor}; }
        </style>
    `;

    const styleIndex = itemSvg.indexOf('>', itemSvg.indexOf('<svg')) + 1;

    return [ itemSvg.slice(0, styleIndex), styleBlock, itemSvg.slice(styleIndex) ].join('');
};
