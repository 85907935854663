import {
    Box,
    FormControlLabel,
    Radio,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

const useStyles = makeStyles(theme =>
    createStyles({
        themeRadioGroup: {
            display: 'grid',
            gap: Tokens.Padding.PadS,
            gridTemplateRows: '1fr min-content',
        },
        themeRadioImage: {
            marginLeft: `-${Tokens.Spacing.SpacingXl}`,
            borderRadius: '10px',
        },
        themeRadioControl: {
            width: 'min-content',
            alignItems: 'end',
        },
        themeRadioLabel: {
            display: 'grid',
            gridTemplateRows: 'min-content 42px',
            gap: Tokens.Padding.PadM,
            alignItems: 'center',
        },
        selectedThemeImage: {
            outline: `${Tokens.Border.BorderThickM} solid ${theme.palette.semantic.colorPrimary}`,
            outlineOffset: '-2px',
        },
        radio: { color: theme.palette.semantic.colorForegroundDeEmp },
    }),
);

interface ThemeRadioComponentProps {
    theme: string;
    value: string;
    disabled: boolean;
    imgSrc: string;
    label: string;
}

const ThemeRadioComponent: FC<ThemeRadioComponentProps> = ({
    theme, value, disabled, imgSrc, label,
}) => {
    const classes = useStyles();

    const labelName = `theme-${theme}`;
    const selected = value === theme;

    const imageClasses = {
        [classes.themeRadioImage]: true,
        [classes.selectedThemeImage]: selected,
    };

    return <Box
        className={classes.themeRadioGroup}>
        <FormControlLabel
            className={classes.themeRadioControl}
            control={<Radio
                className={classes.radio}
                data-cy={`${labelName}-radio`}
                name={labelName}
                value={theme}
                disabled={disabled}
                checked={selected}
                color="primary" />}
            label={
                <Box className={classes.themeRadioLabel}>
                    <img
                        className={clsx(imageClasses)}
                        src={imgSrc}
                        alt="" />
                    <Typography>
                        {label}
                    </Typography>
                </Box>
            }
        />
    </Box>;
};

export default ThemeRadioComponent;
