import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';

import CarouselItems from './CarouselItems';
import type { CarouselSlideProps } from './types/CarouselSlideProps';

const useStyles = makeStyles((theme) =>
    createStyles({
        carouselSlideItems: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '38px',
            paddingBottom: '28px',
            marginRight: '54px',
            marginLeft: '32px',
        },
        fullRightBgImageContainer: {
            flexShrink: 0,
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '360px',
        },
        fullRightImage: {
            overflow: 'clip',
            maxWidth: '100%',
        },
        '@media all and (max-width: 1350px)': { fullRightBgImageContainer: { display: 'none' } },
    }),
);

const CarouselSlideFullRightImageStyle = ({
    lineImage,
    title,
    description,
    backgroundImage,
    buttonText,
    buttonRedirectUrl,
    buttonType,
    setOpen,
    idx,
    currSlide,
}: CarouselSlideProps) => {
    const classes = useStyles();
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'space-between',
            }}
            data-cy={`carousel-slide-${idx}`}
        >
            <div
                className={classes.carouselSlideItems}
                data-cy={`carousel-items-primary-${idx}`}>
                <CarouselItems
                    title={title}
                    description={description}
                    lineImage={lineImage}
                    buttonText={buttonText}
                    buttonType={buttonType}
                    buttonRedirectUrl={buttonRedirectUrl}
                    idx={idx}
                    currSlide={currSlide}
                    setOpen={setOpen}
                />
            </div>
            {backgroundImage && <div
                className={classes.fullRightBgImageContainer}
                data-cy={`bottom-right-img-${idx}`}
            >
                <img
                    alt="" // decorative image no alt text needed
                    src={backgroundImage}
                    className={classes.fullRightImage}
                />
            </div> }
        </div>
    );
};

export default CarouselSlideFullRightImageStyle;
