import type { IUiLinkMap } from '@experiences/interfaces';

export const DirectorySSOLink: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/automation-cloud/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'ja', 'https://docs.uipath.com/automation-cloud/lang-ja/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'fr', 'https://docs.uipath.com/automation-cloud/lang-fr/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'de', 'https://docs.uipath.com/automation-cloud/lang-de/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'zh-CN', 'https://docs.uipath.com/automation-cloud/lang-zh_CN/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'zh-TW', 'https://docs.uipath.com/automation-cloud/lang-zh_CN/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'es', 'https://docs.uipath.com/automation-cloud/lang-es/docs/authentication-options#models-that-use-directory-accounts' ],
    [ 'pt-BR', 'https://docs.uipath.com/automation-cloud/lang-pt_BR/docs/authentication-options#models-that-use-directory-accounts' ],
]);
