import { Entitlements } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ICard } from '@experiences/interfaces';
import { PermissionType } from '@experiences/interfaces';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import accountsAndGroupIcon from '../../images/accountsAndGroupsAdmin.svg';
import auditLogsAdminon from '../../images/auditLogsAdmin.svg';
import externalAppsAdmin from '../../images/externalAppsAdmin.svg';
import licensesAdmin from '../../images/licensesAdmin.svg';
import securityAdmin from '../../images/securityAdmin.svg';
import settingsAdmin from '../../images/settingsAdmin.svg';
import tagsAdmin from '../../images/tagsAdmin.svg';
import type { IKmsConfig } from '../../services/identity/KeyManagementService';
import {
    getKmsConfig,
    kmsUrl,
} from '../../services/identity/KeyManagementService';
import { isEntitled } from '../../services/licensing/EntitlementsService';
import {
    getPermissions,
    tagsUrl,
} from '../../services/orchestrator/TagsService.msi';
import {
    accountGlobalId,
    accountType,
    isHostModeSelector,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import EncryptionDowngradeWarningDialogBody from '../securitySettings/subcomponents/Encryption/EncryptionDowngradeWarningDialogBody';
import { hasPermission } from '../tags/addedit/properties/TenantTagsUtil';

const useStyles = makeStyles(() =>
    createStyles({
        cardIcon: {
            width: '30px',
            marginRight: '4px',
        },
    }),
);

const OrganizationAdminHome: FC = () => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();

    const isHost = useSelector(isHostModeSelector);
    const AccountType = useSelector(accountType);
    const accountId = useSelector(accountGlobalId);
    const createDialog = useShowDialog();

    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);
    const EnablePortalTagManagement = useFeatureFlagValue(Features.EnablePortalTagManagement.name);
    const EnableCustomerManagedKeyEntitlement = useFeatureFlagValue(Features.EnableCustomerManagedKeyEntitlement.name);
    const EnableNewOrganizationSMTPSettingsPage = useFeatureFlagValue(Features.EnableNewOrganizationSMTPSettingsPage.name);

    const { data: hasEntitlement } =
        useSWR<boolean, Error>([ Entitlements.CustomerManagedKey, EnableCustomerManagedKeyEntitlement ],
            (entitlement, flag) => flag ? isEntitled(entitlement) : false);

    const { data: kmsConfig } = useSWR<IKmsConfig, Error>(
        [ kmsUrl, accountId ],
        getKmsConfig,
    );

    const { data: permissions } = useSWR(
        (process.buildConfigs.showForMSI) ?
            [ `${tagsUrl}/permissions`, '', '', accountId ] : null,
        (_url: string, organizationName: string, tenantName: string, selectedAccountId: string) =>
            getPermissions(organizationName, tenantName, selectedAccountId),
    );

    useEffect(() => {
        if (EnableCustomerManagedKeyEntitlement && hasEntitlement != null && !hasEntitlement && kmsConfig?.keyType === 1) {
            createDialog({
                title: translate({ id: 'CLIENT_ENCRYPTION_DOWNGRADE_WARNING_TITLE' }),
                customDialogContent: EncryptionDowngradeWarningDialogBody,
                icon: 'warning',
            });
        }
    }, [ EnableCustomerManagedKeyEntitlement, createDialog, hasEntitlement, kmsConfig?.keyType, translate ]);

    const handleTagsClick = useCallback(() => {
        logEvent('PortalTagManagement.CardClick');
        return getRoute(RouteNames.TenantTagsLabelsMsi);
    }, [ getRoute, logEvent ]);

    const cardList: ICard[] = useMemo(() => [
        {
            title: isHost ? 'CLIENT_USERS' : 'CLIENT_ACCOUNTS_AND_GROUPS',
            titleIcon: accountsAndGroupIcon,
            description: isHost ? 'CLIENT_USERS_DESCRIPTION' : 'CLIENT_ACCOUNTS_AND_GROUPS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.Users),
            dataCy: 'org-settings-users-card',
        },
        {
            title: 'CLIENT_LICENSES',
            titleIcon: licensesAdmin,
            description: isHost ? 'CLIENT_HOST_LICENSES_DESCRIPTION' : 'CLIENT_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.Licensing),
            chipText: AccountType ? `CLIENT_${AccountType.toUpperCase()}_PLAN_ADMIN` : 'CLIENT_UNLICENSED',
            dataCy: 'org-settings-licenses-card',
            invisible: process.buildConfigs.hideForMSI,
        },
        {
            title: 'CLIENT_SECURITY',
            titleIcon: securityAdmin,
            description: isHost ? 'CLIENT_HOST_SECURITY_DESCRIPTION' : 'CLIENT_SECURITY_DESCRIPTION',
            onClickLink: getRoute(EnableSecuritySettingsRevamp || !isHost
                ? RouteNames.SecuritySettings
                : RouteNames.AuthSettings),
            dataCy: 'org-settings-security-card',
        },
        {
            title: 'CLIENT_AUDIT_LOG',
            titleIcon: auditLogsAdminon,
            description: 'CLIENT_AUDIT_LOG_DESCRIPTION',
            onClickLink: getRoute(RouteNames.AuditLogs),
            dataCy: 'org-settings-audit-logs-card',
        },
        {
            title: 'CLIENT_EXTERNAL_APPLICATIONS',
            titleIcon: externalAppsAdmin,
            description: 'CLIENT_EXTERNAL_APPLICATIONS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ExternalApplications),
            dataCy: 'org-settings-external-apps-card',
            invisible: isHost,
        },
        ...((process.buildConfigs.showForMSI && !isHost && (EnablePortalTagManagement && permissions)) ? [
            {
                title: 'CLIENT_TAGS',
                titleIcon: tagsAdmin,
                description: 'CLIENT_TENANT_HOME_TAGS_DESCRIPTION',
                onClickLink: handleTagsClick(),
                dataCy: 'tenant-home-tags-card',
                alt: 'Tags',
                disabled: !hasPermission(permissions, [ PermissionType.View ]),
                showWarning: !hasPermission(permissions, [ PermissionType.View ]),
                warningTooltip: translate({ id: 'CLIENT_TENANT_TAGS_PERMISSIONS_RESTRICTION' }, { permissions: 'Tags.View' }),
            },
        ] : []),
        {
            title: 'CLIENT_MAIL_SETTINGS',
            titleIcon: auditLogsAdminon,
            description: 'CLIENT_MAIL_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.EmailSettings),
            dataCy: 'org-settings-smtp-settings-card',
            invisible: !EnableNewOrganizationSMTPSettingsPage,
        },
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: settingsAdmin,
            description: isHost ? 'CLIENT_HOST_SETTINGS_DESCRIPTION' : 'CLIENT_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(isHost ? RouteNames.LoginCustomization : RouteNames.OrganizationSettings),
            dataCy: 'org-settings-global-settings-card',
            invisible: !isHost && process.buildConfigs.hideForMSI,
        },
    ], [
        AccountType,
        EnableNewOrganizationSMTPSettingsPage,
        EnablePortalTagManagement,
        EnableSecuritySettingsRevamp,
        getRoute,
        handleTagsClick,
        isHost,
        permissions,
        translate,
    ]);

    return (
        <UiPageContainer>
            <UiCardGrid
                maxCardWidth="338px"
                style={{ marginTop: '20px' }}>
                {cardList.map((card, i) =>
                    !card.invisible &&
                    <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={<img
                            className={classes.cardIcon}
                            src={card.titleIcon}
                            alt='accountsAndGroups'
                            style={{ width: '40px' }} />}
                        description={card.description}
                        chipText={card.chipText}
                        onClickLink={() => history.push(card.onClickLink)}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default OrganizationAdminHome;
