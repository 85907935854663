import {
    UiBackground,
    UiLogo,
    UiSessionId,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Button,
    Paper,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router';
import { matchRoutes } from 'react-router-config';

import useStaticLogout from '../../auth/hooks/StaticLogout';
import * as RouteNames from '../../common/constants/RouteNames';
import useOrgAdminPrivateShellRoutes from '../../container/routeConfigs/useOrgAdminPrivateShellRoutes.default';
import { isAdminSelector } from '../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        paperDefault: {
            width: '425px',
            minHeight: '370px',
            overflow: 'auto',
            marginTop: '100px',
            backgroundColor: theme.palette.semantic.colorBackgroundRaised,
        },
        headerContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            marginTop: '42px',
        },
        headerIcon: {
            height: '48px',
            width: '48px',
            marginTop: '16px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        headerText: {
            marginTop: '16px',
            fontWeight: 600,
            fontSize: '18px',
        },
        headerSubText: {
            marginTop: '8px',
            width: '280px',
            textAlign: 'center',
        },
        button: { width: '100%' },
        buttonContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            width: '280px',
            marginTop: '32px',
            textAlign: 'center',
            marginBottom: '32px',
        },
    }),
);

/**
 * Display page when user enters wrong url
 */
const InvalidUrl: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);
    const logout = useStaticLogout();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const orgAdminPrivateShellRoutes = useOrgAdminPrivateShellRoutes();
    const location = useLocation();
    const isAdminOnlyRoute = useMemo(
        () => matchRoutes(orgAdminPrivateShellRoutes, location.pathname).length > 0,
        [ location.pathname, orgAdminPrivateShellRoutes ],
    );

    const login = useCallback(() => {
        logout();
    }, [ logout ]);

    const handleGoToProfile = useCallback(() => {
        history.push(getRoute(RouteNames.Profile));
    }, [ getRoute, history ]);

    return (
        <UiBackground center>
            <Paper className={classes.paperDefault}>
                <div className={classes.headerContainer}>
                    <UiLogo />
                    <CancelIcon className={classes.headerIcon} />
                    <Typography
                        className={classes.headerText}
                        data-cy='invalid-url-header'
                    >
                        {translate({ id: 'CLIENT_INVALID_BODY_HEADER' })}
                    </Typography>
                    <Typography className={classes.headerSubText}>
                        {
                            (isAdminOnlyRoute && !isAdmin && translate({ id: 'CLIENT_INVALID_ADMIN_ONLY_URL_BODY' })) ||
                            translate({ id: 'CLIENT_INVALID_URL_BODY' })
                        }
                    </Typography>
                    <div className={classes.buttonContainer}>
                        {!isAdmin && process.buildConfigs.enableInvalidUrlGoToProfile && (
                            <Button
                                variant="contained"
                                className={classes.button}
                                onClick={handleGoToProfile}>
                                {translate({ id: 'CLIENT_GO_TO_PROFILE' })}
                            </Button>
                        )}
                        <Button
                            variant="contained"
                            className={classes.button}
                            onClick={login}>
                            {translate({ id: 'CLIENT_SIGN_IN' })}
                        </Button>
                    </div>
                    <UiSessionId style={{ marginBottom: '20px' }} />
                </div>
            </Paper>
        </UiBackground>
    );
};

export default InvalidUrl;
