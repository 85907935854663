import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useMemo } from 'react';

import { useIsAdminRevampEnabled } from './useIsAdminRevampEnabled';

export const useIsSecuritySettingsRevampEnabled = () => {
    const EnableSecuritySettingsRevamp = useFeatureFlagValue(Features.EnableSecuritySettingsRevamp.name);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const isSecuritySettingsRevampEnabled = useMemo(() =>
        !!EnableSecuritySettingsRevamp && isAdminRevampEnabled
    , [ EnableSecuritySettingsRevamp, isAdminRevampEnabled ]);

    return isSecuritySettingsRevampEnabled;
};

