import { getVariation } from '@experiences/ab-testing';
import { Campaigns } from '@experiences/constants';
import type {
    IPackage,
    IPackagesConfig,
} from '@experiences/ecommerce';
import {
    ANNUAL_PLAN_TYPE,
    billingUrl,
    getPackagesConfigByCurrency,
    getPriceString,
    MONTHLY_PLAN_TYPE,
    useEcommerce,
    useEcommerceEnabledCountries,
} from '@experiences/ecommerce';
import { useLocalization } from '@experiences/locales';
import { useAuthContext } from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import useSWR from 'swr';

import useShowLearnMoreEcommerceDialog from '../../../../common/hooks/useShowLearnMoreEcommerceDialog';
import { getPackagesConfig } from '../../../../services/licensing/BillingService';

const useEcommercePresetsViewModel = (
    currentAccountName: string
) => {
    const { accountCountryCode } = useEcommerceEnabledCountries();
    const { token } = useAuthContext();
    const [ selectedPackageType, setSelectedPackageType ] = useState('SPATT');
    const packagesConfigUrl = `${billingUrl}/packagesConfig`;
    const [ currentCurrency, setCurrentCurrency ] = useState('USD');
    const language = useLocalization();

    const {
        currentSkuPackageDetails, arePricesLoading: pricesLoading, productsPricesInAllCurrencies,
    } = useEcommerce();

    const {
        data: offeredPresetsVariation,
        isValidating: offeredPresetsVariationLoading,
    } = useSWR<string, Error>(
        [ currentAccountName, '/offeredPresetsVariation' ],
        () => getVariation(
            Campaigns.EcommercePlanSelectionDisplayedPresets,
            Campaigns.EcommercePlanSelectionDisplayedPresets.parameters.ecommerceOfferedPresetsParameter,
            token
        ),
    );

    const {
        data: packagesData,
        isValidating: packagesLoading,
    } = useSWR<IPackagesConfig, Error>(
        [ currentAccountName, accountCountryCode, packagesConfigUrl ],
        () => {
            if (!currentCurrency) {
                return getPackagesConfig(currentAccountName, accountCountryCode);
            }
            return getPackagesConfigByCurrency(currentAccountName, token, currentCurrency);
        },
    );
    const [ selectedPackage, setSelectedPackage ] = useState<IPackage | undefined>();

    useEffect(() => {
        if (packagesData) {
            setSelectedPackage(packagesData?.packages.find(lpsPackage => lpsPackage.type === selectedPackageType));
        }
    }, [ packagesData, selectedPackageType ]);

    const onSelectOption = useCallback((packageType: string) => function() {
        setSelectedPackageType(packageType);
        setSelectedPackage(packagesData?.packages.find(lpsPackage => lpsPackage.type === packageType));
    },
    [ setSelectedPackageType, packagesData ],
    );

    const skuCodeToOfferType = useMemo<{ [key: string]: string | undefined }>(() => {
        const variation1 = Campaigns.EcommercePlanSelectionDisplayedPresets.variations.OneSingleOfferedPreset.name;
        const variation2 = Campaigns.EcommercePlanSelectionDisplayedPresets.variations.ThreeOfferedPresets.name;
        if (!offeredPresetsVariationLoading && offeredPresetsVariation === variation1) {
            return { 'SPATT': 'CLIENT_ECOMMERCE_AUTOMATE_FOR_YOURSELF' };
        } else if (!offeredPresetsVariationLoading && offeredPresetsVariation === variation2) {
            return {
                'SPATT': 'CLIENT_ECOMMERCE_BUILD_RUN_ON_YOUR_MACHINE',
                'AUTODEV_UNATTROBOT': 'CLIENT_ECOMMERCE_BUILD_RUN_ON_YOUR_VM',
                'SPUNATT': 'CLIENT_ECOMMERCE_BUILD_RUN_ON_UIPATH_HOSTED_VM',
            };
        }
        return {
            'SPATT': 'CLIENT_ECOMMERCE_AUTOMATE_FOR_YOURSELF',
            'SPUNATT': 'CLIENT_ECOMMERCE_AUTOMATE_FOR_YOUR_TEAM',
        };
    },
    [ offeredPresetsVariationLoading, offeredPresetsVariation ]);

    const showUiCustomizationDialog = useShowLearnMoreEcommerceDialog();
    const showDialogCallback = useCallback((packageType: string) => async () => {
        await showUiCustomizationDialog(packageType, skuCodeToOfferType[packageType] ?? '');
    }, [ showUiCustomizationDialog, skuCodeToOfferType ]);

    const getPriceValue = useCallback(
        (sku: IPackage, planType: string) => {
            let price = 0;
            sku.productQuantities?.forEach(productQuantity => {
                if (currentCurrency) {
                    const foundProductPrice = productsPricesInAllCurrencies?.find(
                        pp => pp.code === productQuantity.code && pp.planType === planType);
                    if (foundProductPrice) {
                        price += productQuantity.quantity * foundProductPrice.prices[currentCurrency];
                    }
                }
            });
            return price;
        },
        [ currentCurrency, productsPricesInAllCurrencies ],
    );

    const getPrice = useCallback(
        (sku: IPackage, planType: string) => {
            const price = getPriceValue(sku, planType);
            return getPriceString(price, currentCurrency, language);
        },
        [ currentCurrency, language, getPriceValue ],
    );

    const presetsOfferSubtitle = useMemo<string>(() => {
        if (skuCodeToOfferType) {
            return Object.keys(skuCodeToOfferType).length > 1
                ? 'CLIENT_ECOMMERCE_PRESETS_OFFERS'
                : 'CLIENT_ECOMMERCE_GET_STARTED_WITH_AUTOMATION_DEVELOPER';
        }

        return 'CLIENT_ECOMMERCE_PRESETS_OFFERS';
    },
    [ skuCodeToOfferType ]);

    const planTypeText: { [key: string]: string } = {
        [MONTHLY_PLAN_TYPE]: 'CLIENT_SLASH_MONTH',
        [ANNUAL_PLAN_TYPE]: 'CLIENT_SLASH_YEAR',
    };

    const discountedPrice = useMemo(() => currentSkuPackageDetails.discountedPrices[currentSkuPackageDetails.planType]?.[currentCurrency],
        [ currentSkuPackageDetails, currentCurrency ]);

    const price = useMemo (() => currentSkuPackageDetails.prices[currentSkuPackageDetails.planType]?.[currentCurrency],
        [ currentSkuPackageDetails, currentCurrency ]);

    const isMonthlyPlanType = useMemo(() => currentSkuPackageDetails.planType === 'ANNUAL_SUB_MONTHLY_PAY', [ currentSkuPackageDetails ]);

    const packagesDataSortedByPrice = useMemo(() => {
        if (!pricesLoading) {
            const planType = isMonthlyPlanType ? 'ANNUAL_SUB_MONTHLY_PAY' : 'ANNUAL_SUB_ANNUAL_PAY';
            packagesData?.packages.sort((p1, p2) => (getPriceValue(p1, planType) < getPriceValue(p2, planType) ? -1 : 1));
            packagesData?.packages.forEach(p => p.productQuantities.sort((p1, p2) => p1.code < p2.code ? -1 : 1));
        }

        return packagesData;
    },
    [ packagesData, pricesLoading, getPriceValue, isMonthlyPlanType ]);

    return {
        packagesData: packagesDataSortedByPrice,
        loading: packagesLoading || pricesLoading,
        selectedPackage,
        currentCurrency,
        currentSkuPackageDetails,
        productsPricesInAllCurrencies,
        selectedPackageType,
        getPrice,
        skuCodeToOfferType,
        planTypeText,
        isMonthlyPlanType,
        onSelectOption,
        discountedPrice,
        price,
        setCurrentCurrency,
        showDialogCallback,
        presetsOfferSubtitle,
    };
};

export default useEcommercePresetsViewModel;
