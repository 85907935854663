export const services = [
    {
        text: 'CLIENT_USAGE_HEADER_AHUB',
        link: 'https://docs.uipath.com/automation-hub/docs/introduction',
        dataCy: 'automation-hub-link',
    },
    {
        text: 'CLIENT_USAGE_HEADER_ANL',
        link: 'https://docs.uipath.com/insights/docs/about-insights',
        dataCy: 'insights-link',
    },
    {
        text: 'CLIENT_USAGE_HEADER_TMNG',
        link: 'https://docs.uipath.com/task-mining/docs/introduction',
        dataCy: 'task-mining-link',
    },
    {
        text: 'CLIENT_AI_FABRIC',
        link: 'https://docs.uipath.com/ai-fabric/docs/about-ai-fabric',
        dataCy: 'ai-fabric-link',
    },
];

export const EnterpriseBenefitStringIds: Record<string, string[]> = {
    leftPart: [
        'CLIENT_UNLIMITED_ROBOTS_AND_TENANTS',
        'CLIENT_ACCESS_TO_ADDITIONAL_UIPATH_SERVICES',
        'CLIENT_LARGE_DATA_STORAGE_AND_ENHANCED_DOCUMENT_UNDERSTANDING',
    ],
    rightPart: [ 'CLIENT_UNLIMITED_LICENSE_SUPPORT', 'CLIENT_IN_TIME_SUPPORT', 'CLIENT_GUARANTEED_UPTIME_SLA' ],
};
