import {
    Chip,
    CircularProgress,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { Theme } from '@mui/system';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import {
    getTenantById,
    tenantByIdUrl,
} from '../../../services/organization/TenantService';

interface IUiPageContainerThemeProps {
    maxWidth: string;
}

const useStyles = makeStyles<Theme, IUiPageContainerThemeProps>(theme =>
    createStyles({
        root: ({ maxWidth }) => ({
            display: 'flex',
            flexDirection: 'column',
            flexBasis: '900px',
            flexGrow: 1,
            flexShrink: 1,
            maxWidth,
            marginLeft: 'auto',
            marginRight: 'auto',
            minWidth: 0,
        }),
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
        },
        containerWithShell: {
            width: 'calc(100vw - 312px)',
            '@media all and (max-width: 1150px)': { width: 'calc(100vw - 175px)' },
        },
        headerBar: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 10px',
        },
        headerComponent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
        },
        mainContent: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            flex: 1,
            scrollbarColor: `unset ${theme.palette.semantic.colorForegroundDeEmp}`,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': { width: '0.5em' },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:vertical:hover': { backgroundColor: theme.palette.semantic.colorForegroundDisable },
        },
        loadingSpinner: { margin: 'auto' },
        tenantHeader: {
            display: 'inline-flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '18px 20px',
        },
        tenantText: {
            marginRight: '8px',
            fontWeight: 400,
            fontSize: '16px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
        banner: {
            width: '100%',
            marginBottom: '12px',
        },
        tenantChip: { paddingBottom: '2px' },
    }),
);

const UiPageContainer: React.FC<{
    children?: React.ReactNode;
    loading?: boolean;
    position?: 'left' | 'center';
    maxWidth?: string;
    banner?: React.ReactNode;
    header?: React.ReactNode;
    primaryActions?: React.ReactNode;
    tenantShellHidden?: boolean;
    disableGutter?: Array<'left' | 'right' | 'top' | 'bottom' | 'all'>;
}> = ({
    children,
    loading = false,
    position = 'center',
    maxWidth = '1600px',
    banner,
    header,
    primaryActions,
    tenantShellHidden = false,
    disableGutter,
}) => {

    const classes = useStyles({ maxWidth });
    const isOnPrem = useIsOnPrem();

    const { formatMessage: translate } = useIntl();

    const match = useRouteMatch<{ tenantId: string }>();
    const isMatchTenantHome = useRouteMatch({
        path: RouteNames.TenantHome,
        strict: true,
        exact: true,
    });
    const isMatchOrganizationHome = useRouteMatch({
        path: RouteNames.OrganizationAdminHome,
        strict: true,
    });

    const isCenter = useMemo(() => position === 'center', [ position ]);
    const { tenantId } = useMemo(() => ({ tenantId: match.params.tenantId }), [ match.params.tenantId ]);
    const isHomeRoute = useMemo(() => isMatchTenantHome || isMatchOrganizationHome, [ isMatchOrganizationHome, isMatchTenantHome ]);

    const {
        data: tenantData,
        isValidating,
        error,
    } = useSWR<ITenant, Error>(
        tenantId && isHomeRoute ? [ tenantByIdUrl, tenantId ] : null,
        getTenantById,
    );

    const loadingComponent = useMemo(() => loading || (tenantId && isHomeRoute && (!tenantData && !error) || isValidating),
        [ error, isHomeRoute, isValidating, loading, tenantData, tenantId ]);

    const paddingValues = useMemo(() => {
        let padding = '';

        if (disableGutter?.includes('all')) {
            return '0px';
        }

        padding += disableGutter?.includes('top') ? '0px ' : '20px ';
        padding += disableGutter?.includes('right') ? '0px ' : '20px ';
        padding += disableGutter?.includes('bottom') ? '0px ' : '20px ';
        padding += disableGutter?.includes('left') ? '0px' : '20px';

        return padding;
    }, [ disableGutter ]);

    return (
        <div className={clsx({
            [classes.container]: true,
            [classes.containerWithShell]: !tenantShellHidden && !process.buildConfigs.tenantShellHidden,
        })}>
            <div
                className={classes.root}
                data-cy='ui-page-container'>
                <div className={clsx(header && classes.headerBar)}>
                    {isMatchTenantHome && tenantData
                        ? <div className={classes.tenantHeader}>
                            <Typography
                                className={classes.tenantText}
                                data-cy='ui-page-container-tenant-name'
                                role="heading"
                                aria-level={1}
                            >
                                {tenantData.name}
                            </Typography>
                            {!isOnPrem && <div
                                className={classes.tenantChip}
                                data-cy='ui-page-container-tenant-chip'>
                                <Chip
                                    size='small'
                                    label={translate({ id: `CLIENT_${tenantData.region.toUpperCase()}` })}
                                    className="info" />
                            </div>}
                        </div>
                        : <div
                            className={classes.headerComponent}
                            role="heading"
                            aria-level={1}
                        >
                            {header}
                        </div>}
                    {!isMatchOrganizationHome && primaryActions}
                </div>
                <div className={classes.banner}>
                    {banner}
                </div>
                {loadingComponent
                    ? <CircularProgress
                        className={classes.loadingSpinner}
                        data-cy='ui-page-container-loading' />
                    : <div
                        className={classes.mainContent}
                        style={{
                            alignItems: isCenter ? 'center' : 'normal',
                            padding: paddingValues,
                        }}
                        data-cy='ui-page-container-content'>
                        {children}
                    </div>}
            </div>
        </div>
    );
};

export default UiPageContainer;
