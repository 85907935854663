import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    isStringEmpty,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import Add from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useHistory } from 'react-router';
import type { Row } from 'react-table';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import type { IDirectoryEntry } from '../../common/interfaces/cis/directory';
import type { IUserWithExplicitAllocations } from '../../common/interfaces/licenses';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import {
    deleteUserExplicitAllocations,
    getExplicitUserBundleCodes,
    getUsersExplicitAllocationsPage,
    userLicenseUrl,
} from '../../services/licensing/accountant/UserLicenseService';
import { trialServicesSwrKey } from '../../services/licensing/TrialPerSku';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiPaginatedGrid } from '../common/UiPaginatedGrid';
import UiPeoplePicker from '../common/UiPeoplePicker';
import { UserGroup } from '../common/UserGroups';
import type { IUserWithExplicitAllocationsPagedResult } from './interfaces/license';
import { UserLicenseSelectFilterComponent } from './subcomponents/UserLicenseSelectFilterComponent';

const useStyles = makeStyles(() =>
    createStyles({
        actionsAndGrid: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '650px',
            width: '100%',
        },
        peoplePicker: { minWidth: '350px' },
    }),
);

const EnableExternalUserLicense = getFeatureFlagValue(Features.EnableExternalUserLicense.name);

export default function PaginatedExplicitAllocationGridComponent() {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const getRoute = useRouteResolver();
    const history = useHistory();

    const [ refresh, setRefresh ] = useState(false);
    const [ selectedUserId, setSelectedUserId ] = useState<string | undefined>();
    const [ selectedLicenseCodes, setSelectedLicenseCodes ] = useState<string[] | undefined>();
    const isLicenseExpired = useLicenseExpired();

    const { data: explicitlyAllocatedLicenseCodes } = useSWR<string[], Error>(
        `${userLicenseUrl}/ublCodes/explicit`,
        getExplicitUserBundleCodes,
    );

    const userLicenseCodeFilterChange = useCallback((filteredCodes: string[]) => {
        setSelectedLicenseCodes(filteredCodes);
    }, []);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const getUserLicenseNames = useCallback(
        (data: string[], useExternalLicense: boolean) => {
            if (useExternalLicense) {
                return translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' });
            }
            return data?.length
                ? data.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ')
                : translate({ id: 'CLIENT_NO_LICENSE' });
        },
        [ translate ],
    );

    const getLastActive = useCallback(
        (lastLoginTime: Date | string | null) => {
            let date: Date | null = null;

            if (lastLoginTime != null) {
                if (lastLoginTime instanceof Date) {
                    date = lastLoginTime;
                } else {
                    date = new Date(lastLoginTime);
                }
            }
            return date == null || date.getTime() <= 0 ? (
                translate({ id: 'CLIENT_NO_DATA' })
            ) : (
                <FormattedDate
                    value={date}
                    year="numeric"
                    month="short"
                    day="numeric" />
            );
        },
        [ translate ],
    );

    const refreshGridAndActions = useCallback(async () => {
        setRefresh(true);
        mutate(`${userLicenseUrl}/ublCodes/explicit`);
        selectedUserId && mutate([ selectedUserId ]);
        await mutate(trialServicesSwrKey);
    }, [ setRefresh, selectedUserId ]);

    const openDeleteLicenseDialog = useCallback(
        async (row: Row<IUserWithExplicitAllocations>) => {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REMOVE_USER_FROM_THE_LIST' }),
                body: `${translate({ id: 'CLIENT_REMOVE_USER_FROM_THE_LIST_DETAILS' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
            });
            if (proceed) {
                await deleteUserExplicitAllocations([ row.original.id ]);
                createNotification(
                    translate(
                        { id: 'CLIENT_SUCCESS_DELETING_EXPLICIT_ALLOCATIONS' },
                        { 0: `${row.original.name} ${row.original.surname ?? ''}` },
                    ),
                );
                refreshGridAndActions();
            }
        },
        [ createDialog, createNotification, translate, refreshGridAndActions ],
    );

    useEffect(() => {
        history.listen(history => {
            if (history.state && (history.state as any)['refresh']) {
                refreshGridAndActions();
            }
        });
    }, [ history, refreshGridAndActions ]);

    const userBundleCodeToTranslationMap = useMemo(() => {
        const bundleToTranslation = new Map<string, string>();
        explicitlyAllocatedLicenseCodes?.forEach(ublCode =>
            bundleToTranslation.set(ublCode, translate({ id: `CLIENT_${ublCode}` })),
        );
        if (EnableExternalUserLicense) {
            bundleToTranslation.set('EXTERNAL_LICENSE', translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' }));
        } else if (explicitlyAllocatedLicenseCodes?.length === 0) {
            bundleToTranslation.set('NO_LICENSE', translate({ id: 'CLIENT_NO_LICENSE' }));
        }
        return bundleToTranslation;
    }, [ explicitlyAllocatedLicenseCodes, translate ]);

    return (
        <div className={classes.actionsAndGrid}>
            <UiPaginatedGrid<IUserWithExplicitAllocations, IUserWithExplicitAllocationsPagedResult>
                tableId="user-explicit-allocations-paginated-ui-grid"
                url={`${userLicenseUrl}/user/page`}
                dataCy="user-explicit-allocations-paginated-ui-grid"
                pagination
                fetcher={getUsersExplicitAllocationsPage}
                fetcherArgs={[ selectedUserId, ...(selectedLicenseCodes ?? []) ]}
                refreshData={{
                    current: refresh,
                    set: setRefresh,
                }}
                defaultPageSize={10}
                search
                SearchComponent={
                    <>
                        <UiPeoplePicker
                            className={classes.peoplePicker}
                            searchOnly
                            type="user"
                            helper={<></>}
                            inputPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_PLACEHOLDER' })}
                            ariaLabel={translate({ id: 'CLIENT_SEARCH_USER_PLACEHOLDER' })}
                            onChange={async (directoryEntries: IDirectoryEntry[]) => {
                                if (directoryEntries.length > 0 && directoryEntries[0]) {
                                    setSelectedUserId(directoryEntries[0].identifier);
                                } else {
                                    setSelectedUserId(undefined);
                                }
                                setRefresh(true);
                            }}
                        />
                        <UserLicenseSelectFilterComponent
                            filterMap={userBundleCodeToTranslationMap}
                            header={translate({ id: 'CLIENT_LICENSES' })}
                            filterChange={userLicenseCodeFilterChange}
                        />
                    </>
                }
                columns={[
                    {
                        accessor: 'name',
                        disableSortBy: true,
                        Header: translate({ id: 'CLIENT_NAME' }),
                        width: 20,
                        Cell: ({ row }) => (
                            <span>
                                {row.original.orphan
                                    ? translate({ id: 'CLIENT_NOT_APPLICABLE' })
                                    : `${row.original.name} ${row.original.surname ?? ''}`}
                            </span>
                        ),
                    },
                    {
                        accessor: 'email',
                        disableSortBy: true,
                        Header: translate({ id: 'CLIENT_EMAIL' }),
                        width: 25,
                        Cell: ({ row }) => (
                            <span>
                                {row.original.orphan || isStringEmpty(row.original.email) ? translate({ id: 'CLIENT_NOT_APPLICABLE' }) : row.original.email}
                            </span>
                        ),
                    },
                    {
                        accessor: 'licenses',
                        disableSortBy: true,
                        Header: translate({ id: 'CLIENT_LICENSES' }),
                        Cell: ({ row }) => (
                            <Tooltip
                                data-cy="licenses-tooltip"
                                arrow
                                placement="bottom"
                                title={getUserLicenseNames(
                                    row.original.userBundleLicenses,
                                    row.original.useExternalLicense,
                                )}
                            >
                                <span>
                                    {getUserLicenseNames(
                                        row.original.userBundleLicenses,
                                        row.original.useExternalLicense,
                                    )}
                                </span>
                            </Tooltip>
                        ),
                        width: 45,
                    },
                    {
                        accessor: 'lastInUse',
                        sortName: 'UpdatedAt',
                        Header: <Tooltip title={translate({ id: 'CLIENT_LAST_IN_USE' })}>
                            <span>
                                {translate({ id: 'CLIENT_LAST_IN_USE' })}
                            </span>
                        </Tooltip>,
                        Cell: ({ row }) => getLastActive(row.original.lastInUse),
                        width: 15,
                    },
                ]}
                hiddenColumns={[ 'id', 'orphan' ]}
                initialSort={[
                    {
                        id: 'UpdatedAt',
                        desc: false,
                    },
                ]}
                tableActions={[
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_ALLOCATE_LICENSES' }),
                        tooltip: translate({ id: 'CLIENT_ALLOCATE_LICENSES' }),
                        actionType: GridActionType.Main,
                        icon: <Add />,
                        click: () => {
                            history.push({ pathname: `${getRoute(RouteNames.LicensingUserAllocation)}/add` });
                        },
                        disable: () => isLicenseExpired,
                    },
                ]}
                rowActions={[
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                        tooltip: translate({ id: 'CLIENT_EDIT_USER_EXPLICIT_ALLOCATION' }),
                        actionType: GridActionType.Row,
                        icon: <AllocateLicenseIcon data-cy="ui-grid-edit-explicit-allocations" />,
                        click: row => {
                            history.push({
                                pathname: `${getRoute(RouteNames.LicensingUserAllocation)}/edit`,
                                state: { user: row.original },
                            });
                        },
                        disable: row =>
                            row?.original?.id === UserGroup.Everyone ||
                            row?.original?.id === UserGroup.Administrators ||
                            row?.original?.orphan ||
                            isLicenseExpired,
                    },
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_REMOVE_USER' }),
                        tooltip: translate({ id: 'CLIENT_REMOVE_USER' }),
                        actionType: GridActionType.Row,
                        icon: <DeleteForeverOutlinedIcon data-cy="ui-grid-delete-explicit-allocations" />,
                        click: openDeleteLicenseDialog,
                        disable: row =>
                            row?.original?.id === UserGroup.Everyone || row?.original?.id === UserGroup.Administrators,
                    },
                ]}
            />
        </div>
    );
}
