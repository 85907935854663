import {
    get,
    post,
} from '../utility/Requests.default';

export const kmsUrl = `${process.buildConfigs.identityApiBaseRoute}/CisKmsConfig`;

export interface IKmsConfig {
    id: string;
    configId: string;
    partitionId: string;
    subkey?: string;
    keyType: number;
    configuration: string;
    creationTime: Date;
    lastModificationTime: Date;
}

interface IKmsConfigPayload {
    partitionGlobalId: string;
    subKey?: string;
    keyType: number;
    configuration?: string;
}

export function getKmsConfig(url: string, partitionGlobalId: string) {
    return get<IKmsConfig>(kmsUrl, { urlParams: { partitionGlobalId } });
}

export function saveKmsConfig(url: string, payload: IKmsConfigPayload) {
    return post<IKmsConfig>(kmsUrl, { body: payload });
}
