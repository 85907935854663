import { portalTelemetry } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiDataContextProvider } from '@experiences/ui-common';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import InfoIcon from '@mui/icons-material/Info';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { renderRoutes } from 'react-router-config';

import { AboutAccountsLink } from '../../common/constants/documentation/DocumentationLinks.default';
import { AboutAccountsLinkOrchestrator } from '../../common/constants/documentation/DocumentationLinks.onprem';
import * as RouteNames from '../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import { isHostModeSelector } from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import UsersPageAADBanner from './subcomponents/UsersPageAADBanner';
import UsersPageHeaderTitle from './subcomponents/UsersPageHeaderTitle';
import UsersPageTabs from './subcomponents/UsersPageTabs';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        userRobotGroupDoc: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        userRobotGroupDocText: {
            fontWeight: 600,
            marginLeft: '5px',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': {
                textDecoration: 'none',
                color: theme.palette.semantic.colorForegroundLinkPressed,
            },
        },
    }),
}));

const UsersPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { logTrace } = useTelemetryHelper();

    const isHostMode = useSelector(isHostModeSelector);
    const organizationName = useOrganizationName();

    const isGroup = useRouteMatch(RouteNames.Groups);
    const isAADForm = useRouteMatch(RouteNames.AuthSettings);
    const getLocalizedLink = useDocumentationLinks();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    useEffect(() => {
        // Log from Portal Backend
        // https://uipath.atlassian.net/browse/PLT-10510
        logTrace('Admin.Users');

        // Log from Portal Frontend
        portalTelemetry.trackTrace({
            message: 'Admin.Users',
            severityLevel: SeverityLevel.Information,
        }, { revampEnabled: isAdminRevampEnabled });
        portalTelemetry.flush();
    }, [ isAdminRevampEnabled, logTrace ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.Users,
            name: translate({ id: isHostMode ? 'CLIENT_USERS' : 'CLIENT_ACCOUNTS_AND_GROUPS' }),
        },
    ];

    return (
        isAdminRevampEnabled ? <UiPageContainer
            banner={<UsersPageAADBanner />}
            position='left'
            header={AdminBreadCrumbs(breadCrumbLinks)}
        >
            <UsersPageTabs />
        </UiPageContainer> : <UiPanel
            licenseInGracePeriodBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseInGracePeriodBanner />}
            licenseExpiredBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseExpiredBanner />}
            trialPerSkuLicenseInGracePeriodBanners={!process.buildConfigs.disableUserLicensing && <UiTrialPerSkuLicenseInGracePeriodBanners />}
            licenseOverAllocationBanner={!process.buildConfigs.disableUserLicensing && <UiOverAllocationBanner />}
            header={{
                title: <UsersPageHeaderTitle />,
                actions:
                !isHostMode &&
                !isAADForm &&
                (!isGroup ? (
                    <div
                        className={classes.userRobotGroupDoc}
                        data-cy="users-vs-robot-account">
                        <InfoIcon className={classes.userRobotGroupDocText} />
                        <a
                            className={clsx(classes.userRobotGroupDocText, classes.a)}
                            target="_blank"
                            rel="noreferrer"
                            href={getLocalizedLink(
                                process.buildConfigs.documentationProduct === 'orchestrator'
                                    ? AboutAccountsLinkOrchestrator : AboutAccountsLink,
                            )}
                        >
                            {translate({ id: 'CLIENT_USERS_VS_ROBOT_ACCOUNT' })}
                        </a>
                    </div>
                ) : null),
            }}
            banner={<UsersPageAADBanner />}
            data-cy="users-panel"
        >
            <UsersPageTabs />
        </UiPanel>

    );
};

export default ({ route }: { route?: any }) => (
    <UiDataContextProvider<{ refresh: boolean }> initialState={{ refresh: false }}>
        <UsersPageComponent />
        {renderRoutes(route.routes)}
    </UiDataContextProvider>
);
