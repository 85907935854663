import {
    Features,
    getFeatureFlagConfig,
} from '@experiences/feature-flags';
import { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import useSWR from 'swr';

import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import type { ITenant } from '../../common/interfaces/tenant/tenant';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../services/organization/TenantService';

const useTenantServicesViewModel = () => {

    const adminRevampEnabled = useIsAdminRevampEnabled();

    const match = useRouteMatch<{ tenantId: string }>();

    const EnableInsightsExportConfig = getFeatureFlagConfig(Features.EnableInsightsExportConfig.name);

    const { tenantId } = useMemo(() => ({ tenantId: match.params.tenantId }), [ match ]);

    const { data: tenant } = useSWR<ITenant>(adminRevampEnabled ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null, getTenantById);

    const enableInsightsExport: boolean = useMemo(() =>
        (process.buildConfigs.name === 'k8s' && String(window.env?.ENABLE_REALTIME_MONITORING) === 'true')
        || (tenant?.region ? EnableInsightsExportConfig.split(',').includes(tenant.region) : false)
    , [ EnableInsightsExportConfig, tenant?.region ]);

    return {
        EnableInsightsExportConfig,
        tenantId,
        adminRevampEnabled,
        enableInsightsExport,
    };

};

export default useTenantServicesViewModel;
