import type {
    IEcommerceCheckoutComponentState,
    IOrderConfirmation,
} from '@experiences/ecommerce';
import {
    BuyProCheckout,
    BuyProConfirmation,
    BuyProIndividualProducts,
    BuyProLoading,
    BuyProPresets,
    EcommerceCheckoutWrapperComponent,
    EcommerceOrderConfirmationComponent,
    EcommerceOrderLoadingComponent,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import React, { useMemo } from 'react';
import type {
    RouteConfig,
    RouteConfigComponentProps,
} from 'react-router-config';
import { Redirect } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import AuditDialogComponent from '../../component/audit/AuditDialogComponent';
import AuditPageComponent from '../../component/audit/AuditPageComponent';
import { ConfigureSSOComponent } from '../../component/authsettings/subcomponents/ConfigureSSOComponent';
import CreateEditSAMLProvisioningRuleComponent from '../../component/authsettings/subcomponents/CreateEditSAMLProvisioningRuleComponent';
import EditPasswordPolicyComponent from '../../component/authsettings/subcomponents/EditPasswordPolicyComponent';
import SAMLProvisioningRulesComponent from '../../component/authsettings/subcomponents/SAMLProvisioningRulesComponent';
import EcommerceComponent from '../../component/ecommerce/EcommerceComponent';
import EcommerceIndividualProductsComponent from '../../component/ecommerce/EcommerceIndividualProductsComponent';
import EcommerceManagePlanComponent from '../../component/ecommerce/EcommerceManagePlanComponent';
import EcommerceMonthlyUpsellComponent from '../../component/ecommerce/EcommerceMonthlyUpsellComponent';
import EcommercePresetsPage from '../../component/ecommerce/subcomponents/EcommercePresetsPage/EcommercePresetsPage';
import ExternalApps from '../../component/externalApps/ExternalApps';
import ExternalApplicationsPATSettingsDrawerComponent from '../../component/externalApps/pat/ExternalApplicationsPATSettingsDrawerComponent';
import CreateEditExternalApplicationsPageComponent
    from '../../component/externalApps/subcomponents/CreateEditExternalApplicationsPageComponent';
import ExternalApplicationsScopesDrawerComponent
    from '../../component/externalApps/subcomponents/ExternalApplicationsScopesDrawerComponent';
import Licenses from '../../component/licensing/Licenses';
import ActivateLicenseOfflineComponent from '../../component/licensing/subcomponents/ActivateLicenseOfflineComponent';
import ActivateLicenseOnlineComponent from '../../component/licensing/subcomponents/ActivateLicenseOnlineComponent';
import AddEditGroupAllocationComponent from '../../component/licensing/subcomponents/AddEditGroupAllocationComponent';
import AddUserAllocationComponent from '../../component/licensing/subcomponents/AddUserAllocationComponent';
import DeactivateLicenseOfflineComponent from '../../component/licensing/subcomponents/DeactivateLicenseOfflineComponent';
import EcommerceSuccessComponent from '../../component/licensing/subcomponents/EcommerceSuccessComponent';
import EditUserAllocationComponent from '../../component/licensing/subcomponents/EditUserAllocationComponent';
import InheritedLicenseComponent from '../../component/licensing/subcomponents/InheritedLicenseComponent';
import ViewUserAllocationsInGroupComponent from '../../component/licensing/subcomponents/ViewUserAllocationsInGroupComponent';
import UpgradeToEnterpriseDialogComponent from '../../component/licensing/UpgradeToEnterpriseDialogComponent';
import OrganizationEmailSettingsRevampComponent from '../../component/organizationsettings/email/OrganizationEmailSettingsRevampComponent';
import OrganizationAdminHomeComponent from '../../component/organizationsettings/OrganizationAdminHome';
import OrganizationSettingsPageComponent from '../../component/organizationsettings/OrganizationSettingsPageComponent';
import AccountLogicalNameWarningDialogComponent
    from '../../component/organizationsettings/subcomponents/AccountLogicalNameWarningDialogComponent';
import VerifyEmailSettingsComponent from '../../component/organizationsettings/subcomponents/VerifyEmailSettingsComponent';
import AddOrganizationAccessPolicyMembersDrawerComponent
    from '../../component/securitySettings/accessPolicy/add/AddOrganizationAccessPolicyMembersDrawerComponent';
import ConfigureSSOPage from '../../component/securitySettings/ConfigureSSOPage';
import SecuritySettings from '../../component/securitySettings/SecuritySettings';
import AttributeMapping from '../../component/securitySettings/subcomponents/AttributeMapping';
import AddEditTenantTagsLabelsComponent from '../../component/tags/addedit/labels/AddEditTenantTagsLabelsComponent';
import AddEditTenantTagsPropertiesComponent from '../../component/tags/addedit/properties/AddEditTenantTagsPropertiesComponent';
import AddEditTenantTagsPropertiesDrawer from '../../component/tags/addedit/properties/AddEditTenantTagsPropertiesDrawer';
import TenantTagsComponent from '../../component/tags/base/TenantTagsViewComponent';
import TenantCreateComponent from '../../component/tenants/create/TenantCreate';
import ConfigureTenantLicensesComponent from '../../component/tenants/subcomponents/ConfigureTenantLicenseComponent';
import CreateEditTenantComponent from '../../component/tenants/subcomponents/CreateEditTenantComponent';
import LogExportComponent from '../../component/tenants/subcomponents/LogExportComponent';
import SkipServiceConfirmationDialogComponent from '../../component/tenants/subcomponents/SkipServiceConfirmationDialogComponent';
import ServicesUsagePageComponent from '../../component/tenants/subcomponents/usage/ServicesUsagePageComponent';
import TenantsPageComponent from '../../component/tenants/TenantsPageComponent';
import TenantAdminHome from '../../component/tenantsv2/TenantAdminHome';
import { TenantServicesAddComponent } from '../../component/tenantsv2/TenantServicesAddComponent';
import TenantServicesComponent from '../../component/tenantsv2/TenantServicesComponent';
import TenantSettingsComponent from '../../component/tenantsv2/TenantSettingsComponent';
import GroupsPageComponent from '../../component/users/GroupsPageComponent';
import GroupsPageComponentV2 from '../../component/users/GroupsPageComponentV2';
import RobotsPageComponent from '../../component/users/RobotsPageComponent';
import AddEditRobotAccountComponent from '../../component/users/subcomponents/AddEditRobotAccountComponent';
import AddEditUserComponent from '../../component/users/subcomponents/AddEditUserComponent';
import BulkInviteUserComponent from '../../component/users/subcomponents/BulkInviteUserComponent';
import CreateEditGroupComponent from '../../component/users/subcomponents/CreateEditGroupComponent';
import UsersPageComponent from '../../component/users/UsersPageComponent';
import CheckGuard from '../helpers/CheckGuard';

const useOrgAdminPrivateShellRoutes = () => {
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);
    const EnableGridRefactor = useFeatureFlagValue(Features.EnableGridRefactor.name);
    const EnablePortalTagManagement = useFeatureFlagValue(Features.EnablePortalTagManagement.name);
    const EnableOrganizationAccessPolicy = useFeatureFlagValue(Features.EnableOrganizationAccessPolicy.name);
    const EnableEcommercePlanSelectionRevampM0 = useFeatureFlagValue(Features.EnableEcommercePlanSelectionRevampM0.name);
    const EnableAttributeMapping = useFeatureFlagValue(Features.EnableAttributeMapping.name);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const {
        isAccountLicenseEnterpriseOrPro, isMonthlyUpsellApplicable,
    } = useCheckLicense();

    const getRoute = useRouteResolver();

    return useMemo<RouteConfig[]>(() => [
        {
            path: RouteNames.TenantSettings,
            render: props => CheckGuard(isAdminRevampEnabled, <TenantSettingsComponent {...props} />),
        },
        {
            path: RouteNames.TenantServices,
            render: props => CheckGuard(isAdminRevampEnabled, <TenantServicesComponent {...props} />),
            routes: (function() {
                const subroutes: any[] = [
                    {
                        path: RouteNames.TenantServicesAdd,
                        component: TenantServicesAddComponent,
                    },
                    {
                        path: RouteNames.TenantConfigureService,
                        component: ConfigureTenantLicensesComponent,
                    },
                    {
                        path: RouteNames.TenantServiceLogExport,
                        component: LogExportComponent,
                    },
                ];
                return subroutes;
            })(),
        },
        ...(EnablePortalTagManagement ? [
            {
                path: RouteNames.TenantTagsPropertiesAddEdit,
                component: AddEditTenantTagsPropertiesComponent,
                routes: [
                    {
                        path: RouteNames.TenantTagsPropertiesDrawer,
                        component: AddEditTenantTagsPropertiesDrawer,
                    },
                ],
            },
            {
                path: RouteNames.TenantTagsLabelsAddEdit,
                component: AddEditTenantTagsLabelsComponent,
            },
            {
                path: RouteNames.TenantTags,
                render: (props: RouteConfigComponentProps) => CheckGuard(isAdminRevampEnabled, <TenantTagsComponent {...props} />),
            },
        ] : []),
        {
            path: RouteNames.TenantHome,
            render: () => CheckGuard(isAdminRevampEnabled, <TenantAdminHome />),
        },
        {
            path: RouteNames.Services,
            render: props => isAdminRevampEnabled
                ? <Redirect to={getRoute(RouteNames.OrganizationAdminHome)} />
                : <TenantsPageComponent {...props} />,
            routes: (function() {
                const subroutes: any[] = [
                    {
                        path: RouteNames.TenantCreateEdit,
                        component: CreateEditTenantComponent,
                        routes: [
                            {
                                path: RouteNames.TenantCreateEditSkip,
                                component: SkipServiceConfirmationDialogComponent,
                            },
                        ],
                    },
                    {
                        path: RouteNames.TenantConfigure,
                        component: ConfigureTenantLicensesComponent,
                        routes: [
                            {
                                path: RouteNames.TenantConfigureSkip,
                                component: SkipServiceConfirmationDialogComponent,
                            },
                        ],
                    },
                    {
                        path: RouteNames.OrchLogExport,
                        component: LogExportComponent,
                    },
                ];

                return subroutes;
            })(),
        },
        {
            path: RouteNames.Users,
            component: UsersPageComponent,
            routes: (function() {
                const subroutes = [
                    {
                        path: RouteNames.UserAddEdit,
                        component: AddEditUserComponent,
                    },
                    {
                        path: RouteNames.Groups,
                        component: EnableGridRefactor ? GroupsPageComponentV2 : GroupsPageComponent,
                    },
                    {
                        path: RouteNames.UsersUserAddLicenseAllocation,
                        component: AddUserAllocationComponent,
                    },
                    {
                        path: RouteNames.UsersUserEditLicenseAllocation,
                        component: EditUserAllocationComponent,
                    },
                    {
                        path: RouteNames.UsersUserLicenseInheritance,
                        component: InheritedLicenseComponent,
                    },
                    {
                        path: RouteNames.BulkUserInvite,
                        component: BulkInviteUserComponent,
                    },
                ];

                subroutes.push({
                    path: RouteNames.Robots,
                    component: RobotsPageComponent,
                });
                return subroutes;
            })(),
        },
        {
            path: RouteNames.SecuritySettingsConfigure,
            component: ConfigureSSOPage,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRules,
            exact: true,
            component: SAMLProvisioningRulesComponent,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRulesAddEdit,
            component: CreateEditSAMLProvisioningRuleComponent,
        },
        {
            path: RouteNames.SecuritySettingsAttributeMapping,
            exact: true,
            render: () =>
                CheckGuard(
                    EnableAttributeMapping,
                    <AttributeMapping />
                ),
        },
        {
            path: RouteNames.Groups,
            component: UsersPageComponent,
            routes: [
                {
                    path: RouteNames.GroupsAddEdit,
                    component: CreateEditGroupComponent,
                },
                {
                    path: RouteNames.GroupAllocationRule,
                    component: AddEditGroupAllocationComponent,
                },
            ],
        },
        {
            path: RouteNames.Robots,
            component: UsersPageComponent,
            routes: [
                {
                    path: RouteNames.RobotsAddEdit,
                    component: AddEditRobotAccountComponent,
                },
            ],
        },
        {
            path: RouteNames.Licensing,
            component: Licenses,
            routes: [
                {
                    path: RouteNames.LicensingUpgradeToEnterprise,
                    component: UpgradeToEnterpriseDialogComponent,
                },
                {
                    path: RouteNames.LicensingUserAddLicenseAllocation,
                    component: AddUserAllocationComponent,
                },
                {
                    path: RouteNames.LicensingUserEditLicenseAllocation,
                    component: EditUserAllocationComponent,
                },
                {
                    path: RouteNames.LicensingUserLicenseInheritance,
                    component: InheritedLicenseComponent,
                },
                {
                    path: RouteNames.LicensingGroupAllocationAddEdit,
                    component: AddEditGroupAllocationComponent,
                },
                {
                    path: RouteNames.LicensingGroupAllocationView,
                    component: ViewUserAllocationsInGroupComponent,
                },
                {
                    path: RouteNames.LicensingTenantConfigure,
                    component: ConfigureTenantLicensesComponent,
                },
                {
                    path: RouteNames.ActivateOffline,
                    component: ActivateLicenseOfflineComponent,
                },
                {
                    path: RouteNames.BuyProSuccess,
                    render: () => CheckGuard(EnableEcommerceIntegration, <EcommerceSuccessComponent />),
                },
                {
                    path: RouteNames.LicensingActivateLicenseOnline,
                    component: isMonthlyUpsellApplicable ? EcommerceMonthlyUpsellComponent : ActivateLicenseOnlineComponent,
                },
                {
                    path: RouteNames.DeactivateOffline,
                    component: DeactivateLicenseOfflineComponent,
                },
                {
                    path: RouteNames.AuthSettingsDeprecated,
                    component: (props: RouteConfigComponentProps<any>) => (
                        <Redirect to={RouteNames.AuthSettings.replace(':accountName', props.match.params.accountName)} />
                    ),
                },
            ],
        },
        {
            path: RouteNames.OrganizationAdminHome,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <OrganizationAdminHomeComponent />),
        },
        {
            path: RouteNames.TenantCreate,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <TenantCreateComponent />),
        },
        {
            path: BuyProCheckout,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                    <EcommerceCheckoutWrapperComponent
                        {...(props as {
                            location?: { state?: IEcommerceCheckoutComponentState };
                        })}
                    />
                    ,
                ),
        },
        {
            path: BuyProLoading,
            render: () => CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(), <EcommerceOrderLoadingComponent />),
        },
        {
            path: BuyProConfirmation,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(EnableEcommerceIntegration,
                    <EcommerceOrderConfirmationComponent
                        {...(props as {
                            location?: {
                                state?: {
                                    orderConfirmation: IOrderConfirmation;
                                    isLicenseDecreaseExecuted: boolean;
                                    nextPaymentDate: string;
                                };
                            };
                        })}
                    />
                    ,
                ),
        },
        {
            path: [ RouteNames.BuyPro, RouteNames.BuyEnterprise ],
            render: (props: RouteConfigComponentProps<any>) => EnableEcommercePlanSelectionRevampM0 ? (
                <Redirect to={props.location.pathname.replace(/\/buypro|\/buyenterprise/, '/buyPresets')} />
            ) :
                CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(), <EcommerceComponent {...props} />),
        },
        {
            path: BuyProPresets,
            render: (props: RouteConfigComponentProps<any>) =>
                !EnableEcommercePlanSelectionRevampM0 ? (
                    <Redirect to={props.location.pathname.replace('/buyPresets', '/buypro')} />
                ) :
                    CheckGuard(EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                        <EcommercePresetsPage {...props} />),
        },
        {
            path: [ BuyProIndividualProducts ],
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(EnableEcommercePlanSelectionRevampM0 && EnableEcommerceIntegration && !isAccountLicenseEnterpriseOrPro(),
                    <EcommerceIndividualProductsComponent {...props} />),
        },
        {
            path: RouteNames.ManagePlan,
            render: (props: RouteConfigComponentProps<any>) =>
                CheckGuard(
                    EnableEcommerceIntegration && isAccountLicenseEnterpriseOrPro(),
                    <EcommerceManagePlanComponent {...props} />,
                ),
        },
        {
            path: RouteNames.OrganizationSettings,
            component: OrganizationSettingsPageComponent,
            routes: [
                {
                    path: RouteNames.OrganizationSettingsWarning,
                    component: AccountLogicalNameWarningDialogComponent,
                },
            ],
        },
        {
            path: RouteNames.OrganizationSettingsAdvanced,
            render: props =>
                CheckGuard(isAdminRevampEnabled, <OrganizationSettingsPageComponent {...props} />),
        },
        {
            path: RouteNames.EmailSettings,
            component: OrganizationEmailSettingsRevampComponent,
            routes: [
                {
                    path: RouteNames.EmailSettingsTest,
                    component: VerifyEmailSettingsComponent,
                },
            ],
        },
        {
            path: RouteNames.LoginCustomization,
            component: OrganizationSettingsPageComponent,
        },
        {
            path: RouteNames.AuditLogs,
            component: AuditPageComponent,
            routes: [
                {
                    path: RouteNames.AuditLogDialog,
                    component: AuditDialogComponent,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplications,
            exact: true,
            component: ExternalApps,
        },
        {
            path: RouteNames.ExternalApplicationsPAT,
            component: ExternalApps,
            routes: [
                {
                    path: RouteNames.ExternalApplicationsPATSettings,
                    component: ExternalApplicationsPATSettingsDrawerComponent,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplicationAddEdit,
            component: CreateEditExternalApplicationsPageComponent,
            routes: [
                {
                    path: RouteNames.ExternalApplicationScopes,
                    component: ExternalApplicationsScopesDrawerComponent,
                },
            ],
        },
        {
            path: RouteNames.ServicesUsage,
            component: ServicesUsagePageComponent,
            routes: [
                {
                    path: RouteNames.TenantServicesUsageAllocation,
                    component: ConfigureTenantLicensesComponent,
                },
            ],
        },
        {
            path: RouteNames.SessionPolicy,
            render: props => CheckGuard(isAdminRevampEnabled, <SecuritySettings {...props} />),
        },
        {
            path: RouteNames.OrganizationAccessPolicy,
            render: (props: RouteConfigComponentProps<any>) => CheckGuard(
                EnableOrganizationAccessPolicy,
                <SecuritySettings {...props} />,
            ),
            routes: [
                {
                    path: RouteNames.OrganizationAccessPolicyAdd,
                    component: AddOrganizationAccessPolicyMembersDrawerComponent,
                },
            ],
        },
        {
            path: RouteNames.SecuritySettings,
            render: props => CheckGuard(isAdminRevampEnabled, <SecuritySettings {...props} />),
            routes: [
                {
                    path: RouteNames.ConfigureSSO,
                    component: ConfigureSSOComponent,
                },
                {
                    path: RouteNames.AuthSettingsPasswordPolicy,
                    component: EditPasswordPolicyComponent,
                },
            ],
        },
    ], [
        EnableAttributeMapping,
        EnableEcommerceIntegration,
        EnableEcommercePlanSelectionRevampM0,
        EnableGridRefactor,
        EnableOrganizationAccessPolicy,
        EnablePortalTagManagement,
        getRoute,
        isAccountLicenseEnterpriseOrPro,
        isAdminRevampEnabled,
        isMonthlyUpsellApplicable,
    ]);
};

export default useOrgAdminPrivateShellRoutes;
