import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function useStaticLogout() {
    const history = useHistory();

    const logout = useCallback((returnUrl?: string) => {
        const returnUrlRoute = returnUrl ? `?returnUrl=${encodeURIComponent(returnUrl)}` : '';
        const logoutUrl = `/portal_/logout${returnUrlRoute}`;
        history.push(logoutUrl);
    }, []);

    return logout;
}
