import { useShowDialog } from '@experiences/util';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router';

import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import TenantStatusDialogBody from '../../tenants/subcomponents/TenantStatusDialogBody';
import { TenantStatusConstants } from '../../tenants/TenantConstants';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import UiAlertBanner from '../UiAlertBanner';

const useStyles = makeStyles(() =>
    createStyles({
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignContent: 'center',
            alignItems: 'center',
        },
        link: { marginLeft: '8px' },
    }),
);

export const UiTenantStatusBanner: React.FC<{
    status: string | undefined; tenantName: string | undefined; dataCy?: string;
}> = ({
    status, tenantName, dataCy,
}) => {

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const createDialog = useShowDialog();
    const tenantOperations = useTenantOperations();

    const match = useRouteMatch<{ tenantId: string }>();
    const { tenantId } = useMemo<{ tenantId: string }>(
        () => ({ tenantId: match.params.tenantId }),
        [ match ],
    );
    const { getTenantData } = useTenantsContext();

    const enableTenant = useCallback(
        async (data) => await createDialog({
            title: translate({ id: 'CLIENT_TENANT_CONFIRMATION_ENABLE_HEADER' }),
            icon: 'warning',
            customDialogContent: TenantStatusDialogBody,
            customDialogContentProps: {
                tenant: data,
                status: TenantStatusConstants.ENABLE,
                tenantStatusModify: tenantOperations.tenantStatusModify,
            },
        }),
        [ createDialog, translate, tenantOperations.tenantStatusModify ],
    );

    const bannerText = useMemo(() => {
        switch (status?.toLocaleLowerCase()) {
            case TenantStatusConstants.UPDATING.toLowerCase(): return translate({ id: 'CLIENT_TENANT_STATUS_UPDATING' });
            case TenantStatusConstants.DISABLED.toLowerCase():
                return translate({ id: 'CLIENT_TENANT_STATUS_DISABLED' }, { tenant: tenantName });
            default: return '';
        }
    }, [ status, tenantName, translate ]);

    return (
        <>
            {bannerText &&
                <UiAlertBanner
                    dataCy={dataCy}
                    type="warning"
                    closeable={false}
                    enterpriseTrialAlertBar>
                    <div className={classes.banner}>
                        {bannerText}
                        {status?.toLocaleLowerCase() === TenantStatusConstants.DISABLED.toLowerCase() &&
                        <Button
                            size='small'
                            onClick={() => enableTenant(getTenantData(tenantId))}>
                            {translate({ id: 'CLIENT_ENABLE' })}
                        </Button>}
                    </div>
                </UiAlertBanner>}
        </>
    );
};
