import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import type { FC } from 'react';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import { isHostModeSelector } from '../../../store/selectors';
import GroupsPageComponent from '../GroupsPageComponent';
import GroupsPageComponentV2 from '../GroupsPageComponentV2';
import RobotsPageComponent from '../RobotsPageComponent';
import UsersCISPageComponent from '../UsersCISPageComponent';

const UserGroupsTab = {
    USERS: 'users',
    ROBOTS: 'robots',
    GROUPS: 'groups',
    AZURE: 'authsettings',
};

const TabPanel: React.FC<{ value: string; gridRefactorEnabled: boolean }> = ({
    value, gridRefactorEnabled,
}) => {
    switch (value) {
        case UserGroupsTab.USERS:
            return <UsersCISPageComponent />;
        case UserGroupsTab.ROBOTS:
            return <RobotsPageComponent />;
        case UserGroupsTab.GROUPS:
            return gridRefactorEnabled ? <GroupsPageComponentV2 /> : <GroupsPageComponent />;
    }

    return null;
};

const UsersPageTabs: FC = () => {
    const { formatMessage: translate } = useIntl();

    const DisableUsersTab = useFeatureFlagValue(Features.DisableUsersTab.name);
    const EnableGridRefactor = useFeatureFlagValue(Features.EnableGridRefactor.name);

    const history = useHistory();
    const getRoute = useRouteResolver();
    const isGroup = useRouteMatch(RouteNames.Groups);
    const isRobot = useRouteMatch(RouteNames.Robots);
    const isAADForm = useRouteMatch(RouteNames.AuthSettings);
    const isHostMode = useSelector(isHostModeSelector);

    const [ tabValue, setTabValue ] = useState(
        isGroup
            ? UserGroupsTab.GROUPS
            : isAADForm
                ? UserGroupsTab.AZURE
                : isRobot
                    ? UserGroupsTab.ROBOTS
                    : UserGroupsTab.USERS,
    );

    const handleChange = useCallback((_, newValue: string) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default">
                {!DisableUsersTab && (
                    <Tab
                        value={UserGroupsTab.USERS}
                        label={translate({ id: 'CLIENT_USERS' })}
                        onClick={() => history.push(getRoute(RouteNames.Users))}
                        data-cy="users-tab"
                        className="default"
                    />
                )}
                {!isHostMode && (
                    <Tab
                        value={UserGroupsTab.ROBOTS}
                        label={translate({ id: 'CLIENT_ROBOT_ACCOUNTS' })}
                        onClick={() => history.push(getRoute(RouteNames.Robots))}
                        data-cy="robots-tab"
                        className="default"
                    />
                )}
                {!isHostMode && (
                    <Tab
                        value={UserGroupsTab.GROUPS}
                        label={translate({ id: 'CLIENT_GROUPS' })}
                        onClick={() => history.push(getRoute(RouteNames.Groups))}
                        data-cy="groups-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel
                value={tabValue}
                gridRefactorEnabled={EnableGridRefactor} />
        </>
    );
};

export default UsersPageTabs;
