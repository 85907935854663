import { UiLoader } from '@experiences/ui-common';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useLocation } from 'react-router';

import useAuthentication from './hooks/Authentication';

const Logout: React.FC = () => {
    const location = useLocation();
    const { logout } = useAuthentication();

    const returnUrl = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const returnUrl = params.get('returnUrl');
        return returnUrl ? decodeURIComponent(returnUrl) : undefined;
    }, [ location ]);

    useEffect(() => {
        logout({ returnUrl });
    // eslint-disable-next-line
  }, []);

    return <UiLoader type="full" />;
};

export default Logout;
