import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiDividerWithText } from '@experiences/ui-common';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
    Button,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useState,
} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';

import { notificationType } from '../../../common/constants/Constant';
import { inviteUsers } from '../../../services/identity/UserService';

const RESEND_INVITE_TIMEOUT_INTERVAL_SECONDS = 60;

const useStyles = makeStyles(theme =>
    createStyles({
        inviteUserUrlBox: {
            width: '100%',
            marginTop: '8px',
        },
        dialogBody: { minWidth: '500px' },
        copyInvitationLink: {
            marginTop: '16px',
            marginBottom: '33px',
            color: theme.palette.semantic.colorPrimaryFocused,
            display: 'flex',
            alignItems: 'center',
            fontWeight: 600,
            paddingLeft: '0px',
        },
        copyInvitationLinkIcon: { marginRight: '10px' },
        divider: { marginTop: '16px' },
        sendEmailAgain: {
            padding: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        sendEmailAgainButton: {
            color: theme.palette.semantic.colorPrimaryFocused,
            fontWeight: 600,
        },
        sendEmailAgainIcon: { marginRight: '10px' },
    }),
);

const ResendInvitationDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog,
    row,
    orgLanguage,
    disabledEmails,
    setDisabledEmails,
    loginProvider,
    inviteUrl,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage: translate } = useIntl();
    const [ copiedText, setCopiedText ] = useState('');

    const findDisabledEmail = useCallback(() => !!disabledEmails.find((email: string) => email === row?.original?.email), [ disabledEmails, row ]);

    const disableEmailSend = useCallback(() => {
        setDisabledEmails((emails: string[]) => [ ...emails, row?.original?.email ]);
        setTimeout(() => {
            setDisabledEmails((emails: string[]) => emails.filter((disabled: string) => disabled !== row?.original?.email));
        }, RESEND_INVITE_TIMEOUT_INTERVAL_SECONDS * 1000);
    }, [ row, setDisabledEmails ]);

    const sendEmailAgain = useCallback(async () => {
        try {
            await inviteUsers(
                [
                    {
                        name: row.original.name,
                        email: row.original.email,
                        groupIDs: row.original.groupIDs,
                    },
                ],
                loginProvider,
                orgLanguage,
            );
            disableEmailSend();
            enqueueSnackbar(translate({ id: 'CLIENT_RESEND_SUCCESS' }), { variant: notificationType.SUCCESS as any });
            closeDialog(true);
        } catch (error) {
            setDisabledEmails((emails: string[]) => emails.filter((disabled: string) => disabled !== row?.original?.email));
            enqueueSnackbar(translate({ id: 'CLIENT_RESEND_FAILED' }), { variant: notificationType.ERROR as any });
        }
    }, [ translate, closeDialog, enqueueSnackbar, orgLanguage, row, setDisabledEmails, loginProvider, disableEmailSend ]);

    return (
        <div className={classes.dialogBody}>
            <Typography data-cy="copy-to-clipboard-reminder">
                {translate({ id: 'CLIENT_COPY_TO_CLIPBOARD_REMINDER' })}
            </Typography>
            <TextField
                className={classes.inviteUserUrlBox}
                variant="outlined"
                value={inviteUrl}
                data-cy="invite-user-url"
                multiline
                disabled
                inputProps={{ 'aria-label': translate({ id: 'CLIENT_INVITATION_LINK_FIELD_ARIA' }) }}
            />

            <CopyToClipboard
                text={inviteUrl}
                onCopy={() => setCopiedText('copied')}>
                <Tooltip
                    title={
                        copiedText === 'copied'
                            ? translate({ id: 'CLIENT_INVITATION_LINK_COPIED' })
                            : translate({ id: 'CLIENT_COPY_TO_CLIPBOARD' })
                    }
                    placement="top"
                >
                    <Button
                        className={classes.copyInvitationLink}
                        data-cy="copy-invitation-link"
                        id="copyInvitationLink">
                        <FileCopyOutlinedIcon
                            color="primary"
                            className={classes.copyInvitationLinkIcon}
                            fontSize="small"
                            aria-labelledby="copyInvitationLink"
                            data-cy="copy-invitation-link-icon"
                        />
                        {translate({ id: 'CLIENT_COPY_INVITATION_LINK' })}
                    </Button>
                </Tooltip>
            </CopyToClipboard>
            <UiDividerWithText text={translate({ id: 'CLIENT_OR' })} />

            <Tooltip
                arrow
                title={findDisabledEmail() ? translate({ id: 'CLIENT_RESEND_COOLDOWN' }) : ''}>
                <div
                    className={classes.sendEmailAgain}
                    data-cy="send-email-again">
                    <Button
                        className={classes.sendEmailAgainButton}
                        data-cy="send-email-again-button"
                        disabled={findDisabledEmail()}
                        onClick={() => sendEmailAgain()}
                    >
                        <MailOutlineIcon className={classes.sendEmailAgainIcon} />
                        {translate({ id: 'CLIENT_SEND_EMAIL_AGAIN_TEXT' })}
                    </Button>
                </div>
            </Tooltip>
        </div>
    );
};

export default ResendInvitationDialogBody;
