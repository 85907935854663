import { UiLoader } from '@experiences/ui-common';
import {
    hasAuthParams,
    useAuth,
} from '@uipath/auth-react';
import React from 'react';
import {
    Redirect,
    useParams,
} from 'react-router';

export default function AuthCallback() {
    const { error } = useAuth();
    const { accountName } = useParams<{ accountName?: string }>();

    // redirect if user is stuck or error has occurred
    if (!hasAuthParams() || error) {
        return <Redirect to={
            `${accountName ? `/${accountName}` : ''}/portal_/cloudrpa`
        }
        />;
    }

    return <UiLoader type="full" />;
}
