import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

const ShowPlatformManagementLicensingScopes = getFeatureFlagValue(Features.ShowPlatformManagementLicensingScopes.name);

export const ResourceToHiddenScopes: Map<string, string[]> = new Map<string, string[]>([
    [
        'PlatformManagement',
        !ShowPlatformManagementLicensingScopes ? [ 'PM.License', 'PM.License.Write', 'PM.License.Read' ] : [],
    ],
]);
