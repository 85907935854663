import InfoIcon from '@mui/icons-material/Info';
import {
    Fade,
    Tooltip,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import type { FieldError } from 'react-hook-form';
import type { DeepMap } from 'react-hook-form/dist/types/utils';
import { useIntl } from 'react-intl';

import type { IOrganizationLicenseProduct } from '../interfaces/license';
import type { IConfigureOrganizationLicenses } from './AddEditOrganizationLicenseComponent';

const useStyles = makeStyles(() =>
    createStyles({
        overAllocatedWarning: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
);

const ProductQuantityHelperText: React.FC<{
    errors: DeepMap<IConfigureOrganizationLicenses, FieldError>;
    product: IOrganizationLicenseProduct;
}> = ({
    errors, product,
}) => {
    const { formatMessage: translate } = useIntl();
    const theme = useTheme();
    const classes = useStyles();
    const maxAvailable = useCallback((currentQuantity: number, available: number) => available + currentQuantity > 0 ? available + currentQuantity : 0, []);

    return (
        <>
            {(errors.products &&
                ((errors.products![`${product.code}`]?.type === 'required' &&
                    translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                    (errors.products![`${product.code}`]?.type === 'min' &&
                        translate(
                            { id: 'CLIENT_WARN_LICENSE' },
                            {
                                0: `${product.consumedQuantity}`,
                                1: `${maxAvailable(product.quantity, product.available)}`,
                            },
                        )) ||
                    (errors.products![`${product.code}`]?.type === 'max' &&
                        product.available > 0 &&
                        product.type === 'UNLIMITED' &&
                        translate({ id: 'CLIENT_WARN_LICENSE' }, {
                            0: `${product.consumedQuantity}`,
                            1: '1',
                        })) ||
                    (errors.products![`${product.code}`]?.type === 'max' &&
                        product.available > 0 &&
                        translate(
                            { id: 'CLIENT_WARN_LICENSE' },
                            {
                                0: `${product.consumedQuantity}`,
                                1: `${maxAvailable(product.quantity, product.available)}`,
                            },
                        )) ||
                    (errors.products![`${product.code}`]?.type === 'max' &&
                        product.available === 0 &&
                        product.quantity > 0 &&
                        translate(
                            { id: 'CLIENT_WARN_LICENSE' },
                            {
                                0: `${product.consumedQuantity}`,
                                1: `${product.quantity}`,
                            },
                        )) ||
                    (errors.products![`${product.code}`]?.type === 'max' &&
                        product.available === 0 &&
                        translate({ id: 'CLIENT_WARN_NO_LICENSE' })))) ||
                ((product.available as number) < 0 && (
                    <div className={clsx(classes.overAllocatedWarning)}>
                        {translate(
                            { id: 'CLIENT_TENANTS_LICENSE_OVERALLOCATED' },
                            { 0: Math.abs(product.available as number) },
                        )}
                        <Tooltip
                            data-cy="overallocation-tooltip"
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={translate({ id: 'CLIENT_LICENSE_OVERALLOCATION_TOOLTIP' })}
                            arrow
                        >
                            <InfoIcon
                                style={{
                                    color: theme.palette.semantic.colorInfoForeground,
                                    paddingLeft: '3px',
                                    margin: '1px',
                                }}
                            />
                        </Tooltip>
                    </div>
                )) ||
                `${product.available} ${translate({ id: 'CLIENT_AVAILABLE' })}`}
        </>
    );
};

export default ProductQuantityHelperText;
