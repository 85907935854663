import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';

import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { AdvancedSettingsTabComponent } from './AdvancedSettingsTabContent';
import GeneralSettingsComponent from './GeneralSettingsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        divider: {
            color: theme.palette.semantic.colorBorderDeEmp,
            width: '518px',
        },
        generalSettings: {
            width: '100%',
            height: 'calc(100% - 40px)',
        },
        section: {
            width: 'inherit',
            marginTop: '24px',
        },
    }),
);

const GeneralSettingsTabContent: FC = () => {
    const classes = useStyles();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <div
            className={classes.generalSettings}
            data-cy="general-settings-page">
            <GeneralSettingsComponent />

            {!isAdminRevampEnabled && <>
                <Divider className={classes.divider} />
                <div className={clsx(classes.section)}>
                    <AdvancedSettingsTabComponent />
                </div>
            </>}
        </div>
    );
};

export default GeneralSettingsTabContent;
