import { AccountLicense } from '@experiences/constants';
import {
    BuyProLoading,
    useIsEcommerceEnabled,
} from '@experiences/ecommerce';
import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    Pendo,
    UiStorage,
    useRouteResolver,
} from '@experiences/util';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import {
    Badge,
    FormControlLabel,
    IconButton,
    Popover,
    Switch,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalDivider } from '@uipath/portal-shell-react';
import type { PropsWithChildren } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import {
    matchPath,
    useLocation,
} from 'react-router-dom';

import ProtectedRoute from '../../auth/ProtectedRoute';
import * as RouteNames from '../../common/constants/RouteNames';
import { useAdminNavigationRoute } from '../../common/hooks/useAdminNavigationRoute';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import useIsNoShowLeftNav from '../../common/hooks/useIsNoShowLeftNav';
import useProductName from '../../common/hooks/useProductName.default';
import type { ISettingRoute } from '../../common/interfaces/route';
import adminRevampIcon from '../../images/adminRevampIcon.svg';
import { toggleAdminRevamp } from '../../store/action/AdminRevampToggleAction';
import { markAnnouncementRead } from '../../store/action/AnnouncementAction';
import {
    accountType,
    adminRevampSelector,
    announcementsList,
    isHostModeSelector,
    unReadAnnouncementCount,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import {
    isSubRoute,
    useIsSettingsRouteMatched,
    useSettingsRoutes,
} from '../../util/RouteUtil';
import OrganizationSwitcherDialogComponent from '../authentication/organizationSwitcher/OrganizationSwitcherDialogComponent';
import UiLicenseExpiredBanner from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiTrialPerSkuLicenseInGracePeriodBanners from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import { EcommercePaymentFailedBannerComponent }
    from '../ecommerce/subcomponents/EcommercePaymentFailedBanner/EcommercePaymentFailedBannerComponent';
import { EcommerceAlertBarComponent } from './EcommerceAlertBarComponent';
import { Notifications } from './Notifications';
import { SecondaryNav } from './SecondaryNav';
import TenantShell from './TenantShell';

const EnableHelpCopyright = getFeatureFlagValue(Features.EnableHelpCopyright.name);

export const useStyles = makeStyles(theme =>
    createStyles({
        leftRail: { height: '100%' },
        content: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: theme.palette.semantic.colorBackground,
        },
        appContent: {
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            overflow: 'auto',
            boxSizing: 'border-box',
            height: '100%',
        },
        popoverDefaults: {
            width: '339px',
            height: 'auto',
            border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            boxShadow: '0px 9px 46px rgba(0, 0, 0, 0.12)',
        },
        version: {
            color: theme.palette.semantic.colorForegroundDisable,
            paddingBottom: '12px',
        },
        notificationsIcon: {
            height: '48px',
            display: 'flex',
            alignSelf: 'center',
        },
        copyRightSlot: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: '-27px',
        },
        copyRightText: { color: theme.palette.semantic.colorForegroundDeEmp },
        hidden: { display: 'none' },
        adminToggle: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '8px',
        },
        adminToggleText: {
            fontWeight: 600,
            color: theme.palette.semantic.colorForeground,
        },
    }),
);

const PrivateShell: React.FC = props => {
    const classes = useStyles();
    const history = useHistory();
    const {
        productName, showTrademark,
    } = useProductName();
    const { formatMessage: translate } = useIntl();
    const isNoLeftNavRoute = useIsNoShowLeftNav();
    const dispatch = useDispatch();
    const location = useLocation();
    const getRoute = useRouteResolver();

    const { logEvent } = useTelemetryHelper();

    const [ notificationsAnchorEl, setNotificationsAnchorEl ] = useState<any>(null);

    const apShell = document.getElementById('apollo-shell')! as HTMLApShellElement;
    const settingsRoutes = useSettingsRoutes();
    const isSettingsRoute = useIsSettingsRouteMatched();
    const isPortalHome = useRouteMatch(RouteNames.Home);
    const isOrderLoadingPage = useRouteMatch(BuyProLoading);
    const announcements = useSelector(announcementsList);
    const unreadAnnouncementsCount = useSelector(unReadAnnouncementCount);
    const currentAccountType = useSelector(accountType);
    const adminRevamp = useSelector(adminRevampSelector);
    const isHost = useSelector(isHostModeSelector);

    const isEcommerceEnabled = useIsEcommerceEnabled();
    const adminRevampEnabled = useIsAdminRevampEnabled();
    const adminNavigationRoute = useAdminNavigationRoute();

    const EnableAdminRevampToggle = useFeatureFlagValue(Features.EnableAdminRevampToggle.name);
    const EnableAppBarEnterpriseTrial = useFeatureFlagValue(Features.EnableAppBarEnterpriseTrial.name);
    const EnableEcommercePromotionalDiscounts = useFeatureFlagValue(Features.EnableEcommercePromotionalDiscounts.name);
    const EnableAdminRevampDefaultExperience = useFeatureFlagValue(Features.EnableAdminRevampDefaultExperience.name);
    const HideAdminRevampToggle = useFeatureFlagValue(Features.HideAdminRevampToggle.name);
    const HideTenantPicker = useFeatureFlagValue(Features.HideTenantPicker.name);
    const EnableAiChatFeature = useFeatureFlagValue(Features.EnableAiChatFeature.name);

    const [ adminRevampToggleState, setAdminRevampToggleState ] = useState<
    'off' | 'on' | 'off-to-on' | 'on-to-off'
    >(adminRevamp ? 'on' : 'off');

    const isResourceCenter = useRouteMatch(RouteNames.ResourceCenter);

    const onLeftNavItemChangeCallback = useCallback(
        (e: any) => {
            const requestedRoute = e.detail.serviceid as 'admin' | 'home';
            if (requestedRoute === 'admin') {
                history.push(adminNavigationRoute);
            } else if (requestedRoute === 'home') {
                history.push(getRoute(RouteNames.Home));
            }
        },
        [ history, adminNavigationRoute, getRoute ],
    );

    const getSelectedPage = useCallback(
        (page: ISettingRoute) => {
            const subRouteParent = isSubRoute(location.pathname, page);
            return subRouteParent ? page.route === subRouteParent : !!matchPath(location.pathname, page.route);
        },
        [ location.pathname ],
    );

    const isHelpPage = useMemo(
        () => location.pathname?.toLowerCase() === getRoute(RouteNames.ResourceCenter).toLowerCase(),
        [ getRoute, location.pathname ],
    );

    const listOfPages = useMemo(
        () => settingsRoutes.filter(
            item => typeof item.hide === 'boolean' ?
                item.hide !== true :
                item.hide?.(currentAccountType) !== true)
            .map(item => ({
                ...item,
                selected: getSelectedPage(item),
            })),
        [ currentAccountType, getSelectedPage, settingsRoutes ],
    );

    const isPageWithNoDisplayedBanner = useMemo(
        () => isOrderLoadingPage,
        [ isOrderLoadingPage ],
    );

    const openNotificationsFlyout = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setNotificationsAnchorEl(event.currentTarget);
    }, []);

    const closeNotificationsFlyout = useCallback(() => {
        if (announcements?.length > 0) {
            dispatch(markAnnouncementRead());
        }
        setNotificationsAnchorEl(null);
    }, [ announcements?.length, dispatch ]);

    const showNotificationsBell = useMemo(() => announcements.length > 0, [ announcements?.length ]);

    const adminRevampToggleChanged = useCallback(async (ev: React.ChangeEvent<HTMLInputElement>) => {
        setAdminRevampToggleState(ev.target.checked ? 'off-to-on' : 'on-to-off');
        dispatch(toggleAdminRevamp());
        history.push(!adminRevamp ? getRoute(RouteNames.OrganizationAdminHome)
            : (isHost ?
                getRoute(RouteNames.Organizations)
                : getRoute(RouteNames.Services)
            ),
        );
        await logEvent('PortalAdminRevamp.Toggle', {
            OldRevampValue: !ev.target.checked,
            NewRevampValue: ev.target.checked,
        });
        Pendo.updateOptions({ visitor: { newExperienceOn: ev.target.checked } });
        Pendo.loadGuides();
    }, [ adminRevamp, dispatch, getRoute, history, isHost, logEvent ]);

    const chatMessageReceivedListener = useCallback((event: any) => {
        const {
            source, content, hidden, postMessage,
        } = event.detail;

        if (!hidden && postMessage) {
            postMessage(
                source,
                content,
            );
        }
    }, []);

    const logoutListener = useCallback(() => {
        history.push('/portal_/logout');
    }, [ history ]);

    const portalShellLoadedListener = useCallback(() => {
        window.PortalShell?.Chat?.initialize({
            context: `You are an expert on UiPath Automation Cloud home and admin sections.`,
            serviceAvatarUrl: 'https://platform-cdn.uipath.com/portal/portal/images/flyingRobot.png',
        });
    }, []);

    useEffect(() => {
        if (!HideAdminRevampToggle && EnableAdminRevampDefaultExperience) {
            if (UiStorage.getItem('adminRevamp') == null) {
                dispatch(toggleAdminRevamp());
                if (!isPortalHome && !isResourceCenter) {
                    history.push(getRoute(RouteNames.OrganizationAdminHome));
                }
                logEvent('PortalAdminRevamp.Toggle', {
                    OldRevampValue: false,
                    NewRevampValue: true,
                });
                Pendo.updateOptions({ visitor: { newExperienceOn: true } });
                Pendo.loadGuides();
            }
        }
    }, [
        EnableAdminRevampDefaultExperience,
        HideAdminRevampToggle,
        dispatch,
        getRoute,
        history,
        isPortalHome,
        isResourceCenter,
        logEvent,
    ]);

    useEffect(() => {
        if (EnableAiChatFeature) {
            if (window.PortalShell?.Chat) {
                portalShellLoadedListener();
            } else {
                document.addEventListener('portalShellLoaded', portalShellLoadedListener);
            }
        }

        document.addEventListener('chatMessageReceived', chatMessageReceivedListener);
        document.addEventListener('logout', logoutListener);

        return () => {
            document.removeEventListener('chatMessageReceived', chatMessageReceivedListener);
            document.removeEventListener('logout', logoutListener);
            EnableAiChatFeature && document.removeEventListener('portalShellLoaded', portalShellLoadedListener);
        };
    }, [
        history,
        productName,
        EnableAiChatFeature,
        chatMessageReceivedListener,
        logoutListener,
        portalShellLoadedListener,
    ]);

    const adminRevampToggle = useMemo(() => (
        <div
            slot="slot-end"
            className={classes.adminToggle}>
            <img
                src={adminRevampIcon}
                alt='adminRevampIcon'
                style={{ marginRight: '4px' }}
                width='18px'
            />
            <FormControlLabel
                style={{ marginLeft: '4px' }}
                control={
                    <Switch
                        data-cy="new-admin-experience-toggle"
                        checked={adminRevamp}
                        onChange={adminRevampToggleChanged}
                    />
                }
                label={
                    <Typography className={classes.adminToggleText}>
                        {translate({ id: 'CLIENT_ADMIN_REVAMP_TOGGLE' })}
                    </Typography>
                }
                labelPlacement='start'
                data-state={adminRevampToggleState}
            />
        </div>
    ), [ adminRevamp, adminRevampToggleChanged, adminRevampToggleState, classes.adminToggle, classes.adminToggleText, translate ]);

    const helpPageCopyright = useMemo(() => (
        <div
            slot="slot-end"
            className={classes.copyRightSlot}>
            <Typography className={classes.copyRightText}>
                {`© UiPath ${new Date().getFullYear()}`}
                {' '}
            </Typography>
            <PortalDivider />
        </div>
    ), [ classes.copyRightSlot, classes.copyRightText ]);

    const notificationsBellAndPopover = useMemo(() => (
        <>
            {showNotificationsBell && (
                <IconButton
                    color="default"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    data-cy="App_Bar_Notifications_Button"
                    onClick={openNotificationsFlyout}
                    className={classes.notificationsIcon}
                >
                    <Badge
                        badgeContent={unreadAnnouncementsCount}
                        color="error">
                        <NotificationsOutlinedIcon style={{ transform: 'scale(1.25)' }} />
                    </Badge>
                </IconButton>
            )}
            <Popover
                anchorEl={notificationsAnchorEl}
                open={Boolean(notificationsAnchorEl)}
                onClose={closeNotificationsFlyout}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{ className: classes.popoverDefaults }}
                transitionDuration={200}
            >
                <Notifications announcements={announcements} />
            </Popover>
        </>
    ), [
        announcements,
        classes.notificationsIcon,
        classes.popoverDefaults,
        closeNotificationsFlyout,
        notificationsAnchorEl,
        openNotificationsFlyout,
        showNotificationsBell,
        unreadAnnouncementsCount,
    ]);

    const mainContent = useMemo(() => (
        <>
            <div role="banner">
                {isEcommerceEnabled && !EnableAppBarEnterpriseTrial && !EnableEcommercePromotionalDiscounts
                && AccountLicense[currentAccountType] === AccountLicense.TRIAL && !isPageWithNoDisplayedBanner
                && <EcommerceAlertBarComponent />}
                {adminRevampEnabled
                            && !isPortalHome && !isPageWithNoDisplayedBanner
                            && <>
                                <UiLicenseInGracePeriodBanner />
                                <UiLicenseExpiredBanner />
                                <UiOverAllocationBanner />
                                <UiTrialPerSkuLicenseInGracePeriodBanners />
                            </>}

                {adminRevampEnabled && !isPageWithNoDisplayedBanner && <EcommercePaymentFailedBannerComponent />}
            </div>
            <div
                className={classes.appContent}
                id="portal-root"
                role="main"
            >
                {isSettingsRoute && (!adminRevampEnabled
                    ? <SecondaryNav pages={listOfPages} />
                    : <TenantShell />
                )}
                {props.children}
            </div>
        </>
    ), [
        EnableAppBarEnterpriseTrial,
        EnableEcommercePromotionalDiscounts,
        adminRevampEnabled,
        classes.appContent,
        currentAccountType,
        isEcommerceEnabled,
        isPortalHome,
        isSettingsRoute,
        listOfPages,
        props.children,
        isPageWithNoDisplayedBanner,
    ]);

    const apShellContents = useMemo(() => (
        <>
            {!HideAdminRevampToggle && EnableAdminRevampToggle && isSettingsRoute && adminRevampToggle}
            {EnableHelpCopyright && isHelpPage && helpPageCopyright}
            {notificationsBellAndPopover}

            {mainContent}
        </>
    ), [
        EnableAdminRevampToggle,
        HideAdminRevampToggle,
        adminRevampToggle,
        helpPageCopyright,
        isHelpPage,
        isSettingsRoute,
        mainContent,
        notificationsBellAndPopover,
    ]);

    const active = useMemo(() => {
        if (location.pathname?.toLowerCase() === getRoute(RouteNames.Home).toLowerCase()) {
            return 'home';
        }
        if (isSettingsRoute) {
            return 'admin';
        }
        return '';
    }, [ location, isSettingsRoute, getRoute ]);

    useEffect(() => {
        apShell.addEventListener('serviceClicked', onLeftNavItemChangeCallback);
        return () => {
            apShell.removeEventListener('serviceClicked', onLeftNavItemChangeCallback);
        };
    }, [ apShell, onLeftNavItemChangeCallback ]);

    const isFocusMode = adminRevampEnabled && !isPortalHome || isNoLeftNavRoute;
    const isHidingTenantPicker = HideTenantPicker && isSettingsRoute;
    const isShowingLogoTrademark = process.buildConfigs.forceLogoTrademark || !showTrademark;
    const feature = isResourceCenter ? translate({ id: 'CLIENT_RESOURCE_CENTER' }) : undefined;
    apShell.setAttribute('product-name', productName);
    apShell.setAttribute('show-product-trademark', showTrademark.toString());
    apShell.setAttribute('focus-mode', isFocusMode.toString());
    apShell.setAttribute('data-cy', 'ap-shell');
    apShell.setAttribute('active', active);
    apShell.setAttribute('hide-tenant-picker', isHidingTenantPicker.toString());
    apShell.setAttribute('emit-event-and-prevent-navigation-services', 'admin,help,home');
    apShell.setAttribute('disable-product-click', 'true');
    apShell.setAttribute('show-logo-trademark', isShowingLogoTrademark.toString());

    if (feature) {
        apShell.setAttribute('feature', feature.toString());
    }

    return createPortal(
        <>
            <OrganizationSwitcherDialogComponent />
            {apShellContents}
        </>,
        apShell
    );
};

export default (props: PropsWithChildren<{}>) => (
    <ProtectedRoute>
        <PrivateShell {...props} />
    </ProtectedRoute>
);
