import {
    UiCopyButton,
    UiDialog,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Button,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import {
    accountLogicalName,
    isAdminSelector,
} from '../../store/selectors';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginBottom: '10px' },
        inputLabelRoot: {
            '&[class*=MuiInputLabel-root]': {
                marginTop: '0px',
                marginBottom: '0px',
            },
        },
        inputRoot: {
            '&[class*=MuiInput-root]': {
                marginTop: '0px',
                marginBottom: '0px',
            },
        },
        connectViaAPI: {
            height: '32px',
            fontSize: '16px',
            fontWeight: 600,
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '0px',
        },
        warningIcon: {
            color: theme.palette.semantic.colorWarningIcon,
            marginRight: '8px',
            fontSize: '18px',
        },
        banner: {
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.semantic.colorWarningBackground,
            marginBottom: '12px',
            padding: '10px 24px',
            fontSize: '14px',
            fontWeight: 400,
            maxWidth: '500px',
        },
        transbox: {
            maxWidth: '480px',
            marginBottom: '0px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const RefreshTokenPopUp: React.FC<{
    close: () => void;
    refreshToken: string | null;
    tenantName: string | null;
    clientId: string;
    handleRevoke: () => void;
}> = ({
    close, refreshToken, tenantName, clientId, handleRevoke,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const accountName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);

    const getField = useCallback(
        (label: string, value: string | null, dataCy: string, ariaLabel: string) => (
            <TextField
                className={classes.input}
                data-cy={dataCy}
                InputLabelProps={{ classes: { root: classes.inputLabelRoot } }}
                InputProps={{
                    classes: { root: classes.inputRoot },
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <UiCopyButton
                                textToCopy={value ?? ''}
                                aria-label={ariaLabel}
                                data-cy={`${dataCy}-copy-button`}
                            />
                        </InputAdornment>
                    ),
                }}
                label={label}
                variant='standard'
                value={value} />
        ),
        [ classes.input, classes.inputLabelRoot, classes.inputRoot ],
    );

    const apiConnectionData = useMemo(
        () => (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {getField(translate({ id: 'CLIENT_USER_KEY' }), refreshToken, 'APIAccessUserKey', translate({ id: 'COPY_CLIENT_USER_KEY' }))}
                {getField(translate({ id: 'CLIENT_ACCOUNT_LOGICAL_NAME' }), accountName, 'APIAccessaccountName', translate({ id: 'CLIENT_COPY_LOGICAL_NAME' }))}
                {getField(translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' }), tenantName, 'APIAccessTenantLogicalName', translate({ id: 'COPY_CLIENT_TENANT_NAME_CREATE_EDIT' }))}
                {getField(translate({ id: 'CLIENT_CLIENT_ID' }), clientId, 'APIAccessClientId', translate({ id: 'COPY_CLIENT_CLIENT_ID' }))}
            </div>
        ),
        [ accountName, clientId, getField, refreshToken, tenantName, translate ],
    );

    return (
        <UiDialog
            close={close}
            title={translate({ id: 'CLIENT_API_ACCESS' })}
            actions={[
                <Button
                    key="primaryButton"
                    variant="contained"
                    data-cy="refresh-token-popup-submit"
                    onClick={close}>
          OK
                </Button>,
            ]}
        >
            {isAdmin && (
                <div className={classes.banner}>
                    <WarningIcon className={classes.warningIcon} />
                    <div>
                        <Typography data-cy="external-api-access-text">
                            {translate({ id: 'CLIENT_EXTERNAL_APP_API_ACCESS' })}
&nbsp;
                            <Link
                                to={getRoute(RouteNames.ExternalApplications)}
                                style={{ fontWeight: 'bold' }}
                                aria-label={translate({ id: 'CLIENT_VIEW_DESCRIPTION' })}
                                onClick={close}>
                                {translate({ id: 'CLIENT_VIEW' })}
                            </Link>
                        </Typography>
                    </div>
                </div>
            )}
            <div className={classes.connectViaAPI}>
                {translate({ id: 'CLIENT_CONNECT_VIA_API' })}
            </div>
            {apiConnectionData}
            <div className={classes.connectViaAPI}>
                {translate({ id: 'CLIENT_REVOKE_ACCESS' })}
            </div>
            <div className={classes.transbox}>
                <p>
                    {translate({ id: 'CLIENT_REVOKE_DESCRIPTION' })}
                </p>
                <Button
                    variant="outlined"
                    onClick={handleRevoke}
                    id="secondaryButton"
                    data-cy="refresh-token-popup-revoke-button"
                >
                    {translate({ id: 'CLIENT_REVOKE_ACCESS_CAPS' })}
                </Button>
            </div>
        </UiDialog>
    );
};

export default RefreshTokenPopUp;
