import {
    Chip,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

import { useCheckAADEnabled } from '../../../common/hooks/useCheckAADEnabled';

const useStyles = makeStyles(theme =>
    createStyles({
        headerTitle: {
            display: 'flex',
            alignItems: 'center',
        },
        azureChip: {
            marginLeft: '8px',
            color: theme.palette.getContrastText(theme.palette.semantic.colorForegroundDeEmp),
            backgroundColor: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const UsersPageHeaderTitle: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAADConnectionEnabled = useCheckAADEnabled();

    return (
        <div className={classes.headerTitle}>
            <Typography
                style={{
                    fontWeight: 600,
                    fontSize: '18px',
                    lineHeight: '35px',
                    padding: '3px 0',
                }}
                role="heading"
                aria-level={1}
            >
                {translate({ id: 'CLIENT_ACCOUNTS_AND_GROUPS' })}
            </Typography>
            {isAADConnectionEnabled && (
                <Tooltip title={translate({ id: 'CLIENT_AZURE_CONNECTED_TOOLTIP' })}>
                    <Chip
                        className={classes.azureChip}
                        label={translate({ id: 'CLIENT_AZURE_CONNECTED' })} />
                </Tooltip>
            )}
        </div>
    );
};

export default UsersPageHeaderTitle;
