import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import type { IExternalClient } from '../../common/interfaces/cis/client';
import {
    deleteExternalClient,
    externalClientUrl,
    getExternalClients,
} from '../../services/identity/ExternalClientService';
import { accountGlobalId } from '../../store/selectors';
import { UiGrid } from '../common/UiGrid';
import type { IActionHeader } from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';

const ExternalApplicationsPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();
    const { enqueueSnackbar } = useSnackbar();
    const setErrorMessage = useCentralErrorSetter();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const partitionGlobalId = useSelector(accountGlobalId);

    const { getErrorMessage } = useGetErrorInfo();

    const {
        data, isValidating, error,
    } = useSWR<IExternalClient[], Error>(
        [ externalClientUrl, partitionGlobalId ],
        getExternalClients,
    );

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    useEffect(() => {
        history.listen(async location => {
            if (location.state && (location.state as any)['externalClient']) {
                const externalClient: IExternalClient = (location.state as any)['externalClient'];
                await createDialog({
                    title: translate({ id: 'CLIENT_EXTERNAL_APP_CREATED_SUCCESSFULLY' }),
                    body: (
                        <>
                            <Typography
                                variant="body1"
                                style={{ marginBottom: '12px' }}>
                                {translate({
                                    id: externalClient.secret
                                        ? 'CLIENT_EXTERNAL_APP_CREATED_SUCCESSFULLY_DESCRIPTION'
                                        : 'CLIENT_EXTERNAL_APP_CREATED_SUCCESSFULLY_DESCRIPTION_WITHOUT_SECRET',
                                })}
                            </Typography>
                            <Typography>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_APP_ID_WITH_ID' }, { 0: externalClient.id })}
                            </Typography>
                            {externalClient.secret && (
                                <Typography>
                                    {translate({ id: 'CLIENT_EXTERNAL_APP_APP_SECRET_WITH_SECRET' }, { 0: externalClient.secret })}
                                </Typography>
                            )}
                        </>
                    ),
                    icon: 'success',
                    primaryButtonText: translate({ id: 'CLIENT_CLOSE' }),
                });

                history.replace(history.location.pathname);
            }
        });
    }, [ createDialog, history, translate ]);

    const actions: IActionHeader[] = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_APPLICATION' }),
            tooltip: translate({ id: 'CLIENT_ADD_APPLICATION' }),
            actionType: GridActionType.Main,
            icon: <AddIcon />,
            click: () => history.push({ pathname: getRoute(`${RouteNames.ExternalApplications}/add`) }),
            variant: 'contained',
            dataCy: 'ui-grid-add-application-button',
        },
    ], [ getRoute, history, translate ]);

    return (
        <div style={{ padding: isAdminRevampEnabled ? '0px' : '24px' }}>
            <Typography style={{ marginBottom: '12px' }}>
                {translate({ id: 'CLIENT_EXTERNAL_APP_DESCRIPTION' })}
            </Typography>
            <UiGrid<IExternalClient>
                data-cy="external-apps-ui-grid"
                loading={isValidating}
                search
                searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                pagination
                error={error}
                tableHeight='500px'
                extraActionButtons={actions}
                columns={[
                    {
                        accessor: 'name',
                        Header: translate({ id: 'CLIENT_EXTERNAL_APP_NAME' }),
                        sortType: 'alphanumeric',
                        width: 40,
                    },
                    {
                        accessor: 'isConfidential',
                        Header: translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' }),
                        width: 40,
                        disableSortBy: true,
                        Cell: ({ row }) => row.original.isConfidential
                            ? translate({ id: 'CLIENT_EXTERNAL_APP_CONFIDENTIAL_APPLICATION' })
                            : translate({ id: 'CLIENT_EXTERNAL_APP_NON_CONFIDENTIAL_APPLICATION' }),
                    },
                    {
                        accessor: 'id',
                        Header: translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_ID' }),
                        sortType: 'alphanumeric',
                        width: 20,
                    },
                ]}
                data={data ?? []}
                refresh={() => mutate([ externalClientUrl, partitionGlobalId ])}
                rowActions={[
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_EDIT' }),
                        tooltip: translate({ id: 'CLIENT_EDIT' }),
                        actionType: GridActionType.Row,
                        icon: <EditOutlinedIcon />,
                        click: row => {
                            history.push({ pathname: getRoute(`${RouteNames.ExternalApplications}/edit/${row.original.id}`) });
                        },
                        dataCy: 'edit-external-app-button',
                    },
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_DELETE' }),
                        tooltip: translate({ id: 'CLIENT_DELETE' }),
                        actionType: GridActionType.Row,
                        icon: <DeleteForeverOutlinedIcon />,
                        click: async row => {
                            const proceed = await createDialog({
                                title: translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_APPLICATION_TITLE' }),
                                body: (
                                    <Typography>
                                        {translate(
                                            { id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_APPLICATION' },
                                            { appName: row.original.name },
                                        )}
                                    </Typography>
                                ),
                                icon: 'warning',
                                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                                showCancel: true,
                            });

                            if (proceed) {
                                try {
                                    await deleteExternalClient(row.original.id, partitionGlobalId);
                                    createNotification(
                                        translate({ id: 'CLIENT_EXTERNAL_APP_DELETE_APPLICATION_SUCCESS' }, { 0: row.original.name }),
                                    );
                                    mutate([ externalClientUrl, partitionGlobalId ]);
                                } catch (deleteError) {
                                    setErrorMessage(await getErrorMessage(deleteError));
                                }
                            }
                        },
                        dataCy: 'delete-external-app-button',
                    },
                ]}
            />
        </div>
    );
};

export default ExternalApplicationsPageComponent;
