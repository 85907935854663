import {
    BuyProIndividualProducts,
    EcommerceBusinessFeaturesForCloud,
    EcommerceContactSalesLinkComponent,
    EcommerceCurrencyDropdown,
    EcommercePlanSummary,
    EcommerceProvider,
    TRY_BUY_FLOW,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    CircularProgress,
    Divider,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';

import { accountLogicalName } from '../../../../store/selectors';
import EcommerceDiscountBanner from '../EcommerceDiscountBanner';
import useEcommercePresetsViewModel from './useEcommercePresetsViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        presetsContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
        },
        presetsColumns: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            columnGap: '20%',
        },
        mainSection: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            maxWidth: '860px',
        },
        summarySection: { alignSelf: 'stretch' },
        summaryWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%',
            borderRadius: '16px',
        },
        summaryComponent: {
            display: 'flex',
            width: '387px',
            height: '481px',
            backgroundColor: theme.palette.semantic.colorChipInfoBackground,
            borderRadius: '16px',
            marginTop: '60px',
        },
        radioCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',
            boxSizing: 'border-box',
            paddingTop: '20px',
            paddingBottom: '24px',
            minWidth: '571px',
            marginBottom: '16px',
            marginLeft: '20px',
            border: '1px solid',
            borderColor: theme.palette.semantic.colorHover,
            borderRadius: '8px',
            fontFamily: 'Noto Sans',
            '&:hover': {
                borderColor: theme.palette.semantic.colorForegroundLink,
                backgroundColor: theme.palette.semantic.colorHover,
            },
        },
        selectedRadioCard: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            justifyContent: 'center',
            boxSizing: 'border-box',
            paddingTop: '20px',
            paddingBottom: '24px',
            minWidth: '571px',
            marginBottom: '16px',
            marginLeft: '20px',
            border: '1px solid',
            borderColor: theme.palette.semantic.colorForegroundLink,
            borderRadius: '8px',
            fontFamily: 'Noto Sans',
            '&:hover': {
                borderColor: theme.palette.semantic.colorForegroundLink,
                backgroundColor: theme.palette.semantic.colorHover,
            },
        },
        productDetailsContainer: {
            display: 'flex',
            marginLeft: '60px',
            flexDirection: 'column',
        },
        productDetails: { display: 'flex' },
        packageDetails: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: '30px',
            marginRight: '30px',
            '& .MuiRadio-root': {
                paddingTop: '0px',
                paddingBottom: '0px',
            },
        },
        packageName: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        packagePrice: {
            fontSize: '20px',
            lineHeight: '24px',
            fontWeight: '600',
            paddingRight: '4px',
            color: theme.palette.semantic.colorForeground,
        },
        planType: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            color: theme.palette.semantic.colorForegroundDeEmp,
            whiteSpace: 'pre-wrap',
        },
        learnMoreLink: { textDecoration: 'none' },
        learnMore: {
            size: '14px',
            lineHeight: '20px',
            fontWeight: '400',
            marginLeft: '60px',
            textDecoration: 'none',
            color: theme.palette.semantic.colorForegroundLink,
        },
        packageInnerDetails: {
            display: 'flex',
            alignItems: 'flex-end',
        },
        presetsTitle: {
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '32px',
            width: '100%',
            color: theme.palette.semantic.colorForeground,
        },
        presetsSubTitle: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            marginTop: '35px',
            marginBottom: '20px',
            color: theme.palette.semantic.colorForeground,
        },
        presetsDescription: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
        },
        dividerSection: {
            minWidth: '571px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '36px',
            marginLeft: '20px',
        },
        divider: { width: '47%' },
        dividerText: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
        },
        individualLicensesButton: {
            marginTop: '32px',
            minWidth: '571px',
            height: '68px',
            marginLeft: '20px',
            borderRadius: '3px',
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForegroundLink,
            textAlign: 'center',
            '&:hover': { backgroundColor: theme.palette.semantic.colorPrimaryHover },
        },
        businessFeaturesSection: {
            marginLeft: '20px',
            marginTop: '40px',
        },
        titleRow: {
            width: 'auto',
            marginLeft: '20px',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        salesLink: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '24px',
        },
        buyEnterpriseText: { paddingRight: '4px' },
        productDetailsQuantity: { paddingRight: '1ch' },
        productDetailsText: { whiteSpace: 'pre-wrap' },
        pickerRow: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginLeft: '20px',
            minWidth: '571px',
        },
        picker: { marginBottom: '8px' },
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
    }),
}));

const EcommercePresetsPage: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const logEcommerceEvent = useEcommerceTelemetry();
    const currentAccountName = useSelector(accountLogicalName);
    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        logEcommerceEvent('Licenses.ViewPlans', { Flow: TRY_BUY_FLOW });
    }, [ logEcommerceEvent ]);

    const {
        packagesData,
        loading,
        selectedPackage,
        currentCurrency,
        currentSkuPackageDetails,
        productsPricesInAllCurrencies,
        selectedPackageType,
        getPrice,
        skuCodeToOfferType,
        planTypeText,
        isMonthlyPlanType,
        onSelectOption,
        discountedPrice,
        price,
        setCurrentCurrency,
        showDialogCallback,
        presetsOfferSubtitle,
    } = useEcommercePresetsViewModel(currentAccountName);

    return (
        loading ? (
            <div className={clsx(classes.loading)}>
                <CircularProgress />
            </div>
        ) :
            (
                <div className={classes.presetsContent}>
                    <div className={classes.presetsColumns}>
                        <div className={classes.mainSection}>
                            <EcommerceDiscountBanner />
                            <div className={classes.titleRow}>
                                <div>
                                    <Typography
                                        className={classes.presetsTitle}
                                        variant="h1">
                                        {translate({ id: 'CLIENT_ECOMMERCE_BUY_AUTOMATION_CLOUD_PRO' })}
                                    </Typography>
                                    {isMonthlyPlanType &&
                                        <Typography>
                                            {translate({ id: 'CLIENT_ECOMMERCE_PAY_MONTHLY_CANCEL_ANYTIME' })}
                                        </Typography>}
                                </div>
                            </div>
                            <div>
                                <div className={classes.pickerRow}>
                                    <Typography
                                        className={classes.presetsSubTitle}
                                        variant="h2">
                                        {translate({ id: presetsOfferSubtitle })}
                                    </Typography>
                                    <div className={classes.picker}>
                                        <EcommerceCurrencyDropdown
                                            selectedCurrency={currentCurrency}
                                            setSelectedCurrency={setCurrentCurrency}
                                            productsPricesInAllCurrencies={productsPricesInAllCurrencies ?? []} />
                                    </div>
                                </div>
                                <RadioGroup
                                    aria-label="presets"
                                    name="presets"
                                    value={selectedPackageType}>
                                    {
                                        packagesData?.packages.map((skuPackage) => (
                                            skuCodeToOfferType[skuPackage.type] &&
                                            <div
                                                className={selectedPackageType === skuPackage.type ? classes.selectedRadioCard : classes.radioCard}
                                                data-cy={skuPackage.type + '-PackageCard'}
                                                key={skuPackage.type}
                                                onClick={onSelectOption(skuPackage.type)} >
                                                <div className={classes.packageDetails}>
                                                    <FormControlLabel
                                                        value={skuPackage.type}
                                                        control={<Radio />}
                                                        label={<Typography className={classes.packageName}>
                                                            {translate({ id: skuCodeToOfferType[skuPackage.type] })}
                                                        </Typography>} />
                                                    <div className={classes.packageInnerDetails}>
                                                        <Typography className={classes.packagePrice}>
                                                            {getPrice(skuPackage, currentSkuPackageDetails.planType)}
                                                        </Typography>
                                                        <Typography className={classes.planType}>
                                                            {translate({ id: planTypeText[currentSkuPackageDetails.planType] })}
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className={classes.productDetailsContainer}>
                                                    {skuPackage.productQuantities.map((productQuantity) => (
                                                        <div
                                                            className={classes.productDetails}
                                                            key={productQuantity.code}>
                                                            <Typography
                                                                className={classes.productDetailsQuantity}>
                                                                {productQuantity.quantity}
                                                            </Typography>
                                                            <Typography>
                                                                {translate({ id: `CLIENT_PRODUCT_${productQuantity.code }` })}
                                                            </Typography>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className={classes.learnMore}>
                                                    <Typography>
                                                        <Link
                                                            component='button'
                                                            data-cy={'learn-more-' + skuPackage.type}
                                                            onClick={showDialogCallback(skuPackage.type)}
                                                            className={classes.learnMoreLink}>
                                                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                                                        </Link>
                                                    </Typography>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </RadioGroup>
                            </div>

                            <div className={classes.dividerSection}>
                                <Divider className={classes.divider} />
                                <Typography className={classes.dividerText}>
                                    {translate({ id: 'CLIENT_OR' })}
                                </Typography>
                                <Divider className={classes.divider} />
                            </div>

                            <Button
                                className={classes.individualLicensesButton}
                                variant="outlined"
                                onClick={() => {
                                    history.push(getRoute(BuyProIndividualProducts));
                                }}
                                data-cy='buy-individual-button'
                            >
                                {translate({ id: 'CLIENT_ECOMMERCE_BUY_INDIVIDUAL_LICENSES' })}
                            </Button>

                            <div className={classes.businessFeaturesSection}>
                                <EcommerceBusinessFeaturesForCloud />
                            </div>
                        </div>
                        <div className={classes.summarySection}>
                            <div className={classes.salesLink}>
                                <Typography className={classes.buyEnterpriseText}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_BUYING_FOR_AN_ENTERPRISE' })}
                                </Typography>
                                <EcommerceContactSalesLinkComponent />
                            </div>
                            <div className={classes.summaryWrapper}>
                                <div className={classes.summaryComponent}>
                                    {productsPricesInAllCurrencies && selectedPackage && (
                                        <EcommercePlanSummary
                                            {...currentSkuPackageDetails}
                                            products={selectedPackage.productQuantities}
                                            taxLoading={false}
                                            currency={currentCurrency}
                                            price={price}
                                            productsPricesInAllCurrencies={productsPricesInAllCurrencies}
                                            discountedPrice={discountedPrice}
                                            discountDetails={currentSkuPackageDetails.discountDetails}
                                            productPricesLoading={loading}
                                            isFirstSubscription
                                            isFormValid
                                            planPackage={selectedPackage}
                                        />)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
    );
};

export default ({ route }: { route?: any }) => (
    <EcommerceProvider>
        <EcommercePresetsPage />
        {renderRoutes(route.routes)}
    </EcommerceProvider>
);
