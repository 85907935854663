import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useShowDialog } from '@experiences/util';
import WarningIcon from '@mui/icons-material/Warning';
import {
    Button,
    Link,
    Typography,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { LeavingOrganizationLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import {
    getTenants,
    getTenantsV2,
    organizationManagementTenantUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    companyName,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import OrganizationDeleteReasonsDialogBody from './OrganizationDeleteReasonsDialogBody';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteOrg: { marginTop: '24px' },
        deleteOrgBox: {
            borderRadius: '3px',
            border: `1px solid ${theme.palette.semantic.colorErrorIcon}`,
            marginTop: '24px',
            maxWidth: '518px',
            paddingLeft: '16px',
            paddingTop: '16px',
        },
        deleteOrgTitle: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForeground,
        },
        deleteOrgDetails: {
            fontSize: '14px',
            marginTop: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        deleteOrgDetailsIcon: {
            marginBottom: '-7px',
            paddingRight: '2px',
            marginRight: '2px',
        },
        deleteOrgDialogIcon: { marginTop: '3.5px' },
        deleteOrgButton: {
            marginTop: '12px',
            marginBottom: '16px',
            color: `${theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon)}`,
            backgroundColor: `${theme.palette.semantic.colorErrorIcon} !important`,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        deleteOrgButtonRevamp: {
            marginTop: '16px',
            marginBottom: '16px',
            color: `${theme.palette.semantic.colorErrorText} !important`,
            '&:hover': {
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
                borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            },
            borderColor: `${theme.palette.semantic.colorErrorText} !important`,
            width: '120px',
        },
        deleteOrgActions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        leaveOrgLink: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
    }),
);

const OrganizationDelete: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });
    const createDialog = useShowDialog();
    const theme = useTheme();

    const orgName = useSelector(companyName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);
    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const { logEvent } = useTelemetryHelper();

    const { data } = useSWR<ITenant[], Error>(
        !EnableGetTenantsRedesign
            ? [ organizationManagementTenantUri, partitionGlobalId, accountName, true ]
            : [ organizationManagementTenantUri, partitionGlobalId, accountName, 'All', undefined, undefined, true ],
        !EnableGetTenantsRedesign ? getTenants : getTenantsV2,
    );

    const openDeleteOrgReasonsDialog = useCallback(async () => {
        logEvent('Delete Org Dialog Opened');
        await createDialog({
            customDialogContent: OrganizationDeleteReasonsDialogBody,
            customDialogContentProps: {
                orgName,
                tenantList: data,
            },
            title: translate({ id: 'CLIENT_DELETE_ORGANIZATION' }),
            showCancel: false,
            icon: (
                <WarningIcon
                    className={classes.deleteOrgDialogIcon}
                    style={{ color: theme.palette.semantic.colorWarningIcon }}
                />
            ),
        });
    }, [
        logEvent,
        createDialog,
        orgName,
        data,
        translate,
        classes.deleteOrgDialogIcon,
        theme.palette.semantic.colorWarningIcon,
    ]);

    return (
        <div
            className={clsx({
                [classes.deleteOrg]: isAdminRevampEnabled,
                [classes.deleteOrgBox]: !isAdminRevampEnabled,
            })}
            data-cy="delete-org-section">
            <Typography
                className={classes.deleteOrgTitle}
                data-cy="delete-organization-title">
                {translate({ id: 'CLIENT_DELETE_ORGANIZATION' })}
            </Typography>
            <Typography
                className={classes.deleteOrgDetails}
                data-cy="delete-organization-details">
                {!isAdminRevampEnabled && <WarningIcon
                    className={classes.deleteOrgDetailsIcon}
                    style={{ color: theme.palette.semantic.colorWarningIcon }}
                />}
                <FormattedMessage
                    id="CLIENT_DELETE_ORGANIZATION_DETAILS"
                    values={{
                        b: (chunk: string) => <b>
                            {chunk}
                        </b>,
                    }}
                />
            </Typography>
            <div className={classes.deleteOrgActions}>
                <Button
                    className={clsx({
                        [classes.deleteOrgButton]: !isAdminRevampEnabled,
                        [classes.deleteOrgButtonRevamp]: isAdminRevampEnabled,
                    })}
                    onClick={async () => {
                        logEvent('Delete Org Button Clicked');
                        await openDeleteOrgReasonsDialog();
                    }}
                    data-cy="delete-organization-button"
                    variant={isAdminRevampEnabled ? 'outlined' : undefined}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </Button>
                {isAdminRevampEnabled && <Link
                    href={getLocalizedLink(LeavingOrganizationLink)}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    className={classes.leaveOrgLink}
                >
                    {translate({ id: 'CLIENT_LEAVE_ORG_INSTEAD' })}
                </Link>}
            </div>
        </div>
    );
};

export default OrganizationDelete;
