import type { IDirectoryEntry } from '../../common/interfaces/cis/directory';
import { get } from '../utility/Requests.default';

const userPartitionUrl = `${process.buildConfigs.identityApiBaseRoute}/Directory`;

export type SourceFilters = 'localUsers' | 'directoryUsers' | 'localGroups' | 'directoryGroups' | 'robotAccounts';

export function getDirectoryEntities(
    url: string,
    partitionGlobalId: string,
    sourceFilter: SourceFilters[],
    startsWith: string,
) {
    return get<IDirectoryEntry[]>(`${userPartitionUrl}/Search/${partitionGlobalId}`, {
        urlParams: {
            sourceFilter,
            startsWith,
        },
    });
}
