import { PORTAL_PREFIX } from '@experiences/constants';

export const PortalPrefix = PORTAL_PREFIX;

export const Root = `${PortalPrefix}/`;
export const RootRedirect = `${PortalPrefix}/rootRedirect`;
export const Home = `${PortalPrefix}/home`;
export const CloudRPA = `${PortalPrefix}/cloudrpa`;
export const LoginSuccess = `${PortalPrefix}/loginsuccess`;
export const Services = `${PortalPrefix}/serviceinstances`;
export const TenantDelete = `${Services}/delete`;
export const TenantStatus = `${Services}/status`;
export const TenantLicensingUpgradeToEnterprise = `${Services}/upgrade`;
export const TenantCreateEdit = `${Services}/:type(add|edit)/:tenantId?`;
export const TenantCreate = `${PortalPrefix}/createTenant`;
export const TenantCreateEditSkip = `${TenantCreateEdit}/skip`;
export const TenantConfigure = `${Services}/configure/:tenantId`;
export const TenantConfigureSkip = `${TenantCreateEdit}/skip`;
export const OrchLogExport = `${Services}/logsexport/:tenantId`;
export const Users = `${PortalPrefix}/users`;
export const Groups = `${PortalPrefix}/groups`;
export const Robots = `${PortalPrefix}/robots`;
export const AuthSettingsDeprecated = `${PortalPrefix}/azure`; // Deprecated; prefer `AuthSettings` route
export const AuthSettings = `${PortalPrefix}/authsettings`;
export const AuthSettingsConfigureAD = `${AuthSettings}/configureAd`;
export const AuthSettingsConfigureADDirectory = `${AuthSettings}/configureAdDirectory`;
export const AuthSettingsConfigureAAD = `${AuthSettings}/configureAad`;
export const AuthSettingsConfigureGoogle = `${AuthSettings}/configureGoogle`;
export const AuthSettingsConfigureSaml = `${AuthSettings}/configureSaml`;
export const AuthSettingsConfigureSamlOrg = `${AuthSettings}/configure/:type(aad|saml2)`;
export const UserInviteEdit = `${Users}/:type(invite|edit)`;
export const UserAddEdit = `${Users}/:type(add|edit)`;
export const DirectoryUser = `${Users}/directoryUser`;
export const DirectoryUserAddEdit = `${DirectoryUser}/:type(add|edit)`;
export const BulkUserInvite = `${Users}/bulkinvite`;
export const UsersUserAddLicenseAllocation = `${Users}/allocations/add`;
export const UsersUserEditLicenseAllocation = `${Users}/allocations/edit`;
export const UsersUserLicenseInheritance = `${Users}/allocations/inheritance`;
export const RobotsAddEdit = `${Robots}/:type(add|edit)`;
export const GroupsAddEdit = `${Groups}/:type(add|edit)/:id?`;
export const GroupAllocationRule = `${Groups}/allocations/:type(add|edit)`;
export const Licensing = `${PortalPrefix}/licensing`;
export const LicensingTenantConfigure = `${PortalPrefix}/licensing/:type(configure)/:tenantId`;
export const LicensingUpgradeToEnterprise = `${Licensing}/upgrade`;
export const ActivateOffline = `${Licensing}/activate/offline`;
export const DeactivateOffline = `${Licensing}/deactivate/offline`;
export const ManagePlan = `${PortalPrefix}/manageplan`;
export const BuyEnterprise = `${PortalPrefix}/buyenterprise`;
export const BuyPro = `${PortalPrefix}/buypro`;
export const UpdateLicenseQuantity = `${PortalPrefix}/updateLicenseQuantity`;
export const ViewCancelLicensePendingChange = `${Licensing}/manageLicensePendingChange`;
export const BuyProSuccess = `${Licensing}/success`;
export const LicensingGroupAllocation = `${Licensing}/group`;
export const LicensingGroupAllocationAddEdit = `${LicensingGroupAllocation}/:type(add|edit)`;
export const LicensingGroupAllocationView = `${LicensingGroupAllocation}/view`;
export const LicensingUserAllocation = `${Licensing}/user`;
export const LicensingUserAddLicenseAllocation = `${LicensingUserAllocation}/add`;
export const LicensingUserEditLicenseAllocation = `${LicensingUserAllocation}/edit`;
export const LicensingUserLicenseInheritance = `${LicensingUserAllocation}/inheritance`;
export const LicensingActivateLicenseOnline = `${Licensing}/activate/online`;
export const ResourceCenter = `${PortalPrefix}/resourceCenter`;
export const ResourceCenterCMSUpgradeToEnterprise = `${ResourceCenter}/upgrade`;
export const AuditLogs = `${PortalPrefix}/auditLog`;
export const AuditLogDialog = `${AuditLogs}/dialog`;
export const ServicesUsage = `${PortalPrefix}/service/:tenantId/usage`;
export const TenantServicesUsageAllocation = `${ServicesUsage}/:type(configure)`;
export const OrganizationSettings = `${PortalPrefix}/settings`;
export const OrganizationSettingsAdvanced = `${PortalPrefix}/advancedSettings`;
export const OrganizationSettingsWarning = `${OrganizationSettings}/warning`;
export const OrganizationMigration = `${PortalPrefix}/migration`;
export const EditOrganizationMigration = `${OrganizationSettings}/editMigration`;
export const EmailSettings = `${PortalPrefix}/emailsettings`;
export const EmailSettingsTest = `${EmailSettings}/test`;
export const LoginCustomization = `${PortalPrefix}/loginCustomization`;
export const RequestUserTokenAuth = `${PortalPrefix}/requestusertokenauth`;
export const RedirectToService = `${PortalPrefix}/redirectToService`;
export const ExternalApplications = `${PortalPrefix}/externalApps`;
export const ExternalApplicationsPAT = `${PortalPrefix}/externalAppsPAT`;
export const ExternalApplicationsPATSettings = `${ExternalApplicationsPAT}/settings`;
export const ExternalApplicationAddEdit = `${ExternalApplications}/:type(add|edit)/:id?`;
export const ExternalApplicationScopes = `${ExternalApplicationAddEdit}/scopes/:name?`;
export const Profile = `${PortalPrefix}/profile`;
export const PrivacyAndSecuritySettings = `${PortalPrefix}/privacyAndSecuritySettings`;
export const NavigationSettings = `${PortalPrefix}/navigationSettings`;
export const ThemeSettings = `${PortalPrefix}/themeSettings`;
export const PersonalAccessToken = `${PortalPrefix}/personalAccessToken`;
export const AddPersonalAccessToken = `${PersonalAccessToken}/add`;
export const ChangePassword = `${PrivacyAndSecuritySettings}/changePassword`;
export const Organizations = `${PortalPrefix}/organizations`;
export const OrganizationsAdd = `${Organizations}/add`;
export const OrganizationsLicense = `${Organizations}/license`;
export const OrganizationAdminHome = `${PortalPrefix}/admin`;
export const TenantHome = `${PortalPrefix}/tenant/:tenantId`;
export const OrganizationHome = `${PortalPrefix}/organization/:organizationId`;
export const HostOrganizationLicense = `${OrganizationHome}/licenses`;
export const HostOrganizationSettings = `${OrganizationHome}/settings`;
export const HostOrganizationAdd = `${PortalPrefix}/organization/add`;
export const TenantServices = `${TenantHome}/services`;
export const TenantServiceLogExport = `${TenantServices}/logsexport`;
export const TenantServicesAdd = `${TenantServices}/add`;
export const TenantConfigureService = `${TenantServices}/:type(configure|enable|addLicenses)/:serviceType?`;
export const TenantSettings = `${TenantHome}/settings`;
export const TenantMigration = `${TenantHome}/migration`;
export const EditTenantMigration = `${TenantSettings}/editMigration`;
export const VpnGateway = `${TenantHome}/vpn-gateway`;
export const VpnGatewayCreateEdit = `${VpnGateway}/:type(add|edit)`;
export const VpnGatewayConnections = `${TenantHome}/vpn-gateway-connections/:vpnGatewayKey`;
export const VpnGatewayConnectionsCreateEdit = `${VpnGatewayConnections}/:type(add|edit)`;

export const FreeSkuLandingPage = `${PortalPrefix}/automationkit`;

export const SecuritySettings = `${PortalPrefix}/authenticationsettings`;
export const ConfigureSSO = `${SecuritySettings}/configuresso`;
export const SecuritySettingsConfigure = `${SecuritySettings}/configure/:type(aad|saml2)`;
export const SecuritySettingsSAMLProvisioningRules = `${SecuritySettings}/configure/saml/provisioningRules`;
export const SecuritySettingsSAMLProvisioningRulesAddEdit = `${SecuritySettings}/configure/saml/provisioningRules/:type(add|edit)/:id?`;
export const SecuritySettingsAttributeMapping = `${SecuritySettings}/attributes/:type(aad|saml2)`;
export const SessionPolicy = `${PortalPrefix}/sessionpolicy`;
export const IPRestriction = `${PortalPrefix}/iprestriction`;
export const IPRestrictionAddEdit = `${IPRestriction}/:type(add|edit)/:id?`;
export const IPRestrictionBulkImport = `${IPRestriction}/bulkimport`;
export const Encryption = `${PortalPrefix}/encryption`;
export const EncryptionConfigure = `${Encryption}/configure/:type(add|edit)`;
export const NotificationSettings = `${PortalPrefix}/notification-settings`;
export const Notifications = `${PortalPrefix}/notifications`;
export const AddNotificationSettingsProfile = `${NotificationSettings}/editprofile/:id/:name/:type`;
export const AddUserGroupProfile = `${NotificationSettings}/addusergroup`;
export const AuthSettingsPasswordPolicy = `${SecuritySettings}/configure/passwordPolicy`;

export const TenantTags = `${TenantHome}/tags`;
export const TenantTagsLabels = `${TenantTags}/labels`;
export const TenantTagsLabelsAddEdit = `${TenantTagsLabels}/:type(add|edit)/:id?`;
export const TenantTagsProperties = `${TenantTags}/properties`;
export const TenantTagsPropertiesAddEdit = `${TenantTagsProperties}/:type(add|edit)/:editType(key|details|keyValues)/:id?`;
export const TenantTagsPropertiesDrawer = `${TenantTagsPropertiesAddEdit}/values`;
export const OrganizationAccessPolicy = `${PortalPrefix}/accesspolicy`;
export const OrganizationAccessPolicyAdd = `${PortalPrefix}/accesspolicy/add`;

export const TenantTagsMsi = `${OrganizationAdminHome}/tags`;
export const TenantTagsLabelsMsi = `${TenantTagsMsi}/labels`;
export const TenantTagsLabelsAddEditMsi = `${TenantTagsLabelsMsi}/:type(add|edit)/:id?`;
export const TenantTagsPropertiesMsi = `${TenantTagsMsi}/properties`;
export const TenantTagsPropertiesAddEditMsi = `${TenantTagsPropertiesMsi}/:type(add|edit)/:editType(key|details|keyValues)/:id?`;
export const TenantTagsPropertiesDrawerMsi = `${TenantTagsPropertiesAddEditMsi}/values`;
