import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { CustomerManagedKeyBestPracticesLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';

const useStyles = makeStyles(() =>
    createStyles({
        button: { marginRight: '10px' },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        buttonInner: { width: '100px' },
        confirm: { marginBottom: '8px' },
        description: { marginBottom: '24px' },
    }),
);

const EncryptionConsentDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, organizationName,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ textFieldValue, setTextFieldValue ] = useState('');
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'zh-TW', 'pt', 'tr', 'ru' ] });

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
    }, []);

    const formatStrong = useCallback((chunk: string) => (
        <strong>
            {chunk}
        </strong>
    ), []);

    return (
        <>
            <Typography
                data-cy='encryption-consent-description'
                className={classes.description}>
                <FormattedMessage
                    id='CLIENT_ENCRYPTION_CONSENT_DESCRIPTION'
                    values={{
                        0: { organizationName },
                        link: (
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                href={getLocalizedLink(CustomerManagedKeyBestPracticesLink)}
                                underline="none">
                                {translate({ id: 'CLIENT_BEST_PRACTICES' })}
                            </Link>
                        ),
                    }}
                />
            </Typography>
            <Typography
                className={classes.confirm}
                data-cy="encryption-consent-note"
                id='consentConfirm'>
                <FormattedMessage
                    id="CLIENT_CONFIRM_BY_TYPING_BELOW"
                    values={{
                        strong: formatStrong,
                        0: <span>
                            {organizationName}
                        </span>,
                    }}
                />
            </Typography>
            <TextField
                fullWidth
                autoComplete="off"
                variant="outlined"
                id="standard-name"
                onChange={handleChange}
                value={textFieldValue}
                data-cy="encryption-consent-textfield"
                inputProps={{ 'aria-labelledby': 'consentConfirm' }}
            />

            <div className={classes.buttonContainer}>
                <Button
                    className={clsx(classes.button, classes.buttonInner)}
                    color="primary"
                    data-cy="encryption-consent-cancel-button"
                    onClick={() => closeDialog(false)}
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    className={classes.button}
                    data-cy="encryption-consent-confirm-button"
                    innerButtonClass={classes.buttonInner}
                    loading={false}
                    onClick={() => closeDialog(true)}
                    disabled={textFieldValue !== organizationName}
                    variant="contained"
                >
                    {translate({ id: 'CLIENT_CONFIRM' })}
                </UiProgressButton>
            </div>
        </>
    );
};

export default EncryptionConsentDialogBody;
