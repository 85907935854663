const ActivationErrorCodeMapping: Record<string, string> = {
    '-100': 'CLIENT_ACTIVATION_NUMBER_OF_ACTIVATIONS_EXCEEDED',
    '-101': 'CLIENT_ACTIVATION_START_DATE_IN_FUTURE',
    '-102': 'CLIENT_ACTIVATION_LICENSE_EXPIRED',
    '-103': 'CLIENT_ACTIVATION_LICENSE_CODE_DOES_NOT_EXIST',
    '-105': 'CLIENT_ACTIVATION_LICENSE_DISABLED',
    '-106': 'CLIENT_ACTIVATION_LICENSE_MODEL_DATA_MISSING',
    '-110': 'CLIENT_ACTIVATION_INCOMPATIBLE_LICENSE_MODEL',
    '-503': 'CLIENT_ACTIVATION_SERVER_UNREACHEABLE',
    '-504': 'CLIENT_ACTIVATION_SERVER_TIMEOUT',
    '-505': 'CLIENT_ACTIVATION_PROXY_FAILURE',
    '-502': 'CLIENT_ACTIVATION_ERROR_PREPARING_DATA',
    '-500': 'CLIENT_ACTIVATION_GENERIC_SERVER_ERROR',
    '-1500': 'CLIENT_ACTIVATION_UNKNOWN_SERVER_ERROR',
    '-1030': 'CLIENT_ACTIVATION_SERVER_CONNECTION_FAILURE',
    '-1040': 'CLIENT_ACTIVATION_LICENSE_FORMAT_INCOMPATIBLE',
    '-1050': 'CLIENT_ACTIVATION_INVALID_LICENSE_SIGNATURE',
    '-1510': 'CLIENT_ACTIVATION_GENERIC_ERROR',
};

export function getActivationErrorMapping(operationStatus: number) {
    return ActivationErrorCodeMapping[operationStatus] ?? 'CLIENT_ACTIVATION_GENERIC_ERROR';
}
