import {
    AccountLicense,
    TelemetryTitle,
} from '@experiences/constants';
import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { useApolloTheme } from '@experiences/theme';
import { imageUrlToBase64 } from '@experiences/util';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    Button,
    IconButton,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';
import type { IGeneralSettingsForm } from './types/settings';

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 600,
        },
        infoText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '20px',
        },
        imagePreview: {
            borderRadius: '2px',
            boxSizing: 'border-box',
            marginBottom: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '70px',
            width: '102px',
            padding: '10px',
        },
        lightImagePreview: { backgroundColor: Tokens.Colors.ColorBackgroundLight },
        darkImagePreview: { backgroundColor: Tokens.Colors.ColorBackgroundDark },
        flex: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },
        button: { marginRight: '16px' },
        removeButton: {
            marginRight: '16px',
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            color: theme.palette.getContrastText(theme.palette.semantic.colorErrorIcon),
            maxHeight: '20px',
            maxWidth: '20px',
            marginLeft: '-12px',
            marginTop: '-8px',
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '16px 0px 8px 0',
        },
        chipSpacer: { marginLeft: '8px' },
    }),
);

const FILE_SIZE_LIMIT = 1024 * 1024;

const THEMES = [ 'light', 'dark' ];

const EnableAirgappedLicenseActivation = getFeatureFlagValue(Features.EnableOffersRevampAdmin.name);

const EnableLogoDatabaseStorage = getFeatureFlagValue(Features.EnableLogoDatabaseStorage.name);

const CobrandingComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        theme: apolloTheme, themeId,
    } = useApolloTheme();

    const [ error, setError ] = useState<string | undefined>();

    const {
        register, setValue, watch, control, formState,
    } = useFormContext<IGeneralSettingsForm>();

    const { includesLicense } = useCheckLicense();

    const showUpgrade = useMemo(() =>
        EnableAirgappedLicenseActivation && includesLicense([ AccountLicense.FREEKIT, AccountLicense.COMMUNITY ]),
    [ includesLicense ]);

    const usingLightBaseTheme = useCallback((theme: string) => theme === 'light' || theme === 'light-hc', []);

    const logoKey = useCallback((theme: string) => EnableLogoDatabaseStorage ? `${theme}Logo.base64` : `${theme}Logo.url`, []);

    const onFileSelected = useCallback(
        async (event, theme) => {
            let uploadedFile: File;
            if (event.target.files && (uploadedFile = event.target.files[0])) {
                if (uploadedFile.size > FILE_SIZE_LIMIT) {
                    setError('CLIENT_LOGO_FILE_SIZE_ERROR');
                }

                setValue(`${theme}Logo.image`, uploadedFile);
                setValue(`${theme}Logo.url`, URL.createObjectURL(uploadedFile), { shouldDirty: true });
                setValue(`${theme}Logo.base64`, await imageUrlToBase64(uploadedFile));
            }
        },
        [ setValue ],
    );

    const onFileRemove = useCallback(
        (theme) => {
            setValue(`${theme}Logo.url`, '', { shouldDirty: true });
            setValue(`${theme}Logo.base64`, '');
        },
        [ setValue ],
    );

    return (
        <>
            <div className={classes.titleSection}>
                <Typography className={classes.title}>
                    {translate({ id: 'CLIENT_LOGO_TITLE' })}
                </Typography>
                {showUpgrade &&
                    <UiUpgradeChip
                        licenseType={AccountLicense.PRO}
                        className={classes.chipSpacer}
                        title={translate({ id: 'CLIENT_CHIP_LOGO_CUSTOMIZATION_HEADER' })}
                        description={translate({ id: 'CLIENT_CHIP_LOGO_CUSTOMIZATION_DESCRIPTION' })}
                        iconDescription={translate({ id: 'CLIENT_CHIP_LOGO_CUSTOMIZATION_ICON_DESCRIPTION' })}
                        icon={<ColorLensOutlinedIcon />}
                        telemetryTitle={TelemetryTitle.OrganizationSettings.General} />}
            </div>
            <Typography className={classes.infoText}>
                {translate({ id: 'CLIENT_LOGO_INFO' })}
            </Typography>
            {error ? <Typography color="error">
                {translate({ id: error })}
            </Typography> : undefined}
            <div className={classes.flex}>
                {THEMES.map((theme) =>
                    (
                        watch(logoKey(theme)) && <div
                            key={`image-preview-${theme}`}
                            className={classes.flex}>
                            <div
                                className={clsx(
                                    classes.imagePreview,
                                    usingLightBaseTheme(theme) ? classes.lightImagePreview : classes.darkImagePreview,
                                )}
                                style={
                                    theme === themeId
                                        ? { border: `1px solid ${apolloTheme.palette.semantic.colorBorderDeEmp}` }
                                        : undefined
                                }
                                data-cy={`image-preview-${theme}`}
                            >
                                {EnableLogoDatabaseStorage ? <Controller
                                    key={Date.now()}
                                    control={control}
                                    name={logoKey(theme)}
                                    render={(props) =>
                                        <img
                                            src={props.value}
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                            }}
                                            alt={`${theme} logo`}
                                        />}
                                /> : <img
                                    src={watch(logoKey(theme))}
                                    style={{
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                    alt={`${theme} logo`}
                                />}
                            </div>
                            <Tooltip
                                arrow
                                title={translate({ id: `CLIENT_LOGO_REMOVE_${theme.toUpperCase()}` })}>
                                <IconButton
                                    size="small"
                                    className={classes.removeButton}
                                    data-cy={`remove-button-${theme}`}
                                    onClick={() => onFileRemove(theme)}
                                >
                                    <RemoveIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    ))}
            </div>
            <div className={classes.flex}>
                {THEMES.map((theme) => <div key={`file-input-${theme}`}>
                    <input
                        ref={register()}
                        style={{ display: 'none' }}
                        name={`${theme}Logo.url`} />
                    <input
                        id={`file-input-${theme}`}
                        key={Date.now()}
                        type="file"
                        style={{ display: 'none' }}
                        accept=".png,.jpeg,.jpg,.svg"
                        onChange={(event) => onFileSelected(event, theme)}
                        data-cy={`file-input-${theme}`}
                    />
                    <label htmlFor={`file-input-${theme}`}>
                        <Button
                            className={classes.button}
                            variant="outlined"
                            component="span"
                            disabled={showUpgrade}
                            data-cy={`upload-update-button-${theme}`}
                        >
                            {translate({
                                id: `CLIENT_LOGO_${
                                    watch(`${theme}Logo.url`) ? 'UPDATE' : 'UPLOAD'
                                }_${theme.toUpperCase()}`,
                            })}
                        </Button>
                    </label>
                </div>)}
            </div>
        </>
    );
};

export default CobrandingComponent;
