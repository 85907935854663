import { portalTelemetry } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as RouteNames from '../../../../../src/common/constants/RouteNames';
import { ReactComponent as UpgradeOrangeIcon } from '../../../../../src/images/upgradeOrange.svg';
import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import {
    accountLogicalName,
    userGlobalId,
} from '../../../../store/selectors';
import { services } from '../constant/EnterpriseTrailConstant';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        root: {
            width: '100%',
            padding: '25px 0px 25px 0px',
            borderRadius: '3px',
            marginTop: '10px',
        },
        accordionContent: {
            flexDirection: 'column',
            width: '100%',
        },
        header: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
        },
        upgradeOrangeIcon: {
            width: '22px',
            height: '22px',
            marginRight: '7px',
        },
        subHeader: { fontSize: '12.5px' },
        checkIconWithBenefits: {
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
        },
        checkIcon: {
            color: theme.palette.semantic.colorSuccessIcon,
            marginRight: '10px',
        },
        launchIcon: {
            marginLeft: '5px',
            marginTop: '5px',
            width: '17px',
            height: '17px',
        },
        buttonContainer: {
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        learnMoreLink: {
            fontWeight: 600,
            marginRight: '15px',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': {
                textDecoration: 'none',
                color: theme.palette.semantic.colorForegroundLinkPressed,
            },
        },
    }),
}));

export const NeedMoreServicesComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const accountName = useSelector(accountLogicalName);

    const userId = useSelector(userGlobalId);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <div
            className={classes.root}
            data-cy="try-enterprise-trial-accordion">
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon data-cy="accordion-expand-icon" />}>
                    <div className={classes.accordionContent}>
                        <Typography
                            className={classes.header}
                            data-cy="header-text">
                            <UpgradeOrangeIcon className={classes.upgradeOrangeIcon} />
                            {translate({ id: 'CLIENT_NEED_MORE_SERVICES' })}
                        </Typography>
                        <Typography
                            className={classes.subHeader}
                            data-cy="sub-header-text">
                            {translate({ id: 'CLIENT_UPGRADE_TO_ACCESS_MORE' })}
                        </Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div
                        className={classes.accordionContent}
                        data-cy="enterprise-trial-services">
                        <div>
                            {services.map(service => (
                                <>
                                    <Typography
                                        className={classes.checkIconWithBenefits}
                                        id={`linkTo${service.text}`}>
                                        <CheckIcon className={classes.checkIcon} />
                                        {translate({ id: service.text })}
                                        <a
                                            className={classes.a}
                                            href={service.link}
                                            aria-labelledby={`linkTo${service.text}`}>
                                            <LaunchIcon
                                                className={classes.launchIcon}
                                                data-cy={service.dataCy} />
                                        </a>
                                    </Typography>
                                </>
                            ))}
                        </div>
                        <div className={classes.buttonContainer}>
                            <a
                                className={clsx(classes.learnMoreLink, classes.a)}
                                target="_blank"
                                rel="noreferrer"
                                href="https://docs.uipath.com/automation-cloud/docs/about-licensing#robots--services-licenses"
                                data-cy="learn-more-link"
                            >
                                {translate({ id: 'CLIENT_LEARN_MORE' })}
                            </a>
                            <Button
                                type="submit"
                                key="primaryButton"
                                variant="contained"
                                data-cy="start-trial-button"
                                onClick={() => {
                                    portalTelemetry.trackTrace({
                                        message: `Clicked on start trial button to open request enterprise trial form with account name ${accountName}`,
                                        severityLevel: SeverityLevel.Information,
                                    }, { revampEnabled: isAdminRevampEnabled });
                                    portalTelemetry.trackTrace({
                                        message: `Need more Services form: ${userId} requested enterprise trial`,
                                        severityLevel: SeverityLevel.Information,
                                    }, { revampEnabled: isAdminRevampEnabled });
                                    history.push(getRoute(RouteNames.TenantLicensingUpgradeToEnterprise));
                                }}
                            >
                                {translate({ id: 'CLIENT_START_TRIAL' })}
                            </Button>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
