import {
    AccountLicense,
    ContactUsLinks,
} from '@experiences/constants';
import {
    useLocalizedLinks,
    useRouteResolver,
} from '@experiences/util';
import {
    Button,
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import detectiveRobot from '../../images/detectiveRobot.png';
import flyingRobot from '../../images/flyingRobot.png';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';

const useStyles = makeStyles((theme) =>
    createStyles({
        mainContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '70%',
        },
        link: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundLink,
            '&:hover': { textDecoration: 'underline' },
        },
        header: {
            fontFamily: Tokens.FontFamily.FontTitle,
            fontWeight: 600,
            fontSize: '20px',
            textAlign: 'center',
        },
        description: {
            fontFamily: Tokens.FontFamily.FontTitle,
            fontWeight: 400,
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '16px',
        },
        spacer: { marginTop: '16px' },
        oldAdminSpacing: {
            position: 'absolute',
            left: '45%',
            top: '200px',
        },
        '@media all and (max-width: 560px)': {
            oldAdminSpacing: {
                position: 'static',
                marginTop: '60px',
            },
        },
    }),
);

const UpgradeForFeature: React.FC<{
    upgradeTitle: string;
    upgradeMessage: string;
    documentationLink?: string;
    level: AccountLicense.PRO | AccountLicense.ENTERPRISE;
    telemetryTitle: string;
}> = ({
    upgradeTitle, upgradeMessage, documentationLink, level, telemetryTitle,
}) => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const getLocalizedLink = useLocalizedLinks();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();
    const upgradeToEnterprise = useMemo(() => level === AccountLicense.ENTERPRISE, [ level ]);

    logEvent(telemetryTitle, { UpgradePageLicense: AccountLicense[level] });

    const upgradeButton = useCallback(() => {

        // Sets the logEvent title to event name (from prop) + contact or upgrade depending on the license tier
        logEvent(`${telemetryTitle}${upgradeToEnterprise ? 'Contact' : 'Upgrade'}`, { UpgradePageLicense: AccountLicense[level] });

        upgradeToEnterprise ? window.open(getLocalizedLink(ContactUsLinks), '_blank', 'noopener,noreferrer')
            : history.push(getRoute(RouteNames.BuyPro));
    }, [ getLocalizedLink, getRoute, history, level, logEvent, telemetryTitle, upgradeToEnterprise ]);

    return (
        <div className={clsx(classes.mainContainer, !isAdminRevampEnabled && classes.oldAdminSpacing)}>
            <div
                className={classes.mainContainer}
                style={{ maxWidth: '500px' }}>
                <Typography
                    className={classes.header}
                    data-cy='upgrade-title'>
                    {upgradeTitle}
                </Typography>
                <Typography
                    className={clsx(classes.description, classes.spacer)}
                    data-cy='upgrade-message'>
                    {upgradeMessage}
                </Typography>
                <img
                    src={upgradeToEnterprise ? detectiveRobot : flyingRobot}
                    alt='UiPath robot'
                    className={classes.spacer}
                />
                <Button
                    onClick={upgradeButton}
                    className={classes.spacer}
                    variant='contained'
                    style={{ width: '166px' }}
                    data-cy='upgrade-button'>
                    {translate({ id: level === AccountLicense.ENTERPRISE ? 'CLIENT_CONTACT_SALES' : 'CLIENT_UPGRADE' })}
                </Button>
                {documentationLink && <Typography
                    component={Link}
                    target='_blank'
                    href={documentationLink}
                    onClick={() => logEvent(`${telemetryTitle}Docs`)}
                    className={clsx(classes.link, classes.spacer)}
                    data-cy='upgrade-link'>
                    {translate({ id: 'CLIENT_VIEW_DOCUMENTATION' })}
                </Typography>}
            </div>
        </div>
    );
};

export default UpgradeForFeature;
