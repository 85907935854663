import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
            marginBottom: '24px',
        },
        button: {
            backgroundColor: theme.palette.semantic.colorBackground,
            padding: '4px',
            margin: '2px',
            borderRadius: '50%',
        },
        allowClick: { pointerEvents: 'auto' },
        summary: {
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        summaryText: {
            flex: 1,
            padding: '12px',
            margin: 'auto',
            fontSize: '18px',
            fontWeight: 600,
        },
        autoCenter: { margin: 'auto 16px auto 0px' },
        details: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0px 32px 48px 32px',
        },
        detailsAndButton: { marginRight: '12px' },
        detailBody: { marginBottom: '64px' },
    }),
);

const UiFREBanner: React.FC<{
    summary: React.ReactNode;
    details: React.ReactNode;
    image?: React.ReactNode;
    action?: React.ReactNode;
    close?: () => void;
}> = ({
    summary, details, image, action, close,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ expanded, setExpanded ] = useState(true);

    return (
        <Accordion
            className={classes.root}
            expanded={expanded}
            onChange={(e, exp) => setExpanded(exp)}
            data-cy="ui-fre-banner"
        >
            <AccordionSummary
                className={classes.summary}
                onClick={e => {
                    e.stopPropagation();
                }}
                expandIcon={<ExpandMoreIcon
                    onClick={() => setExpanded(!expanded)}
                    data-cy="ui-fre-banner-summary" />}
                aria-label={translate({ id: 'CLIENT_TOGGLE_BANNER_OPEN_CLOSE' })}
                classes={{ expandIconWrapper: clsx(classes.button, classes.allowClick) }}
            >
                {!expanded && (
                    <>
                        <Typography
                            className={classes.summaryText}
                            variant="h4">
                            {summary}
                        </Typography>
                        <div className={clsx(classes.autoCenter, classes.allowClick)}>
                            {action}
                        </div>
                        <span className={classes.autoCenter}>
                            <IconButton
                                className={clsx(classes.button, classes.allowClick)}
                                aria-label={translate({ id: 'CLIENT_CLOSE' })}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (close) {
                                        close();
                                    }
                                }}
                                data-cy="ui-fre-banner-close-button"
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    </>
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.detailsAndButton}>
                    <div className={classes.detailBody}>
                        {details}
                    </div>
                    <div>
                        {action}
                    </div>
                </div>
                {image}
            </AccordionDetails>
        </Accordion>
    );
};

export default UiFREBanner;
