import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
    Box,
    Button,
    IconButton,
    InputLabel,
    List,
    ListItem,
    TextField,
    Typography,
} from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useCheckAuthenticationSetting } from '../../../common/hooks/useCheckAuthenticationSetting';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IFieldAttribute } from '../../../common/interfaces/cis/attribute';
import { AttributeType } from '../../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import type { ISaml2ProviderSettings } from '../../../common/interfaces/externalIdentity';
import type { IAuthenticationSettingPayload } from '../../../services/identity/AuthenticationSettingService';
import {
    AuthenticationSettingType,
    BulkAuthenticationSettingKey,
    updateDirectoryIntegrationSetting,
    updateDirectoryIntegrationSettingSaml2,
} from '../../../services/identity/AuthenticationSettingService';
import { accountGlobalId } from '../../../store/selectors';
import { mapSamlFormDataToAuthSettingPayload } from '../../../util/setting/AuthSettingUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';

const classes = {
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    cancelButton: {
        marginRight: '10px',
        width: '102px',
    },
    container: { maxWidth: '480px' },
    description: {
        marginTop: '8px',
        marginBottom: '10px',
    },
    listItem: {
        padding: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        marginBottom: '8px',
        alignItems: 'start',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    title: {
        fontSize: '18px',
        lineHeight: '24px',
        fontWeight: 600,
    },
};

interface IAttributeMappingForm {
    attributes: IFieldAttribute[];
}

const AttributeMapping: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const match = useRouteMatch<{ type: string }>();
    const type = match.params.type;
    const partitionGlobalId = useSelector(accountGlobalId);
    const createNotification = useUiSnackBar();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const handleError = useCallback(
        async error => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    const bulkAuthenticationSetting = useCheckAuthenticationSetting();
    const {
        isAAD, isSaml,
    } = useMemo(
        () => ({
            isAAD: !!bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.AAD],
            isSaml: !!bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML],
        }),
        [ bulkAuthenticationSetting ],
    );

    const methods = useForm<IAttributeMappingForm>({
        mode: 'onSubmit',
        defaultValues: { attributes: [] },
    });

    const {
        control,
        formState: {
            dirtyFields, isSubmitting,
        },
        errors,
        handleSubmit,
        watch,
        register,
        setValue,
        getValues,
        reset,
    } = methods;

    const {
        fields, append, remove,
    } = useFieldArray({
        control,
        name: 'attributes',
    });

    const samlAuthenticationSetting = bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML];
    const aadAuthenticationSetting = bulkAuthenticationSetting?.[AuthenticationSettingType.AAD];

    const externalIdentityProviderDtoSettings: ISaml2ProviderSettings | undefined = useMemo(() => {
        if (!samlAuthenticationSetting?.externalIdentityProviderDto?.settings) {
            return undefined;
        }

        try {
            return JSON.parse(samlAuthenticationSetting.externalIdentityProviderDto.settings) as ISaml2ProviderSettings;
        } catch (error) {
            handleError(error);
        }
    }, [ samlAuthenticationSetting, handleError ]);

    useEffect(() => {
        if (bulkAuthenticationSetting && isAAD) {
            // populate fields for aad
            const configuration = aadAuthenticationSetting?.directoryConnectionDto.configuration;
            const configurationJson = JSON.parse(configuration ?? '');
            if (configurationJson?.AADProvisioningAttributeMapper?.ExtensionUserAttributeMappings) {
                const initial: IFieldAttribute[] = [];
                for (const [ key, value ] of Object.entries(configurationJson?.AADProvisioningAttributeMapper?.ExtensionUserAttributeMappings)) {
                    initial.push({
                        attributeType: key as AttributeType,
                        value: value as string,
                    });
                }
                reset({ attributes: initial });
            }
        } else if (bulkAuthenticationSetting && isSaml) {
            // populate fields for saml
            const settings = samlAuthenticationSetting?.externalIdentityProviderDto?.settings;
            const settingsJson = JSON.parse(settings ?? '');
            if (settingsJson.ProvisioningSetting.AttributeMapper.ExtensionUserAttributeMappings) {
                const initial: IFieldAttribute[] = [];
                for (const [ key, value ] of Object.entries(settingsJson.ProvisioningSetting.AttributeMapper.ExtensionUserAttributeMappings)) {
                    initial.push({
                        attributeType: key as AttributeType,
                        value: value as string,
                    });
                }
                reset({ attributes: initial });
            }
        }
    }, [
        aadAuthenticationSetting?.directoryConnectionDto.configuration,
        bulkAuthenticationSetting,
        isAAD,
        isSaml,
        partitionGlobalId,
        reset,
        samlAuthenticationSetting?.externalIdentityProviderDto?.settings,
    ]);

    const getSamlAuthenticationPayload = useCallback((data: IAttributeMappingForm) => {
        const configurationPayload: { [key: string]: string } = {};
        if (data.attributes) {
            for (const attribute of data.attributes) {
                configurationPayload[attribute.attributeType] = attribute.value;
            }
        }
        return mapSamlFormDataToAuthSettingPayload(
            {
                ...externalIdentityProviderDtoSettings,
                ProvisioningSetting: {
                    AccountLinkConfirmation:
                        externalIdentityProviderDtoSettings?.ProvisioningSetting?.AccountLinkConfirmation,
                    AllowedDomains: externalIdentityProviderDtoSettings?.ProvisioningSetting?.AllowedDomains?.join(', '),
                    AttributeMapper: {
                        ...externalIdentityProviderDtoSettings?.ProvisioningSetting?.AttributeMapper,
                        ExtensionUserAttributeMappings: configurationPayload,
                    },
                },
            } as ISamlFormData,
            partitionGlobalId,
            bulkAuthenticationSetting?.[BulkAuthenticationSettingKey.SAML]?.externalIdentityProviderDto?.id,
        );
    }, [
        bulkAuthenticationSetting,
        externalIdentityProviderDtoSettings,
        partitionGlobalId,
    ]);

    const getAADAuthenticationPayload = useCallback((data: IAttributeMappingForm) => {
        const authority = aadAuthenticationSetting?.externalIdentityProviderDto?.authority;
        const matchedValues = authority?.match(/.com\/(.*)\/v/);
        const authenticationSettingData = {
            tenantID: (matchedValues && matchedValues.length > 1 && matchedValues[1]) || '',
            applicationID: aadAuthenticationSetting?.externalIdentityProviderDto.clientId,
            applicationSecret: aadAuthenticationSetting?.externalIdentityProviderDto.clientSecret,
        };
        const payload: IAuthenticationSettingPayload = {
            type: AuthenticationSettingType.AAD,
            displayName: AuthenticationSettingType.AAD,
            partitionGlobalId,
            clientId: authenticationSettingData.applicationID?.trim(),
            clientSecret: authenticationSettingData.applicationSecret?.trim(),
            tenantId: authenticationSettingData.tenantID?.trim(),
        };
        const configurationPayload: { [key: string]: string } = {};
        if (data.attributes) {
            for (const attribute of data.attributes) {
                configurationPayload[attribute.attributeType] = attribute.value;
            }
        }
        payload.extensionUserAttributeMappings = configurationPayload;
        return payload;
    }, [
        aadAuthenticationSetting,
        partitionGlobalId,
    ]);

    const onSubmit = useCallback(async (data: IAttributeMappingForm) => {
        try {
            let payload;
            if (isAAD) {
                payload = getAADAuthenticationPayload(data);
                await updateDirectoryIntegrationSetting(payload);
            } else {
                payload = getSamlAuthenticationPayload(data);
                await updateDirectoryIntegrationSettingSaml2(payload);
            }
            history.push(getRoute(RouteNames.SecuritySettings));
            createNotification(translate({ id: 'CLIENT_SAVE_SUCCESSFUL' }), notificationType.SUCCESS);
        } catch (error) {
            await handleError(error);
        }
    }, [ createNotification, getAADAuthenticationPayload, getRoute, getSamlAuthenticationPayload, handleError, history, isAAD, translate ]);

    const attributes: IFieldAttribute[] = useMemo(() => watch('attributes'), [ watch ]);

    const addMapping = useCallback(() => {
        append({
            attributeType: '',
            value: '',
        });
    }, [ append ]);

    const removeMapping = useCallback(
        (index: number) => {
            setValue(
                'attributes',
                attributes.filter((_, i) => i !== index),
            );
        },
        [ attributes, setValue ],
    );

    const goBack = useCallback(() => {
        history.push(getRoute(RouteNames.SecuritySettings));
    }, [ history, getRoute ]);

    // Other options to be added later
    const attributeTypeOptions: { [key: string]: string } = useMemo(() => ({
        [AttributeType.BUSINESS]: 'CLIENT_ATTRIBUTE_BUSINESS',
        // [AttributeType.JOB]: 'CLIENT_ATTRIBUTE_JOB',
        // [AttributeType.LOCATION]: 'CLIENT_ATTRIBUTE_LOCATION',
    }), []);

    const currentOptions = useCallback((option: AttributeType | undefined) => {
        const currentAttributes = getValues();
        if (Object.keys(currentAttributes).length === 0) {
            currentAttributes.attributes = [];
        }
        const inUse: AttributeType[] = currentAttributes.attributes.map(obj => obj.attributeType);
        const filtered = Object.keys(attributeTypeOptions)
            .filter(key => key === option || !inUse.includes(key as AttributeType))
            .reduce((cur: any, key: AttributeType | string | number) =>
                Object.assign(cur, { [key]: attributeTypeOptions[key] }), {});
        return filtered;
    }, [ attributeTypeOptions, getValues ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.SecuritySettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.SecuritySettingsAttributeMapping,
            name: translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_2' }),
        },
    ];

    const optionsIsEmpty = Object.keys(currentOptions(undefined)).length === 0;
    const hasMoreRowsThanSelectedOptions = fields.length >= Object.keys(currentOptions(undefined)).length;
    const isAddingMapDisabled = optionsIsEmpty || hasMoreRowsThanSelectedOptions;

    return <UiPageContainer
        header={AdminBreadCrumbs(breadCrumbLinks)}
        disableGutter={[ 'bottom', 'left', 'right' ]}
    >
        <UiForm
            bodyMaxWidth='536px'
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <Box sx={classes.actions}>
                    <Button
                        sx={classes.cancelButton}
                        onClick={goBack}
                        color="primary"
                        data-cy='attribute-mapping-cancel-button'>
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        type='submit'
                        sx={{
                            width: '120px',
                            marginRight: '24px',
                        }}
                        loading={isSubmitting}
                        disabled={Object.keys(dirtyFields).length === 0 || Object.keys(errors).length > 0}
                        variant='contained'
                        data-cy='attribute-mapping-save-button'
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </Box>
            }
            centerChild
        >
            <Typography
                variant='h3'
                sx={classes.title}
            >
                {translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_2' })}
            </Typography>
            <Box sx={classes.description}>
                <Typography data-cy='attribute-mapping-description'>
                    {translate({ id: type === 'aad' ? 'CLIENT_ATTRIBUTE_DESCRIPTION_AZURE_AD' : 'CLIENT_ATTRIBUTE_DESCRIPTION_SAML' })}
                </Typography>
                {/* <Typography>
                    <Link
                        href="TODO: get documentation link"
                        target="_blank"
                        data-cy="learn-more-attribute-mapping-link"
                    >
                        {translate({ id: 'CLIENT_LEARN_MORE' })}
                    </Link>
                </Typography> */}
            </Box>
            <List>
                {fields.map((field, i) => (
                    <ListItem
                        key={field.id}
                        sx={classes.listItem}
                        data-cy={`attribute-list-item-${i}`}>
                        <Box>
                            { i === 0 && <InputLabel id={`attribute-value-${i}`}>
                                {translate({ id: type === 'aad' ? 'CLIENT_ATTRIBUTE_FIELD_AAD' : 'CLIENT_ATTRIBUTE_FIELD_SAML' })}
                            </InputLabel>}
                            <TextField
                                required
                                sx={{ width: '210px' }}
                                name={`attributes.${i}.value`}
                                inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                                error={!!errors.attributes?.[i]?.value}
                                helperText={errors.attributes?.[i]?.value?.message}
                                defaultValue={field.value}
                                variant="outlined"
                                data-cy={`attributes-value-${i}`}
                                aria-labelledby={`attribute-value-${i}`}
                            />
                        </Box>
                        <ArrowForwardIcon sx={i === 0 ? { margin: '44px 0px 8px 0px' } : { margin: '8px 0px' }} />
                        <Box sx={{ minWidth: '210px' }}>
                            <UiSelect
                                required
                                name={`attributes.${i}.attributeType`}
                                control={control}
                                error={!!errors.attributes?.[i]?.attributeType}
                                helperText={errors.attributes?.[i]?.attributeType && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                inputLabel={i === 0 ? translate({ id: 'CLIENT_ATTRIBUTE_CUSTOM' }) : undefined}
                                placeholder={translate({ id: 'CLIENT_SELECT' })}
                                options={currentOptions(getValues(`attributes.${i}.attributeType`))}
                                defaultValue={field.attributeType}
                                fullWidth
                                dataCy={`ui-select-attribute-type-${i}`}
                            />
                        </Box>
                        <IconButton
                            sx={i === 0 ? { marginTop: '36px' } : undefined}
                            onClick={() => removeMapping(i)}
                            data-cy={`remove-mapping-button-${i}`}
                        >
                            <DeleteOutlineIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>

            <Button
                sx={{ width: 'fit-content' }}
                disabled={isAddingMapDisabled}
                variant="text"
                startIcon={<AddIcon />}
                onClick={() => addMapping()}
                data-cy="add-mapping-button">
                {translate({ id: 'CLIENT_ADD_NEW_MAPPING' })}
            </Button>
        </UiForm>
    </UiPageContainer>;
};

export default AttributeMapping;
