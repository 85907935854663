import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { isString } from 'lodash';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import forceLogout from '../../../auth/utils/ForceLogout';
import { deleteOrganization } from '../../../services/organization/OrganizationService';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { OrgDeleteAlertDialog } from './OrgDeleteAlertDialog';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteOrgReasonsDialog: { maxWidth: '539px' },
        rowGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        reasonChoices: {
            marginTop: '14px',
            maxHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        reasonTextField: {
            width: '100%',
            marginTop: '12px',
            marginBottom: '15px',
        },
        buttonContainer: {
            display: 'flex',
            marginTop: '10px',
            justifyContent: 'flex-end',
        },
        cancelButton: { marginRight: '10px' },
        deleteOrgConfirmationDialog: {
            maxWidth: '465px',
            maxHeight: '495px',
        },

        deleteOrgConfirmationMessage: { marginTop: '20px' },
        TextField: {
            marginTop: '8px',
            display: 'flex',
            width: '100%',
        },
    }),
);

interface IDeleteOrganizationReasonPayload {
    deleteReason: string;
    deleteReasonText?: string;
}

enum Reasons {
    NoBudget = 'noBudget',
    NoTrial = 'noTrial',
    NoNeedCater = 'noNeedCater',
    AnotherProduct = 'anotherProduct',
    NoSupport = 'noSupport',
    DiffReason = 'diffReason',
}

const reasonMap: Record<string, string> = {
    nobudget: 'CLIENT_DELETE_ORGANIZATION_REASON_NOBUDGET',
    noTrial: 'CLIENT_DELETE_ORGANIZATION_REASON_NONEED_TRIAL',
    noNeedCater: 'CLIENT_DELETE_ORGANIZATION_REASON_NOCATER_NEEDS',
    anotherProduct: 'CLIENT_DELETE_ORGANIZATION_REASON_MOVINGTO_ANOTHER_PRODUCT',
    noSupport: 'CLIENT_DELETE_ORGANIZATION_REASON_NO_PROPER_SUPPORT',
    diffReason: 'CLIENT_DELETE_ORGANIZATION_REASON_DIFF_REASON',
};

const OrganizationDeleteReasonsDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog,
    orgName,
    tenantList,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const setErrorMessage = useCentralErrorSetter();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const { logEvent } = useTelemetryHelper();

    const [ deleteConfirmtextFieldValue, setDeleteConfirmtextFieldValue ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ clickedContinue, setClickedContinue ] = useState(false);
    const [ deleteOrgFail, setDeleteOrgFail ] = useState(false);

    const {
        control, handleSubmit, watch, formState,
    } = useForm<IDeleteOrganizationReasonPayload>({
        mode: 'onChange',
        defaultValues: {
            deleteReason: '',
            deleteReasonText: undefined,
        },
        shouldUnregister: false,
    });

    const {
        isValid, errors,
    } = formState;

    const {
        deleteReason, deleteReasonText,
    } = useMemo(() => watch([ 'deleteReason', 'deleteReasonText' ]), [ watch ]);

    const deleteConfirmhandleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteConfirmtextFieldValue(event.target.value);
    }, []);

    const onSubmit = useCallback(
        async (data: IDeleteOrganizationReasonPayload) => {
            setLoading(true);

            const deletionQuestion = data.deleteReason !== Reasons.DiffReason
                ? translate({ id: reasonMap[data.deleteReason] })
                : data.deleteReasonText ?? '';
            logEvent('Delete Org Confirm Submitted', { reason: deletionQuestion });

            try {
                const response = await deleteOrganization(deletionQuestion);
                if (response.status === 204 || response.status !== 202) {
                    closeDialog();
                    forceLogout();
                } else {
                    setDeleteOrgFail(true);
                }
            } catch (error) {
                const errorObject = await getErrorObject(error);
                const errorData = errorObject.response?.data;
                const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
            } finally {
                setLoading(false);
            }
        },
        [ logEvent, translate, closeDialog, getErrorObject, getErrorMessage, setErrorMessage ],
    );

    const listItems = useMemo(
        () => {
            if (!tenantList) {
                return '';
            }

            return tenantList.length < 3
                ? tenantList.map((d: any) => d.name).join(' and ')
                : `${tenantList[0].name}, ${tenantList[1].name} and ${tenantList.length - 2} more`;
        },
        [ tenantList ],
    );

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!clickedContinue && (
                    <div className={classes.deleteOrgReasonsDialog}>
                        <Typography data-cy="select-delete-org-reason">
                            {translate({ id: 'CLIENT_DELETE_ORGANIZATION_SELECT_REASON' })}
                        </Typography>

                        <Controller
                            control={control}
                            name="deleteReason"
                            rules={{ required: true }}
                            helperText={errors.deleteReason?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            render={({
                                onChange, value,
                            }) => (
                                <RadioGroup
                                    data-cy="delete-reason-radio-buttons"
                                    className={classes.rowGroup}
                                    value={value}
                                    onChange={event => {
                                        onChange(event.target.value);
                                    }}
                                >
                                    {Object.entries(reasonMap).map(([ reasonId, reasonValue ], i) => (
                                        <FormControlLabel
                                            key={i}
                                            className={classes.reasonChoices}
                                            control={<Radio
                                                value={reasonId}
                                                checked={value === reasonId}
                                                color="primary" />}
                                            label={translate({ id: reasonValue })}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                        />

                        <Controller
                            as={TextField}
                            control={control}
                            name="deleteReasonText"
                            className={classes.reasonTextField}
                            multiline
                            autoComplete="off"
                            rows={2}
                            required={deleteReason === Reasons.DiffReason}
                            rules={{
                                maxLength: 256,
                                required: deleteReason === Reasons.DiffReason,
                            }}
                            error={!!errors.deleteReasonText}
                            helperText={
                                errors.deleteReasonText?.type === 'maxLength' && translate({ id: 'CLIENT_DELETE_REASON_LENGTH_ERROR' })
                            }
                            variant="outlined"
                            placeholder={translate({ id: 'CLIENT_HINT_TEXT' })}
                            disabled={deleteReason !== Reasons.DiffReason}
                            data-cy="delete-organization-reasons-textfield"
                        />

                        <div className={classes.buttonContainer}>
                            <Button
                                key="cancelButton"
                                className={classes.cancelButton}
                                data-cy="delete-organization-reasons-cancel-button"
                                onClick={() => {
                                    logEvent('Delete Org Reason Cancelled');
                                    closeDialog();
                                }}
                                color="primary"
                            >
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>

                            <Button
                                key="primaryButton"
                                variant="contained"
                                data-cy="delete-organization-reasons-continue-button"
                                onClick={() => {
                                    logEvent('Delete Org Reason Submitted');
                                    setClickedContinue(true);
                                }}
                                disabled={!isValid || (deleteReason === Reasons.DiffReason && !deleteReasonText)}
                            >
                                {translate({ id: 'CLIENT_CONTINUE' })}
                            </Button>
                        </div>
                    </div>
                )}

                {clickedContinue && (
                    <>
                        <div
                            className={classes.deleteOrgConfirmationDialog}
                            data-cy="delete-org-confirmation-dialog">
                            <Typography data-cy="delete-org-confirmation-dialog-consequence-one">
                                <FormattedMessage
                                    id="CLIENT_DELETE_ORGANIZATION_CONSEQUENCE_ONE"
                                    values={{
                                        b: (chunk: string) => <b>
                                            {chunk}
                                        </b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                style={{ fontWeight: 800 }}
                                data-cy="delete-org-confirmation-dialog-consequence-one-list">
                                <ul>
                                    <FormattedMessage
                                        id="CLIENT_DELETE_ORGANIZATION_CONSEQUENCE_ONE_LIST"
                                        values={{
                                            li: (chunk: string) => <li>
                                                {chunk}
                                            </li>,
                                            0: tenantList?.length,
                                            1: listItems,
                                        }}
                                    />
                                </ul>
                            </Typography>
                            <Typography className={classes.deleteOrgConfirmationMessage}>
                                <FormattedMessage
                                    id="CLIENT_DELETE_ORGANIZATION_CONSEQUENCE_ONE_EXTEND"
                                    values={{
                                        b: (chunk: string) => <b style={{ color: 'red' }}>
                                            {chunk}
                                        </b>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                className={classes.deleteOrgConfirmationMessage}
                                data-cy="delete-org-confirmation-dialog-consequence-two"
                            >
                                <FormattedMessage
                                    id="CLIENT_DELETE_ORGANIZATION_CONSEQUENCE_TWO"
                                    values={{
                                        strong: (chunk: string) => <strong>
                                            {chunk}
                                        </strong>,
                                    }}
                                />
                            </Typography>
                            <Typography
                                className={classes.deleteOrgConfirmationMessage}
                                data-cy="delete-org-confirmation-dialog-consequence-three"
                            >
                                {translate({ id: 'CLIENT_DELETE_ORGANIZATION_CONSEQUENCE_THREE' })}
                            </Typography>
                            <Typography
                                className={classes.deleteOrgConfirmationMessage}
                                data-cy="delete-org-type-reminder">
                                <FormattedMessage
                                    id="CLIENT_DELETE_ORGANIZATION_TYPE_REMINDER"
                                    values={{
                                        strong: (chunk: string) => <strong>
                                            {chunk}
                                        </strong>,
                                        0: orgName,
                                    }}
                                />
                            </Typography>

                            <TextField
                                className={classes.TextField}
                                autoComplete="off"
                                variant="outlined"
                                id="standard-name"
                                onChange={deleteConfirmhandleChange}
                                value={deleteConfirmtextFieldValue}
                                data-cy="delete-organization-confirmation-textfield"
                                inputProps={{ 'aria-label': translate({ id: 'CLIENT_DELETE_ORGANIZATION_CONFIRM_INPUT_ARIA_LABEL' }) }}
                            />

                            <div className={classes.buttonContainer}>
                                <Button
                                    key="cancelButton"
                                    className={classes.cancelButton}
                                    color="primary"
                                    data-cy="delete-organization-confirmation-cancel-button"
                                    onClick={() => {
                                        logEvent('Delete Org Confirm Cancelled');
                                        closeDialog();
                                    }}
                                >
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>

                                <UiProgressButton
                                    type="submit"
                                    loading={loading}
                                    key="primaryButton"
                                    variant="contained"
                                    data-cy="delete-organization-confirmation-delete-button"
                                    disabled={deleteConfirmtextFieldValue !== orgName}
                                >
                                    {translate({ id: 'CLIENT_DELETE' })}
                                </UiProgressButton>
                            </div>
                        </div>
                    </>
                )}
                {deleteOrgFail && <OrgDeleteAlertDialog />}
            </form>
        </>
    );
};

export default OrganizationDeleteReasonsDialogBody;
