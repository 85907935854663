import type { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useMemo,
    useRef,
} from 'react';
import Sticky from 'react-stickynode';

interface UiFormThemeProps {
    heightOffset?: string;
    isDrawer?: boolean;
    centerChild?: boolean;
    bodyMaxWidth?: string;
    addScrollPadding?: boolean;
}

const useStyles = makeStyles<Theme, UiFormThemeProps>(theme =>
    createStyles({
        root: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: 'inherit',
            height: 'inherit',
        },
        form: ({ heightOffset }) => ({
            flex: 1,
            overflowY: 'hidden',
            height: `calc(100% - ${heightOffset ?? '0px'})`,
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
        }),
        content: ({
            centerChild, bodyMaxWidth, isDrawer, addScrollPadding,
        }) => ({
            flex: 1,
            maxWidth: bodyMaxWidth,
            alignSelf: centerChild ? 'center' : 'normal',
            overflowY: 'auto',
            height: 'inherit',
            padding: !isDrawer && addScrollPadding ? '0px 12px' : undefined,

            scrollbarColor: `unset ${theme.palette.semantic.colorForegroundDeEmp}`,
            scrollbarWidth: 'thin',

            '&::-webkit-scrollbar': { width: '0.5em' },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.semantic.colorBackgroundGray,
                borderRadius: '10px',
            },
            '&::-webkit-scrollbar-thumb:vertical:hover': { backgroundColor: theme.palette.semantic.colorForegroundDisable },
        }),
        scrollBar: ({
            addScrollPadding, isDrawer,
        }) => ({ paddingRight: addScrollPadding && isDrawer ? '24px' : undefined }),
        actions: ({
            isDrawer, addScrollPadding,
        }) => ({
            width: `calc(100% - ${isDrawer && addScrollPadding ? '24px' : '0px'})`,
            padding: '12px 0px',
            marginTop: '12px',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        }),
    }),
);

export type Gutter = 'top' | 'right' | 'bottom' | 'left';

interface IUiFormProps extends
    React.HTMLAttributes<HTMLFormElement>,
    Omit<UiFormThemeProps, 'isScrollable'> {
    onSubmit: any;
    actions?: React.ReactNode;
    disableActionsGutters?: boolean;
    id?: string;
    dataCy?: string;
}

interface IUiPanelActionProps extends Omit<IUiFormProps, 'onSubmit'> {
    onFormSubmit?: any;
}

const UiForm: React.FC<IUiFormProps> = ({
    children,
    onSubmit,
    actions,
    disableActionsGutters,
    id,
    centerChild = false,
    isDrawer = false,
    dataCy,
    ...rest
}) => (
    <UiPanelWithActions
        onFormSubmit={onSubmit}
        actions={actions}
        disableActionsGutters={disableActionsGutters}
        id={id}
        centerChild={centerChild}
        isDrawer={isDrawer}
        dataCy={dataCy}
        {...rest}
    >
        {children}
    </UiPanelWithActions>
);

export const UiPanelWithActions: React.FC<IUiPanelActionProps> = ({
    children,
    className,
    style,
    onFormSubmit,
    actions,
    disableActionsGutters,
    heightOffset,
    id,
    centerChild,
    isDrawer,
    bodyMaxWidth,
    addScrollPadding,
    dataCy,
}) => {
    const formContentRef = useRef<HTMLDivElement | null>(null);

    const classes = useStyles({
        heightOffset,
        isDrawer,
        centerChild,
        bodyMaxWidth,
        addScrollPadding,
    });

    const contentElement = useMemo(() => (
        <div
            ref={formContentRef}
            className={clsx(classes.root, addScrollPadding && classes.scrollBar, classes.content)}
        >
            {children}
        </div>
    ),
    [
        addScrollPadding,
        children,
        classes.content,
        classes.root,
        classes.scrollBar,
        formContentRef,
    ],
    );

    const actionsElement = useMemo(
        () => (
            <Sticky
                bottomBoundary={[ '#formRoot', id ].join('-')}
                innerZ={500}>
                <div
                    className={disableActionsGutters ? undefined : classes.actions}
                    data-cy="ui-form-actions">
                    {actions}
                </div>
            </Sticky>
        ),
        [ actions, classes.actions, disableActionsGutters, id ],
    );

    return onFormSubmit ? (
        <form
            className={clsx(classes.form, className)}
            style={style}
            id={[ 'formRoot', id ].join('-')}
            onSubmit={onFormSubmit}
            noValidate
            data-cy={dataCy}>
            {contentElement}
            {actions && actionsElement}
        </form>
    ) : (
        <div
            className={clsx(classes.form, className)}
            style={style}
            data-cy={dataCy}>
            {contentElement}
            {actions && actionsElement}
        </div>
    );
};

export default UiForm;
