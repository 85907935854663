import type { ICreateEditTenantPayload } from '@experiences/interfaces';

import type { ITenant } from '../../common/interfaces/tenant/tenant';
import type { IServiceMetadata } from '../../component/tenants/interfaces/service';
import type { IEditTenantPayload } from '../../component/tenants/subcomponents/CreateEditTenantComponent';
import {
    axiosDelete,
    get,
    patch,
    post,
} from '../utility/Requests.default';

export const organizationManagementTenantUri = '/api/organization/tenant';
export const tenantAvailableServicesUri = `${organizationManagementTenantUri}/availableservices`;
export const tenantByIdUrl = `${organizationManagementTenantUri}/tenantById`;
export interface IServiceStatusMap {
    [key: string]: string;
}

export interface IServiceError {
    ServiceType: string;
    ErrorCode: number;
    Description: string;
}

export function getTenants(_: string, organizationGuid: string, accountName: string, includeTenantServices = false) {
    return get<ITenant[]>(organizationManagementTenantUri, {
        urlParams: {
            organizationGuid,
            accountName,
            includeTenantServices,
        },
    });
}

export function getTenantsV2(
    _: string,
    organizationGuid: string,
    accountName: string,
    tenantStatus = 'All',
    serviceType?: string[],
    serviceStatus?: string,
    includeTenantServices = false,
) {
    const queryParams: { [key: string]: any } = {
        organizationGuid,
        accountName,
        tenantStatus,
        serviceType,
        serviceStatus,
        includeTenantServices,
    };
    Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);
    return get<ITenant[]>(organizationManagementTenantUri, { urlParams: queryParams });
}

export function getOrgLogicalNameAndTenantName(tenantGuid: string) {
    return get<{ organizationLogicalName: string; tenantName: string }>(
        `${organizationManagementTenantUri}/orgLogicalNameAndTenantName`,
        { urlParams: { tenantGuid } },
    );
}

export function getAvailableServices(url: string, organizationGuid: string, accountName: string, isCanaryTenant = false) {
    return get<IServiceMetadata[]>(`${organizationManagementTenantUri}/availableservices`, {
        urlParams: {
            organizationGuid,
            accountName,
            isCanaryTenant,
        },
    });
}

async function getServicesWithTenant(
    url: string,
    organizationGuid: string,
    accountName: string,
    includeTenantServices?: boolean,
) {
    return get<ITenant[]>(`${organizationManagementTenantUri}/tenantservices`, {
        urlParams: {
            organizationGuid,
            accountName,
            includeTenantServices,
        },
    });
}

export function getTenantById(url: string, id: string) {
    return get<ITenant>(`${organizationManagementTenantUri}/tenantById`, { urlParams: { id } });
}

export function createTenant(payload: ICreateEditTenantPayload) {
    const services = payload.services;
    if (
        services.indexOf('bupproxyservice') === -1 &&
    (services.indexOf('actions') > -1 || services.indexOf('processes') > -1)
    ) {
        services.push('bupproxyservice');
    }
    return post<ITenant>(organizationManagementTenantUri, { body: { ...payload } });
}

export function editTenant(id: string, payload: IEditTenantPayload) {
    const services = payload.services;
    if (services['actions'] || services['processes']) {
        services['bupproxyservice'] = true;
    }
    return patch<string>(organizationManagementTenantUri, {
        body: {
            id,
            ...payload,
        },
    });
}

export async function deleteTenant(id: string) {
    return axiosDelete(organizationManagementTenantUri, { body: { id } });
}

export async function setTenantStatus(id: string, status: boolean) {
    return post<IServiceError[]>(`${organizationManagementTenantUri}/status`, {
        body: {
            id,
            status,
        },
    });
}

export async function setServiceStatus(organizationId: string, tenantId: string, services: IServiceStatusMap) {
    return patch<IServiceError[]>(`${organizationManagementTenantUri}/${organizationId}/tenants/${tenantId}/services`, { body: { serviceTypeServiceStatusMap: services } });
}
