import { TextField } from '@mui/material';
import type { FC } from 'react';
import React from 'react';

import type { ICustomEditorProps } from './subcomponents/CustomEditor';
import CustomEditor from './subcomponents/CustomEditor';

export const UiCodeEditor: FC<ICustomEditorProps> = ({
    value, onValueChange, dataCy,
}) => (
    <TextField
        variant="outlined"
        fullWidth
        InputProps={{
            inputComponent: CustomEditor,
            inputProps: {
                dataCy,
                value,
                onValueChange,
            },
        }}
    />
);
