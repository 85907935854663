import { ArrowRight } from '@mui/icons-material';
import type {
    ForwardedRef,
    PropsWithChildren,
    SyntheticEvent,
} from 'react';
import React, {
    useCallback,
    useRef,
} from 'react';

import type { ISubMenuItemProps } from './UiNestedMenuItem';

const useUiNestedMenuItemViewModel = (props: PropsWithChildren<ISubMenuItemProps>, ref: ForwardedRef<HTMLLIElement>) => {

    const {
        children,
        parentMenuOpen,
        ref: containerRefProp,
        className,
        label,
        leftAnchorMenus,
        righticon = <ArrowRight style={{ fontSize: 16 }} />,
        tabIndex: tabIndexProp,
        keepOpen,
        ...MenuItemProps
    } = props;

    const menuItemRef = useRef<HTMLLIElement>(null);
    React.useImperativeHandle<HTMLLIElement | null, HTMLLIElement | null>(ref, () => menuItemRef.current);

    const containerRef = useRef<HTMLDivElement>(null);
    React.useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(containerRefProp, () => containerRef.current);

    const menuContainerRef = React.useRef<HTMLDivElement>(null);

    const [ isSubMenuOpen, setIsSubMenuOpen ] = React.useState(false);

    const onClose = useCallback(() => {
        setIsSubMenuOpen(false);
    }, []);

    const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(true);
    };

    const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
        setIsSubMenuOpen(false);
    };

    const isSubmenuFocused = () => {
        const active = containerRef.current?.ownerDocument?.activeElement;

        if (menuContainerRef?.current?.children) {
            for (const child of menuContainerRef.current?.children ?? []) {
                if (child === active) {
                    return true;
                }
            }
        }

        return false;
    };

    const handleFocus = (event: SyntheticEvent) => {
        if (event.target === containerRef.current) {
            setIsSubMenuOpen(true);
        }
    };

    const handleBlur = (event: SyntheticEvent) => {
        if (isSubmenuFocused() && event.target !== containerRef.current && isSubMenuOpen) {
            setIsSubMenuOpen(false);
        }
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Escape') {
            return;
        }

        if (isSubmenuFocused()) {
            event.stopPropagation();
        }

        const active = containerRef.current?.ownerDocument?.activeElement;

        if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && event.target === containerRef.current) {
            setIsSubMenuOpen(false);
        }

        if ((event.key === 'ArrowLeft') && isSubmenuFocused()) {
            containerRef.current?.focus();
            setIsSubMenuOpen(false);
        }

        if ((event.key === 'ArrowRight' || event.key === 'Enter') &&
          event.target === containerRef.current &&
          event.target === active
        ) {
            setIsSubMenuOpen(true);
            const firstChild = menuContainerRef.current?.children[0];
            (firstChild as HTMLElement)?.focus();
        }
    };

    const open = isSubMenuOpen && parentMenuOpen;

    let tabIndex;
    if (!props.disabled) {
        tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
    }

    return {
        children,
        containerRef,
        menuContainerRef,
        menuItemRef,
        isSubMenuOpen,
        tabIndex,
        righticon,
        MenuItemProps,
        leftAnchorMenus,
        label,
        open,
        onClose,
        handleBlur,
        handleFocus,
        handleKeyDown,
        handleMouseEnter,
        handleMouseLeave,
    };
};

export default useUiNestedMenuItemViewModel;
