import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import {
    Tab,
    Tabs,
} from '@mui/material';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import {
    accountType as accountTypeSelector,
    isHostModeSelector,
} from '../../../store/selectors';
import ExternalProvidersComponentRevamp from '../../authsettings/subcomponents/ExternalProvidersComponentRevamp';
import OrganizationAccessPolicyComponent from '../accessPolicy/base/OrganizationAccessPolicyComponent';
import EncryptionComponent from './Encryption/EncryptionComponent';
import IPRestrictionComponent from './IPRestriction/IPRestrictionComponent';
import SessionPolicyComponent from './SessionPolicyComponent';

const SecuritySettingsTab = {
    AUTHENTICATION: 'authenticationsettings',
    SESSION_POLICY: 'sessionpolicy',
    IP_RESTRICTION: 'iprestriction',
    ENCRYPTION: 'encryption',
    RESTRICT_ACCESS: 'restrictaccess',
};

const TabPanel: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case SecuritySettingsTab.AUTHENTICATION:
            return <ExternalProvidersComponentRevamp />;
        case SecuritySettingsTab.SESSION_POLICY:
            return <SessionPolicyComponent />;
        case SecuritySettingsTab.IP_RESTRICTION:
            return <IPRestrictionComponent />;
        case SecuritySettingsTab.ENCRYPTION:
            return <EncryptionComponent />;
        case SecuritySettingsTab.RESTRICT_ACCESS:
            return <OrganizationAccessPolicyComponent />;
    }

    return null;
};

const SecuritySettingsTabs: React.FC = () => {

    const { formatMessage: translate } = useIntl();
    const {
        isOffersRevampAndCommunity, isUserEnterprise,
    } = useCheckLicense();

    const isHostMode = useSelector(isHostModeSelector);
    const accountType = useSelector(accountTypeSelector);

    const EnableSecuritySettingsSessionPolicy = useFeatureFlagValue(Features.EnableSecuritySettingsSessionPolicy.name);
    const EnableSecuritySettingsIPRestriction = useFeatureFlagValue(Features.EnableSecuritySettingsIPRestriction.name);
    const EnableSecuritySettingsEncryption = useFeatureFlagValue(Features.EnableSecuritySettingsEncryption.name);
    const EnableOrganizationAccessPolicy = useFeatureFlagValue(Features.EnableOrganizationAccessPolicy.name);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const route = useRouteMatch();
    const isOnPrem = useIsOnPrem();

    const getActiveTab = () => {
        switch (route.path) {
            case RouteNames.SessionPolicy:
                return SecuritySettingsTab.SESSION_POLICY;
            case RouteNames.IPRestriction:
                return SecuritySettingsTab.IP_RESTRICTION;
            case RouteNames.Encryption:
                return SecuritySettingsTab.ENCRYPTION;
            case RouteNames.OrganizationAccessPolicy:
                return SecuritySettingsTab.RESTRICT_ACCESS;
            default:
                return SecuritySettingsTab.AUTHENTICATION;
        }
    };

    const [ tabValue, setTabValue ] = useState(getActiveTab);

    const handleChange = useCallback((_, newValue: string) => setTabValue(newValue), [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default"
                style={
                    isAdminRevampEnabled
                        ? {
                            width: '100%',
                            alignItems: 'center',
                            marginBottom: '32px',
                        }
                        : undefined
                }>
                <Tab
                    value={SecuritySettingsTab.AUTHENTICATION}
                    label={translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS' })}
                    onClick={() => history.push(getRoute(RouteNames.SecuritySettings))}
                    data-cy="auth-settings-tab"
                    className="default"
                />
                {!isHostMode && EnableSecuritySettingsIPRestriction && (isUserEnterprise || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingsTab.IP_RESTRICTION}
                        label={translate({ id: 'CLIENT_IP_RESTRICTION' })}
                        onClick={() => history.push(getRoute(RouteNames.IPRestriction))}
                        data-cy="ip-restrictions-tab"
                        className="default"
                    />
                )}
                {EnableSecuritySettingsSessionPolicy && (isUserEnterprise || isOnPrem || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingsTab.SESSION_POLICY}
                        label={translate({ id: 'CLIENT_SESSION_POLICY' })}
                        onClick={() => history.push(getRoute(RouteNames.SessionPolicy))}
                        data-cy="session-policy-tab"
                        className="default"
                    />
                )}
                {!isHostMode && EnableSecuritySettingsEncryption && (AccountLicense[accountType] === AccountLicense.ENTERPRISE || isOffersRevampAndCommunity) && (
                    <Tab
                        value={SecuritySettingsTab.ENCRYPTION}
                        label={translate({ id: 'CLIENT_ENCRYPTION_CERTIFICATE_LOCATION_USAGE' })}
                        onClick={() => history.push(getRoute(RouteNames.Encryption))}
                        data-cy="encryption-tab"
                        className="default"
                    />
                )}
                {!isHostMode && EnableOrganizationAccessPolicy && (
                    <Tab
                        value={SecuritySettingsTab.RESTRICT_ACCESS}
                        label={translate({ id: 'CLIENT_ACCESS_RESTRICTION' })}
                        onClick={() => history.push(getRoute(RouteNames.OrganizationAccessPolicy))}
                        data-cy="access-policy-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel value={tabValue} />
        </>
    );
};

export default SecuritySettingsTabs;
