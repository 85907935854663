import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { PermissionType } from '@experiences/interfaces';
import { useRouteResolver } from '@experiences/util';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useCheckOrchestratorEnabled } from '../../common/hooks/useCheckOrchestratorEnabled';
import type { ITenant } from '../../common/interfaces/tenant/tenant';
import licensesAdmin from '../../images/licensesAdmin.svg';
import servicesAdmin from '../../images/servicesAdmin.svg';
import settingsAdmin from '../../images/settingsAdmin.svg';
import tagsAdmin from '../../images/tagsAdmin.svg';
import vpnAdmin from '../../images/vpnAdmin.svg';
import { getRobotUnits } from '../../services/hypervisor';
import {
    getPermissions,
    tagsUrl,
} from '../../services/orchestrator/TagsService.default';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { hasPermission } from '../tags/addedit/properties/TenantTagsUtil';

interface ICard {
    title: string;
    titleIcon: string;
    description: string;
    onClickLink: string;
    dataCy: string;
    alt: string;
    invisible?: boolean;
    disabled?: boolean;
    showWarning?: boolean;
    warningTooltip?: string;
}

const useTenantAdminViewModel = (id: string, tenant: ITenant | undefined) => {

    const getRoute = useRouteResolver();

    const { formatMessage: translate } = useIntl();
    const { logEvent } = useTelemetryHelper();

    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);

    const vpnGatewayCost = Number(useFeatureFlagValue(Features.VpnGatewayCost.name));
    const EnablePortalTagManagement = useFeatureFlagValue(Features.EnablePortalTagManagement.name);

    const isOrchestratorEnabled = useCheckOrchestratorEnabled(id);

    const { data: permissions } = useSWR(
        (accountName && tenant?.name) ?
            [ `${tagsUrl}/permissions`, accountName, tenant.name, accountId ] : null,
        (_url: string, organizationName: string, tenantName: string, selectedAccountId: string) =>
            getPermissions(organizationName, tenantName, selectedAccountId),
    );

    const { data: robotUnits } = useSWR([ accountName, id ],
        getRobotUnits,
    );

    const handleTagsClick = useCallback(() => {
        logEvent('PortalTagManagement.CardClick');
        return getRoute(RouteNames.TenantTagsLabels.replace(':tenantId', id));
    }, [ getRoute, id, logEvent ]);

    const cardList: ICard[] = [
        {
            title: 'CLIENT_SERVICES',
            titleIcon: servicesAdmin,
            description: 'CLIENT_TENANT_HOME_SERVICES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.TenantServices.replace(':tenantId', id)),
            dataCy: 'tenant-home-services-card',
            alt: 'Services',
        },
        {
            title: 'CLIENT_LICENSES',
            titleIcon: licensesAdmin,
            description: 'CLIENT_TENANT_HOME_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.ServicesUsage.replace(':tenantId', id)),
            dataCy: 'tenant-home-licenses-card',
            alt: 'Licenses',
            invisible: AccountLicense[accountPlan] >= AccountLicense.FREEKIT,
        },
        {
            // Check if Orchestrator is enabled for this Tenant
            title: 'CLIENT_TAGS',
            titleIcon: tagsAdmin,
            description: 'CLIENT_TENANT_HOME_TAGS_DESCRIPTION',
            onClickLink: handleTagsClick(),
            dataCy: 'tenant-home-tags-card',
            alt: 'Tags',
            invisible: !EnablePortalTagManagement,
            disabled: !hasPermission(permissions, [ PermissionType.View ]) || !isOrchestratorEnabled,
            showWarning: !hasPermission(permissions, [ PermissionType.View ]),
            warningTooltip: translate({ id: 'CLIENT_TENANT_TAGS_PERMISSIONS_RESTRICTION' }, { permissions: 'Tags.View' }),
        },
        {
            title: 'CLIENT_GATEWAY',
            titleIcon: vpnAdmin,
            description: 'CLIENT_TENANT_HOME_GATEWAY_DESCRIPTION',
            onClickLink: getRoute(RouteNames.VpnGateway.replace(':tenantId', id)),
            dataCy: 'tenant-home-vpn-gateway-card',
            alt: translate({ id: 'CLIENT_GATEWAY' }),
            disabled: tenant?.status === 'Disabled',
            showWarning: robotUnits ? robotUnits?.Total < vpnGatewayCost && !robotUnits.Expired : false,
            warningTooltip: translate({ id: 'CLIENT_INSUFFICIENT_RU_VPN_GATEWAY_BANNER_TEXT' }),
        },
    ];

    // Do No Modify
    cardList.push({
        title: 'CLIENT_SETTINGS',
        titleIcon: settingsAdmin,
        description: 'CLIENT_TENANT_HOME_SETTINGS_DESCRIPTION',
        onClickLink: getRoute(RouteNames.TenantSettings.replace(':tenantId', id)),
        dataCy: 'tenant-home-settings-card',
        alt: 'Settings',
    });

    return { cardList };
};

export default useTenantAdminViewModel;
