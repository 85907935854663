import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import type { IActionHeader } from './grid';
import { ButtonType } from './grid';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
        },
    }),
}));

export default function UiMoreRowActionsHeaderComponent({ actions }: { actions?: IActionHeader[] }) {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const clickMenuItem = useCallback((click: (_: any) => void, action: IActionHeader) => {
        setAnchorEl(null);
        click(action);
    }, []);

    return (
        <>
            <IconButton
                aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                className={clsx(classes.showMoreButton)}
                onClick={event => setAnchorEl(event.currentTarget)}
            >
                <MoreVertIcon className={classes.icon} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                data-cy="more-actions-menu-header"
            >
                {actions?.map((action, index) =>
                    !action.invisible ? (
                        action.subMenuList ? (
                            action.subMenuList.map(subMenu => subMenu)
                        ) : (
                            <MenuItem
                                key={index}
                                disabled={action.disable}
                                onClick={() => (action.click ? clickMenuItem(action.click, action) : undefined)}
                                data-cy={action.dataCy + '-header'}
                                className={clsx(classes.menuItem)}
                            >
                                {action.type === ButtonType.ButtonWithIcon && action.icon ? (
                                    <ListItemIcon className={clsx(classes.icon, classes.svgIcon)}>
                                        {action.icon}
                                    </ListItemIcon>
                                ) : null}

                                <ListItemText primary={action.label} />

                                {action.type === ButtonType.ButtonWithIcon && action.endIcon ? (
                                    <ListItemIcon className={clsx(classes.icon, classes.svgIcon)}>
                                        {action.endIcon}
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                        )
                    ) : null,
                )}
            </Menu>
        </>
    );
}
