import type { IPortalUser } from '@experiences/interfaces';
import produce from 'immer';
import difference from 'lodash/difference';

import { appState } from '../../common/constants/Constant';
import type { IAccountUserDto } from '../../component/users/interfaces/accountUser';
import store from '../index';
import {
    FETCH_PROFILE_SUCCESS,
    UPDATE_ACCOUNT_USER,
    UPDATE_ORG_REGION,
    UPDATE_USER_GROUPS,
} from './ActionTypes';

const receiveUserProfile = (newProfile: IPortalUser) => ({
    type: FETCH_PROFILE_SUCCESS,
    profile: newProfile,
});

const updateUserGroupsDispatch = (groups: string[]) => ({
    type: UPDATE_USER_GROUPS,
    profile: {
        ...store.getState().profile,
        groups,
    },
});

const updateAccountUser = (accountUserDto: IAccountUserDto) => ({
    type: UPDATE_ACCOUNT_USER,
    profile: {
        ...store.getState().profile,
        accountUserDto,
    },
});

export const updateOrgRegion = (region: string) => {

    const profileReducer = produce(store.getState().profile, (draft: IPortalUser) => {
        draft.accountUserDto.region = region;
    });

    sessionStorage.setItem(appState.REDUX_STORE_STATE, JSON.stringify(profileReducer));
    return {
        type: UPDATE_ORG_REGION,
        region,
    };
};

export async function setUserProfile(profile: IPortalUser) {
    store.dispatch(receiveUserProfile(profile));
}

export async function updateUserGroups(type: 'create' | 'delete' | 'update', groupIds: string[]) {
    const groups = store.getState().profile.groups as string[];
    const groupsToUpdate =
    type === 'create' ? groups.concat(...groupIds) : type === 'delete' ? difference(groups, groupIds) : groupIds;
    store.dispatch(updateUserGroupsDispatch(groupsToUpdate));
}

export function setUnlicensedState() {
    return (dispatch: (arg0: { type: string; profile: IPortalUser }) => void) => {
        const oldAccountUser = store.getState().profile.accountUserDto as IAccountUserDto;
        const accountUserDto = {
            ...oldAccountUser,
            accountType: 'UNLICENSED',
            licenseCode: 'Unlicensed',
            licenseType: '',
        } as IAccountUserDto;

        dispatch(updateAccountUser(accountUserDto));
    };
}
