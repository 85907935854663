import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { AccountType } from '@experiences/interfaces';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import { accountType } from '../../store/selectors';

const useCheckLicense = () => {

    const licensePlan = useSelector(accountType);

    const EnableOffersRevampAdmin = useFeatureFlagValue(Features.EnableOffersRevampAdmin.name);
    const EnableCloudEnterpriseLicenseActivation = useFeatureFlagValue(Features.EnableCloudEnterpriseLicenseActivation.name);
    const EnableEcommerceMonthlyUpsell = useFeatureFlagValue(Features.EnableEcommerceMonthlyUpsell.name,);

    const includesLicense = useCallback((licenses: AccountLicense[]) => licenses.includes(AccountLicense[licensePlan]), [ licensePlan ]);

    const notIncludesLicense = useCallback((licenses: AccountLicense[]) => !licenses.includes(AccountLicense[licensePlan])
        , [ licensePlan ]);

    const isOffersRevampAndCommunity = useMemo(() => EnableOffersRevampAdmin &&
            AccountLicense[licensePlan] <= AccountLicense.FREEKIT && AccountLicense[licensePlan] >= AccountLicense['PRO-TRIAL']
    , [ EnableOffersRevampAdmin, licensePlan ]);

    const isFreeOrCommunityRevamp = useMemo(() => EnableOffersRevampAdmin && AccountLicense[licensePlan] >= AccountLicense.COMMUNITY
        , [ EnableOffersRevampAdmin, licensePlan ]);

    const isFreeOrCommunity = useCallback((accountLicense?: AccountType) => accountLicense
        ? AccountLicense[accountLicense] >= AccountLicense.COMMUNITY
        : AccountLicense[licensePlan] >= AccountLicense.COMMUNITY, [ licensePlan ]);

    const isEnterprise = useMemo(() => AccountLicense[licensePlan] === AccountLicense.ENTERPRISE, [ licensePlan ]);

    const isCommunity = useMemo(() => AccountLicense[licensePlan] === AccountLicense.COMMUNITY, [ licensePlan ]);

    const isAccountLicenseEnterpriseOrPro = useCallback((accountLicense?: AccountType) => accountLicense
        ? AccountLicense[accountLicense] === AccountLicense.ENTERPRISE || AccountLicense[accountLicense] === AccountLicense.PRO
        : includesLicense([ AccountLicense.ENTERPRISE, AccountLicense.PRO ]), [ includesLicense ]);

    const isUserEnterprise = useMemo(() => AccountLicense[licensePlan] <= AccountLicense.TRIAL, [ licensePlan ]);

    const checkLicense = useCallback((licenses: AccountLicense[], licenseToCheck?: AccountType) =>
        licenseToCheck ? licenses.includes(AccountLicense[licenseToCheck]) : false, []);

    const isUnpaidLicense = useMemo(() =>
        includesLicense([ AccountLicense.TRIAL, AccountLicense.COMMUNITY, AccountLicense.FREEKIT, AccountLicense['PRO-TRIAL'] ])
    , [ includesLicense ]);

    const isUnlicensed = useMemo(() => includesLicense([ AccountLicense.UNLICENSED ]), [ includesLicense ]);

    const isPro = useMemo(() => includesLicense([ AccountLicense.PRO ]), [ includesLicense ]);

    const isMonthlyUpsellApplicable = useMemo(() => EnableEcommerceMonthlyUpsell && isPro, [ EnableEcommerceMonthlyUpsell, isPro ]);

    const canHaveOnlineEnterpriseActivation = useMemo(() => {
        const validAccountTypes = [ AccountLicense.COMMUNITY, AccountLicense.FREEKIT, AccountLicense.TRIAL, AccountLicense['PRO-TRIAL'] ];
        const hasValidLicenseType = checkLicense(validAccountTypes, licensePlan);
        return EnableCloudEnterpriseLicenseActivation && (hasValidLicenseType || isMonthlyUpsellApplicable);
    }, [ EnableCloudEnterpriseLicenseActivation, licensePlan, checkLicense, isMonthlyUpsellApplicable ]);

    return {
        checkLicense,
        includesLicense,
        isAccountLicenseEnterpriseOrPro,
        isEnterprise,
        isFreeOrCommunity,
        isFreeOrCommunityRevamp,
        isOffersRevampAndCommunity,
        isUserEnterprise,
        notIncludesLicense,
        isUnpaidLicense,
        isCommunity,
        isUnlicensed,
        isPro,
        isMonthlyUpsellApplicable,
        canHaveOnlineEnterpriseActivation,
    };
};

export default useCheckLicense;
