import type { IUiLinkMap } from '@experiences/interfaces';

export const LegacyLicenseLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/automation-suite/docs/legacy-license-management' ],
    [ 'ja', 'https://docs.uipath.com/automation-suite/lang-ja/docs/legacy-license-management' ],
]);

export const AutomationDocsLinks: IUiLinkMap = new Map([
    [ 'en', 'https://docs.uipath.com/automation-suite/docs/user-license-management' ],
    [ 'ja', 'https://docs.uipath.com/automation-suite/lang-ja/docs/user-license-management' ],
]);
