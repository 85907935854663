export default function buildTitleMap(hostMode: boolean) {
    const includesPathTitle = new Map([
        [ 'robots', 'CLIENT_ROBOTS_TITLE' ],
        [ 'buypro', 'CLIENT_ECOMMERCE_TITLE' ],
        [ 'service', 'CLIENT_SERVICE_INSTANCE_DETAILS_TITLE' ],
        [ 'existingaccount', 'CLIENT_EXISTING_ACCOUNT_TITLE' ],
        [ 'missingaccount', 'CLIENT_MISSING_ACCOUNT_TITLE' ],
        [ 'completeSignup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'completeInvite', 'CLIENT_SIGNUP_TITLE' ],
        [ 'acceptInvite', 'CLIENT_ACCEPT_INVITE_TITLE' ],
        [ 'emailverification', 'CLIENT_EMAIL_VERIFICATION_TITLE' ],
        [ 'signup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'completeSignup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'completeInvite', 'CLIENT_SIGNUP_TITLE' ],
        [ 'serviceinstances', 'CLIENT_SERVICEINSTANCE_TITLE' ],
        [ 'users', 'CLIENT_USERS_TITLE' ],
        [ 'licensing', 'CLIENT_LICENSE_TITLE' ],
        [ 'resourceCenter', 'CLIENT_RESOURCE_TITLE' ],
        [ 'notification', 'CLIENT_NOTIFICATION_TITLE' ],
        [ 'unregistered', 'CLIENT_UNREGISTERED_TITLE' ],
        [ 'home', 'CLIENT_HOME_TITLE' ],
        [ 'auditLog', 'CLIENT_AUDITLOG_TITLE' ],
        [ 'genericerror', 'CLIENT_GENERIC_ERROR' ],
        [ 'organizations', 'CLIENT_ORGANIZATION_TITLE' ],
        [ 'externalApps', 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ 'groups', 'CLIENT_GROUPS_TITLE' ],
        [ 'authsettings', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'azure', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'automationkit', 'CLIENT_AUTOMATION_KIT_TITLE' ],
        [ 'authenticationsettings', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'sessionpolicy', 'CLIENT_SESSION_POLICY_TITLE' ],
        [ 'iprestriction', 'CLIENT_IP_RESTRICTION_TITLE' ],
        [ 'profile', 'CLIENT_PREFERENCES' ],
        [ 'privacyAndSecuritySettings', 'CLIENT_PRIVACY_AND_SECURITY' ],
    ]);

    if (hostMode) {
        includesPathTitle.set('emailsettings', 'CLIENT_GLOBAL_SETTINGS_TITLE');
        includesPathTitle.set('settings', 'CLIENT_GLOBAL_SETTINGS_TITLE');
    } else {
        includesPathTitle.set('emailsettings', 'CLIENT_ACCOUNT_SETTINGS_TITLE');
        includesPathTitle.set('settings', 'CLIENT_ACCOUNT_SETTINGS_TITLE');
    }

    return includesPathTitle;
}
