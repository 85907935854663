import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import { Typography } from '@mui/material';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useLocation,
} from 'react-router';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { EmailSettingKey } from '../../../common/constants/EmailSettingConstant';
import { EmailSettingsTest } from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IEmailSettingsData } from '../../../common/interfaces/emailSetting';
import type { ISetting } from '../../../services/identity/SettingService';
import {
    deleteSettings,
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import {
    mapEmailSettingDataToKeyValuePairs,
    mapSettingArrayToEmailSettingsData,
} from '../../../util/setting/EmailSettingUtil';
import VerifyMailSettingsDialogBody from './VerifyMailSettingsDialogBody';

export const defaultEmailSettingsData: IEmailSettingsData = {
    domain: '',
    enableSsl: false,
    fromDisplayName: 'UiPath Platform',
    fromAddress: 'Default Address',
    host: 'SendGrid',
    password: '',
    port: 25,
    useDefaultCredentials: !process.buildConfigs.disableUseDefaultCredentials,
    userName: '',
    timeout: 180000,
};

const useOrganizationEmailSettingsRevampViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const location = useLocation<{ verifiedSettings: boolean }>();

    const EnableNewOrganizationSMTPSettingsPage = useFeatureFlagValue(Features.EnableNewOrganizationSMTPSettingsPage.name);

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ mailSetting, setMailSetting ] = useState<'default' | 'custom'>('default');
    const [ verifiedSettings, setVerifiedSettings ] = useState(() => location?.state?.verifiedSettings ?? false);

    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();

    const keys = [
        EmailSettingKey.Domain,
        EmailSettingKey.EnableSsl,
        EmailSettingKey.FromDisplayName,
        EmailSettingKey.FromEmail,
        EmailSettingKey.Host,
        EmailSettingKey.Password,
        EmailSettingKey.Port,
        EmailSettingKey.UseDefaultCredentials,
        EmailSettingKey.UserName,
        EmailSettingKey.ConnectionTimeout,
    ];
    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[]>(
        [ settingUrl, keys, partitionGlobalId ],
        getSetting,
    );

    const methods = useForm<IEmailSettingsData>({
        mode: 'onChange',
        defaultValues: defaultEmailSettingsData,
    });
    const {
        reset, watch, setValue,
    } = methods;

    const originalData = useMemo(() => mapSettingArrayToEmailSettingsData(fetchedSettings), [ fetchedSettings ]);
    const emailSettingsData = useMemo(() => watch(), [ watch ]);
    const useDefaultCredentials = watch('useDefaultCredentials');

    useEffect(() => {
        if (fetchedSettings) {
            const domainSetting = fetchedSettings.find(setting => setting.key === EmailSettingKey.Domain);
            if (domainSetting?.value) {
                setMailSetting('custom');
            }
            reset(originalData);
        }
    }, [ reset, fetchedSettings, originalData ]);

    useEffect(() => {
        setValue('userName', useDefaultCredentials ? '' : originalData.userName);
        setValue('password', useDefaultCredentials ? '' : originalData.password);
    }, [ originalData.password, originalData.userName, setValue, useDefaultCredentials ]);

    const onSubmit = async (data: IEmailSettingsData) => {
        try {
            if (verifiedSettings) {
                data.host = data.host?.trim();
                data.domain = data.domain?.trim();
                data.userName = data.userName?.trim();
                data.fromAddress = data.fromAddress?.trim();
                const savedData = await saveSetting(settingUrl, {
                    settings: mapEmailSettingDataToKeyValuePairs(data, originalData),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SMTP_SETTINGS_SAVED_SUCCESSFULLY' }), notificationType.SUCCESS);
            } else {
                createNotification(translate({ id: 'CLIENT_SETTINGS_NOT_TESTED_ERROR' }), notificationType.ERROR);
            }
        } catch (error) {
            createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
        }
    };

    const handleTestEmailSettings = useCallback(async () => {
        if (EnableNewOrganizationSMTPSettingsPage) {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_TEST_MAIL_SETTINGS' }),
                hideActions: true,
                customDialogContent: VerifyMailSettingsDialogBody,
                customDialogContentProps: { emailSettingsData },
            });
            setVerifiedSettings(proceed);
            return;
        }

        history.push({
            pathname: getRoute(EmailSettingsTest),
            state: { emailSettingsData },
        });
    }, [ EnableNewOrganizationSMTPSettingsPage, createDialog, emailSettingsData, getRoute, history, translate ]);

    const handleSwitch = useCallback(
        (name: string, checked: boolean, callback: (_: any) => void) => {
            if (checked) {
                setValue(name, [], { shouldDirty: true });
            }
            callback(checked);
        },
        [ setValue ],
    );

    const handleCancel = () => reset();

    const deleteCustomSetting = useCallback(async () => {
        const domainSetting = fetchedSettings?.find(setting => setting.key === EmailSettingKey.Domain);
        if (domainSetting?.value) {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_CUSTOM_SETTING_TITLE' }),
                body: <Typography>
                    {translate({ id: 'CLIENT_DELETE_CUSTOM_SETTING_DESCRIPTION' })}
                </Typography>,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
            });

            if (!proceed) {
                return false;
            }
        }

        reset({
            ...originalData,
            host: 'SendGrid',
        });

        await deleteSettings([ EmailSettingKey.Domain, EmailSettingKey.Host ], partitionGlobalId);
        await mutate();

        return true;
    }, [ createDialog, fetchedSettings, mutate, originalData, partitionGlobalId, reset, translate ]);

    return {
        fetchedSettings,
        mutate,
        methods,
        onSubmit,
        useDefaultCredentials,
        handleTestEmailSettings,
        handleSwitch,
        handleCancel,
        mailSetting,
        setMailSetting,
        verifiedSettings,
        deleteCustomSetting,
    };
};

export default useOrganizationEmailSettingsRevampViewModel;
