import type {
    ICancelOrganizationScheduledMoveRequest,
    IOrganizationScheduledMoveRequest,
    IOrganizationStartMoveResponse,
    IScheduledOrganizationMigrationStatus,
} from '../../common/interfaces/gws';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

const tenantMigrationUrl = '/api/move/tenants';

export function getScheduledTenantMigrationStatus(tenantId: string): Promise<IScheduledOrganizationMigrationStatus> {
    return get<IScheduledOrganizationMigrationStatus>(`${tenantMigrationUrl}/getScheduledTenantMigrationStatus`, { urlParams: { tenantId } });
}

export function scheduleTenantMove(scheduleMoveRequest: IOrganizationScheduledMoveRequest, tenantId: string): Promise<IOrganizationStartMoveResponse> {
    return post<IOrganizationStartMoveResponse>(`${tenantMigrationUrl}/scheduleTenantMove`, {
        body: scheduleMoveRequest,
        urlParams: { tenantId },
    });
}

export function cancelTenantMove(cancelScheduledMoveRequest: ICancelOrganizationScheduledMoveRequest, tenantId: string) {
    return axiosDelete(`${tenantMigrationUrl}/cancelTenantMove`, {
        body: cancelScheduledMoveRequest,
        urlParams: { tenantId },
    });
}
