import { UiDialog } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import ErrorIcon from '@mui/icons-material/Error';
import {
    Backdrop,
    Button,
    CircularProgress,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import type { IDirectoryEntry } from '../../../common/interfaces/cis/directory';
import UiPeoplePicker from '../../common/UiPeoplePicker';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';

const useStyles = makeStyles(theme =>
    createStyles({
        form: { marginTop: Tokens.Padding.PadXxxl },
        peoplePicker: { minWidth: '350px' },
        configureTitle: {
            marginLeft: '64px',
            fontSize: Tokens.FontFamily.FontHeader4Size,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            marginBottom: Tokens.Padding.PadXxxl,
        },
        configureIcon: {
            color: Tokens.Colors.ColorError500,
            marginRight: Tokens.Padding.PadXl,
        },
        circularProgress: {
            color: Tokens.Colors.ColorWhite,
            marginRight: Tokens.Padding.PadXxxl,
        },
        progressMessage: {
            fontSize: Tokens.FontFamily.FontHeader3Size,
            color: Tokens.Colors.ColorWhite,
        },
        backdrop: { zIndex: 1300 },
        userGroupConfig: { marginBottom: '24px' },
        userGroupTitle: {
            marginBottom: Tokens.Padding.PadS,
            fontWeight: Tokens.FontFamily.FontWeightBold,
        },
        userGroupCantCOnfig: { marginBottom: '24px' },
    }),
);

export const AddUserGroupMadal: React.FC = () => {

    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const [ loading, setLoading ] = useState(false);

    const [ progressMessage, setProgressMessage ] = useState<string>('');
    const [ refresh, setRefresh ] = useState(false);
    const [ selectGroup, setSelectGroup ] = React.useState<IDirectoryEntry | null>();
    const close = useCallback(
        () =>
            history.push({
                pathname: getRoute(RouteNames.NotificationSettings),
                state: refresh ? { refresh: true } : undefined,
            }),
        [ history, getRoute ],
    );

    const [ errorState, setErrorState ] = React.useState<boolean>(false);
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const dialogActions = useMemo(() => {
        const actions = [];
        if (errorState) {
            actions.push(
                <Button
                    key="cancel"
                    data-cy="button-cancel"
                    variant="contained"
                    onClick={close}
                    color="primary">
                    {translate({ id: 'CLIENT_CLOSE' })}
                </Button>,
            );
            return actions;
        }

        actions.push(
            <Button
                key="cancel"
                data-cy="button-cancel"
                onClick={close}
                color="primary">
                {translate({ id: 'CLIENT_CANCEL' })}
            </Button>, <Button
                key="configure"
                data-cy="button-configure"
                disabled={selectGroup == null ? true : false}
                variant="contained"
                onClick={() => {
                    if (selectGroup?.email === '' || selectGroup?.email == null || selectGroup?.email === undefined) {
                        setErrorState(true);
                        return;
                    }
                    sessionStorage.setItem('newgroupprofiledetails', JSON.stringify(selectGroup));
                    const identifier = selectGroup.identifier.startsWith('aad|') ?
                        selectGroup.identifier.substring(4) : selectGroup.identifier;

                    const editProfileRoute = RouteNames.AddNotificationSettingsProfile
                        .replace(':id', `${identifier}`)
                        .replace(':name', `${selectGroup.displayName}`)
                        .replace(':type', `NewUserGroup`);

                    history.push(`${getRoute(editProfileRoute)}`);
                }}
                color="primary">
                {translate({ id: 'CLIENT_CONFIGURE' })}
            </Button>,

        );
        return actions;
    }, [ errorState, translate, selectGroup, close, history, getRoute ]);

    return (
        <>
            <Backdrop
                open={loading}
                className={classes.backdrop}>
                <CircularProgress
                    className={classes.circularProgress}
                    size={44}
                    variant="indeterminate"
                />
                <Typography className={classes.progressMessage}>
                    {progressMessage}
                </Typography>
            </Backdrop>
            <UiDialog
                title={errorState ? '' : translate({ id: 'CLIENT_SELECT_USER_GROUP' })}
                close={() => close()}
                dialogProps={{
                    open: !loading,
                    maxWidth: 'xl',
                }}
                actions={dialogActions}
            >
                <>

                    { !errorState && <>
                        <Typography className={classes.userGroupConfig}>
                            {translate({ id: 'CLIENT__USER_GROUP_CONFIGURE' })}
                        </Typography>
                        <Typography className={classes.userGroupTitle}>
                            {translate({ id: 'CLIENT_USER_GROUP' })}
                        </Typography>
                        <UiPeoplePicker
                            className={classes.peoplePicker}
                            searchOnly
                            type="group"
                            ariaLabel={translate({ id: 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER' })}
                            inputPlaceholder={translate({ id: 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER' })}
                            onChange={async (directoryEntries: IDirectoryEntry[]) => {
                                if (directoryEntries.length > 0 && directoryEntries[0]) {
                                    setSelectGroup(directoryEntries[0]);
                                } else {
                                    setSelectGroup(undefined);
                                }
                                setRefresh(true);
                            }}
                        />
                    </>}
                    { errorState && <>
                        <Typography className={classes.configureTitle}>
                            <ErrorIcon className={classes.configureIcon} />
                            {translate({ id: 'CLIENT_PROFILE_CANT_CONFIGURE_TITLE' })}
                        </Typography>

                        <Typography
                            className={classes.userGroupCantCOnfig}
                            sx={{ width: 430 }}>
                            {translate({ id: 'CLIENT_PROFILE_CANT_CONFIGURE' }, { profilename: selectGroup?.displayName })}
                        </Typography>
                    </>}
                </>
            </UiDialog>
        </>
    );
};

export default AddUserGroupMadal;
