import { UiProgressButton } from '@experiences/ui-common';
import { validateUsername } from '@experiences/util';
import {
    Button,
    TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import {
    Controller,
    FormProvider,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
    MaxFirstNameLength,
    MaxLastNameLength,
    MaxUsernameLength,
} from '../../../common/constants/UsersConstant';
import validateEmail from '../../../util/validators/EmailValidator';
import type { IEditPasswordData } from '../../common/EditPasswordFormComponent';
import EditPasswordFormComponent from '../../common/EditPasswordFormComponent';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IEditGroupMembershipData } from './EditGroupMembershipFormComponent';
import EditGroupMembershipFormComponent from './EditGroupMembershipFormComponent';
import type { IEditUserAuthSettingsData } from './EditUserAuthSettingsFormComponent';
import EditUserAuthSettingsFormComponent from './EditUserAuthSettingsFormComponent';
import useAddEditUserViewModel from './useAddEditUserViewModel';

export interface IAddEditUserData
    extends Partial<IEditUserAuthSettingsData>,
    Partial<IEditGroupMembershipData>,
    IEditPasswordData {
    userName: string;
    firstName: string;
    lastName: string;
    email?: string;
}

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const AddEditUserComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        type,
        loading,
        isHostMode,
        onSubmit,
        methods,
        watchBypassPasswordRestriction,
        defaultChecked,
        groups,
        open,
        closeModal,
        showDrawerError,
        errorMessage,
    } = useAddEditUserViewModel();

    const {
        control, handleSubmit, register, formState,
    } = methods;

    const {
        isDirty, isValid, errors,
    } = formState;

    return (
        <UiDrawer
            title={translate({ id: type === 'add' ? 'CLIENT_ADD_USER' : 'CLIENT_EDIT_USER' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => closeModal(true),
            }}
            loading={!isHostMode && !groups.length}
            error={{
                showError: showDrawerError,
                message: errorMessage,
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => closeModal(true)}
                            color="primary"
                            data-cy='add-edit-cancel-button'>
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty || !isValid}
                            variant="contained"
                            data-cy="add-edit-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <div className={classes.input}>
                    <Controller
                        as={TextField}
                        control={control}
                        label={translate({ id: 'CLIENT_USERNAME' })}
                        disabled={type === 'edit'}
                        required
                        rules={{
                            required: true,
                            maxLength: MaxUsernameLength,
                            validate: { valid: value => validateUsername(value) },
                        }}
                        error={!!errors.userName}
                        helperText={
                            (errors.userName?.type === 'required'
                                && translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_USERNAME' }) },
                                ))
                            || (errors.userName?.type === 'maxLength'
                                && translate(
                                    { id: 'CLIENT_USERNAME_MAX_LENGTH_ERROR' },
                                    { 0: MaxUsernameLength },
                                ))
                            || (errors.userName?.type === 'available'
                                && translate({ id: 'CLIENT_USERNAME_TAKEN_ERROR' }))
                            || (errors.userName?.type === 'valid'
                                && translate({ id: 'CLIENT_INVALID_USERNAME_ERROR' }))
                        }
                        name="userName"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-label': translate({ id: 'CLIENT_USERNAME' }) }}
                        data-cy="add-edit-username"
                    />
                </div>
                <div className={classes.input}>
                    <Controller
                        as={TextField}
                        control={control}
                        label={translate({ id: 'CLIENT_FIRST_NAME' })}
                        required
                        rules={{
                            required: true,
                            maxLength: MaxFirstNameLength,
                        }}
                        error={!!errors.firstName}
                        helperText={
                            (errors.firstName?.type === 'required'
                                && translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_FIRST_NAME' }) },
                                ))
                            || (errors.firstName?.type === 'maxLength'
                                && translate(
                                    { id: 'CLIENT_FIRST_NAME_MAX_LENGTH_ERROR' },
                                    { 0: MaxFirstNameLength },
                                ))
                        }
                        name="firstName"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-label': translate({ id: 'CLIENT_FIRST_NAME' }) }}
                        data-cy="add-edit-first-name"
                    />
                </div>
                <div className={classes.input}>
                    <Controller
                        as={TextField}
                        control={control}
                        label={translate({ id: 'CLIENT_LAST_NAME' })}
                        required
                        rules={{
                            required: true,
                            maxLength: MaxLastNameLength,
                        }}
                        error={!!errors.lastName}
                        helperText={
                            (errors.lastName?.type === 'required'
                                && translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_LAST_NAME' }) },
                                ))
                            || (errors.lastName?.type === 'maxLength'
                                && translate(
                                    { id: 'CLIENT_LAST_NAME_MAX_LENGTH_ERROR' },
                                    { 0: MaxLastNameLength },
                                ))
                        }
                        name="lastName"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-label': translate({ id: 'CLIENT_LAST_NAME' }) }}
                        data-cy="add-edit-last-name"
                    />
                </div>
                <div className={classes.input}>
                    <TextField
                        inputRef={register({ validate: value => !value || validateEmail(value) })}
                        label={translate({ id: 'CLIENT_EMAIL' })}
                        name="email"
                        variant="outlined"
                        type="email"
                        error={!!errors.email}
                        helperText={
                            errors.email?.type === 'validate'
                                ? translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })
                                : errors.email?.type === 'available' && translate({ id: 'CLIENT_EMAIL_TAKEN_ERROR' })
                        }
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-label': translate({ id: 'CLIENT_EMAIL' }) }}
                        data-cy="add-edit-email"
                    />
                </div>
                <FormProvider {...methods}>
                    <EditPasswordFormComponent
                        respectPasswordRequirements
                        required={watchBypassPasswordRestriction}
                    />
                    {!isHostMode && <EditUserAuthSettingsFormComponent />}
                    {!isHostMode
                        && <EditGroupMembershipFormComponent
                            groups={groups}
                            defaultCheckedGroupIds={defaultChecked} />}
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default AddEditUserComponent;
