import { useGetErrorInfo } from '@experiences/error';
import type {
    ILabelModel,
    IPagination,
    IValueModelObject,
    LabelDataType,
    TagType,
} from '@experiences/interfaces';
import {
    OrchestratorObjectType,
    PermissionType,
} from '@experiences/interfaces';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { useUiDataContext } from '@experiences/ui-common';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import {
    IconButton,
    Tooltip,
} from '@mui/material';
import { elementToNode } from '@uipath/portal-shell-react';
import type {
    GridHeaderButtons,
    GridRowButtons,
    IColumn,
    IGridOptions,
} from '@uipath/portal-shell-types/components/angular-elements';
import { omit } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    useForm,
    useWatch,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { ITenant } from '../../../../common/interfaces/tenant/tenant';
import {
    createKeyValue,
    deleteValueFromKey,
    deleteValuesFromKey,
    getLabel,
    getPermissions,
    getValuesForKey,
    tagsUrl,
    updateLabel,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import { redirectToOrchestrator } from '../../common/redirectToOrchestrator.default';
import TenantTagsDeleteDialogBody from '../../common/TenantTagsDeleteDialogBody';
import TenantTagsSaveWarningDialogBody from '../../common/TenantTagsSaveWarningDialogBody';
import { useTagsCancel } from '../../common/useTagsCancel';
import type { ITenantTagsPropertiesContext } from './AddEditTenantTagsPropertiesComponent';
import RegexWarningDialogBody from './RegexWarningDialogBody';
import {
    hasPermission,
    isValidRegex,
    validateName,
} from './TenantTagsUtil';
import ValuesWarningDialogBody from './ValuesWarningDialogBody';

interface ITagLabelDto extends Omit<Partial<ILabelModel>, 'values'> {
    values: IValueModelObject[];
}

const DEFAULT_VALUES = {
    key: '',
    name: '',
    description: '',
    dataType: 'String' as LabelDataType,
    regex: '',
    type: 'KeyValue' as TagType,
    values: [],
};

export const ValueDataTypes = {
    ['String']: 'CLIENT_VALUE_DATA_TYPE_STRING',
    ['Number']: 'CLIENT_VALUE_DATA_TYPE_NUMBER',
    ['Boolean']: 'CLIENT_VALUE_DATA_TYPE_BOOLEAN',
    ['Regex']: 'CLIENT_VALUE_DATA_TYPE_REGEX',
};

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const useAddEditTenantTagsPropertiesViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const match = useRouteMatch<{ tenantId: string; type: 'add' | 'edit'; id: string; editType: string }>();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const createDialog = useShowDialog();
    const { getErrorMessage } = useGetErrorInfo();
    const createNotification = useUiSnackBar();
    const { logEvent } = useTelemetryHelper();

    const organizationName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);

    const apGridRef = useRef<any>(null);
    const [ index, setPageIndex ] = useState(DEFAULT_PAGE_INDEX);
    const [ size, setPageSize ] = useState(DEFAULT_PAGE_SIZE);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ prevDataType, setPrevDataType ] = useState('String');
    const [ prevRegex, setPrevRegex ] = useState('');

    const {
        register, control, handleSubmit, reset, setError, formState, setValue, getValues, clearErrors,
    } = useForm<ITagLabelDto>({
        mode: 'onSubmit',
        defaultValues: DEFAULT_VALUES,
    });

    const { isDirty } = formState;

    const values = useWatch<IValueModelObject[]>({
        name: 'values',
        control,
        defaultValue: [],
    });

    const dataType = useWatch<LabelDataType>({
        name: 'dataType',
        control,
        defaultValue: 'String',
    });

    const regex = useWatch<string>({
        name: 'regex',
        control,
        defaultValue: '',
    });

    const tenantId = match.params.tenantId;
    const type = match.params.type;
    const id = match.params.id;
    const editType = match.params.editType;
    const refreshState = useState(false);
    const [ _, setRefresh ] = refreshState;

    const isAdd = useMemo(() => type === 'add', [ type ]);
    const isEditDetails = useMemo(() => type === 'edit' && editType === 'details', [ editType, type ]);
    const isEditKeyValues = useMemo(() => type === 'edit' && editType === 'keyValues', [ editType, type ]);

    const cancel = useTagsCancel(isDirty, !isEditKeyValues);

    const {
        data: tenant, isValidating: tenantLoading,
    } = useSWR<ITenant>(
        (tenantId && !process.buildConfigs.showForMSI) ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null,
        getTenantById,
    );

    const {
        data: propertyData, isValidating: propertyLoading,
    } = useSWR<ILabelModel>(
        type === 'edit' && ((id && tenant) || (process.buildConfigs.showForMSI && accountId && id))
            ? [ `${tagsUrl}/keyValue`, organizationName, tenant?.name, id, accountId ]
            : null,
        getLabel,
    );

    const {
        data: valuesData, mutate: valuesMutate, isValidating: valuesLoading,
    } = useSWR<{ totalCount: number; results: any[] }>(
        isEditKeyValues && ((id && tenant) || (process.buildConfigs.showForMSI && accountId)) ? [
            `${tagsUrl}/keyValue/values`, {
                top: size,
                skip: index * size,
                searchTerm,
            }, organizationName, tenant?.name, id, accountId,
        ] : null,
        (_url: string, pagination: IPagination, organizationName: string, tenantName: string, key: string, selectedAccountId: string) =>
            getValuesForKey(pagination, organizationName, tenantName, key, selectedAccountId),
    );

    const { data: permissions } = useSWR(
        (organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId) ?
            [ `${tagsUrl}/permissions`, organizationName, tenant?.name, accountId ] : null,
        (_url: string, organizationName: string, tenantName: string, selectedAccountId: string) =>
            getPermissions(organizationName, tenantName, selectedAccountId),
    );

    const isBooleanDataType = useMemo(() => propertyData ? propertyData.dataType === 'Boolean' : dataType === 'Boolean',
        [ propertyData, dataType ],
    );

    const isRegex = useMemo(() => propertyData ? propertyData.dataType === 'Regex' : dataType === 'Regex', [ dataType, propertyData ]);

    const changePageIndex = useCallback((pageIndex) => {
        setPageIndex(pageIndex);
        mutate([
            [
                `${tagsUrl}/keyValue/values`, {
                    top: size,
                    skip: pageIndex * size,
                    searchTerm,
                }, organizationName, tenant?.name, id, accountId,
            ],
            (pagination: IPagination, organizationName: string, tenantName: string, key: string, selectedAccountId: string) =>
                getValuesForKey(pagination, organizationName, tenantName, key, selectedAccountId),
        ]);
    }, [ accountId, id, organizationName, searchTerm, size, tenant?.name ]);

    const changePageSize = useCallback((pageSize) => {
        setPageSize(pageSize);
        mutate([
            [
                `${tagsUrl}/keyValue/values`, {
                    top: pageSize,
                    skip: index * pageSize,
                    searchTerm,
                }, organizationName, tenant?.name, id, accountId,
            ],
            (pagination: IPagination, organizationName: string, tenantName: string, key: string, selectedAccountId: string) =>
                getValuesForKey(pagination, organizationName, tenantName, key, selectedAccountId),
        ]);
    }, [ accountId, id, index, organizationName, searchTerm, tenant?.name ]);

    const onSubmit = useCallback(async (data: ITagLabelDto) => {
        try {
            if (data.name && !validateName(data.name)) {
                setError('name', {
                    type: 'invalid',
                    message: translate({ id: 'CLIENT_TENANT_TAGS_NAME_ERROR' }),
                });
                return;
            }
            if (isAdd) {
                await createKeyValue(
                    organizationName,
                    tenant?.name,
                    {
                        ...data,
                        values: data.values.map(valObj => valObj.normalizedValue),
                        type: 'KeyValue',
                    },
                    accountId,
                );
                logEvent('PortalTagManagement.CreateProperty');
            } else {
                if (propertyData?.entityReferencesCount) {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TENANT_TAGS_OBJECTS_AFFECTED_BY_EDIT_TITLE' }),
                        customDialogContent: TenantTagsSaveWarningDialogBody,
                        icon: 'info',
                    });
                    if (!proceed) {
                        return;
                    }
                }
                await updateLabel(
                    organizationName,
                    tenant?.name,
                    propertyData?.key,
                    {
                        ...omit(data, 'values'),
                        type: 'KeyValue',
                    },
                    accountId,
                );
                logEvent('PortalTagManagement.EditKeyDetails');
            }
            createNotification(translate({ id: 'CLIENT_YOUR_CHANGES_WERE_SAVED_SUCCESSFULLY' }), notificationType.SUCCESS);
            setRefresh(true);
            history.push({
                pathname: getRoute(
                    process.buildConfigs.showForMSI
                        ? RouteNames.TenantTagsPropertiesMsi
                        : RouteNames.TenantTagsProperties.replace(':tenantId', tenantId),
                ),
                state: { refresh: true },
            });
        } catch (e) {
            const message = await getErrorMessage(e);
            if (message.includes('already exists')) {
                setError('name', {
                    type: 'duplicate',
                    message: translate({ id: 'CLIENT_DUPLICATE_NAME' }),
                });
            }
        }
    }, [
        isAdd,
        createNotification,
        translate,
        setRefresh,
        history,
        getRoute,
        tenantId,
        setError,
        organizationName,
        tenant?.name,
        accountId,
        logEvent,
        propertyData?.entityReferencesCount,
        propertyData?.key,
        createDialog,
        getErrorMessage,
    ]);

    useEffect(() => {
        if (type === 'edit' && propertyData) {
            setPrevDataType(propertyData.dataType);
            reset({
                key: propertyData.key,
                name: propertyData.name,
                description: propertyData.description,
                dataType: propertyData.dataType,
                regex: propertyData.regex,
                type: 'KeyValue' as TagType,
                values: [],
            });
        }
    }, [ propertyData, reset, type ]);

    const {
        data: gridResourceData, setData,
    } = useUiDataContext<ITenantTagsPropertiesContext>();

    useEffect(() => {
        if (gridResourceData.Refresh === true) {
            setRefresh(true);
            setData({
                ...gridResourceData,
                Refresh: false,
            });
            if (isEditKeyValues) {
                valuesMutate();
            }
        }

        if (gridResourceData.Values?.length) {
            setValue('values', gridResourceData.Values, { shouldDirty: true });
        }
    }, [ gridResourceData, isEditKeyValues, setData, setRefresh, setValue, valuesMutate ]);

    const calculateDrawerRoute = useMemo(() => {
        let editRoute = '';
        if (isAdd) {
            editRoute = 'key';
        } else if (isEditDetails) {
            editRoute = 'details';
        } else if (isEditKeyValues) {
            editRoute = 'keyValues';
        }

        const cloud: string = RouteNames.TenantTagsPropertiesAddEdit.replace(':tenantId', tenantId);
        const msi: string = RouteNames.TenantTagsPropertiesAddEditMsi;

        const base = process.buildConfigs.showForMSI ? msi : cloud;
        const ret = getRoute(`${base
            .replace(':type(add|edit)', type)
            .replace(':editType(key|details|keyValues)', editRoute)
            .replace('/:id?', isEditDetails || isEditKeyValues ? `/${id}` : '')}/values`);
        return ret;
    }, [ getRoute, id, isAdd, isEditDetails, isEditKeyValues, tenantId, type ]);

    const openValuesDrawer = useCallback(() => {
        let regExpString: string = '';
        if (propertyData?.regex) {
            regExpString = propertyData.regex;
        } else {
            regExpString = getValues('regex') ?? '';
        }
        setData({
            Values: getValues('values'),
            DataType: getValues('dataType') ?? dataType,
            Regex: new RegExp(regExpString),
        });
        history.push(calculateDrawerRoute);
    }, [ setData, getValues, dataType, propertyData, history, calculateDrawerRoute ]);

    const deleteValueRow = useCallback(async (row) => {

        const deletedValue = values.filter(value => value.normalizedValue === row.normalizedValue);
        const filteredValues = values.filter(value => value.normalizedValue !== row.normalizedValue);
        const key = row.key;

        try {
            if (type === 'add') {
                setValue('values', filteredValues);
            } else if (type === 'edit') {
                let proceed = true;
                proceed = await createDialog({
                    title: translate({ id: 'CLIENT_TENANT_TAGS_DELETE_VALUE_HEADER' }),
                    customDialogContent: TenantTagsDeleteDialogBody,
                    customDialogContentProps: { deleteType: 'Value' },
                    icon: 'error',
                });

                if (proceed) {
                    await deleteValueFromKey(organizationName, tenant?.name, id, key, accountId);

                    createNotification(
                        translate(
                            { id: 'CLIENT_VALUES_WERE_DELETED_FROM_THE_KEY' },
                            { values: deletedValue.join(', ') }),
                        notificationType.INPROGRESS,
                    );

                    setRefresh(true);
                    valuesMutate();
                }
            }
        } catch (e) {
            createNotification(
                translate({ id: 'CLIENT_FAILED_TO_DELETE_VALUES_FROM_KEY' }, { values: deletedValue.join(', ') }),
                notificationType.ERROR,
            );
        }
    }, [
        accountId,
        values,
        type,
        setValue,
        createDialog,
        translate,
        organizationName,
        tenant,
        id,
        createNotification,
        setRefresh,
        valuesMutate,
    ]);

    const columnDef: Array<IColumn<IValueModelObject>> = useMemo(() => [
        {
            property: 'displayValue',
            title: translate({ id: 'CLIENT_VALUES' }),
            sortable: false,
            visible: true,
        },
    ], [ translate ]);

    const getOrchestratorCount = useCallback((row: IValueModelObject) => row.entityReferencesCount?.reduce((acc, curr) => {
        if (OrchestratorObjectType.includes(curr.entityType)) {
            return acc + curr.count;
        }
        return acc;
    }, 0) ?? 0, []);

    const taggedObjectsTooltipTitle = useCallback((row: IValueModelObject) => {
        const orchestratorCount = getOrchestratorCount(row);
        return orchestratorCount > 0
            ? translate({ id: 'CLIENT_LIST_TAGGED_ORCHESTRATOR_OBJECTS' })
            : translate({ id: 'CLIENT_TAG_HAS_NO_REFERENCES_PROPERTY' });
    }, [ getOrchestratorCount, translate ]);

    const extraActionRowButtons: Array<GridRowButtons<IValueModelObject>> = useMemo(() => {
        if (!isAdmin) {
            return [];
        }
        return [
            {
                id: 'listTaggedObjects',
                label: translate({ id: 'CLIENT_LIST_TAGGED_ORCHESTRATOR_OBJECTS' }),
                icon: 'list_alt',
                dataCy: 'tags-properties-tagged-objects-button',
                customTemplate: (row: IValueModelObject) => elementToNode(
                    <ApolloThemeProvider>
                        <IntlProvider>
                            <Tooltip
                                title={taggedObjectsTooltipTitle(row)}>
                                <span>
                                    <IconButton
                                        target='_blank'
                                        href={redirectToOrchestrator(
                                            organizationName,
                                            tenant?.name,
                                            {
                                                kvp: {
                                                    data: row,
                                                    id: `${getValues('name')?.toLowerCase()}:${row.displayValue}`,
                                                    text: `${getValues('name')}:${row.displayValue}`,
                                                },
                                            },
                                        )}
                                        onClick={() => {
                                            logEvent('PortalTagManagement.ListTaggedObjectsProperty');
                                        }}
                                        disabled={getOrchestratorCount(row) === 0}
                                    >
                                        <ListAltOutlinedIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </IntlProvider>
                    </ApolloThemeProvider>,
                ),
            },
            {
                id: 'delete',
                label: translate({ id: 'CLIENT_DELETE' }),
                icon: 'delete',
                onClick: deleteValueRow,
                disabled: isBooleanDataType || (isEditKeyValues && !hasPermission(permissions, [ PermissionType.Delete ])),
            },
        ];
    }, [ deleteValueRow, getOrchestratorCount, getValues, isAdmin, isBooleanDataType, isEditKeyValues, logEvent, organizationName, permissions, taggedObjectsTooltipTitle, tenant?.name, translate ]);

    const deleteValueRowBulk = useCallback(
        async (rows: IValueModelObject[]) => {
            const currentValues = getValues('values');
            const deletedValueKeys = rows.map(row => row.key);
            const deletedValues = rows.map((row) => row.normalizedValue);
            try {
                if (type === 'add') {
                    const filteredValues = currentValues.filter(val => !deletedValues.includes(val.normalizedValue));
                    setValue('values', filteredValues);
                } else if (type === 'edit') {
                    let proceed = true;
                    proceed = await createDialog({
                        title: translate({ id: 'CLIENT_TENANT_TAGS_DELETE_PROPERTY_HEADER' }),
                        customDialogContent: TenantTagsDeleteDialogBody,
                        customDialogContentProps: { deleteType: 'Value' },
                        icon: 'error',
                    });

                    if (proceed) {
                        await deleteValuesFromKey(organizationName, tenant?.name, id, deletedValueKeys, accountId);

                        createNotification(
                            translate(
                                { id: 'CLIENT_VALUES_WERE_DELETED_FROM_THE_KEY' },
                                { values: deletedValues.join(', ') }),
                            notificationType.INPROGRESS,
                        );

                        setRefresh(true);
                        valuesMutate();
                    }
                }
            } catch (e) {
                createNotification(
                    translate({ id: 'CLIENT_FAILED_TO_DELETE_VALUES_FROM_KEY' }, { values: deletedValues.join(', ') }),
                    notificationType.ERROR,
                );
            }
        },
        [
            accountId,
            getValues,
            type,
            setValue,
            createDialog,
            translate,
            organizationName,
            tenant,
            id,
            createNotification,
            setRefresh,
            valuesMutate,
        ],
    );

    const extraActionHeaderButtons: Array<GridHeaderButtons<IValueModelObject>> = useMemo(() => {
        const actionList: Array<GridHeaderButtons<IValueModelObject>> = [];

        const addValues: GridHeaderButtons<IValueModelObject> = {
            id: 'addValues',
            type: 'main',
            buttonType: 'mat-flat-button',
            color: 'primary',
            text: translate({ id: 'CLIENT_ADD_VALUES' }),
            label: translate({ id: 'CLIENT_ADD_VALUES' }),
            icon: 'add',
            onClick: () => openValuesDrawer(),
            dataCy: 'ap-grid-add-values-button',
            disabled: (
                isBooleanDataType
                || (isAdd && dataType === 'Regex' && !regex)
                || !!formState.errors.regex
                || !hasPermission(permissions, [ PermissionType.Create ])
            ),
        };

        const bulkDeleteAction: GridHeaderButtons<IValueModelObject> = {
            id: 'deleteProperties',
            type: 'action',
            buttonType: 'mat-flat-button',
            icon: 'delete',
            color: 'warn',
            text: translate({ id: 'CLIENT_DELETE' }),
            label: translate({ id: 'CLIENT_DELETE' }),
            onClick: (rows) => deleteValueRowBulk(rows ?? []),
            disabled: (isEditKeyValues && !hasPermission(permissions, [ PermissionType.Delete ])),
        };
        isAdmin && actionList.push(...[ addValues, bulkDeleteAction ]);
        return actionList;
    }, [
        translate,
        isBooleanDataType,
        isAdd,
        dataType,
        regex,
        formState.errors.regex,
        permissions,
        isEditKeyValues,
        isAdmin,
        openValuesDrawer,
        deleteValueRowBulk,
    ]);

    const renderedValues = useCallback((index: number, searchTerm: string, size: number, values: IValueModelObject[]) => {
        const filteredValues = values.filter(item => item.displayValue.toLowerCase().includes(searchTerm.toLowerCase()));
        return filteredValues.slice(index * size, index * size + size);
    }, []);

    const setBooleanValues = useCallback(() => {
        const booleanValues = [
            {
                displayValue: 'true',
                id: '',
                key: '',
                normalizedValue: 'true',
            },
            {
                displayValue: 'false',
                id: '',
                key: '',
                normalizedValue: 'false',
            },
        ];
        setValue('values', booleanValues);
    }, [ setValue ]);

    const refreshValues = useCallback(async (newDataType) => {
        let proceed = true;
        proceed = await createDialog({
            title: translate({ id: 'CLIENT_CHANGE_VALUES_WARNING_HEADER' }),
            customDialogContent: ValuesWarningDialogBody,
            icon: 'error',
        });
        if (proceed) {
            setValue('dataType', newDataType);
            if (newDataType === 'Boolean') {
                setBooleanValues();
            } else {
                setValue('values', []);
            }
            setPrevDataType(newDataType);

        } else {
            setValue('dataType', prevDataType);
            setValue('regex', regex);
        }
    }, [ createDialog, prevDataType, regex, setBooleanValues, setValue, translate ]);

    const changeDataType = useCallback((e) => {
        if (values.length === 0 && e.target.value === 'Boolean') {
            setValue('dataType', e.target.value);
            setBooleanValues();
            setPrevDataType(e.target.value);
        } else if (values.length > 0 && prevDataType !== 'Boolean') {
            refreshValues(e.target.value);
        } else {
            setValue('dataType', e.target.value);
            setValue('values', []);
            setPrevDataType(e.target.value);
        }
    }, [ prevDataType, refreshValues, setBooleanValues, setValue, values.length ]);

    const handleOnBlurRegex = useCallback(async (e, valueModel: IValueModelObject[], prevReg) => {

        // eslint-disable-next-line no-useless-escape
        const invalidChar = [ '\<', '\>', '\%', '\&', '\/', '\?', '\\', '\:' ];
        for (const c of invalidChar) {
            if (e.target.value.includes(c)) {
                setError('regex', {
                    type: 'invalid',
                    message: translate({ id: 'CLIENT_ADD_VALUE_INVALID_REGEX_CHAR_ERROR' }),
                });
                return;
            }
        }

        const isValid = isValidRegex(e.target.value);
        if (!isValid) {
            setError('regex', {
                type: 'invalid',
                message: translate({ id: 'CLIENT_ADD_VALUE_INVALID_REGEX_ERROR' }),
            });
        } else {
            clearErrors('regex');
            if (e.target.value !== prevReg && valueModel?.length > 0) {
                let proceed = false;
                if (prevReg.length > 0) {
                    proceed = await createDialog({
                        title: translate({ id: 'CLIENT_CHANGE_VALUES_WARNING_HEADER' }),
                        customDialogContent: RegexWarningDialogBody,
                        icon: 'warning',
                    });
                }
                if (proceed) {
                    setValue('values', []);
                    setPrevRegex(e.target.value);
                } else {
                    setValue('regex', prevReg);
                }
            } else {
                setPrevRegex(e.target.value);
            }
        }
    }, [ clearErrors, createDialog, setError, setValue, translate ]);

    const configuration: IGridOptions<any> = useMemo(() => ({
        loading: isEditKeyValues ? (propertyLoading || tenantLoading || valuesLoading) : false,
        columns: columnDef,
        headerOptions: {
            search: true,
            searchTerm: (term: string) => setSearchTerm(term),
            gridHeaderButtons: extraActionHeaderButtons,
        },
        refreshable: true,
        data: (isEditKeyValues ? valuesData?.results : renderedValues(index, searchTerm, size, values)) ?? [],
        refresh: valuesMutate,
        selectable: !isBooleanDataType,
        footerOptions: {
            length: valuesData?.totalCount ?? values.length,
            pageSizes: [ 5, 10, 25, 50 ],
            pageIndex: index,
            pageSize: size,
            pageChange: ({
                pageIndex, pageSize,
            }: {
                pageIndex: number; pageSize: number;
            }) => {
                index !== pageIndex && changePageIndex(pageIndex);
                size !== pageSize && changePageSize(pageSize);
            },
        },
        gridRowButtons: extraActionRowButtons,
    }), [
        isEditKeyValues,
        propertyLoading,
        tenantLoading,
        valuesLoading,
        columnDef,
        setSearchTerm,
        extraActionHeaderButtons,
        valuesData?.results,
        valuesData?.totalCount,
        renderedValues,
        index,
        searchTerm,
        size,
        values,
        valuesMutate,
        isBooleanDataType,
        extraActionRowButtons,
        changePageIndex,
        changePageSize,
    ]);

    useEffect(() => {
        if (apGridRef.current && configuration) {
            apGridRef.current.configuration = configuration;
        }
    }, [ configuration ]);

    return {
        isAdmin,
        isEditDetails,
        isEditKeyValues,
        values,
        history,
        type,
        id,
        editType,
        property: propertyData,
        organizationName,
        tenant,
        tenantId,
        cancel,
        tenantLoading,
        propertyLoading,
        valuesLoading,
        isRegex,
        methods: {
            register,
            control,
            formState,
            handleSubmit: handleSubmit(onSubmit),
            setValue,
            getValues,
        },
        createDialog,
        openValuesDrawer,
        deleteValueRow,
        deleteValueRowBulk,
        translate,
        refreshState,
        apGridRef,
        onSubmit,
        changeDataType,
        setBooleanValues,
        refreshValues,
        handleOnBlurRegex,
        prevRegex,
        renderedValues,
        extraActionRowButtons,
        taggedObjectsTooltipTitle,
    };
};

export default useAddEditTenantTagsPropertiesViewModel;
