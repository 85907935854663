// @ts-nocheck

import { GlobalStyles } from '@experiences/theme';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { ReactComponent as ProcessIcon } from '../../../images/processesIcon.svg';
import {
    getProcesses,
    processesUrl,
} from '../../../services/orchestrator/ProcessesService';
import { accountLogicalName } from '../../../store/selectors';
import HomePageHeader from '../../home/helper/HomePageHeader';
import useTenantChangeListener from '../TenantChangeListener';
import type { IProcessesApp } from './interfaces/processes';
import ProcessesCard from './ProcessesCard';

const useStyles = makeStyles((theme) => ({
    ...GlobalStyles(theme),
    ...createStyles({
        processWidget: { padding: '20px 0' },
        processContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            maxHeight: '120px',
            overflow: 'hidden',
            margin: '0px -8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            '& $cardWrapper': {
                marginTop: '4px',
                boxSizing: 'border-box',
                height: '112px',
                minWidth: '311px',
                marginRight: '24px',
                marginBottom: '12px',
            },
        },
        cardWrapper: {},
        '@media all and (max-width: 960px)': { cardWrapper: { width: '30%' } },
        '@media all and (min-width: 1024px)': { cardWrapper: { width: '30%' } },
        '@media all and (min-width: 1400px)': { cardWrapper: { width: '20%' } },
        '@media all and (min-width: 1600px)': { cardWrapper: { width: '15%' } },
        '@media all and (min-width: 1920px)': { cardWrapper: { width: '10%' } },
        headerBar: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '12px 0',
        },
        noData: {
            fontSize: '14px',
            height: '36px',
            paddingLeft: '8px',
            color: theme.palette.semantic.colorForeground,
        },
        spinner: { padding: '0 8px' },
    }),
}));

const ProcessesComponent: React.FC = () => {
    const [ currentTenant, setCurrentTenant ] = useState(
        localStorage.getItem('PORTAL_CURRENT_TENANT'),
    );
    const logicalName = useSelector(accountLogicalName);

    const tenantChangeListener = useTenantChangeListener(setCurrentTenant);

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const onViewAll = () => {
        window.location.assign(
            `${window.location.origin}/${logicalName}/${localStorage.getItem(
                'PORTAL_CURRENT_TENANT',
            )}/processes_`,
        );
    };

    const {
        data: processesList,
        isValidating: isValidating,
        error: processError,
    } = useSWR<IProcessesApp[], Error>(
        processesUrl + '/' + currentTenant,
        (() => getProcesses(currentTenant, logicalName)) as () => Promise<
        IProcessesApp[]
        >,
    );

    useEffect(() => {
        window.addEventListener('tenantChanged', tenantChangeListener);
        mutate(processesUrl + '/' + currentTenant);
        return function cleanup() {
            window.removeEventListener('tenantChanged', tenantChangeListener);
        };
        // eslint-disable-next-line
    }, [currentTenant]);

    return (
        <div
            data-cy="home-page-process-widget"
            className={classes.processWidget}
        >
            <div
                className={classes.headerBar}
                data-cy="home-page-processes-header-bar"
            >
                <HomePageHeader
                    icon={<ProcessIcon className={classes.svgIcon} />}
                    label={translate({ id: 'CLIENT_RECENT_PROCESSES' })}
                    fontSize="20px"
                />
                <Button
                    color="primary"
                    onClick={onViewAll}
                    data-cy="home-page-processess-view-all-button"
                >
                    <HomePageHeader
                        label={translate({ id: 'CLIENT_SEE_ALL_PROCESSES' })}
                        variant="button"
                    />
                </Button>
            </div>
            <div className={clsx(classes.processContainer)}>
                {processesList &&
                    Array.isArray(processesList) &&
                    processesList.map((process, id) => (
                        <div
                            key={id}
                            className={clsx(classes.cardWrapper)}>
                            <ProcessesCard
                                data-cy="home-page-process-card"
                                processInfo={process}
                                processURL={`${
                                    window.location.origin
                                }/${logicalName}/${localStorage.getItem(
                                    'PORTAL_CURRENT_TENANT',
                                )}/processes_/${process.Type}/${
                                    process.Entity.Id
                                }`}
                                idx={id}
                            />
                        </div>
                    ))}
                {!isValidating && processesList?.length === 0 && (
                    <div className={classes.noData}>
                        {translate({ id: 'CLIENT_NO_PROCESSES' })}
                    </div>
                )}
                {isValidating && (
                    <div className={classes.spinner}>
                        <CircularProgress
                            size={36}
                            thickness={4} />
                    </div>
                )}
                {!isValidating && processError && (
                    <div className={classes.noData}>
                        {translate({ id: 'CLIENT_NO_PROCESSES' })}
                    </div>
                )}
            </div>
        </div>
    );
};
export default ProcessesComponent;
