import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import { ConsumptionInfo } from '../../../common/constants/Constant';
import type { ITenantServiceProduct } from '../interfaces/service';

const useStyles = makeStyles(theme =>
    createStyles({
        consumptionInfoContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: '0.5em',
        },
        mapContainer: {
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
        },
        bullet: {
            width: '5px',
            height: '5px',
            borderRadius: '100%',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            background: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginLeft: '12px',
            fontSize: '12px',
            lineHeight: '16px',
        },
    }),
);

class ConsumptionInfoValue {
    translateId: string;
    translateMessageValue: number;
    hideIfZero: boolean;
    constructor(
        translationId: string,
        translateMessageValue: number,
        hideIfZero: boolean = false,
    ) {
        this.translateId = translationId;
        this.translateMessageValue = translateMessageValue;
        this.hideIfZero = hideIfZero;
    }
}

const ConsumptionInfoComponent: React.FC<{
    product: ITenantServiceProduct;
    isNewTenant: boolean;
}> = ({
    product, isNewTenant,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const consumptionInfoBullets: ConsumptionInfoValue[] = [
        new ConsumptionInfoValue(
            ConsumptionInfo.AllocatedQuantity,
            product.allocated,
            isNewTenant,
        ),
        new ConsumptionInfoValue(
            ConsumptionInfo.AvailableForAllocation,
            product.available,
        ),
        new ConsumptionInfoValue(
            ConsumptionInfo.ConsumedOnTenant,
            product.used,
            isNewTenant,
        ),
        new ConsumptionInfoValue(
            ConsumptionInfo.AllocatedAcrossOtherTenants,
            product.allocatedAcrossOtherTenants,
        ),
        new ConsumptionInfoValue(
            ConsumptionInfo.ConsumedByDeletedTenants,
            product.consumedByDeletedTenants,
            true,
        ),
        new ConsumptionInfoValue(
            ConsumptionInfo.TotalUnitsInAccount,
            product.totalUnits,
        ),
        new ConsumptionInfoValue(
            ConsumptionInfo.ConsumedAtOrganizationLevel,
            product.consumedAtOrganizationLevel,
            true,
        ),
    ];

    return <div
        className={clsx(classes.consumptionInfoContainer)}
        data-cy="consumption-info"
    >
        {consumptionInfoBullets.map((consumptionInfo, index) => (!consumptionInfo.hideIfZero || consumptionInfo.translateMessageValue !== 0) && <div
            key={index}
            className={clsx(classes.mapContainer)}
            data-cy={`consumption-info-value-${product.code}`}>
            <span className={clsx(classes.bullet)} />
            <Typography className={clsx(classes.inputLabel)}>
                {translate(
                    { id: consumptionInfo.translateId },
                    { 0: consumptionInfo.translateMessageValue.toLocaleString() })}
            </Typography>
        </div>)}
    </div>;
};

export default ConsumptionInfoComponent;
