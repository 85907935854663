import { OperationTypeConstants } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useSupportedLanguagesMap } from '@experiences/locales';
import {
    UiCopyButton,
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import {
    getAsset,
    getMiddleTruncatedString,
    useRouteResolver,
} from '@experiences/util';
import type { Theme } from '@mui/material';
import {
    Button,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { TextFieldClassName } from '@uipath/portal-shell-util';
import clsx from 'clsx';
import type { UnregisterCallback } from 'history';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import useUserInfo from '../../../auth/hooks/UserInfo';
import {
    accountSetting,
    notificationType,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { triggerPortalShellRefresh } from '../../../common/hooks/triggerPortalShellRefresh';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { ServiceErrorCode } from '../../../common/interfaces/error';
import type { IOrganizationError } from '../../../common/interfaces/organization';
import {
    cobrandingUrl,
    deleteLogo,
    getLogos,
    uploadLogo,
} from '../../../services/organization/CobrandingService';
import { updateOrganization } from '../../../services/organization/OrganizationService';
import { setUserProfile } from '../../../store/action/UserProfileAction';
import { profile } from '../../../store/selectors';
import validateAccountLogicalName from '../../../util/validators/AccountLogicalNameValidator';
import UiAlertBanner from '../../common/UiAlertBanner';
import UiForm from '../../common/UiForm';
import { useTenantOperationTrackerContext } from '../../tenants/TenantOperationTrackerContextProvider';
import CobrandingComponent from '../subcomponents/CobrandingComponent';
import type {
    IGeneralSettingsForm,
    ILogo,
    ILogoSettingsDto,
    IUpdateOrganizationResponse,
} from './types/settings';

interface GeneralSettingsThemeProps {
    width: string | undefined;
}

const useStyles = makeStyles<Theme, GeneralSettingsThemeProps>(theme =>
    createStyles({
        root: props => ({
            paddingTop: '20px',
            width: props.width,
        }),
        input: { marginTop: '24px' },
        select: { marginTop: '16px' },
        generalSettingsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        warningText: { fontSize: '12px !important' },
        flex: { display: 'flex' },
        supportIdContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        supportIdLabel: {
            fontWeight: 600,
            lineHeight: '15px',
            marginBottom: '2px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        supportId: { color: theme.palette.semantic.colorForegroundDeEmp },
        actions: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginTop: '28px',
            marginBottom: '24px',
        },
        rightAlignedActions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            marginTop: '16px',
        },
    }),
);

const EnableLogoDatabaseStorage = getFeatureFlagValue(Features.EnableLogoDatabaseStorage.name);

const EnablePortalLogoCobrandingAdminUX = !process.buildConfigs.disablePortalLogoCobrandingAdminUX;

const orgNameIsOrgLogicalName = process.buildConfigs.orgNameIsOrgLogicalName;

const GeneralSettingsComponent: React.FC = () => {
    const classes = useStyles({ width: '100%' });
    const { formatMessage: translate } = useIntl();

    const setErrorMessage = useCentralErrorSetter();
    const { parseErrorObject } = useGetErrorInfo();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const history = useHistory();
    const getRoute = useRouteResolver();
    const {
        addTenantOperation, checkOperationList,
    } = useTenantOperationTrackerContext();

    const createNotification = useUiSnackBar();

    const [ loading, setLoading ] = useState(false);
    const [ operationId, setOperationId ] = useState('');
    const [ savedPayload, setSavedPayload ] = useState<IGeneralSettingsForm>();

    const proceedListener = useRef<UnregisterCallback>();

    const languageOptions = useSupportedLanguagesMap();

    const accountProfile = useSelector(profile);

    const { token } = useUserInfo();

    const {
        companyName,
        accountLogicalName,
        accountLanguage,
    } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const {
        data: logos,
        error: logoError,
        mutate: triggerLogos,
    } = useSWR<ILogoSettingsDto>(EnablePortalLogoCobrandingAdminUX ? [ cobrandingUrl ] : null, getLogos);

    const defaultValues = useMemo(() => ({
        companyName: companyName ?? '',
        logicalName: accountLogicalName ?? '',
        language: !accountLanguage || accountLanguage?.toLowerCase() === 'keys' ? 'en' : accountLanguage,
        lightLogo: {
            url: '',
            base64: '',
        },
        darkLogo: {
            url: '',
            base64: '',
        },
    }), [ accountLanguage, accountLogicalName, companyName ]);

    const methods = useForm<IGeneralSettingsForm>({
        mode: 'onSubmit',
        defaultValues,
        shouldUnregister: false,
    });

    const {
        control, handleSubmit, reset, setError, getValues, formState: {
            errors, dirtyFields,
        }, setValue, watch,
    } = methods;

    const initializeLogos = useCallback(async () => {
        const lightLogo = defaultValues.lightLogo;
        const darkLogo = defaultValues.darkLogo;

        if (logos?.lightLogo) {
            lightLogo.url = logos.lightLogo;
        }

        if (logos?.darkLogo) {
            darkLogo.url = logos.darkLogo;
        }

        if (EnableLogoDatabaseStorage) {
            if (lightLogo.url) {
                lightLogo.base64 = await getAsset(lightLogo.url, token, { base64: true });
            }

            if (darkLogo.url) {
                darkLogo.base64 = await getAsset(darkLogo.url, token, { base64: true });
            }
        }

        reset({
            ...defaultValues,
            darkLogo,
            lightLogo,
        });
    }, [ defaultValues, logos, reset, token ]);

    const onReset = useCallback(() => {
        triggerLogos();
        reset();
    }, [ reset, triggerLogos ]);

    const saveLogos = useCallback(async (lightLogo: ILogo | null, darkLogo: ILogo | null) => {
        const cobrandingPromises = [];

        const lightLogoUpdated = lightLogo?.image && dirtyFields.lightLogo?.url;
        const darkLogoUpdated = darkLogo?.image && dirtyFields.darkLogo?.url;

        const lightLogoDeleted = !lightLogo?.url && dirtyFields.lightLogo?.url;
        const darkLogoDeleted = !darkLogo?.url && dirtyFields.darkLogo?.url;

        if (lightLogoUpdated) {
            cobrandingPromises.push(uploadLogo('light', lightLogo.image!));
        }

        if (lightLogoDeleted) {
            cobrandingPromises.push(deleteLogo('light'));
        }

        if (darkLogoUpdated) {
            cobrandingPromises.push(uploadLogo('dark', darkLogo.image!));
        }

        if (darkLogoDeleted) {
            cobrandingPromises.push(deleteLogo('dark'));
        }

        await Promise.all(cobrandingPromises);

        triggerPortalShellRefresh();
    }, [ dirtyFields ]);

    const onSaveProceed = useCallback(
        async (data: IGeneralSettingsForm) => {
            const {
                companyName: companyNameFormData, logicalName, language: languageFormData,
            } = data;

            // language should never be undefined
            const language = languageFormData ?? 'en';

            try {
                const logicalNameChanged = dirtyFields.logicalName;
                const companyNameChanged = dirtyFields.companyName;

                const response: IUpdateOrganizationResponse = await updateOrganization({
                    companyName: companyNameFormData,
                    logicalName,
                    language,
                });

                if (companyNameChanged) {
                    setOperationId(response.id);
                    addTenantOperation(response.operationId, response.logicalName, response.id, OperationTypeConstants.ORGANIZATION);
                    createNotification(translate({ id: 'CLIENT_ACCOUNT_SETTINGS_CHANGED_ASYNC' }), notificationType.INPROGRESS);
                } else {
                    createNotification(translate({ id: 'CLIENT_ACCOUNT_SETTINGS_CHANGED' }));
                }

                if (EnablePortalLogoCobrandingAdminUX) {
                    await saveLogos(data.lightLogo, data.darkLogo);
                }

                const updateProfile = Object.assign({}, accountProfile);
                updateProfile.accountUserDto.companyName = companyNameFormData;
                updateProfile.accountUserDto.accountLogicalName = logicalName;
                updateProfile.accountUserDto.accountLanguage = language;

                setUserProfile(updateProfile);

                // set param in URL to avoid rendering Organization Switching warning dialog
                if (logicalNameChanged) {
                    history.push({
                        pathname: `/${data.logicalName}/portal_/settings`,
                        search: '?isOrgNameUpdate=true',
                    });
                    return;
                }

                reset(data);
            } catch (error) {
                const parsedError: IOrganizationError = await parseErrorObject(error);
                if (parsedError.ErrorCode === ServiceErrorCode.Conflict) {
                    setError('logicalName', { type: 'duplicate' });
                    setErrorMessage(translate({ id: 'CLIENT_BLACKLISTED_ACCOUNT_LOGICAL_NAME' }));
                } else if (parsedError.ErrorCode === ServiceErrorCode.ClientError && parsedError.Message.includes('is in-progress')) {
                    createNotification(translate({ id: 'CLIENT_OPERATION_IN_PROGRESS' }), notificationType.INPROGRESS);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_GENERIC_ERROR_MESSAGE' }));
                }
            } finally {
                setLoading(false);
            }
        },
        [
            dirtyFields,
            accountProfile,
            history,
            saveLogos,
            reset,
            addTenantOperation,
            createNotification,
            translate,
            parseErrorObject,
            setError,
            setErrorMessage,
        ],
    );

    const onSave = useCallback(
        async (data: IGeneralSettingsForm) => {
            setLoading(true);

            data.logicalName = data.logicalName.trim();
            data.companyName = data.companyName.trim();

            if (process.buildConfigs.orgNameIsOrgLogicalName && !validateAccountLogicalName(data.companyName)) {
                setError('companyName', { type: 'invalidLogical' });
                setLoading(false);
                return;
            }

            if (!validateAccountLogicalName(data.logicalName)) {
                setError('logicalName', { type: 'invalid' });
                setLoading(false);
                return;
            }

            if (accountLogicalName.toLowerCase() !== data.logicalName.toLowerCase()) {
                setSavedPayload(data);
                history.push(getRoute(RouteNames.OrganizationSettingsWarning));
            } else {
                await onSaveProceed(data);
            }
        },
        [ accountLogicalName, getRoute, history, onSaveProceed, setError ],
    );

    useEffect(() => {
        if (logos) {
            initializeLogos();
        }
    }, [ initializeLogos, logos ]);

    useEffect(() => {
        if (orgNameIsOrgLogicalName) {
            setValue('logicalName', watch('companyName').toLowerCase(), { shouldDirty: true });
        }
    }, [ setValue, watch ]);

    useEffect(() => {
        proceedListener.current = history.listen(async h => {
            if (h.state && (h.state as any)['proceed'] && savedPayload) {
                onSaveProceed(savedPayload);
            } else {
                setLoading(false);
            }
        });

        return () => {
            if (proceedListener.current) {
                proceedListener.current();
            }
        };
    }, [ history, loading, savedPayload, onSaveProceed ]);

    const formActions = useMemo(() => (
        <div className={clsx(classes.rightAlignedActions)}>
            <Button
                className={classes.cancelButton}
                onClick={onReset}
                color="primary"
                data-cy="organization-settings-form-reset-button"
            >
                {translate({ id: 'CLIENT_RESET' })}
            </Button>
            <UiProgressButton
                loading={loading}
                disabled={!!logoError || checkOperationList(operationId)}
                type="submit"
                variant="contained"
                data-cy="organization-settings-form-submit-button"
            >
                {translate({ id: 'CLIENT_SAVE_CHANGES' })}
            </UiProgressButton>
        </div>
    ), [ checkOperationList, classes, loading, logoError, onReset, operationId, translate ]);

    return <UiForm
        className={classes.root}
        onSubmit={handleSubmit(onSave)}
        actions={Object.keys(dirtyFields).length ? formActions : undefined}
        centerChild
        heightOffset="0px">
        <>
            {checkOperationList(operationId) && <UiAlertBanner
                dataCy='organization-info-updating-banner'
                type="warning"
                closeable={false}
                enterpriseTrialAlertBar>
                <div>
                    {translate({ id: 'CLIENT_ORGANIZATION_STATUS_UPDATING' })}
                </div>
            </UiAlertBanner>}
            <Controller
                as={TextField}
                control={control}
                rules={{
                    required: true,
                    maxLength: accountSetting.companyNameLength,
                }}
                error={!!errors.companyName}
                name="companyName"
                variant="outlined"
                label={`${translate({ id: 'CLIENT_ORGANIZATION_NAME' })} *`}
                InputLabelProps={{ id: 'organizationNameLabel' }}
                disabled={checkOperationList(operationId)}
                helperText={
                    (errors.companyName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                    || (errors.companyName?.type === 'invalidLogical'
                    && translate(
                        { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME_ORG' },
                        { 0: accountSetting.accountLogicalNameLength },
                    ))
                    || (errors.companyName
                        && translate({ id: 'CLIENT_VALID_COMPANY_NAME' }, { 0: accountSetting.companyNameLength }))
                }
                InputProps={{ className: 'Tall' }}
                fullWidth
                data-cy="organization-settings-form-companyName"
                inputProps={{ 'aria-labelledby': 'organizationNameLabel' }}
            />
            <div className={classes.input}>
                <Typography
                    className={classes.inputLabel}
                    id="logicalNameLabel">
                    {translate({ id: 'CLIENT_URL' })}
                </Typography>
                <div className={classes.flex}>
                    <Controller
                        as={TextField}
                        control={control}
                        rules={{
                            required: true,
                            maxLength: accountSetting.accountLogicalNameLength,
                        }}
                        error={!!errors.logicalName}
                        name="logicalName"
                        variant="outlined"
                        helperText={
                            (errors.logicalName?.type === 'required'
                                && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                            || (errors.logicalName?.type === 'duplicate'
                                && translate({ id: 'CLIENT_BLACKLISTED_ACCOUNT_LOGICAL_NAME' }))
                            || (errors.logicalName
                                && translate(
                                    { id: 'CLIENT_INVALID_ACCOUNT_LOGICAL_NAME' },
                                    { 0: accountSetting.accountLogicalNameLength },
                                ))
                            || translate({
                                id: process.buildConfigs.disableUserInvite
                                    ? 'CLIENT_URL_CHANGE_WARNING_HELPER_TEXT_NO_INVITE'
                                    : 'CLIENT_URL_CHANGE_WARNING_HELPER_TEXT',
                            })
                        }
                        FormHelperTextProps={{ className: classes.warningText }}
                        InputProps={{
                            readOnly: orgNameIsOrgLogicalName,
                            className: `Tall ${orgNameIsOrgLogicalName ? 'Mui-disabled' : ''}`,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography>
                                        {getMiddleTruncatedString(`${window.location.origin}/`)}
                                    </Typography>
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <UiCopyButton
                                        textToCopy={`${window.location.origin}/${accountLogicalName}`}
                                        aria-label={translate({ id: 'CLIENT_COPY_LOGICAL_NAME' })}
                                        data-cy="organization-settings-form-logicalName-copy-button"
                                    />
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            'aria-labelledby': 'logicalNameLabel',
                            className: `${orgNameIsOrgLogicalName ? 'Mui-disabled' : ''}`,
                        }}
                        className={TextFieldClassName.OmitStartAdornmentSpacing}
                        fullWidth
                        data-cy="organization-settings-form-logicalName"
                    />
                </div>
            </div>
            {!DisableFeatureFedRamp && (
                <div className={clsx(classes.select)}>
                    <UiSelect
                        control={control}
                        dataCy="organization-settings-form-language"
                        inputLabel={translate({ id: 'CLIENT_LANGUAGE' })}
                        name="language"
                        options={languageOptions}
                        error={!!errors.language}
                        fullWidth
                        isTranslated
                        required
                        helperText={translate({ id: 'CLIENT_LANGUAGE_CHANGE_NOTE_HELPER_TEXT' })}
                    />
                </div>
            )}
            {EnablePortalLogoCobrandingAdminUX && (
                <div className={clsx(classes.input)}>
                    <FormProvider {...methods}>
                        <CobrandingComponent />
                    </FormProvider>
                </div>
            )}
        </>
    </UiForm>;
};

export default GeneralSettingsComponent;
