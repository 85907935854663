import type { NavCustomizationSetting } from '@experiences/constants';
import { getSetting } from '@uipath/portal-shell-util';
import type { ISetting } from '@uipath/portal-shell-util/lib/types/SettingTypes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    getSetting as getSettingPromise,
    settingUrl,
} from '../../services/identity/SettingService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../store/selectors';

const useIsCustomizationElementVisible = (navCustomizationSetting: NavCustomizationSetting) => {
    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const navCustomizationKeys = useMemo(() => [ navCustomizationSetting ], []);
    const { data: fetchedSettings } = useSWR<ISetting[], Error>(
        [ settingUrl, navCustomizationKeys, partitionGlobalId ],
        getSettingPromise,
        { shouldRetryOnError: false },
    );
    const hidden = getSetting(fetchedSettings, navCustomizationSetting);
    return useMemo(() => isAdmin || hidden !== 'true', [ isAdmin, hidden ]);
};

export default useIsCustomizationElementVisible;
