import type {
    IHistoryStatus,
    IPlanDetails,
} from '@experiences/ecommerce';
import {
    BillingEntityCode,
    EcommerceDiscountedPrice,
    EcommercePrice,
    getLicenseInfoUiState,
    INCREMENTAL_FLOW,
    storePlanType,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import {
    Features,
    getFeatureFlagValue,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import {
    Button,
    CircularProgress,
    Link,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import moment from 'moment';
import type { KeyboardEvent } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import {
    billingUrl,
    fetchHistoryStatus,
    fetchPlanDetails,
    goToStripeCustomerPortalSession,
} from '../../../services/licensing/BillingService';
import { accountLogicalName } from '../../../store/selectors';
import { formatDate } from '../../../util/DateUtil';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        infoSection: { display: 'flex' },
        supportSection: {
            display: 'flex',
            marginTop: '14px',
        },
        alignRight: { marginLeft: 'auto' },
        column: { marginLeft: '60px' },
        row: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        value: {
            marginTop: '4px',
            letterSpacing: '0em',
            textAlign: 'left',
        },
        fontSizeM: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
        },
        colorForeground: {
            color: theme.palette.semantic.colorForeground,
            marginTop: '4px',
        },
        fontSizeMBold: {
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '20px',
        },
        contactSupport: { marginLeft: '4px' },
        box: {
            border: `1px solid ${theme.palette.semantic.colorBorderDisabled}`,
            borderRadius: '3px',
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '17px',
            paddingRight: '17px',
            marginBottom: '19px',
        },
        loading: {
            display: 'flex',
            justifyContent: 'center',
        },
        infoTooltipText: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundInverse,
        },
        discountInfoIcon: {
            fontSize: '16px',
            marginLeft: '4px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        tooltipBox: {
            maxWidth: '490px',
            padding: '8px 12px',
        },
        updatePaymentInfoLink: { cursor: 'pointer' },
        updateLicenseSpan: { display: 'inline-block' },
    }),
}));

const EcommerceLicenseInfo: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const currentAccountName = useSelector(accountLogicalName);
    const language = useLocalization();
    const logEcommerceEvent = useEcommerceTelemetry();
    const EnableEcommerceDecreaseLicenseFlow = useFeatureFlagValue(Features.EnableEcommerceDecreaseLicenseFlow.name);
    const EnableEcommercePaymentElementM2 = getFeatureFlagValue(Features.EnableEcommercePaymentElementM2.name);

    const {
        data: planDetails, isValidating: loading,
    } = useSWR<IPlanDetails, Error>(
        [ currentAccountName, `${billingUrl}/planDetails/licenseInfoSection` ],
        fetchPlanDetails,
    );

    const { data: ecommerceHistoryStatus } = useSWR<IHistoryStatus, Error>(
        EnableEcommercePaymentElementM2 ? [ currentAccountName, `${billingUrl}/historyStatus` ] : null,
        fetchHistoryStatus,
    );

    const [
        endDate,
        nextPayment,
        upcomingBillValue,
        currency,
        subscriptionId,
        billingEntityCode,
        discount,
        subscriptionScheduleId,
        hasLicenceUpdateSubscriptionSchedule,
        hasPriceChangesSubscriptionSchedule,
    ] =
        useMemo(() => [
            planDetails?.endDate,
            planDetails?.nextPayment,
            planDetails?.upcomingBill,
            planDetails?.currency,
            planDetails?.subscriptionId,
            planDetails?.billingEntityCode,
            planDetails?.discount,
            planDetails?.subscriptionScheduleId,
            planDetails?.hasLicenceUpdateSubscriptionSchedule,
            planDetails?.hasPriceChangesSubscriptionSchedule,
        ],
        [ planDetails ],
        );

    const {
        shouldDisplayUpdateLicenseButton,
        shouldEnableUpdateLicenseButton,
        shouldDisplayDiscountInfoTooltipIcon,
        shouldDisplayDiscount,
        updateLicenseButtonTooltipTextId,
        paymentInfoLinkText,
    } = useMemo(() =>
        getLicenseInfoUiState(
            subscriptionScheduleId, upcomingBillValue, discount, ecommerceHistoryStatus,
            EnableEcommerceDecreaseLicenseFlow, hasLicenceUpdateSubscriptionSchedule, hasPriceChangesSubscriptionSchedule
        ),
    [
        subscriptionScheduleId,
        upcomingBillValue,
        discount,
        ecommerceHistoryStatus,
        EnableEcommerceDecreaseLicenseFlow,
        hasLicenceUpdateSubscriptionSchedule,
        hasPriceChangesSubscriptionSchedule,
    ]);

    const getUpcomingBillValue = useCallback((upcomingBillCurrency) => shouldDisplayDiscount ? (
        <EcommerceDiscountedPrice
            fullPrice={upcomingBillValue}
            discountedPrice={discount?.discountedPrice}
            discountDetails={discount}
            loading={false}
            currency={upcomingBillCurrency}
            shouldDisplaySavesPercentageOffText={false}
            dataCy="ecommerce-upcoming-bill-full-price"
        />
    ) : (
        <EcommercePrice
            value={upcomingBillValue}
            loading={false}
            gap
            currency={upcomingBillCurrency}
            dataCy="ecommerce-upcoming-bill-price"
        />
    ), [ discount, upcomingBillValue, shouldDisplayDiscount ]);

    const getUpcomingBillSection = useCallback(() => upcomingBillValue ? (
        <div className={clsx(classes.row, classes.colorForeground)}>
            {currency && getUpcomingBillValue(currency)}
            <Typography className={clsx(classes.fontSizeM)}>
                {translate({ id: 'CLIENT_TAX' })}
            </Typography>
            {shouldDisplayDiscountInfoTooltipIcon && (
                <Tooltip
                    classes={{ tooltip: classes.tooltipBox }}
                    title={
                        <Typography
                            data-cy="discount-info-tooltip"
                            className={classes.infoTooltipText}>
                            <FormattedMessage
                                id="CLIENT_ECOMMERCE_DISCOUNT_TOOLTIP_TEXT"
                                values={{
                                    percentageOff: discount?.percentageOff,
                                    discountEndDate: moment(discount?.end).format('DD MMMM, YYYY'),
                                }}
                            />
                        </Typography>
                    }
                    role="tooltip"
                >
                    <InfoOutlined
                        className={classes.discountInfoIcon}
                        data-cy="discount-info-icon"
                        tabIndex={0} />
                </Tooltip>
            )}
        </div>
    ) : (
        <Typography className={clsx(classes.fontSizeM, classes.colorForeground)}>
            {translate({ id: 'CLIENT_NONE' })}
        </Typography>
    ), [ classes, currency, discount, shouldDisplayDiscountInfoTooltipIcon, getUpcomingBillValue, translate, upcomingBillValue ]);

    const [ licensePendingChangeInfoTooltipOpen, setLicensePendingChangeInfoTooltipOpen ] = React.useState(false);

    const handleLicensePendingChangeInfoTooltipClose = useCallback(() => {
        setLicensePendingChangeInfoTooltipOpen(false);
    }, [ setLicensePendingChangeInfoTooltipOpen ]);

    const handleLicensePendingChangeInfoTooltipOpen = useCallback(() => {
        setLicensePendingChangeInfoTooltipOpen(true);
    }, [ setLicensePendingChangeInfoTooltipOpen ]);

    useEffect(() => {
        if (planDetails?.planType) {
            storePlanType(planDetails?.planType);
        }
    },
    [ planDetails ]);
    return loading ? (
        <div className={classes.box}>
            <div className={classes.loading}>
                <CircularProgress />
            </div>
        </div>
    ) : (planDetails ? (
        <div className={classes.box}>
            <div className={classes.infoSection}>
                <div>
                    <Typography
                        className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_PLAN_TYPE' })}
                    </Typography>
                    <Typography
                        className={clsx(classes.fontSizeM, classes.colorForeground)}
                        data-cy="ecommerce-plan-type">
                        {translate({ id: `CLIENT_${planDetails?.planType}_PAYMENT` })}
                    </Typography>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_NEXT_PAYMENT_DATE' })}
                    </Typography>
                    <div
                        className={classes.fontSizeM}
                        data-cy="ecommerce-next-payment-date">
                        {nextPayment ? (
                            <Typography className={clsx(classes.fontSizeM, classes.colorForeground)}>
                                {formatDate(endDate, language)}
                            </Typography>
                        ) : (
                            <Typography
                                className={clsx(classes.fontSizeM, classes.colorForeground)}
                                data-cy="plan-end-message">
                                {translate(
                                    { id: 'CLIENT_PLAN_END_MESSAGE' },
                                    { 0: formatDate(endDate, language) },
                                )}
                            </Typography>
                        )}
                    </div>
                </div>
                <div className={classes.column}>
                    <Typography className={classes.fontSizeMBold}>
                        {translate({ id: 'CLIENT_HEADER_UPCOMING_BILL' })}
                    </Typography>
                    <div
                        className={classes.fontSizeM}
                        data-cy="ecommerce-upcoming-bill">
                        {getUpcomingBillSection()}
                    </div>
                </div>
                {shouldDisplayUpdateLicenseButton && (
                    <div className={classes.alignRight}>
                        {billingEntityCode && billingEntityCode !== BillingEntityCode.JP && (
                            <Tooltip
                                classes={{ tooltip: classes.tooltipBox }}
                                open={licensePendingChangeInfoTooltipOpen && !shouldEnableUpdateLicenseButton}
                                onClose={handleLicensePendingChangeInfoTooltipClose}
                                onOpen={handleLicensePendingChangeInfoTooltipOpen}
                                title={
                                    <Typography
                                        data-cy="license-pending-change-tooltip"
                                        className={classes.infoTooltipText}>
                                        {translate({ id: updateLicenseButtonTooltipTextId })}
                                    </Typography>
                                }
                            >
                                <span className={classes.updateLicenseSpan}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            logEcommerceEvent('Licenses.UpdateLicenseQuantity', { Flow: INCREMENTAL_FLOW });
                                            history.push(getRoute(RouteNames.UpdateLicenseQuantity), { subscriptionId });
                                        }}
                                        id="updateLicenseQuantity"
                                        data-cy="ecommerce-license-update-license-quantity"
                                        disabled={!shouldEnableUpdateLicenseButton}
                                    >
                                        {translate({ id: 'CLIENT_UPDATE_LICENSE_QUANTITY' })}
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
            <div className={classes.supportSection} >
                <Typography className={classes.fontSizeM}>
                    {translate({ id: 'CLIENT_HAVE_ANY_QUESTIONS' })}
                </Typography>
                <Link
                    className={clsx(classes.contactSupport, classes.fontSizeMBold)}
                    href="mailto:cloud.billing@uipath.com"
                    data-cy="ecommerce-questions"
                    onClick={() => {
                        logEcommerceEvent('ManagePlan.ContactSupport');
                    }}
                >
                    {translate({ id: 'CLIENT_CONTACT_SUPPORT' })}
                </Link>
                <Link
                    className={clsx(classes.alignRight, classes.fontSizeMBold, classes.updatePaymentInfoLink)}
                    onClick={() => {
                        goToStripeCustomerPortalSession(currentAccountName);
                    }}
                    onKeyDown={(e: KeyboardEvent<HTMLAnchorElement> | KeyboardEvent<HTMLSpanElement>) => {
                        if (e.key === 'Enter') {
                            goToStripeCustomerPortalSession(currentAccountName);
                        }
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-cy="ecommerce-update-payment-info"
                    role='link'
                    tabIndex={0}
                >
                    {translate({ id: paymentInfoLinkText })}
                </Link>
            </div>
        </div>) : (<div />)
    );
};

export default EcommerceLicenseInfo;
