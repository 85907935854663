import type { IPlanDetails } from '@experiences/ecommerce';
import {
    getPriceString,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import { useLocalization } from '@experiences/locales';
import { GlobalStyles } from '@experiences/theme';
import { useRouteResolver } from '@experiences/util';
import {
    Breadcrumbs,
    Button,
    Card,
    CircularProgress,
    Divider,
    Link,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import {
    fetchPlanDetails,
    goToStripeCustomerPortalSession,
} from '../../services/licensing/BillingService';
import { accountLogicalName } from '../../store/selectors';
import { formatDate } from '../../util/DateUtil';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        managePlanContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        breadcrumbText: {
            fontSize: '18px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            fontWeight: 600,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        title: {
            color: theme.palette.semantic.colorForeground,
            display: 'flex',
            alignItems: 'center',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 'bold',
            marginTop: '24px',
            marginBottom: '12px',
        },
        subTitle: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '10px',
            display: 'flex',
            fontWeight: 600,
            fontSize: '14px',
        },
        detailContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        detailColumn: {
            display: 'flex',
            minWidth: '121px',
            lineHeight: '20px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginRight: '50px',
        },
        details: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '10px',
            fontWeight: 'normal',
            fontSize: '14px',
        },
        hyperLinkText: {
            fontWeight: 600,
            color: theme.palette.semantic.colorPrimary,
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        link: { fontWeight: 600 },
        renewalSection: { marginTop: '25px' },
        planDetailSection: { marginTop: '5px' },
        updatePaymentButton: {
            marginTop: '10px',
            marginBottom: '10px',
            padding: '0px, 16px',
            width: '137px',
            fontSize: '14px',
        },
        titleContainer: {
            flexGrow: 1,
            marginTop: '10px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
            marginTop: '8px',
        },
        loading: {
            position: 'absolute',
            top: '50%',
            left: 'calc(50% + 100px)',
        },
        divider: {
            margin: '12px 0px 24px 0px',
            backgroundColor: theme.palette.semantic.colorBorderDeEmp,
        },
        salesCards: { display: 'flex' },
        card: {
            padding: '16px',
            paddingRight: '24px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxShadow: 'none',
            borderRadius: '3px',
            borderWidth: '1px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            marginRight: '16px',
        },
        cardTitle: {
            fontWeight: 600,
            lineHeight: '20px',
            marginBottom: '11px',
            color: theme.palette.semantic.colorForeground,
        },
        salesLink: { fontSize: '14px' },
    }),
}));

const EcommerceManagePlanComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const history = useHistory();

    const logEcommerceEvent = useEcommerceTelemetry();

    const language = useLocalization();
    const currentAccountName = useSelector(accountLogicalName);
    const {
        data: planDetails, isValidating: loading,
    } = useSWR<IPlanDetails, Error>(
        [ currentAccountName ],
        fetchPlanDetails,
    );
    const [ endDate, nextPayment, upcomingBillValue, currency ] = useMemo(
        () => [
            planDetails?.endDate,
            planDetails?.nextPayment,
            planDetails?.upcomingBill,
            planDetails?.currency,
        ],
        [ planDetails ],
    );

    return (
        <UiPanel
            licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
            licenseExpiredBanner={<UiLicenseExpiredBanner />}
            trialPerSkuLicenseInGracePeriodBanners={<UiTrialPerSkuLicenseInGracePeriodBanners />}
            licenseOverAllocationBanner={<UiOverAllocationBanner />}
            header={{
                title: (
                    <Breadcrumbs separator="/">
                        <span
                            className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                            onClick={() => history.push(getRoute(RouteNames.Licensing))}
                        >
                            {translate({ id: 'CLIENT_LICENSES' })}
                        </span>
                        <span
                            className={classes.breadcrumbText}
                            role="heading"
                            aria-level={1}>
                            {translate({ id: 'CLIENT_MANAGE_PLAN' })}
                        </span>
                    </Breadcrumbs>
                ),
                disableGutter: true,
            }}
        >
            {loading ? (
                <div className={clsx(classes.loading)}>
                    <CircularProgress />
                </div>
            ) : (
                <div className={classes.managePlanContainer}>
                    <div className={classes.planDetailSection}>
                        <Typography className={classes.title}>
                            {translate({ id: 'CLIENT_PLAN_DETAIL' })}
                        </Typography>
                        <div
                            className={classes.detailContainer}
                            data-cy="plandetail-container">
                            <div className={classes.detailColumn}>
                                <Typography
                                    data-cy="client-plan-text"
                                    className={classes.subTitle}>
                                    {translate({ id: 'CLIENT_PLAN' })}
                                </Typography>
                                <Typography
                                    data-cy="client-license-redirect-text"
                                    className={classes.subTitle}>
                                    {translate({ id: 'CLIENT_LICENSES_INCLUDED' })}
                                </Typography>
                                {planDetails && (
                                    <>
                                        <Typography
                                            data-cy="client-plan-type-text"
                                            className={classes.subTitle}>
                                            {translate({ id: 'CLIENT_PLAN_TYPE' })}
                                        </Typography>
                                    </>
                                )}
                            </div>
                            <div className={classes.detailColumn}>
                                <Typography
                                    data-cy="client-plan-value"
                                    className={classes.details}>
                                    {!planDetails
                                        ? translate({ id: 'CLIENT_CUSTOM_PLAN' })
                                        : translate({ id: 'CLIENT_SKU_PACKAGE_PACK_CUSTOM' })}
                                </Typography>
                                <Link
                                    className={clsx(classes.details, classes.hyperLinkText)}
                                    data-cy="client-license-redirect-value"
                                    onClick={() => history.push(getRoute(RouteNames.Licensing))}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {translate({ id: 'CLIENT_VIEW_LICENSES_PAGE' })}
                                </Link>
                                {planDetails && (
                                    <>
                                        <Typography
                                            data-cy="client-plan-type-value"
                                            className={classes.details}>
                                            {translate({ id: 'CLIENT_MONTHLY_PLAN' })}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>
                        {!planDetails && (
                            <Typography
                                data-cy="client-custom-plan-message"
                                className={classes.details}>
                                {translate({ id: 'CLIENT_CUSTOM_PLAN_MESSAGE' })}
                            </Typography>
                        )}
                    </div>

                    {planDetails && (
                        <>
                            <Button
                                className={classes.updatePaymentButton}
                                variant="outlined"
                                onClick={() => {
                                    goToStripeCustomerPortalSession(currentAccountName);
                                }}
                                data-cy="update-payment-info-button"
                            >
                                {translate({ id: 'CLIENT_MANAGE_BILLING_BUTTON' })}
                            </Button>
                            <Typography className={classes.details}>
                                {translate({ id: 'CLIENT_BUTTON_HINT' })}
                            </Typography>

                            <div
                                className={classes.renewalSection}
                                data-cy="renewal-section">
                                <Typography className={classes.title}>
                                    {translate({ id: 'CLIENT_PLAN_RENEWAL' })}
                                </Typography>
                                {nextPayment ? (
                                    <>
                                        <Typography className={classes.details}>
                                            {translate(
                                                { id: 'CLIENT_PLAN_RENEWAL_MESSAGE' },
                                                { 0: formatDate(endDate, language) },
                                            )}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography
                                        className={classes.details}
                                        data-cy="plan-end-message">
                                        {translate(
                                            { id: 'CLIENT_PLAN_END_MESSAGE' },
                                            { 0: formatDate(endDate, language) },
                                        )}
                                    </Typography>
                                )}
                                <div
                                    className={classes.detailContainer}
                                    data-cy="plandetail-container">
                                    <div className={classes.detailColumn}>
                                        <Typography
                                            data-cy="client-upcoming-bill-text"
                                            className={classes.subTitle}>
                                            {translate({ id: 'CLIENT_UPCOMING_BILL' })}
                                        </Typography>
                                    </div>
                                    <div className={classes.detailColumn}>
                                        {upcomingBillValue ? (
                                            <Typography
                                                data-cy="client-upcoming-bill-value"
                                                className={classes.details}>
                                                {currency && getPriceString(upcomingBillValue, currency, language)}
                                                {translate({ id: 'CLIENT_TAX' })}
                                            </Typography>
                                        ) : (
                                            <Typography
                                                data-cy="client-none-upcoming-bill"
                                                className={classes.details}>
                                                {translate({ id: 'CLIENT_NONE' })}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <Divider className={classes.divider} />

                    <div className={classes.salesCards}>
                        <Card
                            variant="outlined"
                            className={classes.card}
                            data-cy="manageplan-support-section">
                            <Typography className={classes.cardTitle}>
                                {translate({ id: 'CLIENT_SUPPORT' })}
                            </Typography>
                            <Typography className={classes.details}>
                                {translate({ id: 'CLIENT_BILLING_QUESTIONS' })}
                            </Typography>
                            <Typography className={classes.link}>
                                <FormattedMessage
                                    id="CLIENT_CONTACT_UI_PATH_AT"
                                    values={{
                                        a: (chunk: string) => (
                                            <a
                                                className={classes.a}
                                                onClick={() => {
                                                    logEcommerceEvent('ManagePlan.ContactSupport');
                                                }}
                                                href="mailto:cloud.billing@uipath.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {chunk}
                                            </a>
                                        ),
                                    }}
                                />
                            </Typography>
                        </Card>
                        <Card
                            variant="outlined"
                            className={classes.card}
                            data-cy="manageplan-services-section">
                            <Typography className={classes.cardTitle}>
                                {translate({ id: 'CLIENT_NEED_MORE_SERVICES' })}
                            </Typography>
                            <Typography className={classes.details}>
                                {translate({ id: 'CLIENT_GET_FLEXIBLE_OFFERINGS' })}
                            </Typography>
                            <Link
                                className={classes.salesLink}
                                onClick={() => {
                                    logEcommerceEvent('ManagePlan.ContactSales');
                                }}
                                href={
                                    language === 'en'
                                        ? 'https://www.uipath.com/company/contact-us'
                                        : `https://www.uipath.com/${language}/company/contact-us`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translate({ id: 'CLIENT_CONTACT_SALES' })}
                            </Link>
                        </Card>
                    </div>
                </div>
            )}
        </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <EcommerceManagePlanComponent />
        {renderRoutes(route.routes)}
    </>
);
