import {
    ApolloThemeProvider,
    GlobalStyles,
} from '@experiences/theme';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import clsx from 'clsx';
import intersection from 'lodash/intersection';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import type { IGroup } from '../../common/interfaces/cis/group';
import type { IGroupAllocations } from '../../common/interfaces/licenses';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import {
    deleteGroups,
    getGroups,
    getGroupsWithLicenses,
} from '../../services/identity/GroupService';
import {
    accountGlobalId,
    EnableUserLicensingSelector,
    groupIdsForUser,
    isAdminSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import { ActionIconSize } from '../common/UiGrid/constants';
import { UserGroup } from '../common/UserGroups';

const groupUrl = `${process.buildConfigs.identityApiBaseRoute}/Group`;

type IGroupWithLicenses = IGroup & Partial<IGroupAllocations>;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        actionIconButton: {
            width: ActionIconSize + 'px',
            height: ActionIconSize + 'px',
            padding: '8px',
        },
        licenseAllocationRuleContainer: { display: 'flex' },
        licenseAllocationRule: {
            maxWidth: '500px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        '@media all and (max-width: 1001px)': { licenseAllocationRule: { maxWidth: '100px' } },
        '@media all and (max-width: 1201px)': { licenseAllocationRule: { maxWidth: '200px' } },
    }),
}));

const GroupsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const currentGroupIds = useSelector(groupIdsForUser);
    const isLicenseExpired = useLicenseExpired();

    const createDialog = useShowDialog();

    const dataUrl = useMemo(() => {
        const baseUrl = EnableUserLicensing && isAdmin ? `${groupUrl}/licenses` : `${groupUrl}`;
        return `${baseUrl}?partitionGlobalId=${partitionGlobalId}`;
    }, [ EnableUserLicensing, isAdmin, partitionGlobalId ]);

    const {
        data: groups, isValidating: loading, error,
    } = useSWR<IGroupWithLicenses[], Error>(
        [ dataUrl, partitionGlobalId ],
        EnableUserLicensing && isAdmin ? getGroupsWithLicenses : getGroups,
    );

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const openDeleteDialog = useCallback(
        async (rows: IGroupWithLicenses[] | IGroupWithLicenses) => {
            const grps = Array.isArray(rows) ? rows.map(row => row) : [ rows ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_GROUP' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_GROUPS' },
                    {
                        0: grps.length === 1
                            ? decodeSanitizedHtml(grps[0].name)
                            : translate({ id: 'CLIENT_SELECTED_GROUPS_TEXT' }),
                    },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_GROUPS_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const groupIDs = grps.map(group => group.id);
                const includesCurrentUser = intersection(currentGroupIds, groupIDs).length > 0;
                await deleteGroups(partitionGlobalId, { groupIDs }, includesCurrentUser);
                if (grps.length === 1) {
                    createNotification(translate({ id: 'CLIENT_GROUP_DELETED' }, { 0: decodeSanitizedHtml(grps[0].name) }));
                } else {
                    createNotification(translate({ id: 'CLIENT_GROUPS_DELETED' }));
                }
                mutate([ dataUrl, partitionGlobalId ]);
            }
        },
        [ createDialog, createNotification, partitionGlobalId, translate, dataUrl, currentGroupIds ],
    );

    return (
        <ApDataGridWrapper<IGroupWithLicenses>
            data={groups ?? []}
            unpaginated
            initialFilters={{
                sort: [
                    {
                        field: 'type',
                        direction: 'asc',
                        title: translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' }),
                    },
                ],
            }}
            loading={loading}
            customTemplateProviders={[ { provider: ApolloThemeProvider } ]}
            refreshable
            disableSelectionByEntry={(entry: IGroupWithLicenses) => entry?.type === 0 ? translate({ id: 'CLIENT_DISABLED' }) : null}
            refresh={() => mutate([ dataUrl, partitionGlobalId ])}
            dataCy="ap-data-grid"
        >
            <ApDataGridHeader<IGroupWithLicenses> search>
                {isAdmin && (
                    [
                        <ApDataGridHeaderButton<IGroupWithLicenses>
                            id='addGroups'
                            key='addGroups'
                            type='main'
                            buttonType='mat-flat-button'
                            color='primary'
                            text={translate({ id: 'CLIENT_GROUPS_ADD_GROUP' })}
                            label={translate({ id: 'CLIENT_GROUPS_ADD_GROUP' })}
                            icon='add'
                            onClick={() => history.push(`${getRoute(RouteNames.Groups)}/add`)}
                            dataCy='ui-grid-add-groups-button'
                        />,
                        <ApDataGridHeaderButton<IGroupWithLicenses>
                            id='deleteGroups'
                            key='deleteGroups'
                            type='action'
                            icon='delete'
                            color='warn'
                            label={translate({ id: 'CLIENT_DELETE' })}
                            onClick={(rows) => openDeleteDialog(rows ?? [])}
                            dataCy='ui-grid-add-groups-button'
                        />,
                    ]
                )}
            </ApDataGridHeader>

            <ApDataGridColumn<IGroupWithLicenses>
                property="name"
                resizable={false}
                title={translate({ id: 'CLIENT_DISPLAY_NAME' })}
                width={EnableUserLicensing && isAdmin ? '50%' : '100%'}
                sortable
                searchable
            />

            <ApDataGridColumn<IGroupWithLicenses>
                property="type"
                sort="asc"
                title={translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' })}
                sortable
                visible={false}
            />

            { EnableUserLicensing && isAdmin && !isUnlicensedMode && (
                <ApDataGridColumn<IGroupWithLicenses>
                    property='userBundleLicenses'
                    title={translate({ id: 'CLIENT_LICENSES_ALLOCATION_RULES' })}
                    resizable={false}
                    width="50%"
                    render={row => {
                        const licenseList = row.useExternalLicense
                            ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
                            : row.userBundleLicenses?.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ');

                        return (
                            <Tooltip title={licenseList ?? ''}>
                                <div className={clsx(classes.licenseAllocationRuleContainer)}>
                                    <span
                                        className={clsx(classes.licenseAllocationRule)}
                                        data-cy="license-allocation-rule">
                                        {licenseList ?? translate({ id: 'CLIENT_NO_ALLOCATION_RULE' })}
                                    </span>
                                </div>
                            </Tooltip>
                        );

                    }}
                />
            )}

            {isAdmin && (
                <ApDataGridRowActions>
                    { EnableUserLicensing && !isUnlicensedMode && (
                        <ApDataGridRowButton<IGroupWithLicenses>
                            id='allocateLicense'
                            label={translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' })}
                            dataCy='ui-grid-edit-group-allocations-button'
                            render={row => (
                                <Tooltip title={translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' })}>
                                    <IconButton
                                        className={clsx(classes.actionIconButton, classes.icon, classes.svgIcon)}
                                        disabled={isLicenseExpired}
                                        onClick={() => history.push({
                                            pathname: `${getRoute(RouteNames.Groups)}/allocations/edit`,
                                            state: {
                                                group: row,
                                                previousLocation: location.pathname,
                                            },
                                        })}
                                        data-cy='ui-grid-edit-group-allocations-button'
                                        aria-label='row action'
                                    >
                                        <AllocateLicenseIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            disabled={() => isLicenseExpired} />
                    )}

                    <ApDataGridRowButton<IGroupWithLicenses>
                        id='edit'
                        label={translate({ id: 'CLIENT_EDIT' })}
                        icon='edit'
                        onClick={(row) => history.push(`${getRoute(RouteNames.Groups)}/edit/${row.id}`)}
                        disabled={(row) => row?.id === UserGroup.Everyone
                            ? translate({ id: 'CLIENT_DISABLED' })
                            : false}
                    />

                    <ApDataGridRowButton<IGroupWithLicenses>
                        id='delete'
                        label={translate({ id: 'CLIENT_DELETE' })}
                        icon='delete'
                        onClick={openDeleteDialog}
                        disabled={(row) => row?.type === 0
                            ? translate({ id: 'CLIENT_DISABLED' })
                            : false}
                    />
                </ApDataGridRowActions>
            )}

            <ApDataGridFooter
                pageSizes={[ 5, 10, 25, 50 ]}
            />

        </ApDataGridWrapper>
    );
};

export default GroupsPageComponent;
