import React, { useMemo } from 'react';
import type { RouteConfig } from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import AuditDialogComponent from '../../component/audit/AuditDialogComponent';
import AuditPageComponent from '../../component/audit/AuditPageComponent';
import ConfigureAADComponent from '../../component/authsettings/subcomponents/ConfigureAADComponent';
import ConfigureADComponent from '../../component/authsettings/subcomponents/ConfigureADComponent';
import ConfigureGoogleComponent from '../../component/authsettings/subcomponents/ConfigureGoogleComponent';
import ConfigureSamlComponent from '../../component/authsettings/subcomponents/ConfigureSamlComponent';
import EditPasswordPolicyComponent from '../../component/authsettings/subcomponents/EditPasswordPolicyComponent';
import SecuritySettingsADForm from '../../component/authsettings/subcomponents/SecuritySettingsADForm';
import EcommerceMonthlyUpsellComponent from '../../component/ecommerce/EcommerceMonthlyUpsellComponent';
import Licenses from '../../component/licensing/Licenses';
import ActivateLicenseOfflineComponent from '../../component/licensing/subcomponents/ActivateLicenseOfflineComponent';
import ActivateLicenseOnlineComponent from '../../component/licensing/subcomponents/ActivateLicenseOnlineComponent';
import AddEditOrganizationLicenseComponent from '../../component/licensing/subcomponents/AddEditOrganizationLicenseComponent';
import DeactivateLicenseOfflineComponent from '../../component/licensing/subcomponents/DeactivateLicenseOfflineComponent';
import HostOrganizationAdd from '../../component/organization/HostOrganization/OrganizationAdd';
import HostOrganizationLicense from '../../component/organization/HostOrganization/OrganizationLicense';
import HostOrganizationSettings from '../../component/organization/HostOrganization/OrganizationSettings';
import OrganizationPageComponent from '../../component/organization/OrganizationPageComponent';
import AddOrganizationComponent from '../../component/organization/subcomponents/AddOrganizationComponent';
import OrganizationEmailSettingsRevampComponent from '../../component/organizationsettings/email/OrganizationEmailSettingsRevampComponent';
import OrganizationAdminHomeComponent from '../../component/organizationsettings/OrganizationAdminHome';
import OrganizationHome from '../../component/organizationsettings/OrganizationHome.onprem';
import OrganizationSettingsPageComponent from '../../component/organizationsettings/OrganizationSettingsPageComponent';
import VerifyEmailSettingsComponent from '../../component/organizationsettings/subcomponents/VerifyEmailSettingsComponent';
import SecuritySettings from '../../component/securitySettings/SecuritySettings';
import AddEditUserComponent from '../../component/users/subcomponents/AddEditUserComponent';
import UsersPageComponent from '../../component/users/UsersPageComponent';
import CheckGuard from '../helpers/CheckGuard';

const useHostPrivateShellRoutes = () => {

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const { isMonthlyUpsellApplicable } = useCheckLicense();

    return useMemo<RouteConfig[]>(() => [
        {
            path: RouteNames.Organizations,
            component: OrganizationPageComponent,
            routes: [
                {
                    path: RouteNames.OrganizationsAdd,
                    component: AddOrganizationComponent,
                },
                {
                    path: RouteNames.OrganizationsLicense,
                    component: AddEditOrganizationLicenseComponent,
                },
            ],
        },
        {
            path: RouteNames.OrganizationAdminHome,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <OrganizationAdminHomeComponent />),
        },
        {
            path: RouteNames.HostOrganizationSettings,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <HostOrganizationSettings />),
        },
        {
            path: RouteNames.HostOrganizationLicense,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <HostOrganizationLicense />),
        },
        {
            path: RouteNames.HostOrganizationAdd,
            render: () =>
                CheckGuard(isAdminRevampEnabled, <HostOrganizationAdd />),
        },
        {
            path: RouteNames.OrganizationHome,
            render: props => CheckGuard(isAdminRevampEnabled, <OrganizationHome {...props} />),
        },
        {
            path: RouteNames.Users,
            component: UsersPageComponent,
            routes: [
                {
                    path: RouteNames.UserAddEdit,
                    component: AddEditUserComponent,
                },
            ],
        },
        {
            path: RouteNames.AuthSettingsConfigureAD,
            component: ConfigureADComponent,
        },
        {
            path: RouteNames.AuthSettingsConfigureAAD,
            component: ConfigureAADComponent,
        },
        {
            path: RouteNames.AuthSettingsConfigureGoogle,
            component: ConfigureGoogleComponent,
        },
        {
            path: RouteNames.AuthSettingsConfigureSaml,
            component: ConfigureSamlComponent,
        },
        {
            path: RouteNames.AuthSettingsConfigureADDirectory,
            component: SecuritySettingsADForm,
        },
        {
            path: RouteNames.Licensing,
            component: Licenses,
            routes: [
                {
                    path: RouteNames.ActivateOffline,
                    component: ActivateLicenseOfflineComponent,
                },
                {
                    path: RouteNames.LicensingActivateLicenseOnline,
                    component: isMonthlyUpsellApplicable ? EcommerceMonthlyUpsellComponent : ActivateLicenseOnlineComponent,
                },
                {
                    path: RouteNames.DeactivateOffline,
                    component: DeactivateLicenseOfflineComponent,
                },
            ],
        },
        {
            path: RouteNames.EmailSettings,
            component: OrganizationEmailSettingsRevampComponent,
            routes: [
                {
                    path: RouteNames.EmailSettingsTest,
                    component: VerifyEmailSettingsComponent,
                },
            ],
        },
        {
            path: RouteNames.LoginCustomization,
            component: OrganizationSettingsPageComponent,
        },
        {
            path: RouteNames.AuditLogs,
            component: AuditPageComponent,
            routes: [
                {
                    path: RouteNames.AuditLogDialog,
                    component: AuditDialogComponent,
                },
            ],
        },
        {
            path: RouteNames.SessionPolicy,
            render: props => CheckGuard(isAdminRevampEnabled, <SecuritySettings {...props} />),
        },
        {
            path: RouteNames.SecuritySettings,
            render: props => CheckGuard(isAdminRevampEnabled, <SecuritySettings {...props} />),
            routes: [
                {
                    path: RouteNames.AuthSettingsPasswordPolicy,
                    component: EditPasswordPolicyComponent,
                },
            ],
        },
    ], [ isAdminRevampEnabled, isMonthlyUpsellApplicable ]);
};

export default useHostPrivateShellRoutes;
