import { UiStorage } from '@experiences/util';
import type { Dispatch } from 'react';
import { createAction } from 'redux-act';

export const ToggleAdminRevamp = createAction('ToggleAdminRevamp');

const saveAdminToggleToLocalStorage = (newValue: boolean) => {
    UiStorage.setItem('adminRevamp', newValue.toString());
};

export const getAdminToggleFromLocalStorage = () => UiStorage.getItem('adminRevamp') === 'true';

export const toggleAdminRevamp = () => (dispatch: Dispatch<any>, getState: any) => {
    const curVal = getState().adminRevamp.adminRevampToggle;
    saveAdminToggleToLocalStorage(!curVal);
    dispatch(ToggleAdminRevamp());
};
