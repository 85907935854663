import type {
    IUserLicenseAllocation,
    IUserLicenseAllocationRequest,
} from '../../component/users/interfaces/userLicense';
import {
    get,
    put,
} from '../utility/Requests.default';

export const userLicenseUri = '/api/userLicense';

export async function getUserLicenseAllocation(accountGlobalId: string) {
    return await get<IUserLicenseAllocation[]>(`${userLicenseUri}/api/account/${accountGlobalId}/user-license`);
}

export async function allocateUserLicenses(accountGlobalId: string, requestBody: IUserLicenseAllocationRequest) {
    return await put<any>(`${userLicenseUri}/api/account/${accountGlobalId}/user-license`, { body: requestBody });
}

export function updateUserLicenseManagementStatus(enabled: boolean) {
    return put(`${userLicenseUri}/UserLicense/status`, { body: { enabled } });
}

export function getUserLicenseManagementStatus() {
    return get<{ enabled: boolean }>(`${userLicenseUri}/UserLicense/status`);
}
