import {
    Features,
    getFeatureFlagConfig,
} from '@experiences/feature-flags';
import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import urljoin from 'url-join';

type JSONObject = Record<string, string>;

export function navigateToExternalServiceManageUsers(
    serviceType: string,
    tenantName: string,
    accountLogicalName: string,
) {
    window.location.assign(returnExternalServiceManageUsersUrl(serviceType, tenantName, accountLogicalName));
}

export function returnExternalServiceManageUsersUrl(
    serviceType: string,
    tenantName: string,
    accountLogicalName: string,
) {
    const configFromJson = getFeatureFlagConfig<JSONObject>(Features.ManageUsersMapping.name);
    try {
        const parsedMapping: Record<string, string> = typeof configFromJson === 'string' ? JSON.parse(configFromJson) : configFromJson;
        const manageUsersMapping = parsedMapping[serviceType];
        if (manageUsersMapping) {
            return urljoin(window.location.origin, accountLogicalName, tenantName, manageUsersMapping);
        }
        return urljoin(window.location.origin, accountLogicalName, tenantName, serviceType + '_', 'users');
    } catch (e) {
        portalTelemetry.trackTrace({
            message: `Error while parsing ManageUserConfig mapping: ${(e as Error).message}`,
            severityLevel: SeverityLevel.Error,
        });
        return '';
    }
}

export function isUserManagementEnabled(serviceType?: string) {
    if (!serviceType) {
        return false;
    }

    const configFromJson = getFeatureFlagConfig<JSONObject>(Features.ManageUsersMapping.name);
    try {
        if (configFromJson) {
            const parsedMapping: Record<string, string> = typeof configFromJson === 'string' ? JSON.parse(configFromJson) : configFromJson;
            // eslint-disable-next-line no-prototype-builtins
            return !!parsedMapping.hasOwnProperty(serviceType);
        }
    } catch (e) {
        portalTelemetry.trackTrace({
            message: `Error while parsing ManageUserConfig mapping: ${(e as Error).message}`,
            severityLevel: SeverityLevel.Error,
        });
    }

    // Return this as a fallback default if parsing JSON fails
    return (
        serviceType === 'orchestrator'
            || serviceType === 'actions'
            || serviceType === 'processes'
            || serviceType === 'aifabric'
            || serviceType === 'dataservice'
            || serviceType === 'automationhub'
            || serviceType === 'automationstore'
            || serviceType === 'taskmining'
            || serviceType === 'processmining'
            || serviceType === 'insights'
    );
}
