import styled from '@emotion/styled';
import {
    ColorBlueSecondary400,
    ColorOrange700,
} from '@uipath/apollo-core/lib/_generated/Colors';
import type {
    LegendRow,
    ScaleOptions,
} from '@uipath/apollo-lab';
import type { ChartLegendProperties } from '@uipath/apollo-lab/core/visualizations/charts/chart/models/ChartLegendProperties';
import { Chart } from '@uipath/apollo-lab/react';
import React from 'react';
import { useIntl } from 'react-intl';

import { formatScaleX } from './formatScaleX';
import type { PointMetaData } from './PointMetaData';

const ChartWrapper = styled(Chart)`
  padding: 0 1px 1px;
  box-sizing: border-box;
` as typeof Chart;

interface DistributionChartContentProps {
    onSelect?: (newSelection: number[]) => void;
    data: number[];
    compareData?: number[];
    legendTitle?: string;
    compareLegendTitle?: string;
    metaData?: PointMetaData[];
    labels: string[];
    scaleY: ScaleOptions;
    legendProperties: ChartLegendProperties;
    dataCy?: string;
    translateIdTooltipPrimary?: string;
    translateIdTooltipSecondary?: string;
}

export const BarChartContent: React.FC<{
    dist: DistributionChartContentProps;
}> = ({ dist }) => {
    const { formatMessage: translate } = useIntl();

    const scaleX = formatScaleX();

    if (dist.compareData) {
        return <ChartWrapper
            alt={translate({ id: 'CLIENT_USAGE_CHART_ALT_TEXT' })}
            data={[
                {
                    data: dist.data,
                    renderer: 'bar',
                    color: ColorBlueSecondary400,
                    title: dist.legendTitle,
                },
                {
                    data: dist.compareData,
                    isCompareData: true,
                    renderer: 'bar',
                    color: ColorOrange700,
                    title: dist.compareLegendTitle,
                },
            ]}
            labels={dist.labels}
            scaleX={scaleX}
            scaleY={dist.scaleY}
            tooltipSchemaBuilder={(values) => {
                const dataValues: LegendRow[] = [
                    {
                        cells: [
                            {
                                text: `${translate({ id: dist.translateIdTooltipPrimary })} ${values[0].value.y}`,
                                role: 'primary',
                                color: ColorBlueSecondary400,
                            },
                            {
                                text: `${translate({ id: dist.translateIdTooltipSecondary })} ${values[1].value.y}`,
                                role: 'secondary',
                                color: ColorOrange700,
                            },
                        ],
                    },
                ];
                return dataValues;
            }}
            legendProperties={dist.legendProperties}
        />;
    }

    return <ChartWrapper
        alt={translate({ id: 'CLIENT_USAGE_CHART_ALT_TEXT' })}
        data={[
            {
                data: dist.data,
                renderer: 'bar',
                color: ColorBlueSecondary400,
                title: dist.legendTitle,
            },
        ]}
        labels={dist.labels}
        scaleX={scaleX}
        scaleY={dist.scaleY}
        tooltipSchemaBuilder={(values) => {
            const dataValues: LegendRow[] = [
                {
                    cells: [
                        {
                            text: `${translate({ id: dist.translateIdTooltipPrimary })} ${values[0].value.y}`,
                            role: 'primary',
                            color: ColorBlueSecondary400,
                        },
                    ],
                },
            ];
            return dataValues;
        }}
        legendProperties={dist.legendProperties}
    />;
};
