import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import type { IServiceMetadata } from '../../component/tenants/interfaces/service';
import { accountType } from '../../store/selectors';

export const useTenantFormHelpers = () => {

    const EnableInsightsTrial = useFeatureFlagValue(Features.EnableInsightsTrial.name);
    const licenseAccountType = useSelector(accountType);

    const isInsightsTrial = useCallback(
        (service: IServiceMetadata) => service.id === 'insights' && AccountLicense[licenseAccountType] === AccountLicense.ENTERPRISE && EnableInsightsTrial,
        [ EnableInsightsTrial, licenseAccountType ],
    );

    const getServiceName = useCallback(
        (service: IServiceMetadata) => {
            if (isInsightsTrial(service)) {
                return 'Insights Trial';
            } else if (service.name === 'ProcessMining') {
                return 'Process Mining';
            } else if (service.name === 'Reinfer') {
                return 'Communications Mining';
            }

            return service.name;
        },
        [ isInsightsTrial ],
    );

    return {
        isInsightsTrial,
        getServiceName,
    };
};
