import type { ISAMLRule } from '../../common/interfaces/cis/saml';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';

export const ruleUrl = `${process.buildConfigs.identityApiBaseRoute}/Rule`;

export function getRules(url: string, partitionGlobalId: string) {
    return get<ISAMLRule[]>(`${ruleUrl}/${partitionGlobalId}`);
}

export function getRuleById(url: string, partitionGlobalId: string, ruleId: number) {
    return get<ISAMLRule>(`${ruleUrl}/${partitionGlobalId}/${ruleId}`);
}

export function createRule(payload: ISAMLRule) {
    return post<ISAMLRule>(ruleUrl, { body: payload });
}

export function updateRule(payload: ISAMLRule) {
    return put(ruleUrl, { body: payload });
}

export function deleteRule(partitionGlobalId: string, ruleId: number) {
    return axiosDelete(`${ruleUrl}/${partitionGlobalId}/${ruleId}`);
}
