import Warning from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';

import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiTenantStatusBanner } from '../common/UiTenantStatusBanner';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import useTenantAdminViewModel from './TenantAdminViewModel';

const useStyles = makeStyles(() =>
    createStyles({
        grid: { maxWidth: '1100px' },
        cardIcon: { width: '40px' },
    }),
);

const TenantAdminHome: FC = () => {

    const classes = useStyles();
    const history = useHistory();

    const match = useRouteMatch<{ tenantId: string }>();

    const { id } = useMemo<{ id: string }>(
        () => ({ id: match.params.tenantId }),
        [ match ],
    );
    const { getTenantData } = useTenantsContext();

    const tenant = useMemo(() => getTenantData(id), [ getTenantData, id ]);

    const { cardList } = useTenantAdminViewModel(id, tenant);

    return (
        <UiPageContainer
            banner={<UiTenantStatusBanner
                status={tenant?.status}
                tenantName={tenant?.name}
            />}
        >
            <UiCardGrid
                maxCardWidth="338px"
                style={{ marginTop: '20px' }}>
                {cardList.map((card, i) =>
                    !card.invisible && <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={
                            <>
                                <img
                                    className={classes.cardIcon}
                                    src={card.titleIcon}
                                    alt='accountsAndGroups' />
                                { (card.showWarning && card.warningTooltip) ?
                                    <Tooltip
                                        title={card.warningTooltip}>
                                        <Warning
                                            style={{ marginLeft: '10px' }}
                                            color='warning'
                                            data-cy={`${card.dataCy}-warning`} />
                                    </Tooltip> : undefined}
                            </>
                        }
                        description={card.description}
                        onClickLink={() => history.push(card.onClickLink)}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        disabled={card.disabled}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default TenantAdminHome;
