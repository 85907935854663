export const TelemetryTitle = {
    RobotAccounts: 'Accounts.ClickedRobotAccounts',
    SecuritySettings: {
        AuthSettingsEnterprise: 'SecuritySettings.AuthSettingsClickedEnterpriseChip',
        LearnMoreEnterprise: 'SecuritySettings.AuthSettingsLearnMoreClickedEnterpriseChip',
        SessionPolicy: 'SecuritySettings.ClickedSessionPolicy',
        EncryptionChip: 'SecuritySettings.EncryptionClickedEnterpriseChip',
        EncryptionPage: 'SecuritySettings.ClickedEncryption',
        IPRestriction: 'SecuritySettings.ClickedIPRestrictions',
        AccessPolicy: 'SecuritySettings.ClickedRestrictAccess',
    },
    AddTenant: 'Tenants.ClickedAddNew',
    OrganizationSettings: {
        General: 'OrganizationSettings.GeneralClickedProChip',
        Advanced: 'OrganizationSettings.AdvancedClickedProChip',
    },
    TenantSettings: 'TenantSettings.ClickedProChipClickedUpgrade',
    TenantSelfServeMigration: {
        PrepareForMove: 'TenantSelfServeMigration.PrepareForMove',
        SelectNewRegion: 'TenantSelfServeMigration.SelectNewRegion',
        SelectDesiredTime: 'TenantSelfServeMigration.SelectDesiredTime',
        ConfirmMigrationDetails: 'TenantSelfServeMigration.ConfirmMigrationDetails',
        RequestMove: 'TenantSelfServeMigration.RequestMove',
    },
    ResourceCenter: {
        FeaturedDownload: 'ResourceCenter.AccessResource',
        CardDownload: 'ResourceCenter.AccessResource',
        MarketoFirstNameAdminCheck: 'ResourceCenter.MarketoFirstNameAdminCheck',
        MarketoLastNameAdminCheck: 'ResourceCenter.MarketoLastNameAdminCheck',
        MarketoEmptyNameCheck: 'ResourceCenter.MarketoEmptyNameCheck',
    },
};
