import type { ButtonProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import type {
    ReactElement,
    ReactNode,
    RefObject,
    SyntheticEvent,
} from 'react';
import { forwardRef } from 'react';
import * as React from 'react';

interface UiMenuProps extends ButtonProps {
    trigger: ReactElement;
    menu: ReactNode[];
    keepOpen?: boolean;
    onOpen?: (event: SyntheticEvent | null) => void;
    minWidth?: number;
    dataCy?: string;
}

interface MenuItemProps extends React.ComponentPropsWithoutRef<'div'> {
    menu?: ReactNode[];
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const UiMenu = forwardRef<HTMLLIElement, UiMenuProps>((props, ref: React.Ref<HTMLLIElement>) => {
    const {
        trigger,
        menu,
        keepOpen: keepOpenGlobal,
        onOpen: onControlledOpen,
        minWidth,
        dataCy,
    } = props;

    const [ isInternalOpen, setInternalOpen ] = React.useState<HTMLElement | null>(null);

    const isOpen = isInternalOpen;

    let anchorRef = React.useRef<HTMLLIElement>(null);
    if (ref) {
        anchorRef = ref as RefObject<HTMLLIElement>;
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        event.stopPropagation();

        if (menu.length) {
            setInternalOpen(event.currentTarget);
        }
    };

    const handleClose = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        event.stopPropagation();

        if (anchorRef.current?.contains(event.target as HTMLElement)) {
            return;
        }

        handleForceClose();
    };

    const handleForceClose = () => {
        onControlledOpen ? onControlledOpen(null) : setInternalOpen(null);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
        if (event.key === 'Escape') {
            handleForceClose();
        }
    };

    const renderMenu: any = (menuItem: React.ReactElement<MenuItemProps>, index: number) => {
        if (React.isValidElement<MenuItemProps>(menuItem)) {
            const { ...menuItemProps } = menuItem.props;

            let extraProps = {};
            if (menuItemProps.menu) {
                extraProps = {
                    parentMenuOpen: isOpen,
                    tabIndex: 0,
                };
            }
            return React.createElement(menuItem.type, {
                ...menuItemProps,
                key: index,
                ...extraProps,
                onClick: (event: React.MouseEvent<HTMLElement>) => {
                    if (menuItemProps.menu) {
                        return;
                    }

                    event.stopPropagation();

                    if (!keepOpenGlobal) {
                        handleClose(event);
                    }

                    if (menuItem.props.onClick) {
                        menuItem.props.onClick(event);
                    }
                },
            }, menuItemProps.menu
                ? React.Children.map(menuItemProps.menu, renderMenu)
                : menuItemProps.children);
        }

    };

    return (
        <>
            {React.cloneElement(trigger, {
                onClick: isOpen ? handleForceClose : handleOpen,
                onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
                    if (event.key === 'Enter') {
                        handleOpen(event);
                    }
                },
                ref: anchorRef,
                tabIndex: 0, // Make the trigger focusable
            })}

            <Menu
                PaperProps={{ sx: { minWidth: minWidth ?? 0 } }}
                anchorEl={isOpen}
                open={!!isOpen}
                onClose={handleClose}
                onKeyDown={handleKeyDown}
                data-cy={dataCy ?? 'ui-menu'}
            >
                {React.Children.map(menu, renderMenu)}
            </Menu>
        </>
    );
}
);

UiMenu.displayName = 'UiMenu';

export default UiMenu;
