import type { Region } from '@experiences/constants';
import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    ICreateEditTenantPayload,
    ILrmProduct,
} from '@experiences/interfaces';
import { useRouteResolver } from '@experiences/util';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    useForm,
    useWatch,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import { useTenantOperations } from '../../../common/hooks/useTenantOperations';
import { getServiceLicenses } from '../../../services/licensing/LicenseManagementService';
import { getAvailableServices } from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
} from '../../../store/selectors';
import validateName from '../../../util/validators/NameValidator';
import TenantGeneralForm from '../general/TenantGeneralForm';
import type {
    IProductRequested,
    IServiceMetadata,
} from '../interfaces/service';
import { addHiddenDependenciesHelper } from '../subcomponents/helpers/AddHiddenDependenciesHelper';
import { getServiceFromProduct } from '../subcomponents/helpers/ManageLicensesHelper';
import TenantCreateAllocateLicensesComponent from '../subcomponents/TenantCreateSubcomponents/TenantCreateAllocateLicensesComponent';
import TenantCreateServicesComponent from '../subcomponents/TenantCreateSubcomponents/TenantCreateServices';
import { useTenantsContext } from '../TenantsContextProvider';

const initialSteps = [ 'CLIENT_GENERAL', 'CLIENT_ADD_SERVICES' ];

export const flow = {
    TENANT_CREATE_GENERAL_INFO: TenantGeneralForm,
    TENANT_CREATE_ADD_SERVICE: TenantCreateServicesComponent,
    TENANT_CREATE_LICENSE_ALLOCATION: TenantCreateAllocateLicensesComponent,
};

export interface ICreateEditTenantForm extends Omit<ICreateEditTenantPayload, 'customProperties'> {
    customProperties?: {
        allocatedProductLicenses: { [product: string]: string | number | undefined };
        isCanaryTenant: boolean;
    };
}

const useTenantCreateViewModel = () => {

    const history = useHistory();
    const getRoute = useRouteResolver();
    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const tenantOperations = useTenantOperations();
    const { refreshTenantsList } = useTenantsContext();
    // Redux state
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const accountPlan = useSelector(accountType);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    // SWR fetches
    const { data: licenseData } = useSWR<ILrmProduct[], Error>(
        [
            `/api/manageLicense/${partitionGlobalId}/service-licenses`,
            partitionGlobalId,
        ],
        getServiceLicenses,
    );

    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ '/api/tenant/availableservices', partitionGlobalId, accountName ],
        getAvailableServices,
    );

    // Component state
    const [ steps, updateSteps ] = useState(initialSteps);
    const [ activeStep, setActiveStep ] = useState(0);
    const isRegionEnabled = useMemo(
        () => process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL) && !DisableFeatureFedRamp,
        [ DisableFeatureFedRamp, accountPlan ],
    );
    const checkShowCreateButton = useMemo(() => {
        switch (activeStep) {
            case 0: return false;
            case 1: return licenseData?.length ? false : true;
            case 2: return true;
        }
    }, [ activeStep, licenseData ]);

    // React-hook-form initialization
    const methods = useForm<ICreateEditTenantForm>({
        mode: 'onChange',
        criteriaMode: 'all',
        shouldUnregister: false,
        defaultValues: {
            name: '',
            color: '',
            region: '' as Region,
            services: [],
            customProperties: {
                allocatedProductLicenses: {},
                isCanaryTenant: false,
            },
        },
    });

    const {
        control, handleSubmit, setError, getValues, formState: {
            errors, isDirty, isSubmitting,
        },
    } = methods ;

    const region: string | undefined = useWatch({
        name: 'region',
        control,
    });

    const onSubmit = useCallback(async (data: ICreateEditTenantForm) => {
        data.name = data.name.trim();

        if (!Object.values(data.services).some(service => !!service)) {
            setError('services', { type: 'required' });
            return;
        }

        if (!validateName(data.name)) {
            setError('name', { type: 'invalid' });
            return;
        }

        try {
            data.services = addHiddenDependenciesHelper(data.services, availableServices);
            const {
                customProperties, ...payload
            } = data;
            const createPayload: ICreateEditTenantPayload = {
                ...payload,
                CustomProperties: {
                    isCanaryTenant: customProperties?.isCanaryTenant ?? false,
                    allocatedProductLicenses: {},
                },
            };
            if (customProperties?.allocatedProductLicenses) {
                const serviceProductMap: { [service: string]: IProductRequested[] } = {};
                Object.entries(customProperties?.allocatedProductLicenses).forEach(([ product, quantity ]) => {
                    const service = getServiceFromProduct(product);
                    if (!service) {
                        return;
                    }
                    if (!serviceProductMap[service]) {
                        serviceProductMap[service] = [];
                    }

                    serviceProductMap[service].push({
                        code: product,
                        quantity: typeof quantity === 'string' ? parseInt(quantity) : (quantity ?? 0),
                    });
                });
                createPayload.CustomProperties!.allocatedProductLicenses = serviceProductMap;
            }
            await tenantOperations.tenantCreate(createPayload);
            refreshTenantsList();
            history.push(getRoute(RouteNames.OrganizationAdminHome));
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
    }, [ availableServices, getErrorMessage, getRoute, history, refreshTenantsList, setError, setErrorMessage, tenantOperations ]);

    // Side-effects via useEffect
    useEffect(() => {
        if (licenseData?.length) {
            updateSteps(initialSteps.concat('CLIENT_LICENSE_ALLOCATION'));
        }
    }, [ licenseData?.length ]);

    // State functions
    const handleStepper = (isForward?: boolean) => {
        setActiveStep(prevStep => (isForward ? prevStep + 1 : prevStep - 1));
    };

    const checkNextStep: () => boolean = useCallback(() => {
        switch (activeStep) {
            case 0: {
                return !!errors.name || !getValues('name') || (isRegionEnabled && !region);}
            case 1: return !!errors.services || getValues('services')?.length === 0;
            default: return false;
        }
    }, [ activeStep, errors.name, errors.services, getValues, isRegionEnabled, region ],
    );

    return {
        steps,
        activeStep,
        handleStepper,
        checkNextStep,
        checkShowCreateButton,
        methods,
        onSubmit: handleSubmit(onSubmit),
        isDirty,
        isSubmitting,
        isRegionEnabled,
    };
};

export default useTenantCreateViewModel;
