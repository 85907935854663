import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { secretPlaceholder } from '../../../common/constants/SecretConstant';

const useStyles = makeStyles(theme =>
    createStyles({
        section: { marginTop: 20 },
        cancelButton: { marginRight: '10px' },
        switchLabel: {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        switchFormControl: { width: '100%' },
    }),
);

export interface IEditClientIdAndSecretData {
    clientId?: string;
    clientSecret?: string;
}

export const defaultEditClientIdAndSecretData: IEditClientIdAndSecretData = {
    clientId: '',
    clientSecret: '',
};

const EditClientIdAndSecretFormComponent: FC<{
    showClientSecretPlaceholder: boolean;
    clientSecretRequired?: boolean;
}> = ({
    showClientSecretPlaceholder = false, clientSecretRequired = false,
}) => {
    const {
        control, errors,
    } = useFormContext<IEditClientIdAndSecretData>();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    return (
        <>
            <div className={classes.section}>
                <Controller
                    label={translate({ id: 'CLIENT_CLIENT_ID' })}
                    as={TextField}
                    control={control}
                    required
                    rules={{ required: true }}
                    error={!!errors.clientId}
                    helperText={errors.clientId?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    name="clientId"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    InputLabelProps={{ id: 'clientIdLabel' }}
                    inputProps={{ 'aria-labelledby': 'clientIdLabel' }}
                    data-cy="edit-client-id-and-secret-client-id"
                />
            </div>
            <div className={classes.section}>
                <Controller
                    label={translate({ id: 'CLIENT_CLIENT_SECRET' })}
                    as={TextField}
                    control={control}
                    required={clientSecretRequired}
                    rules={{ required: clientSecretRequired && !showClientSecretPlaceholder }}
                    error={!!errors.clientSecret}
                    helperText={errors.clientSecret?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                    name="clientSecret"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    InputProps={{ className: 'Tall' }}
                    placeholder={showClientSecretPlaceholder ? secretPlaceholder : undefined}
                    InputLabelProps={{ id: 'clientSecretLabel' }}
                    inputProps={{ 'aria-labelledby': 'clientSecretLabel' }}
                    data-cy="edit-client-id-and-secret-client-secret"
                />
            </div>
        </>
    );
};

export default EditClientIdAndSecretFormComponent;
