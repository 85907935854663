import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import {
    notificationType,
    UserPartition,
} from '../../../common/constants/Constant';
import { GroupsAndRolesLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import useIsServicesPageEnabled from '../../../common/hooks/useIsServicesPageEnabled';
import type { IRobot } from '../../../common/interfaces/cis/robot';
import {
    createRobot,
    editRobot,
    getRobots,
} from '../../../services/identity/RobotAccountService';
import { accountGlobalId } from '../../../store/selectors';
import reduceGroupCIS, { mapUpdateRobotDto } from '../../../util/UserGroupUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import { ConfirmAddEditRobotSuccessComponent } from './SuccessMessageComponents';

const EnableUnattRobotDisplayName = getFeatureFlagValue(Features.EnableUnattRobotDisplayName.name);

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        input: { marginTop: 20 },
        groups: {
            marginTop: 40,
            display: 'flex',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        groupText: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        groupInfoIcon: {
            width: 18,
            height: 18,
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        tooltipIcon: {
            width: 16,
            height: 16,
            marginLeft: '4px',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        editText: { margin: '48px 0px 24px 0px' },
        inlineIcon: {
            verticalAlign: 'middle',
            paddingBottom: '3px',
        },
    }),
}));

interface SimpleGroup {
    [key: string]: boolean;
}

export interface AddEditRobotAccountData {
    displayName: string;
    name: string;
    groupIds: SimpleGroup;
}

const AddEditRobotAccountComponent: React.FC<{ location?: { state?: { robot: IRobot } } }> = ({ location }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const isServicesPageEnabled = useIsServicesPageEnabled();
    const match = useRouteMatch<{ type: 'add' | 'edit' }>();
    const {
        open, close,
    } = useModalState(RouteNames.Robots);

    const getLocalizedLink = useDocumentationLinks();
    const currentAccountGlobalId = useSelector(accountGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const robot = useMemo(() => location?.state?.robot || ({} as IRobot), [ location ]);
    const type = useMemo(() => (match.params.type === 'edit' ? 'edit' : 'add'), [ match ]);

    const {
        loading: groupLoading, groups,
    } = useSimpleGroup(true);

    const [ loading, setLoading ] = useState(false);
    const [ showConfirmCreate, setShowConfirmCreate ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessageId, setErrorMessageId ] = useState('CLIENT_GROUP_CIS_ERROR');

    const initialFormData = useMemo(() => {
        if (type === 'add' && groups) {
            return {
                name: '',
                displayName: '',
                groupIds: reduceGroupCIS(groups),
            };
        }
        if (type === 'edit' && robot && groups) {
            return {
                name: robot.name,
                displayName: robot.displayName,
                groupIds: reduceGroupCIS(groups, robot.groupIds),
            };
        }
    }, [ robot, groups, type ]);

    const {
        control, handleSubmit, reset, errors, formState: { isDirty }, register, setError,
    } = useForm<AddEditRobotAccountData>({
        mode: 'onSubmit',
        defaultValues: initialFormData,
    });

    const defaultChecked = useMemo(
        () => Object.keys(initialFormData?.groupIds || {}).filter(g => initialFormData?.groupIds[g]),
        [ initialFormData ],
    );

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const isNameTaken = useCallback(
        async (name: string) => {
            if (name === initialFormData?.name) {
                return false;
            }
            const response = await getRobots(
                {
                    top: UserPartition.MAX_RETURNED_USERS,
                    skip: 0,
                    searchTerm: name,
                },
                partitionGlobalId,
            );
            return response.results.some(result => result.name?.toLowerCase() === name.toLowerCase());
        },
        [ initialFormData?.name, partitionGlobalId ],
    );

    const onSubmit = useCallback(
        async (data: AddEditRobotAccountData) => {
            setLoading(true);

            try {
                if (await isNameTaken(data.name)) {
                    setError('name', { type: 'available' });
                    setLoading(false);
                    return;
                }
                if (type === 'add') {
                    await createRobot(mapUpdateRobotDto(data, currentAccountGlobalId));
                } else {
                    await editRobot(robot.id, mapUpdateRobotDto(data, currentAccountGlobalId));
                }
            } catch (error) {
                setShowDrawerError(true);
                setErrorMessageId(`CLIENT_${type.toUpperCase()}_ROBOT_ERROR`);
                return;
            }

            setLoading(false);
            if (type === 'add' && isServicesPageEnabled) {
                setShowConfirmCreate(true);
            } else {
                createNotification(
                    translate({ id: type === 'add' ? 'CLIENT_NEW_ROBOT_ADDED' : 'CLIENT_ROBOT_UPDATED' }),
                );
                close(true);
            }
        },
        [
            type,
            isServicesPageEnabled,
            isNameTaken,
            setError,
            currentAccountGlobalId,
            robot.id,
            createNotification,
            translate,
            close,
        ],
    );

    return (
        <UiDrawer
            title={
                type === 'add'
                    ? translate({ id: 'CLIENT_ADD_ROBOT_ACCOUNT' })
                    : translate({ id: 'CLIENT_EDIT_ROBOT_ACCOUNT' })
            }
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(true),
            }}
            loading={groupLoading}
            error={{
                showError: showDrawerError,
                message: translate({ id: errorMessageId }),
            }}
        >
            <>
                {showConfirmCreate ? (
                    <ConfirmAddEditRobotSuccessComponent close={close} />
                ) : (
                    <UiForm
                        onSubmit={handleSubmit(onSubmit)}
                        actions={
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    onClick={() => close()}
                                    color="primary"
                                    data-cy='add-edit-cancel-button'>
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={loading}
                                    disabled={!isDirty}
                                    onClick={handleSubmit(onSubmit)}
                                    variant="contained"
                                    data-cy="add-edit-submit-button"
                                >
                                    {type === 'add'
                                        ? translate({ id: 'CLIENT_ADD' })
                                        : translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        }
                        isDrawer
                    >
                        {type === 'add' && (
                            <Typography style={{ marginTop: '24px' }}>
                                {translate({ id: 'CLIENT_ADD_ROBOT_DESC' })}
                            </Typography>
                        )}
                        {EnableUnattRobotDisplayName && (
                            <div className={classes.input}>
                                <Controller
                                    label={translate({ id: 'CLIENT_DISPLAY_NAME' })}
                                    as={TextField}
                                    control={control}
                                    required
                                    rules={{ required: true }}
                                    error={!!errors.displayName}
                                    helperText={
                                        errors.displayName?.type === 'required' &&
                                        translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                                    }
                                    name="displayName"
                                    variant="outlined"
                                    fullWidth
                                    InputProps={{ className: 'Tall' }}
                                    InputLabelProps={{ id: 'requiredDisplayNameLabel' }}
                                    inputProps={{ 'aria-labelledby': 'requiredDisplayNameLabel' }}
                                    data-cy="add-edit-display-name"
                                />
                            </div>
                        )}
                        <div className={classes.input}>
                            <Controller
                                label={translate({ id: 'CLIENT_NAME' })}
                                disabled={type === 'edit'}
                                as={TextField}
                                control={control}
                                required
                                rules={{ required: true }}
                                error={!!errors.name}
                                helperText={
                                    (errors.name?.type === 'required' &&
                                        translate(
                                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                            { 0: translate({ id: 'CLIENT_NAME' }) },
                                        )) ||
                                    (errors.name?.type === 'available' && translate({ id: 'CLIENT_NAME_TAKEN_ERROR' }))
                                }
                                name="name"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                InputProps={{ className: 'Tall' }}
                                InputLabelProps={{ id: 'requiredNameLabel' }}
                                inputProps={{ 'aria-labelledby': 'requiredNameLabel' }}
                                data-cy="add-edit-name"
                            />
                        </div>
                        <div className={classes.groups}>
                            <Typography
                                id="client-group-label"
                                className={classes.inputLabel}>
                                {translate({ id: 'CLIENT_GROUP_MEMBERSHIP' })}
                            </Typography>
                            <Tooltip
                                arrow
                                title={
                                    <div style={{ display: 'flex' }}>
                                        {translate(
                                            { id: 'CLIENT_GROUPS_GUIDE' },
                                            { titleProductName: process.buildConfigs.titleProductName },
                                        )}
                                        <OpenInNewIcon className={classes.tooltipIcon} />
                                    </div>
                                }
                            >
                                <a
                                    className={classes.a}
                                    href={getLocalizedLink(GroupsAndRolesLink)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label={translate({ id: 'CLIENT_GROUPS_GUIDE_LINK_ARIA' })}
                                >
                                    <HelpOutlineIcon className={classes.groupInfoIcon} />
                                </a>
                            </Tooltip>
                        </div>
                        <FormGroup
                            role="group"
                            aria-labelledby="client-group-label">
                            {groups?.map((group, i) => (
                                <FormControlLabel
                                    key={i}
                                    control={
                                        <Checkbox
                                            size="small"
                                            defaultChecked={defaultChecked.indexOf(group.id) > -1}
                                        />
                                    }
                                    label={<Typography className={classes.groupText}>
                                        {group.name}
                                    </Typography>}
                                    disabled={group.name === 'Everyone'}
                                    inputRef={register}
                                    name={`groupIds[${group.id}]`}
                                    aria-label={`${group.name}-label`}
                                />
                            ))}
                        </FormGroup>
                        {type === 'edit' && isServicesPageEnabled && (
                            <>
                                <Typography className={classes.editText}>
                                    {translate({ id: 'CLIENT_INVITE_SUCCESS_TEXT_TWO' })}
                                </Typography>
                                <Typography>
                                    {translate(
                                        {
                                            id: isAdminRevampEnabled
                                                ? 'CLIENT_INVITE_SUCCESS_TEXT_THREE_REVAMP'
                                                : 'CLIENT_INVITE_SUCCESS_TEXT_THREE',
                                        },
                                        { ICON: <PeopleAltOutlinedIcon className={classes.inlineIcon} /> },
                                    )}
                                </Typography>
                            </>
                        )}
                    </UiForm>
                )}
            </>
        </UiDrawer>
    );
};

export default AddEditRobotAccountComponent;
