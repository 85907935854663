import {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isHostModeSelector } from '../../store/selectors';
import buildTitleMap from './Constant/buildTitleMap';

export const useTabName = () => {
    const { formatMessage: translate } = useIntl();
    const location = useLocation();
    const hostMode = useSelector(isHostModeSelector);

    const getTitle = useCallback(
        (pathname: string) => {
            const includesPathTitle = buildTitleMap(hostMode);
            const baseRoute = process.buildConfigs.tabNameRoute + '/';
            const routeString = pathname.substr(pathname.indexOf(baseRoute) + baseRoute.length);
            const path = routeString.substring(0, routeString.indexOf('/')) || routeString.substring(0);
            return includesPathTitle.get(path)
                ? translate({ id: includesPathTitle.get(path) })
                : `${process.buildConfigs.productName}`;
        },
        [ translate, hostMode ],
    );

    useEffect(() => {
        document.title = getTitle(location.pathname);
    }, [ getTitle, location.pathname ]);

    return { getTitle };
};
