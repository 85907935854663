import { UiDialog } from '@experiences/ui-common';
import { Button } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

const RevokeRefreshTokenConfirmation: React.FC<{
    revokeTokenHandler: () => void;
    close: () => void;
}> = ({
    revokeTokenHandler, close,
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_API_ACCESS' })}
            close={close}
            width="650px"
            actions={[
                <Button
                    key="cancelButton"
                    color="primary"
                    onClick={close}
                    data-cy="revoke-refresh-token-cancel">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    key="primaryButton"
                    variant="contained"
                    data-cy="revoke-refresh-token-submit"
                    onClick={revokeTokenHandler}
                >
                    {translate({ id: 'CLIENT_REVOKE' })}
                </Button>,
            ]}
        >
            <div>
                <div className="transboxRevoke">
                    {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE1' })}
                </div>
                <div className="transboxRevoke">
                    {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE2' })}
                </div>
                <div className="transboxRevoke">
                    {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE3' })}
                    <ul>
                        <li>
                            {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE4' })}
                        </li>
                        <li>
                            {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE5' })}
                        </li>
                        <li>
                            {translate({ id: 'CLIENT_REVOKE_CONFIRMATION_LINE6' })}
                        </li>
                    </ul>
                </div>
            </div>
        </UiDialog>
    );
};

export default RevokeRefreshTokenConfirmation;
