import { useShowDialog } from '@experiences/util';
import React, { useCallback } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useShowUiCustomizationDialog = () => {
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();

    return useCallback((nameTextId: string, titleTextId: string, descriptionTextId: string) => {
        const name = translate({ id: nameTextId });
        return createDialog({
            title: translate({ id: titleTextId }, { name }),
            body: (
                <FormattedMessage
                    id={descriptionTextId}
                    values={{
                        strong: (chunk: string) => <strong>
                            {chunk}
                        </strong>,
                        name,
                    }}
                />
            ),
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
        });
    },
    [ createDialog, translate ]);
};

export default useShowUiCustomizationDialog;
