import { UiProgressButton } from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { changePassword } from '../../../services/identity/AccountService';
import { userGlobalId } from '../../../store/selectors';
import { mapResetPasswordDataToChangePasswordDto } from '../../../util/AccountUtil';
import type { IEditPasswordData } from '../../common/EditPasswordFormComponent';
import EditPasswordFormComponent, {
    defaultEditPasswordData,
    useEditPasswordStyles,
} from '../../common/EditPasswordFormComponent';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';

const useStyles = makeStyles(() =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

export interface IResetPasswordData extends IEditPasswordData {
    currentPassword: string;
}

const ResetPasswordComponent: FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const userId = useSelector(userGlobalId);
    const editPasswordClasses = useEditPasswordStyles();
    const { formatMessage: translate } = useIntl();
    const {
        open, close,
    } = useModalState(RouteNames.PrivacyAndSecuritySettings);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(translate({ id: 'CLIENT_RESET_PASSWORD_GENERIC_ERROR' }));
    const methods = useForm<IResetPasswordData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditPasswordData,
            currentPassword: '',
        },
    });
    const {
        handleSubmit, formState, errors, control,
    } = useMemo(() => methods, [ methods ]);
    const {
        isDirty, isSubmitting,
    } = formState;

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: IResetPasswordData) => {
            try {
                await changePassword(mapResetPasswordDataToChangePasswordDto(data, userId));
                createNotification(translate({ id: 'CLIENT_RESET_PASSWORD_SUCCESS' }));
                close(true);
            } catch (error) {
                setShowDrawerError(true);
                const errorMessage = (error as any)?.response?.data?.errorMessage;
                if (errorMessage) {
                    setErrorMessage(errorMessage);
                } else {
                    setErrorMessage(translate({ id: 'CLIENT_RESET_PASSWORD_GENERIC_ERROR' }));
                }
            }
        },
        [ close, createNotification, translate, userId ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_CHANGE_PASSWORD' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: errorMessage,
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="reset-password-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <div className={editPasswordClasses.input}>
                    <Typography
                        className={clsx(editPasswordClasses.inputLabel, editPasswordClasses.inputMargin)}
                        id="currentPasswordLabel"
                    >
                        {translate({ id: 'CLIENT_CURRENT_PASSWORD' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        name="currentPassword"
                        variant="outlined"
                        type="password"
                        autoComplete="new-password"
                        rules={{ required: true }}
                        error={!!errors.currentPassword}
                        helperText={
                            errors.currentPassword?.type === 'required' &&
                            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        inputProps={{ 'aria-labelledby': 'currentPasswordLabel' }}
                        data-cy="reset-password-current-password"
                    />
                </div>
                <FormProvider {...methods}>
                    <EditPasswordFormComponent
                        respectPasswordRequirements
                        required />
                </FormProvider>
            </UiForm>
        </UiDrawer>
    );
};

export default ResetPasswordComponent;
