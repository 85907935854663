import type { IHistoryStatus } from '@experiences/ecommerce';
import { useIsEcommerceEnabled } from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { AccountType } from '@experiences/interfaces';
import {
    useEffect,
    useState,
} from 'react';
import useSWR from 'swr';

import useCheckLicense from '../../common/hooks/useCheckLicense';
import { fetchHistoryStatus } from '../../services/licensing/BillingService';

export function useShouldShowManageButton(
    currentAccountName: string,
    accountType: AccountType | undefined,
): boolean | undefined {

    const isEcommerceEnabled = useIsEcommerceEnabled();
    const EnableEcommerceMonthlyUpsell = useFeatureFlagValue(Features.EnableEcommerceMonthlyUpsell.name);
    const [ historyStatus, setHistoryStatus ] = useState<boolean | undefined>(undefined);
    const { data: ecommerceHistoryStatus } = useSWR<IHistoryStatus, Error>(
        isEcommerceEnabled ? currentAccountName : null,
        fetchHistoryStatus,
    );
    const {
        isAccountLicenseEnterpriseOrPro, isPro,
    } = useCheckLicense();

    useEffect(() => {
        if (ecommerceHistoryStatus?.hasHistoricalData != null) {
            setHistoryStatus(ecommerceHistoryStatus?.hasHistoricalData);
        }
    }, [ ecommerceHistoryStatus ]);

    const isExpectedLicenseType = EnableEcommerceMonthlyUpsell ? isPro : isAccountLicenseEnterpriseOrPro(accountType);

    return isEcommerceEnabled && accountType && isExpectedLicenseType && historyStatus;
}
