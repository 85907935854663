import type {
    SxProps,
    Theme,
} from '@mui/material';
import {
    Card,
    CardActions,
    CardContent,
    Chip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { ReactElement } from 'react';
import React from 'react';
import { useIntl } from 'react-intl';

interface UiCardThemeProps {
    clickable: boolean;
    disabled: boolean;
}

const useStyles = makeStyles<Theme, UiCardThemeProps>(theme =>
    createStyles({
        card: props => ({
            '&:hover': props.clickable
                ? {
                    boxShadow: `0px 1px 10px ${theme.palette.semantic.colorBackgroundGray}, 0px 4px 5px ${theme.palette.semantic.colorBackgroundGray}, 0px 2px 4px -1px ${theme.palette.semantic.colorBackgroundGray}`,
                    cursor: 'pointer',
                }
                : undefined,
            boxShadow: `0px 2px 1px -1px ${theme.palette.semantic.colorBackgroundGray}, 0px 1px 1px ${theme.palette.semantic.colorBackgroundGray}, 0px 1px 3px ${theme.palette.semantic.colorBackgroundGray}`,
            border: `1px ${theme.palette.semantic.colorBorderDisabled}`,
            backgroundColor: props.disabled ? theme.palette.semantic.colorBackgroundDisabled : '',
        }),
        cardContent: {
            padding: '32px 24px 16px 24px',
            '&:last-child': { paddingBottom: '36px' },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '8px 0 12px 0',
        },
        titleText: {
            marginLeft: '12px',
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        descriptionText: {
            fontWeight: 400,
            fontSize: '14px',
        },
        chip: props => ({
            marginTop: '16px',
            fontSize: '12px',
            fontWeight: 600,
            backgroundColor: props.disabled ? theme.palette.semantic.colorBorderDisabled : `${theme.palette.semantic.colorInfoBackground} !important`,
            color: theme.palette.semantic.colorInfoForeground,
            height: '24px',
        }),
        cardAction: {
            position: 'absolute',
            bottom: '5px',
            right: '0px',
        },
    }),
);

export const UiCard: React.FC<{
    title: string;
    titleIcon: ReactElement<any, any>;
    description: string;
    chipText?: string;
    onClickLink: () => void;
    dataCy: string;
    actions?: ReactElement<any, any>;
    sx?: SxProps;
    style?: React.CSSProperties;
    disabled?: boolean;
}> = ({
    title, titleIcon, description, chipText, onClickLink, dataCy, actions, sx, style, disabled = false,
}) => {

    const classes = useStyles({
        clickable: !!onClickLink && !disabled,
        disabled,
    });
    const { formatMessage: translate } = useIntl();

    return (
        <Card
            className={classes.card}
            role="gridcell"
            tabIndex={0}
            sx={sx}
            style={style}
            onClick={() => !disabled && onClickLink()}
            onKeyPress={event => (event.key === 'Enter' || event.code === 'Space')
                && !disabled && onClickLink()}
            data-cy={dataCy}
            aria-label={translate({ id: title })}>
            <CardContent classes={{ root: classes.cardContent }}>
                <div className={classes.titleSection}>
                    {titleIcon}
                    <Typography
                        className={classes.titleText}
                        component="div">
                        {translate({ id: title })}
                    </Typography>
                </div>
                <Typography
                    className={classes.descriptionText}>
                    {translate({ id: description })}
                </Typography>
                {chipText && <Chip
                    clickable={false}
                    className={classes.chip}
                    label={translate({ id: chipText })} />}
            </CardContent>
            {actions &&
                <CardActions className={classes.cardAction}>
                    {actions}
                </CardActions>}
        </Card>
    );
};
