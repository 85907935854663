import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiProgressButton } from '@experiences/ui-common';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

import UiAlertBanner from '../../common/UiAlertBanner';
import UiForm from '../../common/UiForm';
import useExternalProvidersAADFormViewModel from './ExternalProvidersAADFormViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '8px' },
        button: { marginTop: '24px' },
        errorIcon: {
            color: theme.palette.semantic.colorErrorIcon,
            marginRight: '4px',
        },
        errorMessage: {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            fontSize: '14px',
            color: theme.palette.semantic.colorErrorIcon,
        },
        errorAlert: {
            width: 'fit-content',
            marginTop: '16px',
        },
        linkEmailsCheckbox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        footer: {
            position: 'fixed',
            bottom: '0px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
            height: '64px',
            width: 'calc(100% - max(min(312px, 20vw), 175px) - 68px)',
            marginLeft: '-24px',
            padding: '0px 24px',
            backgroundColor: theme.palette.semantic.colorBackground,
            zIndex: 1,
        },
        footerButton: {
            color: theme.palette.semantic.colorPrimary,
            marginLeft: '8px',
            '& > a': {
                color: theme.palette.semantic.colorForegroundInverse,
                'text-decoration': 'none !important',
            },
        },
        deleteButton: {
            marginTop: 20,
            color: theme.palette.semantic.colorErrorText,
            width: 'fit-content',
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important` },
            '&:focus': {
                color: `${theme.palette.semantic.colorErrorText} !important`,
                backgroundColor: `${theme.palette.semantic.colorErrorBackground} !important`,
            },
        },
        learnMore: {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.semantic.colorPrimaryLighter,
        },
        lauchIcon: {
            fontSize: '14px',
            marginLeft: '4px',
            color: theme.palette.semantic.colorPrimaryLighter,
        },
        groupInfoIcon: {
            width: 18,
            height: 18,
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        centerLoader: {
            display: 'flex',
            justifyContent: 'center',
        },
    }),
}));

const ExternalProvidersAADForm: React.FC<{
    onError: (error: any) => Promise<void> | void;
    onClose?: () => void;
    showButtonFooter?: boolean;
}> = ({
    onError,
    onClose,
    showButtonFooter = false,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const {
        bulkAuthenticationSetting,
        testConnectionState,
        testConnectionError,
        emailLinkingChecked,
        setEmailLinkingChecked,
        methods: {
            register,
            handleSubmit,
            trigger,
            formState: {
                errors, isSubmitting,
            },
        },
        disableSubmit,
        disableTestConnection,
        cancel,
        handleAADAuthPopup,
        handleTestConnectionBypass,
    } = useExternalProvidersAADFormViewModel(
        showButtonFooter,
        onError,
        onClose,
    );

    if (!bulkAuthenticationSetting) {
        return <div className={classes.centerLoader}>
            <CircularProgress />
        </div>;
    }

    return (
        <>
            {showButtonFooter && !process.buildConfigs.disableTestConnection && testConnectionState === 'error' && (
                <div className={classes.errorAlert}>
                    <UiAlertBanner
                        closeable
                        type="error">
                        {`${translate({ id: 'CLIENT_AZURE_TEST_CONNECTION_FAILED' })}: ${testConnectionError}`}
                    </UiAlertBanner>
                </div>
            )}
            {DisableFeatureFedRamp && (
                <PortalAlertBar cancelable={false}>
                    <Typography>
                        {translate({ id: 'CLIENT_AZURE_AD_PUBLIC_INFO' })}
                    </Typography>
                            &nbsp;
                    <a
                        className={classes.a}
                        target="_blank"
                        rel="noopener noreferrer"
                        // eslint-disable-next-line max-len
                        href="https://docs.microsoft.com/en-us/azure/azure-government/documentation-government-plan-identity#choosing-your-identity-authority"
                    >
                        {translate({ id: 'CLIENT_MORE_INFO' })}
                    </a>
                </PortalAlertBar>
            )}
            <UiForm
                onSubmit={handleSubmit}>
                <div className={clsx(classes.input)}>
                    <TextField
                        inputRef={register({
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_AZURE_TENANT_ID' }) },
                            ),
                        })}
                        error={!!errors.tenantID}
                        label={translate({ id: 'CLIENT_AZURE_TENANT_ID' })}
                        placeholder={translate({ id: 'CLIENT_AZURE_TENANT_ID_PLACEHOLDER' })}
                        name="tenantID"
                        variant="outlined"
                        fullWidth
                        size="small"
                        inputProps={{ 'data-cy': 'azure-tenant-id-field' }}
                        required
                    />
                </div>
                <div className={clsx(classes.input)}>
                    <TextField
                        inputRef={register({
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_AZURE_APPLICATION_ID' }) },
                            ),
                        })}
                        error={!!errors.applicationID}
                        label={translate({ id: 'CLIENT_AZURE_APPLICATION_ID' })}
                        placeholder={translate({ id: 'CLIENT_AZURE_APPLICATION_ID_PLACEHOLDER' })}
                        name="applicationID"
                        variant="outlined"
                        fullWidth
                        size="small"
                        inputProps={{ 'data-cy': 'azure-application-id-field' }}
                        required
                    />
                </div>
                <div className={clsx(classes.input)}>
                    <TextField
                        inputRef={register({
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_AZURE_APPLICATION_SECRET' }) },
                            ),
                        })}
                        error={!!errors.applicationSecret}
                        label={translate({ id: 'CLIENT_AZURE_APPLICATION_SECRET' })}
                        placeholder={translate({ id: 'CLIENT_AZURE_APPLICATION_SECRET_PLACEHOLDER' })}
                        name="applicationSecret"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        inputProps={{ 'data-cy': 'azure-application-secret-field' }}
                        style={{ marginBottom: '20px' }}
                        required
                    />
                </div>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={emailLinkingChecked}
                            onChange={async e => {
                                setEmailLinkingChecked(e.target.checked);
                                await trigger();
                            }}
                            data-cy="link-email-checkbox"
                        />
                    }
                    label={
                        <div className={classes.linkEmailsCheckbox}>
                            <Typography>
                                {translate({
                                    id: process.buildConfigs.disableUserInvite
                                        ? 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_ADD'
                                        : 'CLIENT_AZURE_AD_CHECK_LINK_EMAILS_INVITE',
                                })}
                            </Typography>
                        </div>
                    }
                />
                {!showButtonFooter
                    ? (
                        <div>
                            {!process.buildConfigs.disableTestConnection && (
                                <UiProgressButton
                                    className={classes.button}
                                    component={Link}
                                    disabled={disableTestConnection}
                                    target="popup"
                                    onClick={handleAADAuthPopup}
                                    loading={testConnectionState === 'loading'}
                                    endIcon={testConnectionState === 'success' && <CheckIcon />}
                                    data-cy="azure-test-connection-button"
                                >
                                    {translate({ id: 'CLIENT_AZURE_TEST_CONNECTION' })}
                                </UiProgressButton>
                            )}
                            {!process.buildConfigs.disableTestConnection &&
                                (testConnectionState === 'error' || testConnectionState === 'unknown') && (
                                <div className={classes.errorMessage}>
                                    <ErrorIcon className={classes.errorIcon} />
                                    <p>
                                        {`${translate({ id: 'CLIENT_AZURE_TEST_CONNECTION_FAILED' })}: ${testConnectionError}`}
                                        {process.buildConfigs.enableTestConnectionBypass && testConnectionState === 'unknown' && <>
                                            &nbsp;
                                            {translate({ id: 'CLIENT_RELEASE_USER_LICENSE_DETAILS_P3' })}
                                            &nbsp;
                                            <Link
                                                component='p'
                                                underline='hover'
                                                display="inline"
                                                onClick={handleTestConnectionBypass}
                                            >
                                                {translate({ id: 'CLIENT_CLICK_HERE' })}
                                            </Link>
                                        </>}
                                    </p>
                                </div>
                            )}
                            <UiProgressButton
                                type="submit"
                                loading={isSubmitting}
                                disabled={disableSubmit}
                                variant="contained"
                                className={clsx(classes.button)}
                                data-cy="azure-submit-button"
                            >
                                {translate({ id: 'CLIENT_SAVE' })}
                            </UiProgressButton>
                        </div>
                    )
                    : (
                        <div className={classes.footer}>
                            <Button
                                className={classes.footerButton}
                                onClick={cancel}>
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                className={classes.footerButton}
                                component={Link}
                                loading={testConnectionState === 'loading' || isSubmitting}
                                disabled={disableTestConnection}
                                target="popup"
                                variant="contained"
                                onClick={handleAADAuthPopup}
                                data-cy="azure-test-and-save-button"
                            >
                                {translate({ id: 'CLIENT_TEST_AND_SAVE' })}
                            </UiProgressButton>
                        </div>
                    )}
            </UiForm>
        </>
    );
};

export default ExternalProvidersAADForm;
