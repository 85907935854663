import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import {
    updateDefaultSubscription,
    UpdateDefaultSubscriptionUrl,
} from '../../services/notification-preferences';
import { isAdminSelector } from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import { useTenantsContext } from '../tenants/TenantsContextProvider';
import NotificationSettingsAdminPage from './Admin/NotificationSettingsAdminPage';
import NotificationSettingsEditProfilePage from './Admin/NotificationSettingsEditProfilePage';
import NotificationSettingsComponentUser from './NotificationSettingsComponentUser';
import NotificationSettingSelectionMenu from './NotificationSettingSelectionMenu';

const useStyles = makeStyles((theme) =>
    createStyles({
        heading: {
            fontSize: '16px',
            fontWeight: 'bold',
            paddingBottom: '20px',
        },
        previewHeadingSuffix: {
            fontSize: '10px',
            color: theme.palette.semantic.colorForegroundLight,
            fontWeight: 400,
            lineHeight: '16px',
            paddingLeft: '8px',
        },
        mainContentPlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
        },
        restoreDefaultSubscriptionBlock: { margin: '20px 0' },
        restoreDefaultSubscriptionLink: {
            marginLeft: '14px',
            fontWeight: 600,
            cursor: 'pointer',
            textDecoration: 'none',
        },
    }),
);

const NotificationSettingsComponent: React.FC = () => {
    const isAdmin = useSelector(isAdminSelector);
    const { logEvent } = useTelemetryHelper();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const DisableNotificationPreview = useFeatureFlagValue(
        Features.DisableNotificationPreview.name,
    );

    const EnableNotificationSettingsForAdmin = useFeatureFlagValue(
        Features.EnableNotificationSettingsForAdmin.name,
    );
    const match = useRouteMatch<{ type: 'User' | 'UserGroup' | 'NewUserGroup'; id: string; name: string }>(RouteNames.AddNotificationSettingsProfile);
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const { enqueueSnackbar } = useSnackbar();
    const isNotificationSettings = useRouteMatch(RouteNames.NotificationSettings);
    const isAddUserGroupProfile = useRouteMatch(RouteNames.AddUserGroupProfile);

    useSWR(
        tenantId ? [ UpdateDefaultSubscriptionUrl, tenantId ] : null,
        updateDefaultSubscription);

    const isAddNotificationSettingsProfile = useRouteMatch(RouteNames.AddNotificationSettingsProfile);
    useEffect(() => {
        logEvent('NotificationSettings.loadPreferences');
        return () => {};
    }, [ logEvent ]);

    const createErrorNotification = useCallback(
        (message: string) => {
            enqueueSnackbar(message, { variant: notificationType.ERROR as any });
        },
        [ enqueueSnackbar ],
    );

    const handleError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createErrorNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`);
        },
        [ createErrorNotification, translate ],
    );

    const [ isManageActionmode, setManageActionmode ] = React.useState<boolean>(false);
    const [ severitymode, setMode ] = useState(false);
    useEffect(() => {
        const severitymodeState = sessionStorage.getItem('isNSManageMode');
        setManageActionmode(severitymodeState === 'true' && EnableNotificationSettingsForAdmin);
    }, [ isManageActionmode, EnableNotificationSettingsForAdmin ]);

    const handleActionMenu = useCallback((manageMode: boolean) => {
        sessionStorage.setItem('isNSManageMode', manageMode.toString());
        setManageActionmode(manageMode);
    }, []);

    return (
        <>
            { (isNotificationSettings?.isExact || isAddUserGroupProfile?.isExact) && <div>
                {isAdmin && EnableNotificationSettingsForAdmin && <div>
                    <NotificationSettingSelectionMenu {...{ handleActionMenu }} />
                </div>}
                { (!isAdmin || !EnableNotificationSettingsForAdmin) &&
                <Typography
                    className={classes.heading}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_NOTIFICATIONS' })}
                    {!DisableNotificationPreview && (
                        <span className={classes.previewHeadingSuffix}>
                            {translate({ id: 'CLIENT_PREVIEW' })}
                        </span>
                    )}
                </Typography>}

                {!isManageActionmode &&
                  <NotificationSettingsComponentUser />}
                {isManageActionmode && <NotificationSettingsAdminPage
                    {...{ handleError }}
                />}

            </div>}
            {isAddNotificationSettingsProfile?.isExact &&
                <NotificationSettingsEditProfilePage
                    displayName={match?.params.name ?? ''}
                    identifier={match?.params.id ?? ''}
                    type={match?.params.type ?? ''} />}
        </>
    );
};

export default NotificationSettingsComponent;
