import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import { PortalTooltip } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) =>
    createStyles({
        unreadMainMessage: {
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            cursor: 'pointer',
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: `${Tokens.FontFamily.FontMSize } !important`,
        },
        readMainMessage: {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            fontSize: `${Tokens.FontFamily.FontMSize } !important`,
        },
        unreadMMessage: {
            cursor: 'pointer',
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            fontSize: `${Tokens.FontFamily.FontMSize } !important`,
        },
        severityErrorMessage: {
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            color: theme.palette.semantic.colorErrorIcon,
            cursor: 'default',
        },
        severityWarningMessage: {
            fontWeight: `${Tokens.FontFamily.FontWeightSemibold } !important`,
            color: theme.palette.semantic.colorWarningIcon,
            cursor: 'default',
        },
    }),
);
const NotificationCellComponent: React.FC<{ isRead: boolean;isMain: boolean; text: string; severity: boolean }> = ({
    isRead, isMain, text, severity,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return (
        <div>
            { !severity &&
            <Typography
                className={isRead ? classes.readMainMessage : isMain ?
                    classes.unreadMainMessage : classes.unreadMMessage}>
                {text}
            </Typography>}

            { severity && text &&
            <PortalTooltip text={translate({ id: isMain ? 'SEVERITY_WARN' : 'SEVERITY_ERROR' })}>
                <Typography
                    className={isMain ? classes.severityWarningMessage : classes.severityErrorMessage}>
                    {text}
                </Typography>
            </PortalTooltip>}
        </div>
    );
};

export default NotificationCellComponent;
