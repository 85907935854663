import { get } from '../utility/Requests.default';

export const insightsDataRetentionPolicyUrl = `/api/insights/dataretention`;

export interface InsightsDataRetentionPolicy {
    result: {
        OrganizationId: string;
        RetentionTime_Years: number;
        DeletionDate: Date;
    };
}

export const getDataRetentionPolicy = (_url: string, organizationName: string) => get<InsightsDataRetentionPolicy>(insightsDataRetentionPolicyUrl, { urlParams: { organizationName } });
