import {
    Button,
    Fade,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { renderRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';

import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import useTenantCreateViewModel, { flow } from './TenantCreateViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        standardLayout: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '40px',
            width: '100%',
        },
        componentLayout: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        footer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        footerButton: {
            marginLeft: '8px',
            minWidth: '86px',
        },
        button: {
            marginLeft: '8px',
            minWidth: '172px',
        },
        stepper: {
            width: '100%',
            paddingTop: '48px',
        },
        noBannerHeader: {
            alignSelf: 'flex-start',
            fontSize: '20px',
            fontWeight: 600,
            marginTop: '20px',
            color: theme.palette.semantic.colorForegroundEmp,
        },
    }),
);

const TenantCreateComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const history = useHistory();

    const {
        steps, activeStep, handleStepper, checkNextStep, checkShowCreateButton, methods, isDirty, isSubmitting, onSubmit,
    } = useTenantCreateViewModel();

    return (
        <UiPageContainer
            maxWidth="1100px"
            tenantShellHidden>
            <Typography
                className={classes.noBannerHeader}
                component='h1'
            >
                {translate({ id: 'CLIENT_TENANT_CREATE' })}
            </Typography>
            <div className={classes.stepper}>
                <Stepper
                    activeStep={activeStep}
                    orientation="horizontal"
                >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        return (
                            <Step
                                key={index}
                                {...stepProps}>
                                <StepLabel {...labelProps}>
                                    {translate({ id: label })}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <UiForm
                onSubmit={onSubmit}
                actions={<div className={classes.footer}>
                    <Button
                        onClick={history.goBack}
                        className={classes.footerButton}
                        data-cy="tenant-create-cancel-button">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    {activeStep > 0 && (
                        <Button
                            className={classes.button}
                            variant="outlined"
                            onClick={() => handleStepper(false)}
                            data-cy="tenant-create-back-button">
                            {translate({ id: 'CLIENT_PREVIOUS' })}
                        </Button>
                    )}
                    {!checkShowCreateButton && <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => handleStepper(true)}
                        disabled={checkNextStep()}
                        data-cy="tenant-create-next-button">
                        {translate({ id: 'CLIENT_NEXT' })}
                    </Button>}
                    {checkShowCreateButton && <Button
                        type="submit"
                        variant="contained"
                        className={classes.button}
                        disabled={!isDirty || isSubmitting}
                        data-cy="tenant-create-submit-button">
                        {translate({ id: 'CLIENT_CREATE' })}
                    </Button>}
                </div>}>
                <div className={classes.standardLayout}>
                    <FormProvider {...methods}>
                        {Object.values(flow)
                            .filter((_, index) => index === activeStep)
                            .map(Component =>
                                activeStep === 0 ? (
                                    <div
                                        key={activeStep}
                                        className={classes.componentLayout}>
                                        <Component
                                            width='100%'
                                            type='add' />
                                    </div>
                                ) : (
                                    <Fade
                                        in
                                        timeout={300}
                                        key={activeStep}>
                                        <div className={classes.componentLayout}>
                                            <Component />
                                        </div>
                                    </Fade>
                                ),
                            )}
                    </FormProvider>
                </div>
            </UiForm>
        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <TenantCreateComponent />
        {renderRoutes(route?.routes)}
    </>
);
