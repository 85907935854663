import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { setUserProfile } from '../../store/action/UserProfileAction';
import { profile } from '../../store/selectors';

export default function useTenantChangeListener(callback: (_: any) => void) {
    const currentProfile = useSelector(profile);

    return useCallback(
        (e: any) => {
            setUserProfile({
                ...currentProfile,
                currentOrchestratorUser: null,
            });
            callback(e.detail.selectedTenantId);
        },
        [ callback, currentProfile ],
    );
}
