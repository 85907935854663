import type {
    IPagination,
    IValueModelObject,
} from '@experiences/interfaces';
import { useUiDataContext } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import {
    isEqual,
    omit,
} from 'lodash';
import { useSnackbar } from 'notistack';
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import type { ITenant } from '../../../../common/interfaces/tenant/tenant';
import {
    createValuesForKey,
    getValuesForKey,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import type { ITenantTagsPropertiesContext } from './AddEditTenantTagsPropertiesComponent';
import {
    validateNumber,
    validateRegex,
    validateString,
} from './TenantTagsUtil';
import ValuesDuplicateWarningDialogBody from './ValuesDuplicateWarningDialogBody';

export interface ValueChip {
    id: string;
    type: string;
    value: string;
    data: IValueModelObject;
    tooltip?: string;
}
interface ITagPropertyDrawerDto {
    Values: ValueChip[];
}

const DEFAULT_VALUES = { Values: [] };

const useAddEditTenantTagsPropertiesDrawerViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const { open } = useModalState();
    const { logEvent } = useTelemetryHelper();
    const createDialog = useShowDialog();

    const match = useRouteMatch<{ tenantId: string; type: 'add' | 'edit'; id: string }>();
    const type = match.params.type;
    const id = match.params.id;
    const tenantId = match.params.tenantId;

    const organizationName = useSelector(accountLogicalName);
    const accountKey = useSelector(accountGlobalId);

    const { enqueueSnackbar } = useSnackbar();

    const [ hasError, setHasError ] = useState(false);

    const {
        data: contextData, setData,
    } = useUiDataContext<ITenantTagsPropertiesContext>();

    const close = useCallback(() => history.goBack(), [ history ]);

    const {
        data: tenant, isValidating: tenantLoading,
    } = useSWR<ITenant>(
        (tenantId && !process.buildConfigs.showForMSI) ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ] : null,
        getTenantById,
    );

    const {
        data: propertyValues, isValidating: valuesLoading,
    } = useSWR<{ totalCount: number; results: any[] }>(
        (type === 'edit' && id && tenant) ? [
            `${tagsUrl}/keyValue/values`,
            {
                top: 25,
                skip: 0,
            },
            organizationName,
            tenant.name,
            id,
            accountKey,
        ] : null,
        (_url: string, pagination: IPagination, organizationName: string, tenantName: string, key: string, selectedAccountId: string) =>
            getValuesForKey(pagination, organizationName, tenantName, key, selectedAccountId),
    );
    const {
        control, handleSubmit, getValues, setValue, reset, formState,
    } = useForm<ITagPropertyDrawerDto>({
        mode: 'onChange',
        defaultValues: DEFAULT_VALUES,
    });

    const { isSubmitting } = formState;

    useEffect(() => {
        if (contextData.Values?.length && !isSubmitting) {
            reset({
                Values: contextData.Values.map(keyValue => ({
                    id: keyValue.id ?? '',
                    type: 'loading',
                    value: keyValue.normalizedValue,
                    data: keyValue,
                })),
            });
        }
    }, [ contextData, isSubmitting, reset, setValue ]);

    const createNotification = useCallback(
        (message: string, messageType = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: messageType });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(async (data: ITagPropertyDrawerDto) => {
        try {
            const dataStrings = data.Values.map(obj => obj.value);
            const hasDuplicateInData = dataStrings.some((val, i) => dataStrings.indexOf(val) !== i);

            const propertyValuesString = propertyValues?.results.map(obj => obj.displayValue) ?? [];
            const hasDuplicateInExisting = propertyValuesString.some(val => dataStrings.indexOf(val) >= 0);

            let proceed = true;
            if (hasDuplicateInData || hasDuplicateInExisting) {
                proceed = await createDialog({
                    title: translate({ id: 'CLIENT_TENANT_TAGS_VALUES_DUPLICATE_WARNING_TITLE' }),
                    customDialogContent: ValuesDuplicateWarningDialogBody,
                    customDialogContentProps: { deleteType: 'Value' },
                    icon: 'warning',
                });
            }
            if (!proceed) {
                return;
            }
            if (type === 'add') {
                setData({
                    Values: data.Values.map(chip => chip.data ?? ({
                        id: chip.id,
                        key: chip.id,
                        displayValue: chip.value,
                        normalizedValue: chip.value,
                    })),
                    DataType: contextData.DataType,
                    Refresh: true,
                });
            } else {
                await createValuesForKey(
                    organizationName,
                    tenant?.name ?? '',
                    id,
                    {
                        labelKey: id,
                        values: data.Values.map(chip => chip.value),
                        accountKey,
                        tenantKey: tenantId,
                    },
                    accountKey,
                );

                setData({
                    ...contextData,
                    Refresh: true,
                });

                logEvent('PortalTagManagement.EditValues');
            }

            close();
        } catch (e) {
            createNotification(
                translate({ id: 'CLIENT_FAILED_TO_ADD_VALUES_TO_KEY' }),
                notificationType.ERROR,
            );
        }
    }, [
        accountKey,
        close,
        contextData,
        createDialog,
        createNotification,
        id,
        logEvent,
        organizationName,
        propertyValues?.results,
        setData,
        tenant?.name,
        tenantId,
        translate,
        type,
    ]);

    const portalObjectPickerCallback = useCallback((ref: HTMLPortalObjectPickerElement | null) => {
        ref?.addEventListener('objectPickerChanged', (event: any) => {
            const prevWithoutChip =
                    getValues('Values')?.map(chip => {
                        if (typeof chip === 'string') {
                            return chip;
                        }
                        return omit(chip, [ 'optionId', 'chipType' ]);
                    }) ?? [];
            const curWithoutChip = event.detail.data.map((chip: any) => omit(chip, [ 'optionId', 'chipType' ]));
            const valuesChanged = !isEqual(prevWithoutChip, curWithoutChip);
            setValue('Values', event.detail.data, {
                shouldDirty: valuesChanged,
                shouldValidate: valuesChanged,
            });
        });
    }, [ getValues, setValue ]);

    const validateValue = useCallback((value) => {
        if (value && value.length > 0) {
            switch (contextData.DataType) {
                case 'Number':
                    return validateNumber(value);
                case 'Regex':
                    return contextData.Regex && validateRegex(contextData.Regex, value);
                default:
                    return validateString(value);
            }
        }
        return true;
    }, [ contextData ]);

    const errorMessage = useMemo(() => {
        switch (contextData.DataType) {
            case 'Number':
                return translate({ id: 'CLIENT_ADD_VALUE_INVALID_NUMBER_ERROR' });
            case 'Regex':
                return translate({ id: 'CLIENT_ADD_VALUE_INVALID_REGEX_ERROR' });
            default:
                return translate({ id: 'CLIENT_ADD_VALUE_INVALID_STRING_ERROR' });
        }
    }, [ contextData.DataType, translate ]);

    return {
        loading: tenantLoading || valuesLoading,
        propertyValues: propertyValues?.results ?? [],
        open,
        close,
        portalObjectPickerCallback,
        methods: {
            control,
            handleSubmit: handleSubmit(onSubmit),
            formState,
        },
        errorMessage,
        hasError,
        setHasError,
        validateValue,
        onSubmit,
        regex: contextData.Regex,
        dataType: contextData.DataType,
    };

};

export default useAddEditTenantTagsPropertiesDrawerViewModel;
