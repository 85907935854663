import type { TaskOdata } from '../../common/interfaces/task';
import { get } from '../utility/Requests.default';

const taskUrl = `/api/orchestrator/tasks`;

export default function getTask(tenantName: string | null, accountLogicalName: string, filter: string) {
    return get<TaskOdata>(taskUrl, {
        urlParams: {
            tenantName,
            filter,
            accountLogicalName,
        },
    });
}
