import {
    CommandTableOperationConstants,
    OperationTypeConstants,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    ITableContext,
    TenantOperation,
} from '@experiences/interfaces';
import { portalTelemetry } from '@experiences/telemetry';
import {
    commandTableOperationResult,
    useAuthContext,
    useInterval,
} from '@experiences/util';
import { useSnackbar } from 'notistack';
import React, {
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { mutate } from 'swr';

import { notificationType } from '../../common/constants/Constant';
import { triggerPortalShellRefresh } from '../../common/hooks/triggerPortalShellRefresh';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { leaseUrl } from '../../services/licensing/accountant/LeaseService';
import { organizationManagementTenantUri } from '../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../store/selectors';
import { serviceInstanceUrl } from './TenantsContextProvider';

const TenantOperationTrackerContext = createContext<ITableContext>({
    addTenantOperation: (_operationID: string, _tenantName: string, _tenantID: string, _operationType: string) => {},
    refreshAfterComplete: (_tenantId?: string, _triggerRefresh?: boolean) => {},
    checkOperationList: (_tenantId: string) => false,
});

export const useTenantOperationTrackerContext = () => useContext(TenantOperationTrackerContext);

export const TenantOperationTrackerContextProvider: React.FC = props => {

    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const [ tenantOperationsList, setTenantOperationsList ] = useState([] as TenantOperation[]);
    const { token } = useAuthContext();

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const addTenantOperation = useCallback((operationID: string, tenantName: string, tenantID: string, operationType: string) => {
        setTenantOperationsList(tenantOperationsList.concat({
            operationID,
            tenantName,
            tenantID,
            operationType,
        }));
    }, [ tenantOperationsList ]);

    const checkOperationList = useCallback((tenantID: string) =>
        tenantOperationsList.some(operation => operation.tenantID === tenantID)
    , [ tenantOperationsList ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const EnableGetTenantsRedesign = useFeatureFlagValue(Features.EnableGetTenantsRedesign.name);

    const partitionGlobalId = useSelector(accountGlobalId);
    const accountName = useSelector(accountLogicalName);

    const refreshAfterComplete = useCallback((tenantId?: string, triggerRefresh = true) => {
        if (triggerRefresh) {
            triggerPortalShellRefresh();
        }
        if (tenantId) {
            mutate([ `${organizationManagementTenantUri}/tenantById`, tenantId ]);
        }
        mutate(
            !EnableGetTenantsRedesign
                ? [ serviceInstanceUrl, partitionGlobalId, accountName, true ]
                : [ serviceInstanceUrl, partitionGlobalId, accountName, 'All', undefined, undefined, true ],
        );
        mutate(leaseUrl);
        mutate(!EnableGetTenantsRedesign
            ? [ organizationManagementTenantUri, partitionGlobalId, accountName, true ]
            : [ organizationManagementTenantUri, partitionGlobalId, accountName, 'Enabled' ]);

    }, [ EnableGetTenantsRedesign, accountName, partitionGlobalId ]);

    useInterval(async () => {
        tenantOperationsList.forEach(async (currentOperation) => {
            try {
                const operationStatus = await commandTableOperationResult(currentOperation.operationID, partitionGlobalId, token);
                if (operationStatus) {
                    refreshAfterComplete(currentOperation.tenantID, currentOperation.operationType === OperationTypeConstants.TENANT);
                    currentOperation.operationType === OperationTypeConstants.ORGANIZATION
                        ? createNotification(translate({ id: `CLIENT_ORGANIZATION_${operationStatus.toUpperCase()}` }, { 0: currentOperation.tenantName }),
                            operationStatus === CommandTableOperationConstants.FAILED ? notificationType.ERROR : notificationType.SUCCESS)
                        : createNotification(translate({ id: `CLIENT_TENANT_${operationStatus.toUpperCase()}` }, { 0: currentOperation.tenantName }),
                            operationStatus === CommandTableOperationConstants.FAILED ? notificationType.ERROR : notificationType.SUCCESS);
                    setTenantOperationsList(prevList => prevList.filter((operationIterator) =>
                        operationIterator.operationID !== currentOperation.operationID));
                }
            } catch (error) {
                portalTelemetry.trackTrace(
                    { message: `Failed to retrieve information on ID - ${currentOperation.operationID} from Command Table: ${error}` },
                    { revampEnabled: isAdminRevampEnabled },
                );
                setTenantOperationsList(prevList => prevList.filter((operationIterator) =>
                    operationIterator.operationID !== currentOperation.operationID));
            }
        });
    }, tenantOperationsList ? 10000 : null);

    return (
        <TenantOperationTrackerContext.Provider
            value={{
                addTenantOperation,
                refreshAfterComplete,
                checkOperationList,
            }}
        >
            {props.children}
        </TenantOperationTrackerContext.Provider>
    );

};
