import type { ILabelModelObject } from '@experiences/interfaces';
import { PermissionType } from '@experiences/interfaces';
import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    MenuItem,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { History } from 'history';
import React from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../../../common/constants/RouteNames';
import UiMenu from '../../../common/UiMenu/UiMenu';
import { hasPermission } from '../../addedit/properties/TenantTagsUtil';
import { useTagsDisabled } from '../../common/useTagsDisabled';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        deleteButton: { color: theme.palette.semantic.colorErrorText },
        menuItem: { '&:focus-visible': { backgroundColor: theme.palette.semantic.colorHover } },
    }),
}));

const TenantTagsPropertiesMoreActions: React.FC<{
    getRoute: (route: string, customAccountName?: string | undefined) => string;
    history: History;
    row: ILabelModelObject;
    tenantId: string;
    permissions?: PermissionType[];
    onDelete: (rows: ILabelModelObject) => Promise<void>;
}> = ({
    getRoute,
    history,
    row,
    tenantId,
    permissions,
    onDelete,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const { disabledToolTipMessage } = useTagsDisabled();

    return (<UiMenu
        trigger={<Tooltip
            title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
            placement='right'>
            <IconButton
                aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                data-cy="ap-data-grid-show-more-actions-button"
            >
                <MoreVertIcon className={classes.icon} />
            </IconButton>
        </Tooltip>}
        menu={[
            <Tooltip
                tabIndex={0}
                key='editKeyDetails'
                title={!hasPermission(permissions, [ PermissionType.View, PermissionType.Edit ])
                    ? disabledToolTipMessage(permissions, [ PermissionType.View, PermissionType.Edit ])
                    : undefined}>
                <div className={classes.menuItem}>
                    <MenuItem
                        disabled={!hasPermission(permissions, [ PermissionType.View, PermissionType.Edit ])}
                        onClick={() => history.push(getRoute(process.buildConfigs.showForMSI
                            ? `${RouteNames.TenantTagsPropertiesMsi}/edit/details/${row?.key}`
                            : `${RouteNames.TenantTagsProperties}/edit/details/${row?.key}`.replace(':tenantId', tenantId)))}
                        data-cy='ap-grid-row-edit-details'
                    >
                        {translate({ id: 'CLIENT_EDIT_DETAILS' })}
                    </MenuItem>
                </div>
            </Tooltip>,
            <Tooltip
                tabIndex={0}
                key='editKeyValues'
                title={!hasPermission(permissions, [ PermissionType.View, PermissionType.Edit ])
                    ? disabledToolTipMessage(permissions, [ PermissionType.View, PermissionType.Edit ])
                    : undefined}>
                <div className={classes.menuItem}>
                    <MenuItem
                        disabled={!hasPermission(permissions, [ PermissionType.View, PermissionType.Edit ])}
                        onClick={() => history.push(getRoute(process.buildConfigs.showForMSI
                            ? `${RouteNames.TenantTagsPropertiesMsi}/edit/keyValues/${row?.key}`
                            : `${RouteNames.TenantTagsProperties}/edit/keyValues/${row?.key}`.replace(':tenantId', tenantId)))}
                        data-cy='ap-grid-row-edit-values'
                    >
                        {translate({ id: 'CLIENT_MANAGE_VALUES' })}
                    </MenuItem>
                </div>
            </Tooltip>,
            <Tooltip
                tabIndex={0}
                key='deleteKey'
                title={!hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ])
                    ? disabledToolTipMessage(permissions, [ PermissionType.View, PermissionType.Delete ])
                    : undefined}>
                <div className={classes.menuItem}>
                    <MenuItem
                        className={classes.deleteButton}
                        disabled={!hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ])}
                        onClick={() => onDelete(row)}
                        data-cy='ap-grid-row-delete'
                    >
                        {translate({ id: 'CLIENT_DELETE' })}
                    </MenuItem>
                </div>
            </Tooltip>,
        ]} />);
};
export default TenantTagsPropertiesMoreActions;
