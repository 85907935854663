import React from 'react';
import { useIntl } from 'react-intl';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import ExternalAppsTabs from './subcomponents/ExternalAppsTabs';

const ExternalApps: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.ExternalApplications,
            name: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                data-cy="external-apps-panel"
                position='left'
            >
                <ExternalAppsTabs />
            </UiPageContainer> :
            <UiPanel
                header={{ title: translate({ id: 'CLIENT_EXTERNAL_APPLICATIONS' }) }}
                data-cy="external-apps-panel"
            >
                <ExternalAppsTabs />
            </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <ExternalApps />
        {renderRoutes(route.routes)}
    </>
);
