import { UiErrorBoundary } from '@experiences/ui-common';
import CloseIcon from '@mui/icons-material/Close';
import type {
    DrawerProps,
    Theme,
} from '@mui/material';
import {
    CircularProgress,
    Drawer,
    IconButton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import { useIntl } from 'react-intl';

import type { Gutter } from '../UiForm';

interface IUiDrawerThemeProps {
    disableGutters?: Gutter[];
    width?: 'small' | 'medium' | 'large';
}

const useStyles = makeStyles<Theme, IUiDrawerThemeProps>(theme =>
    createStyles({
        root: {
            height: '100%',
            width: ({ width }: any) => {
                switch (width) {
                    case 'small':
                        return '344px';
                    case 'medium':
                        return '392px';
                    case 'large':
                        return '688px';
                    default:
                        return '344px';
                }
            },
            display: 'flex',
            flexDirection: 'column',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 12px 0px 24px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            wordWrap: 'break-word',
            width: 'calc(100% - 40px)',
        },
        iconContainer: {
            marginBottom: '4px',
            alignSelf: 'flex-start',
        },
        closeIcon: {
            height: 16,
            width: 16,
        },
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
        error: {
            display: 'flex',
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        paper: {
            backgroundImage: 'none',
            overflowY: 'hidden',
        },
        content: ({ disableGutters }) => ({
            width: 'inherit',
            height: `calc(100% - ${disableGutters?.includes('bottom') ? '46px' : '70px'})`,
            paddingTop: disableGutters?.includes('top') ? '0px' : '24px',
            paddingRight: disableGutters?.includes('right') ? '0px' : '24px',
            paddingBottom: disableGutters?.includes('bottom') ? '0px' : '24px',
            paddingLeft: disableGutters?.includes('left') ? '0px' : '24px',
        }),
    }),
);

interface IErrorObject {
    message: string;
    showError: boolean;
}

export const UiDrawer: React.FC<{
    title: string;
    children?: React.ReactNode;
    drawerProps?: DrawerProps;
    themeProps?: Omit<IUiDrawerThemeProps, 'width'>;
    width?: 'small' | 'medium' | 'large';
    loading?: boolean;
    error?: IErrorObject;
}> = ({
    title, children, drawerProps, themeProps, width = 'small', loading, error,
}) => {
    const classes = useStyles({
        ...themeProps,
        disableGutters: themeProps?.disableGutters ?? [ 'top', 'bottom' ],
        width,
    });
    const { formatMessage: translate } = useIntl();

    const [ , forceUpdate ] = useReducer(x => x + 1, 0);

    // forces a re-render of the drawer on mount, this is required to
    // force the "drawer opening" animation for components that don't
    // already do a re-render on mount (e.g. via reset from react-hook-forms)
    useEffect(() => {
        // @ts-ignore
        forceUpdate(0);
    }, []);

    const Content = useMemo(() => {
        if (error?.showError) {
            return (
                <div
                    className={classes.error}
                    data-cy="drawer-error">
                    <Typography>
                        {error?.message || 'Error'}
                    </Typography>
                </div>
            );
        } else if (loading) {
            return (
                <>
                    <div style={{ flex: '1 1 100%' }} />
                    <div className={classes.loading}>
                        <CircularProgress />
                    </div>
                </>
            );
        }
        return <div className={classes.content}>
            {children}
        </div>;

    }, [ error, loading, children, classes ]);

    return (
        <Drawer
            {...drawerProps}
            classes={{ paper: classes.paper }}
            style={{ whiteSpace: 'pre-wrap' }}
            role="main"
        >
            <div
                className={classes.root}
                role="dialog"
                aria-label={translate({ id: 'CLIENT_DRAWER' }, { 0: title })}>
                <div className={classes.header}>
                    <Typography
                        className={classes.headerText}
                        data-cy="ui-drawer-title"
                        role="heading"
                        aria-level={1}
                    >
                        {title}
                    </Typography>
                    <IconButton
                        onClick={() => drawerProps?.onClose && drawerProps.onClose({}, 'escapeKeyDown')}
                        className={classes.iconContainer}
                        aria-label={translate({ id: 'CLIENT_CLOSE' })}
                        data-cy="close-button"
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                </div>
                <UiErrorBoundary>
                    {Content}
                </UiErrorBoundary>
            </div>
        </Drawer>
    );
};
