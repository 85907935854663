import { EventsHubConnectionsString } from '@experiences/constants';
import { useLocalizedLinks } from '@experiences/util';
import {
    FormHelperText,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { AzEventHubsConnStrPattern } from '../constant/InsightsLogExportConstant';
import type { AzureEventHubConnDetails } from './ConnectionDetailsType';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

export const AzureEventHubInputs: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();
    const {
        control, errors,
    } = useFormContext<AzureEventHubConnDetails>();

    return (
        <div className={clsx(classes.input)}>
            <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                {translate({ id: 'CLIENT_AZURE_EVENT_HUBS_CONNECTION_STRING' })}
            </Typography>
            <Controller
                as={TextField}
                rules={{
                    required: true,
                    pattern: AzEventHubsConnStrPattern,
                }}
                control={control}
                type="password"
                name="endpoint"
                data-cy="logs-connection-endpoint"
                variant="outlined"
                error={!!errors.endpoint}
                fullWidth
                inputProps={
                    {
                        className: 'Tall',
                        'aria-label': translate({ id: 'CLIENT_AZURE_EVENT_HUBS_CONNECTION_STRING' }),
                    }
                }
            />
            <FormHelperText className={clsx(errors.endpoint?.type === 'pattern' ? classes.breakAll : '')}>
                {errors.endpoint?.type === 'required'
                    ? translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                    : errors.endpoint?.type === 'pattern'
                        ? translate({ id: 'CLIENT_INSIGHTS_CONNECTION_PATTERN_ERROR' })
                        : translate(
                            { id: 'CLIENT_INSIGHTS_CONNECTION_INFO' },
                            {
                                azureDocumentationLink: (
                                    <Link
                                        href={getLocalizedLink(EventsHubConnectionsString)}
                                        rel="noreferrer"
                                        target="_blank"
                                        underline='none'
                                    >
                                        {translate({ id: 'CLIENT_INSIGHTS_AZURE_DOCUMENTATION' })}
                                    </Link>
                                ),
                            },
                        )}
            </FormHelperText>
        </div>
    );
};
