import { createReducer } from 'redux-act';

import immerReducer from '../../common/immerReducer';
import {
    getAdminToggleFromLocalStorage,
    ToggleAdminRevamp,
} from '../action/AdminRevampToggleAction';

const currentSavedState = getAdminToggleFromLocalStorage();
const initialState = { adminRevampToggle: currentSavedState };

const reducer = createReducer({}, initialState);
reducer.on(
    ToggleAdminRevamp,
    immerReducer(state => {
        state.adminRevampToggle = !state.adminRevampToggle;
        return state;
    }),
);

export default reducer;
