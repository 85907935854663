import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { ISetting } from '../../services/identity/SettingService';
import {
    getSetting,
    settingUrl,
} from '../../services/identity/SettingService';
import { getUser } from '../../services/identity/UserService';
import {
    accountGlobalId,
    userGlobalId,
} from '../../store/selectors';
import { mapSettingArrayToRestrictBasicAuthentication } from '../../util/setting/AuthSettingUtil';
import { AuthSettingKey } from '../constants/AuthSettingConstant';
import type { IUserCIS } from '../interfaces/cis/user';

const useIsBasicAuthEnabled = (): boolean => {
    const partitionGlobalId = useSelector(accountGlobalId);
    const keys = useMemo(() => [ AuthSettingKey.RestrictBasicAuthentication ], []);
    const userId = useSelector(userGlobalId);
    const { data: user } = useSWR<IUserCIS, Error>([ userId ], getUser);
    const { data: fetchedSettings } = useSWR<ISetting[], Error>([ settingUrl, keys, partitionGlobalId ], getSetting, { shouldRetryOnError: false });
    const restrictBasicAuthentication = useMemo(
        () => mapSettingArrayToRestrictBasicAuthentication(fetchedSettings),
        [ fetchedSettings ],
    );
    return useMemo(
        () => !restrictBasicAuthentication || user?.bypassBasicAuthRestriction || false,
        [ restrictBasicAuthentication, user?.bypassBasicAuthRestriction ],
    );
};

export default useIsBasicAuthEnabled;
