import { useEcommerceTelemetry } from '@experiences/ecommerce';
import { useApolloTheme } from '@experiences/theme';
import {
    Chip,
    CircularProgress,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, {
    useEffect,
    useMemo,
} from 'react';
import {
    FormattedDate,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import type { ILicenseAndKey } from '../../common/interfaces/licenses';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import {
    countrySelector,
    isAdminSelector,
    isUnlicensedSelector,
    profile,
} from '../../store/selectors';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import EcommerceLicenseInfo from '../ecommerce/subcomponents/EcommerceLicenseInfo';
import { EcommerceLicensePaymentPendingBanner } from '../ecommerce/subcomponents/EcommerceLicensePaymentPendingBanner';
import { EcommerceLicensePendingChangeBanner } from '../ecommerce/subcomponents/EcommerceLicensePendingChangeBanner';
import { EcommercePriceChangesBanner } from '../ecommerce/subcomponents/EcommercePriceChangesBanner';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import LicenseHeaderActionComponent from './LicenseHeaderActionComponent.default';
import { UnlicensedComponent } from './Unlicensed';
import { UserLicensingTabs } from './UserLicensingTabs';
import { useShouldShowManageButton } from './useShouldShowManageButton';

const useStyles = makeStyles(theme =>
    createStyles({
        expiry: {
            marginTop: '8px',
            display: 'flex',
            minHeight: '28px',
            alignItems: 'center',
        },
        header: {
            display: 'flex',
            alignItems: 'center',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '18px',
        },
        chip: {
            background: `${theme.palette.semantic.colorBackgroundHigh} !important`,
            height: '24px',
            marginLeft: '4px',
        },
        chipLabel: { color: theme.palette.semantic.colorForegroundHigh },
    }),
);

const LicensesComponent = () => {
    const classes = useStyles();
    const { themeId } = useApolloTheme();
    const { formatMessage: translate } = useIntl();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const logEcommerceEvent = useEcommerceTelemetry();

    const isAdmin = useSelector(isAdminSelector);
    const accountCountry = useSelector(countrySelector);
    const profileState = useSelector(profile);
    const organizationName = useOrganizationName();

    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);

    const ExpiryDate = useMemo(() => (license?.endDate ? new Date(license.endDate * 1000) : undefined), [ license ]);

    const shouldShowManageButton = useShouldShowManageButton(
        profileState.accountUserDto.accountLogicalName,
        license?.accountType,
    );

    const [ LicenseStatusOrPlan, ExpiryMessage ] = useMemo(() => {
        switch (license?.licenseStatus) {
            case 'EXPIRED':
                return [ 'LICENSE_EXPIRED', 'LICENSE_EXPIRED_DATE' ];
            default:
                return [ license?.accountType, 'SUBSCRIPTION_EXPIRY' ];
        }
    }, [ license ]);

    useEffect(() => {
        if (license?.accountType && license.accountType !== profileState.accountUserDto.accountType) {
            mutate(licenseManagementAccountUrl);
        }
    }, [ license?.accountType, profileState ]);

    useEffect(() => {
        logEcommerceEvent('Licenses.Navigate', {
            IsAdmin: isAdmin,
            CloudOrganizationCountry: accountCountry,
        }, false);
    }, [ logEcommerceEvent, isAdmin, accountCountry ]);

    const licenseChip = useMemo(() => <>
        {LicenseStatusOrPlan &&
            <Chip
                style={{ marginRight: '8px' }}
                label={
                    <Typography
                        data-cy="licenses-account-plan"
                        className={classes.chipLabel}
                        style={{ color: themeId === 'dark' ? Tokens.Colors.ColorOrange300 : Tokens.Colors.ColorOrange700 }}
                    >
                        {translate({ id: `CLIENT_${LicenseStatusOrPlan?.toUpperCase()}_PLAN` })}
                    </Typography>
                }
                className={classes.chip}
            />}
    </>, [ LicenseStatusOrPlan, classes.chip, classes.chipLabel, themeId, translate ]);

    const ecommerceAndExpiryComponent = useMemo(() => <>
        {shouldShowManageButton === undefined ? (
            <CircularProgress
                size={20}
                aria-label="circular-progress" />
        ) : (
            (shouldShowManageButton) ? (<>
                {license && (<EcommerceLicenseInfo />)}
            </>) : (
                <div className={classes.expiry}>
                    {isAdminRevampEnabled && licenseChip}
                    {ExpiryDate &&
                        <Typography>
                            {translate({ id: `CLIENT_${ExpiryMessage}` })}
                        </Typography>}
                    <div style={{ marginLeft: '8px' }}>
                        {ExpiryDate && (
                            <Typography data-cy="licenses-expiry-date">
                                <FormattedDate
                                    value={Date.UTC(ExpiryDate.getUTCFullYear(), ExpiryDate.getUTCMonth(), ExpiryDate.getUTCDate())}
                                    year="numeric"
                                    month="short"
                                    day="numeric"
                                    timeZone="UTC"
                                />
                            </Typography>
                        )}
                    </div>
                </div>)
        )}
        <UserLicensingTabs />
    </>, [
        shouldShowManageButton,
        license,
        classes.expiry,
        isAdminRevampEnabled,
        licenseChip,
        translate,
        ExpiryMessage,
        ExpiryDate,
    ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.Licensing,
            name: translate({ id: 'CLIENT_LICENSES' }),
        },
    ];

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                banner={
                    <>
                        <EcommerceLicensePendingChangeBanner />
                        <EcommercePriceChangesBanner />
                        <EcommerceLicensePaymentPendingBanner />
                    </>
                }
                position='left'
                primaryActions={isAdmin && <LicenseHeaderActionComponent />}>
                {ecommerceAndExpiryComponent}
            </UiPageContainer> :
            <UiPanel
                licenseInGracePeriodBanner={<UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={<UiLicenseExpiredBanner />}
                trialPerSkuLicenseInGracePeriodBanners={<UiTrialPerSkuLicenseInGracePeriodBanners />}
                licenseOverAllocationBanner={<UiOverAllocationBanner />}
                licensePendingChangeBanner={<EcommerceLicensePendingChangeBanner />}
                licensePaymentPendingBanner={<EcommerceLicensePaymentPendingBanner />}
                header={{
                    title: (
                        <div className={classes.header}>
                            <Typography
                                className={classes.headerText}
                                role="heading"
                                aria-level={1}>
                                {translate({ id: 'CLIENT_LICENSES' })}
                            </Typography>
                            {licenseChip}
                        </div>
                    ),
                    actions: isAdmin && <LicenseHeaderActionComponent />,
                    disableGutter: true,
                }}
            >
                {ecommerceAndExpiryComponent}
            </UiPanel>
    );
};

const Licenses = ({ route }: { route?: any }) => {
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    return (
        <>
            {isUnlicensedMode ? <UnlicensedComponent showSubscription /> : <LicensesComponent />}
            {renderRoutes(route.routes)}
        </>
    );
};

export default Licenses;
