import { useRouteMatch } from 'react-router';

import * as RouteNames from '../constants/RouteNames';

const useIsProfileRoute = () => !!useRouteMatch([
    RouteNames.Profile,
    RouteNames.ThemeSettings,
    RouteNames.NavigationSettings,
    RouteNames.NotificationSettings,
    RouteNames.AddNotificationSettingsProfile,
    RouteNames.PrivacyAndSecuritySettings,
]);

export default useIsProfileRoute;
