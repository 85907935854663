import {
    CircularProgress,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import useOrganizationAccessPolicySettingViewModel, { AccessPolicyType } from './OrganizationAccessPolicySettingViewModel';

const useStyles = makeStyles(theme => createStyles({
    loading: { margin: 'auto' },
    page: {
        width: '50%',
        marginBottom: '18px',
    },
    headerText: {
        maginBottom: '18px',
        color: theme.palette.semantic.colorForegroundDeEmp,
        fontWeight: 600,
        fontSize: '14px',
    },
    label: {
        color: theme.palette.semantic.colorForeground,
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
    },
    description: {
        color: theme.palette.semantic.colorForegroundDeEmp,
        marginBottom: '14px',
        marginLeft: '32px',
    },
}));

const OrganizationAccessPolicySettingComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        loading, accessPolicySettingState, setAccessPolicySettingState, saveAccessPolicySetting,
    } = useOrganizationAccessPolicySettingViewModel();

    if (loading) {
        return <CircularProgress className={classes.loading} />;
    }

    return <div className={classes.page}>
        <Typography className={classes.headerText}>
            {translate({ id: 'CLIENT_ACCESS_POLICY_DESCRIPTION' })}
        </Typography>
        <RadioGroup
            value={accessPolicySettingState}
            onChange={async e => {
                setAccessPolicySettingState(e.target.value === 'true');
                await saveAccessPolicySetting(e.target.value === 'true');
            }}
        >
            <FormControlLabel
                control={<Radio data-cy="access-policy-everyone-toggle" />}
                value={AccessPolicyType.Everyone}
                color="primary"
                label={
                    <Typography className={classes.label}>
                        {translate({ id: 'CLIENT_EVERYONE' })}
                        <span>
                            {` (${(translate({ id: 'CLIENT_DEFAULT' }))})`}
                        </span>
                    </Typography>
                }
                data-cy="access-policy-everyone"
            />
            <Typography className={classes.description}>
                {translate({ id: 'CLIENT_EVERYONE_DESCRIPTION' })}
            </Typography>
            <FormControlLabel
                control={<Radio data-cy="access-policy-only-allowed-toggle" />}
                value={AccessPolicyType.Restricted}
                color="primary"
                label={
                    <Typography className={classes.label}>
                        {translate({ id: 'CLIENT_ONLY_ALLOWED_MEMBERS' })}
                    </Typography>
                }
                data-cy="access-policy-only-allowed"
            />
            <Typography className={classes.description}>
                {translate({ id: 'CLIENT_ONLY_ALLOWED_MEMBERS_DESCRIPTION' })}
            </Typography>
        </RadioGroup>
    </div>;
};

export default OrganizationAccessPolicySettingComponent;
