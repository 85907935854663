import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiDialog } from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';

const AccountLogicalNameWarningDialogComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const history = useHistory();
    const getRoute = useRouteResolver();
    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    const close = useCallback(
        (proceed = false) =>
            history.push({
                pathname: getRoute(RouteNames.OrganizationSettings),
                state: { proceed },
            }),
        [ getRoute, history ],
    );

    const warnAndClose = useCallback(async () => {
        close(true);
    }, [ close ]);

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_CHANGE_SITE_URL' })}
            actions={[
                <Button
                    key="cancel"
                    onClick={() => close()}
                    data-cy="logical-name-change-cancel-button"
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    key="skip"
                    variant="contained"
                    onClick={warnAndClose}
                    data-cy="logical-name-change-submit-button">
                    {translate({ id: 'CLIENT_PROCEED' })}
                </Button>,
            ]}
            close={() => close()}
        >
            <Typography>
                <FormattedMessage
                    id={
                        process.buildConfigs.disableUserInvite || DisableFeatureFedRamp
                            ? 'CLIENT_URL_CHANGE_WARNING_NO_INVITE'
                            : 'CLIENT_URL_CHANGE_WARNING'
                    }
                    values={{
                        strong: (chunk: string) => <strong>
                            {chunk}
                        </strong>,
                    }}
                />
            </Typography>
        </UiDialog>
    );
};

export default AccountLogicalNameWarningDialogComponent;
