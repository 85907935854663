import type { ICard } from '@experiences/interfaces';
import { useRouteResolver } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    useHistory,
    useRouteMatch,
} from 'react-router';
import { renderRoutes } from 'react-router-config';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import licensesAdmin from '../../images/licensesAdmin.svg';
import settingsAdmin from '../../images/settingsAdmin.svg';
import {
    getAllOnPremOrganizations,
    onPremOrganizationManagementUri,
} from '../../services/organization/OnPremOrganizationService';
import {
    accountType,
    isHostModeSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { UiCard } from '../common/UiCard';
import UiCardGrid from '../common/UiCardGrid/UiCardGrid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import type { IOnPremOrganizationResponse } from '../organization/subcomponents/OrganizationGridComponent';
import AdminBreadCrumbs from './AdminBreadCrumbs';

const useStyles = makeStyles(() =>
    createStyles({
        cardIcon: {
            width: '30px',
            marginRight: '4px',
        },
    }),
);

const OrganizationHome: FC = () => {

    const classes = useStyles();
    const getRoute = useRouteResolver();
    const history = useHistory();
    const isLicenseExpired = useLicenseExpired();

    const isHost = useSelector(isHostModeSelector);
    const AccountType = useSelector(accountType);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);

    const match = useRouteMatch<{ organizationId: string }>();
    const { organizationId } = useMemo<{ organizationId: string }>(
        () => ({ organizationId: match.params.organizationId }),
        [ match ],
    );

    const { data: organizations } = useSWR<IOnPremOrganizationResponse>(
        !process.buildConfigs.hideForMSI ? [ `${onPremOrganizationManagementUri}/getAllOrganizations` ] : null,
        getAllOnPremOrganizations,
    );

    const organizationName = useMemo(() => organizations?.results.find(orgIterator => orgIterator.id === organizationId)?.name ?? ''
        , [ organizationId, organizations?.results ]);

    const AccountPlan = useMemo(() => {
        switch (AccountType) {
            case 'ENTERPRISE':
                return 'ENTERPRISE_PLAN';
            case 'TRIAL':
                return 'TRIAL_PLAN';
            case 'UNLICENSED':
                return 'UNLICENSED_PLAN';
            default:
                return 'COMMUNITY_PLAN';
        }
    }, [ AccountType ]);

    const cardList: ICard[] = useMemo(() => [
        {
            title: 'CLIENT_LICENSES',
            titleIcon: licensesAdmin,
            description: 'CLIENT_LICENSES_DESCRIPTION',
            onClickLink: getRoute(RouteNames.HostOrganizationLicense.replace(':organizationId', organizationId)),
            chipText: !isHost ? `CLIENT_${AccountPlan}` : '',
            dataCy: 'org-settings-licenses-card',
            disabled: isLicenseExpired || isUnlicensedMode,
        },
        {
            title: 'CLIENT_SETTINGS',
            titleIcon: settingsAdmin,
            description: 'CLIENT_SETTINGS_DESCRIPTION',
            onClickLink: getRoute(RouteNames.HostOrganizationSettings.replace(':organizationId', organizationId)),
            dataCy: 'org-settings-global-settings-card',
        },
    ], [ AccountPlan, getRoute, isHost, isLicenseExpired, isUnlicensedMode, organizationId ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationHome.replace(':organizationId', organizationId),
            name: organizationName,
        },
    ];

    return (
        <UiPageContainer
            header={AdminBreadCrumbs(breadCrumbLinks)}
            maxWidth='1100px'>
            <UiCardGrid
                maxCardWidth="338px"
                style={{
                    marginTop: '20px',
                    justifyItems: 'center',
                }}>
                {cardList.map((card, i) =>
                    !card.invisible &&
                    <UiCard
                        key={i}
                        title={card.title}
                        titleIcon={<img
                            className={classes.cardIcon}
                            src={card.titleIcon}
                            alt='accountsAndGroups'
                            style={{ width: '40px' }} />}
                        description={card.description}
                        disabled={card.disabled}
                        chipText={card.chipText}
                        onClickLink={() => history.push(card.onClickLink)}
                        style={{
                            height: '224px',
                            maxWidth: '338px',
                        }}
                        dataCy={card.dataCy} />,
                )}
            </UiCardGrid>
        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <OrganizationHome />
        {renderRoutes(route.routes)}
    </>
);
