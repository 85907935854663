import { AccountLicense } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    FormControlLabel,
    MenuItem,
    Select,
    Switch,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../../../common/hooks/useIsAdminRevampEnabled';
import type { IExportLogsData } from '../../../../services/insights/InsightsLogsExportService';
import {
    deleteLogsExportDetails,
    exportLogsUrl,
    getLogsExportDetails,
    postLogsExportDetails,
} from '../../../../services/insights/InsightsLogsExportService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    isAdminSelector,
} from '../../../../store/selectors';
import { useTelemetryHelper } from '../../../../telemetry/TelemetryHelper';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import {
    LogExportInsStorageType,
    logExportInsStorageTypeSelectItems,
    logExportInsStorageTypeSelectItemsOnPrem,
} from '../constant/InsightsLogExportConstant';
import {
    FormActionButtons,
    FormErrorHandler,
} from '../LogExportComponent';
import { AwsSqsInputs } from './AwsSqsInputs';
import { AzureEventHubInputs } from './AzureEventHubInputs';
import type { LogExportConnectionDetails } from './ConnectionDetailsType';
import { SplunkInputs } from './SplunkInputs';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: 20 },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        formButtons: { display: 'flex' },
        cancelButton: { marginRight: '10px' },
        menuItem: {
            display: 'flex',
            justifyContent: 'flex-start',
        },
        previewText: {
            fontSize: Tokens.FontFamily.FontSSize,
            marginLeft: Tokens.Spacing.SpacingXl,
            fontWeight: Tokens.FontFamily.FontWeightBold,
            color: theme.palette.text.secondary,
        },
    }),
);

const InsightsLogExportComponent: React.FC<{
    tenantId: string;
    tenantName: string;
}> = ({
    tenantId,
    tenantName,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ submitError, setSubmitError ] = useState('');
    const logicalName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);
    const currentAccountType = useSelector(accountType);
    const { logEvent } = useTelemetryHelper();
    const { enqueueSnackbar } = useSnackbar();
    const adminRevampEnabled = useIsAdminRevampEnabled();
    const isK8s = process.buildConfigs.name === 'k8s';
    const storageTypeToSelect = isK8s ? logExportInsStorageTypeSelectItemsOnPrem : logExportInsStorageTypeSelectItems;

    const {
        open, close,
    } = useModalState(adminRevampEnabled ? RouteNames.TenantServices.replace(':tenantId', tenantId) : RouteNames.Services);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();

    const createDialog = useShowDialog();

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const {
        data: blobFields,
        isValidating,
        error,
    } = useSWR<IExportLogsData>(
        [ exportLogsUrl, tenantId, accountId, logicalName, tenantName ],
        () => getLogsExportDetails({
            tenantId,
            accountId,
            accountLogicalName: logicalName,
            tenantName,
        }),
        { shouldRetryOnError: false }, // prevents loading screen showing if there is no config set up
    );

    const [ showStorageFields, setShowStorageFields ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ storageType, setStorageType ] = useState(LogExportInsStorageType.None);

    const methods = useForm<LogExportConnectionDetails>({
        mode: 'onChange',
        defaultValues: blobFields?.connectionDetails,
    });

    const {
        handleSubmit, formState, reset,
    } = methods;

    const {
        isDirty, isValid,
    } = formState;

    const selectedStorageType = useMemo(
        () => storageType === LogExportInsStorageType.None ? blobFields?.storageType as LogExportInsStorageType : storageType,
        [ storageType, blobFields ]);

    const isSubmitDisabled = useMemo(() => !isDirty || !isValid, [ isDirty, isValid ]);

    // When we switch to different storage type, the control will be set to dirty even no changes made on inputs, this is to clean it up
    useEffect(() => {
        if (!isValidating) {
            if (selectedStorageType === blobFields?.storageType) {
                reset(blobFields?.connectionDetails);
            } else {
                reset({} as LogExportConnectionDetails);
            }
        }
    }, [ selectedStorageType, reset, blobFields, isValidating ]);

    useEffect(() => {
        setShowStorageFields(!!blobFields?.storageType && !!blobFields?.connectionDetails);
    }, [ blobFields ]);

    const deleteConfig = useCallback(async () => {
        if (!blobFields?.connectionDetails) {
            setShowStorageFields(false);
            return;
        }

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_LOGS_DELETE' }),
            body: translate({ id: 'CLIENT_INSIGHTS_DELETE_DETAILS' }),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
        });
        if (proceed) {
            setLoading(true);
            try {
                await deleteLogsExportDetails({
                    tenantId,
                    accountId,
                    accountLogicalName: logicalName,
                    tenantName,
                });
                createNotification(translate({ id: 'CLIENT_INSIGHTS_DELETE_SUCCESS' }));
                setLoading(false);
                setShowStorageFields(false);
                setStorageType(LogExportInsStorageType.None);
                mutate(
                    [ exportLogsUrl, tenantId, accountId, logicalName, tenantName ],
                    {
                        tenantKey: tenantId,
                        storageType: undefined,
                        connectionDetails: {},
                    },
                    true,
                );
                reset({} as LogExportConnectionDetails);
            } catch (err) {
                createNotification(translate({ id: 'CLIENT_INSIGHTS_DELETE_ERROR' }));
                setLoading(false);
            }
        }
    }, [
        createDialog,
        createNotification,
        translate,
        accountId,
        logicalName,
        tenantName,
        tenantId,
        reset,
        blobFields?.connectionDetails,
    ]);

    const onSubmit = useCallback(
        async (data: LogExportConnectionDetails) => {
            setLoading(true);

            try {
                await postLogsExportDetails({
                    body: {
                        tenantKey: tenantId,
                        storageType: selectedStorageType,
                        connectionDetails: data,
                    },
                    tenantId,
                    accountId,
                    tenantName,
                    accountLogicalName: logicalName,
                });
                createNotification(translate({ id: 'CLIENT_INSIGHTS_DATA_SUCCESS' }, { 0: tenantName }));
                setLoading(false);
                close(true);
                logEvent('AdminInsightsExport.Success');
            } catch (err) {
                const errorObject = await getErrorObject(err);
                const errorMessage = errorObject.response?.data || (await getErrorMessage(errorObject));
                setSubmitError(errorMessage);
                setLoading(false);
                logEvent('AdminInsightsExport.Failed', { AdminInsightsExportError: errorMessage });
            }
        },
        [
            close,
            logicalName,
            createNotification,
            translate,
            getErrorMessage,
            getErrorObject,
            accountId,
            tenantId,
            tenantName,
            logEvent,
            selectedStorageType,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_CONFIGURATION' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => {
                    logEvent('AdminInsightsExport.Cancel');
                    close();
                },
            }}
            loading={isValidating}
        >
            <FormErrorHandler
                error={error}
                submitError={submitError}
                setSubmitError={setSubmitError}
                currentAccountType={currentAccountType}
            />
            {isAdmin &&
                    AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY &&
                    !submitError &&
                    (!error || error.response?.status === 404) && (
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <FormActionButtons
                            showActions={showStorageFields}
                            classes={classes}
                            loading={loading}
                            onSubmit={handleSubmit(onSubmit)}
                            isSubmitDisabled={isSubmitDisabled}
                            close={() => {
                                logEvent('AdminInsightsExport.Cancel');
                                close();
                            }}
                        />
                    }
                    isDrawer
                >
                    <div className={clsx(classes.input)}>
                        <Typography className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_INSIGHTS_DATA_EXPORT' })}
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showStorageFields}
                                    onClick={() => {
                                        if (showStorageFields) {
                                            deleteConfig();
                                        } else {
                                            setShowStorageFields(true);
                                        }
                                    }}
                                />
                            }
                            label={translate({ id: 'CLIENT_SEND_INSIGHTS_DATA_CUSTOM_STORAGE' })}
                            data-cy="logs-switch"
                        />
                    </div>
                    {showStorageFields && (
                        <>
                            <div className={clsx(classes.input)}>
                                <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                                    {translate({ id: 'CLIENT_STORAGE_TYPE' })}
                                </Typography>
                                <Select
                                    aria-label={translate({ id: 'CLIENT_STORAGE_TYPE' })}
                                    variant="outlined"
                                    value={selectedStorageType}
                                    fullWidth
                                    IconComponent={ExpandMoreIcon}
                                    onChange={(event) => setStorageType(event.target.value as LogExportInsStorageType)}
                                    data-cy="logs-types-select"
                                    MenuProps={
                                        { MenuListProps: { 'aria-label': translate({ id: 'CLIENT_STORAGE_TYPE' }) } }
                                    }
                                >
                                    {storageTypeToSelect.map((item, i) => (
                                        <MenuItem
                                            key={i}
                                            selected={selectedStorageType === item.id}
                                            value={item.id}
                                            data-cy={`logs-item-${item.id}`}
                                            aria-label={translate({ id: item.displayId })}
                                        >
                                            <div className={clsx(classes.menuItem)}>
                                                {translate({ id: item.displayId })}
                                                {
                                                    item.inPreview &&
                                                    <Typography className={clsx(classes.previewText)}>
                                                        {translate({ id: 'CLIENT_LOG_EXPORT_PREVIEW' })}
                                                    </Typography>
                                                }
                                            </div>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </div>
                            <FormProvider {...methods} >
                                {
                                    selectedStorageType === LogExportInsStorageType.AzureEventHub &&
                                    <AzureEventHubInputs />
                                }
                                {
                                    selectedStorageType === LogExportInsStorageType.AwsSqs &&
                                    <AwsSqsInputs />
                                }
                                {
                                    selectedStorageType === LogExportInsStorageType.Splunk &&
                                    <SplunkInputs />
                                }
                            </FormProvider>
                        </>
                    )}

                </UiForm>
            )}
        </UiDrawer>
    );
};

export default InsightsLogExportComponent;
