import type { ICurrentTrial } from '@experiences/interfaces';
import { useRouteResolver } from '@experiences/util';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';
import type { IDropdownMenuItem } from '../../common/UiDropdownButton/UiDropdownMenu';
import {
    UiDropdownMenuComponent,
    UiDropdownMenuType,
} from '../../common/UiDropdownButton/UiDropdownMenu';

export const CurrentTrialActionButtons: React.FC<{ currentTrial: ICurrentTrial; id: number }> = ({
    currentTrial: _currentTrial, id: _id,
}) => {
    const getRoute = useRouteResolver();
    const history = useHistory();
    const { formatMessage: translate } = useIntl();

    const updateLicenseCallback = useCallback<(trial: ICurrentTrial, isOnline: boolean) => void>(
        async (trial, isOnline) => {
            if (!trial.licenseCode) {
                return;
            }
            if (isOnline) {
                history.push({
                    pathname: `${getRoute(RouteNames.LicensingActivateLicenseOnline)}`,
                    state: {
                        previousLocation: location.pathname,
                        isUpdateLicense: true,
                        isEnterpriseActivation: false,
                        isTrialPerSkuActivation: true,
                        customLicenseCode: trial.licenseCode,
                    },
                });
            } else {
                history.push({
                    pathname: `${getRoute(RouteNames.ActivateOffline)}`,
                    state: {
                        previousLocation: location.pathname,
                        isUpdateLicense: true,
                        isTrialPerSkuActivation: true,
                        customLicenseCode: trial.licenseCode,
                    },
                });
            }
        }, [ getRoute, history ]);

    const getTrialActionsCallback = useCallback<(trial: ICurrentTrial, idx: number) => IDropdownMenuItem[]>(
        (trial, idx) =>
            [
                {
                    label: translate({ id: 'CLIENT_TRIAL_UPDATE_LICENSE' }),
                    tag: trial,
                    isGroupName: true,
                },
                {
                    label: translate({ id: 'CLIENT_TRIAL_UPDATE_LICENSE_ONLINE' }),
                    onClick: (mi: IDropdownMenuItem) => updateLicenseCallback(mi.tag as ICurrentTrial, true),
                    dataCy: `trial_update_license_online-${idx}`,
                    tag: trial,
                },
                {
                    label: translate({ id: 'CLIENT_TRIAL_UPDATE_LICENSE_OFFLINE' }),
                    onClick: (mi: IDropdownMenuItem) => updateLicenseCallback(mi.tag as ICurrentTrial, false),
                    dataCy: `trial_update_license_offline-${idx}`,
                    tag: trial,
                },
            ]
    , [ translate, updateLicenseCallback ]);

    return <UiDropdownMenuComponent
        menuItems={getTrialActionsCallback(_currentTrial, _id)}
        type={UiDropdownMenuType.Kebab}
        dataCy={`ui-show-more-actions-btn-${_id}`}
    />;
};
