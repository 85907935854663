import type { IPagination } from '@experiences/interfaces';

import type { IAuditLogResponse } from '../../component/audit/AuditPageComponent';
import {
    get,
    getFile,
} from '../utility/Requests.default';

export const auditUrl = process.buildConfigs.auditApiBaseRoute;

export function getAuditLogs(pagination: IPagination, language: string, partitionGlobalId: string) {
    return get<IAuditLogResponse>(`${auditUrl}/${partitionGlobalId}`, {
        urlParams: { language },
        pagination,
    });
}

export function downloadAuditLogs(language: string, partitionGlobalId: string) {
    return getFile(`${auditUrl}/${partitionGlobalId}/download`, { urlParams: { language } });
}
