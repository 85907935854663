import type {
    IEmailConfiguration,
    IFolderUpdatePayload,
    INotificationProfileResponse,
    IOrchestratorFolderEntity,
    IProfileUpdateSubscriptionPayload,
    IUpdateCategorySubscriptionModeRequestData,
    IUpdateUserNotificationSubscriptionModeRequestData,
    IUpdateUserSubscriptionsRequestData,
} from '../../component/notificationSettings/interfaces/notificationSettings';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

const initialApiPath = '/api/notificationSettings/userSubscription';
export const userNotificationSubscriptionUri = initialApiPath + '/';
const updateUserNotificationSubscriptionModeUri = initialApiPath + '/updateMode';
const resetUserNotificationSubscriptionUri = initialApiPath + '/reset';
const resetUserNotificationSeveritySubscriptionUri = initialApiPath + '/CategorySubscription';
export const SubscriptionProfileMetadata = initialApiPath + '/SubscriptionProfileMetadata';
export const SubscriptionProfile = initialApiPath + '/SubscriptionProfile';
const SubscriptionProfileReset = initialApiPath + '/SubscriptionProfile/Reset';
const UnsubscribeTenant = initialApiPath + '/UnsubscribeTenant';
export const UpdateDefaultSubscriptionUrl = initialApiPath + '/UpdateDefaultSubscription';
const resetUserNotificationPublisherSubscriptionUri = initialApiPath + '/PublisherSubscription';
export const folderNavigationUrl = '/api/notificationSettings/folderNavigation';
export const emailConfigurationUrl = initialApiPath + '/emailConfiguration';

const getHeaders = (selectedTenantId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
});
const getHeaderswithAccount = (selectedTenantId: string, selectedAccontId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
    'X-UiPath-Internal-AccountId': selectedAccontId,
});

export function getEmailConfiguration(requestUri: string, selectedTenantId: string, selectedAccontId: string) {
    return get<IEmailConfiguration>(requestUri, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function resetEmailConfiguration(requestUri: string, selectedTenantId: string) {
    return axiosDelete(requestUri, { headers: getHeaders(selectedTenantId) });
}
export function updateEmailConfiguration(requestUri: string,
    updateEmailConfigurationData: IEmailConfiguration,
    selectedTenantId: string) {
    return post<string>(requestUri, {
        body: { ...updateEmailConfigurationData },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateDefaultSubscription(requestUri: string, selectedTenantId: string) {
    return post<string>(requestUri, {
        body: { TenantId: selectedTenantId },
        headers: getHeaders(selectedTenantId),
    });
}

export function getFolderStructure(requestUri: string, selectedTenantId: string, urlTemplate: string,
    accountName: string, tenantName: string) {
    urlTemplate = urlTemplate.replace('{accountName}', accountName);
    urlTemplate = urlTemplate.replace('{tenantName}', tenantName);
    const headers = getHeaders(selectedTenantId);
    return get<IOrchestratorFolderEntity[]>(requestUri, {
        headers,
        urlParams: { urlTemplate },
    });
}

export function updateUserPublisherSubscriptionSubscription(
    updateUserSubscriptions: IFolderUpdatePayload,
    selectedTenantId: string) {
    return post<any>(resetUserNotificationPublisherSubscriptionUri, {
        body: { publisherSubscriptions: [ updateUserSubscriptions ] },
        headers: getHeaders(selectedTenantId),
    });
}

export function deleteProfile(profileName: string, selectedTenantId: string) {
    return axiosDelete(SubscriptionProfile + '?Name=' + profileName, { headers: getHeaders(selectedTenantId) });
}

export function getSubscriptionProfile(requestUri: string, selectedTenantId: string, selectedAccontId: string) {
    return get<any>(requestUri, { headers: getHeaderswithAccount(selectedTenantId, selectedAccontId) });
}

export function getSubscriptionProfiles(requestUri: string, selectedTenantId: string) {
    return get<INotificationProfileResponse>(requestUri, { headers: getHeaders(selectedTenantId) });
}

export function getUserNotificationSubscription(requestUri: string, selectedTenantId: string, publisherName: string) {
    return get<any>(requestUri, {
        headers: getHeaders(selectedTenantId),
        urlParams: { publisherName },
    });
}
export function updateProfileNotificationSubscription(
    updateUserSubscriptions: IProfileUpdateSubscriptionPayload,
    selectedTenantId: string) {
    return post<any>(SubscriptionProfile, {
        body: { ...updateUserSubscriptions },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateUserNotificationTopicSubscription(
    updateUserSubscriptions: IUpdateUserSubscriptionsRequestData[],
    selectedTenantId: string) {
    return post<any>(userNotificationSubscriptionUri, {
        body: { userSubscriptions: [ ...updateUserSubscriptions ] },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateUserNotificationSubscriptionMode(
    updateNotificationMode: IUpdateUserNotificationSubscriptionModeRequestData,
    selectedTenantId: string) {
    return post<any>(updateUserNotificationSubscriptionModeUri, {
        body: { ...updateNotificationMode },
        headers: getHeaders(selectedTenantId),
    });
}

export function updateUserNotificationSeveritySubscriptionMode(
    updateNotificationMode: IUpdateCategorySubscriptionModeRequestData[],
    selectedTenantId: string) {
    return post<any>(resetUserNotificationSeveritySubscriptionUri, {
        body: { CategorySubscriptions: [ ...updateNotificationMode ] },
        headers: getHeaders(selectedTenantId),
    });
}

export async function resetUserNotificationSubscription(publisherId: string, selectedTenantId: string) {
    return await post<any>(resetUserNotificationSubscriptionUri, {
        body: { publisherId },
        headers: getHeaders(selectedTenantId),
    });
}

export async function UnsubscribeTenantNotificationSubscription(selectedTenantId: string) {
    return await post<string>(UnsubscribeTenant, {
        body: { tenantId: selectedTenantId },
        headers: getHeaders(selectedTenantId),
    });
}
