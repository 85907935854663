import type {
    ICancelOrganizationScheduledMoveRequest,
    IOrganizationMigrationStatus,
    IOrganizationScheduledMoveRequest,
    IOrganizationStartMoveRequest,
    IOrganizationStartMoveResponse,
    IScheduledOrganizationMigrationStatus,
} from '../../common/interfaces/gws';
import {
    axiosDelete,
    get,
    post,
} from '../utility/Requests.default';

const orgMigrationUrl = '/api/move/organizations';

export function getOrgMigrationStatuses(): Promise<IOrganizationMigrationStatus[]> {
    return get<IOrganizationMigrationStatus[]>(`${orgMigrationUrl}/getOrgMigrationStatuses`);
}

export function getOrgMigrationStatus(migrationInstanceId: string): Promise<IOrganizationMigrationStatus> {
    const urlParams: any = {};
    urlParams['migrationInstanceId'] = migrationInstanceId;

    return get<IOrganizationMigrationStatus>(`${orgMigrationUrl}/getOrgMigrationStatus`, { urlParams });
}

export function startOrganizationMove(startMoveRequest: IOrganizationStartMoveRequest): Promise<IOrganizationStartMoveResponse> {
    return post<IOrganizationStartMoveResponse>(`${orgMigrationUrl}/startOrgMove`, { body: startMoveRequest });
}

export function scheduleOrganizationMove(scheduleMoveRequest: IOrganizationScheduledMoveRequest): Promise<IOrganizationStartMoveResponse> {
    return post<IOrganizationStartMoveResponse>(`${orgMigrationUrl}/scheduleOrgMove`, { body: scheduleMoveRequest });
}

export function cancelOrganizationMove(cancelScheduleMoveRequest: ICancelOrganizationScheduledMoveRequest) {
    return axiosDelete(`${orgMigrationUrl}/cancelOrgMove`, { body: cancelScheduleMoveRequest });
}

export function getScheduledOrgMigrationStatus(): Promise<IScheduledOrganizationMigrationStatus> {
    return get<IScheduledOrganizationMigrationStatus>(`${orgMigrationUrl}/getScheduledOrgMigrationStatus`);
}

export function startOrganizationMoveDryRun(startMoveRequest: IOrganizationStartMoveRequest): Promise<IOrganizationStartMoveResponse> {
    return post<IOrganizationStartMoveResponse>(`${orgMigrationUrl}/startOrgMoveDryRun`, { body: startMoveRequest });
}
