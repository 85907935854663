import { useApolloTheme } from '@experiences/theme';
import type { InputBaseComponentProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { omit } from 'lodash';
import Highlight, { defaultProps } from 'prism-react-renderer';
import lightTheme from 'prism-react-renderer/themes/github';
import darkTheme from 'prism-react-renderer/themes/oceanicNext';
import React, {
    forwardRef,
    Fragment,
    useCallback,
} from 'react';
import Editor from 'react-simple-code-editor';

const useStyles = makeStyles(theme =>
    createStyles({
        editor: {
            fontFamily: 'monospace',
            width: '100%',
            height: '100%',
        },
        darkEditor: {
            ...(darkTheme.plain as any),
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        },
        lightEditor: {
            ...(lightTheme.plain as any),
            backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        },
    }),
);

export interface ICustomEditorProps {
    value: string;
    onValueChange: (value: string) => void;
    dataCy?: string;
}

const CustomEditor = forwardRef<Editor & HTMLDivElement, InputBaseComponentProps & ICustomEditorProps>((props, ref) => {
    const { themeId } = useApolloTheme();
    const classes = useStyles();
    const {
        onValueChange, value, dataCy, ...other
    } = props;

    const highlightToken = useCallback((token, key, getTokenProps) => {
        const {
            style, children,
        } = getTokenProps({
            token,
            key,
        });
        return (
            <span
                key={key}
                style={style}>
                {children}
            </span>
        );
    }, []);

    const highlight = useCallback(
        code => (
            <Highlight
                {...defaultProps}
                theme={themeId === 'dark' ? darkTheme : lightTheme}
                code={code}
                language="markup">
                {({
                    tokens, getLineProps, getTokenProps,
                }) => (
                    <>
                        {tokens.map((line, key) => (
                            <div
                                key={key}
                                {...getLineProps({
                                    line,
                                    key,
                                })}>
                                {line.map((token, key) => highlightToken(token, key, getTokenProps))}
                            </div>
                        ))}
                    </>
                )}
            </Highlight>
        ),
        [ highlightToken, themeId ],
    );

    return (
        <Editor
            {...omit(other, 'inputRef')}
            ref={ref}
            className={clsx(classes.editor, themeId === 'dark' ? classes.darkEditor : classes.lightEditor)}
            data-cy={dataCy}
            padding="10px"
            highlight={highlight}
            value={value}
            onValueChange={onValueChange}
        />
    );
});

export default CustomEditor;
