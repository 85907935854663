import { useMemo } from 'react';

import useCheckAuthTypes from '../../../auth/hooks/CheckAuthType';
import type { UiPeoplePickerType } from '.';

const useUiPeoplePickerStringIds = (peoplePickerType: UiPeoplePickerType = 'all') => {
    const {
        checkTokenTypeIsAuth0: isAuth0, checkTokenAuthIsAzure: isAAD,
    } = useCheckAuthTypes();

    const defaultHelperTextId = useMemo(() => {
        if (peoplePickerType === 'group') {
            return 'CLIENT_PEOPLE_PICKER_GROUP_HELPER_TEXT';
        }
        if (isAuth0) {
            return 'CLIENT_PEOPLE_PICKER_DEFAULT_HELPER_TEXT';
        } else if (isAAD) {
            return peoplePickerType === 'user'
                ? 'CLIENT_PEOPLE_PICKER_DEFAULT_HELPER_TEXT'
                : 'CLIENT_PEOPLE_PICKER_ALL_AAD_HELPER_TEXT';
        }
        return 'CLIENT_PEOPLE_PICKER_AD_LDAP_HELPER_TEXT';

    }, [ isAAD, isAuth0, peoplePickerType ]);

    const defaultPlaceholderId = useMemo(() => {
        if (peoplePickerType === 'group') {
            return 'CLIENT_PEOPLE_PICKER_GROUP_PLACEHOLDER';
        }
        if (isAuth0) {
            return 'CLIENT_PEOPLE_PICKER_USER_PLACEHOLDER';
        } else if (isAAD) {
            return peoplePickerType === 'user'
                ? 'CLIENT_PEOPLE_PICKER_USER_PLACEHOLDER'
                : 'CLIENT_PEOPLE_PICKER_ALL_PLACEHOLDER';
        }
        return 'CLIENT_PEOPLE_PICKER_USER_PLACEHOLDER';

    }, [ isAAD, isAuth0, peoplePickerType ]);

    const invalidErrorMessageId = useMemo(() => {
        if (peoplePickerType === 'group') {
            return 'CLIENT_PEOPLE_PICKER_GROUP_ERROR';
        }

        if (isAAD || isAuth0) {
            return 'CLIENT_INVALID_EMAIL_ERROR';
        }
        return 'CLIENT_INVALID_GROUP_MEMBER_AD_LDAP_ERROR';

    }, [ isAAD, isAuth0, peoplePickerType ]);

    return {
        defaultHelperTextId,
        defaultPlaceholderId,
        invalidErrorMessageId,
    };
};

export default useUiPeoplePickerStringIds;
