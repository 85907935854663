import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import type { IEmailSettingsData } from '../../../common/interfaces/emailSetting';
import validateEmail from '../../../util/validators/EmailValidator';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import useVerifyMailSettingsDialogBodyViewModel from '../email/VerifyMailSettingsDialogBodyViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

const VerifyEmailSettingsComponent: FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const { state: { emailSettingsData } } = useLocation<{ emailSettingsData: IEmailSettingsData }>();

    const {
        methods: {
            control,
            handleSubmit,
            formState: {
                isDirty,
                errors,
            },
        },
        onSubmit,
        loading,
        showDrawerError,
        open,
        close,
    } = useVerifyMailSettingsDialogBodyViewModel(emailSettingsData);

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: translate({ id: 'CLIENT_TEST_EMAIL_SETTINGS_GENERIC_ERROR' }),
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary">
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={loading}
                            disabled={!isDirty}
                            variant="contained"
                            data-cy="verify-email-settings-send-button"
                        >
                            {translate({ id: 'CLIENT_SEND' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
            >
                <div className={classes.input}>
                    <Typography
                        id="sendToLabel"
                        className={clsx(classes.inputLabel, classes.inputMargin)}>
                        {translate({ id: 'CLIENT_SEND_TO' })}
                    </Typography>
                    <Controller
                        as={TextField}
                        control={control}
                        name="recipient"
                        variant="outlined"
                        type="email"
                        rules={{ validate: { valid: value => !value || validateEmail(value) } }}
                        error={!!errors.recipient}
                        helperText={errors.recipient?.type === 'valid' && translate({ id: 'CLIENT_INVALID_EMAIL_ERROR' })}
                        autoComplete="off"
                        fullWidth
                        InputProps={{ className: 'Tall' }}
                        data-cy="verify-email-settings-recipient"
                        inputProps={{ 'aria-labelledby': 'sendToLabel' }}
                    />
                </div>
            </UiForm>
        </UiDrawer>
    );
};

export default VerifyEmailSettingsComponent;
