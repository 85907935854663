import type IExternalIdentity from '../../common/interfaces/externalIdentity';
import {
    authenticationSettingMapper,
    authenticationSettingMapperSaml2,
} from '../mappers/AuthenticationSettingMapper';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';
import type {
    DirectoryConnectionType,
    IDirectoryConnectionPayload,
} from './DirectoryConnectionService';

export const authenticationSettingUrl = `${process.buildConfigs.identityApiBaseRoute}/AuthenticationSetting`;

export const AuthenticationSettingType = {
    NoSetting: 'no setting',
    AAD: 'aad',
    Google: 'google',
    Microsoft: 'microsoft',
    SAML: 'saml2',
    AD: 'ad',
    LDAPAD: 'ldapad',
};

export const BulkAuthenticationSettingKey = {
    NoSetting: 'no setting',
    AAD: 'aad',
    Google: 'Google',
    Microsoft: 'Microsoft',
    SAML: 'Saml2',
    AD: 'ad',
    LDAPAD: 'ldapad',
};

export interface ExtensionUserAttributeMappings {
    [key: string]: string;
}

export interface IAuthenticationSettingPayload {
    type: string;
    displayName: string;
    partitionGlobalId: string;
    externalIdenptyProviderId?: number;
    allowedIdp?: string;
    clientId?: string;
    clientSecret?: string;
    tenantId?: string;
    settings?: string;
    department?: string;
    city?: string;
    extensionUserAttributeMappings?: ExtensionUserAttributeMappings;
}

interface IAuthenticationSettingResponse {
    authenticationSettingType?: string;
    hostConnectionType?: DirectoryConnectionType;
    externalIdentityProviderDto: IExternalIdentity;
    directoryConnectionDto: IDirectoryConnectionPayload;
}

export interface IBulkAuthenticationSettingResponse {
    [key: string]: IAuthenticationSettingResponse;
}

export interface IAuthenticationIDPSettingPayload {
    allowedIdp: string;
    partitionGlobalId: string;
}

export function createAuthenticationSetting(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(authenticationSettingUrl, { body: authenticationSettingMapper(payload) });
}

export function updateAuthenticationSetting(payload: IAuthenticationSettingPayload) {
    return put<IAuthenticationSettingResponse>(authenticationSettingUrl, { body: authenticationSettingMapper(payload) });
}

export function getTemplateData(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/GenerateTemplateSettings`, { body: authenticationSettingMapperSaml2(payload) });
}

export function getAllAuthenticationSettings(_url: string, partitionGlobalId: string) {
    return get<IBulkAuthenticationSettingResponse>(`${authenticationSettingUrl}/getAll/${partitionGlobalId}`);
}

export function updateIDPSetting(payload: IAuthenticationIDPSettingPayload) {
    return post<string>(`${authenticationSettingUrl}/IDP`, { body: payload });
}

export function deleteIDPSetting(partitionGlobalId: string) {
    return axiosDelete(`${authenticationSettingUrl}/IDP/${partitionGlobalId}`);
}

export function createDirectoryIntegrationSetting(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapper(payload) });
}

export function updateDirectoryIntegrationSetting(payload: IAuthenticationSettingPayload) {
    return put<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapper(payload) });
}

export function createDirectoryIntegrationSettingSaml2(payload: IAuthenticationSettingPayload) {
    return post<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapperSaml2(payload) });
}

export function updateDirectoryIntegrationSettingSaml2(payload: IAuthenticationSettingPayload) {
    return put<IAuthenticationSettingResponse>(`${authenticationSettingUrl}/DirectoryIntegration`, { body: authenticationSettingMapperSaml2(payload) });
}

export function deleteDirectoryIntegrationSetting(partitionGlobalId: string) {
    return axiosDelete(`${authenticationSettingUrl}/DirectoryIntegration/${partitionGlobalId}`);
}
