import type { Persona } from '@experiences/interfaces';

import type { slidesData } from '../common/interfaces/slide';
import { get } from './utility/Requests.default';

export const carouselURL = '/api/content/carousel/getCarouselCMSData';

export async function getContentfulResource(language: string, dominantPersona: Persona) {
    return get<slidesData>(carouselURL, {
        urlParams: {
            language,
            dominantPersona,
        },
    });
}
