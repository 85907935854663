export enum AttributeType {
    BUSINESS = 'BusinessUnit',
    JOB = 'JobRole',
    LOCATION = 'Location',
}

export interface IFieldAttribute {
    attributeType: AttributeType;
    value: string;
}
