import {
    get,
    put,
} from '../utility/Requests.default';

const entitlementsUrl = '/api/license/accountant/Entitlement';

export async function isEntitled(entitlement: string) {
    try {
        await get(`${entitlementsUrl}/${entitlement}`,
            {
                headers: {
                    extendRequestHeaders: 'true',
                    'cache-control': 'no-cache, no-store, must-revalidate',
                    'pragma': 'no-cache',
                },
            }
        );
        return true;
    } catch {
        return false;
    }
}

export async function consumeEntitlement(entitlement: string) {
    await put(`${entitlementsUrl}/${entitlement}`, { body: { } });
    return true;
}
