import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';

const EnableXHRWithOrgName = getFeatureFlagValue(Features.EnableXHRWithOrgName.name);

export function createInviteUrl(
    originUrl: string,
    partitionGlobalId: string,
    email: string,
    provider?: string,
    orgName?: string,
) {
    return (
        `${originUrl}/portal_/acceptInvite?organizationId=${partitionGlobalId}&emailForUserinvite=${encodeURIComponent(email)}&organizationName=${orgName}` +
    (provider ? `&provider=${provider?.toLowerCase()}` : '')
    );
}

