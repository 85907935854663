import { UiProgressButton } from '@experiences/ui-common';
import { Button } from '@mui/material';
import type { ClassNameMap } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

export const UiFormActionButtons: React.FC<{
    showActions: boolean;
    classes: ClassNameMap;
    loading: boolean;
    isSubmitDisabled: boolean;
    close: () => void;
    onSubmit?: (...props: any) => void;
    altSaveText?: string;
}> = ({
    showActions = true,
    classes,
    loading,
    isSubmitDisabled,
    close = () => {},
    onSubmit = () => {},
    altSaveText,
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        showActions ? (
            <div className={classes.actions}>
                <div className={clsx(classes.formButtons)}>
                    <Button
                        className={classes.cancelButton}
                        onClick={close}
                        color="primary"
                        data-cy="ui-form-cancel"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        loading={loading}
                        onClick={onSubmit}
                        variant="contained"
                        data-cy="ui-form-save"
                        disabled={isSubmitDisabled}
                    >
                        {altSaveText ?? translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            </div>
        ) : null
    );
};
