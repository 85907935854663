import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiCopyButton,
    UiProgressButton,
} from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import SearchIcon from '@mui/icons-material/Search';
import {
    Button,
    FormGroup,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import type { IResource } from '../../../common/interfaces/cis/client';
import { ExternalApiResourceType } from '../../../common/interfaces/cis/client';
import {
    externalApiResourceUrl,
    getExternalApiResources,
} from '../../../services/identity/ExternalApiResourceService';
import {
    generateReferenceToken,
    referenceTokenUrl,
} from '../../../services/identity/ReferenceTokenService';
import {
    getSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import {
    accountGlobalId,
    userGlobalId,
} from '../../../store/selectors';
import {
    getToDateFromNow,
    secondsToDays,
    timestampToDate,
} from '../../../util/DateUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import type { IResourceForm } from '../../externalApps/subcomponents/ScopesController';
import ScopeController from '../../externalApps/subcomponents/ScopesController';

const useStyles = makeStyles(theme =>
    createStyles({
        inputHeader: { display: 'flex' },
        input: { marginTop: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        heading: {
            color: theme.palette.semantic.colorForeground,
            fontSize: '16px',
            fontWeight: 600,
            marginTop: '28px',
        },
        mainHeading: { paddingBottom: '12px' },
        searchBar: { width: '100%' },
        searchIcon: { marginRight: '4px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
    }),

);

interface IReferenceTokenForm extends IResourceForm {
    tokenName: '';
    expirationDate: 0;
}

const CreatePersonalAccessTokenDrawerComponent: FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const theme = useTheme();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const createDialog = useShowDialog();
    const {
        open, close,
    } = useModalState(RouteNames.PersonalAccessToken);

    const [ selectedResource, setSelectedResource ] = useState<IResource>();
    const [ scopeSearch, setScopeSearch ] = useState<string>('');
    const [ loading, setLoading ] = useState(false);
    const [ lifetimeMax, setLifetimeMax ] = useState(0);

    const { data: externalResources } = useSWR<IResource[], Error>(externalApiResourceUrl, getExternalApiResources);

    const filteredResources = useMemo(() => externalResources?.filter(resource => resource.scopes.some(scope =>
        scope.type === ExternalApiResourceType.User || scope.type === ExternalApiResourceType.UserAndApplication)), [ externalResources ]);

    const methods = useForm<IReferenceTokenForm>({
        mode: 'onChange',
        defaultValues: {
            tokenName: '',
            expirationDate: 0,
            name: undefined,
            userScopes: [],
            applicationScopes: [],
        },
    });

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        setValue,
        formState: {
            dirtyFields, errors,
        },
    } = methods;

    const partitionGlobalId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const expirationValue = useMemo(() => watch('expirationDate'), [ watch ]);

    useEffect(() => {
        (async () => {
            const result = await getSetting(settingUrl, [ 'ReferenceTokensLifetimeMax' ], partitionGlobalId, userId);
            const getSettingWithKeyLifetimeMax = !!result?.length && result.find(setting => setting.key === 'ReferenceTokensLifetimeMax');
            setLifetimeMax(getSettingWithKeyLifetimeMax ? parseInt(secondsToDays(parseInt(getSettingWithKeyLifetimeMax.value))) : 365);

            reset({
                tokenName: '',
                expirationDate: 0,
                name: undefined,
                userScopes: [],
                applicationScopes: [],
            });
        })();
    }, [ partitionGlobalId, reset, userId ]);

    const handleChangeSelect = useCallback(
        (event, onChange) => {
            const pickedResource = filteredResources?.find(resource => resource.name === (event.target.value as string));
            setSelectedResource(pickedResource);
            onChange(pickedResource);
            setValue('userScopes', [], { shouldDirty: true });
        },
        [ filteredResources, setValue ],
    );

    const onSubmit = useCallback(
        async (data: IReferenceTokenForm) => {
            setLoading(true);
            const payload = {
                description: data.tokenName,
                expiration: getToDateFromNow(data.expirationDate),
                scopes: data.userScopes,
            };

            try {
                const returnPayload = await generateReferenceToken(payload);

                setLoading(false);
                close();

                await createDialog({
                    title: translate({ id: 'CLIENT_TOKEN_GENERATED' }),
                    body: (
                        <>
                            <Typography>
                                {translate({ id: 'CLIENT_TOKEN_GENERATED_MESSAGE' })}
                            </Typography>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                alignItems: 'center',
                                backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
                                marginTop: '20px',
                                padding: '8px',
                                border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
                                borderRadius: '3px',
                            }}>
                                <Typography
                                    style={{
                                        flex: 'auto',
                                        minWidth: 0,
                                        textAlign: 'left',
                                        maxWidth: '80%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                    data-cy="generated-token">
                                    {returnPayload}
                                </Typography>
                                <div style={{ marginLeft: 'auto' }}>
                                    <UiCopyButton
                                        textToCopy={returnPayload}
                                        data-cy="token-copy"
                                    />
                                </div>
                            </div>
                        </>
                    ),
                    icon: 'success',
                    primaryButtonText: translate({ id: 'CLIENT_CLOSE' }),
                    showCancel: false,
                });
            } catch (error) {
                const response = await getErrorMessage(error);
                setErrorMessage(response);
            } finally {
                setLoading(false);
                mutate([ `${referenceTokenUrl}` ]);
            }

        },
        [
            close,
            createDialog,
            getErrorMessage,
            setErrorMessage,
            theme.palette.semantic.colorBackgroundSecondary,
            theme.palette.semantic.colorBorderDeEmp,
            translate,
        ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_GENERATE_PERSONAL_ACCESS_TOKEN' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            width='medium'
            themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
        >
            <FormProvider {...methods}>
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => close()}
                                color="primary">
                                {translate({ id: 'CLIENT_CANCEL' })}
                            </Button>
                            <UiProgressButton
                                loading={loading}
                                disabled={Object.keys(dirtyFields).length < 4 || expirationValue > lifetimeMax}
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                data-cy="token-add-submit-button"
                            >
                                {translate({ id: 'CLIENT_SAVE' })}
                            </UiProgressButton>
                        </div>
                    }
                    isDrawer
                    addScrollPadding
                >
                    <div style={{ paddingTop: '24px' }}>
                        <div className={classes.inputHeader}>
                            <TextField
                                label={
                                    <Typography
                                        className={clsx(classes.inputLabel, classes.inputMargin)}
                                    >
                                        {translate({ id: 'CLIENT_NAME' })}
                                    </Typography>
                                }
                                name="tokenName"
                                variant="outlined"
                                sx={{ width: '18ch' }}
                                inputRef={register({ required: true })}
                                data-cy="generate-name"
                            />
                            <TextField
                                label={
                                    <Typography
                                        className={clsx(classes.inputLabel, classes.inputMargin)}
                                    >
                                        {translate({ id: 'CLIENT_EXPIRATION_DATE' })}
                                    </Typography>
                                }
                                name="expirationDate"
                                type="number"
                                variant="outlined"
                                sx={{ width: '18ch' }}
                                style={{ marginLeft: 'auto' }}
                                inputRef={register({ required: true })}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                            days
                                    </InputAdornment>,
                                    inputProps: {
                                        min: -10,
                                        max: lifetimeMax,
                                    },
                                }}
                                error={expirationValue > lifetimeMax}
                                helperText={expirationValue > lifetimeMax ? (
                                    translate({ id: 'CLIENT_LIFETIME_MESSAGE' }, { 0: lifetimeMax })
                                ) : (
                                    translate({ id: 'CLIENT_EXPIRES_ON' }, { 0: timestampToDate(getToDateFromNow(expirationValue)) })
                                )}
                                data-cy="generate-expiration"
                            />
                        </div>
                        <Typography className={clsx(classes.heading, classes.mainHeading)}>
                            {translate({ id: 'CLIENT_SCOPES' })}
                        </Typography>
                        <div>
                            <Typography
                                id="scopes-resource-name-label"
                                className={clsx(classes.inputLabel, classes.inputMargin)}>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_RESOURCES' })}
                            </Typography>
                            <Controller
                                render={({
                                    onChange, value,
                                }) => (
                                    <Select
                                        labelId="scopes-resource-name-label"
                                        value={value?.name || selectedResource?.name || ''}
                                        onChange={event => handleChangeSelect(event, onChange)}
                                        variant="outlined"
                                        fullWidth
                                        data-cy="scope-resource-select"
                                    >
                                        {filteredResources?.map(resource => (
                                            <MenuItem
                                                key={`resource-select-${resource.name}`}
                                                value={resource.name}>
                                                {resource.displayName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 256,
                                }}
                                error={!!errors.name}
                                name="name"
                                data-cy="scopes-resource-name"
                            />
                            <div className={classes.input}>
                                <TextField
                                    className={classes.searchBar}
                                    variant="outlined"
                                    placeholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                                    InputProps={{ startAdornment: <SearchIcon className={classes.searchIcon} /> }}
                                    value={scopeSearch}
                                    onChange={e => setScopeSearch(e.target.value)}
                                    data-cy="scope-search-bar"
                                />
                                <div className={classes.input}>
                                    {!selectedResource ? (
                                        <Typography>
                                            {translate({ id: 'CLIENT_EXTERNAL_APP_SCOPE_USER_SCOPE_DESCRIPTION' })}
                                        </Typography>
                                    ) : (
                                        <FormGroup>
                                            <ScopeController
                                                name="userScopes"
                                                selectedResource={selectedResource}
                                                scopeSearch={scopeSearch}
                                            />
                                        </FormGroup>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </UiForm>
            </FormProvider>
        </UiDrawer>
    );
};

export default CreatePersonalAccessTokenDrawerComponent;
