import { useLocalization } from '@experiences/locales';
import Cookies from 'js-cookie';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
    generatePath,
    Redirect,
    useParams,
} from 'react-router-dom';

import ProtectedRoute from '../../auth/ProtectedRoute';
import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import {
    accountGlobalId,
    accountLogicalName,
    countrySelector,
    isAdminSelector,
    isHostModeSelector,
    userGlobalId,
} from '../../store/selectors';
import {
    useDeviceInfo,
    useTelemetryHelper,
} from '../../telemetry/TelemetryHelper';

export const AccountAdmin = () => {
    const isHostMode = useSelector(isHostModeSelector);
    const currentAccountName = useSelector(accountLogicalName);
    const userId = useSelector(userGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const { accountName: paramAccountName } = useParams<{ accountName?: string }>();
    const hostPath = useMemo(() => generatePath(isAdminRevampEnabled
        ? RouteNames.OrganizationAdminHome : RouteNames.Organizations, { accountName: 'host' }), []);
    const { logEvent } = useTelemetryHelper();
    const deviceInfo = useDeviceInfo();
    const isAdmin = useSelector(isAdminSelector);
    const accountCountry = useSelector(countrySelector);
    const productLanguage = useLocalization();

    const getOrgPath = useCallback(accountName => generatePath(RouteNames.Home, { accountName }), []);

    const path = useMemo(() => {
        if (paramAccountName) {
            return paramAccountName === 'host' ? hostPath : getOrgPath(paramAccountName);
        }
        return isHostMode ? hostPath : getOrgPath(currentAccountName);
    }, [ getOrgPath, currentAccountName, hostPath, isHostMode, paramAccountName ]);

    useEffect(() => {
        logEvent('Application.Start', {
            IsAdmin: isAdmin,
            CloudOrganizationCountry: accountCountry,
            CloudOrganizationId: partitionGlobalId,
            CloudUserId: userId,
            ProductLanguage: productLanguage,
            UserCountry: accountCountry,
            GAId: Cookies.get('_ga'),
            ...deviceInfo,
        });
    }, [ logEvent, isAdmin, accountCountry, deviceInfo, productLanguage ]);

    return <Redirect to={path} />;
};

export default (props: any) => (
    <ProtectedRoute>
        <AccountAdmin {...props} />
    </ProtectedRoute>
);
