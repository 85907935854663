export enum SubscriptionType {
    TRIAL = 'trial',
    COMMUNITY = 'community',
    AUTOMATION_HUB = 'automationhub',
    MARKETPLACE = 'marketplace',
    FREE_SKU = 'automationkit',
    FEDRAMP = 'govcloud',
    DIRECT_BUY = 'directbuy',
    PRO_TRIAL = 'protrial',
    COMMUNITY_REVAMP = 'communityrevamp',
    ACADEMY = 'academy',
    FREE = 'free',
}
