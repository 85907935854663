import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type {
    RouteConfig,
    RouteConfigComponentProps,
} from 'react-router-config';
import {
    generatePath,
    Redirect,
} from 'react-router-dom';

import AuthCallback from '../../auth/AuthCallback';
import AuthError from '../../auth/AuthError';
import ClearAuthStorageComponent from '../../auth/ClearAuthStorageComponent';
import Logout from '../../auth/Logout';
import SessionExpiry from '../../auth/SessionExpiry';
import * as RouteNames from '../../common/constants/RouteNames';
import AccountAdmin from '../../component/authentication/AccountAdmin.default';
import GenericError from '../../component/authentication/GenericError';
import InvalidUrl from '../../component/authentication/InvalidUrl';
import RedirectToService from '../../component/authentication/RedirectToService';
import Unregistered from '../../component/authentication/Unregistered';
import RestrictedPolicyError from '../../component/securitySettings/subcomponents/RestrictedPolicyError';
import { TestConnection } from '../../component/users/subcomponents/TestConnection';
import { accountLogicalName } from '../../store/selectors';
import ShellRouteWrapper from '../ShellRouteWrapper';

const useMainRoutes = () => {
    const accountName = useSelector(accountLogicalName);

    return useMemo<RouteConfig[]>(() => [
        {
            path: '/',
            exact: true,
            component: () => <Redirect to="/portal_/cloudrpa" />,
        },
        {
            path: '/host',
            exact: true,
            component: () => <Redirect to="/host/portal_/cloudrpa" />,
        },
        {
            path: '/portal_',
            exact: true,
            component: () => (
                <Redirect to={accountName ? RouteNames.PortalPrefix.replace(':accountName', accountName) : '/portal_/cloudrpa'} />
            ),
        },
        {
            path: '/portal_/cloudrpa',
            exact: true,
            component: AccountAdmin,
        },
        {
            path: '/portal_/logout',
            exact: true,
            component: Logout,
        },
        {
            path: '/portal_/testconnection',
            exact: true,
            component: TestConnection,
        },
        {
            path: '/portal_/restricted-policy',
            exact: true,
            component: RestrictedPolicyError,
        },
        {
            path: '/portal_/unregistered',
            exact: true,
            component: Unregistered,
        },
        {
            path: '/portal_/genericerror',
            exact: true,
            component: GenericError,
        },
        {
            path: '/portal_/loginsuccess',
            exact: true,
            component: AuthCallback,
        },
        {
            path: '/portal_/sessionexpired',
            exact: true,
            component: SessionExpiry,
        },
        {
            path: '/portal_/autherror',
            exact: true,
            component: AuthError,
        },
        {
            path: '/portal_/clearAuthStorage',
            exact: true,
            component: ClearAuthStorageComponent,
        },
        {
            path: RouteNames.Root,
            exact: true,
            component: AccountAdmin,
        },
        {
            path: RouteNames.CloudRPA,
            exact: true,
            component: AccountAdmin,
        },
        {
            path: RouteNames.LoginSuccess,
            exact: true,
            component: AuthCallback,
        },
        {
            path: RouteNames.RedirectToService,
            exact: true,
            component: RedirectToService,
        },
        {
            path: RouteNames.PortalPrefix,
            render: (routeConfig: RouteConfigComponentProps) => (
                <ShellRouteWrapper routeConfig={routeConfig} />
            ),
        },
        {
            path: '/:accountName',
            exact: true,
            render: ({ match }) => <Redirect to={generatePath(RouteNames.Root, { accountName: match.params.accountName })} />,
        },
        {
            // prettier-ignore
            path: (/^(?!(\/portal_|\/.+\/portal_)).*$/g) as any,
            component: () => <Redirect to={`/portal_${location.pathname + location.search}`} />,
        },
        {
            path: '*',
            component: InvalidUrl,
        },
    ], [ accountName ]);
};

export default useMainRoutes;
