import type { IFolderTreeDTO } from '@experiences/ui-common';

import type { ActionTypes } from '../reducers/actionTypes';

export enum NotificationMode {
    InApp = 'InApp',
    Email = 'Email',
}
export enum NotificationSettingsMode {
    MyNotification = 'My Notification',
    ManageNotification = 'Manage Notification',
}
export enum ProfileType {
    UserGroup = 'UserGroup',
    NewUserGroup = 'NewUserGroup',
    User = 'User',
}
export interface INotificationSettingsReducer {
    tabIndex: number;
    publishers: INotificationPublisherDto[];
    publishersWithGroupedTopics: INotificationTopicPublisher[];
    publishersWithSeverity: INotificationTopicPublisher[];
}

export interface INotificationSettingsAction {
    type: ActionTypes;
    data: INotificationPublisherDto[] | INotificationSettingsActionUpdateTabIndexData | INotificationSettingsActionUpdateTopicData | INotificationSettingsActionUpdateModeData | INotificationSettingsActionRestoreDefaultData | INotificationSeverityModeData | INotificationSettingsFoldersDefaultData | INotificationSettingsFolderUpdate;
}

export interface IFolderEntityDto {
    id: string;
    name: string;
    parentName: string;
    type: string;
    isSubscribed: boolean;
}
export interface IEntityTypeDto {
    type: string;
    urlTemplate: string;
    projectionProperty: string;
    publicationPayloadProperty: string;
    requestType: string;
    payload: string;
}
export interface INotificationTopicPublisher {
    name: string;
    displayName: string;
    modes: INotificationMode[];
    topicGroups: INotificationTopicGroup[];
    publisherIndex: number;
    publisherId: string;
    isUpdating?: boolean;
    isMandatory: boolean;
    isMandatoryInDeterminate: boolean;
    isVisible: boolean;
    isUserOptIn: boolean;
    entityTypes: IEntityTypeDto[];
    folders?: IFolderTreeDTO[];
    entities: IFolderEntityDto[];
}

export interface INotificationPublisherDto {
    name: string;
    displayName: string;
    modes: INotificationMode[];
    topics: INotificationTopicDto[];
    id: string;
    entityTypes: IEntityTypeDto[];
    folders?: IFolderNavigationDTO[];
    entities: IFolderEntityDto[];
    isUserOptin: boolean;
}

export interface INotificationMode {
    name: NotificationMode;
    isSubscribed?: boolean;
    isIndeterminate?: boolean;
    isUpdating?: boolean;
    isActive?: boolean;
}

export interface INotificationTopicGroup {
    groupName: string;
    topics: INotificationTopic[];
    modes: INotificationMode[];
    groupIndex: number;
    isUpdating?: boolean;
    isMandatoryChecked: boolean;
    isMandatoryIndeterminate: boolean;
    isVisible: boolean;
}

export interface INotificationTopicDto {
    displayName: string;
    group: string | null;
    id: string;
    isSubscribed: boolean;
    name: string;
    parentGroup: string | null;
    topicFilters?: string;
    modes?: INotificationMode[];
    description: string;
    category: string;
    isMandatory: boolean;
    isVisible: boolean;
}

export interface INotificationTopic {
    displayName: string;
    topicId: string;
    name: string;
    isSubscribed: boolean;
    topicFiltersUrl?: string;
    topicIndex: number;
    isUpdating?: boolean;
    modes?: INotificationMode[];
    description: string;
    category: string;
    isMandatory: boolean;
    isVisible: boolean;
}

export interface IUpdateUserSubscriptionsRequestData {
    topicId: string;
    isSubscribed: boolean;
    notificationMode?: NotificationMode;
}

export interface IUpdateUserNotificationSubscriptionModeRequestData {
    publisherId: string;
    publisherMode: INotificationMode;
}

export interface IUpdateCategorySubscriptionModeRequestData {
    publisherId: string;
    notificationMode: NotificationMode;
    isSubscribed: boolean;
    category: string;
}

export interface INotificationSettingsActionUpdateTabIndexData {
    tabIndex: number;
}

export interface INotificationSettingsActionUpdateTopicData {
    publisherIndex: number;
    groupIndex?: number;
    topicIndex?: number;
    isSubscribed?: boolean;
    mode?: NotificationMode;
}

export interface INotificationSettingsActionUpdateModeData {
    publisherIndex: number;
    modeIndex: number;
    newModeStatus?: boolean;
}

export interface INotificationSettingsActionRestoreDefaultData {
    publisherIndex: number;
    resetData?: INotificationPublisherDto[];
}

export interface INotificationSeverityModeData {
    publisherid: string;
    severity: string;
    isSubscribed: boolean;
    mode: NotificationMode;
    groupIndex?: number;
}
export interface INotificationProfileResponse {
    value: INotificationProfile[];
}
export interface INotificationProfile {
    name: string;
    displayName: string;
    description: string;
    modifiedByUser: string;
    modifiedTime: string;
    externalUserGroupId: string;
    profileType: string;
}
export interface IProfileUpdateSubscriptionPayload {
    name: string;
    description: string;
    displayName: string;
    externalUserGroupId: string;
    profileType: string;
    tenantId: string;
    profileSubscriptions: IProfileSubscriptionPayload[];
}

export interface IProfileSubscriptionPayload {
    topicId: string;
    isSubscribed: boolean;
    notificationMode: string;
    isMandatory: boolean;
    isVisible: boolean;
}
interface INotificationSettingsFoldersDefaultData {
    publisherIndex: number;
    resetData?: any;
}
interface IFolderNavigationDTO {
    Description: string;
    Key: string;
    ParentKey: string;
    HasChildren: boolean;
    DisplayName: string;
    ParentId: number;
    Level: number;
    FolderType: string;
}

interface INotificationSettingsFolderUpdate {
    publisherIndex: number;
    Key: string;
    tenantId: string;
    isSubscribed: boolean;
}
export interface IFolderUpdatePayloadEntity {
    id: string;
    name: string;
    type: string;
    parentName: string;
    isSubscribed: boolean;
}

export interface IFolderUpdatePayload {
    publisherID: string;
    isUserOptIn: boolean;
    entities: IFolderUpdatePayloadEntity[];
}

export interface IOrchestratorFolderEntity {
    HasChildren: boolean;
    IsSelectable: boolean;
    IsPersonal: boolean;
    Key: string;
    DisplayName: string;
    FullyQualifiedName: string;
    FolderType: string;
    ProvisionType: string;
    PermissionModel: string;
    ParentId: string;
    ParentKey: string;
    entities: IFolderUpdatePayloadEntity[];
    Id: number;
}

export interface IEmailConfiguration {
    senderName: string;
    senderId: string;
    digestSchedule: number;
    senderLogo: string;
    isAccountLevel: boolean;
    emailFooter: string;
    senderLogoPrefix: string;
}
