import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        dialog: { minWidth: '550px' },
        button: { marginLeft: '10px' },
        buttonContainer: {
            marginTop: '24px',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
);

const TenantTagsWarningDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.dialog}>
            <Typography>
                {translate({ id: 'CLIENT_CHANGES_WILL_BE_LOST' })}
            </Typography>
            <div className={classes.buttonContainer}>
                <Button
                    className={clsx(classes.button)}
                    color="primary"
                    data-cy="tenant-tags-warning-cancel-button"
                    onClick={() => closeDialog()}
                    variant="outlined"
                >
                    {translate({ id: 'CLIENT_CONTINUE_EDITING' })}
                </Button>
                <Button
                    className={classes.button}
                    data-cy="tenant-tags-warning-confirmation-button"
                    onClick={() => closeDialog(true)}
                    variant="contained"
                >
                    {translate({ id: 'CLIENT_LEAVE_PAGE' })}
                </Button>
            </div>
        </div>
    );
};

export default TenantTagsWarningDialogBody;
