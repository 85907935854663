import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type {
    IFile,
    IPagination,
} from '@experiences/interfaces';
import { useLocalization } from '@experiences/locales';
import { portalTelemetry } from '@experiences/telemetry';
import { useRouteResolver } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Button,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { renderRoutes } from 'react-router-config';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useIsAdminRevampEnabled } from '../../common/hooks/useIsAdminRevampEnabled';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import {
    auditUrl,
    downloadAuditLogs,
    getAuditLogs,
} from '../../services/audit/AuditService';
import {
    accountCreatedOn,
    accountGlobalId,
    accountType,
    userGlobalId,
} from '../../store/selectors';
import {
    isBeforeGivenDate,
    timestampToDateTime,
    useUserReadableTime,
} from '../../util/DateUtil';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import { UiLicenseExpiredBanner } from '../common/UiLicenseExpiredBanner';
import { UiLicenseInGracePeriodBanner } from '../common/UiLicenseInGracePeriodBanner/index.default';
import UiOverAllocationBanner from '../common/UiOverAllocationBanner';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import {
    DEFAULT_PAGINATION,
    UiPaginatedGrid,
} from '../common/UiPaginatedGrid';
import { shouldRenderAlertForOldestPage } from '../common/UiPaginatedGrid/PaginationUtils';
import { UiPanel } from '../common/UiPanel/UiPanel';
import { UiRefreshButton } from '../common/UiRefreshButton';
import { UiTrialPerSkuLicenseInGracePeriodBanners } from '../common/UiTrialPerSkuLicenseInGracePeriodBanners';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import type { IAuditLog } from './interfaces/auditLog';

const useStyles = makeStyles(() =>
    createStyles({ operationCell: { maxWidth: '334px' } }),
);

export interface IAuditLogResponse {
    totalCount: number;
    results: IAuditLog[];
}

const AuditPageComponent: React.FC = () => {
    const classes = useStyles();
    const setErrorMessage = useCentralErrorSetter();

    const { getErrorMessage } = useGetErrorInfo();
    const { userReadableTime } = useUserReadableTime();
    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const getRoute = useRouteResolver();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const organizationName = useOrganizationName();
    const language = useLocalization();

    const accountId = useSelector(userGlobalId);
    const createdOn = useSelector(accountCreatedOn);
    const type = useSelector(accountType);
    const partitionGlobalId = useSelector(accountGlobalId);

    const [ isAlertDismissed, setIsAlertDismissed ] = useState(false);
    const [ downloadInProgress, setDownloadInProgress ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);
    const [ pageNumber, getPagedNumber ] = useState<IPagination>({
        ...DEFAULT_PAGINATION,
        sortBy: 'CreatedOn',
        sortOrder: 'desc',
    });

    const logInfo = useCallback(
        (message: string) => {
            portalTelemetry.trackTrace({
                message,
                severityLevel: SeverityLevel.Information,
            }, {
                accountId,
                revampEnabled: isAdminRevampEnabled,
            });
        },
        [ accountId, isAdminRevampEnabled ],
    );

    const saveAuditFile = useCallback(
        (file: IFile) => {
            // The '\ufeff' appended in front of the data allows Excel to process JA characters correctly
            // It will not affect processing of English characters
            const auditData = new Blob([ '\ufeff' + file.data ], { type: 'application/vnd.ms-excel;charset=utf-8' });
            FileSaver.saveAs(auditData, file.filename);
            logInfo('Successfully downloaded audit records');
        },
        [ logInfo ],
    );

    const downloadLogs = useCallback(async () => {
        try {
            if (!downloadInProgress) {
                logInfo('Sending request to server to download audit details');
                setDownloadInProgress(true);
                enqueueSnackbar(translate({ id: 'CLIENT_DOWNLOAD_IN_PROGRESS' }), { variant: notificationType.INPROGRESS } as any);
                const auditFile = await downloadAuditLogs(language, partitionGlobalId);
                saveAuditFile(auditFile);
            }
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        } finally {
            setDownloadInProgress(false);
        }
    }, [
        downloadInProgress,
        logInfo,
        enqueueSnackbar,
        translate,
        language,
        partitionGlobalId,
        saveAuditFile,
        setErrorMessage,
        getErrorMessage,
    ]);

    const grid = useMemo(() => <UiPaginatedGrid<IAuditLog, IAuditLogResponse>
        url={auditUrl}
        fetcher={getAuditLogs}
        fetcherArgs={[ language, partitionGlobalId ]}
        data-cy="auditlogs-ui-grid"
        initialSort={[
            {
                id: 'CreatedOn',
                desc: true,
            },
        ]}
        setLoading={setLoading}
        getPagedNumber={getPagedNumber}
        pagination
        hideRefresh={isAdminRevampEnabled}
        alert={{
            message: translate({ id: 'CLIENT_AUDIT_LIST_END_ALERT' }),
            onClose: () => setIsAlertDismissed(true),
            condition: (p, s, count) =>
                !isAlertDismissed &&
                    [ 'ENTERPRISE', 'TRIAL' ].indexOf(type) > -1 && // accountType is ENTERPRISE or TRIAL
                    shouldRenderAlertForOldestPage(p, s, count) && // user is on last/oldest page
                    isBeforeGivenDate(createdOn, '2020-11-12'), // account creation date before 2020-11-12
        }}
        columns={[
            {
                accessor: 'category',
                Header: translate({ id: 'CLIENT_CATEGORY' }),
                width: 18,
                Cell: ({ row }) => row.original.category,
                disableSortBy: true,
            },
            {
                accessor: 'userName',
                Header: translate({ id: 'CLIENT_USER' }),
                width: 20,
                disableSortBy: true,
                Cell: ({ row }) => row.original.userName || translate({ id: 'CLIENT_SYSTEM' }),
            },
            {
                accessor: 'action',
                Header: translate({ id: 'CLIENT_ACTION' }),
                width: 15,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <Tooltip
                        arrow
                        title={row.original.action}>
                        <Typography>
                            {row.original.action}
                        </Typography>
                    </Tooltip>
                ),
            },
            {
                accessor: 'operation',
                Header: translate({ id: 'CLIENT_OPERATION' }),
                width: 30,
                disableSortBy: true,
                Cell: ({ row }) => (
                    <Tooltip
                        arrow
                        title={row.original.message}>
                        <div className={clsx(classes.operationCell)}>
                            {row.original.message}
                        </div>
                    </Tooltip>
                ),
            },
            {
                accessor: 'createdOn',
                sortName: 'CreatedOn',
                Header: translate({ id: 'CLIENT_TIME' }),
                width: 16,
                Cell: ({ row }) => (
                    <Tooltip
                        arrow
                        title={timestampToDateTime(row.original.createdOn, language)}>
                        <span>
                            {userReadableTime(row.original.createdOn)}
                        </span>
                    </Tooltip>
                ),
            },
        ]}
        rowActions={[
            {
                type: ButtonType.Icon,
                label: translate({ id: 'CLIENT_OPEN' }),
                tooltip: translate({ id: 'CLIENT_OPEN' }),
                actionType: GridActionType.Row,
                icon: <InfoOutlinedIcon />,
                click: row => {
                    history.push(getRoute(RouteNames.AuditLogDialog), { data: row.original });
                },
            },
        ]}
    />, [
        classes.operationCell,
        createdOn,
        getRoute,
        history,
        isAdminRevampEnabled,
        isAlertDismissed,
        language,
        partitionGlobalId,
        translate,
        type,
        userReadableTime,
    ]);

    const breadCrumbLinks = [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.AuditLogs,
            name: translate({ id: 'CLIENT_AUDIT_LOG' }),
        },
    ];

    const headerButtons = useMemo(() =>
        <div>
            {isAdminRevampEnabled && <UiRefreshButton
                isValidating={isLoading}
                swrKey={[ auditUrl, pageNumber, language, partitionGlobalId ]} />}
            <Button
                style={{ margin: '0px 20px 0 20px' }}
                size='small'
                variant="contained"
                onClick={() => downloadLogs()}
                disabled={downloadInProgress}
                data-cy="ui-grid-primary-action-button"
            >
                {translate({ id: 'CLIENT_DOWNLOAD' })}
            </Button>
        </div>,
    [ downloadInProgress, downloadLogs, isAdminRevampEnabled, isLoading, language, pageNumber, partitionGlobalId, translate ]);

    return (
        isAdminRevampEnabled ?
            <UiPageContainer
                position='left'
                header={AdminBreadCrumbs(breadCrumbLinks)}
                primaryActions={headerButtons}
            >
                {grid}
            </UiPageContainer> :
            <UiPanel
                licenseInGracePeriodBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseInGracePeriodBanner />}
                licenseExpiredBanner={!process.buildConfigs.disableUserLicensing && <UiLicenseExpiredBanner />}
                trialPerSkuLicenseInGracePeriodBanners={!process.buildConfigs.disableUserLicensing && <UiTrialPerSkuLicenseInGracePeriodBanners />}
                licenseOverAllocationBanner={!process.buildConfigs.disableUserLicensing && <UiOverAllocationBanner />}
                header={{
                    title: translate({ id: 'CLIENT_AUDIT_LOG' }),
                    actions: (
                        headerButtons
                    ),
                }}
                data-cy="auditLog-panel"
            >
                {grid}
            </UiPanel>
    );
};

export default ({ route }: { route?: any }) => (
    <>
        <AuditPageComponent />
        {renderRoutes(route.routes)}
    </>
);
