import { useCentralErrorSetter } from '@experiences/error';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import type { FC } from 'react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAuthenticationScheme } from '../../../common/constants/ExternalIdentityProviderConstant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useExternalIdentity from '../../../common/hooks/useExternalIdentity';
import { useIsSecuritySettingsRevampEnabled } from '../../../common/hooks/useIsSecuritySettingsRevampEnabled';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import useShowRestartMessageDialog from '../../../common/hooks/useShowRestartMessageDialog';
import {
    createExternalIdentityProvider,
    updateExternalIdentityProvider,
} from '../../../services/identity/ExternalIdentityProviderService';
import { accountGlobalId } from '../../../store/selectors';
import {
    mapExternalIdentityPayloadToGoogleConfigData,
    mapGoogleConfigDataToExternalIdentityPayload,
} from '../../../util/ExternalIdentityProviderUtil';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../organizationsettings/AdminBreadCrumbs';
import type { IEditClientIdAndSecretData } from './EditClientIdAndSecretFormComponent';
import EditClientIdAndSecretFormComponent, { defaultEditClientIdAndSecretData } from './EditClientIdAndSecretFormComponent';
import type { IEditIdentityProviderData } from './EditIdentityProviderFormComponent';
import EditIdentityProviderFormComponent, { defaultEditIdentityProviderData } from './EditIdentityProviderFormComponent';

const useStyles = makeStyles(() =>
    createStyles({
        cancelButton: { marginRight: '10px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
    }),
);

export interface IGoogleConfigData extends IEditIdentityProviderData, IEditClientIdAndSecretData {}

const ConfigureGoogleComponent: FC = () => {
    const { formatMessage: translate } = useIntl();

    const isSecuritySettingsRevampEnabled = useIsSecuritySettingsRevampEnabled();
    const organizationName = useOrganizationName();
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        open, close,
    } = useModalState(isSecuritySettingsRevampEnabled
        ? RouteNames.SecuritySettings : RouteNames.AuthSettings);

    const classes = useStyles();
    const setErrorDialog = useCentralErrorSetter();
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const createDialog = useShowDialog();
    const showRestartMessageDialog = useShowRestartMessageDialog();
    const [ showClientSecretPlaceholder, setShowClientSecretPlaceholder ] = useState(false);
    const methods = useForm<IGoogleConfigData>({
        mode: 'onSubmit',
        defaultValues: {
            ...defaultEditIdentityProviderData,
            ...defaultEditClientIdAndSecretData,
        },
    });
    const {
        handleSubmit, formState, reset,
    } = useMemo(() => methods, [ methods ]);
    const fetchedExternalIdentity = useExternalIdentity(ExternalAuthenticationScheme.Google);

    const {
        isSubmitting, isDirty,
    } = formState;

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: IGoogleConfigData) => {
            const payload = mapGoogleConfigDataToExternalIdentityPayload(data, fetchedExternalIdentity);
            try {
                if (isSecuritySettingsRevampEnabled) {
                    const proceed = await createDialog({
                        title: translate({ id: 'CLIENT_IDP_SAVED_SUCCESSFULLY' }, { idp: 'Google' }),
                        body: <Typography>
                            {translate({ id: 'CLIENT_SUCCESSFULLY_SAVED' }, { idp: 'Google' })}
                        </Typography>,
                        icon: 'info',
                        showCancel: true,
                        primaryButtonText: translate({ id: 'CLIENT_ENABLE_NOW' }),
                        cancelButtonText: translate({ id: 'CLIENT_ENABLE_LATER' }),
                    });
                    payload.isActive = proceed;
                }
                if (fetchedExternalIdentity) {
                    await updateExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });
                } else {
                    await createExternalIdentityProvider({
                        ...payload,
                        partitionGlobalId,
                    });
                }
                createNotification(translate({ id: 'CLIENT_CONFIGURATION_UPDATED' }));
                await showRestartMessageDialog();
                close(true);
            } catch (error) {
                if (isSecuritySettingsRevampEnabled) {
                    setErrorDialog(translate({ id: 'CLIENT_CONFIGURE_GOOGLE_GENERIC_ERROR' }));
                } else {
                    setShowDrawerError(true);
                }
            }
        },
        [
            fetchedExternalIdentity,
            isSecuritySettingsRevampEnabled,
            createNotification,
            translate,
            showRestartMessageDialog,
            close,
            createDialog,
            partitionGlobalId,
            setErrorDialog,
        ],
    );

    useEffect(() => {
        if (fetchedExternalIdentity) {
            reset(mapExternalIdentityPayloadToGoogleConfigData(fetchedExternalIdentity));
            setShowClientSecretPlaceholder(true);
        }
    }, [ fetchedExternalIdentity, reset ]);

    const breadCrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: isSecuritySettingsRevampEnabled ? RouteNames.SecuritySettings : RouteNames.AuthSettings,
            name: translate({ id: 'CLIENT_SECURITY_SETTINGS' }),
        },
        {
            link: RouteNames.AuthSettingsConfigureSaml,
            name: translate({ id: 'CLIENT_GOOGLE_SSO_CONFIGURATION' }),
        },
    ], [ isSecuritySettingsRevampEnabled, organizationName, translate ]);

    const form = useMemo(() =>
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            actions={
                <div className={classes.actions}>
                    <Button
                        className={clsx(classes.cancelButton)}
                        onClick={() => close()}
                        color="primary">
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        type="submit"
                        loading={isSubmitting}
                        disabled={!isDirty}
                        variant="contained"
                        data-cy="configure-google-submit-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
            isDrawer={!isSecuritySettingsRevampEnabled}
            centerChild={isSecuritySettingsRevampEnabled}
            addScrollPadding={!isSecuritySettingsRevampEnabled}
        >
            <FormProvider {...methods}>
                <EditIdentityProviderFormComponent />
                <EditClientIdAndSecretFormComponent
                    showClientSecretPlaceholder={showClientSecretPlaceholder}
                    clientSecretRequired
                />
            </FormProvider>
        </UiForm>
    , [
        classes,
        close,
        isSecuritySettingsRevampEnabled,
        handleSubmit,
        isDirty,
        isSubmitting,
        methods,
        onSubmit,
        showClientSecretPlaceholder,
        translate,
    ]);

    return (
        isSecuritySettingsRevampEnabled ?
            <UiPageContainer
                header={AdminBreadCrumbs(breadCrumbLinks)}
                maxWidth="900px"
                position='center'>
                {form}
            </UiPageContainer>
            :
            <UiDrawer
                title={translate({ id: 'CLIENT_CONFIGURE_GOOGLE_INTEGRATION' })}
                drawerProps={{
                    anchor: 'right',
                    open,
                    onClose: () => close(),
                }}
                error={{
                    showError: showDrawerError,
                    message: translate({ id: 'CLIENT_CONFIGURE_GOOGLE_GENERIC_ERROR' }),
                }}
                themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
            >
                {form}
            </UiDrawer>
    );
};

export default ConfigureGoogleComponent;
