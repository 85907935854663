import { useRouteMatch } from 'react-router-dom';
import useSWR from 'swr';

import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../services/organization/TenantService';

const useTenantTagsViewModel = () => {
    const match = useRouteMatch<{ tenantId: string }>();

    // Component state
    const tenantId = match.params.tenantId;

    // SWR fetch
    const { data: tenant } = useSWR<ITenant>(
        (tenantId && !process.buildConfigs.showForMSI)
            ? [ `${organizationManagementTenantUri}/tenantById`, tenantId ]
            : null,
        getTenantById);

    return {
        tenantId,
        tenant,
    };
};

export default useTenantTagsViewModel;
