import {
    EcommerceBusinessFeaturesForCloud,
    EcommerceContactSalesLinkComponent,
    EcommercePlanSummary,
    EcommerceProductsTable,
    EcommerceProvider,
} from '@experiences/ecommerce';
import { GlobalStyles } from '@experiences/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import {
    Button,
    CircularProgress,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';

import { accountLogicalName } from '../../store/selectors';
import EcommerceDiscountBanner from './subcomponents/EcommerceDiscountBanner';
import useEcommerceIndividualProductsViewModel from './useEcommerceIndividualProductsViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        individualProductsContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            maxWidth: '100%',
        },
        individualProductsColumns: {
            display: 'flex',
            gap: '20%',
            justifyContent: 'center',
            width: '100%',
        },
        mainSection: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            maxWidth: '860px',
        },
        summarySection: {
            alignSelf: 'stretch',
            justifyContent: 'center',
        },
        summaryWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '50%',
            borderRadius: '16px',
        },
        summaryComponent: {
            display: 'flex',
            width: '387px',
            height: '481px',
            backgroundColor: theme.palette.semantic.colorChipInfoBackground,
            borderRadius: '16px',
            marginTop: '60px',
        },
        separator: {
            width: '100%',
            height: '1px',
            background: theme.palette.semantic.colorBorderDisabled,
        },
        separatorParent: {
            paddingTop: '40px',
            paddingBottom: '40px',
        },
        titleRow: {
            display: 'flex',
            marginTop: '20px',
        },
        pageTitle: {
            fontWeight: '600',
            fontSize: '24px',
            lineHeight: '32px',
            color: theme.palette.semantic.colorForeground,
        },
        backToPresetsButton: {
            color: 'black',
            fontSize: '10px',
            borderRadius: '5px',
            margin: '10px 0px',
            justifyContent: 'flex-start',
            cursor: 'pointer',
            maxWidth: '200px',
        },
        salesLink: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '24px',
        },
        buyEnterpriseText: { paddingRight: '4px' },
        loading: {
            position: 'absolute',
            top: '50%',
            left: '50%',
        },
    }),
}));

export const EcommerceIndividualProductsComponent: React.FC = () => {
    const classes = useStyles();
    const currentAccountName = useSelector(accountLogicalName);
    const { formatMessage: translate } = useIntl();

    const {
        defaultProductsToRender, addonProductsToRender, customPlanPackage, useFormMethods, updatePlanSummary,
        productsPricesInAllCurrencies, loading, isTaxLoading, currentSkuPackageDetails, isMonthlyPlanType, selectedCurrency, isFormValid,
        setSelectedCurrency, backToPresets, checkPlusInput,
    } = useEcommerceIndividualProductsViewModel(currentAccountName);

    return (
        (productsPricesInAllCurrencies === undefined) ? (
            <div
                className={clsx(classes.loading)}
                data-cy="circular-progress">
                <CircularProgress />
            </div>
        ) :
            <FormProvider {...useFormMethods}>
                <form
                    className={classes.individualProductsContent}
                >
                    {customPlanPackage && (<div className={classes.individualProductsColumns}>
                        <div className={classes.mainSection}>
                            <EcommerceDiscountBanner />
                            <div className={classes.titleRow}>
                                <Typography
                                    className={classes.pageTitle}
                                    variant="h1">
                                    {translate({ id: 'CLIENT_ECOMMERCE_BUY_UI_PATH_AUTOMATION_CLOUD_PRO' })}
                                </Typography>
                            </div>
                            {isMonthlyPlanType &&
                                <Typography>
                                    {translate({ id: 'CLIENT_ECOMMERCE_PAY_MONTHLY_CANCEL_ANYTIME' })}
                                </Typography>}
                            <Button
                                className={classes.backToPresetsButton}
                                data-cy="ecommerce-back-to-presets"
                                startIcon={<ArrowBackIcon />}
                                onClick={backToPresets}>
                                <Typography>
                                    {translate({ id: 'CLIENT_ECOMMERCE_BACK_TO_PRESET_OFFERS' })}
                                </Typography>
                            </Button>
                            <EcommerceProductsTable
                                productsToRender={defaultProductsToRender}
                                useFormMethods={useFormMethods}
                                updatePlanSummary={updatePlanSummary}
                                customPlanPackage={customPlanPackage}
                                selectedCurrency={selectedCurrency}
                                setSelectedCurrency={setSelectedCurrency}
                                checkPlusInput={checkPlusInput}
                                isMonthlyPlanType={isMonthlyPlanType}
                                showCurrencyDropdown
                                tableTitleTranslationId="CLIENT_ECOMMERCE_TABLE_DEFAULT_TITLE"
                                showNumbers
                            />
                            <div className={classes.separatorParent}>
                                <div className={classes.separator} />
                            </div>
                            <EcommerceBusinessFeaturesForCloud />
                            <div className={classes.separatorParent}>
                                <div className={classes.separator} />
                            </div>
                            <EcommerceProductsTable
                                productsToRender={addonProductsToRender}
                                useFormMethods={useFormMethods}
                                updatePlanSummary={updatePlanSummary}
                                customPlanPackage={customPlanPackage}
                                selectedCurrency={selectedCurrency}
                                setSelectedCurrency={setSelectedCurrency}
                                isMonthlyPlanType={isMonthlyPlanType}
                                checkPlusInput={checkPlusInput}
                                tableTitleTranslationId="CLIENT_ECOMMERCE_TABLE_ADDONS_TITLE" />
                        </div>
                        <div className={classes.summarySection}>
                            <div className={classes.salesLink}>
                                <Typography className={classes.buyEnterpriseText}>
                                    {translate({ id: 'CLIENT_ECOMMERCE_BUYING_FOR_AN_ENTERPRISE' })}
                                </Typography>
                                <EcommerceContactSalesLinkComponent />
                            </div>
                            <div className={classes.summaryWrapper}>
                                <div className={classes.summaryComponent}>
                                    <EcommercePlanSummary
                                        {...currentSkuPackageDetails}
                                        products={customPlanPackage.productQuantities}
                                        taxLoading={isTaxLoading}
                                        currency={selectedCurrency}
                                        price={currentSkuPackageDetails.prices[
                                            currentSkuPackageDetails.planType]?.[selectedCurrency]}
                                        productsPricesInAllCurrencies={productsPricesInAllCurrencies}
                                        discountedPrice={currentSkuPackageDetails.discountedPrices[
                                            currentSkuPackageDetails.planType]?.[selectedCurrency]}
                                        discountDetails={currentSkuPackageDetails.discountDetails}
                                        productPricesLoading={loading}
                                        isFirstSubscription
                                        planPackage={customPlanPackage}
                                        isFormValid={isFormValid}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>)}
                </form>
            </FormProvider>
    );
};

const EcommerceIndividualProducts = ({ route }: { route?: any }) => (
    <EcommerceProvider>
        <EcommerceIndividualProductsComponent />
        {renderRoutes(route.routes)}
    </EcommerceProvider>
);
export default EcommerceIndividualProducts;
