import {
    AccountLicense,
    Entitlements,
    TelemetryTitle,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditIcon from '@mui/icons-material/Edit';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import {
    CircularProgress,
    Divider,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import type { Row } from 'react-table';
import useSWR, { mutate } from 'swr';

import { IPRestrictionLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import type {
    IIPNetwork,
    IIPRestrictionStatus,
} from '../../../../common/interfaces/iprestriction';
import { getIpConfigurationStatus } from '../../../../services/access-policy/IPConfigurationStatusService';
import {
    getCurrentIp,
    getIpNetworkByOrganizationId,
    ipNetworkUrl,
} from '../../../../services/access-policy/IPNetworkService';
import { isEntitled } from '../../../../services/licensing/EntitlementsService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../../store/selectors';
import UiAlertBanner from '../../../common/UiAlertBanner';
import { UiGrid } from '../../../common/UiGrid';
import type {
    IAction,
    IActionHeader,
} from '../../../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../../../common/UiGrid/grid';
import UpgradeForFeature from '../../../common/UpgradeForFeature';
import IPRestrictionConfirmationDialogBody from './IPRestrictionConfirmationDialogBody';
import IPRestrictionDeleteDialogBody from './IPRestrictionDeleteDialogBody';
import { validateIp } from './IPRestrictionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        headerButton: { marginLeft: '5px' },
        banner: {
            fontWeight: 600,
            fontSize: '14px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
        },
        centerLoader: { margin: 'auto' },
        container: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
        },
        enforcementTypeSelect: { width: '240px' },
        IPRestrictionDescription: { marginBottom: '8px' },
        label: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '8px',
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 3,
            marginRight: '24px',
        },
        link: { marginLeft: '8px' },
        mainContent: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: '24px',
        },
    }),
);

const ipStatusKey = '/api/accessPolicy/ipRangeStatus';
export const ipCurrentKey = '/api/accessPolicy/ip';
const entitlementsKey = '/api/license/accountant/Entitlement/';

const IPRestrictionComponent: React.FC = () => {

    const classes = useStyles();
    const EnableSecuritySettingsIPRestrictionAudit = useFeatureFlagValue(Features.EnableSecuritySettingsIPRestrictionAudit.name);
    const EnableIPRestrictionBulkDelete = useFeatureFlagValue(Features.EnableIPRestrictionBulkDelete.name);
    const history = useHistory();
    const createDialog = useShowDialog();
    const getRoute = useRouteResolver();
    const getLocalizedLink = useDocumentationLinks();

    const partitionGlobalId = useSelector(accountGlobalId);
    const isAdmin = useSelector(isAdminSelector);

    const { formatMessage: translate } = useIntl();

    const { data: hasEntitlement } =
        useSWR<boolean, Error>([ entitlementsKey, partitionGlobalId, Entitlements.IpRestrictions ],
            (key, organizationId, entitlement) => isEntitled(entitlement));

    const [ ipStatus, setIpStatus ] = useState<EnforcementType>(EnforcementType.DISABLED);

    const {
        data: ipRanges, isValidating: isValidatingIpRanges, error: ipRangesError,
    } = useSWR<IIPNetwork[], Error>(
        [ ipNetworkUrl, partitionGlobalId ],
        getIpNetworkByOrganizationId,
    );

    const {
        data: ipStatusData, isValidating: isValidatingStatus, error: ipStatusError,
    } = useSWR<IIPRestrictionStatus, Error>(
        [ ipStatusKey, partitionGlobalId ],
        getIpConfigurationStatus,
    );

    const { data: currentUserIp } = useSWR<string, Error>([ ipCurrentKey ], getCurrentIp);

    const inIpRange = useMemo(() => {
        if (!currentUserIp || !ipRanges) {
            return false;
        }
        return validateIp(currentUserIp, ipRanges?.map(val => val.ipNetwork));
    }, [ currentUserIp, ipRanges ]);

    const refreshGrid = useCallback(() => {
        mutate([ ipNetworkUrl, partitionGlobalId ]);
    }, [ partitionGlobalId ]);

    const refreshRadioGroup = useCallback(() => {
        mutate([ ipStatusKey, partitionGlobalId ]);
    }, [ partitionGlobalId ]);

    const ipConfigStatus = useMemo(
        () => ({
            [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_ENABLED',
            [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_DISABLED',
            [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_AUDIT',
        }),
        [],
    );

    const handleAddIPRange = useCallback(() => {
        history.push({
            pathname: `${getRoute(RouteNames.IPRestriction)}/add`,
            state: { currentIp: currentUserIp },
        });
    }, [ currentUserIp, getRoute, history ]);

    const clickedDelete = useCallback(
        async (row: Row<IIPNetwork>) => {
            await createDialog({
                title: translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_CONFIRMATION' }),
                customDialogContent: IPRestrictionDeleteDialogBody,
                customDialogContentProps: {
                    currentIp: currentUserIp,
                    ipRange: row?.original,
                    ipStatus,
                    refreshCallback: () => {
                        refreshGrid();
                    },
                },
                icon: 'error',
            });
        },
        [ createDialog, currentUserIp, ipStatus, refreshGrid, translate ],
    );

    const handleImportCsv = useCallback(() => {
        history.push(`${getRoute(RouteNames.IPRestrictionBulkImport)}`);
    }, [ getRoute, history ]);

    const extraActionHeaderButtons: IActionHeader[] = useMemo(() => {
        const importCsv: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_UPLOAD_CSV' }),
            icon: <UploadOutlinedIcon />,
            click: handleImportCsv,
            disable: false,
            invisible: !isAdmin,
            className: classes.headerButton,
            dataCy: 'import-csv-button',
        };
        const addIPRange: IActionHeader = {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_IP_RANGE' }),
            icon: <AddIcon />,
            click: handleAddIPRange,
            disable: false,
            invisible: !isAdmin,
            variant: 'contained',
            className: classes.headerButton,
            dataCy: 'add-ip-restriction-button',
        };

        return [ importCsv, addIPRange ];
    }, [ translate, handleImportCsv, isAdmin, classes.headerButton, handleAddIPRange ]);

    useEffect(() => {
        if (ipStatusData) {
            setIpStatus(ipStatusData.status ?? EnforcementType.DISABLED);
        }
    }, [ ipStatusData, ipStatusError, setIpStatus ]);

    const gridData = useMemo(() => ipRanges?.filter((ipRange) => ipRange.type === 'Customer'), [ ipRanges ]);

    const rowActions = useMemo(() => {
        const editIPRange: IAction<IIPNetwork> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_EDIT' }),
            tooltip: translate({ id: 'CLIENT_EDIT' }),
            actionType: GridActionType.Row,
            icon: <EditIcon />,
            click: row => {
                const ipRange: IIPNetwork = row.original;
                history.push({
                    pathname: `${getRoute(RouteNames.IPRestriction)}/edit/${row.original.id}`,
                    state: { ipRange },
                });
            },
            dataCy: 'edit-ip-restriction-button',
        };

        const deleteIPRange: IAction<IIPNetwork> = {
            type: ButtonType.Icon,
            label: translate({ id: 'CLIENT_DELETE' }),
            tooltip: translate({ id: 'CLIENT_DELETE' }),
            actionType: GridActionType.Row,
            icon: <DeleteIcon />,
            click: clickedDelete,
            dataCy: 'delete-ip-restriction-button',
        };
        return isAdmin ? [ editIPRange, deleteIPRange ] : [];
    }, [ clickedDelete, getRoute, history, isAdmin, translate ]);

    const dialogTitle = useMemo(() => ({
        [EnforcementType.DISABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_DISABLE',
        [EnforcementType.ENABLED]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_ENABLE',
        [EnforcementType.AUDIT]: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE_HEADER_AUDIT',
    }), []);

    const handleChange = useCallback(async (e, value) => {
        await createDialog({
            title: translate({ id: dialogTitle[value as EnforcementType] }),
            customDialogContent: IPRestrictionConfirmationDialogBody,
            customDialogContentProps: {
                inIpRange,
                ipStatus: value,
                refreshCallback: (newVal: React.SetStateAction<EnforcementType>) => {
                    setIpStatus(newVal);
                    refreshRadioGroup();
                },
            },
            icon: 'info',
        });
    }, [ createDialog, dialogTitle, inIpRange, refreshRadioGroup, translate ]);

    const options = useMemo(() => {
        const opt = [
            {
                label: translate({ id: ipConfigStatus.Disabled }),
                value: EnforcementType.DISABLED,
                dataCy: 'disable',
            },
            {
                label: translate({ id: ipConfigStatus.Enabled }),
                value: EnforcementType.ENABLED,
                dataCy: 'enable',
            },
        ];
        if (EnableSecuritySettingsIPRestrictionAudit) {
            opt.push({
                label: translate({ id: ipConfigStatus.Audit }),
                value: EnforcementType.AUDIT,
                dataCy: 'audit',
            });
        }
        return opt;
    }, [ EnableSecuritySettingsIPRestrictionAudit, ipConfigStatus.Audit, ipConfigStatus.Disabled, ipConfigStatus.Enabled, translate ]);

    const auditBanner = useMemo(() => (
        <UiAlertBanner
            type="info"
            closeable={false}
            enterpriseTrialAlertBar
            dataCy='ip-restriction-audit-banner'
        >
            <div className={classes.banner}>
                {translate({ id: 'CLIENT_IP_RESTRICTION_AUDIT_BANNER_MESSAGE' })}
                <Link
                    className={classes.link}
                    data-cy="ip-restriction-banner-audit-log-link"
                    href={getRoute(RouteNames.AuditLogs)}
                    underline='none'
                >
                    {translate({ id: 'CLIENT_IP_RESTRICTION_AUDIT_LINK' })}
                </Link>
            </div>
        </UiAlertBanner>)
    , [ classes.banner, classes.link, getRoute, translate ]);

    const allowIpRestrictions = useMemo(() => hasEntitlement,
        [ hasEntitlement ]);

    const clickedBulkDelete = useCallback(
        async (rows: Array<Row<IIPNetwork>>) => {
            const networks: IIPNetwork[] = rows.map(row => row.original);
            await createDialog({
                title: translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_CONFIRMATION' }),
                customDialogContent: IPRestrictionDeleteDialogBody,
                customDialogContentProps: {
                    currentIp: currentUserIp,
                    ipRange: networks,
                    ipStatus,
                    refreshCallback: () => {
                        refreshGrid();
                    },
                },
                icon: 'error',
            });
        },
        [ createDialog, translate, currentUserIp, ipStatus, refreshGrid ],
    );

    if (hasEntitlement == null) {
        return <div className={classes.centerLoader}>
            <CircularProgress />
        </div>;
    }

    if (!allowIpRestrictions) {
        return <UpgradeForFeature
            upgradeTitle={translate({ id: 'CLIENT_UPGRADE_IP_TITLE' })}
            upgradeMessage={translate({ id: 'CLIENT_UPGRADE_IP_DESCRIPTION' })}
            documentationLink={getLocalizedLink(IPRestrictionLink)}
            level={AccountLicense.ENTERPRISE}
            telemetryTitle={TelemetryTitle.SecuritySettings.IPRestriction} />;
    }

    return (
        <div
            className={classes.mainContent}
            data-cy='ip-restriction-container'
        >
            {ipStatus === EnforcementType.AUDIT && auditBanner}
            <div className={classes.container}>
                <div className={classes.leftContainer}>
                    <Typography className={classes.IPRestrictionDescription}>
                        {translate({ id: 'CLIENT_IP_RESTRICTION_DESCRIPTION' })}
                    </Typography>
                    <UiGrid<IIPNetwork>
                        data={gridData ?? []}
                        dataCy="ip-restriction-ui-grid"
                        error={ipRangesError}
                        loading={isValidatingIpRanges}
                        checkbox={isAdmin && EnableIPRestrictionBulkDelete}
                        columns={[
                            {
                                accessor: 'name',
                                Header: translate({ id: 'CLIENT_IP_SET_NAME' }),
                                sortName: translate({ id: 'CLIENT_IP_SET_NAME' }),
                                width: 30,
                                sortType: 'alphanumeric',
                            },
                            {
                                accessor: 'ipNetwork',
                                Header: translate({ id: 'CLIENT_IP_RANGE' }),
                                sortName: translate({ id: 'CLIENT_IP_RANGE' }),
                                width: 60,
                                sortType: 'alphanumeric',
                            },
                        ]}
                        pagination
                        filters
                        search
                        searchPlaceholder={translate({ id: 'CLIENT_SEARCH_IP_RANGE_TEXT' })}
                        extraActionButtons={extraActionHeaderButtons}
                        rowActions={rowActions}
                        tableActions={
                            (isAdmin && EnableIPRestrictionBulkDelete)
                                ? [
                                    {
                                        type: ButtonType.Button,
                                        label: translate({ id: 'CLIENT_DELETE' }),
                                        icon: <DeleteForeverOutlinedIcon />,
                                        actionType: GridActionType.Selected,
                                        click: clickedBulkDelete,
                                    },
                                ]
                                : []
                        }
                        wrapHeader
                    />
                </div>
                <Divider
                    orientation='vertical'
                    flexItem />

                <div className={classes.rightContainer}>
                    <Typography
                        id="enforcement-type"
                        className={classes.label}>
                        {translate({ id: 'CLIENT_IP_RESTRICTION_ENFORCEMENT_TYPE' })}
                    </Typography>
                    {!isValidatingStatus ?
                        <RadioGroup
                            aria-labelledby="enforcement-type"
                            onChange={handleChange}
                            value={ipStatus}
                        >
                            {options.map((option, i) => (
                                <FormControlLabel
                                    key={i}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                    data-cy={`ip-restriction-radio-${option.dataCy}`}
                                />
                            ))}
                        </RadioGroup> :
                        <div className={classes.centerLoader}>
                            <CircularProgress />
                        </div>}
                </div>
            </div>
        </div>
    );
};

export default IPRestrictionComponent;
