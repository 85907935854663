import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { isString } from 'lodash';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import type { IIPNetwork } from '../../../../common/interfaces/iprestriction';
import {
    deleteBulkIpNetworks,
    deleteIpNetwork,
} from '../../../../services/access-policy/IPNetworkService';
import { accountGlobalId } from '../../../../store/selectors';
import { UiDeleteButton } from '../../../common/UiDeleteButton/UiDeleteButton';
import { validateIp } from './IPRestrictionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmDeleteSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const IPRestrictionDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, currentIp, ipRange, ipStatus, refreshCallback,
}) => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const [ loading, setLoading ] = useState(false);

    const isBulkDelete = useMemo(() => Array.isArray(ipRange), [ ipRange ]);
    const inIpRange = useMemo(() => {
        if (isBulkDelete) {
            const deleteList = ipRange.map((range: IIPNetwork) => range.ipNetwork);
            return validateIp (currentIp, deleteList);
        }
        return validateIp(currentIp, [ ipRange.ipNetwork ]);

    }
    , [ currentIp, ipRange, isBulkDelete ]);

    const enforcementEnabled = useMemo(() => ipStatus === EnforcementType.ENABLED, [ ipStatus ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.INPROGRESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const deleteAndClose = useCallback(async () => {
        try {
            setLoading(true);
            if (isBulkDelete) {
                const deleteIpNetworks = ipRange.map((range: IIPNetwork) => range.ipNetwork);
                await deleteBulkIpNetworks(partitionGlobalId, deleteIpNetworks);
            } else {
                await deleteIpNetwork(partitionGlobalId, ipRange.id);
            }
            createNotification(
                isBulkDelete
                    ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BULK_SUCCESS_NOTIFICATION' })
                    : translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_SUCCESS_NOTIFICATION' }, { 0: ipRange.name }),
                notificationType.SUCCESS,
            );
            refreshCallback();
            closeDialog(true);
        } catch (error) {
            createNotification(
                isBulkDelete
                    ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BULK_FAILURE_NOTIFICATION' })
                    : translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_FAILURE_NOTIFICATION' }, { 0: ipRange.name }),
                notificationType.ERROR,
            );
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, [
        isBulkDelete,
        createNotification,
        translate,
        ipRange,
        refreshCallback,
        closeDialog,
        partitionGlobalId,
        getErrorObject,
        getErrorMessage,
        setErrorMessage,
    ]);

    const deleteBlocked = useMemo(() =>
        <Typography data-cy="delete-blocked-message">
            {translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BLOCKED' })}
        </Typography>
    , [ translate ]);

    const deleteMessage = useMemo(() =>

        <Typography data-cy="delete-confirmation-ask">
            {isBulkDelete
                ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_ASK_BULK' })
                : <FormattedMessage
                    id="CLIENT_IP_RESTRICTION_DELETE_ASK"
                    values={{ 0: ipRange.name }}
                />}
        </Typography>
    , [ ipRange.name, isBulkDelete, translate ]);

    const deleteWarning = useMemo(() =>
        <Typography data-cy="delete-confirmation-type-warning">
            {isBulkDelete
                ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_WARNING_BULK' })
                : <FormattedMessage
                    id="CLIENT_IP_RESTRICTION_DELETE_WARNING"
                    values={{ 0: ipRange.name }}
                />}
        </Typography>

    , [ ipRange.name, isBulkDelete, translate ]);

    const deleteContent = useMemo(() => {
        if (enforcementEnabled && inIpRange) {
            return deleteBlocked;
        } else if (enforcementEnabled && !inIpRange) {
            return (
                <>
                    {deleteWarning}
                    <br />
                    {deleteMessage}
                </>
            );
        }
        return deleteMessage;

    }, [ deleteBlocked, deleteMessage, deleteWarning, enforcementEnabled, inIpRange ]);

    const buttons = useMemo(() => (
        <div className={classes.buttonContainer}>
            <Button
                key="cancelButton"
                className={classes.cancelButton}
                onClick={() => closeDialog()}
                color="primary"
                data-cy="close-button-confirmation"
            >
                {translate({ id: enforcementEnabled && inIpRange ? 'CLIENT_OK' : 'CLIENT_CANCEL' })}
            </Button>

            {!(enforcementEnabled && inIpRange) &&
            <UiDeleteButton
                data-cy="delete-button-confirmation"
                loading={loading}
                onClick={() => deleteAndClose()}
                variant="contained"
            >
                {translate({ id: 'CLIENT_DELETE' })}
            </UiDeleteButton>}
        </div>
    ), [ classes.buttonContainer, classes.cancelButton, closeDialog, deleteAndClose, enforcementEnabled, inIpRange, loading, translate ]);

    return (
        <>
            <div className={classes.confirmDeleteSection}>
                {deleteContent}
            </div>
            {buttons}
        </>
    );
};

export default IPRestrictionDeleteDialogBody;
