import { portalTelemetry } from '@experiences/telemetry';
import { useAuthContext } from '@experiences/util';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import platform from 'platform';
import {
    useCallback,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';

import useSessionId from '../auth/hooks/UseSessionId';
import { useIsAdminRevampEnabled } from '../common/hooks/useIsAdminRevampEnabled';
import { post } from '../services/utility/Requests.default';
import {
    accountGlobalId,
    accountType,
    profile,
    userGlobalId,
} from '../store/selectors';

const telemetryUrl = '/api/telemetry';

export const useTelemetryHelper = () => {

    const accountId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const subscriptionType = useSelector(accountType);
    const currentProfile = useSelector(profile);

    const sessionId = useSessionId();
    const {
        claims, token,
    } = useAuthContext();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const email = currentProfile ? currentProfile.emailId : claims?.email;

    const logEvent = useCallback(
        async (name: string, details: any = {}) => {
            const logEventUrl = `${telemetryUrl}/logCustomEvent`;
            try {
                await post(logEventUrl, {
                    body: {
                        name,
                        properties: {
                            ApplicationName: process.buildConfigs.telemetryApplicationName ?? 'UiPath.AutomationCloud',
                            CloudOrganizationId: accountId,
                            CloudUserId: userId,
                            CloudTenantId: '', // Purposefully left blank to match telemetry schema
                            SubscriptionType: subscriptionType,
                            SessionId: sessionId,
                            IsTestAccount: !!email?.endsWith('uipath-qa.com'),
                            ...details,
                        },
                    },
                });
            } catch (e) {
                portalTelemetry.trackTrace({
                    message: `Error tracking telemetry event ${name}`,
                    severityLevel: SeverityLevel.Error,
                }, { revampEnabled: isAdminRevampEnabled });
            }
        },
        [ accountId, userId, subscriptionType, sessionId, email, isAdminRevampEnabled ],
    );

    const logTrace = useCallback(
        async (message: string, details: any = {}) => {
            const logTraceUrl = `${telemetryUrl}/logCustomTrace`;

            try {
                await post(logTraceUrl, {
                    body: {
                        message,
                        severityLevel: SeverityLevel.Information,
                        properties: details,
                    },
                });
            } catch (e) {
                portalTelemetry.trackTrace({
                    message: `Error tracking telemetry trace ${message}`,
                    severityLevel: SeverityLevel.Error,
                }, { revampEnabled: isAdminRevampEnabled });
            }
        }, [],
    );

    return {
        logEvent,
        logTrace,
    };
};

export const useDeviceInfo = () => {

    const deviceInfo = useMemo(() => ({
        Orientation: screen.orientation?.type,
        ScreenWidth: screen.width,
        ScreenHeight: screen.height,
        WindowWidth: window.innerWidth,
        WindowHeight: window.innerHeight,
        DeviceType: `${platform?.manufacturer} ${platform?.product}`,
        BrowserVersion: `${platform?.name} ${platform?.version}`,
        OSVersion: `${platform?.os?.family} ${platform?.os?.version}`,
        OSArchitecture: `${platform?.os?.architecture} bit`,
        SystemLanguage: navigator.language,
    }), []);

    return deviceInfo;
};
