import { useGetErrorInfo } from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type {
    IProductAllocation,
    ITabEntitlementUsage,
} from '@experiences/interfaces';
import {
    getSortedEntitlementUsageConfigurations,
    getSortedRobotsAndServicesProductConfigurations,
    getSortedUserBundleConfigurations,
} from '@experiences/util';
import {
    Grid,
    Skeleton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import sortBy from 'lodash/sortBy';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import {
    useDispatch,
    useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import useSWR, { mutate } from 'swr';

import {
    dashboardLicenses,
    LegacyProductsToUserBundleLicenseMap,
} from '../../common/constants/Constant';
import type { IRobotAndServicesLicenses } from '../../common/interfaces/licenses';
import { getTenantProductAllocations } from '../../services/licensing/LicenseService';
import {
    getRobotsAndServices,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import { setUnlicensedState } from '../../store/action/UserProfileAction';
import {
    accountGlobalId,
    accountType,
    isHostModeSelector,
    userGlobalId,
} from '../../store/selectors';
import {
    computeProductProperties,
    extractEntitlementUsages,
    extractProducts,
} from '../../util/LicenseUtil';
import RobotUsage from '../usage/helpers/RobotUsage';
import { HomePageLicenseAllocations } from './HomePageLicenseAllocations';
import { LicensingProductAllocations } from './LicensingProductAllocations';
import UsageDetailsComponent from './UsageDetailsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        parent: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        product: { flex: '50%' },
        content: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '8px',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginLeft: '24px',
            marginBottom: '6px',
        },
    }),
);

export const ViewLicensesPanel: React.FC<{
    selectedTab: string;
    isHome?: boolean;
    useLegacyProducts?: boolean;
    isServiceMode?: boolean;
    tenantId?: string;
    tenantIds?: string[];
}> = ({
    selectedTab, isHome = false, useLegacyProducts = false, isServiceMode = false, tenantId = '', tenantIds,
}) => {
    const enableRobotUnits = useFeatureFlagValue(Features.EnableRobotUnits.name);
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const history = useHistory();
    const isHostMode = useSelector(isHostModeSelector);
    const accountTypeSelector = useSelector(accountType);
    const userId = useSelector(userGlobalId);
    const accountId = useSelector(accountGlobalId);
    const dispatch = useDispatch();
    const { getErrorObject } = useGetErrorInfo();
    const autodetectWhenLicenseIsRemovedByHostAdmin = useMemo(
        () => process.buildConfigs.autodetectWhenLicenseIsRemovedByHostAdmin,
        [],
    );
    const url = useMemo(() => {
        if (isServiceMode) {
            return tenantId ? `/api/licensing/tenantProductAllocation` : null;
        }

        return `${licenseManagementAccountUrl}/available`;
    }, [ isServiceMode, tenantId ]);

    const {
        data: licenses,
        isValidating,
        error: requestError,
    } = useSWR<IRobotAndServicesLicenses, Error>(
        [ url, tenantId ],
        () =>
            isServiceMode
                ? getTenantProductAllocations(accountId, tenantId)
                : getRobotsAndServices(userId, accountId),
    );

    useEffect(() => {
        history.listen(async (historyObj) => {
            if (historyObj.state && (historyObj.state as any)['refresh']) {
                await mutate([ `${licenseManagementAccountUrl}/available`, '' ]);
            }
        });
    }, [ history ]);

    useEffect(() => {
        (async () => {
            if (!isValidating && requestError) {
                const errorObject = await getErrorObject(requestError);
                if (
                    errorObject.response?.status === 404 &&
                    !isServiceMode &&
                    autodetectWhenLicenseIsRemovedByHostAdmin
                ) {
                    dispatch(setUnlicensedState());
                }
            }
        })();
    }, [
        isValidating,
        requestError,
        getErrorObject,
        isServiceMode,
        autodetectWhenLicenseIsRemovedByHostAdmin,
        dispatch,
    ]);

    const mapLegacyProducts = useCallback((productAllocations: IProductAllocation[]) => {
        const legacyProducts = productAllocations
            .filter(p => (p.allocated > 0 || p.total > 0) && !!LegacyProductsToUserBundleLicenseMap[p.code])
            .map(product => ({
                ...product,
                code: LegacyProductsToUserBundleLicenseMap[product.code],
            }));

        return sortBy(legacyProducts, product =>
            Object.values(LegacyProductsToUserBundleLicenseMap).indexOf(product.code),
        );
    }, []);

    const productsToRender = useMemo(() => {
        const mlKeys = licenses?.mlKeys;
        const productAllocations =
            selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES || useLegacyProducts
                ? licenses?.productAllocations
                : licenses?.userLicensingBundles;

        if (useLegacyProducts && productAllocations) {
            return mapLegacyProducts(productAllocations);
        }

        if (!productAllocations || (!mlKeys && !isServiceMode)) {
            return undefined;
        }

        const productConfigs =
            selectedTab === dashboardLicenses.USERS
                ? getSortedUserBundleConfigurations()
                : getSortedRobotsAndServicesProductConfigurations();

        // map product allocations to product configurations by `code`
        const foundProducts = extractProducts(productConfigs, productAllocations, isServiceMode, isHome);

        return selectedTab === dashboardLicenses.USERS
            ? foundProducts
            : foundProducts.map(p => computeProductProperties(p, isHostMode, mlKeys, accountTypeSelector));
    }, [
        licenses?.mlKeys,
        licenses?.productAllocations,
        licenses?.userLicensingBundles,
        selectedTab,
        useLegacyProducts,
        isServiceMode,
        mapLegacyProducts,
        isHome,
        isHostMode,
        accountTypeSelector,
    ]);

    const entitlementUsagesToRender = useMemo(() => {
        const orderedEntitlementUsages = licenses?.entitlementUsages ?
            extractEntitlementUsages(getSortedEntitlementUsageConfigurations(), licenses?.entitlementUsages)
            : [] ;
        return selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES ?
            orderedEntitlementUsages
            : [] as ITabEntitlementUsage[];
    }, [ licenses?.entitlementUsages, selectedTab, useLegacyProducts ]);

    const hasDocumentUnderstandingLicense = useMemo(() => productsToRender ? productsToRender.some(p => p.code === 'TIE') : false, [ productsToRender ]);

    const hasRobotUnitsLicense = useMemo(() => productsToRender ? enableRobotUnits && productsToRender.some(p => p.code === 'RU') : false, [ productsToRender, enableRobotUnits ]);

    const hasAiUnitsLicense = useMemo(() => productsToRender ? productsToRender.some(p => p.code === 'AIU') : false, [ productsToRender ]);

    const showLicenseUsage = useMemo(() => hasRobotUnitsLicense || hasDocumentUnderstandingLicense || hasAiUnitsLicense, [ hasDocumentUnderstandingLicense, hasRobotUnitsLicense, hasAiUnitsLicense ]);

    return productsToRender ? (
        productsToRender.length === 0 ? (
            <Typography className={classes.noLicensesAvailable}>
                {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
            </Typography>
        ) : (
            <div>
                {isHome ?
                    <HomePageLicenseAllocations
                        products={productsToRender}
                        entitlementUsages={entitlementUsagesToRender}
                    /> :
                    <LicensingProductAllocations
                        products={productsToRender}
                        entitlementUsages={entitlementUsagesToRender}
                        loading={isValidating} />}
                {selectedTab === dashboardLicenses.ROBOTS_AND_SERVICES && (
                    <div>
                        { showLicenseUsage && (
                            <>
                                <Typography className={classes.subTitle}>
                                    {translate({ id: 'CLIENT_USAGE_TITLE' })}
                                </Typography>
                                { hasDocumentUnderstandingLicense && (
                                    <RobotUsage
                                        tenantIds={tenantIds}
                                        isServiceMode={isServiceMode}
                                        productsToDisplay={[ 'TIE' ]}
                                    />
                                )}
                                {hasAiUnitsLicense && (
                                    <RobotUsage
                                        tenantIds={tenantIds}
                                        isServiceMode={isServiceMode}
                                        productsToDisplay={[ 'AIU' ]}
                                    />
                                )}
                                {hasRobotUnitsLicense && (
                                    <RobotUsage
                                        tenantIds={tenantIds}
                                        isServiceMode={isServiceMode}
                                        productsToDisplay={[ 'RU' ]}
                                    />
                                )}
                            </>
                        )}
                        { !isServiceMode &&
                        <UsageDetailsComponent entitlement='APICALLS' /> }
                    </div>
                )}
            </div>
        )
    ) : (
        <Grid
            container
            spacing={3}>
            {(selectedTab === dashboardLicenses.USERS ? [ 1, 2 ] : [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]).map(number => (
                <Grid
                    key={number}
                    item
                    xs={6}>
                    <div className={classes.content}>
                        <div style={{ flex: 0.9 }}>
                            <Skeleton
                                variant="text"
                                width={150}
                                style={{ marginBottom: '8px' }} />
                            <Skeleton
                                variant="rectangular"
                                height={36} />
                        </div>
                        <Skeleton
                            variant="circular"
                            height={28}
                            width={28} />
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
