import { ReservedUsernames } from '../../common/constants/UsersConstant';
import type { IUserCIS } from '../../common/interfaces/cis/user';
import type { IUserCISResponse } from '../../component/users/UsersCISPageComponent';

export function cleanUserCISResponse(originalResponse: IUserCISResponse): IUserCISResponse {
    const results: IUserCIS[] = originalResponse.results.filter(
        user => !ReservedUsernames.some(reservedUsername => reservedUsername === user.userName),
    );
    return {
        totalCount: originalResponse.totalCount,
        results,
    };
}
