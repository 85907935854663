import {
    UiImageSelector,
    UiProgressButton,
} from '@experiences/ui-common';
import { InfoOutlined } from '@mui/icons-material';
import {
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    emailConfigurationUrl,
    getEmailConfiguration,
    resetEmailConfiguration,
    updateEmailConfiguration,
} from '../../../../services/notification-preferences';
import { accountGlobalId } from '../../../../store/selectors';
import UiForm from '../../../common/UiForm';
import { useTenantsContext } from '../../../tenants/TenantsContextProvider';
import type { IEmailConfiguration } from '../../interfaces/notificationSettings';

const useStyles = makeStyles(theme =>
    createStyles({
        input: { marginTop: '24px' },
        inputSender: { marginTop: '16px' },
        infoIcon: {
            marginLeft: Tokens.Padding.PadM,
            height: Tokens.FontFamily.FontXsLineHeight,
            width: Tokens.Padding.PadXxxl,
            marginTop: '3px',
        },
        inputLabel: {
            fontWeight: Tokens.FontFamily.FontBrandH4Weight,
            fontSize: Tokens.FontFamily.FontMSize,
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: Tokens.Padding.PadXxl },
        rowGroup: {
            display: 'flex',
            flexDirection: 'row',
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        cancelButton: { marginRight: Tokens.Padding.PadXl },
        container: {
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            width: '100%',
            paddingLeft: '20px',
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '50%',
            flex: 1,
            marginRight: '24px',
        },
        digestRadio: { marginRight: '25px' },
    }),
);

const EmailSettingsFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountId = useSelector(accountGlobalId);
    const maxImageSize = 524288;
    const createNotification = useUiSnackBar();
    const imageByteSeparator = ';base64,';
    const [ isSaveLoading, setIsSaveLoading ] = React.useState<boolean>(false);
    const [ isResetLoading, setIsResetLoading ] = React.useState<boolean>(false);
    const {
        selectedTenant: {
            id: tenantId, name: tenantName,
        },
    } = useTenantsContext();

    const { data: configurationData } = useSWR(
        tenantId ? [ emailConfigurationUrl, tenantId, accountId ] : [],
        getEmailConfiguration);

    const {
        control, handleSubmit, reset, errors,
    } =
    useForm<IEmailConfiguration>({
        mode: 'onChange',
        defaultValues: {
            senderId: '',
            senderName: '',
            digestSchedule: 1,
            senderLogo: '',
            isAccountLevel: false,
            emailFooter: '',
            senderLogoPrefix: '',
        },
    });

    const onSubmit = useCallback(async (data: IEmailConfiguration) => {
        try {
            setIsSaveLoading(true);
            if (data.senderLogo) {
                const logoArray: string[] = data.senderLogo.split(imageByteSeparator);
                data.senderLogoPrefix = logoArray[0] + imageByteSeparator;
                data.senderLogo = logoArray[1];
            }
            await updateEmailConfiguration(emailConfigurationUrl, data, tenantId);
            createNotification(
                translate({ id: 'CLIENT_EMAIL_CONFIG_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS,
            );
            setIsSaveLoading(false);
        } catch (error) {
            setIsSaveLoading(false);
            const errorMessage = (error as Error)?.message;
            createNotification(
                errorMessage,
                notificationType.ERROR,
            );
        }

    }, [ createNotification, tenantId, translate ]);

    const resetForm = useCallback(async () => {
        try {
            setIsResetLoading(true);
            const configData: IEmailConfiguration = await resetEmailConfiguration(emailConfigurationUrl, tenantId);
            reset({
                senderId: configData.senderId,
                senderName: configData.senderName,
                digestSchedule: configData.digestSchedule,
                senderLogo: configData.senderLogoPrefix + configData.senderLogo,
                isAccountLevel: configData.isAccountLevel ?? true,
                emailFooter: configData.emailFooter,
                senderLogoPrefix: configData.senderLogoPrefix,
            });
            createNotification(
                translate({ id: 'CLIENT_EMAIL_CONFIG_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS,
            );
            setIsResetLoading(false);
        } catch (error) {
            setIsResetLoading(false);
            const errorMessage = (error as Error)?.message;
            createNotification(
                errorMessage,
                notificationType.ERROR,
            );
        }
    }, [ createNotification, reset, tenantId, translate ]);

    useEffect(() => {
        if (configurationData) {
            reset({
                senderId: configurationData.senderId,
                senderName: configurationData.senderName,
                digestSchedule: configurationData.digestSchedule,
                senderLogo: configurationData.senderLogoPrefix + configurationData.senderLogo,
                isAccountLevel: configurationData.isAccountLevel ?? true,
                emailFooter: configurationData.emailFooter,
                senderLogoPrefix: configurationData.senderLogoPrefix,
            });
        }
    }, [ reset, configurationData ]);
    return (
        <UiForm
            onSubmit={handleSubmit(onSubmit)}
            id="emailConfigurationForm"
            actions={
                <div className={classes.actions}>
                    <UiProgressButton
                        loading={isResetLoading}
                        className={classes.cancelButton}
                        onClick={resetForm}
                        color="primary"
                        data-cy="add-edit-reset-button"
                    >
                        {translate({ id: 'CLIENT_NOTIFICATIONS_FILTER_RESET_DEFAULT' })}
                    </UiProgressButton>
                    <UiProgressButton
                        loading={isSaveLoading}
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        data-cy="add-edit-submit-button"
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            }
        >
            <div className={classes.container}>
                <div className={classes.leftContainer}>
                    <div className={classes.input}>
                        <Controller
                            render={({
                                value, onChange,
                            }) => (
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                        size="small"
                                        checked={value}
                                        onChange={e => onChange(e.target.checked)}
                                    />}
                                    label={
                                        <div className={classes.rowGroup}>
                                            <div>
                                                <span >
                                                    {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_ACCOUNTLEVEL' })}
                                                </span>
                                            </div>
                                            <div>
                                                <Tooltip
                                                    arrow
                                                    title={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_ACCOUNTLEVEL_TOOLTIP' })}>
                                                    <InfoOutlined
                                                        className={classes.infoIcon}
                                                        tabIndex={0}
                                                        aria-label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_ACCOUNTLEVEL_TOOLTIP' })}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    }
                                    data-cy="emailconfig-accountlevel-check"
                                />
                            )}
                            rules={{ required: false }}
                            name="isAccountLevel"
                            control={control}
                            data-cy="emailconfig-accountlevel"
                        />
                    </div>
                    <div className={classes.input}>
                        <Typography
                            className={clsx(classes.inputLabel)}
                            id="applicationNameLabel">
                            {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERID_TEXT' })}
                        </Typography>
                    </div>
                    <div className={classes.inputSender}>
                        <Typography
                            className={clsx(classes.inputLabel, classes.inputMargin)}
                            id="senderLogoLabel">
                            {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERLOGO' })}
                        </Typography>
                        <Controller
                            render={({
                                value, onChange,
                            }) => (
                                <UiImageSelector
                                    handleFileChange={onChange}
                                    allowedTypes={[ 'image/png' ]}
                                    preview
                                    maxSize={maxImageSize}
                                    defaultImage={value}
                                    defaultImageName={tenantName + '_logo.png'}
                                />
                            )}
                            rules={{ required: false }}
                            name="senderLogo"
                            control={control}
                            data-cy="emailconfig-senderlogo"
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            as={TextField}
                            control={control}
                            error={!!errors.senderName}
                            name="senderName"
                            label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERNAME' })}
                            variant="outlined"
                            fullWidth
                            data-cy="sender-name-text-field"
                        />
                    </div>
                    <div className={classes.input}>
                        <Controller
                            as={TextField}
                            control={control}
                            error={!!errors.senderId}
                            name="senderId"
                            label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_SENDERID' })}
                            variant="outlined"
                            fullWidth
                            data-cy="senderid-text-field"
                        />
                    </div>
                    <div className={classes.input}>
                        <Typography className={clsx(classes.inputLabel, classes.inputMargin)}>
                            {translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTFREQ' })}
                            <Tooltip
                                arrow
                                title={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTFREQ_TOOLTIP' })}>
                                <InfoOutlined
                                    className={classes.infoIcon}
                                    tabIndex={0}
                                    aria-label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTFREQ_TOOLTIP' })}
                                />
                            </Tooltip>
                        </Typography>
                        <Controller
                            as={
                                <RadioGroup
                                    className={classes.rowGroup}
                                >
                                    <FormControlLabel
                                        className={classes.digestRadio}
                                        value="0"
                                        control={<Radio
                                            color="primary" />}
                                        label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGEST10MIN' })}
                                    />
                                    <FormControlLabel
                                        className={classes.digestRadio}
                                        value="1"
                                        control={<Radio
                                            color="primary" />}
                                        label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGEST30MIN' })}
                                    />
                                    <FormControlLabel
                                        className={classes.digestRadio}
                                        value="2"
                                        control={
                                            <Radio
                                                color="primary" />
                                        }
                                        label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGEST1DAY' })}
                                    />

                                    <FormControlLabel
                                        className={classes.digestRadio}
                                        value="3"
                                        control={
                                            <Radio
                                                color="primary" />
                                        }
                                        label={translate({ id: 'CLIENT_NOTIFICATION_EMAILCONFIG_DIGESTWICE' })}
                                    />
                                </RadioGroup>
                            }
                            name="digestSchedule"
                            control={control}
                        />
                    </div>
                </div>
            </div>
        </UiForm>
    );
};
export default EmailSettingsFormComponent;
