import {
    AccountLicense,
    Region,
    TranslatedRegionName,
} from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import useIsOnPrem from '../../../common/hooks/useIsOnPrem';
import type { ITenant } from '../../../common/interfaces/tenant/tenant';
import {
    getAvailableServices,
    tenantAvailableServicesUri,
} from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    organizationRegion,
} from '../../../store/selectors';
import type { IServiceMetadata } from '../interfaces/service';
import { getSupportedRegionForTenant } from '../subcomponents/helpers/TenantRegionHelper';
import type { ServiceErrorType } from '../subcomponents/helpers/TenantServiceErrorMessage';
import { useTenantsContext } from '../TenantsContextProvider';

const useTenantGeneralFormViewModel = (tenant?: ITenant, type?: 'add' | 'edit') => {
    // Redux state
    const accountPlan = useSelector(accountType);
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const orgRegion = useSelector(organizationRegion);

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);

    // SWR fetch
    const { data: availableServices } = useSWR<IServiceMetadata[], Error>(
        [ tenantAvailableServicesUri, partitionGlobalId, accountName ],
        getAvailableServices,
    );

    const isAdminRevampEnabled = useIsAdminRevampEnabled();
    const isOnPrem = useIsOnPrem();

    // React-hook-form initalization
    const {
        register, control, formState: { errors }, setValue, watch,
    } = useFormContext();

    const isCanaryChecked: boolean | undefined = watch('customProperties.isCanaryTenant');

    // Component state
    const { tenantsWithoutServices } = useTenantsContext();

    const validationErrorHandlers = useState<Record<ServiceErrorType, string[]>>({
        license: [],
        altRegion: [],
        shouldDisable: [],
        shouldDisableRevamp: [],
        unsupported: [],
        noFallback: [],
        missingDependency: [],
        dependencyDisabled: [],
    });
    const isCanaryTenant = !!tenant?.isCanaryTenant;

    const canaryTenantExists = useMemo(
        () => tenantsWithoutServices.filter(tenantIterator => tenantIterator.isCanaryTenant).length > 0,
        [ tenantsWithoutServices ],
    );
    const isRegionEnabled = useMemo(
        () => !!process.buildConfigs.enableTenantRegion && (AccountLicense[accountPlan] <= AccountLicense.TRIAL),
        [ accountPlan ]);

    const DefaultRegions = useMemo(
        () => getSupportedRegionForTenant(availableServices),
        [ availableServices ],
    );
    const translatedRegion = useMemo(() =>
        Object.keys(TranslatedRegionName)
            .filter((key) => DefaultRegions?.includes(key as Region))
            .reduce((obj, key) => Object.assign(obj, { [key]: TranslatedRegionName[key as Region] }), {})
    , [ DefaultRegions ]);

    const canaryTenantFormEnabled = useMemo(() => AccountLicense[accountPlan] === AccountLicense.ENTERPRISE && isAdminRevampEnabled
            && orgRegion !== Region.GxPUnitedStates && (type === 'add' || isCanaryTenant) && !isOnPrem && !DisableFeatureFedRamp
    , [ DisableFeatureFedRamp, accountPlan, isAdminRevampEnabled, isCanaryTenant, isOnPrem, orgRegion, type ]);

    // Side-effects via useEffect
    useEffect(() => {
        if (isCanaryChecked) {
            setValue('region', 'Europe' as Region);
        }
    }, [ isCanaryChecked, setValue ]);

    return {
        tenantsWithoutServices,
        validationErrorHandlers,
        isCanaryTenant,
        isCanaryChecked: !!isCanaryChecked,
        canaryTenantExists,
        isRegionEnabled,
        DefaultRegions,
        translatedRegion,
        methods: {
            register,
            control,
            errors,
        },
        canaryTenantFormEnabled,
        isAdminRevampEnabled,
    };
};

export default useTenantGeneralFormViewModel;
