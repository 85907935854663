import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
    IconButton,
    MenuItem,
    Select,
    Typography,
    useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexShrink: 0,
        },
        pageIndicator: {
            margin: '0px 8px',
            display: 'flex',
            alignItems: 'center',
        },
        pageText: { marginRight: '4px' },
        pagePicker: {
            fontSize: '14px',
            fontWeight: 600,

            '&:focus-within': { outline: 'auto' },
        },
    }),
);

interface IPaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
    tableId?: string;
}

export const UiPaginationActionsComponent = ({
    count,
    page,
    rowsPerPage,
    onPageChange,
    tableId = 'table',
}: IPaginationActionsProps) => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const { formatMessage: translate } = useIntl();

    const totalPages = useMemo(() => Math.ceil(count / rowsPerPage), [ count, rowsPerPage ]);

    return (
        <div
            className={clsx(classes.root)}
            id={`${tableId}PaginationButtons`}
            aria-flowto={`${tableId}PageSizeSelect`}>
            <IconButton
                onClick={(event) => onPageChange(event, 0)}
                disabled={page === 0}
                data-cy="ui-grid-pagination-first-page"
                aria-label={translate({ id: 'CLIENT_TABLE_PAGINATION_TO_FIRST_PAGE' })}
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={(event) => onPageChange(event, page - 1)}
                disabled={page === 0}
                data-cy="ui-grid-pagination-previous-page"
                aria-label={translate({ id: 'CLIENT_TABLE_PAGINATION_TO_PREVIOUS_PAGE' })}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <div className={clsx(classes.pageIndicator)}>
                <span className={clsx(classes.pageText)}>
                    {translate({ id: 'CLIENT_PAGE' })}
                </span>
                <Select
                    className={clsx(classes.pagePicker)}
                    value={totalPages > 0 ? (page + 1).toString() : 0}
                    disableUnderline
                    onChange={(event) =>
                        onPageChange(
                            event as unknown as React.MouseEvent<HTMLButtonElement>,
                            parseInt(event.target.value as string) - 1,
                        )}
                    data-cy="ui-grid-pagination-page-selector"
                    aria-label={translate({ id: 'CLIENT_PAGE_MENU_WITH_VALUE' }, { 0: page + 1 })}
                    variant="standard"
                >
                    {totalPages > 0 ? [ ...Array<number>(totalPages) ].map((_, i) => (
                        <MenuItem
                            key={i}
                            value={i + 1}>
                            {i + 1}
                        </MenuItem>
                    ))
                        : <MenuItem
                            key={0}
                            value={0}>
                            0
                        </MenuItem>}
                </Select>
                <Typography style={{
                    height: '40px',
                    lineHeight: '42px',
                }}>
                    {' '}
/
                    {totalPages}
                </Typography>
            </div>
            <IconButton
                onClick={(event) => onPageChange(event, page + 1)}
                disabled={page >= totalPages - 1}
                data-cy="ui-grid-pagination-next-page"
                aria-label={translate({ id: 'CLIENT_TABLE_PAGINATION_TO_NEXT_PAGE' })}
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={(event) => {
                    const maxPage = Math.max(0, totalPages - 1);
                    onPageChange(event, maxPage);
                }}
                disabled={page >= totalPages - 1}
                data-cy="ui-grid-pagination-last-page"
                aria-label={translate({ id: 'CLIENT_TABLE_PAGINATION_TO_LAST_PAGE' })}
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
};
