import { useIdentityAuth } from '@uipath/auth-react';
import { useMemo } from 'react';

// This session Id is returned from Identity.
// This session Id is same for the user accross the applications

export default function useSessionId() {
    const { user } = useIdentityAuth();

    const sessionId = useMemo(() => user?.profile?.sid ?? '', [ user ]);

    return sessionId;
}
