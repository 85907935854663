import {
    Tab,
    Tabs,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';

import type { INotificationSettingsReducer } from './interfaces/notificationSettings';
import { ActionTypes } from './reducers/actionTypes';

const useStyles = makeStyles(() =>
    createStyles({ tabsContainer: { marginBottom: '16px' } }),
);

const NotificationTabsComponent: React.FC<{
    notificationSettings: INotificationSettingsReducer;
    tabsList: string[];
    dispatch: any;
}> = ({
    tabsList, notificationSettings, dispatch,
}) => {
    const classes = useStyles();

    return (
        <div
            data-cy="notification-settings-tabs"
            className={classes.tabsContainer}>
            <Tabs
                value={notificationSettings.tabIndex}
                onChange={(__, newIndex) => dispatch({
                    type: ActionTypes.NS_SET_TAB_INDEX,
                    data: { tabIndex: newIndex },
                })}
                indicatorColor="primary"
                textColor="primary"
            >
                {tabsList.map((tabName, index) => (
                    <Tab
                        key={index}
                        label={tabName}
                        style={{ textTransform: 'capitalize' }} />
                ))}
            </Tabs>
        </div>
    );
};

export default NotificationTabsComponent;
