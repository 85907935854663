export enum ExternalApiResourceType {
    User,
    Application,
    UserAndApplication,
}

export interface ICreateExternalClientPayload {
    partitionGlobalId: string;
    name: string;
    id?: string;
    secret?: string;
    isConfidential: boolean;
    redirectUri?: string;
    scopes: IScope[];
}

export interface IExternalClient extends Omit<ICreateExternalClientPayload, 'partitionGlobalId, scopes'> {
    resources: IResource[];
    secrets: ISecret[];
}

export interface IEditExternalClientPayload {
    partitionGlobalId: string;
    name: string;
    redirectUri?: string;
    scopes: IScope[];
}

export interface IResource {
    name: string;
    displayName?: string;
    description?: string;
    scopes: IScope[];
}

export interface IGridResource {
    name: string;
    userScopes: string[];
    applicationScopes: string[];
}

export interface IScope {
    name: string;
    displayName?: string;
    description?: string;
    type?: ExternalApiResourceType;
}

export interface IExternalClientSecretPayload {
    clientId: string | undefined;
    partitionGlobalId: string;
}

export interface ISecret {
    id: number;
    description: string;
    secret: string;
    creationTime: string;
    expiryTime: string;
}
