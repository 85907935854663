import {
    Tab,
    Tabs,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { dashboardLicenses } from '../../common/constants/Constant';
import { useTrialServiceTab } from '../../services/licensing/TrialPerSku';
import {
    EnableUserLicensingSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { TrialPerSkuPanel } from '../licensing/trialPerSku/TrialPerSkuPanel';
import { Unlicensed } from '../licensing/Unlicensed';
import { ViewLicensesPanel } from '../licensing/ViewLicensesPanel';

const useStyles = makeStyles(theme =>
    createStyles({
        content: { marginTop: '8px' },
        tabIndicator: { height: '3px' },
        licenseLabel: { marginTop: '24px' },
        unlicensed: { marginTop: '32px' },
        newUiTabs: { marginBottom: '10px' },
        allocatedLabel: {
            margin: '12px 0',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const UsageChartsComponent: React.FC<{
    isServiceMode: boolean;
    tenantIds?: string[];
}> = ({
    tenantIds, isServiceMode,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const [ value, setValue ] = React.useState(dashboardLicenses.USERS);
    const {
        shouldSeeTrialServicesTab, trialServicesResponse,
    } = useTrialServiceTab();

    useEffect(() => {
        if (EnableUserLicensing && !isServiceMode) {
            setValue(dashboardLicenses.USERS);
        } else {
            setValue(EnableUserLicensing ? dashboardLicenses.ROBOTS_AND_SERVICES : dashboardLicenses.USERS);
        }
    }, [ EnableUserLicensing, isServiceMode ]);

    const handleChange = useCallback(
        (_, newValue: string) => {
            setValue(newValue);
        },
        [ setValue ],
    );

    if (isUnlicensedMode) {
        return (
            <div className={classes.unlicensed}>
                <Unlicensed />
            </div>
        );
    }

    return (
        <div>
            <div>
                {(!EnableUserLicensing || !isServiceMode) && (
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        className={clsx('default', classes.newUiTabs)}
                    >
                        <Tab
                            value={dashboardLicenses.USERS}
                            label={translate({ id: 'CLIENT_USERS' })}
                            className="default"
                            data-cy="usage-chart-users-tab"
                            disableRipple
                        />
                        <Tab
                            value={dashboardLicenses.ROBOTS_AND_SERVICES}
                            label={translate({ id: 'CLIENT_ROBOTS_AND_SERVICES' })}
                            className="default"
                            data-cy="usage-chart-robots-and-services-tab"
                            disableRipple
                        />
                        {!isServiceMode && shouldSeeTrialServicesTab && (
                            <Tab
                                value={dashboardLicenses.TRIAL_SERVICES}
                                label={translate({ id: 'CLIENT_TRIAL_SERVICES' })}
                                className="default"
                                data-cy="trial-services-tab"
                                disableRipple
                            />
                        )}
                    </Tabs>
                )}
                {value === dashboardLicenses.TRIAL_SERVICES ? (
                    <TrialPerSkuPanel
                        trialServices={trialServicesResponse}
                        tenantIds={tenantIds}
                        isHome />
                ) : (
                    <ViewLicensesPanel
                        selectedTab={value}
                        isHome
                        useLegacyProducts={!EnableUserLicensing && value === dashboardLicenses.USERS}
                        isServiceMode={isServiceMode}
                        tenantId={tenantIds?.length === 1 ? tenantIds[0] : ''}
                        tenantIds={tenantIds}
                    />
                )}

            </div>
        </div>
    );
};

export default UsageChartsComponent;
