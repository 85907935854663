import { getShortNumber } from '@experiences/util';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { getUsageDetails } from '../../services/licensing/LicenseUsageService';
import { BarChartContent } from '../common/UiBarChart/BarChartContent';
import type { IEntitlementUsageDetailsResponse } from '../tenants/interfaces/service';

const useStyles = makeStyles(theme =>
    createStyles({
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '8px',
        },
    }),
);

interface IUsageDetailsProps {
    entitlement: string;

}

interface IUsageDetailsPools {
    primary: string;
    secondary: string;
}

interface IUsageDetailsTranslate{
    title: string;
    primary: string;
    secondary: string;
}

type ChartData = {
    primary?: number[];
    secondary?: number[];
};

const defaultMonths = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
const defaultUsageDetails = [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ];

const UsageDetailsComponent = ({ entitlement }: IUsageDetailsProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ translateMap ] = useState<Map<string, IUsageDetailsTranslate>>(
        new Map([
            [
                'APICALLS',
                {
                    title: 'CLIENT_USAGE_API_CALLS_TITLE',
                    primary: 'CLIENT_USAGE_API_CALLS_BUNDLED_TITLE',
                    secondary: 'CLIENT_USAGE_API_CALLS_PURCHASED_TITLE',
                },
            ],
        ]),
    );
    const [ poolsMap ] = useState<Map<string, IUsageDetailsPools>>(
        new Map([
            [
                'APICALLS',
                {
                    primary: 'ApiCalls.Bundled',
                    secondary: 'ApiCalls.Purchased',
                },
            ],
        ]),
    );
    const entitlements = [ entitlement ];

    const [ usageDetailsData, setUsageDetailsData ] = useState<ChartData>(() =>
        ({ primary: undefined })
    );

    const { data: usageDetails } = useSWR<IEntitlementUsageDetailsResponse[], Error>(
        [
            `/api/usage/pools/details`,
            entitlements,
        ],
        () => getUsageDetails(entitlements)
    );

    const currentMonth = new Date().getUTCMonth();

    const months = useMemo(() => {
        const result = [ ...defaultMonths ];
        result.unshift(...result.splice(currentMonth - 11));
        return result;
    }, [ currentMonth ]);

    useEffect(() => {

        if (usageDetails) {
            const usageDetail = usageDetails.find(value => value.entitlement === entitlement);
            const pools = poolsMap.get(entitlement);
            if (usageDetail && pools && usageDetail.poolsUsage[pools.primary] !== undefined) {

                const primaryUsageDetails = [ ...usageDetail.poolsUsage[pools.primary] ];

                primaryUsageDetails.unshift(...primaryUsageDetails.splice(currentMonth - 11));

                let secondaryUsageDetails = undefined;
                if (usageDetail.poolsUsage[pools.secondary] !== undefined) {

                    secondaryUsageDetails = [ ...usageDetail.poolsUsage[pools.secondary] ];
                    secondaryUsageDetails.unshift(...secondaryUsageDetails.splice(currentMonth - 11));
                }

                setUsageDetailsData({
                    primary: primaryUsageDetails,
                    secondary: secondaryUsageDetails,
                });

                return;
            }

            setUsageDetailsData({ primary: defaultUsageDetails });
        }
    }, [ usageDetails, currentMonth, entitlement, poolsMap ]);

    return <>
        { usageDetailsData.primary &&
        <div data-cy={`${entitlement.toLowerCase()}-usage-details-chart`}>
            <Typography className={classes.subTitle}>
                {translate({ id: translateMap.get(entitlement)?.title })}
            </Typography>
            <BarChartContent dist={{
                data: usageDetailsData.primary,
                compareData: usageDetailsData.secondary,
                legendProperties: { hasLegend: true },
                legendTitle: translate({ id: translateMap.get(entitlement)?.primary }),
                compareLegendTitle: translate({ id: translateMap.get(entitlement)?.secondary }),
                labels: months,
                scaleY: {
                    type: 'linear',
                    display: true,
                    tickFormatCallback: (domainValue: number) => getShortNumber(domainValue),
                },
                translateIdTooltipPrimary: translateMap.get(entitlement)?.primary,
                translateIdTooltipSecondary: translateMap.get(entitlement)?.secondary,
            }} />
        </div>}
    </>;
};

export default UsageDetailsComponent;
