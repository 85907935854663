import {
    useAuth,
    useSignoutRedirect,
} from '@uipath/auth-react';
import { useCallback } from 'react';

import { clearAuthStorage } from '../utils/ClearStorage';

interface ILogoutParams {
    returnUrl: string;
    shouldClearAuthStorage: boolean;
}

export default function useAuthentication() {
    const { clearStaleState } = useAuth();
    const signoutRedirect = useSignoutRedirect();

    const logout = useCallback(
        async ({
            returnUrl = undefined, shouldClearAuthStorage = true,
        }: Partial<ILogoutParams> = {}) => {
            returnUrl = returnUrl ?? `${window.location.origin}${process.buildConfigs.postLogoutReturnPathname}`;

            clearStaleState();

            if (shouldClearAuthStorage) {
                clearAuthStorage();
            }

            signoutRedirect({ post_logout_redirect_uri: returnUrl });
        },
        [ signoutRedirect ],
    );

    return { logout };
}
