import ExpandMore from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() => createStyles({ stackTrace: { marginTop: '12px' } }));

export const TagDeleteErrorDialog: React.FC<{ error: Error; type: 'Label' | 'Property' }> = ({
    error, type,
}) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    return <>
        <Typography>
            {translate({ id: `CLIENT_FAILED_TO_DELETE_${type.toUpperCase()}` })}
        </Typography>
        <Accordion
            className={classes.stackTrace}
            defaultExpanded={false}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                    {translate({ id: 'CLIENT_OPEN_STACK_TRACE' })}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <code>
                    {error.stack}
                </code>
            </AccordionDetails>

        </Accordion>
    </>;
};
