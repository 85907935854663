import { combineReducers } from 'redux';

import adminRevampToggleReducer from './AdminRevampToggleReducer';
import AnnouncementReducer from './AnnouncementReducer';
import userProfileReducer from './UserProfileReducer';

export default combineReducers({
    profile: userProfileReducer,
    announcements: AnnouncementReducer,
    adminRevamp: adminRevampToggleReducer,
});
