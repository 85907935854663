import type { IPagination } from '@experiences/interfaces';

import type {
    IGroupAllocations,
    IUserWithExplicitAllocations,
} from '../../../common/interfaces/licenses';
import type {
    IGroupAllocationsPagedResult,
    IUserWithExplicitAllocationsPagedResult,
} from '../../../component/licensing/interfaces/license';
import type {
    IAvailableUserLicense,
    IGroupAllocationRules,
    ILicenseInheritance,
    IUserAllocationsInGroupResponse,
    IUserLicenseAllocation,
} from '../../../component/users/interfaces/userLicense';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../../utility/Requests.default';

export const userLicenseUrl = '/api/license/accountant/UserLicense';

export interface ISubmitGroupAllocations {
    groupId: string;
    licenses: string[];
    useExternalLicense: boolean;
}

export interface ISubmitUsersAllocations {
    userIds: Array<string | undefined>;
    licenseCodes: string[];
    useExternalLicense: boolean;
}

export function getAccountLicenseAllocation() {
    return get<IUserLicenseAllocation[]>(userLicenseUrl);
}

export function putUserLicenseAllocation(payload: ISubmitUsersAllocations) {
    return put<IUserLicenseAllocation[]>(userLicenseUrl, { body: { ...payload } });
}

export function getGroupLicenseAllocation(id: string) {
    return get<IGroupAllocationRules>(`${userLicenseUrl}/group`, { urlParams: { id } });
}

export function putGroupLicenseAllocation(payload: ISubmitGroupAllocations) {
    return put(`${userLicenseUrl}/group`, {
        body: {
            ubls: payload.licenses,
            id: payload.groupId,
            useExternalLicense: payload.useExternalLicense,
        },
    });
}

export function deleteGroupLicenseAllocation(groupId: string) {
    return axiosDelete(`${userLicenseUrl}/group`, { body: { id: groupId } });
}

export function deleteUserExplicitAllocations(userIds: string[]) {
    return axiosDelete(`${userLicenseUrl}`, { body: { userIds } });
}

export function getUsersExplicitAllocationsPage(...args: any[]) {
    const pagination = args[0] as IPagination;
    const allocation = args[1] as IUserWithExplicitAllocations | undefined;
    const licenseCodes = args.slice(2) as string[];
    return get<IUserWithExplicitAllocationsPagedResult>(`${userLicenseUrl}/user/page`, {
        urlParams: {
            userToRequestAllocationFor: allocation,
            userBundleLicenseCodes: licenseCodes,
        },
        pagination,
    });
}

export function retrieveExplicitAllocationsForUserIds(userIds: Array<string | undefined>) {
    return post<IUserWithExplicitAllocations[]>(`${userLicenseUrl}/users`, { body: { userIds } });
}

export function getEditableUserLicense(userGlobalId: string) {
    return get<IAvailableUserLicense>(`${userLicenseUrl}/availableUserBundles`, { urlParams: { userGlobalId } });
}

export function getGroupsAllocationsPage(...args: any[]) {
    const pagination = args[0] as IPagination;
    const groupAllocations = args[1] as IGroupAllocations | undefined;
    const licenseCodes = args.slice(2) as string[];
    return get<IGroupAllocationsPagedResult>(`${userLicenseUrl}/group/page`, {
        urlParams: {
            groupToRequestAllocationFor: groupAllocations,
            userBundleLicenseCodes: licenseCodes,
        },
        pagination,
    });
}

export function getUserAllocationsForGroup(pagination: IPagination, groupId: string) {
    return get<IUserAllocationsInGroupResponse>(`${userLicenseUrl}/group/${groupId}/allocations`, { pagination });
}

export async function releaseUserLicensesFromGroup(groupId: string, userId: string) {
    return await axiosDelete(`${userLicenseUrl}/group/${groupId}/user/${userId}`);
}

export async function getLicenseInheritance(userId: string) {
    return get<ILicenseInheritance>(`${userLicenseUrl}/licenseInheritance/${userId}`);
}

export function getExplicitUserBundleCodes() {
    return get<string[]>(`${userLicenseUrl}/ublCodes/explicit`);
}

export function getGroupRuleUserBundleCodes() {
    return get<string[]>(`${userLicenseUrl}/ublCodes/group`);
}
