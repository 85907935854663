import { isBrowserIE } from '@experiences/util';
import {
    Checkbox,
    Skeleton,
    TableCell,
    TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';

import { CheckboxCellSize } from './constants';

const useStyles = makeStyles(() =>
    createStyles({
        checkboxCell: { flex: `0 0 ${CheckboxCellSize}px` },
        skeletonRow: {
            flex: '1 0 auto',
            position: 'absolute',
            left: '0px',
        },
        dataSkeleton: { margin: '9px 9px 9px 0px' },
        actionSkeleton: { marginRight: '16px' },
    }),
);

interface IUiSkeletonGridProps {
    columnWidths: number[];
    rowHeight: number;
    position: number;
    actionWidth: number;
    checkbox?: boolean;
}

export const UiSkeletonGridComponent = ({
    columnWidths,
    rowHeight,
    position,
    actionWidth,
    checkbox,
}: IUiSkeletonGridProps) => {
    const classes = useStyles();

    return (
        <TableRow
            component="div"
            className={clsx(classes.skeletonRow)}
            style={{
                top: rowHeight * position + 'px',
                height: rowHeight + 'px',
            }}
        >
            {checkbox ? (
                <TableCell
                    component={'div' as any}
                    padding="checkbox"
                    className={clsx(classes.checkboxCell)}
                    style={{ height: rowHeight + 'px' }}
                >
                    <Checkbox />
                </TableCell>
            ) : null}
            {columnWidths.map((width, j) => (
                <TableCell
                    key={j}
                    component={'div' as any}
                    style={
                        !isBrowserIE()
                            ? {
                                flex: `1 1 ${width}px`,
                                height: rowHeight + 'px',
                            }
                            : {
                                flex: `1 1 auto`,
                                width: `${width}px`,
                                height: rowHeight + 'px',
                            }
                    }
                >
                    <Skeleton
                        className={clsx(classes.dataSkeleton)}
                        variant="text"
                        width="100%" />
                </TableCell>
            ))}
            {actionWidth ? (
                <TableCell
                    component={'div' as any}
                    style={
                        !isBrowserIE()
                            ? {
                                flex: `1 1 ${actionWidth}px`,
                                height: rowHeight + 'px',
                            }
                            : {
                                flex: `1 1 auto`,
                                width: `${actionWidth}px`,
                                height: rowHeight + 'px',
                            }
                    }
                >
                    <Skeleton
                        className={clsx(classes.actionSkeleton)}
                        variant="rectangular"
                        height="36px"
                        width="100%" />
                </TableCell>
            ) : null}
        </TableRow>
    );
};
