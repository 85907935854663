import { GlobalStyles } from '@experiences/theme';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import ProductDescriptionList from './ProductDescriptionList';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        headerContainer: {
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'left',
        },
        productSubtitle: {
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForeground,
            whiteSpace: 'pre-wrap',
        },
    }),
}));

const EcommerceLearnMoreComponent: React.FC<{
    productCode: string;
}> = ({ productCode }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <div className={classes.headerContainer}>
            <Typography
                className={classes.productSubtitle}
                data-cy={`ecommerce-learn-more-subtitle-${productCode}`}>
                {translate({ id: `CLIENT_ECOMMERCE_PRODUCT_LEARN_MORE_SUBTITLE_${productCode}` })}
            </Typography>
            <ProductDescriptionList productCode={productCode} />
            {/** TODO - Decoment this as part of [PLT-33277]
            <IncludedServicesList productCode={productCode} />
            */}

        </div>);
};

export default EcommerceLearnMoreComponent;
