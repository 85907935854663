import RefreshIcon from '@mui/icons-material/Refresh';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { mutate } from 'swr';

export const UiRefreshButton: React.FC<{
    isValidating: boolean;
    swrKey: Array<string | number>;
}> = ({
    isValidating, swrKey,
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        <Button
            size='small'
            variant="text"
            startIcon={isValidating ? <CircularProgress
                size={16}
                thickness={4} /> : <RefreshIcon />}
            onClick={() => mutate(swrKey)}
            data-cy="ui-grid-refresh-button"
            aria-label='refresh-button'
        >
            {translate({ id: 'CLIENT_REFRESH' })}
        </Button>
    );
};
