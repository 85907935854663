import { AccountLicense } from '@experiences/constants';
import type { ICreateEditTenantPayload } from '@experiences/interfaces';
import {
    CircularProgress,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    getTenantServiceLicenses,
    unavailableTenantId,
} from '../../../../services/licensing/LicenseManagementService';
import {
    accountGlobalId,
    accountType,
    concurrentLicensesOpted,
} from '../../../../store/selectors';
import UiLicenseAllocationPerServiceComponent from '../../../common/UiLicenseAllocationComponent/UiLicenseAllocationComponent';
import type { ITenantServiceLicense } from '../../interfaces/service';
import NeedMoreLicensesComponent from '../forms/NeedMoreLicensesComponent';
import { mapServiceLicenseToTenantCustomAllocationSections } from '../helpers/AllocationSectionsHelper';
import { getProductsFromServices } from '../helpers/getProductsForServices';
import { sortedServiceTypes } from '../helpers/ManageLicensesHelper';

const useStyles = makeStyles(theme =>
    createStyles({
        groups: {
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
        },
        groupColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        noLicensesAvailable: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginBottom: '6px',
        },
        serviceLicense: {
            maxWidth: '900px',
            width: '100%',
        },
    }),
);

const TenantCreateAllocateLicenseComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const partitionGlobalId = useSelector(accountGlobalId);
    const concurrentLicenseEnabled = useSelector(concurrentLicensesOpted);
    const currentAccountType = useSelector(accountType);

    const {
        watch, setValue,
    } = useFormContext<ICreateEditTenantPayload>();

    const services = useMemo(() => watch('services'), [ watch ]);

    const { data: tenantServiceLicenseData } = useSWR<ITenantServiceLicense[], Error>(
        services
            ? [
                `/api/manageLicense/${partitionGlobalId}/service-licenses/${unavailableTenantId}}`,
                partitionGlobalId,
                unavailableTenantId,
                services,
            ]
            : null,
        getTenantServiceLicenses,
    );

    const filteredByServiceLicenseData = useMemo(() => tenantServiceLicenseData
        ?.filter(tenantServiceLicense => services.includes(tenantServiceLicense.serviceType)
                && (!process.buildConfigs.unlicensedServices?.includes(tenantServiceLicense.serviceType) ?? true))
        .flatMap(mapServiceLicenseToTenantCustomAllocationSections)
        ?.sort((l1, l2) => sortedServiceTypes.indexOf(l1.serviceType) - sortedServiceTypes.indexOf(l2.serviceType)), [ services, tenantServiceLicenseData ]);

    const getAvailableProductsFromServices = useMemo(() => getProductsFromServices(filteredByServiceLicenseData, concurrentLicenseEnabled),
        [ filteredByServiceLicenseData, concurrentLicenseEnabled ]);

    useEffect(() => {
        setValue('customProperties.allocatedProductLicenses', getAvailableProductsFromServices);
    }, [ getAvailableProductsFromServices, setValue ]);

    const {
        tenantHasServiceLicenses, tenantHasLicenseProductUnavailable,
    } = useMemo(
        () => ({
            tenantHasServiceLicenses: !!filteredByServiceLicenseData?.some(serviceLicense => serviceLicense.products.length > 0),
            tenantHasLicenseProductUnavailable: !!filteredByServiceLicenseData?.some(serviceLicense =>
                serviceLicense.products.some(product => product.available === 0),
            ),
        }),
        [ filteredByServiceLicenseData ],
    );

    return <>
        {!tenantServiceLicenseData && <CircularProgress style={{ margin: 'auto' }} />}
        {tenantServiceLicenseData
            && <>
                {tenantHasServiceLicenses ? (
                    filteredByServiceLicenseData
                        ?.filter(serviceLicense => serviceLicense.products.length > 0)
                        .map((serviceLicense, i) => (
                            <div
                                key={i}
                                className={classes.serviceLicense}>
                                <UiLicenseAllocationPerServiceComponent
                                    type="add"
                                    name="customProperties.allocatedProductLicenses"
                                    serviceLicense={serviceLicense} />
                            </div>
                        ))
                ) : (
                    <div className={clsx(classes.groups, classes.groupColumn)}>
                        <Typography
                            className={classes.noLicensesAvailable}
                            data-cy="case-no-license-available">
                            {translate({ id: 'CLIENT_NO_LICENSE_AVAILABLE' })}
                        </Typography>
                    </div>
                )}
                <div style={{ marginBottom: '36px' }} />
                {tenantHasLicenseProductUnavailable && AccountLicense[currentAccountType] === AccountLicense.COMMUNITY &&
                <NeedMoreLicensesComponent />}
            </>}
    </>;
};

export default TenantCreateAllocateLicenseComponent;
