import { UiProgressButton } from '@experiences/ui-common';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
    EmailSettings,
    OrganizationAdminHome,
} from '../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../common/hooks/useOrganizationName';
import { isValidDomain } from '../../../util/DomainUtil';
import UiForm from '../../common/UiForm';
import UiPageContainer from '../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../AdminBreadCrumbs';
import useOrganizationEmailSettingsRevampViewModel from './OrganizationEmailSettingsRevampViewModel';

const useStyles = makeStyles(theme => createStyles({
    cancelButton: { marginRight: '10px' },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    inputLabel: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        margin: '8px 0px',
    },
    inputField: { maxWidth: '662px' },
    fieldRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '24px',
    },
    equalSpace: { flex: 1 },
    rowInputMargin: { marginRight: '24px' },
    checkboxMargin: { marginLeft: '2px' },
    divider: {
        marginTop: '24px',
        marginBottom: '24px',
        border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
    },
}));

const OrganizationEmailSettingsRevampComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const {
        methods: {
            register,
            control,
            handleSubmit,
            formState: {
                isDirty, isValid, isSubmitting, errors,
            },
        },
        onSubmit,
        useDefaultCredentials,
        handleTestEmailSettings,
        handleSwitch,
        handleCancel,
        mailSetting,
        setMailSetting,
        verifiedSettings,
        deleteCustomSetting,
    } = useOrganizationEmailSettingsRevampViewModel();

    const isDefault = mailSetting === 'default';

    const breadCrumbLinks = [
        {
            link: OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: EmailSettings,
            name: translate({ id: 'CLIENT_MAIL_SETTINGS' }),
        },
    ];

    return <UiPageContainer
        position="left"
        header={AdminBreadCrumbs(breadCrumbLinks)}>
        <FormControl>
            <Typography className={classes.inputLabel}>
                {translate({ id: 'CLIENT_SMTP_OPTIONS' })}
            </Typography>
            <RadioGroup
                row
                value={mailSetting}
                onChange={async e => {
                    if (e.target.value === 'default') {
                        const proceed = await deleteCustomSetting();
                        if (proceed) {
                            setMailSetting(e.target.value as 'default');
                        }
                    } else {
                        setMailSetting(e.target.value as 'custom');
                    }
                }}
                style={{ marginBottom: '18px' }}>
                <FormControlLabel
                    value="default"
                    control={<Radio />}
                    style={{ marginRight: '24px' }}
                    label={translate({ id: 'CLIENT_USE_DEFAULT_MAIL_SETTINGS' })}
                    data-cy="mail-settings-default-toggle"
                />
                <FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label={translate({ id: 'CLIENT_USE_CUSTOM_MAIL_SETTINGS' })}
                    data-cy="mail-settings-custom-toggle"
                />
            </RadioGroup>
        </FormControl>
        {mailSetting === 'default' && <Typography className={classes.inputLabel}>
            {translate({ id: 'CLIENT_DEFAULT_MAIL_SETTING_DISCLAIMER' })}
        </Typography>}
        {mailSetting === 'custom' && <Typography className={classes.inputLabel}>
            {translate({ id: 'CLIENT_CUSTOM_MAIL_SETTING_DISCLAIMER' })}
        </Typography>}
        <div className={classes.divider} />
        <UiForm
            addScrollPadding
            onSubmit={handleSubmit(onSubmit)}
            actions={
                isDirty && <>
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => handleCancel()}
                            color="primary"
                            data-cy="email-settings-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => handleTestEmailSettings()}
                            color="primary"
                            variant="outlined"
                            data-cy="email-settings-test-button"
                        >
                            {translate({ id: 'CLIENT_TEST_MAIL_SETTINGS' })}
                        </Button>
                        <UiProgressButton
                            type="submit"
                            loading={isSubmitting}
                            disabled={!verifiedSettings || !isValid || !isDirty}
                            variant="contained"
                            data-cy="email-settings-submit-button"
                        >
                            {translate({ id: 'CLIENT_SAVE' })}
                        </UiProgressButton>
                    </div>
                </>
            }>
            <FormControl style={{ marginBottom: '8px' }}>
                <FormLabel
                    required
                    className={classes.inputLabel}>
                    {translate({ id: 'CLIENT_HOSTNAME' })}
                </FormLabel>
                <TextField
                    className={classes.inputField}
                    inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                    name="host"
                    fullWidth
                    InputProps={{ readOnly: isDefault }}
                    error={!!errors?.host}
                    helperText={errors?.host?.message}
                    data-cy="email-settings-host-input"
                />
            </FormControl>
            <FormControl style={{ marginBottom: '8px' }}>
                <FormLabel
                    required
                    className={classes.inputLabel}>
                    {translate({ id: 'CLIENT_DOMAIN' })}
                </FormLabel>
                <TextField
                    className={classes.inputField}
                    inputRef={register({
                        required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                        validate: {
                            validDomain: value =>
                                isValidDomain(value)
                                || translate({ id: 'CLIENT_INVALID_DOMAIN_ERROR' }),
                        },
                    })}
                    name="domain"
                    fullWidth
                    InputProps={{ readOnly: isDefault }}
                    error={!!errors?.domain}
                    helperText={errors?.domain?.message}
                    data-cy="email-settings-domain-input"
                />
            </FormControl>
            <Box className={clsx(classes.fieldRow, classes.inputField)}>
                <FormControl className={clsx(classes.equalSpace, classes.rowInputMargin)}>
                    <FormLabel
                        required
                        className={classes.inputLabel}>
                        {translate({ id: 'CLIENT_PORT' })}
                    </FormLabel>
                    <TextField
                        inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                        name="port"
                        InputProps={{ readOnly: isDefault }}
                        error={!!errors?.port}
                        helperText={errors?.port?.message}
                        data-cy="email-settings-port-input"
                    />
                </FormControl>
                <FormControl className={classes.equalSpace}>
                    <FormLabel
                        required
                        className={classes.inputLabel}>
                        {translate({ id: 'CLIENT_TIMEOUT' })}
                    </FormLabel>
                    <TextField
                        inputRef={register({
                            required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }),
                            min: {
                                message: translate({ id: 'CLIENT_ECOMMERCE_MIN_ALLOWED_PRODUCT_QUANTITY' }, { minQuantity: 0 }),
                                value: 0,
                            },
                            max: {
                                message: translate({ id: 'CLIENT_ECOMMERCE_MAX_ALLOWED_PRODUCT_QUANTITY' }, { maxQuantity: 300000 }),
                                value: 300000,
                            },
                        })}
                        name="timeout"
                        InputProps={{
                            endAdornment: (<InputAdornment position="end">
                                ms
                            </InputAdornment>),
                            readOnly: isDefault,
                            type: 'number',
                        }}
                        error={!!errors?.timeout}
                        helperText={errors?.timeout?.message}
                        data-cy="email-settings-timeout-input"
                    />
                </FormControl>
            </Box>
            {mailSetting === 'custom' && <>
                <FormControlLabel
                    control={<Checkbox />}
                    label={translate({ id: 'CLIENT_USE_TLS_ENCRYPTION' })}
                    inputRef={register()}
                    name="enableSsl"
                    data-cy="email-settings-enable-ssl-switch"
                />
                {!process.buildConfigs.disableUseDefaultCredentials && <Controller
                    control={control}
                    name="useDefaultCredentials"
                    render={props =>
                        <FormControlLabel
                            control={<Checkbox
                                defaultChecked={!useDefaultCredentials}
                                value={!props.value}
                                onChange={e => handleSwitch('useDefaultCredentials', !e.target.checked, props.onChange)}
                                data-cy="email-settings-use-default-credentials-switch"
                            />}
                            label={translate({ id: 'CLIENT_REQUIRE_AUTHENTICATION' })}
                        />}
                />}
                {!useDefaultCredentials && <Box
                    className={clsx(classes.fieldRow, classes.inputField)}
                    style={{ marginTop: '8px' }}>
                    <FormControl className={clsx(classes.equalSpace, classes.rowInputMargin)}>
                        <FormLabel
                            required
                            className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_USERNAME' })}
                        </FormLabel>
                        <TextField
                            inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                            name="userName"
                            error={!!errors?.userName}
                            helperText={errors?.userName?.message}
                            data-cy="email-settings-username-input"
                        />
                    </FormControl>
                    <FormControl className={classes.equalSpace}>
                        <FormLabel
                            required
                            className={classes.inputLabel}>
                            {translate({ id: 'CLIENT_PASSWORD' })}
                        </FormLabel>
                        <TextField
                            inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            InputProps={{ readOnly: isDefault }}
                            error={!!errors?.password}
                            helperText={errors?.password?.message}
                            data-cy="email-settings-password-input"
                        />
                    </FormControl>
                </Box>}
                <div className={classes.divider} />
                <Typography style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    marginBottom: '8px',
                }}>
                    {translate({ id: 'CLIENT_SENDER_DETAILS' })}
                </Typography>
            </>}
            <Box className={clsx(classes.fieldRow, classes.inputField)}>
                <FormControl className={clsx(classes.equalSpace, classes.rowInputMargin)}>
                    <FormLabel
                        required
                        className={classes.inputLabel}>
                        {translate({ id: isDefault ? 'CLIENT_SENDER_ADDRESS' : 'CLIENT_ADDRESS' })}
                    </FormLabel>
                    <TextField
                        inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                        name="fromAddress"
                        InputProps={{ readOnly: isDefault }}
                        error={!!errors?.fromAddress}
                        helperText={errors?.fromAddress?.message}
                        data-cy="email-settings-from-email-input"
                    />
                </FormControl>
                <FormControl className={classes.equalSpace}>
                    <FormLabel
                        required
                        className={classes.inputLabel}>
                        {translate({ id: isDefault ? 'CLIENT_SENDER_NAME' : 'CLIENT_NAME' })}
                    </FormLabel>
                    <TextField
                        inputRef={register({ required: translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }) })}
                        name="fromDisplayName"
                        InputProps={{ readOnly: isDefault }}
                        error={!!errors?.fromDisplayName}
                        helperText={errors?.fromDisplayName?.message}
                        data-cy="email-settings-from-display-name-input"
                    />
                </FormControl>
            </Box>
        </UiForm>
    </UiPageContainer>;
};

export default OrganizationEmailSettingsRevampComponent;
