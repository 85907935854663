import { portalTelemetry } from '@experiences/telemetry';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

import { revokeAuth0RefreshTokenForUser } from '../../services/UserServices';

export default async function revokeRefreshToken(
    refreshToken: string,
    successCallback: () => Promise<void>,
    errorCallback: () => Promise<void>,
) {
    try {
        await revokeAuth0RefreshTokenForUser(refreshToken);
        portalTelemetry.trackTrace({
            message: 'Succesfully revoked token',
            severityLevel: SeverityLevel.Information,
        });

        await successCallback();
    } catch (error) {
        portalTelemetry.trackTrace({
            message: `Error occured while revoking refresh token: ${error}`,
            severityLevel: SeverityLevel.Error,
        });

        await errorCallback();
    }
}
