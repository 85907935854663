import type { Token } from '@experiences/interfaces';
import { getAccessToken } from '@uipath/auth-react';
import jwtDecode from 'jwt-decode';

export async function getToken() {
    return getAccessToken();
}

export async function getSub(tokenToUse = undefined) {
    const token = tokenToUse ?? (await getToken());

    if (!token) {
        return null;
    }

    const decodedToken = jwtDecode<Token>(token);
    return decodedToken.sub;
}

