import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    Tab,
    Tabs,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import EmailConfigComponent from './EmailConfiguration/EmailConfigComponent';
import NotificationsProfiles from './NotificationsProfiles';

const useStyles = makeStyles(() =>
    createStyles({ tabsContainer: { marginBottom: '8px' } }),
);

const NotificationSettingsAdminPage: React.FC<{
    handleError(error: Error): void;
}> = ({ handleError }) => {
    const classes = useStyles();
    const { logEvent } = useTelemetryHelper();
    const { formatMessage: translate } = useIntl();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const [ tabSelected, settabSelected ] = React.useState<number>(0);
    const enableEmailConfigSettings = useFeatureFlagValue(
        Features.EnableEmailConfigSettings.name,
    );
    logEvent('NotificationSettings.AdminPageSettings');

    return (
        <>
            <div
                data-cy="notification-settings-tabs"
                className={classes.tabsContainer}>
                <Tabs
                    value={tabSelected}
                    onChange={(__, newIndex) => settabSelected(newIndex)}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab
                        key={1}
                        label={translate({ id: 'CLIENT_ALERTING_GROUP_CONFIGURATION' })}
                        style={{ textTransform: 'capitalize' }} />
                    {enableEmailConfigSettings && <Tab
                        key={2}
                        label={translate({ id: 'CLIENT_EMAIL_CONFIGURATION' })}
                        style={{ textTransform: 'capitalize' }} />}
                </Tabs>
                { tabSelected === 0 && <NotificationsProfiles />}
                { tabSelected === 1 && enableEmailConfigSettings && <EmailConfigComponent />}

            </div>
        </>
    );
};

export default NotificationSettingsAdminPage;
