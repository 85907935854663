import {
    getRegionFromCountry,
    Region,
} from '@experiences/constants';

import type { IServiceMetadata } from '../../component/tenants/interfaces/service';

export const checkServiceRegion = (service: IServiceMetadata | undefined, tenantRegion: string | undefined) => !!service?.supportedRegions?.concat(service.defaultRegion).includes(tenantRegion as Region);

export const serviceAndTenantSameRegion = (serviceRegion: Region, tenantRegion: string | undefined): string => serviceRegion !== tenantRegion && serviceRegion !== Region.None ? serviceRegion : '';

export const tempServiceCardRegion = (service: IServiceMetadata | undefined, tenantRegion: string) => service && !checkServiceRegion(service, tenantRegion)
    ? service?.defaultRegion ?? getRegionFromCountry(tenantRegion)
    : Region.None;
