import type {
    IValueModelObject,
    LabelDataType,
} from '@experiences/interfaces';
import {
    UiDataContextProvider,
    UiProgressButton,
    UiSelect,
} from '@experiences/ui-common';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Button,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApDataGrid } from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { renderRoutes } from 'react-router-config';

import * as RouteNames from '../../../../common/constants/RouteNames';
import { useOrganizationName } from '../../../../common/hooks/useOrganizationName';
import UiForm from '../../../common/UiForm';
import UiPageContainer from '../../../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../../../organizationsettings/AdminBreadCrumbs';
import useAddEditTenantTagsPropertiesViewModel, { ValueDataTypes } from './AddEditTenantTagsPropertiesViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        gridLabel: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontSize: '14px',
            fontWeight: 600,
        },
        input: { marginBottom: '16px' },
        tooltipIcon: {
            width: '20px',
            height: '20px',
            marginLeft: '6px',
        },
    }),
);

export interface ITenantTagsPropertiesContext {
    Values: IValueModelObject[];
    DataType: LabelDataType;
    Refresh?: boolean;
    Regex?: RegExp;
}

const AddEditTenantTagsPropertiesComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const orgName = useOrganizationName();

    const {
        type,
        isEditDetails,
        isEditKeyValues,
        tenant,
        tenantId,
        isRegex,
        cancel,
        tenantLoading,
        methods: {
            register,
            control,
            formState: {
                errors, isSubmitting, isDirty,
            },
            handleSubmit,
            getValues,
        },
        property,
        apGridRef,
        changeDataType,
        handleOnBlurRegex,
        prevRegex,
    } = useAddEditTenantTagsPropertiesViewModel();

    const breadCrumbLinks = useMemo(() => {
        let propertiesBreadcrumbName = translate({ id: 'CLIENT_ADD_PROPERTY' });
        if (isEditDetails) {
            propertiesBreadcrumbName = translate({ id: 'CLIENT_EDIT_KEY_DETAILS' }, { key: property?.name });
        } else if (isEditKeyValues) {
            propertiesBreadcrumbName = translate({ id: 'CLIENT_EDIT_KEY_VALUES' }, { key: property?.name });
        }

        return process.buildConfigs.showForMSI
            ? [
                {
                    link: RouteNames.OrganizationAdminHome,
                    name: orgName,
                },
                {
                    link: RouteNames.TenantTagsLabelsMsi,
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    link: RouteNames.TenantTagsPropertiesMsi,
                    name: translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' }),
                },
                {
                    link: RouteNames.TenantTagsLabelsAddEditMsi,
                    name: propertiesBreadcrumbName,
                },
            ]
            : [
                {
                    link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                    name: tenant?.name ?? '',
                },
                {
                    link: RouteNames.TenantTagsLabels.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_TAGS' }),
                },
                {
                    link: RouteNames.TenantTagsProperties.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_PROPERTIES_KEY_VALUE' }),
                },
                {
                    link: RouteNames.TenantTagsLabelsAddEdit.replace(':tenantId', tenantId),
                    name: propertiesBreadcrumbName,
                },
            ];
    }, [ isEditDetails, isEditKeyValues, orgName, property?.name, tenant?.name, tenantId, translate ]);

    const showValuesGrid = useMemo(() => (
        <>
            <Typography
                id='gridLabel'
                component='div'
                className={classes.gridLabel}>
                {translate({ id: 'CLIENT_VALUES' })}
            </Typography>
            <Controller
                control={control}
                name="values"
                render={() =>
                    <ApDataGrid
                        data-cy="add-edit-tags-properties-ap-data-grid"
                        ref={apGridRef}
                        aria-labelledby='gridLabel'
                    />}
            />
        </>), [ apGridRef, classes.gridLabel, control, translate ]);

    return (
        <UiPageContainer
            header={AdminBreadCrumbs(breadCrumbLinks)}
            maxWidth='900px'
            loading={type === 'edit' && tenantLoading}
        >
            <UiForm
                className={classes.form}
                onSubmit={handleSubmit}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={cancel}
                            color="primary"
                            data-cy="add-edit-tenant-tag-properties-cancel"
                        >
                            {translate({ id: (type === 'add' || isEditDetails) ? 'CLIENT_CANCEL' : 'CLIENT_RETURN' })}
                        </Button>
                        {(type === 'add' || isEditDetails) && <UiProgressButton
                            type="submit"
                            disabled={!isDirty || Object.keys(errors).length > 0}
                            loading={isSubmitting}
                            variant="contained"
                            data-cy="tenant-tag-properties-add-button"
                        >
                            {translate({ id: type === 'add' ? 'CLIENT_ADD' : 'CLIENT_SAVE' })}
                        </UiProgressButton>}
                    </div>
                }
                centerChild
                dataCy="tenant-settings-component"
            >
                <TextField
                    className={classes.input}
                    inputRef={register({
                        required: translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_KEY_NAME' }) },
                        ),
                        validate: v => !!v.trim(),
                    })}
                    helperText={errors?.name?.message}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_NAME' })}
                    required
                    error={!!errors.name}
                    name="name"
                    variant="outlined"
                    label={translate({ id: 'CLIENT_KEY_NAME' })}
                    fullWidth
                    data-cy="tenant-tags-properties-name"
                    inputProps={{ 'aria-label': translate({ id: 'CLIENT_KEY_NAME' }) }}
                    disabled={isEditKeyValues}
                />
                {!isEditKeyValues && <TextField
                    className={classes.input}
                    inputRef={register()}
                    placeholder={translate({ id: 'CLIENT_LABELS_ADD_DESCRIPTION_OPTIONAL' })}
                    multiline
                    rows={3}
                    error={!!errors.description}
                    name="description"
                    variant="outlined"
                    label={translate({ id: 'CLIENT_DESCRIPTION' })}
                    fullWidth
                    data-cy="tenant-tags-properties-description"
                    inputProps={{ 'aria-label': translate({ id: 'CLIENT_DESCRIPTION' }) }}
                />}
                {!isEditDetails && <>
                    <UiSelect
                        className={classes.input}
                        control={control}
                        name='dataType'
                        error={!!errors.dataType}
                        inputLabel={translate({ id: 'CLIENT_INPUT_DATA_TYPE' })}
                        disabled={isEditKeyValues}
                        options={ValueDataTypes}
                        dataCy='tenant-tags-properties-data-type'
                        required
                        helperText={
                            (errors.dataType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                        }
                        tooltip={
                            <Tooltip title={translate({ id: 'CLIENT_VALUE_DATA_TYPE_WARNING' })}>
                                <InfoOutlinedIcon
                                    className={classes.tooltipIcon}
                                    data-cy='select-tooltip-icon'
                                />
                            </Tooltip>
                        }
                        onChange={changeDataType}
                    />
                    {isRegex && <TextField
                        className={classes.input}
                        inputRef={register({
                            required: true,
                            validate: v => !!v.trim(),
                        })}
                        placeholder={translate({ id: 'CLIENT_ADD_REGEX' })}
                        error={!!errors.regex}
                        name="regex"
                        variant="outlined"
                        label={translate({ id: 'CLIENT_REGEX_RULE' })}
                        fullWidth
                        data-cy="tenant-tags-property-regex-rule"
                        inputProps={{ 'aria-label': translate({ id: 'CLIENT_REGEX_RULE' }) }}
                        required
                        helperText={errors.regex ? errors.regex.message : translate({ id: 'CLIENT_REGEX_RULE_HELPER' })}
                        onBlur={e => handleOnBlurRegex(e, getValues('values'), prevRegex)}
                        disabled={isEditKeyValues}
                    />}
                    {showValuesGrid}
                </>}
            </UiForm>
        </UiPageContainer>
    );
};

export default ({ route }: { route?: any }) => (
    <UiDataContextProvider<ITenantTagsPropertiesContext> initialState={{
        Values: [],
        DataType: 'String',
    }}>
        <AddEditTenantTagsPropertiesComponent />
        {renderRoutes(route?.routes)}
    </UiDataContextProvider>
);
