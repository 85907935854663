import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { IBulkAuthenticationSettingResponse } from '../../services/identity/AuthenticationSettingService';
import {
    authenticationSettingUrl,
    getAllAuthenticationSettings
    ,
} from '../../services/identity/AuthenticationSettingService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../store/selectors';

export const useCheckAuthenticationSetting = () => {
    const partitionGlobalId = useSelector(accountGlobalId);
    const isHost = useSelector(isHostModeSelector);
    const { data: authenticationSetting } = useSWR<IBulkAuthenticationSettingResponse, Error>(
        !isHost ? [ `${authenticationSettingUrl}/getAll`, partitionGlobalId ] : null,
        getAllAuthenticationSettings,
    );
    return authenticationSetting;
};
