import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { ApDataGrid } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

import useOrganizationAccessPolicyGridViewModel from './OrganizationAccessPolicyGridViewModel';

const useStyles = makeStyles(theme => createStyles({
    tableHeader: {
        color: theme.palette.semantic.colorForegroundDeEmp,
        fontWeight: 600,
        fontSize: '14px',
    },
}));

const OrganizationAccessPolicyGridComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        apGridRef, memberCount,
    } = useOrganizationAccessPolicyGridViewModel();

    return (
        <div>
            <Typography className={classes.tableHeader}>
                {translate({ id: 'CLIENT_ALLOWED MEMBERS' }, { count: memberCount })}
            </Typography>
            <ApDataGrid
                data-cy="ap-access-policy-grid"
                ref={apGridRef}
            />
        </div>
    );
};

export default OrganizationAccessPolicyGridComponent;
