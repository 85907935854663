import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiStorage } from '@experiences/util';
import { useSelector } from 'react-redux';

import { adminRevampSelector } from '../../store/selectors';

export const useIsAdminRevampEnabled = () => {
    const EnableAdminRevampToggle = useFeatureFlagValue(Features.EnableAdminRevampToggle.name);
    const HideAdminRevampToggle = useFeatureFlagValue(Features.HideAdminRevampToggle.name);

    const adminRevamp = useSelector(adminRevampSelector);

    const isAdminRevampEnabled = !!EnableAdminRevampToggle && adminRevamp;

    if (UiStorage.getItem('OverrideAdminExperienceLocal') === 'true') {
        return false;
    }

    return HideAdminRevampToggle || isAdminRevampEnabled;
};

