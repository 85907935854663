export const AdvantagesOfSamlIntegration =
  'https://docs.uipath.com/{product}/{version}/{language}/docs/authentication-options#saml-model';

export const AllowedDomainsAndAttributeMapping =
  'https://docs.uipath.com/{product}/{version}/{language}/docs/configuring-the-saml-integration#step-23-configure-automation-cloud ';

export const ReadArticleLinkCloud =
  'https://docs.uipath.com/automation-cloud/{language}/docs/managing-access#managing-service-level-roles';

export const ReadArticleLinkOnPrem = 'https://docs.uipath.com/{product}/{version}/{language}/docs/assigning-roles';
