import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { ITrialServices } from '@experiences/interfaces';
import {
    Grid,
    Skeleton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import RobotUsage from '../../usage/helpers/RobotUsage';
import { CurrentTrialsPanel } from './CurrentTrials';
import { FreeTrialsPanel } from './FreeTrialsPanel';

const useStyles = makeStyles(theme =>
    createStyles({
        panel: { marginTop: '21px' },
        loadingGrid: {
            display: 'flex',
            minHeight: '80px',
            justifyContent: 'space-between',
        },
        subTitle: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginBottom: '8px',
        },
    }),
);

export const TrialPerSkuPanel: React.FC<{
    trialServices: ITrialServices | undefined;
    tenantIds?: string[];
    isHome?: boolean;
}> = ({
    trialServices, tenantIds, isHome,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const enableRobotUnits = useFeatureFlagValue(Features.EnableRobotUnits.name);
    const hasRobotUnitsLicense = useMemo(() => trialServices ? isHome &&
        enableRobotUnits && trialServices.currentTrials.flatMap(t => t.products).some(p => p.code === 'RU') : false,
    [ trialServices, enableRobotUnits ]);

    const showLicenseUsage = useMemo(() => hasRobotUnitsLicense, [ hasRobotUnitsLicense ]);

    return trialServices?.freeTrials && trialServices.currentTrials ? (
        <>
            <div className={classes.panel}>
                <FreeTrialsPanel freeTrials={trialServices.freeTrials} />
            </div>
            <div className={classes.panel}>
                <CurrentTrialsPanel currentTrials={trialServices.currentTrials} />
            </div>
            {showLicenseUsage && (
                <div>
                    <Typography className={classes.subTitle}>
                        {translate({ id: 'CLIENT_USAGE_TITLE' })}
                    </Typography>
                    {hasRobotUnitsLicense && (
                        <RobotUsage
                            tenantIds={tenantIds}
                            isServiceMode={false}
                            productsToDisplay={[ 'RU' ]}
                        />
                    )}
                </div>
            )}
        </>
    ) : (
        <Grid
            container
            spacing={3}>
            {[ 1, 2 ].map(number => (
                <Grid
                    key={number}
                    item
                    xs={4}>
                    <div className={classes.loadingGrid}>
                        <div style={{ flex: 0.9 }}>
                            <Skeleton
                                variant="text"
                                width={150}
                                style={{ marginBottom: '8px' }} />
                            <Skeleton
                                variant="rectangular"
                                height={36} />
                        </div>
                        <Skeleton
                            variant="circular"
                            height={28}
                            width={28} />
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
