import { useState } from 'react';
import { useRouteMatch } from 'react-router';

import * as RouteNames from '../../../common/constants/RouteNames';

export const TenantTagsTab = {
    LABELS: 'labels',
    PROPERTIES: 'properties',
};

const useTenantTagsTabsViewModel = () => {
    const match = useRouteMatch<{ tenantId: string }>();
    const isProperties = useRouteMatch(
        process.buildConfigs.showForMSI ? RouteNames.TenantTagsPropertiesMsi : RouteNames.TenantTagsProperties,
    );

    // Component state
    const tenantId = match.params.tenantId;
    const tabValueState = useState(() => isProperties ? TenantTagsTab.PROPERTIES : TenantTagsTab.LABELS);

    return {
        tenantId,
        tabValueState,
    };
};

export default useTenantTagsTabsViewModel;
