import { logEvent } from '@experiences/telemetry';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { persona } from '../../store/selectors';
import type { CarouselButtonProps } from './CarouselButtonProps';
import {
    CustomButtonFilled,
    CustomButtonUnfilled,
    CustomIconFilled,
    CustomIconUnfilled,
} from './CarouselComponent';

export const CarouselButton = ({
    idx, currentSlide, slideId, setSlide,
}: CarouselButtonProps) => {
    const dominantPersona = useSelector(persona);
    const handleClick = () => {
        logEvent('Carousel button clicked', {
            name: 'Carousel',
            group: 'HomePage',
            code: '[IN-HP-50]',
        }, {
            Persona: dominantPersona,
            Slide: idx + 1,
            SlideId: slideId,
            Description: currentSlide,
        });

        setSlide({ currentSlide: idx });
    };
    const { formatMessage: translate } = useIntl();
    return currentSlide !== idx ? (
        <CustomButtonUnfilled
            data-cy={`carousel-navigate-button-${idx}`}
            aria-label={translate(
                { id: 'CLIENT_UNFILLED_CAROUSEL_BUTTON' },
                { 0: idx.toString() }
            )}
            size="small"
            onClick={handleClick}
        >
            <CustomIconUnfilled />
        </CustomButtonUnfilled>
    ) : (
        <CustomButtonFilled
            aria-label={translate({ id: 'CLIENT_FILLED_CAROUSEL_BUTTON' })}
            size="small"
            onClick={handleClick}
        >
            <CustomIconFilled />
        </CustomButtonFilled>
    );
};
