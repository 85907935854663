import { portalTelemetry } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import urljoin from 'url-join';

import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import { post } from '../../../services/utility/Requests.default';
import {
    accountGlobalId,
    accountLogicalName,
    accountType,
    userGlobalId,
} from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        serviceDisabled: {
            pointerEvents: 'none',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
}));

const ServiceRoutingComponent: React.FC<{ tenantName: string; tenantId: string; serviceType: string; isValid: boolean }> = ({
    tenantName,
    tenantId,
    serviceType,
    isValid,
}) => {
    const classes = useStyles();
    const logicalName = useSelector(accountLogicalName);
    const accountId = useSelector(accountGlobalId);
    const userId = useSelector(userGlobalId);
    const subscriptionType = useSelector(accountType);

    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    return (
        <a
            data-cy={`${serviceType}-service-routing-link`}
            href={urljoin(window.location.origin, logicalName, tenantName, serviceType + '_')}
            className={clsx(!isValid && classes.serviceDisabled, classes.a)}
            onClick={async () => {
                try {
                    await post(`/api/telemetry/logCustomEvent`, {
                        body: {
                            name: 'Service.Open',
                            properties: {
                                ApplicationName: 'UiPath.AutomationCloud',
                                CloudOrganizationId: accountId,
                                CloudTenantId: tenantId,
                                CloudUserId: userId,
                                SubscriptionType: subscriptionType,
                                ServiceName: serviceType,
                            },
                        },
                    });
                } catch (e) {
                    portalTelemetry.trackTrace({
                        message: `Error tracking Service.Open event ${serviceType}`,
                        severityLevel: SeverityLevel.Error,
                    }, { revampEnabled: isAdminRevampEnabled });
                }
            }}
        >
            {getFriendlyName(serviceType)}
        </a>
    );
};

export default ServiceRoutingComponent;
