import { Region } from '@experiences/constants';

import type { IService } from '../../../../common/interfaces/tenant/tenant';
import type { IServiceMetadata } from '../../interfaces/service';
import { TenantStatusConstants } from '../../TenantConstants';
import type { IServiceRegionMap } from '../../TenantsPageComponent';
import { ServiceLicenseStatus } from '../CreateEditTenantComponent';
import { getListOfDependencies } from './ServiceDependencyGraph';
import type { ServiceErrorType } from './TenantServiceErrorMessage';

type IValidatedTenantService = Record<ServiceErrorType, string[]>;

export default function validateTenantService(
    availableServices: IServiceMetadata[],
    originalServices: IService[],
    currentServices: string[],
    regionValidator: { region?: Region; servicesAltRegion?: IServiceRegionMap } = {},
): IValidatedTenantService {
    let servicesWithRegionError: IServiceMetadata[] = [];
    let servicesWithNoFallbackRegionError: IServiceMetadata[] = [];

    const {
        region, servicesAltRegion,
    } = regionValidator;

    if (region) {
        servicesWithRegionError = availableServices.filter(
            s => s.supportedRegions && s.supportedRegions.indexOf(region) === -1 && currentServices.indexOf(s.id) > -1,
        );

        servicesWithNoFallbackRegionError = availableServices.filter(
            s =>
                s.supportedRegions &&
        s.supportedRegions.indexOf(region) === -1 &&
        (!s.defaultRegion || s.defaultRegion === Region.None),
        );
    }

    const servicesWithAltRegion =
    (servicesAltRegion &&
      availableServices.filter(s => !!servicesAltRegion[s.id] && currentServices.indexOf(s.id) > -1)) ??
    [];

    const servicesWithLicenseError = availableServices.filter(
        s =>
            s.serviceLicenseStatus !== ServiceLicenseStatus.Available &&
      !originalServices.find(service => service.serviceType === s.id),
    );

    const missingDependency = availableServices.filter(service => {
        const dependencies = getListOfDependencies(service.id);
        return !dependencies.every(dep => currentServices.indexOf(dep) > -1);
    });

    const missingParentLicense = availableServices.filter(service => {
        const dependencies = getListOfDependencies(service.id);
        return dependencies.some(dep => servicesWithLicenseError.map(serviceIterator => serviceIterator.id).includes(dep));
    });

    const dependencyDisabled = availableServices.filter(service => {
        const dependencies = getListOfDependencies(service.id);
        return (
            !originalServices.find(s => s.serviceType === service.id) &&
      !dependencies.every(
          dep =>
              originalServices.find(s => s.serviceType === dep)?.status.toUpperCase() !== TenantStatusConstants.DISABLED,
      )
        );
    });

    const servicesToDisable = [
        ...servicesWithLicenseError,
        ...servicesWithNoFallbackRegionError, // only disable if no fallback region defined
        ...dependencyDisabled,
    ];

    return {
        shouldDisable: [ ...new Set(servicesToDisable.concat(missingDependency).map(s => s.id)) ],
        shouldDisableRevamp: [ ...new Set(servicesToDisable.concat(missingParentLicense).map(s => s.id)) ],
        license: [ ...servicesWithLicenseError.map(s => s.id) ],
        unsupported: [ ...servicesWithRegionError.map(s => s.id) ],
        noFallback: [ ...servicesWithNoFallbackRegionError.map(s => s.id) ],
        altRegion: [ ...servicesWithAltRegion.map(s => s.id) ],
        missingDependency: [ ...missingDependency.map(s => s.id) ],
        dependencyDisabled: [ ...dependencyDisabled.map(s => s.id) ],
    };
}
