import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { ClassNameMap } from 'notistack';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { isAdminSelector } from '../../../store/selectors';
import InsightsLogExportComponent from './InsightsLogExportComponent/InsightsLogExportComponent';
import InsightsLogExportComponentAzEventHub from './InsightsLogExportComponent/InsightsLogExportComponentAzEventHub';
import OrchLogExportComponent from './OrchLogExportComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        input: {
            marginTop: '20px',
            paddingLeft: '12px',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        formButtons: { display: 'flex' },
        cancelButton: { marginRight: '10px' },
        breakAll: { wordBreak: 'break-all' },
    }),
);

export const FormErrorHandler: React.FC<{
    error?: any;
    submitError: string;
    setSubmitError: (hasError: string) => void;
    currentAccountType: keyof typeof AccountLicense;
}> = ({
    error,
    submitError,
    setSubmitError,
    currentAccountType,
}) => {
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);

    return ((!isAdmin ||
        AccountLicense[currentAccountType] === AccountLicense.COMMUNITY ||
        (!!error && error.response?.status !== 404) ||
        submitError)
        ? (
            <div style={{ marginLeft: '24px' }}>
                {submitError ? (
                    <Typography>
                        {submitError}
                    </Typography>
                ) : (
                    <Typography>
                        {!isAdmin || AccountLicense[currentAccountType] === AccountLicense.COMMUNITY
                            ? translate({ id: 'CLIENT_ACCESS_DENIED' })
                            : translate({
                                id: error.response?.status === 403
                                    ? 'CLIENT_ACCESS_DENIED_TEXT' : 'CLIENT_UNKNOWN_ERROR_FROMBACKEND',
                            })}
                    </Typography>
                )}
                {isAdmin && AccountLicense[currentAccountType] !== AccountLicense.COMMUNITY && (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setSubmitError('');
                        }}
                        style={{ marginTop: '12px' }}
                    >
                        {translate({ id: 'CLIENT_RETRY' })}
                    </Button>
                )}
            </div>)
        : null
    );
};

export const FormActionButtons: React.FC<{
    showActions: boolean;
    classes: ClassNameMap;
    loading: boolean;
    isSubmitDisabled: boolean;
    close: () => void;
    onSubmit?: (...props: any) => void;
}> = ({
    showActions = true,
    classes,
    loading,
    isSubmitDisabled,
    close = () => {},
    onSubmit = () => {},
}) => {
    const { formatMessage: translate } = useIntl();

    return (
        showActions ? (
            <div className={classes.actions}>
                <div className={clsx(classes.formButtons)}>
                    <Button
                        className={classes.cancelButton}
                        onClick={close}
                        color="primary"
                        data-cy="configure-logs-cancel"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        loading={loading}
                        onClick={onSubmit}
                        variant="contained"
                        data-cy="configure-logs-save"
                        disabled={isSubmitDisabled}
                    >
                        {translate({ id: 'CLIENT_SAVE' })}
                    </UiProgressButton>
                </div>
            </div>
        ) : null
    );
};

const LogExportComponent: React.FC<{
    location: { search: string };
}> = ({ location }) => {
    const classes = useStyles();
    const match = useRouteMatch<{ tenantId: string }>();
    const EnableInsightsLogExportMultiStorageTypes = useFeatureFlagValue(Features.EnableInsightsLogExportMultiStorageTypes.name);

    const { tenantId } = useMemo(() => ({ tenantId: match.params.tenantId }), [ match ]);

    const {
        serviceType, tenantName,
    } = useMemo(() => {
        const params = new URLSearchParams(location.search);

        return {
            serviceType: params.get('serviceType')!,
            tenantName: params.get('tenantName')!,
        };
    }, [ location ]);

    const props = {
        classes,
        tenantId,
        tenantName,
    };

    const isK8s = process.buildConfigs.name === 'k8s';

    return serviceType === 'orchestrator'
        ? <OrchLogExportComponent {...props} />
        : (EnableInsightsLogExportMultiStorageTypes || isK8s)
            ? <InsightsLogExportComponent {...props} />
            : <InsightsLogExportComponentAzEventHub {...props} />;
};

export default LogExportComponent;
