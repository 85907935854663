import { GlobalStyles } from '@experiences/theme';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import type { Row } from 'react-table';

import type { IAction } from './grid';
import { ButtonType } from './grid';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            color: theme.palette.semantic.colorIconDefault,
        },
        menuItem: {
            '&:focus': { backgroundColor: theme.palette.semantic.colorHover },
            '&:hover': { backgroundColor: theme.palette.semantic.colorHover },
        },
    }),
}));

interface IMoreRowActionsProps<T extends object> {
    row: Row<T>;
    actions: Array<IAction<T>>;
}

export const UiMoreRowActionsComponent = <T extends object>({
    row, actions,
}: IMoreRowActionsProps<T>) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);

    const clickMenuItem = useCallback((click: (_: any) => void, row: Row<any>) => {
        setAnchorEl(null);
        click(row);
    }, []);

    return (
        <>
            <Tooltip title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    className={clsx(classes.showMoreButton)}
                    onClick={event => setAnchorEl(event.currentTarget)}
                    data-cy="ui-grid-show-more-actions-button"
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                data-cy="ui-grid-show-more-actions-menu"
            >
                {actions.map((action, p) =>
                    (action.invisible !== undefined && typeof action.invisible === 'function' ? (
                        !action.invisible(row)
                    ) : (
                        !action.invisible
                    )) ? (
                            <Tooltip
                                key={p}
                                title={action.tooltip && typeof action.tooltip === 'function' ? action.tooltip(row) : ''}>
                                <div>
                                    <MenuItem
                                        key={p}
                                        disabled={action.disable && typeof action.disable === 'function' ? action.disable(row) : false}
                                        onClick={() => (action.click ? clickMenuItem(action.click, row) : undefined)}
                                        data-cy={action.dataCy || 'ui-grid-show-more-actions-item'}
                                        className={clsx(classes.menuItem)}
                                    >
                                        {action.type === ButtonType.ButtonWithIcon && action.icon ? (
                                            <ListItemIcon className={clsx(classes.icon, classes.svgIcon)}>
                                                {typeof action.icon !== 'function' ? action.icon : action.icon(row)}
                                            </ListItemIcon>
                                        ) : null}

                                        <ListItemText primary={typeof action.label === 'function' ? action.label(row) : action.label} />
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        ) : null,
                )}
            </Menu>
        </>
    );
};
