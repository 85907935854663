import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useLocalization } from '@experiences/locales';
import { UiProgressButton } from '@experiences/ui-common';
import {
    Button,
    Divider,
    Link,
    Skeleton,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import type { AxiosError } from 'axios';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useIsAdminRevampEnabled } from '../../../common/hooks/useIsAdminRevampEnabled';
import type { ISetting } from '../../../services/identity/SettingService';
import {
    getSetting,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import type { InsightsDataRetentionPolicy } from '../../../services/insights/DataRetentionService';
import {
    getDataRetentionPolicy,
    insightsDataRetentionPolicyUrl,
} from '../../../services/insights/DataRetentionService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';
import {
    mapNavCustomizationDataToKeyValuePairs,
    mapSettingArrayToNavCustomizationData,
} from '../../../util/setting/NavCustomizationSettingUtil';
import UiForm from '../../common/UiForm';
import type { INavCustomizationData } from './navigationCustomization/NavCustomizationComponent';
import NavCustomizationComponent, {
    defaultEnterpriseNavCustomizationData,
    defaultNavCustomizationData,
    navCustomizationKeys,
} from './navigationCustomization/NavCustomizationComponent';
import OrganizationDelete from './OrganizationDelete';
import OrganizationMigration from './OrganizationMigration';
import UserLicensingSettingsComponent from './UserLicensingSettingsComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        advancedSettingsContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        },
        advancedSettingsTitle: {
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '8px',
        },
        advancedSettings: { maxWidth: '518px' },
        advancedSettingsRevamp: { width: '100%' },
        divider: { color: theme.palette.semantic.colorBorderDeEmp },
        section: { marginTop: '24px' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginLeft: '10px' },
        generalSettings: {
            width: '75vw',
            maxWidth: '900px',
            height: 'calc(100% - 40px)',
            minWidth: '518px',
        },
        centerProgress: {
            margin: 'auto',
            marginTop: '32px',
        },
        headerWithLink: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        retentionPolicyHeader: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20px',
            marginTop: '32px',
            marginBottom: '8px',
        },
        retentionPolicyLink: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20px',
            marginTop: '32px',
            marginBottom: '8px',
        },
        retentionPolicyBody: {
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '4px',
        },
    }),
);

export const AdvancedSettingsTabComponent: React.FC = () => {
    const classes = useStyles();
    const isAdminRevampEnabled = useIsAdminRevampEnabled();

    const { formatMessage: translate } = useIntl();
    const { enqueueSnackbar } = useSnackbar();

    const DisableFeatureFedRamp = useFeatureFlagValue(Features.DisableFeatureFedRamp.name);
    const EnableOrgMigration = useFeatureFlagValue(Features.EnableOrgMigration.name);
    const EnableInsightsDataMonitoring = useFeatureFlagValue(Features.EnableInsightsDataMonitoring.name);

    const partitionGlobalId = useSelector(accountGlobalId);
    const organizationName = useSelector(accountLogicalName);
    const locale = useLocalization();

    const {
        includesLicense, notIncludesLicense, isFreeOrCommunityRevamp, isUserEnterprise,
    } = useCheckLicense();

    const isUserCommunityAccountType = includesLicense([ AccountLicense.COMMUNITY ]);

    const {
        data: insightsData, error: insightsError, mutate: mutateInsights,
    } = useSWR<InsightsDataRetentionPolicy, AxiosError>(
        EnableInsightsDataMonitoring ? [ insightsDataRetentionPolicyUrl, organizationName ] : null,
        getDataRetentionPolicy,
    );

    const deletionDate = useMemo(() => {
        if (!insightsData) {
            return undefined;
        }

        const deletionDateRaw = insightsData.result.DeletionDate;
        if (typeof deletionDateRaw === 'string') {
            return new Date(deletionDateRaw);
        }

        return deletionDateRaw;
    }, [ insightsData ]);

    const showDeleteOrg = useMemo(() =>
        !process.buildConfigs.disableOrganizationSettingsOrganizationDelete && !DisableFeatureFedRamp &&
        notIncludesLicense([ AccountLicense.ENTERPRISE, AccountLicense.PRO ]),
    [ DisableFeatureFedRamp, notIncludesLicense ]);

    const defaultFormValues = isUserEnterprise
        ? defaultEnterpriseNavCustomizationData
        : defaultNavCustomizationData;

    const methods = useForm<INavCustomizationData>({
        mode: 'onSubmit',
        defaultValues: defaultFormValues,
    });

    const {
        reset, handleSubmit, formState,
    } = methods;
    const {
        isDirty, isSubmitting,
    } = formState;

    const {
        data: fetchedSettings, mutate,
    } = useSWR<ISetting[], Error>(
        [ settingUrl, navCustomizationKeys, partitionGlobalId ],
        getSetting,
        { shouldRetryOnError: false },
    );

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    const createNotification = useCallback(
        (message: string, type = notificationType.SUCCESS) => {
            enqueueSnackbar(message, { variant: type as any });
        },
        [ enqueueSnackbar ],
    );

    const onSubmit = useCallback(
        async (data: INavCustomizationData) => {
            try {
                const savedData = await saveSetting(settingUrl, {
                    settings: mapNavCustomizationDataToKeyValuePairs(data),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATED' }));
            } catch (error) {
                createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
            }
        },
        [ createNotification, mutate, partitionGlobalId, translate ],
    );

    useEffect(() => {
        if (fetchedSettings) {
            reset(mapSettingArrayToNavCustomizationData(
                fetchedSettings,
                defaultFormValues,
            ));
        }
    }, [ reset, fetchedSettings, defaultFormValues ]);

    const InsightsDataMonitoring = useMemo(() => {
        if (!EnableInsightsDataMonitoring) {
            return null;
        }

        if (insightsError && insightsError?.response?.status !== 404) {
            return null;
        }

        if (!insightsData && !insightsError) {
            return <div>
                <Skeleton
                    variant="text"
                    className={classes.retentionPolicyHeader}
                    width="25%" />
                <Skeleton
                    variant="text"
                    className={classes.retentionPolicyBody}
                    width="30%" />
                <Skeleton
                    variant="text"
                    className={classes.retentionPolicyBody}
                    width="30%" />
            </div>;
        }

        return <div>
            <div className={classes.headerWithLink}>
                <Typography
                    variant="h3"
                    className={classes.retentionPolicyHeader}>
                    {translate({ id: 'CLIENT_INSIGHTS_DATA_RETENTION_POLICY' })}
                </Typography>
                <Link
                    className={classes.retentionPolicyLink}
                    href="https://licensing.uipath.com/"
                    underline="hover">
                    {translate({ id: 'CLIENT_STEPS_TO_UPGRADE_POLICY' })}
                </Link>
            </div>
            {!insightsError && <>
                <Typography className={classes.retentionPolicyBody}>
                    {translate({ id: 'CLIENT_CURRENT_DATA_RETENTION_POLICY' }, { years: insightsData?.result.RetentionTime_Years })}
                </Typography>
                <Typography className={classes.retentionPolicyBody}>
                    {`${translate({ id: 'CLIENT_LAST_UPCOMING_DELETION_DATE' })}: ${deletionDate?.toLocaleDateString(
                        locale,
                        {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                        },
                    )}`}
                </Typography>
            </>}
            {insightsError?.response?.status === 404 && <Typography>
                {translate({ id: 'CLIENT_INSIGHTS_ERROR' })}
                <Link
                    style={{ cursor: 'pointer' }}
                    onClick={() => mutateInsights()}>
                    {translate({ id: 'CLIENT_RETRY' })}
                </Link>
            </Typography>}
        </div>;
    }, [
        EnableInsightsDataMonitoring,
        classes.headerWithLink,
        classes.retentionPolicyBody,
        classes.retentionPolicyHeader,
        classes.retentionPolicyLink,
        deletionDate,
        insightsData,
        insightsError,
        locale,
        mutateInsights,
        translate,
    ]);

    const formContents = useMemo(() => (
        <div className={clsx({ [classes.advancedSettingsContainer]: isAdminRevampEnabled })}>
            <div
                className={!isAdminRevampEnabled ? classes.advancedSettings : classes.advancedSettingsRevamp}
                data-cy="advanced-settings-page"
                style={isAdminRevampEnabled ? { paddingTop: '20px' } : undefined}>
                {!isAdminRevampEnabled && <Typography
                    className={classes.advancedSettingsTitle}
                    role="heading"
                    aria-level={2}>
                    {translate({ id: 'CLIENT_ADVANCED_SETTINGS' })}
                </Typography>}
                {!isFreeOrCommunityRevamp && <NavCustomizationComponent />}
                <UserLicensingSettingsComponent />
                {EnableOrgMigration && !DisableFeatureFedRamp && <OrganizationMigration />}

                {showDeleteOrg && (
                    <>
                        <Divider
                            className={classes.divider}
                            style={{ visibility: isAdminRevampEnabled ? 'hidden' : 'visible' }} />
                        <OrganizationDelete />
                    </>
                )}

                {InsightsDataMonitoring}
            </div>
        </div>
    ), [
        DisableFeatureFedRamp,
        EnableOrgMigration,
        InsightsDataMonitoring,
        classes.advancedSettings,
        classes.advancedSettingsContainer,
        classes.advancedSettingsRevamp,
        classes.advancedSettingsTitle,
        classes.divider,
        isAdminRevampEnabled,
        isFreeOrCommunityRevamp,
        showDeleteOrg,
        translate,
    ]);

    return isAdminRevampEnabled
        ? (
            <div className={classes.generalSettings}>
                <UiForm
                    centerChild
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        isDirty && <div className={classes.actions}>
                            <Button
                                className={classes.cancelButton}
                                onClick={() => {
                                    handleCancel();
                                }}
                                color="primary"
                                data-cy="nav-customization-cancel-button"
                            >
                                {translate({ id: 'CLIENT_DISCARD' })}
                            </Button>
                            <UiProgressButton
                                type="submit"
                                loading={isSubmitting}
                                disabled={!isDirty}
                                variant="contained"
                                data-cy="nav-customization-submit-button"
                            >
                                {translate({ id: 'CLIENT_SAVE_CHANGES' })}
                            </UiProgressButton>
                        </div>
                    }
                    heightOffset="0px"
                >
                    <FormProvider {...methods}>
                        {formContents}
                    </FormProvider>
                </UiForm>
            </div>
        )
        : formContents;
};
