import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { GlobalStyles } from '@experiences/theme';
import {
    Button,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import type {
    IActivateOfflineData,
    IOfflineActivationRequestResponse,
} from '../../../common/interfaces/license.activation';
import { getActivationRequest } from '../../../services/licensing/management/ActivationService';
import { isHostModeSelector } from '../../../store/selectors';
import validateLicenseCode from '../../../util/validators/LicenseCodeValidator';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        stepInstruction: {
            color: theme.palette.semantic.colorForeground,
            fontWeight: 'bold',
            fontSize: '14px',
        },
        stepper: { paddingLeft: '0px' },
    }),
}));

const LicenseActivationStepsComponent: React.FC<{ isTrialPerSkuActivation: boolean }> = ({ isTrialPerSkuActivation }) => {
    const classes = useStyles();
    const setErrorMessage = useCentralErrorSetter();
    const [ downloadInProgress, setDownloadInProgress ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage: translate } = useIntl();
    const {
        getValues, register, setError,
    } = useFormContext<IActivateOfflineData>();
    const isHostMode = useSelector(isHostModeSelector);
    const { getErrorMessage } = useGetErrorInfo();

    const saveActivationRequestFile = useCallback(
        (downloadedActivationRequestData: IOfflineActivationRequestResponse, filename: string) => {
            const downloadedActivationRequest = new Blob([ downloadedActivationRequestData.activationRequest ], { type: 'charset=utf-8' });
            FileSaver.saveAs(downloadedActivationRequest, filename);
        },
        [],
    );
    const downloadActivationRequest = useCallback(
        async (licenseCode: string) => {
            if (!validateLicenseCode(licenseCode)) {
                setError('licenseCode', { type: 'license-format' });
                return;
            }
            try {
                if (!downloadInProgress) {
                    setDownloadInProgress(true);
                    enqueueSnackbar(translate({ id: 'CLIENT_DOWNLOAD_IN_PROGRESS' }), { variant: notificationType.INPROGRESS } as any);
                    const activationRequestObject: IOfflineActivationRequestResponse = await getActivationRequest(
                        licenseCode,
                        isHostMode,
                        isTrialPerSkuActivation,
                    );
                    saveActivationRequestFile(activationRequestObject, translate({ id: 'CLIENT_ACTIVATION_REQUEST' }));
                }
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            } finally {
                setDownloadInProgress(false);
            }
        },
        [
            setErrorMessage,
            downloadInProgress,
            enqueueSnackbar,
            translate,
            saveActivationRequestFile,
            setError,
            isHostMode,
            getErrorMessage,
            isTrialPerSkuActivation,
        ],
    );

    const steps = useMemo(() => [
        translate({ id: 'CLIENT_DOWNLOAD_ACTIVATION_REQUEST' }),
        translate({ id: 'CLIENT_ACTIVATE_YOUR_LICENSE' }),
        translate({ id: 'CLIENT_DOWNLOAD_ACTIVATION_FILE' }),
        translate({ id: 'CLIENT_UPLOAD_LICENSE_HERE' }),
    ], [ translate ]);

    const stepsContent = useMemo(() => [
        <Button
            onClick={() => downloadActivationRequest(getValues('licenseCode'))}
            key={1}
            variant="outlined"
            data-cy="download-activation-request"
        >
            {translate({ id: 'CLIENT_DOWNLOAD' })}
        </Button>,
        <FormattedMessage
            key={2}
            id="CLIENT_GO_TO_ACTIVATION_PORTAL"
            values={{
                a: (msg: any) =>
                    (
                        <a
                            className={classes.a}
                            href="https://activate.uipath.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {msg}
                        </a>
                    ) as any,
            }}
        />,
        '',
        <input
            key={4}
            ref={register}
            type="file"
            name="file"
            required
            data-cy="file-input" />,
    ], [ downloadActivationRequest, getValues, register, translate, classes ]);

    return (
        <Stepper
            orientation="vertical"
            className={clsx(classes.stepper)}>
            {steps.map((label, index) => (
                <Step
                    key={label}
                    active>
                    <StepLabel>
                        <div className={clsx(classes.stepInstruction)}>
                            {label}
                        </div>
                    </StepLabel>
                    <StepContent>
                        <Typography>
                            {stepsContent[index]}
                        </Typography>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
};
export default LicenseActivationStepsComponent;
